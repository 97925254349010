import React, { memo } from "react";
import { useService } from "../../../../services/config/dependencyInjectorConfig";
import ProjectsService from "../../../../services/ProjectsService";
import { DetailedInfoProps, ProjectTeamDetails } from "../../launchpad.types";
import { TeamCardProps } from "../../components/TeamCard";
import { getSocialMedia } from "../../../../helpers/socialMedia";
import { awsS3BaseURL } from "config";
import { KeyInvestor } from "../../../KeyInvestors/KeyInvestors.types";
import { ErrorDummy } from "../../../../components/Error";
import TeamSection from "./TeamSection";
import Placeholder from "./Placeholder";
import { useQuery } from "@tanstack/react-query";

export interface TeamSectionProps {
    title: "Members" | "Advisors" | "Key Partners";
    description?: string;
    isHtmlDescription?: boolean;
    items?: TeamCardProps[];
}

const computeTeamSections = (teamInfoDetails: ProjectTeamDetails): TeamSectionProps[] => {
    const { keyInvestors, teamMembers, teamDescription } = teamInfoDetails;
    const members: (TeamCardProps & { isAdvisor: boolean })[] | undefined = teamMembers?.map((member) => {
        return {
            ...member,
            description: member.shortDescription,
            photo: awsS3BaseURL + member.profile,
            roundedCircle: true,
            socialMedia: getSocialMedia(member, ["linkedIn", "twitter"]),
        };
    });
    const advisors = members?.filter((member) => member.isAdvisor);
    const otherMembers = members?.filter((member) => !member.isAdvisor);

    const teamSections: TeamSectionProps[] = [];
    if (!!teamDescription || (otherMembers && otherMembers.length > 0)) {
        teamSections.push({ title: "Members", description: teamDescription, items: otherMembers });
    }

    if (advisors && advisors.length > 0) {
        teamSections.push({ title: "Advisors", items: advisors });
    }

    if (keyInvestors.length > 0) {
        const investors = keyInvestors.map((investor: KeyInvestor) => {
            return {
                ...investor,
                photo: awsS3BaseURL + investor.photo,
            };
        });
        teamSections.push({ title: "Key Partners", items: investors, isHtmlDescription: true });
    }

    return teamSections;
};

const Team = ({ projectId, isActive }: DetailedInfoProps) => {
    const [projectsService] = useService(ProjectsService);

    const { data: teamSections, isLoading } = useQuery({
        enabled: isActive,
        queryKey: ["project-team-details"],
        queryFn: () => projectsService.retrieveTeamDetails(projectId),
        select: (teamDetails) => computeTeamSections(teamDetails),
        staleTime: Infinity,
    });

    if (isLoading || !teamSections) {
        return <Placeholder />;
    }

    return (
        <>
            {teamSections.length > 0 ? (
                teamSections.map(({ title, description, items, isHtmlDescription }, index) => (
                    <TeamSection
                        title={title}
                        description={description}
                        items={items}
                        isHtmlDescription={isHtmlDescription}
                        key={`team-section-${index}`}
                    />
                ))
            ) : (
                <ErrorDummy errorText="There is no information about the team" />
            )}
        </>
    );
};

export default memo(Team);
