import BaseService from "./BaseService";
import axios from "axios";
import { PAGINATION_PER_PAGE } from "../helpers/consts";

export type Users = number;
export const UsersInit = 0;

export interface Stake {
    activeValidators: number;
    queueSize: number;
    totalStaked: string;
    totalValidators: number;
}

export const StakeInit = {
    activeValidators: 0,
    queueSize: 0,
    totalStaked: "0",
    totalValidators: 0,
};

export interface Stats {
    accounts: number;
    blocks: number;
    epoch: number;
    refreshRate: number;
    roundsPassed: number;
    roundsPerEpoch: number;
    shards: number;
    transactions: number;
}

export const StatsInit = {
    accounts: 0,
    blocks: 0,
    epoch: 0,
    refreshRate: 0,
    roundsPassed: 0,
    roundsPerEpoch: 0,
    shards: 0,
    transactions: 0,
};

export interface AccountInfo {
    deployedAt: number;
    ownerAddress: string;
    shard: number;
}

export interface AccountToken {
    identifier: string;
    balance: string;
    price?: number;
}

export type TokenType = "FungibleESDT" | "NonFungibleESDT" | "SemiFungibleESDT" | "MetaESDT";

export interface SimpleTokensSearch {
    type?: TokenType;
    search?: string;
    name?: string;
    identifier?: string;
    identifiers?: string;
    includeMetaESDT?: boolean;
}

export interface TokensSearch extends SimpleTokensSearch {
    from?: number;
    size?: number;
    sort?: "accounts" | "transactions" | "price" | "marketCap";
    order?: "asc" | "desc";
}

export interface TokenInfo {
    type?: TokenType | "ESDT";
    identifier: string;
    name: string;
    ticker: string;
    transactions: number;
    accounts: number;
    price?: number;
    marketCap?: number;
    assets?: {
        website?: string;
        description?: string;
        status?: string;
        pngUrl?: string;
        svgUrl?: string;
    };
}

export class UtilsService extends BaseService {
    private readonly _apiAddress: string;
    private readonly _dataApiAddress: string;

    constructor(apiAddress: string, dataApiAddress: string) {
        super();
        this._apiAddress = apiAddress;
        this._dataApiAddress = dataApiAddress;
    }

    getStakedUsers(): Promise<Users> {
        return this.doCall(() => axios.get(`${this._dataApiAddress}/stakinghistorical/total/users`));
    }

    getStakeInfo(): Promise<Stake> {
        return this.doCall(() => axios.get(`${this._apiAddress}/stake`));
    }

    getAccountInfo(address: string): Promise<AccountInfo> {
        return this.doCall(() => axios.get(`${this._apiAddress}/accounts/` + address));
    }

    getAccountTokens(address: string): Promise<AccountToken[]> {
        return this.doCall(() =>
            axios.get(`${this._apiAddress}/accounts/${address}/tokens`, { params: { fields: "balance,identifier" } }),
        );
    }

    getAccountTokenInfo(address: string, tokenIdentifier: string): Promise<AccountToken[]> {
        return this.doCall(() =>
            axios.get(`${this._apiAddress}/accounts/${address}/tokens`, {
                params: { identifier: tokenIdentifier, fields: "identifier,balance" },
            }),
        );
    }

    getStats(): Promise<Stats> {
        return this.doCall(() => axios.get(`${this._apiAddress}/stats`));
    }

    async getScShard(scAddress: string): Promise<number> {
        const resp = await this.doCall(() =>
            axios.get<{ shard: number }>(`${this._apiAddress}/accounts/${scAddress}`, { params: { fields: "shard" } }),
        );
        return resp.shard;
    }

    async getLastBlockNo(shard: number): Promise<number> {
        const resp = await this.doCall(() =>
            axios.get<{ nonce: number }[]>(`${this._apiAddress}/blocks`, {
                params: { from: 0, size: 1, shard, fields: "nonce" },
            }),
        );
        return resp[0].nonce;
    }

    getTokens({ from = 0, size = PAGINATION_PER_PAGE, ...search }: TokensSearch): Promise<TokenInfo[]> {
        return this.doCall(() => axios.get(`${this._apiAddress}/tokens`, { params: { from, size, ...search } }));
    }

    async getTokenInfo(identifier: string): Promise<TokenInfo> {
        return this.doCall(() => axios.get(`${this._apiAddress}/tokens/${identifier}`));
    }

    countTokens(search: SimpleTokensSearch): Promise<number> {
        return this.doCall(() => axios.get(`${this._apiAddress}/tokens/count`, { params: { ...search } }));
    }
}

export default UtilsService;
