import React, { memo } from "react";
import styled from "styled-components";
import { AdditionalInfoCard } from "./AdditionalInfoCard";
import { routeNames } from "../../../routes/routes";

const Anchor = styled.a`
    margin-left: 4px;
`;

const StyledDisclaimer = styled.p`
    font-size: 0.95rem;
    line-height: 1.84rem;
    font-weight: 100;
`;

const LegalDisclaimer = () => {
    return (
        <AdditionalInfoCard>
            <h4>Legal Disclaimer</h4>
            <StyledDisclaimer>
                All crypto related projects and startups are subject to high market risk. Please make your choice very cautiously
                as Entity Launchpad is not intended as, and does not provide, any investment or financial advice whatsoever, you
                being held completely and fully responsible for your decisions. Entity Launchpad will choose high-quality
                projects, but will not be responsible for any losses. Entity Launchpad does not issue, subscribe, place or accept
                funds from you in relation with the crypto related projects and startups that are promoted on the Entity Launchpad
                platform. For more details about the risks, please consult the
                <Anchor className="text-green" href={routeNames.termsAndConditions} target="_blank" rel="noreferrer">
                    T&Cs.
                </Anchor>
            </StyledDisclaimer>
        </AdditionalInfoCard>
    );
};

export default memo(LegalDisclaimer);
