import React, { FC, useEffect, useState } from "react";
import { LaunchpadCardBaseProps } from "../launchpad.types";
import { faTrophy } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { useGetLoginInfo } from "@multiversx/sdk-dapp/hooks";
import { ELinkButton } from "../../../components/Buttons";
import { routeNames } from "../../../routes/routes";
import { DateUtils } from "../../../common";
import { LaunchpadScConfig, LaunchpadScFlags } from "../../../services/LaunchpadService";
import { useService } from "../../../services/config/dependencyInjectorConfig";
import EventsService, { EventsNames } from "../../../services/EventsService";
import ECountDownFooter from "../../../common/components/ECountDown/ECountDownFooter";
import errorToast from "../../../components/Error/errorToast";
import ESpinner from "../../../components/Spinner/ESpinner";
import UtilsService from "../../../services/UtilsService";

interface WinnerSelectionCardProps extends LaunchpadCardBaseProps {
    scConfig?: LaunchpadScConfig;
    scFlags?: LaunchpadScFlags;
    scShard?: number;
    testId?: string;
}

const WinnerSelectionCard: FC<React.PropsWithChildren<WinnerSelectionCardProps>> = ({
    projectDetails,
    isRejected,
    scConfig,
    scFlags,
    scShard,
    testId = "winner-selection-card",
}) => {
    const [started, setStarted] = useState<boolean | undefined>(undefined);
    const [finished, setFinished] = useState<boolean | undefined>(undefined);

    const { isLoggedIn } = useGetLoginInfo();
    const [utilsService] = useService(UtilsService);
    const [eventsService] = useService(EventsService);

    useEffect(() => {
        (async function () {
            if (!projectDetails) {
                return;
            }

            if (scConfig && scFlags) {
                const [hasStarted, hasFinished] = DateUtils.getPeriodStatusFlags(
                    projectDetails.generalMetrics.winnerSelectionStartTimestamp,
                    projectDetails.generalMetrics.winnerSelectionEndTimestamp,
                );
                setStarted(hasStarted);
                if (hasFinished) {
                    const isWinnerSelectionFinished = await isWinnerSelectionFinishedInSC(scConfig, scFlags);
                    setFinished(isWinnerSelectionFinished);
                } else {
                    setFinished(false);
                }
            }
        })();
    }, [scConfig, scFlags]);

    const onCompleteStartHandler = () => {
        setStarted(true);
        eventsService.emitWithDelay(EventsNames.RERENDER_LAUNCHPAD);
    };

    const isWinnerSelectionFinishedInSC = async (config: LaunchpadScConfig, flags: LaunchpadScFlags): Promise<boolean> => {
        if (scShard === undefined) {
            console.error("Sc shard is required");
            errorToast();
            return false;
        }
        const lastBlockNo = await utilsService.getLastBlockNo(scShard);
        const isInWinnerSelectionEpoch = lastBlockNo >= config.winnerSelectionStartBlock;
        return (
            isInWinnerSelectionEpoch && flags.hasWinnerSelectionStarted && flags.wereTicketsFiltered && flags.wereWinnersSelected
        );
    };

    if ((started === undefined && finished === undefined) || !scFlags) {
        return <ESpinner />;
    }

    if (!isLoggedIn) {
        return (
            <>
                <div className="d-flex justify-content-center align-items-center" data-testid={testId}>
                    <ELinkButton to={routeNames.unlock} testId="login-btn" className="text-uppercase">
                        Login
                    </ELinkButton>
                </div>
                <div className="text-center pt-1 pb-3" data-testid={testId}>
                    <ECountDownFooter
                        started={started}
                        finished={finished}
                        startTimestamp={projectDetails?.generalMetrics?.winnerSelectionStartTimestamp}
                        endTimestamp={projectDetails?.generalMetrics?.winnerSelectionEndTimestamp}
                        onCompleteStartHandler={onCompleteStartHandler}
                        onCompleteEndHandler={() => eventsService.emitWithDelay(EventsNames.RERENDER_LAUNCHPAD)}
                    />
                </div>
            </>
        );
    }

    return (
        <div className="text-center pt-1 pb-3" data-testid={testId}>
            <FontAwesomeIcon size={"3x"} className="text-green mb-3" icon={faTrophy} />
            {isRejected && <p className="text-warning">You are not eligible for the winner selection stage.</p>}
            <ECountDownFooter
                started={started}
                finished={finished}
                startTimestamp={projectDetails?.generalMetrics?.winnerSelectionStartTimestamp}
                endTimestamp={projectDetails?.generalMetrics?.winnerSelectionEndTimestamp}
                onCompleteStartHandler={onCompleteStartHandler}
                onCompleteEndHandler={() => eventsService.emitWithDelay(EventsNames.RERENDER_LAUNCHPAD)}
                inProgressChildren={<h4>Winner selection in progress.</h4>}
            />
            {finished && !isRejected && (
                <>
                    <h4>Winner selection is complete.</h4>
                    <p>You can now claim your winning tickets and tokens.</p>
                </>
            )}
        </div>
    );
};

export default WinnerSelectionCard;
