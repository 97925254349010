import React, { FC } from "react";

interface HexFooterSVGProps {
    backMenu: JSX.Element;
    menu: JSX.Element;
    buildInfo: JSX.Element;
}

const HexFooterSVG: FC<React.PropsWithChildren<HexFooterSVGProps>> = ({ backMenu, menu, buildInfo }) => {
    return (
        <svg
            id="Layer_1"
            xmlns="http://www.w3.org/2000/svg"
            xmlnsXlink="http://www.w3.org/1999/xlink"
            width="100%"
            height="100%"
            viewBox="0 0 806.9516 880.1665"
            preserveAspectRatio="none"
        >
            <defs>
                <radialGradient
                    id="radial-gradient"
                    cx={242.6182}
                    cy={1148.458}
                    fx={242.6182}
                    fy={1148.458}
                    r={0.541}
                    gradientTransform="translate(179768.2984 520002.6272) rotate(-15.6164) scale(581.8695 -477.8568) skewX(-14.6026)"
                    gradientUnits="userSpaceOnUse"
                >
                    <stop offset={0} stopColor="#51e2df" stopOpacity={0.949} />
                    <stop offset={1} stopColor="#51e2df" stopOpacity={0.078} />
                </radialGradient>
                <linearGradient
                    id="linear-gradient"
                    x1={241.1219}
                    y1={1147.7595}
                    x2={241.1219}
                    y2={1148.3175}
                    gradientTransform="translate(-74364.8208 790320.1628) scale(310.416 -687.907)"
                    gradientUnits="userSpaceOnUse"
                >
                    <stop offset={0} stopColor="#061313" />
                    <stop offset={1} stopColor="#51e2df" />
                </linearGradient>
            </defs>
            <g id="Group_170794">
                <path
                    id="blue"
                    d="M428.0409,88.4077c123.3934,80.1542,303.322,214.1204,207.0356,421.5733-96.2864,207.4529-518.8652-36.2849-589.3127-58.1264S-9.449,170.3091,61.7591,60.6879c71.2081-109.6212,242.8884-52.4344,366.2818,27.7198Z"
                    style={{
                        fill: "url(#radial-gradient)",
                        isolation: "isolate",
                        opacity: 0.301,
                    }}
                    className="cloud"
                />
                <g id="Group_170789">
                    <path
                        id="Path_163701"
                        d="M485.7796,178.7138h-107.207l-53.6,92.844,53.6,92.844h107.207l53.6-92.844-53.6-92.844Z"
                        style={{
                            fill: "#b7cfcf",
                        }}
                        className="hex"
                    />
                    <path
                        id="Path_163699"
                        d="M315.7796,277.9848h-107.207l-53.6,92.844,53.6,92.844h107.207l53.6-92.844-53.6-92.844Z"
                        style={{
                            fill: "#b7cfcf",
                            isolation: "isolate",
                            opacity: 0.8,
                        }}
                        className="hex"
                    />
                    <path
                        id="Path_163697"
                        d="M753.3516,643.8969h-107.207l-53.6,92.844,53.6,92.844h107.207l53.6-92.844-53.6-92.844Z"
                        style={{
                            fill: "#b7cfcf",
                        }}
                        className="hex"
                    />
                    <path
                        id="Path_163700"
                        d="M315.7796,474.9877h-107.207l-53.6,92.844,53.6,92.844h107.207l53.6-92.844-53.6-92.844Z"
                        style={{
                            fill: "#b7cfcf",
                            isolation: "isolate",
                            opacity: 0.6,
                        }}
                        className="hex"
                    />
                    <path
                        id="Path_163698"
                        d="M752.7086,447.0648h-107.207l-53.6,92.844,53.6,92.844h107.207l53.6-92.844-53.6-92.844Z"
                        style={{
                            fill: "#b7cfcf",
                        }}
                        className="hex"
                    />
                    <g>
                        <foreignObject x={366} y={230.7138} width={130} height={100}>
                            {backMenu}
                        </foreignObject>
                    </g>
                    <g>
                        <foreignObject x={410} y={456.7138} width={160} height={100}>
                            {menu}
                        </foreignObject>
                    </g>
                    <g>
                        <foreignObject x={360} y={615.7138} width={240} height={120}>
                            {buildInfo}
                        </foreignObject>
                    </g>
                </g>
                <g id="Group_170790">
                    <path
                        id="Path_168751"
                        d="M498.3356,881.1605h-106.812l-43.374-75.128,43.374-75.13h193.563l53.407-92.506-53.407-92.506h-193.563l-43.378-75.13,43.378-75.123h86.75l5.016-8.689,5.017-8.689h-106.82l-53.409,92.501,53.408,92.505h193.567l43.371,75.131-43.375,75.127h-193.562l-53.408,92.5,53.408,92.506h193.566l43.371,75.131-43.375,75.127h-86.75l-5.017,8.69-5.016,8.689h106.821l53.407-92.5-53.407-92.506h-86.751Z"
                        style={{
                            fill: "url(#linear-gradient)",
                        }}
                    />
                    <path
                        id="Path_168750"
                        d="M498.3356,881.1605h-106.812l-43.374-75.128,43.374-75.13h193.563l53.407-92.506-53.407-92.506h-193.563l-43.378-75.13,43.378-75.123h86.75l5.016-8.689,5.017-8.689h-106.82l-53.409,92.501,53.408,92.505h193.567l43.371,75.131-43.375,75.127h-193.562l-53.408,92.5,53.408,92.506h193.566l43.371,75.131-43.375,75.127h-86.75l-5.017,8.69-5.016,8.689h106.821l53.407-92.5-53.407-92.506h-86.751Z"
                        style={{
                            fill: "url(#linear-gradient)",
                        }}
                    />
                </g>
            </g>
        </svg>
    );
};

export default HexFooterSVG;
