import React, { useEffect, useMemo, useState } from "react";
import { Col, Row } from "react-bootstrap";
import { routeNames } from "../../routes/routes";
import styled from "styled-components";
import { featureToggles } from "../../common/utils/FeatureToggleUtils";
import { Navigate, useSearchParams } from "react-router-dom";
import { PageHeader } from "../../components/PageHeader";
import { useService } from "../../services/config/dependencyInjectorConfig";
import ProjectsService from "../../services/ProjectsService";
import { useContext } from "../../context";
import { useGetLoginInfo } from "@multiversx/sdk-dapp/hooks";
import { LaunchpadStage } from "./launchpad.types";
import SocialMediaButtons from "../../components/Buttons/SocialMediaButtons";
import { createSMCommonBtnsList } from "../../helpers/socialMedia";
import { generateKeyMetricsFromGeneralKeyMetrics } from "../../components/ProjectKeyMetricsCard/KeyMetricsHelper";
import TiersModal from "../../components/ProjectKeyMetricsCard/TiersModal";
import SuccessfulLaunches from "./successfulLaunches/SuccessfulLaunches";
import EText from "components/common/EText";
import LaunchpadCard from "../../components/LaunchpadCard/LaunchpadCard";
import { DateUtils, QuestionMarkIcon } from "../../common";
import TicketsService, { TicketsScope } from "../../services/TicketsService";
import { LaunchpadService } from "../../services/LaunchpadService";
import MetaDecorator from "../../common/components/MetaDecorator";
import { seoData } from "../../helpers/consts/SEO";
import ChainDataService from "../../services/ChainDataService";
import InfoCardsBlock from "./InfoCardsBlock/InfoCardsBlock";
import { DetailedInformation } from "./DetailedInformation";
import { BackgroundDecorations } from "../../components/BackgroundDecorations";
import EventsService, { EventsNames } from "../../services/EventsService";
import { BREAKPOINTS } from "../../assets/sass/breakpoints";
import { modulesLinkList } from "../../helpers/consts";
import EPlaceholder from "../../components/Placeholder/Placeholder";
import { LaunchpadAccordionsKeys } from "./DetailedInformation/DetailedInformation";
import { useQuery } from "@tanstack/react-query";
import { LaunchpadQueryKeys } from "../../common/types/queryKeysTypes";
import { useGetKycStatus, useGetProjectGeneralDetails } from "../../common/hooks";
import SessionStorageService from "../../services/sessionStorageService/SessionStorageService";
import { SessionStorageKeys } from "../../services/sessionStorageService/sessionStorageKeys";
import useCheckUserAddressIsBlacklisted from "../../common/hooks/useCheckUserAddressIsBlacklisted";

const ContainerCol = styled(Col)``;

const Header = styled.div`
    display: flex;
    padding: 0 !important;
    margin-bottom: 0.8rem;

    h1 {
        margin: 0;
    }
`;

const ContainerRow = styled(Row)`
    padding-top: 2.142rem;
    margin: 0;

    &:first-child {
        padding-top: 0;

        @media (${BREAKPOINTS.tablet}) {
            padding-top: 2.285rem;
        }
    }

    &:last-child {
        padding-bottom: 0;
    }

    ${ContainerCol} {
        padding-left: 2.1rem !important;
        padding-right: 1.428rem !important;

        &:first-child {
            padding-left: 0 !important;
        }

        &:last-child {
            padding-right: 0 !important;
        }
    }
`;

export const ID_PARAM = "id";

export const CURRENT_STAGE_INDEX = 1;
export const PREV_STAGE_INDEX = 0;

const supportedHashes = {
    about: "about",
    metrics: "metrics",
    team: "team",
    investment: "investment",
    faq: "faq",
};

const Launchpad = () => {
    const [showTiersModal, setShowTiersModal] = useState<boolean>(false);
    const [activeKey, setActiveKey] = useState<LaunchpadAccordionsKeys>(LaunchpadAccordionsKeys.NONE);
    const [rerender, setRerender] = useState<boolean>(false);

    const [projectsService] = useService(ProjectsService);
    const [ticketsService] = useService(TicketsService);
    const [launchpadService] = useService(LaunchpadService);
    const [chainDataService] = useService(ChainDataService);
    const [eventsService] = useService(EventsService);

    const { jwtToken } = useContext();
    const { isLoggedIn } = useGetLoginInfo();
    const [searchParams] = useSearchParams();

    const projectId = searchParams.get(ID_PARAM) ?? undefined;
    useEffect(() => {
        eventsService.getEmitter().addListener(EventsNames.RERENDER_LAUNCHPAD, async () => {
            await retrieveProjectGeneralDetails();
            await retrieveCurrentEpoch();
            setRerender(true);
        });
        return () => eventsService.getEmitter().removeAllListeners(EventsNames.RERENDER_LAUNCHPAD);
    }, []);

    const setActiveKeyAndScroll = (key: LaunchpadAccordionsKeys, hash: string) => {
        setTimeout(() => {
            setActiveKey(key);
            const el = document.getElementById(hash);
            if (el) {
                el.scrollIntoView({ behavior: "smooth" });
            }
        }, 1000);
    };

    useEffect(() => {
        if (!window.location?.hash) return;
        const hash = window.location?.hash.replace("#", "");
        if (!supportedHashes.hasOwnProperty(hash)) return;
        switch (hash) {
            case supportedHashes.about:
                setActiveKeyAndScroll(LaunchpadAccordionsKeys.ABOUT, hash);
                break;
            case supportedHashes.metrics:
                setActiveKeyAndScroll(LaunchpadAccordionsKeys.METRICS, hash);
                break;
            case supportedHashes.team:
                setActiveKeyAndScroll(LaunchpadAccordionsKeys.TEAM, hash);
                break;
            case supportedHashes.investment:
                setActiveKeyAndScroll(LaunchpadAccordionsKeys.INVESTMENT, hash);
                break;
            case supportedHashes.faq:
                setActiveKeyAndScroll(LaunchpadAccordionsKeys.FAQ, hash);
                break;
            default:
                setActiveKey(LaunchpadAccordionsKeys.NONE);
                break;
        }
    }, [window.location?.hash]);

    const { data: projectRoadmap } = useQuery({
        queryKey: [LaunchpadQueryKeys.PROJECT_ROADMAP_DETAILS],
        queryFn: () => projectsService.retrieveProjectRoadmapDetails(projectId),
        staleTime: Infinity,
    });

    const {
        data: projectGeneralDetails,
        refetch: retrieveProjectGeneralDetails,
        isLoading: isLoadingProjectGeneralDetails,
    } = useGetProjectGeneralDetails();

    const { data: scShard, refetch: retrieveCurrentEpoch } = useQuery({
        enabled: !!projectGeneralDetails && !!projectGeneralDetails.scAddress,
        queryKey: [LaunchpadQueryKeys.STATS_SC_SHARD],
        queryFn: () => chainDataService.getScShard(projectGeneralDetails?.scAddress as string),
        staleTime: Infinity,
    });

    const retrieveTicketsInfo = (scope?: TicketsScope) => {
        if (scope) {
            SessionStorageService.setItem(SessionStorageKeys.ticketsScope, scope);
        }
        getTicketsInfo();
    };

    const { data: tiersDetails } = useQuery({
        queryKey: [LaunchpadQueryKeys.PROJECT_TIERS_DETAILS],
        queryFn: () => projectsService.retrieveTiersDetails(isLoggedIn && jwtToken ? projectId : undefined),
    });

    const shouldGetKycStatus = useMemo(() => {
        if (!projectGeneralDetails || !jwtToken) return false;
        const stages = projectGeneralDetails.stages;
        return (
            stages.includes(LaunchpadStage.KYC) ||
            stages.includes(LaunchpadStage.SNAPSHOT) ||
            stages.includes(LaunchpadStage.BUY_TICKETS) ||
            stages.includes(LaunchpadStage.WINNER_SELECTION) ||
            stages.includes(LaunchpadStage.CLAIM_TOKENS)
        );
    }, [projectGeneralDetails?.stages, jwtToken, rerender]);

    const { data: { kycStatusInfo, isRejectedFromKyc } = {}, isLoading: isLoadingKycStatus } = useGetKycStatus(
        !!projectGeneralDetails && shouldGetKycStatus,
        projectGeneralDetails?.id as string,
    );

    const shouldGetTicketsInfo = useMemo(() => {
        if (!projectGeneralDetails || !jwtToken) return false;
        const stages = projectGeneralDetails.stages;
        return (
            (stages.includes(LaunchpadStage.BUY_TICKETS) &&
                Date.now() >=
                    DateUtils.toMillisFormat(projectGeneralDetails.generalMetrics.buyTicketsStartTimestamp as number)) ||
            stages.includes(LaunchpadStage.CLAIM_TOKENS)
        );
    }, [projectGeneralDetails?.stages, projectGeneralDetails?.generalMetrics.buyTicketsStartTimestamp, jwtToken, rerender]);

    const {
        data: ticketsInfo,
        refetch: getTicketsInfo,
        isLoading: isLoadingTicketsInfo,
    } = useQuery({
        enabled: !!projectGeneralDetails && shouldGetTicketsInfo,
        queryKey: [LaunchpadQueryKeys.USER_TICKETS],
        queryFn: () => {
            const scope = SessionStorageService.getItem(SessionStorageKeys.ticketsScope);
            return ticketsService.retrieveUserTickets(projectGeneralDetails?.id as string, scope);
        },
        onSettled: () => SessionStorageService.removeItem(SessionStorageKeys.ticketsScope),
    });

    const shouldGetAddressSnapshotInfo = useMemo(() => {
        if (!projectGeneralDetails || !jwtToken) return false;
        const stages = projectGeneralDetails.stages;
        return (
            (stages.includes(LaunchpadStage.SNAPSHOT) ||
                stages.includes(LaunchpadStage.BUY_TICKETS) ||
                stages.includes(LaunchpadStage.WINNER_SELECTION) ||
                stages.includes(LaunchpadStage.CLAIM_TOKENS)) &&
            DateUtils.isPeriodActive(
                Date.now(),
                projectGeneralDetails.generalMetrics.snapshotEndTimestamp,
                projectGeneralDetails.generalMetrics.claimTokensEndTimestamp,
            )
        );
    }, [
        projectGeneralDetails?.stages,
        projectGeneralDetails?.generalMetrics.kycEndTimestamp,
        projectGeneralDetails?.generalMetrics.claimTokensEndTimestamp,
        jwtToken,
        rerender,
    ]);

    const { data: { snapshotInfo, snapshotStakingValue, isRejectedFromSnapshot } = {}, isLoading: isLoadingSnapshotDetails } =
        useQuery({
            enabled: !!projectGeneralDetails && shouldGetAddressSnapshotInfo,
            queryKey: [LaunchpadQueryKeys.USER_SNAPSHOT],
            queryFn: () => launchpadService.retrieveUserSnapshotDetails(projectGeneralDetails?.id as string),
            select: (snapshotDetails) => {
                const tier = snapshotDetails.snapshotData?.tier;
                const isRejected = tier === null;
                return {
                    snapshotInfo: tier,
                    snapshotStakingValue: snapshotDetails.snapshotData?.value,
                    isRejectedFromSnapshot: isRejected,
                };
            },
        });

    const shouldGetScConfig = useMemo(() => {
        if (!projectGeneralDetails) return false;
        const stages = [projectGeneralDetails.stages[1]];
        return (
            Date.now() >= DateUtils.toMillisFormat(projectGeneralDetails.generalMetrics.buyTicketsStartTimestamp as number) &&
            (stages.includes(LaunchpadStage.BUY_TICKETS) ||
                stages.includes(LaunchpadStage.WINNER_SELECTION) ||
                stages.includes(LaunchpadStage.CLAIM_TOKENS))
        );
    }, [projectGeneralDetails?.stages, projectGeneralDetails?.generalMetrics.buyTicketsStartTimestamp, rerender]);

    const { data: scConfig, isLoading: isLoadingScConfig } = useQuery({
        enabled: !!projectGeneralDetails && shouldGetScConfig,
        queryKey: [LaunchpadQueryKeys.PROJECT_SC_CONFIG],
        queryFn: () => launchpadService.getScConfig(projectGeneralDetails?.id as string),
    });

    const shouldGetScFlags = useMemo(() => {
        if (!projectGeneralDetails) return false;
        const stages = [projectGeneralDetails.stages[1]];
        return stages.includes(LaunchpadStage.WINNER_SELECTION);
    }, [projectGeneralDetails?.stages, rerender]);

    const { data: scFlags, isLoading: isLoadingScFlags } = useQuery({
        enabled: !!projectGeneralDetails && shouldGetScFlags,
        queryKey: [LaunchpadQueryKeys.PROJECT_SC_FLAGS],
        queryFn: () => launchpadService.getScFlags(projectGeneralDetails?.id as string),
    });

    const { data: isAddressBlacklisted, isLoading: isLoadingBlacklistAddress } = useCheckUserAddressIsBlacklisted(
        isLoggedIn && !!projectGeneralDetails?.id && shouldGetKycStatus,
    );

    const userLoadingInfo: boolean =
        isLoadingProjectGeneralDetails ||
        isLoadingBlacklistAddress ||
        (shouldGetKycStatus && isLoadingKycStatus) ||
        (shouldGetAddressSnapshotInfo && isLoadingSnapshotDetails) ||
        (shouldGetTicketsInfo && isLoadingTicketsInfo) ||
        (shouldGetScConfig && isLoadingScConfig) ||
        (shouldGetScFlags && isLoadingScFlags);

    return (
        <>
            <MetaDecorator
                title={seoData.launchpad.title}
                description={seoData.launchpad.description}
                keywords={seoData.launchpad.keywords}
                canonical={routeNames.launchpad}
            />
            <BackgroundDecorations />

            <div className="container py-2 py-md-5" id="top">
                <div>
                    <ContainerRow>
                        <Col className="px-1 px-md-5 px-xl-1 mt-2">
                            <Header className="justify-content-center justify-content-sm-start">
                                <PageHeader>Launchpad</PageHeader>
                                <QuestionMarkIcon redirectUrl={modulesLinkList.launchpad} testId="launchpad-question-mark" />
                            </Header>
                        </Col>
                    </ContainerRow>
                    <ContainerRow>
                        <LaunchpadCard launchpadInfo={projectGeneralDetails} showButtonDetails />
                    </ContainerRow>
                    <ContainerRow className="justify-content-center my-0 my-sm-2">
                        {!projectGeneralDetails && <EPlaceholder size="sm" width={3} lineClass="d-flex justify-content-center" />}
                        {projectGeneralDetails && (
                            <SocialMediaButtons
                                buttons={createSMCommonBtnsList(projectGeneralDetails.links, "lg")}
                                className="w-auto"
                            />
                        )}
                    </ContainerRow>

                    <ContainerRow>
                        <InfoCardsBlock
                            metrics={
                                projectGeneralDetails &&
                                generateKeyMetricsFromGeneralKeyMetrics(projectGeneralDetails.generalMetrics)
                            }
                            titleAdditionalInfo={<EText onClick={() => setShowTiersModal(true)}>Eligibility Tiers</EText>}
                            events={projectGeneralDetails?.launchRoadmap}
                            userLoadingInfo={userLoadingInfo}
                            kycStatusInfo={kycStatusInfo}
                            projectGeneralDetails={projectGeneralDetails}
                            currentStage={projectGeneralDetails?.stages[CURRENT_STAGE_INDEX] ?? null}
                            previousStage={projectGeneralDetails?.stages[PREV_STAGE_INDEX] ?? null}
                            tiersDetails={tiersDetails}
                            snapshotInfo={snapshotInfo}
                            snapshotStakingValue={snapshotStakingValue}
                            isRejectedFromKyc={isRejectedFromKyc}
                            ticketsInfo={ticketsInfo}
                            retrieveTicketsInfo={retrieveTicketsInfo}
                            isRejectedFromSnapshot={isRejectedFromSnapshot}
                            scConfig={scConfig}
                            scShard={scShard}
                            scFlags={scFlags}
                            isAddressBlacklisted={isAddressBlacklisted}
                        />
                    </ContainerRow>

                    <ContainerRow className="pt-0">
                        <ContainerCol>
                            <DetailedInformation
                                projectId={projectGeneralDetails?.id}
                                projectName={projectGeneralDetails?.name}
                                tiersDetails={tiersDetails}
                                launchRoadmap={projectGeneralDetails?.launchRoadmap}
                                tokensPerTicket={projectGeneralDetails?.tokensPerTicket}
                                projectRoadmap={projectRoadmap}
                                activeKey={activeKey}
                                onSelect={setActiveKey}
                                currentStage={projectGeneralDetails?.stages[CURRENT_STAGE_INDEX] ?? null}
                            />
                        </ContainerCol>
                    </ContainerRow>
                    <ContainerRow>
                        <SuccessfulLaunches />
                    </ContainerRow>
                </div>
            </div>
            <TiersModal show={showTiersModal} tiersInfo={tiersDetails} closeModalHandler={() => setShowTiersModal(false)} />
        </>
    );
};

export default Launchpad;
