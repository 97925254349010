export class ImageUtils {
    static async toFile(encodedImage: string): Promise<File> {
        const imageType = encodedImage.split(";")[0].split(":")[1];
        const extension = imageType.split("/")[1];
        const image = await fetch(encodedImage);
        const imageBlob = await image.blob();
        return new File([imageBlob], `image.${extension}`, { type: imageType });
    }

    static extractExtensionFromUrl(url: string): string {
        const index = url.lastIndexOf(".");
        if (index !== -1) {
            return url.substring(url.lastIndexOf(".") + 1);
        }
        return url.substring(url.indexOf("/") + 1).split(";")[0];
    }

    public static isImage(url: string): boolean {
        return /\.(jpg|jpeg|png|webp|avif|gif|svg)$/.test(url);
    }

    public static isVideo(url: string): boolean {
        return /\.(mp4|mkv)$/.test(url);
    }

    public static getImage(file: File): Promise<HTMLImageElement> {
        const image = new Image();
        return new Promise((resolve) => {
            image.addEventListener("load", () => resolve(image));
            image.src = URL.createObjectURL(file);
        });
    }
}

export default ImageUtils;
