import React from "react";

const xAliasLogo = () => (
    <svg width="80" height="45" viewBox="0 0 80 24" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path
            d="M8.59812 8.25671L15.5254 4.51897L14.3609 2.25825L8.01747 4.84938C7.85417 4.91623 7.67309 4.91623 7.50979 4.84938L1.16469 2.26005L0 4.51897L6.92745 8.25671L0 11.9944L1.16469 14.2552L7.50796 11.664C7.67126 11.5972 7.85234 11.5972 8.01578 11.664L14.359 14.2552L15.5237 11.9944L8.59629 8.25671H8.59812Z"
            fill="#23f7dd"
        ></path>
        <path d="M44.3181 4.3288V0H47.1793V4.3288H44.3181Z" fill="white"></path>
        <path d="M44.3783 22.1586V7.20465H47.1191V22.1586H44.3783Z" fill="white"></path>
        <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M31.8755 22.1586L30.1889 18.405H21.3943L19.7077 22.1586H16.9368L24.6472 5.05532H27.1169L34.8272 22.1586H31.8755ZM22.5388 15.9227H29.0746L25.7917 8.68789L22.5388 15.9227Z"
            fill="white"
        ></path>
        <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M64.6065 19.8278C63.6427 19.7369 63.2812 19.3131 63.2812 18.2839V12.7443C63.2812 9.26304 60.7815 6.81101 56.4745 6.81101C52.3784 6.81101 50.3906 9.32359 49.9991 11.1096H52.77C53.0411 10.6253 54.0048 9.23283 56.4745 9.23283C58.9141 9.23283 60.661 10.5042 60.661 12.7745H55.571C51.8664 12.7745 49.5774 14.4092 49.5774 17.4363C49.5774 20.5241 52.2881 22.4917 55.842 22.4917C58.4925 22.4917 60.0586 21.0992 60.7815 19.9791C61.173 21.4624 62.4681 22.2495 64.6065 22.31V19.8278ZM60.661 16.4979C60.661 18.9801 58.4925 20.191 56.0829 20.191C53.9747 20.191 52.3182 19.1618 52.3182 17.4363C52.3182 15.8016 53.6735 14.9541 55.6914 14.9541H60.661V16.4979Z"
            fill="white"
        ></path>
        <path
            d="M74.6089 15.9531C74.0883 15.8935 73.684 15.8481 73.3064 15.8057L73.306 15.8056C72.7224 15.7401 72.2026 15.6817 71.4163 15.5898C68.5549 15.2568 66.5371 13.8643 66.5371 11.1399C66.5371 8.50624 69.0067 6.7808 72.6511 6.7808C77.6507 6.7808 79.3373 9.38413 79.7891 10.8977H76.8677C76.5665 10.5042 75.3919 9.14194 72.6511 9.14194C70.4224 9.14194 69.2477 9.95928 69.2477 11.049C69.2477 12.1086 70.0308 12.7745 71.7777 12.9865C72.4716 13.0758 73.0079 13.1335 73.5976 13.197C74.0061 13.2411 74.4403 13.2878 74.9702 13.3496C78.464 13.7735 80 15.3476 80 18.072C80 20.5846 77.711 22.5824 73.4944 22.5824C68.2537 22.5824 66.3865 19.3736 66.0853 17.9811H69.0067C69.1875 18.4353 70.332 20.1608 73.4944 20.1608C76.0244 20.1608 77.2592 19.2527 77.2592 18.072C77.2592 16.9217 76.5967 16.1951 74.6089 15.9531Z"
            fill="white"
        ></path>
        <path
            d="M41.8017 22.3002C38.1948 22.3002 36.5553 20.5578 36.5553 17.4569V2.39938H39.2679V17.073C39.2679 18.904 40.1324 19.8785 42.0997 19.8785V22.3002H41.8017Z"
            fill="white"
        ></path>
    </svg>
);

export default xAliasLogo;
