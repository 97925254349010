import React, { Dispatch, SetStateAction, Suspense, useEffect, useState } from "react";
import { AuthenticatedRoutesWrapper } from "@multiversx/sdk-dapp/wrappers";
import { NotificationModal, SignTransactionsModals, TransactionsToastList } from "@multiversx/sdk-dapp/UI";
import { ContextProvider } from "../../context";
import { Outlet, useLocation, useNavigate } from "react-router-dom";
import routes, { routeNames } from "../../routes/routes";
import { Footer } from "./Footer";
import { Header, SideNavbar } from "./Navbar";
import { maintenanceMode } from "../../config";
import Maintenance from "../../pages/Maintenance/Maintenance";
import CheckAuth from "./checkAuth";
import SessionStorageService from "../../services/sessionStorageService/SessionStorageService";
import { SessionStorageKeys } from "../../services/sessionStorageService/sessionStorageKeys";
import { TopNavbar } from "./Navbar/TopNavbar";
import { Banner } from "../Banner";
import { LocalStorageKeys } from "../../services/localStorageService/LocalStorageKeys";
import styled from "styled-components";
import { layoutContainer } from "./styles";
import ScrollToTop from "../../common/components/ScrollToTop";
import { ESpinner } from "../Spinner";
import { useGetAccountInfo, useGetLoginInfo, useGetSignedTransactions } from "@multiversx/sdk-dapp/hooks";
import checkSignedTransaction from "../../common/hooks/checkSignedTransaction";
import { SignedTransactionsBodyType } from "@multiversx/sdk-dapp/types";
import { useService } from "../../services/config/dependencyInjectorConfig";
import LdmService from "../../services/LdmService";
import TxPostProcessingService from "../../services/TxPostProcessingService";
import SpinnerOverlay from "./SpinnerOverlay";
import EventsService from "../../services/EventsService";
import NewNavbar from "./Navbar/NewTopBavbar/NewNavbar";

export type SidebarDisplaying = [boolean, Dispatch<SetStateAction<boolean>>];

const Container = styled.div`
    ${layoutContainer};
`;

const StyledMain = styled.main`
    z-index: auto;
    font-family: var(--entity-font-family);
    margin-top: 120px;
`;

const Layout = () => {
    const [isSidebarDisplayed, sidebarDisplayingToggle] = useState(false);
    const [isInformedAboutConditions, setIsInformedAboutConditions] = useState(
        localStorage.getItem(LocalStorageKeys.isInformedAboutConditions) === "true",
    );
    const { search } = useLocation();
    const navigate = useNavigate();
    document.body.classList.add("dark");
    document.body.classList.remove("light");
    const loggedInfo = useGetLoginInfo();
    const accountInfo = useGetAccountInfo();
    const [accountLoading, setAccountLoading] = useState<boolean>(false);

    const [ldmService] = useService(LdmService);
    const [eventsService] = useService(EventsService);
    const signedTransactionsState = useGetSignedTransactions();

    useEffect(() => {
        checkSignedTransaction(signedTransactionsState, {
            onSuccessCallback: async (txSigned: SignedTransactionsBodyType) => {
                TxPostProcessingService.postProcessTx(txSigned, ldmService, eventsService);
            },
        });
    }, [signedTransactionsState]);

    useEffect(() => {
        if (accountInfo.isAccountLoading) {
            setAccountLoading(accountInfo.isAccountLoading);
        } else if (loggedInfo.isLoggedIn) {
            setTimeout(() => {
                setAccountLoading(accountInfo.isAccountLoading);
            }, 500);
        } else {
            setAccountLoading(false);
        }
    }, [accountInfo.isAccountLoading]);

    const onConfirmSeeingConditions = () => {
        localStorage.setItem(LocalStorageKeys.isInformedAboutConditions, "true");
        setIsInformedAboutConditions(true);
    };

    const handleSidebarDisplaying = () => {
        sidebarDisplayingToggle(false);
    };

    useEffect(() => {
        setLocationToLoginRoute();
    }, []);

    if (maintenanceMode) {
        return <Maintenance />;
    }

    const setLocationToLoginRoute = () => {
        if (location.pathname !== routeNames.unlock) {
            SessionStorageService.setItem(SessionStorageKeys.loginRoute, location.pathname);
        }
    };

    const saveLocationOnRedirect = (event: string | undefined) => {
        setLocationToLoginRoute();
        navigate(event ?? routeNames.home, { replace: true });
    };

    return (
        <Container
            className={"d-flex flex-column flex-fill " + location.pathname.substring(1)}
            onClick={() => handleSidebarDisplaying()}
        >
            <ScrollToTop />
            <SpinnerOverlay loading={accountLoading} />
            <ContextProvider>
                <AuthenticatedRoutesWrapper
                    routes={routes}
                    unlockRoute={`${routeNames.unlock}${search}`}
                    onRedirect={saveLocationOnRedirect}
                >
                    <CheckAuth>
                        <NewNavbar />

                        <StyledMain className="d-flex flex-column flex-grow-1">
                            <TransactionsToastList />
                            <NotificationModal />
                            <SignTransactionsModals className="custom-class-for-modals" />
                            <Suspense fallback={<ESpinner />}>
                                <Outlet />
                            </Suspense>
                        </StyledMain>

                        {!location.pathname.includes("/admin") && <Footer />}
                    </CheckAuth>
                </AuthenticatedRoutesWrapper>
            </ContextProvider>
            {!isInformedAboutConditions && <Banner onConfirmSeeingConditions={onConfirmSeeingConditions} />}
        </Container>
    );
};

export default Layout;
