import BaseService from "./BaseService";
import axios from "axios";
import { TokensDetailedEntity } from "../pages/Ico/utils/tokens.dto";

export class TokensQueryParams {
    from?: number | undefined = undefined;
    size?: number | undefined = undefined;
    search?: string | undefined = undefined;
    sort?: string | undefined = undefined;
    order?: string | undefined = undefined;
}

export class TokensService extends BaseService {
    private readonly _apiAddress: string;

    constructor(apiAddress: string) {
        super();
        this._apiAddress = apiAddress;
    }

    public async getTokensList(queryParams: TokensQueryParams): Promise<TokensDetailedEntity[]> {
        return this.doCall(() => axios.get<TokensDetailedEntity[]>(`${this._apiAddress}/tokens`, { params: queryParams }));
    }

    public async getTokenDetails(tokenIdentifier: string): Promise<TokensDetailedEntity | undefined> {
        return this.doCall(() => axios.get<TokensDetailedEntity | undefined>(`${this._apiAddress}/tokens/${tokenIdentifier}`));
    }
}

export default TokensService;
