import React from "react";
import { Card } from "react-bootstrap";
import { faDownload } from "@fortawesome/free-solid-svg-icons";
import EHashLink from "../../Buttons/HashLink";
import { routeNames } from "../../../routes/routes";
import styled, { css } from "styled-components";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { BREAKPOINTS } from "../../../assets/sass/breakpoints";
import ECountDown from "../../../common/components/ECountDown/ECountDown";
import EventsService, { EventsNames } from "../../../services/EventsService";
import { useService } from "../../../services/config/dependencyInjectorConfig";
import { ProjectGeneralDetails } from "../../../pages/Launchpad/launchpad.types";
import LitepaperButton from "./LitepaperButton";
import { EPlaceholder } from "../../Placeholder";
import { DateUtils } from "../../../common";
import { EButton, eButtonStyles } from "../../Buttons";

const BtnRedirect = styled(EButton)`
    margin-left: 0px !important;
`;

const CardFooter = styled.div`
    margin-left: 0;
    margin-right: 0;
`;

const oneThirdPart = css`
    width: 100%;

    @media (${BREAKPOINTS.desktop}) {
        width: 33%;
    }
`;

const BtnContainer = styled.div`
    padding-left: 2.142rem !important;
    padding-right: 2.142rem !important;

    ${oneThirdPart};

    > button {
        &:not(:first-child) {
            margin-left: 1.071rem;
        }
    }
`;

const LaunchpadStatus = styled.div`
    ${oneThirdPart};
    display: flex;
    justify-content: center;
`;

export const FontAwesomeIconCustom = styled(FontAwesomeIcon)`
    margin-right: 0.714rem;
`;

const ProjectLaunchQuarterInfo = styled.div`
    .header {
        font-size: 1.285rem;
    }

    .quarter-info {
        font-size: 2rem;

        @media (${BREAKPOINTS.desktop}) {
            font-size: 3.428rem;
        }
    }
`;

const FooterCard = ({
    showButtonDetails,
    launchpadInfo,
}: {
    showButtonDetails: boolean;
    launchpadInfo?: ProjectGeneralDetails;
}) => {
    const [eventsService] = useService(EventsService);

    const getLaunchpadStatus = () => {
        // TODO: consider more thoughtful placeholders instead of that simple one
        if (!launchpadInfo) {
            return <EPlaceholder width={6} />;
        }

        if (
            launchpadInfo.generalMetrics?.claimTokensEndTimestamp &&
            DateUtils.toMillisFormat(launchpadInfo.generalMetrics?.claimTokensEndTimestamp) < Date.now()
        ) {
            return <h3 className="text-center py-5 py-md-3">Public Sale Ended</h3>;
        }
        if (launchpadInfo.generalMetrics?.kycStartTimestamp && launchpadInfo.generalMetrics?.kycStartTimestamp > 0) {
            return (
                <ECountDown
                    className="mt-5"
                    time={launchpadInfo.generalMetrics?.kycStartTimestamp}
                    text="KYC START"
                    endedText="Public Sale Started"
                    onCompleteHandler={() =>
                        DateUtils.toMillisFormat(launchpadInfo.generalMetrics?.kycStartTimestamp as number) > Date.now() &&
                        eventsService.getEmitter().emit(EventsNames.RERENDER_LAUNCHPAD)
                    }
                />
            );
        } else if (launchpadInfo.generalMetrics?.kycStartQuarter) {
            return (
                <ProjectLaunchQuarterInfo>
                    <h1 className="header text-center py-3">Project launch</h1>
                    <p className="quarter-info">{launchpadInfo.generalMetrics.kycStartQuarter}</p>
                </ProjectLaunchQuarterInfo>
            );
        } else {
            return <h3 className="text-center py-3">To be announced</h3>;
        }
    };

    return (
        <Card.Body className="px-0 launchpad-card-footer d-flex ms-1 me-1">
            <CardFooter className="m-0 align-items-center w-100 d-flex flex-column flex-xl-row">
                {showButtonDetails && (
                    <BtnContainer className="d-flex flex-row justify-content-center justify-content-xl-start">
                        <LitepaperButton showPlaceholder={!launchpadInfo} litepaper={launchpadInfo?.links.litepaper} />
                        {launchpadInfo?.links.tokenomics && (
                            <BtnRedirect>
                                <a href={launchpadInfo?.links.tokenomics} target="_blank" className="text-white">
                                    <FontAwesomeIconCustom icon={faDownload} />
                                    Tokenomics
                                </a>
                            </BtnRedirect>
                        )}

                        {!launchpadInfo?.links.tokenomics && (
                            <EHashLink
                                color="#fff"
                                className="text-capitalize"
                                to={routeNames.launchpadMetricsTab}
                                testId="tokenomics"
                                target="_blank"
                            >
                                <FontAwesomeIconCustom icon={faDownload} />
                                Tokenomics
                            </EHashLink>
                        )}
                    </BtnContainer>
                )}
                <LaunchpadStatus>{getLaunchpadStatus()}</LaunchpadStatus>
            </CardFooter>
        </Card.Body>
    );
};

export default FooterCard;
