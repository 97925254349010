import BaseService from "./BaseService";
import { EventEmitter } from "events";

export enum EventsNames {
    RERENDER_LAUNCHPAD = "rerender-launchpad",
    REFETCH_PUBLIC_SALE = "refetch-public-sale",
}

class EventsService extends BaseService {
    private eventEmitter: EventEmitter | null = null;

    getEmitter(): EventEmitter {
        if (!this.eventEmitter) {
            this.eventEmitter = new EventEmitter();
        }
        return this.eventEmitter as EventEmitter;
    }

    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    emitWithDelay(event: string, delay = 1_000, ...args: any[]): void {
        setTimeout(() => this.getEmitter().emit(event, args), delay);
    }
}

export default EventsService;
