import React from "react";
import { DateUtils, FormDateRangePickerGroup } from "../index";
import { EButton } from "../../components/Buttons";
import { FormGroupProps, TRangeDate } from "./types";
import FormControlledInputGroup, { FormControlGroupProps } from "./FormControlledInputGroup";
import styled, { css } from "styled-components";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCalendarDays, faQ } from "@fortawesome/free-solid-svg-icons";
import { DatePickerComponent } from "@syncfusion/ej2-react-calendars";
import { DATE_FORMAT_US } from "../../helpers/consts";
import QuarterYearSelectGroup from "./QuarterSelectGroup";

interface DateQuarterProps<T> extends FormGroupProps<T>, Pick<FormControlGroupProps<T>, "control"> {
    value: TRangeDate;
    showDatePicker: boolean;
    switcherBtnClick: () => void;
    datePickerName: string;
    datePickerLabel: string;
    quarterSelectorName: string;
    quarterSelectorLabel: string;
    range?: boolean;
    quarterYears?: number;
}

const Container = styled.div`
    align-items: end;
`;

const FormDateRangePickerGroupStyled = styled(FormDateRangePickerGroup)`
    width: 100%;
`;

const btnCommonStyles = css`
    align-self: unset;
    margin-left: 0.5rem;
    min-width: unset !important;
    margin-top: 2.3rem;
    height: 42px;
`;

const SwitchToQuartersBtn = styled(EButton)`
    ${btnCommonStyles};
`;

const SwitchToDatePickerBtn = styled(EButton)`
    ${btnCommonStyles};
`;

const InnerContainer = styled.div`
    justify-content: space-between;
    display: flex;
`;

function DateQuarterGroup<T>(props: DateQuarterProps<T>): JSX.Element {
    const {
        datePickerName,
        datePickerLabel,
        quarterSelectorName,
        quarterSelectorLabel,
        value,
        className = "flex-column",
        control,
        register,
        formState,
        showDatePicker = true,
        range = true,
        quarterYears = 4,
        switcherBtnClick,
    } = props;

    value.quarterInfo = value.quarterInfo ?? { quarter: undefined, year: undefined };

    return (
        <Container>
            {showDatePicker && (
                <InnerContainer>
                    {range && (
                        <FormDateRangePickerGroupStyled
                            name={datePickerName}
                            label={datePickerLabel}
                            value={value}
                            className={className}
                            register={register}
                            control={control}
                            formState={formState}
                        />
                    )}
                    {!range && (
                        <FormControlledInputGroup
                            name={datePickerName}
                            label={datePickerLabel}
                            register={register}
                            control={control}
                            className={className}
                            formState={formState}
                            render={({ onChange }) => (
                                <DatePickerComponent
                                    name={datePickerName}
                                    placeholder="Select date"
                                    allowEdit={false}
                                    value={value.start ? DateUtils.convertToDate(value.start) : undefined}
                                    change={(date) =>
                                        onChange({
                                            start: DateUtils.toDateString(date.value),
                                            end: "",
                                            quarter: undefined,
                                        })
                                    }
                                    format={DATE_FORMAT_US}
                                    openOnFocus
                                    showClearButton
                                    showTodayButton
                                />
                            )}
                        />
                    )}
                    <div className="date-quarter-switcher-container">
                        <SwitchToQuartersBtn onClick={switcherBtnClick}>
                            <FontAwesomeIcon icon={faQ} />
                        </SwitchToQuartersBtn>
                    </div>
                </InnerContainer>
            )}

            {!showDatePicker && (
                <InnerContainer className="d-flex">
                    <QuarterYearSelectGroup
                        name={quarterSelectorName}
                        label="QuarterYearSelectGroup"
                        quarterSelectorName={`${quarterSelectorName}.quarter`}
                        quarterSelectorLabel={`${quarterSelectorLabel} Quarter:`}
                        yearSelectorName={`${quarterSelectorName}.year`}
                        yearSelectorLabel={`${quarterSelectorLabel} Year:`}
                        value={value.quarterInfo ?? {}}
                        quarterYears={quarterYears}
                        control={control}
                        formState={formState}
                        register={register}
                        className="w-100"
                    />

                    <div className="date-quarter-switcher-container">
                        <SwitchToDatePickerBtn onClick={switcherBtnClick}>
                            <FontAwesomeIcon icon={faCalendarDays} />
                        </SwitchToDatePickerBtn>
                    </div>
                </InnerContainer>
            )}
        </Container>
    );
}

export default DateQuarterGroup;
