import moment from "moment";
import { DATE_FORMAT_FULL, DATE_FORMAT_MM_DD_YYYY_MOMENT_JS, DATE_FORMAT_MOMENT_JS } from "../../helpers/consts";
import { getCurrentTimestampMillis } from "../../helpers/util.functions";
import { TQuarterInfo } from "../form/types";
import { Quarters } from "../../pages/Ico/ico.types";
import StringUtils from "./StringUtils";

const ONE_HOUR_IN_MILLIS = 60 * 60 * 1_000;

class DateUtils {
    public static toStartDayTimestamp(date: Date): number {
        return moment(date).set({ hour: 0, minute: 0, second: 0, millisecond: 0 }).unix();
    }

    public static toStartDayTimestampWithMillis(date: Date): number {
        return DateUtils.toMillisFormat(DateUtils.toStartDayTimestamp(date));
    }

    public static toEndDayTimestamp(date: Date): number {
        return moment(date).set({ hour: 23, minute: 59, second: 59, millisecond: 999 }).unix();
    }

    public static toEndDayTimestampWithMillis(date: Date): number {
        return DateUtils.toMillisFormat(DateUtils.toEndDayTimestamp(date));
    }

    public static toMillisFormat(timestamp: number): number {
        return String(timestamp).length < 13 ? timestamp * 1_000 : timestamp;
    }

    public static toDateLabel(timestamp: number, format = "MMM DD, YYYY"): string {
        return moment.unix(timestamp).format(format);
    }

    public static birthDateToDateLabel(birthDate: number): string {
        return birthDate === 0 || !birthDate ? "" : DateUtils.toDateLabel(birthDate, DATE_FORMAT_MM_DD_YYYY_MOMENT_JS);
    }

    public static toDateLabelUsFormat(timestamp: number | undefined): string | undefined {
        if (!timestamp) {
            return undefined;
        }

        return moment.unix(timestamp).format(DATE_FORMAT_MM_DD_YYYY_MOMENT_JS);
    }

    public static toDateString(date: Date, format = DATE_FORMAT_MM_DD_YYYY_MOMENT_JS): string {
        return moment(date).format(format);
    }

    public static dateTimeToUtcTimestampMillis(date: Date, keepLocalTime = false): number {
        return moment(date).utc(keepLocalTime).unix() * 1_000;
    }

    public static dateTimeToUtcTimestamp(date: Date, keepLocalTime = false): number {
        return moment(date).utc(keepLocalTime).unix();
    }

    public static utcTimestampMillisToDate(utcTimestamp?: number | string): Date | undefined {
        if (!utcTimestamp || utcTimestamp === "") return undefined;
        const localTimestamp = +utcTimestamp + new Date().getTimezoneOffset() * 60 * 1_000;
        return new Date(localTimestamp);
    }

    public static toDateLabelWithMillis(timestamp: number): string {
        return DateUtils.toDateLabel(Math.floor(timestamp / 1_000));
    }

    public static toFormat(timestamp: number, format = "DD MMM YY HH:mm"): string {
        return moment(timestamp).format(format);
    }

    public static toFormatFromSeconds(timestamp: number | undefined, format = DATE_FORMAT_FULL): string {
        return timestamp ? moment(timestamp * 1_000).format(format) : StringUtils.DASH_VALUE;
    }

    public static toDate(timestamp: number): Date {
        return moment.unix(timestamp).toDate();
    }

    public static getOneMonthBeforeTimestamp(): number {
        return moment().subtract(1, "month").set({ hour: 0, minute: 0, second: 0, millisecond: 0 }).unix();
    }

    public static getTimestampForHoursAgo(hours: number) {
        return moment().subtract(hours, "hours").toDate().getTime() / 1_000;
    }

    public static convertToOptionalDate(dateString: Date | string | undefined): Date | undefined {
        if (dateString === undefined || dateString === null || dateString === "") {
            return undefined;
        }
        return DateUtils.convertToDate(dateString);
    }

    public static convertToDate(dateInput: Date | string | number, format = DATE_FORMAT_MM_DD_YYYY_MOMENT_JS): Date | undefined {
        return dateInput instanceof Date ? (dateInput as Date) : moment(dateInput, format).toDate();
    }

    public static isDateStringBefore(
        startDateString: string,
        endDateString: string,
        format = DATE_FORMAT_MM_DD_YYYY_MOMENT_JS,
    ): boolean {
        return moment(startDateString, format).isBefore(moment(endDateString, format));
    }

    public static toNowUnixTimestamp(): number {
        return moment(Date.now()).unix();
    }

    public static timestampToDateFormatted(timestamp: number, format: string = DATE_FORMAT_MOMENT_JS) {
        return moment(new Date(timestamp * 1000)).format(format);
    }

    public static timestampToDateFormattedMillis(timestamp: number, format: string = DATE_FORMAT_MOMENT_JS) {
        return moment(new Date(timestamp)).format(format);
    }

    public static toOptionalTime(timestamp?: number): number {
        return !timestamp ? 0 : new Date(timestamp).getTime();
    }

    public static isPeriodActive(now: number, startPeriod?: number, endPeriod?: number): boolean {
        return (
            !!startPeriod &&
            !!endPeriod &&
            DateUtils.toMillisFormat(startPeriod) <= now &&
            now <= DateUtils.toMillisFormat(endPeriod)
        );
    }

    public static getPeriodStatusFlags(startTimestamp?: number, endTimestamp?: number): [boolean, boolean] {
        const now = Date.now();
        const hasStarted = startTimestamp && DateUtils.toMillisFormat(startTimestamp) <= now;
        const hasFinished = endTimestamp && DateUtils.toMillisFormat(endTimestamp) <= now;
        return [!!hasStarted, !!hasFinished];
    }

    public static millisToAgoLabel(millis: number): string {
        const now = moment(getCurrentTimestampMillis());
        const secs = now.diff(millis, "seconds");
        if (secs < 60) return `${secs} seconds ago`;
        const min = now.diff(millis, "minutes");
        if (min < 60) return `${min} minutes ago`;
        const h = now.diff(millis, "hours", true);
        if (h < 24) return `${h.toFixed(1)} hours ago`;
        const d = now.diff(millis, "days", true);
        if (d < 32) return `${d.toFixed(1)} days ago`;
        const m = now.diff(millis, "months", true);
        if (m < 12) return `${m.toFixed(1)} months ago`;
        const y = now.diff(millis, "years", true);
        return `${y.toFixed(1)} years ago`;
    }

    public static generateQuarterInfo = (quarterInfoStr?: string): TQuarterInfo | undefined => {
        if (!quarterInfoStr) {
            return undefined;
        }
        const quarterParts = quarterInfoStr.split(" ");
        if (
            !quarterParts ||
            quarterParts.length !== 2 ||
            !Object.keys(Quarters).includes(quarterParts[0]) ||
            Number(quarterParts[1]) <= 0
        ) {
            return undefined;
        }

        return {
            quarter: quarterParts[0] as Quarters,
            year: quarterParts[1],
        };
    };

    static getMinutesAgoTimestamp(noOfMinutes: number): number {
        return Date.now() / 1000 - noOfMinutes * 60;
    }

    public static getQuarterInfoAsString = (quarterInfo?: TQuarterInfo): string => {
        if (
            !quarterInfo ||
            !quarterInfo.quarter ||
            !quarterInfo.year ||
            quarterInfo.quarter.toLowerCase() === "none" ||
            quarterInfo.year.toLowerCase() === "none"
        ) {
            return "";
        }
        return quarterInfo.quarter + " " + quarterInfo.year;
    };

    public static hoursToMillis(hours: number): number {
        return hours * ONE_HOUR_IN_MILLIS;
    }
}

export default DateUtils;
