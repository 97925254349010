import React, { FC } from "react";
import CardIcon from "../Card/CardIcon";
import { Card } from "react-bootstrap";
import { ELinkButton } from "../Buttons";
import styled from "styled-components";
import SocialMediaButtons, { SocialMediaButton } from "../Buttons/SocialMediaButtons";
import ProjectLabel from "./ProjectLabel";
import ProjectInfoTable, { LaunchInfo } from "./ProjectInfoTable";
import { ReactComponent as EgldSymbol } from "../../assets/img/elrond-symbol.svg";
import { ReactComponent as MyLivn } from "../../assets/img/mylivn.svg";
import { LaunchPlatforms } from "../../helpers/consts";
import { DisplayMode, Rating } from "../../common/components/Rating/Rating";
import LinkUtils from "../../common/utils/LinkUtils";
import { NumberUtils } from "../../common";

const LaunchPlatformInfo = {
    [LaunchPlatforms.Entity]: {
        name: LaunchPlatforms.Entity,
        color: "var(--entity-green)",
        label: "launchpad",
        icon: <EgldSymbol />,
    },
    [LaunchPlatforms.Maiar]: {
        name: "MultiversX",
        color: "var(--maiar-color)",
        label: "xLaunchpad",
        icon: <MyLivn />,
    },
    [LaunchPlatforms.Elrond]: {
        name: "MultiversX",
        color: "var(--elrond-color)",
        label: "ecosystem",
        icon: <EgldSymbol />,
    },
};

interface ProjectCardProps {
    icoId: string;
    icon?: JSX.Element;
    name: string;
    description?: string;
    socialMedia?: SocialMediaButton[];
    launchPlatform?: LaunchPlatforms;
    launchInfo?: LaunchInfo;
    rating?: number;
    className?: string;
    testId?: string;
    linkUrl: string;
}

type PartialBy<T, K extends keyof T> = Omit<T, K> & Partial<Pick<T, K>>;
type ProjectCardById = PartialBy<ProjectCardProps, "linkUrl">;
type ProjectCardByLink = PartialBy<ProjectCardProps, "icoId">;

const StyledCard = styled(Card)`
    overflow: visible;
    width: 100%;
    border: 2px solid #00000029 !important;
    backdrop-filter: blur(0.7rem);
    -webkit-backdrop-filter: blur(0.7rem);
    -moz-backdrop-filter: blur(0.7rem);
    padding-left: 0;
    padding-right: 0;

    &:first-child {
        margin-left: 0;
    }

    &:last-child {
        margin-right: 0;
    }

    .card-body {
        padding-left: 0;
        padding-right: 0;
    }
`;

const StyledTitle = styled(Card.Title)`
    margin-bottom: 0.642rem;
`;

const StyledDescription = styled(Card.Text)`
    margin-bottom: 0.142rem;
    font-size: 1.143rem !important;
    line-height: 2.143rem !important;
    color: #c9c9c9 !important;
`;

const StyledRating = styled(Rating)`
    line-height: 100%;
`;

const StyledSocialMediaLinks = styled(SocialMediaButtons)`
    &&&:hover {
        color: var(--light-grey) !important;
    }
`;

const StyledButton = styled(ELinkButton)`
    max-width: 7.143rem;
    padding-left: 2rem !important;
    padding-right: 2rem !important;
`;

const ProjectCard: FC<React.PropsWithChildren<ProjectCardById | ProjectCardByLink>> = ({
    icoId,
    icon,
    name,
    description,
    socialMedia = [],
    launchPlatform = LaunchPlatforms.Entity,
    launchInfo = [],
    rating = 0,
    className = "",
    testId = "project-card",
    linkUrl,
    ...props
}) => {
    const { color, label } = LaunchPlatformInfo[launchPlatform];
    const launchPlatformIcon = LaunchPlatformInfo[launchPlatform].icon;

    return (
        <StyledCard className={`entityCard ${className}`} data-testid={testId} {...props}>
            <ProjectLabel bgColor={color} platform={LaunchPlatformInfo[launchPlatform].name as LaunchPlatforms} label={label} />
            <CardIcon icon={icon || launchPlatformIcon} iconColor={color} shadowColor={color} />
            <Card.Body className="d-flex flex-column justify-content-between">
                <div className="d-flex flex-column h-100 justify-content-between">
                    <div>
                        <StyledTitle className="text-center">{name}</StyledTitle>
                        <StyledDescription className="text-center">{description ?? <span>&nbsp;</span>}</StyledDescription>
                    </div>

                    <div className="w-100 d-flex justify-content-center">
                        <StyledRating
                            ratingValue={0}
                            initialValue={rating}
                            iconsCount={5}
                            size={17}
                            readonly={true}
                            allowHalfIcon={true}
                            fillColor="white"
                            emptyColor="#708787"
                            data-testid="rating"
                            showTooltip={true}
                            showTitle={false}
                            displayMode={DisplayMode.LabelRight}
                            tooltipText={NumberUtils.toNonUSA(rating.toFixed(2))}
                        />
                    </div>
                </div>
                <ProjectInfoTable data={launchInfo} />
                <div className="d-flex justify-content-center mb-3">
                    <StyledSocialMediaLinks color={"#708787"} buttons={socialMedia.slice(0, 6)} />
                </div>
                <StyledButton
                    borderColor="#4DC2F94D"
                    to={icoId ? LinkUtils.buildIcoDetailsLink(icoId, name) : linkUrl}
                    className="m-auto text-uppercase"
                    testId="more"
                >
                    More
                </StyledButton>
            </Card.Body>
        </StyledCard>
    );
};

export default ProjectCard;
