import React from "react";
import { AdditionalInfoCard } from "../AdditionalInfoCard";
import { EPlaceholder } from "../../../../components/Placeholder";

const Link = ({ title, hrefAttr, testId = "link" }: { title: string; hrefAttr: string; testId?: string }) => (
    <a className="text-green d-block" rel="noreferrer" target="_blank" href={hrefAttr} data-testid={testId}>
        {title}
    </a>
);

const Links = ({
    website,
    whitepaper,
    litepaper,
    showPlaceholder = false,
}: {
    website?: string;
    whitepaper?: string | null;
    litepaper?: string | null;
    showPlaceholder?: boolean;
}) => (
    <AdditionalInfoCard>
        <h4>Related Links</h4>
        {showPlaceholder ? (
            <EPlaceholder numberOfLines={3} />
        ) : (
            <>
                {website && <Link title="Website" hrefAttr={website} testId="website" />}
                {whitepaper && <Link title="Whitepaper" hrefAttr={whitepaper} testId="whitepaper" />}
                {litepaper && <Link title="Litepaper" hrefAttr={litepaper} testId="litepaper" />}
            </>
        )}
    </AdditionalInfoCard>
);

export default Links;
