import React from "react";
import styled from "styled-components";
import { BREAKPOINTS } from "../../../assets/sass/breakpoints";

interface SphereDescriptionProps {
    title: string;
    description: string;
}

const Title = styled.div`
    color: var(--page-block-title-color);
    margin-bottom: 1.428rem;
    font-size: 1.3rem;
`;

const DescriptionText = styled.div`
    font-size: 1rem;
    line-height: 1.571rem;
    letter-spacing: 0;
    color: white;

    @media (${BREAKPOINTS.tablet}) {
        font-size: 1.1rem;
        line-height: 1.857rem;
    }
`;

const Container = styled.div`
    width: 60%;
    margin-top: 2rem;
    margin-left: 6rem;
    overflow: hidden;

    @media (${BREAKPOINTS.tablet}) {
        margin-top: 1rem;
        margin-left: 8rem;
    }

    @media (${BREAKPOINTS.xxlDesktop}) {
        margin-top: 4.5rem;
        margin-left: 9.5rem;
    }
`;

const Description = ({ title, description }: SphereDescriptionProps) => {
    return (
        <Container>
            <Title>{title}</Title>
            <DescriptionText>{description}</DescriptionText>
        </Container>
    );
};

export default Description;
