import { IcoLayers, LaunchPlatforms, SocialLinks } from "../../helpers/consts";
import { TokenWithBalance } from "./utils/tokens.dto";

export enum Quarters {
    Q1 = "Q1",
    Q2 = "Q2",
    Q3 = "Q3",
    Q4 = "Q4",
}

export enum IcoStatus {
    new = "new",
    pending = "pending",
    approved = "approved",
    declined = "declined",
}

export interface IcoStep {
    step: number;
    changeStep: (arg: number) => void;
}

export interface LaunchInfo {
    icoLayer?: IcoLayers;
    tokenName?: string;
    maxTokenSupply?: number;
    preSaleStartTimestamp?: number;
    preSaleStartQuarter?: string;
    publicSaleStartTimestamp?: number;
    publicSaleStartQuarter?: string;
    publicSalePrice?: number | null;
    hasKyc?: boolean;
}

export interface LaunchPlatform {
    launchPlatform: LaunchPlatforms;
}

export interface Name {
    name: string;
}

export interface ProjectId {
    id: string;
}

export interface IcoId {
    icoId: string;
}

export interface ShortDescription {
    shortDescription: string;
}

export interface Slogan {
    slogan: string;
}

export interface IcoLayer {
    icoLayer: IcoLayers;
}

export interface TokenName {
    tokenName: string;
}

export interface MaxTokenSupply {
    maxTokenSupply: number;
}

export interface PublicSalePrice {
    publicSalePrice: number | null;
}

export interface HasKyc {
    hasKyc: boolean;
}

export interface KycStartTimestamp {
    kycStartTimestamp?: number;
}

export interface KycEndTimestamp {
    kycEndTimestamp?: number;
}

export interface PreSaleStartTimestamp {
    preSaleStartTimestamp?: number;
}

export interface PublicSaleStartTimestamp {
    publicSaleStartTimestamp?: number;
}

export interface PublicSaleStartQuarter {
    publicSaleStartQuarter?: string | null;
}

export interface PreSaleStartQuarter {
    preSaleStartQuarter?: string | null;
}

export interface KycStartQuarter {
    kycStartQuarter?: string | null;
}

export interface TradableStartQuarter {
    tradableStartQuarter?: string | null;
}

export interface SnapshotStartQuarter {
    snapshotStartQuarter?: string | null;
}

export interface BuyTicketsStartQuarter {
    buyTicketsStartQuarter?: string | null;
}

export interface WinnerSelectionStartQuarter {
    winnerSelectionStartQuarter?: string | null;
}

export interface ClaimTokensStartQuarter {
    claimTokensStartQuarter?: string | null;
}

export interface KycStartTimestamp {
    kycStartTimestamp?: number;
}

export interface KycEndTimestamp {
    kycEndTimestamp?: number;
}

export interface SnapshotStartTimestamp {
    snapshotStartTimestamp?: number;
}

export interface SnapshotEndTimestamp {
    snapshotEndTimestamp?: number;
}

export interface AllowRegularInvestment {
    allowRegularInvestment: boolean;
}

export interface AllowAdvancedInvestment {
    allowAdvancedInvestment: boolean;
}

export interface BuyTicketsStartTimestamp {
    buyTicketsStartTimestamp?: number;
}

export interface BuyTicketsEndTimestamp {
    buyTicketsEndTimestamp?: number;
}

export interface WinnerSelectionStartTimestamp {
    winnerSelectionStartTimestamp?: number;
}

export interface WinnerSelectionEndTimestamp {
    winnerSelectionEndTimestamp?: number;
}

export interface ClaimTokensStartTimestamp {
    claimTokensStartTimestamp?: number;
}

export interface ClaimTokensEndTimestamp {
    claimTokensEndTimestamp?: number;
}

export interface ProjectGeneralStartTimestamps
    extends KycStartTimestamp,
        SnapshotStartTimestamp,
        BuyTicketsStartTimestamp,
        WinnerSelectionStartTimestamp,
        ClaimTokensStartTimestamp {}

export interface ProjectGeneralEndTimestamps
    extends KycEndTimestamp,
        SnapshotEndTimestamp,
        BuyTicketsEndTimestamp,
        WinnerSelectionEndTimestamp,
        ClaimTokensEndTimestamp {}

export interface Status {
    status: IcoStatus;
}

export interface IsHotListed {
    isHotListed: boolean;
}

export interface IsScam {
    isScam: boolean;
}

export type TotalRating = number;

export interface Hardcap {
    hardcap: number | null;
}

export interface Softcap {
    softcap: number | null;
}

export interface TokenIdentifier {
    token: TokenWithBalance | null;
}

export interface PublicSaleOfferedTokens {
    publicSaleOfferedTokens: number | null;
}

export interface RaisedInPublicSale {
    raisedInPublicSale: number | null;
}

export interface UpcomingProject
    extends LaunchPlatform,
        Name,
        ShortDescription,
        IcoLayer,
        TokenName,
        MaxTokenSupply,
        PublicSalePrice,
        HasKyc,
        PreSaleStartTimestamp,
        PreSaleStartQuarter,
        PublicSaleStartTimestamp,
        PublicSaleStartQuarter,
        Slogan {
    id: string;
    links: SocialLinks;
    rating: TotalRating;
    logo: string;
}

export interface IcoSummary extends Name, LaunchPlatform, Status, IsHotListed, IsScam {
    id: string;
    tokenName: string;
    projectId?: string;
    isActiveForLaunchpad?: boolean;
    kycStartTimestamp?: number;
    launchedBy?: string;
    launchedAt?: number;
}

export const icoSummaryInitial: IcoSummary = {
    id: "",
    tokenName: "",
    name: "",
    launchPlatform: LaunchPlatforms.Entity,
    status: IcoStatus.new,
    isHotListed: false,
    isScam: false,
    isActiveForLaunchpad: false,
};

export enum TokenUtilityOption {
    NO_TOKEN_UTILITY = "NO_TOKEN_UTILITY",
    CUSTOM_INPUT = "CUSTOM_INPUT",
}

export const tokenUtilityOptions = {
    [TokenUtilityOption.NO_TOKEN_UTILITY]: "No token or utility NFTs currently available",
    [TokenUtilityOption.CUSTOM_INPUT]: "Custom Input",
};
