import React, { FC, ReactNode } from "react";
import { Table } from "react-bootstrap";
import styled, { css } from "styled-components";

type InfoItem = {
    key: string;
    value: ReactNode;
};
export type LaunchInfo = InfoItem[];
interface ProjectInfoTableProps {
    data: LaunchInfo;
}

export const stripedTableStyles = css`
    &.table-striped > tbody > tr {
        display: flex;
        flex-direction: row;
        flex-wrap: wrap;
        --bs-table-accent-bg: transparent;

        &:nth-of-type(odd) > * {
            --bs-table-accent-bg: #06131380;
            color: inherit;
        }
    }
`;

const rowHeight = "2.428rem";
const TableContainer = styled.div`
    &&& {
        margin-top: 2.142rem;
        margin-bottom: 2.142rem;
        min-height: calc(${(props: { rowsAmount: number }): number => props.rowsAmount}*${rowHeight});
    }
`;

const StyledTable = styled(Table)`
    color: var(--card-text);
    width: 100%;

    tr {
        width: 100%;
        min-height: ${rowHeight};
        line-height: 1.428rem;
        flex-wrap: wrap;
    }

    th {
        padding-left: 2.142rem;
    }

    td {
        padding-left: 0.714rem;
        padding-right: 1.428rem;
        white-space: nowrap;
    }

    th,
    td {
        font-weight: normal;
        width: 50%;
    }

    ${stripedTableStyles};
`;

const ProjectInfoTable: FC<React.PropsWithChildren<ProjectInfoTableProps>> = ({ data }) => {
    return (
        <TableContainer rowsAmount={data.length}>
            <StyledTable borderless striped>
                <tbody>
                    {data.map((item, index) => (
                        <tr key={index}>
                            <th>{item.key}</th>
                            <td>{item.value}</td>
                        </tr>
                    ))}
                </tbody>
            </StyledTable>
        </TableContainer>
    );
};

export default ProjectInfoTable;
