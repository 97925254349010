import React from "react";
import ModuleDescription from "../../ModuleDescription";

const Esdt = () => (
    <ModuleDescription
        title="EGLD/ESDT"
        description="The EGLD/ESDT module will provide key KPIs and help users easily acquire different tokens from across the MultiversX Ecosystem."
    />
);

export default Esdt;
