export const URL_REGEX = /^https?:\/\/(?:www\.)?(\S+)\.(\S+)/gm;

export const FACEBOOK_URL_REGEX =
    /^https?:\/\/(?:www\.)?facebook.com\/(?!$)(?:\w*#!\/)?(?:pages\/)?(?:[?\w\-]*\/)?(?:profile.php\?id=(?=\d.*))?([\w\-]*)?/gm;

export const TWITTER_URL_REGEX = /^https?:\/\/(?:www\.)?twitter\.com\/(\S+)/gm;

export const TELEGRAM_URL_REGEX = /^https?:\/\/(?:www\.)?(telegram|t)\.me\/([a-zA-Z0-9_-]+)\/?$/gm;

export const DISCORD_URL_REGEX = /^https?:\/\/(?:www\.)?discord\.(\S+)\/([a-zA-Z0-9_]+)/gm;

export const GITHUB_URL_REGEX = /^https?:\/\/(?:www\.)?github\.com\/([a-zA-Z0-9_]+)/gm;

export const LINKEDIN_URL_REGEX = /^https?:\/\/(?:www\.)?linkedin\.com\/(\S+)/gm;

export const ALPHA = /^[a-zA-Z]+$/gm;

export const ALPHA_WITH_SPACE = /^[a-zA-Z ]+$/gm;

export const ALPHA_NUMERIC_WITH_SPACE = /^[a-zA-Z0-9 ]+$/gm;

export const ALPHA_FOR_COUNTRY = /^(?:(?![,])[a-zA-Z -.();’'])+$/gm;

export const ALPHA_FOR_COUNTRIES = /^[a-zA-Z ,-.();’']+$/gm;

export const ICO_ALPHA_NUMERIC_NOT_ONLY_NUMERIC = /^(?:[0-9]+[a-z]|[a-z]+[0-9]|[a-z])[a-z0-9 ]*$/gim;

export const NO_WHITE_SPACE = /^[^\s]+(\s+[^\s]+)*$/gm;

export const OBJECT_ID_REGEX = /^[0-9a-fA-F]{24}$/;
