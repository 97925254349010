import { css } from "styled-components";

export const frostyEffect = css`
    backdrop-filter: blur(1.25rem);
    -webkit-backdrop-filter: blur(1.25rem);
    -moz-backdrop-filter: blur(1.25rem);
`;

// if disabledField is changed, date-picker.scss should be manually modified accordingly
export const disabledField = css`
    background-color: var(--disabled-field-bg-color) !important;
    color: var(--disabled-field-color) !important;
    cursor: not-allowed;
    -webkit-text-fill-color: var(--disabled-field-color) !important;
`;

export const fieldStates = css`
    background-color: var(--field-bg-color) !important;
    color: white;
    box-sizing: border-box;
    font-size: 1rem;
    min-height: 2.857rem;
    height: 2.857rem;

    &:hover,
    &:active,
    &:focus {
        color: #fff;
    }

    &::placeholder {
        color: var(--field-placeholder-color);
    }

    &:disabled,
    &.disabled,
    &.select__control--is-disabled {
        ${disabledField};
    }
`;
