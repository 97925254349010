import React, { FC } from "react";
import { EButtonProps, eButtonStyles } from "./index";
import styled from "styled-components";
import { HashLink } from "react-router-hash-link";

export interface EHashLinkButtonProps extends EButtonProps {
    className?: string;
    target?: string;
    disabled?: boolean;
    state?: { scrollToTop: boolean };
}

const HashLinkCustom = styled(HashLink)`
    color: var(--white) !important;
`;

const Btn = styled.div`
    .btn {
        ${eButtonStyles};
    }
`;

const EHashLink: FC<React.PropsWithChildren<EHashLinkButtonProps>> = ({
    color = "var(--primary-tx-btn)",
    disabled = false,
    className,
    testId = "hash-link-btn",
    children,
    state,
    ...props
}) => {
    const { to, ...containerParams } = props;

    return (
        <Btn color={color} {...containerParams}>
            <HashLinkCustom
                className={`btn ${className}` + (disabled ? " disabled" : "")}
                to={to}
                data-testid={testId}
                state={state}
                smooth
            >
                {children}
            </HashLinkCustom>
        </Btn>
    );
};

export default EHashLink;
