import styled, { css } from "styled-components";
import { Accordion } from "react-bootstrap";
import { frostyEffect } from "../../assets/sass/common-effects";
import { BREAKPOINTS } from "../../assets/sass/breakpoints";

export const EAccordionItem = styled(Accordion.Item)`
    --bs-accordion-border-radius: 10px;
    --bs-accordion-inner-border-radius: 10px;

    background-color: var(--page-block-background-color);
    border: none;
    margin-top: 1.5rem;
    margin-bottom: 1.5rem;
    border-radius: var(--bs-accordion-border-radius);
    position: relative;
    overflow: hidden;
    ${frostyEffect};
    color: var(--white);
    font-size: 1rem;
`;

export const EAccordionItemNoFrost = styled(EAccordionItem)`
    backdrop-filter: none;
    -webkit-backdrop-filter: none;
    -moz-backdrop-filter: none;
`;

export const EAccordionHeaderButtonStyle = css`
    background-color: var(--page-block-background-color);
    color: inherit;
    line-height: 2.571rem;
    font-size: 1.3rem;
    text-transform: capitalize;
    border: none;
    box-shadow: none;
    border-radius: 10px;

    @media (${BREAKPOINTS.tablet}) {
        font-size: 1.712rem;
    }

    &:focus,
    &:active {
        border: none;
        box-shadow: none;
    }
`;

export const EAccordionHeader = styled(Accordion.Header)`
    --bs-accordion-active-color: white;
    --bs-accordion-border-color: none;

    background-color: var(--page-block-background-color);
    border: none;
    border-radius: 10px;

    .accordion-button {
        ${EAccordionHeaderButtonStyle}
    }
`;
