import { css } from "styled-components";

export const closeButton = css`
    --close-button-height: 2.571rem;

    right: var(--bs-modal-header-padding);
    width: var(--close-button-height);
    height: var(--close-button-height);
    border-radius: 0;
    top: var(--bs-modal-header-padding);
    margin: 0;

    background: none;
    border: 0;
    box-sizing: border-box;
    cursor: pointer;
    outline: none;
    overflow: hidden;
    position: absolute;
    font-size: 0.857rem;
    color: transparent;

    &::before,
    &::after {
        background-color: white;
        content: "";
        display: block;
        height: 2px;
        left: 0;
        position: absolute;
        transform-origin: center left;
        transition: all 0.2s ease-in;
        width: calc(2.571rem * 1.5);
        z-index: -1;
    }

    &::before {
        top: 0;
        transform: rotate(45deg);
    }

    &::after {
        bottom: 0;
        transform: rotate(-45deg);
    }

    &:hover::after,
    &:hover::before {
        height: 1.3rem;
        transform: rotate(0deg);
    }

    &:hover::before {
        content: "close";
        padding-top: 0.571rem;
        margin-left: -0.642rem;
        z-index: 1;
        color: black;
    }
`;
