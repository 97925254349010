import { EmailStatus, User } from "../common/types/userTypes";
import { SettingsWallet } from "../common/types/walletTypes";
import LocalStorageService from "../services/localStorageService/LocalStorageService";
import { LocalStorageKeys } from "../services/localStorageService/LocalStorageKeys";

export interface LogoutReason {
    type: "success" | "error";
    text: string;
}

export interface ContextState {
    userSettings: User;
    jwtToken: string;
    settingsWallets: Array<SettingsWallet>;
}

const initialState = (): ContextState => {
    return {
        jwtToken: LocalStorageService.getItem(LocalStorageKeys.entityUserAuthToken),
        userSettings: initUserSettings,
        settingsWallets: LocalStorageService.getItem(LocalStorageKeys.settingsWallets),
    };
};

export const initUserSettings: User = {
    id: "",
    publicAddress: "",
    email: "",
    emailStatus: EmailStatus.notConfirmed,
    username: "",
    walletName: "",
    isWalletMonitored: false,
    isEmailNotificationEnabled: false,
    isBrowserNotificationEnabled: false,
    isTelegramBotNotificationEnabled: false,
    roles: [],
    confirmEmailSentAt: 0,
    conditionsAgreement: false,
};

export default initialState;
