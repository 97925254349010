import "polyfills";
import React from "react";
import App from "./App";
import "./assets/sass/theme.scss";
import TagManager from "react-gtm-module";
import dappUIStyles from "./assets/sass/dappUIStyles";
import { createGlobalStyle } from "styled-components";
import { createRoot } from "react-dom/client";

const GlobalStyle = createGlobalStyle`
  body {
    ${dappUIStyles};
  }
`;

const tagManagerArgs = {
    gtmId: "GTM-N7BGLBL",
};

TagManager.initialize(tagManagerArgs);

const container = document.getElementById("root");

// react 18 upgrade docs advice
// eslint-disable-next-line @typescript-eslint/no-non-null-assertion
const root = createRoot(container!);

root.render(
    <>
        <GlobalStyle />
        <App />
    </>,
);
