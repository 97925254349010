import React, { FC } from "react";
import { Timeline } from "../../components/Timeline";
import { ProjectGeneralDetails } from "./launchpad.types";
import { prepareTimelineEvents } from "./utils/LaunchpadUtils";
import { ErrorDummy } from "../../components/Error";

interface LaunchRoadmapProps {
    events?: ProjectGeneralDetails["launchRoadmap"];
    showPlaceholder?: boolean;
}

const LaunchRoadmap: FC<React.PropsWithChildren<LaunchRoadmapProps>> = ({ events, showPlaceholder }) => {
    if (!showPlaceholder && events?.length === 0) {
        return <ErrorDummy errorText="There is no roadmap yet" />;
    } else {
        return <Timeline events={prepareTimelineEvents(events)} showPlaceholder={!!showPlaceholder} />;
    }
};

export default LaunchRoadmap;
