import React from "react";
import OverlayTriggerTooltip from "../../helpers/OverlayTriggerTooltip";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faInfoCircle } from "@fortawesome/free-solid-svg-icons";
import { EFormLabel } from "../../common";

const LabelWithInfoTooltip = ({
    label,
    tooltipText,
    className = "",
    required,
}: {
    label: string;
    tooltipText: string;
    className?: string;
    required?: boolean;
}) => {
    return (
        <EFormLabel className={className}>
            <span className={`${required ? "required" : ""}`}>{label}</span>
            <span className="ml-0-5">
                <OverlayTriggerTooltip placement="top" tooltipText={tooltipText} inline cursor="pointer">
                    <FontAwesomeIcon icon={faInfoCircle} />
                </OverlayTriggerTooltip>
            </span>
        </EFormLabel>
    );
};

export default LabelWithInfoTooltip;
