import BaseService from "./BaseService";
import axios from "axios";
import { BlacklistAddress } from "../pages/Admin/LaunchpadBlacklist/LaunchpadBlacklistTypes";
import { User } from "../common/types/userTypes";

export interface BlacklistAddressParams {
    address?: string;
    from: number;
    size: number;
}

export class LaunchpadBlacklistService extends BaseService {
    private readonly _launchpadBaseApi: string;

    constructor(launchpadApi: string) {
        super();
        this._launchpadBaseApi = launchpadApi;
    }

    public async isUserAddressBlacklisted(): Promise<boolean> {
        return this.doCall(() => axios.get(`${this._launchpadBaseApi}/blacklist/check`));
    }

    public async retrieveAll(params: BlacklistAddressParams): Promise<BlacklistAddress[]> {
        return this.doCall(() => axios.get(`${this._launchpadBaseApi}/blacklist`, { params }));
    }

    public async addAddressToBlacklist(data: BlacklistAddress): Promise<User> {
        return this.doCall(() => axios.post<User>(`${this._launchpadBaseApi}/blacklist`, data));
    }

    public async syncAddresses(): Promise<User> {
        return this.doCall(() => axios.post<User>(`${this._launchpadBaseApi}/blacklist/sync`));
    }

    public async deleteAddress(address: string): Promise<BlacklistAddress> {
        return this.doCall(() => axios.delete<BlacklistAddress>(`${this._launchpadBaseApi}/blacklist/${address}`));
    }
}

export default LaunchpadBlacklistService;
