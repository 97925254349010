import React, { useMemo } from "react";
import styled from "styled-components";
import { Cloud } from "../../../../components/Cloud";
import { HexContainer } from "../../../../components/Hexagon";
import ProjectLaunch from "../ProjectLaunch";
import { ProjectGeneralDetails } from "../../../Launchpad/launchpad.types";
import { BREAKPOINTS } from "../../../../assets/sass/breakpoints";
import ProjectHexagonContainer from "../helper/ProjectHexagonContainer";

const Header = styled.div`
    color: white;
    display: flex;
    align-items: center;
    flex-direction: unset;
    flex-wrap: wrap;
    min-width: 18.571rem;

    h3 {
        width: 100%;
        text-align: center;
    }

    @media (${BREAKPOINTS.desktop}) {
        margin-right: 4.785rem;

        h3 {
            text-align: right;
        }
    }
`;

const Container = styled.div`
    position: relative;
    width: 100%;

    @media (${BREAKPOINTS.desktop}) {
        margin-top: 6.571rem;
    }
`;

const StyledCloud = styled(Cloud)`
    overflow: visible;
    z-index: -1;
    right: 0;
    top: -53%;
    opacity: 0.3;
`;

const CountdownContainer = styled.div`
    color: white;
    display: flex;
    align-items: center;
    flex-direction: unset;
    flex-wrap: wrap;
    min-width: 18.571rem;

    @media (${BREAKPOINTS.desktop}) {
        margin-left: 3.785rem;
    }
`;

const QuarterInfo = styled.h3`
    margin-left: 1rem;
`;

const StyledHex = styled(HexContainer)`
    display: flex;
    align-items: center;
    flex-direction: unset;
    flex-wrap: wrap;

    &.hex-container {
        height: 23.571rem !important;
        width: 27.143rem !important;
    }

    min-width: 27.142rem;
`;

const ContainerBody = styled(Container)`
    justify-content: center;
    display: flex;
    flex-wrap: wrap;
    position: relative;
`;

const NextLaunchpad = ({ projectGeneralDetails }: { projectGeneralDetails: ProjectGeneralDetails }) => {
    const containerContent = useMemo(
        () => <ProjectHexagonContainer generalMetrics={projectGeneralDetails.generalMetrics} />,
        [projectGeneralDetails.generalMetrics],
    );

    return (
        <Container data-testid="next-launchpad">
            <ContainerBody className="flex-column align-items-center flex-xl-row">
                <Header className="mb-5 mb-xl-0">
                    <h3>Next Launchpad Project</h3>
                </Header>
                <StyledCloud color="var(--entity-green)" height="60%" width="60%" />
                <StyledHex className="hex-container mb-5 mb-xl-0">
                    <ProjectLaunch launchpadInfo={projectGeneralDetails} />
                </StyledHex>
                <CountdownContainer className="justify-content-center justify-content-xl-start">
                    {containerContent}
                </CountdownContainer>
            </ContainerBody>
        </Container>
    );
};

export default NextLaunchpad;
