export enum UserRoles {
    user = "user",
    moderator = "moderator",
    admin = "admin",
}

export enum SuperUserRoles {
    launchpadAdmin = "launchpadAdmin",
}

export default UserRoles;
