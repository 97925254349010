import { Form } from "react-bootstrap";
import styled from "styled-components";
import { formControlCSS } from "./EFormControl";

const EFormSelect = styled(Form.Select)`
    &.form-select {
        ${formControlCSS}; // not all the styles fit, so the customStyles from react-select API is used to polish some of them
        appearance: none;
        -webkit-appearance: none;
        background-image: linear-gradient(45deg, transparent 50%, #206666 50%),
            linear-gradient(135deg, #206666 50%, transparent 50%);
        background-position: calc(100% - 20px) calc(1em + 2px), calc(100% - 15px) calc(1em + 2px);
        background-size: 5px 5px, 5px 5px;
        background-repeat: no-repeat;

        &:hover,
        &:focus,
        &:active {
            background-image: linear-gradient(45deg, transparent 50%, #fff 50%),
                linear-gradient(135deg, #fff 50%, transparent 50%);
        }
    }
`;

export default EFormSelect;
