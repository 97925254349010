import React, { useMemo } from "react";
import { Image } from "react-bootstrap";
import styled from "styled-components";
import { awsS3BaseURL } from "../../../config";
import { ProjectGeneralDetails } from "../../Launchpad/launchpad.types";
import { routeNames } from "../../../routes/routes";
import Link from "../../../components/Buttons/Link";
import { BREAKPOINTS } from "../../../assets/sass/breakpoints";
import ProjectHexagonContainer from "./helper/ProjectHexagonContainer";

const StyledProjectLaunch = styled.div`
    color: #fff;
    opacity: 1;
    letter-spacing: 0;
    font-weight: 300;
    text-align: left;
    font-family: var(--entity-exo-font-family);
    line-height: 1.857rem;

    .invest {
        margin-bottom: 2.857rem;
        font: normal normal medium 1.143rem/1.357rem var(--entity-exo-font-family);
        padding: 0.414rem 1.214rem;
        letter-spacing: 0;
    }
`;

const LogoContainer = styled.div`
    margin-top: 2.285rem;

    .project-name {
        margin-top: 1.214rem;
        font-size: 2rem;
    }

    .project-moto {
        margin-top: 1rem;
        font-size: 1.285rem;
        color: var(--entity-text-grey);
        max-width: 21.428rem;
        text-align: center;
    }
`;

const StyledImage = styled(Image)`
    width: 5rem;
`;

const CountdownContainer = styled.div`
    color: white;
    display: flex;
    align-items: center;
    flex-direction: unset;
    flex-wrap: wrap;
    min-width: 18.571rem;

    @media (${BREAKPOINTS.desktop}) {
        display: block;
        margin: 0;
    }
`;

interface ProjectLaunchProps {
    launchpadInfo: ProjectGeneralDetails;
    position?: string;
    embedded?: boolean;
}

const ProjectLaunch = ({
    launchpadInfo: { name, logo, slogan, generalMetrics },
    position,
    embedded = false,
}: ProjectLaunchProps) => {
    const containerContent = useMemo(
        () => <ProjectHexagonContainer generalMetrics={generalMetrics} embedded={embedded} />,
        [generalMetrics],
    );

    return (
        <StyledProjectLaunch className="d-flex flex-column align-items-center justify-content-between w-100 h-100">
            <div className="d-flex flex-column justify-content-start flex-grow-1">
                {logo && (
                    <LogoContainer className="d-flex flex-column align-items-center justify-content-center mb-3">
                        <StyledImage src={awsS3BaseURL + logo} roundedCircle />
                        <span className="project-name">{name}</span>
                        <span className="project-moto">{slogan}</span>
                    </LogoContainer>
                )}
            </div>

            {position && position === "top" && (
                <CountdownContainer className="justify-content-center justify-content-xl-start text-center">
                    {containerContent}
                </CountdownContainer>
            )}
            <Link className="align-self-center invest" to={routeNames.launchpad} data-testid="invest-btn">
                Find Out More
            </Link>
        </StyledProjectLaunch>
    );
};

export default ProjectLaunch;
