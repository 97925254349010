import React, { memo } from "react";
import { DetailedInfoProps } from "../../launchpad.types";
import { useService } from "../../../../services/config/dependencyInjectorConfig";
import ProjectsService from "../../../../services/ProjectsService";
import styled from "styled-components";
import { renderHTML } from "../../../../helpers/consts";
import ContentContainer from "../ContentContainer";
import { awsS3BaseURL } from "../../../../config";
import AboutPlaceholder from "./Placeholder";
import { useQuery } from "@tanstack/react-query";

export const FeatureImage = styled.img`
    width: 100%;
`;

const TextContainer = styled.div`
    padding-top: 2.142rem;
    line-height: 1.714rem;
    color: var(--light-gray);

    p {
        font-size: 1.1rem;
    }
`;

const About = ({ projectId, isActive }: DetailedInfoProps) => {
    const [projectsService] = useService(ProjectsService);

    const { data: aboutInfo, isLoading } = useQuery({
        enabled: isActive,
        queryKey: ["project-about-details"],
        queryFn: () => projectsService.retrieveAboutDetails(projectId),
        staleTime: Infinity,
    });

    if (isLoading || !aboutInfo) {
        return <AboutPlaceholder />;
    }

    return (
        <ContentContainer title={`What is ${aboutInfo.name}?`}>
            {aboutInfo.featureImage && <FeatureImage src={awsS3BaseURL + aboutInfo.featureImage} />}
            <TextContainer>{renderHTML(aboutInfo.description)}</TextContainer>
        </ContentContainer>
    );
};

export default memo(About);
