import React, { FC } from "react";
import SubTitle from "./SubTitle";
import styled from "styled-components";
import { BREAKPOINTS } from "../../assets/sass/breakpoints";

const Description = styled.div`
    color: white;
    font-size: 1rem;
    line-height: 1.714rem;
    font-weight: 300;
    text-align: center;
    margin-bottom: 1rem;

    @media (${BREAKPOINTS.tablet}) {
        text-align: unset;
        font-size: 1.285rem;
        line-height: 2.285rem;
        margin-bottom: 2.857rem;
    }
`;

const ChildContainer = styled.div`
    display: flex;
    justify-content: center;

    @media (${BREAKPOINTS.tablet}) {
        justify-content: start;
    }
`;

const ModuleDescription: FC<React.PropsWithChildren<{ title: string; description: string; testId?: string }>> = ({
    title,
    description,
    children,
    testId = "module-description",
}) => {
    return (
        <div data-testid={testId}>
            <SubTitle text={title} />
            <Description>{description}</Description>
            <ChildContainer>{children}</ChildContainer>
        </div>
    );
};

export default ModuleDescription;
