import { EntitySocialLinks, modulesLinkList } from "../../../helpers/consts";
import { routeNames } from "../../../routes/routes";
import { NavItem, NavLink } from "../Navbar/navbar.helper";

export const getMenu: () => Record<string, (NavItem & NavLink)[]> = () => {
    return {
        Startups: [
            {
                title: "Launchpad",
                to: routeNames.launchpad,
                testId: "launchpad",
            },
            {
                title: "Project Monitor",
                isDeprecated: true,
                testId: "project-monitor",
            },
            {
                title: "List Your Project",
                to: routeNames.icoNew,
                testId: "list-your-project",
            },
        ],
        Investors: [
            {
                title: "Portfolio",
                to: routeNames.portfolio,
                testId: "portfolio",
            },
            {
                title: "DeFi & Analytics ",
                testId: "defi-and-analytics",
            },
        ],
        Learn: [
            {
                title: "Knowledge Base",
                testId: "documentation",
                href: EntitySocialLinks.gitbook,
            },
            {
                title: "How to List Your Project",
                testId: "how-to-apply",
                href: modulesLinkList.listProject,
            },
            {
                title: "News",
                testId: "news",
                href: EntitySocialLinks.medium,
            },
            {
                title: "Litepaper",
                testId: "litepaper",
            },
        ],
        Legal: [
            {
                title: "Privacy Policy",
                to: routeNames.privacyPolicy,
                testId: "privacy-policy",
            },
            {
                title: "Terms of Use",
                to: routeNames.termsAndConditions,
                testId: "terms-of-policy",
            },
            {
                title: "Disclaimer",
                to: routeNames.disclaimer,
                testId: "disclaimer",
            },
            {
                title: "Imprint",
                to: routeNames.imprint,
                testId: "imprint",
            },
        ],
    };
};
