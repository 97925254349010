import BaseService from "./BaseService";
import axios from "axios";
import { KycStatusInfo } from "../pages/Launchpad/kyc/launchpad.kyc.dtos";
import { KycAmlDetails } from "../pages/KYC/KycAmlStepFour";
import CipherUtils from "../common/utils/CipherUtils";
import { gdprCipherAlgorithm, gdprCipherIv, gdprCipherPassword } from "../config";

export interface SdkTokenResp {
    token: string;
}

export interface UserCountryAllowedResp {
    allowed: boolean;
    countryName: string;
}

export interface KycAccountEmailDetails {
    email: string;
    emailSentAt: number;
    isConfirmed: boolean;
}

export interface KycApplicantDetails {
    firstName: string;
    lastName: string;
    birthDate: string;
    street: string;
    number: string;
    city: string;
    zipCode: string;
    nationality: string;
    residenceCountry: string;
    hasKycRef?: boolean;
}

export interface KycApplicantEmailDetails extends KycApplicantDetails {
    email: string;
}

export enum KycFlow {
    REGULAR = "REGULAR",
    ADVANCED = "ADVANCED",
}

export interface KycFlowDetails {
    kycFlow?: KycFlow;
    tokenThreshold: number;
    usdThreshold: number;
    maxTicketsThreshold: number;
}

export interface CommentDetails {
    comment: string;
    kycAccountWalletAddress: string;
}

export interface CommentDetailsResp {
    comment: string;
    commentUpdatedAt: number;
    commentUpdatedBy: string;
}

interface EncryptedPayload {
    payload: string;
}

export class KycService extends BaseService {
    private readonly _launchpadBaseApi: string;

    constructor(entityApi: string, launchpadApi: string) {
        super();
        this._launchpadBaseApi = launchpadApi;
    }

    public getSdkToken(projectId: string): Promise<SdkTokenResp> {
        return this.doCall(() => axios.get<SdkTokenResp>(`${this._launchpadBaseApi}/projects/${projectId}/account/kyc/token`));
    }

    public startKyc(projectId: string): Promise<void> {
        return this.doCall(() => axios.post(`${this._launchpadBaseApi}/projects/${projectId}/account/kyc/start`));
    }

    public endKyc(projectId: string): Promise<void> {
        return this.doCall(() => axios.post(`${this._launchpadBaseApi}/projects/${projectId}/account/kyc/end`));
    }

    public getKycStatus(projectId: string): Promise<KycStatusInfo> {
        return this.doCall(() => axios.get<KycStatusInfo>(`${this._launchpadBaseApi}/projects/${projectId}/account/kyc/status`));
    }

    public getUserCountryAllowedByCoords(
        projectId: string,
        latitude: number,
        longitude: number,
    ): Promise<UserCountryAllowedResp> {
        return this.doCall(() =>
            axios.post<UserCountryAllowedResp>(`${this._launchpadBaseApi}/projects/${projectId}/account/country`, {
                latitude,
                longitude,
            }),
        );
    }

    public setKycAccountDetails(projectId: string, email: string): Promise<KycAccountEmailDetails> {
        return this.doCall(() => axios.post(`${this._launchpadBaseApi}/projects/${projectId}/account/details`, { email }));
    }

    public async setKycApplicantDetails(projectId: string, applicant: KycApplicantDetails): Promise<KycApplicantDetails> {
        const applicantPayload = await this.doCall<EncryptedPayload>(() =>
            axios.post(`${this._launchpadBaseApi}/projects/${projectId}/account/applicant`, KycService.encryptPayload(applicant)),
        );
        return KycService.decryptPayload(applicantPayload);
    }

    public async retrieveKycApplicantDetails(projectId: string): Promise<KycApplicantEmailDetails> {
        const applicantPayload = await this.doCall<EncryptedPayload>(() =>
            axios.get(`${this._launchpadBaseApi}/projects/${projectId}/account/applicant`),
        );
        return KycService.decryptPayload(applicantPayload);
    }

    public getKycAccountEmailDetails(projectId: string): Promise<KycAccountEmailDetails> {
        return this.doCall(() =>
            axios.get<KycAccountEmailDetails>(`${this._launchpadBaseApi}/projects/${projectId}/account/details`),
        );
    }

    public retrieveKycFlowDetails(projectId: string): Promise<KycFlowDetails> {
        return this.doCall(() => axios.get(`${this._launchpadBaseApi}/projects/${projectId}/account/kyc/flow`));
    }

    public setKycFlowDetails(kycFlow: KycFlow, projectId: string): Promise<KycFlowDetails> {
        return this.doCall(() => axios.post(`${this._launchpadBaseApi}/projects/${projectId}/account/kyc/flow`, { kycFlow }));
    }

    public async retrieveKycAmlDetails(projectId: string): Promise<KycAmlDetails> {
        const detailsPayload = await this.doCall<EncryptedPayload>(() =>
            axios.get(`${this._launchpadBaseApi}/projects/${projectId}/account/kyc/aml`),
        );
        return KycService.decryptPayload(detailsPayload);
    }

    public async setKycAmlDetails(kycAml: KycAmlDetails, projectId: string): Promise<KycAmlDetails> {
        const detailsPayload = await this.doCall<EncryptedPayload>(() =>
            axios.post(`${this._launchpadBaseApi}/projects/${projectId}/account/kyc/aml`, KycService.encryptPayload(kycAml)),
        );
        return KycService.decryptPayload(detailsPayload);
    }

    public updateKycAccountComment(commentDetails: CommentDetails, projectId: string): Promise<CommentDetailsResp> {
        return this.doCall(() =>
            axios.post(`${this._launchpadBaseApi}/projects/${projectId}/account/kyc/comment`, commentDetails),
        );
    }

    private static encryptPayload<T>(data: T): EncryptedPayload {
        const encryptedPayload = CipherUtils.encrypt(JSON.stringify(data), gdprCipherIv, gdprCipherAlgorithm, gdprCipherPassword);
        if (!encryptedPayload) {
            throw new Error("Encryption couldn't be done");
        }
        return { payload: encryptedPayload };
    }

    private static decryptPayload<T>(resp: EncryptedPayload): T {
        const parts = resp.payload.split(":");
        const decryptedPayload = CipherUtils.decrypt<T>(parts[1], parts[0], gdprCipherAlgorithm, gdprCipherPassword);
        if (!decryptedPayload) {
            throw new Error("Decryption couldn't be done");
        }
        return decryptedPayload;
    }
}

export default KycService;
