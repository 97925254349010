import { TDate } from "../form/types";

class StringUtils {
    public static readonly DASH_VALUE = "-";
    public static readonly DOLLAR = "$";
    public static readonly US_DOLLAR = "US$";
    public static readonly PERCENTAGE = "%";

    public static capitalizeFirstLetter(string: string): string {
        return string.charAt(0).toUpperCase() + string.slice(1);
    }

    public static splitStringByUpperCaseLetter(string: string): string | null {
        const convertedString = string.split(/(?=[A-Z])/);
        return string ? convertedString.join(" ") : null;
    }

    static splitInWordsCamelCaseString(str: string | null | undefined): string {
        return !!str ? str.replace(/([a-z0-9])([A-Z])/g, "$1 $2").toLowerCase() : StringUtils.DASH_VALUE;
    }

    static concatWords(words: string): string {
        return !words.includes(" ")
            ? words
            : words
                  .split(" ")
                  .map((word, i) => (i === 0 ? word : StringUtils.capitalizeFirstLetter(word)))
                  .join("");
    }

    public static slugString(string: string): string | undefined {
        if (!string) {
            return undefined;
        }

        return string
            .toLowerCase()
            .replace(/ /g, StringUtils.DASH_VALUE)
            .replace(/[^\w-]+/g, "");
    }

    public static removeSlugFromString(string: string): string | undefined {
        if (!string) {
            return undefined;
        }

        return StringUtils.capitalizeFirstLetter(string.toLowerCase().replace(StringUtils.DASH_VALUE, " "));
    }

    public static toOptionalBooleanLabel(value: boolean | null | undefined): string {
        return !!value ? "Yes" : "No";
    }

    public static toOptionalNumberLabel(value: number | null | undefined): number | string {
        if (value === 0) return 0;
        return !!value ? value : StringUtils.DASH_VALUE;
    }

    public static toOptionalLabel(value: string | null | undefined): string {
        return !!value ? value : StringUtils.DASH_VALUE;
    }

    public static toOptionalGenericLabel<T>(value: T | null | undefined): T | string {
        return value !== null && value !== undefined ? value : StringUtils.DASH_VALUE;
    }

    public static toOptionalLabelWithPrefix<T extends string | number>(
        value: T | null | undefined,
        prefix: string,
        valueMapper?: (value: T) => unknown,
    ): string {
        return !!value ? prefix + (valueMapper ? valueMapper(value) : value) : StringUtils.DASH_VALUE;
    }

    public static toOptionalLabelWithSuffix(value: string | number | null | undefined, suffix: string): string {
        return !!value ? value + suffix : StringUtils.DASH_VALUE;
    }

    static fromEnumValue(value: string): string {
        return value.replaceAll("_", " ");
    }

    static fromOptionalEnumValue(value: string | number | boolean): string | number | boolean {
        return typeof value === "string" && value.includes("_") ? StringUtils.fromEnumValue(value as string) : value;
    }

    public static camelCaseToLabel(text: string): string {
        return text.replace(/([a-z])([A-Z])/g, "$1 $2").toLowerCase();
    }

    public static getDateValue = (date: TDate): string => {
        return date instanceof Date ? date.toDateString() : date;
    };

    public static endStringEllipsis = (val: string, size = 5): string => {
        return val.length > size ? val.slice(0, size - 1) + "..." : val;
    };

    public static generateRandomString(length = 32): string {
        let result = "";
        const characters = "ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789";
        const charactersLength = characters.length;
        let counter = 0;
        while (counter < length) {
            result += characters.charAt(Math.floor(Math.random() * charactersLength));
            counter += 1;
        }
        return result;
    }

    static getTokenNameFromIdentifier(tokenIdentifier: string): string {
        return tokenIdentifier.split("-")[0].toUpperCase();
    }
}

export default StringUtils;
