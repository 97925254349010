import React from "react";
import { DateUtils } from "../../../common";
import { DATE_FORMAT_DAY_MONTH_MOMENT_JS, TBA } from "../../../helpers/consts";
import { ProjectGeneralDetails } from "../../../pages/Launchpad/launchpad.types";
import DetailsRow from "./DetailsRow";
import { EPlaceholder } from "../../Placeholder";

const LaunchpadDetails = ({ launchpadInfo }: { launchpadInfo?: ProjectGeneralDetails }) => {
    if (!launchpadInfo) {
        return <EPlaceholder numberOfLines={3} />;
    }
    const publicSaleDateValue =
        launchpadInfo && launchpadInfo.generalMetrics.publicSaleStartTimestamp
            ? DateUtils.toDateLabel(launchpadInfo.generalMetrics.publicSaleStartTimestamp, DATE_FORMAT_DAY_MONTH_MOMENT_JS)
            : TBA;

    return (
        <div className="lp-infos" data-testid="launchpad-details">
            <DetailsRow key="Category" value={launchpadInfo?.generalMetrics.category} />
            <DetailsRow key="Token" value={launchpadInfo?.generalMetrics.tokenName} />
            <DetailsRow key="Total Token Supply" value={launchpadInfo?.generalMetrics.maxTokenSupply} />
            <DetailsRow key="Public Sale token Price" value={`${launchpadInfo?.generalMetrics.publicSaleTokenPrice} USD`} />
            <DetailsRow key="Public Sale Date" value={publicSaleDateValue} />
        </div>
    );
};

export default LaunchpadDetails;
