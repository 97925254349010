import React, { FC, memo } from "react";
import { Timeline } from "../../../../components/Timeline";
import { TimelineProps } from "../../../../components/Timeline/Timeline";
import { ProjectMetricsDetails } from "../../launchpad.types";

interface ProjectRoadmapProps extends Pick<TimelineProps, "editable" | "editCallback"> {
    events: ProjectMetricsDetails["projectRoadmap"];
}

const getProcessedEvents = (events: ProjectMetricsDetails["projectRoadmap"]): TimelineProps["events"] => {
    const processedEvents = [] as TimelineProps["events"];
    events?.forEach(({ quarterDate, descriptionPlan, isChecked }) =>
        processedEvents.push({
            header: quarterDate,
            subEvents: descriptionPlan,
            isChecked,
        }),
    );
    return processedEvents;
};

const ProjectRoadmap: FC<React.PropsWithChildren<ProjectRoadmapProps>> = ({ events, editable, editCallback }) => (
    <Timeline
        events={getProcessedEvents(events)}
        variant="nested-sub-events"
        editable={editable}
        editCallback={editCallback}
        showPlaceholder={!events}
    />
);

export default memo(ProjectRoadmap);
