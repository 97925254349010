import React, { FC, memo, useMemo } from "react";
import { SliderProps } from "rc-slider/lib/Slider";
import Slider from "rc-slider";
import "../../../assets/sass/reactSlider.scss";

interface ESliderProps extends SliderProps {
    useMarks?: boolean;
    testId?: string;
}

function computePercentageForValue(value: number, maxLimit: number): string {
    return ((value * 100) / maxLimit).toFixed() + "%";
}

function computeValueForPercentage(current: number, maxLimit: number): string {
    return ((current * maxLimit) / 100).toFixed(2);
}

export const ESlider: FC<React.PropsWithChildren<ESliderProps>> = ({
    useMarks = true,
    min,
    max,
    testId = "e-slider",
    ...props
}) => {
    const computeMarks = useMemo(() => {
        if (!useMarks || min === undefined || max === undefined) return;
        if (min === max) {
            return {
                [min]: "100%",
                [max]: "100%",
            };
        }
        const minPercentage = computePercentageForValue(min, max);
        const q1 = computeValueForPercentage(25, max);
        const half = computeValueForPercentage(50, max);
        const q3 = computeValueForPercentage(75, max);
        return {
            [min]: minPercentage,
            [q1]: "25%",
            [half]: "50%",
            [q3]: "75%",
            [max]: "100%",
        };
    }, [min, max]);

    return <Slider {...props} min={min} max={max} marks={computeMarks} data-testid={testId} />;
};

export default memo(ESlider);
