import {
    faDiscord,
    faFacebookF,
    faGithub,
    faInstagram,
    faLinkedinIn,
    faMedium,
    faTelegramPlane,
    faTwitter,
    faYoutube,
} from "@fortawesome/free-brands-svg-icons";
import { faEnvelope, faFile, faCircleInfo, faGlobe, faCoins } from "@fortawesome/free-solid-svg-icons";
import { CommonSMBtn } from "../../components/Buttons/SocialMediaButtons";
import { ENTITY_MODULE_LINK_BASE_PATH } from "./entityModuleLinks";

export enum SocialMediaTypes {
    facebook = "facebook",
    twitter = "twitter",
    youtube = "youtube",
    telegram = "telegram",
    instagram = "instagram",
    email = "email",
    linkedin = "linkedin",
    discord = "discord",
    medium = "medium",
    github = "github",
    whitelist = "whitelist",
    website = "website",
    blog = "blog",
    whitepaper = "whitepaper",
    litepaper = "litepaper",
    tokenomics = "tokenomics",
}

export type SocialLinks = Partial<Record<SocialMediaTypes, string | null>>;

export const socialMediaInfo = {
    [SocialMediaTypes.facebook]: { icon: faFacebookF, testId: "facebook-social" },
    [SocialMediaTypes.twitter]: { icon: faTwitter, testId: "twitter-social" },
    [SocialMediaTypes.youtube]: { icon: faYoutube, testId: "youtube-social" },
    [SocialMediaTypes.telegram]: { icon: faTelegramPlane, testId: "telegram-social" },
    [SocialMediaTypes.instagram]: { icon: faInstagram, testId: "instagram-social" },
    [SocialMediaTypes.email]: { icon: faEnvelope, testId: "email-social" },
    [SocialMediaTypes.linkedin]: { icon: faLinkedinIn, testId: "linkedin-social" },
    [SocialMediaTypes.discord]: { icon: faDiscord, testId: "discord-social" },
    [SocialMediaTypes.medium]: { icon: faMedium, testId: "medium-social" },
    [SocialMediaTypes.github]: { icon: faGithub, testId: "github-social" },
    [SocialMediaTypes.whitelist]: { icon: faFile, testId: "whitelist-social" },
    [SocialMediaTypes.website]: { icon: faGlobe, testId: "website-social" },
    [SocialMediaTypes.blog]: { icon: faCircleInfo, testId: "blog-social" },
    [SocialMediaTypes.whitepaper]: { icon: faFile, testId: "whitepaper-social" },
    [SocialMediaTypes.litepaper]: { icon: faFile, testId: "litepaper-social" },
    [SocialMediaTypes.tokenomics]: { icon: faCoins, testId: "tokenomics-social" },
};

export const EntitySocialLinks = {
    [SocialMediaTypes.twitter]: "https://twitter.com/EntityFinance",
    [SocialMediaTypes.facebook]: "https://www.facebook.com/entity.global/",
    [SocialMediaTypes.youtube]: "https://www.youtube.com/channel/UC14OufwQYsAWw0IAJw8b6iw",
    [SocialMediaTypes.telegram]: "https://t.me/EntityCommunity",
    [SocialMediaTypes.email]: "info@entity.global",
    [SocialMediaTypes.instagram]: "https://www.instagram.com/entityfinance/",
    [SocialMediaTypes.medium]: "https://medium.com/@EntityFinance",
    [SocialMediaTypes.linkedin]: "https://www.linkedin.com/company/entity-finance",
    gitbook: ENTITY_MODULE_LINK_BASE_PATH,
};

export const entitySocialList: CommonSMBtn[] = [
    {
        link: EntitySocialLinks[SocialMediaTypes.twitter],
        icon: SocialMediaTypes.twitter,
    },
    {
        link: EntitySocialLinks[SocialMediaTypes.telegram],
        icon: SocialMediaTypes.telegram,
    },
    {
        link: EntitySocialLinks[SocialMediaTypes.medium],
        icon: SocialMediaTypes.medium,
    },
    {
        link: EntitySocialLinks[SocialMediaTypes.linkedin],
        icon: SocialMediaTypes.linkedin,
    },
    {
        link: EntitySocialLinks[SocialMediaTypes.facebook],
        icon: SocialMediaTypes.facebook,
    },
    {
        link: EntitySocialLinks[SocialMediaTypes.youtube],
        icon: SocialMediaTypes.youtube,
    },

    {
        link: EntitySocialLinks[SocialMediaTypes.instagram],
        icon: SocialMediaTypes.instagram,
    },
];
