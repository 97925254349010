import React, { FC } from "react";
import styled from "styled-components";
import { headerStyles } from "./TableLeftHeader";
import { BgCell } from "./TableCell";

const Container = styled.th`
    &:first-child {
        border-left: 0;
    }

    &:last-child {
        border-right: 0;
    }

    ${headerStyles}
`;

const TableTopHeader: FC<React.PropsWithChildren<unknown>> = ({ children }) => {
    return (
        <Container>
            <BgCell>{children}</BgCell>
        </Container>
    );
};

export default TableTopHeader;
