import React from "react";
import { Placeholder } from "react-bootstrap";
import { Header, SubEvent } from "./EventContent";

const TimelinePlaceholder = () => (
    <>
        <Placeholder as={Header} animation="glow" xs={5}>
            <Placeholder xs={10} />
        </Placeholder>
        <Placeholder as={SubEvent} animation="glow" xs={7}>
            <Placeholder xs={10} />
        </Placeholder>
        <Placeholder as={SubEvent} animation="glow" xs={6}>
            <Placeholder xs={10} />
        </Placeholder>
    </>
);

export default TimelinePlaceholder;
