import { useQuery } from "@tanstack/react-query";
import { useService } from "../../services/config/dependencyInjectorConfig";
import LaunchpadBlacklistService from "../../services/LaunchpadBlacklistService";

export const useCheckUserAddressIsBlacklisted = (enabled: boolean) => {
    const [launchpadBlacklistService] = useService(LaunchpadBlacklistService);

    return useQuery({
        queryKey: [`checkUserAddressInBlacklist`],
        queryFn: () => launchpadBlacklistService.isUserAddressBlacklisted(),
        placeholderData: false,
        enabled: enabled,
    });
};

export default useCheckUserAddressIsBlacklisted;
