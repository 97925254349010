import React from "react";
import styled from "styled-components";
import { entityPresentationSiteUrl } from "../../../../config";
import CloseButton from "../../../../assets/images/close.svg";
import ContactBackground from "../../../../assets/images/ContactBackground.jpg";
import ContactEmail from "../../../../assets/images/ContactEmail.svg";
import ContactArrow from "../../../../assets/images/ContactArrow.svg";
import TwitterIcon from "../../../../assets/img/icons/twitter";
import TelegramIcon from "../../../../assets/img/icons/telegram";
import LinkedinIcon from "../../../../assets/img/icons/linkedin";
import DiscordIcon from "../../../../assets/img/icons/discord";
import ProductIconOne from "../../../../assets/images/ProductIconOne.webp";
import ProductIconTwo from "../../../../assets/images/ProductIconTwo.webp";
import ProductIconThree from "../../../../assets/images/ProductIconThree.webp";

const OverlayContainer = styled("div")`
    height: 100%;
    width: 100%;
    position: fixed; /* Stay in place */
    z-index: 1000; /* Sit on top */
    left: 0;
    top: 0;
    background-color: rgb(0, 0, 0); /* Black fallback color */
    background-color: rgba(0, 0, 0, 0.9); /* Black w/opacity */
    overflow-x: hidden; /* Disable horizontal scroll */
    transition: 0.5s; /* 0.5 second transition effect to slide in or slide down the overlay (height or width, depending on reveal) */

    .overlay-content {
        height: 100%;
        position: relative;
        top: 0%;
        width: 100%;
        text-align: center; /* Centered text/links */
    }

    /* The navigation links inside the overlay */

    .overlay a {
        padding: 8px;
        text-decoration: none;
        font-size: 36px;
        color: #818181;
        display: block; /* Display block instead of inline */
        transition: 0.3s; /* Transition effects on hover (color) */
    }

    /* When you mouse over the navigation links, change their color */

    .overlay a:hover,
    .overlay a:focus {
        color: #f1f1f1;
    }

    /* Position the close button (top right corner) */

    .overlay .closebtn {
        position: absolute;
        top: 20px;
        right: 45px;
        font-size: 60px;
    }

    /* When the height of the screen is less than 450 pixels, change the font-size of the links and position the close button again, so they don't overlap */
    @media screen and (max-height: 450px) {
        .overlay a {
            font-size: 20px;
        }

        .overlay .closebtn {
            font-size: 40px;
            top: 15px;
            right: 35px;
        }
    }
`;

const StyledContainer = styled("div")`
    .icon.is-menu-close-icon {
        font-size: 1.5em;
    }

    .icon.in-menu-contact {
        font-size: 1em;
    }

    .fullscreen-menu {
        z-index: 1050;
        flex-direction: column;
        justify-content: flex-start;
        align-items: flex-start;
        display: none;
        position: fixed;
        top: 0%;
        bottom: 0%;
        left: 0%;
        right: 0%;
        overflow: hidden;
    }

    .menu-layout {
        z-index: 10;
        height: 100%;
        width: 100%;
        flex-direction: row;
        flex: 1;
        justify-content: space-between;
        align-self: stretch;
        align-items: stretch;
        display: flex;
        position: relative;
        overflow: hidden;
    }

    .menu-links {
        z-index: 10;
        min-width: 30%;
        grid-row-gap: 4vh;
        background-color: #34e7d7;
        flex-direction: column;
        justify-content: space-between;
        align-items: center;
        padding: 8em 4vw;
        display: flex;
        position: relative;
        overflow: auto;
    }

    .menu-links-wrapper {
        z-index: 10;
        grid-row-gap: 4vh;
        text-align: left;
        flex-direction: column;
        flex: 1;
        justify-content: space-between;
        align-items: flex-start;
        display: flex;
        position: relative;
    }

    .menu-main-links {
        width: 100%;
        flex-direction: column;
        justify-content: flex-start;
        align-items: flex-start;
        display: flex;
    }

    .menu-link-text {
        border-bottom: 4px solid rgba(255, 255, 255, 0);
        color: #044c46;
        padding-top: 1.2vh;
        font-size: 2.3em;
        font-weight: 400;
        line-height: 1.3em;
        transition: color 0.2s;
    }

    .menu-link-text:hover {
        color: #011917;
    }

    .menu-link-text.w--current {
        border-bottom-color: #044c46;
        color: #011917;
    }

    .menu-small-links {
        grid-row-gap: 1em;
        flex-direction: column;
        justify-content: flex-start;
        align-items: flex-start;
        display: flex;
    }

    .menu-small-link {
        z-index: 10;
        border-bottom: 2px solid rgba(255, 255, 255, 0);
        color: #06665d;
        font-size: 1.3em;
        font-weight: 500;
        line-height: 1.6em;
        transition: color 0.2s;
        position: relative;
    }

    .menu-small-link:hover {
        color: #011917;
    }

    .menu-small-link.w--current {
        border-bottom-color: #011917;
        color: #011917;
    }

    .menu-works {
        z-index: 10;
        grid-row-gap: 4vh;
        border-right: 1px solid #077f75;
        background-color: #141719;
        flex-direction: column;
        justify-content: space-between;
        align-items: stretch;
        min-width: 35%;
        padding: 8em 4vw;
        display: flex;
        position: relative;
        overflow: hidden;
    }

    .menu-works-wrapper {
        width: 100%;
        flex: 1;
        display: flex;
    }

    .menu-works-list {
        width: 100%;
        flex-direction: column;
        justify-content: space-between;
        align-items: stretch;
        display: flex;
    }

    .menu-work-item {
        width: 100%;
        flex: 1;
        display: flex;
    }

    .menu-work-link-block {
        grid-column-gap: 3vw;
        border: 1px solid rgba(255, 255, 255, 0);
        color: #0ab3a3;
        align-items: center;
        padding: 4vh 2vw;
        transition: border-color 0.2s, background-color 0.2s;
        display: flex;
        position: relative;
        overflow: hidden;
    }

    .menu-work-link-block:hover {
        border: 1px solid #077f75;
        background-color: rgba(114, 250, 238, 0.1);
        color: #34e7d7;
        overflow: hidden;
    }

    .menu-work-image {
        width: 35%;
        border-radius: 6px;
        flex: none;
        align-self: stretch;
        position: relative;
        overflow: hidden;
    }

    .cover-image {
        z-index: 1;
        text-align: center;
        object-fit: cover;
        width: 100%;
        height: 100%;
    }

    .cover-image.is-parallax {
        width: 120%;
        top: auto;
        bottom: auto;
        left: auto;
        right: auto;
    }

    .menu-work-title {
        letter-spacing: 0.05em;
        text-transform: uppercase;
        font-family: Antonio Variable, sans-serif;
        font-size: 1.5em;
        line-height: 1.1em;
        overflow: hidden;
        text-align: start;
    }

    .menu-work-title-block {
        padding-top: 1em;
        padding-bottom: 1em;
        overflow: hidden;
    }

    .menu-socials {
        z-index: 10;
        min-width: 10%;
        grid-row-gap: 4vh;
        background-color: #141719;
        flex-direction: column;
        justify-content: space-between;
        align-items: stretch;
        display: flex;
        position: relative;
        overflow: auto;
    }

    .empty-state-hidden {
        display: none;
    }

    .menu-socials-wrapper {
        width: 100%;
        flex: 1;
        align-self: stretch;
        display: flex;
    }

    .menu-socials-list {
        width: 100%;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        display: flex;
    }

    .menu-social-item {
        width: 100%;
        flex: 1;
        transition: width 0.6s cubic-bezier(0.075, 0.82, 0.165, 1);
        display: flex;
    }

    .menu-social-link-block {
        z-index: 10;
        width: 100%;
        min-height: 5em;
        opacity: 0.5;
        flex-direction: column;
        flex: 1;
        justify-content: center;
        align-self: stretch;
        align-items: center;
        padding-left: 0.5em;
        padding-right: 0.5em;
        transition: opacity 0.2s, background-color 0.2s;
        display: flex;
        position: relative;
    }

    .menu-social-link-block:hover {
        background-color: rgba(114, 250, 238, 0.1);
        opacity: 1;
        overflow: hidden;
    }

    .menu-contact {
        z-index: 10;
        min-width: 15%;
        border-right: 1px solid #077f75;
        border-left: 1px solid #077f75;
        background-color: #011917;
        flex-direction: column;
        justify-content: space-between;
        align-items: stretch;
        padding: 2vw;
        display: flex;
        position: relative;
        overflow: hidden;
    }

    .menu-contact-link-block {
        z-index: 10;
        width: 100%;
        height: 100%;
        grid-column-gap: 0.75em;
        border-style: solid;
        border-width: 0 1px 1px;
        border-color: #077f75;
        -webkit-backdrop-filter: blur(15px);
        backdrop-filter: blur(15px);
        color: #34e7d7;
        flex-flow: row;
        justify-content: center;
        align-items: center;
        padding: 3vh 1vw;
        font-size: 1.5em;
        font-weight: 700;
        line-height: 1.9em;
        transition: background-color 0.2s, height 0.6s cubic-bezier(0.075, 0.82, 0.165, 1);
        display: flex;
        position: relative;
    }

    .menu-contact-link-block:hover {
        z-index: 11;
        height: 120%;
        background-color: rgba(114, 250, 238, 0.1);
    }

    .menu-contact-link-block.w--current {
        background-color: rgba(114, 250, 238, 0.1);
    }

    .menu-contact-link-block.is-1st {
        border-top-width: 1px;
    }

    .menu-contact-link-text {
        letter-spacing: 0.1em;
        text-transform: uppercase;
        font-size: 0.7em;
        font-weight: 500;
    }

    .menu-contact-background {
        z-index: 1;
        opacity: 0.1;
        position: absolute;
        top: 0%;
        bottom: 0%;
        left: 0%;
        right: 0%;
    }

    .menu-delay-for-animation {
        z-index: 999;
        display: none;
        position: fixed;
        top: 0%;
        bottom: 0%;
        left: 0%;
        right: 0%;
    }

    .menu-close {
        z-index: 10;
        background-color: #282d33;
        cursor: pointer;
        flex-direction: column;
        flex: 1;
        justify-content: center;
        align-self: stretch;
        align-items: center;
        padding: 5em;
        font-size: 0.8em;
        transition: background-color 0.5s;
        display: flex;
        position: relative;
        overflow: hidden;
    }

    .menu-close:hover {
        background-color: #323940;
    }

    .menu-close-button {
        z-index: 100;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        display: flex;
        position: relative;
    }

    .menu-close-button-text {
        z-index: 10;
        color: #34e7d7;
        letter-spacing: 0.6em;
        text-indent: 0.6em;
        text-transform: uppercase;
        margin-top: 5em;
        font-weight: 500;
        position: relative;
        transform: rotate(90deg);
    }

    .icon {
        z-index: 10;
        object-fit: contain;
        flex: none;
        width: 1em;
        height: 1em;
        font-size: 1.3em;
        position: relative;
    }

    @media screen and (min-width: 1440px) {
        .fullscreen-menu {
            display: none;
        }
    }

    @media screen and (max-width: 991px) {
        .icon.is-menu-close-icon {
            font-size: 2em;
            overflow: hidden;
        }

        .menu-layout {
            flex-wrap: wrap;
            overflow: auto;
        }

        .menu-links {
            width: 100%;
            min-width: auto;
            grid-row-gap: 3em;
            padding: 8em 8vw 6em;
            overflow: hidden;
        }

        .menu-links-wrapper {
            grid-row-gap: 3em;
        }

        .menu-main-links {
            grid-column-gap: 2.75em;
            grid-row-gap: 1.5em;
            flex-flow: wrap;
        }

        .menu-link-text {
            align-self: auto;
            padding-top: 0;
        }

        .menu-small-links {
            grid-column-gap: 2.5em;
            grid-row-gap: 0.25em;
            flex-flow: wrap;
        }

        .menu-small-link {
            align-self: flex-start;
            padding-top: 0.3em;
            padding-bottom: 0.3em;
            line-height: 1.4em;
        }

        .menu-small-link:hover {
            background-image: none;
            background-position: 0 0;
            background-repeat: repeat;
            background-size: auto;
        }

        .menu-works {
            width: 100%;
            min-width: auto;
            grid-row-gap: 3em;
            padding: 6em 8vw;
            overflow: hidden;
        }

        .menu-work-item {
            border: 1px solid #06665d;
            margin-top: -1px;
            position: relative;
            overflow: hidden;
        }

        .menu-work-link-block {
            grid-column-gap: 5vw;
            padding: 1em;
            overflow: hidden;
        }

        .menu-work-link-block:hover {
            border-color: rgba(255, 255, 255, 0);
            background-color: rgba(255, 255, 255, 0);
            overflow: hidden;
        }

        .menu-work-image {
            min-height: 6em;
            overflow: hidden;
        }

        .menu-work-title {
            font-size: 1.8em;
            overflow: hidden;
        }

        .menu-socials {
            width: 100%;
            min-width: auto;
            grid-row-gap: 3em;
            border-top: 1px solid #077f75;
            border-bottom: 1px solid #077f75;
            padding: 2em 8vw;
            overflow: hidden;
        }

        .menu-social-item {
            width: auto;
            flex: 0 auto;
            overflow: hidden;
        }

        .menu-social-link-block {
            width: auto;
            opacity: 1;
            flex: 0 auto;
            padding-left: 6vw;
            padding-right: 6vw;
            overflow: hidden;
        }

        .menu-social-link-block:hover {
            background-color: rgba(255, 255, 255, 0);
            overflow: hidden;
        }

        .menu-contact {
            width: 100%;
            min-width: auto;
            border-right-color: rgba(255, 255, 255, 0);
            border-left-color: rgba(255, 255, 255, 0);
            flex-flow: wrap;
            overflow: hidden;
        }

        .menu-contact-link-block {
            width: 25%;
            height: auto;
            border-top-width: 1px;
            padding-top: 3.5em;
            padding-bottom: 3.5em;
            overflow: hidden;
        }

        .menu-contact-link-block:hover {
            height: auto;
        }

        .menu-contact-link-block.is-1st {
            border-right-width: 0;
            overflow: hidden;
        }

        .menu-close {
            z-index: 100;
            background-color: #06665d;
            border-radius: 50%;
            padding: 1.5em;
            position: absolute;
            top: 1%;
            bottom: auto;
            left: auto;
            right: 2%;
            overflow: hidden;
        }

        .menu-close:hover {
            overflow: hidden;
        }

        .menu-close-button,
        .menu-close-button:hover {
            overflow: hidden;
        }

        .menu-close-button-text {
            display: none;
            overflow: hidden;
        }
    }

    @media screen and (max-width: 767px) {
        .fullscreen-menu {
            font-size: 1em;
        }

        .menu-main-links {
            grid-column-gap: 3vw;
            grid-row-gap: 1em;
        }

        .menu-work-image {
            width: 28%;
            overflow: hidden;
        }

        .menu-work-title {
            font-size: 2em;
            overflow: hidden;
        }

        .menu-socials {
            padding-top: 4em;
            padding-bottom: 4em;
        }

        .menu-contact {
            padding: 4vw;
        }

        .menu-close {
            font-size: 0.7em;
            overflow: hidden;
        }

        .menu-close-button {
            overflow: hidden;
        }

        .menu-contact-link-block {
            width: 50%;
            padding-top: 2em;
            padding-bottom: 2em;
        }

        .menu-contact-link-text {
            font-size: 0.8em;
        }
    }

    @media screen and (max-width: 479px) {
        .menu-links {
            padding-top: 4em;
            padding-bottom: 4em;
        }

        .menu-small-links,
        .menu-main-links {
            grid-column-gap: 6vw;
        }

        .menu-link-text {
            font-size: 9vw;
        }

        .menu-works {
            padding-top: 4em;
            padding-bottom: 4em;
        }

        .menu-work-link-block {
            grid-column-gap: 6vw;
            padding: 4vw;
            overflow: hidden;
        }

        .menu-work-image {
            min-height: 4em;
        }

        .cover-image {
            top: 0%;
            bottom: 0%;
            left: 0%;
            right: 0%;
        }

        .menu-work-title {
            font-size: 1.6em;
            overflow: hidden;
        }

        .menu-social-link-block {
            font-size: 0.9em;
        }

        .menu-contact {
            padding: 8vw;
        }

        .menu-contact-link-block {
            font-size: 1.4em;
        }

        .menu-close {
            font-size: 0.5em;
            overflow: hidden;
        }

        .menu-close-button {
            overflow: hidden;
        }
    }
`;

interface NewNavbarMenuProps {
    showMenu: boolean;
    onMenuClose: (showMenu: boolean) => void;
}

const NewNavbarMenu = ({ showMenu, onMenuClose }: NewNavbarMenuProps) => {
    if (!showMenu) {
        return <></>;
    }

    return (
        <OverlayContainer id="myNav">
            <StyledContainer className="fullscreen-menu overlay-content">
                <div className="menu-layout">
                    <div className="menu-links">
                        <div className="menu-links-wrapper">
                            <div className="menu-main-links">
                                <a href={entityPresentationSiteUrl} aria-current="page" className="menu-link-text w--current">
                                    Home
                                </a>
                                <a href={`${entityPresentationSiteUrl}#products`} className="menu-link-text">
                                    Launchpad
                                </a>
                                <a href={`${entityPresentationSiteUrl}#products`} className="menu-link-text">
                                    Cross-Chain Swap
                                </a>
                                <a href={`${entityPresentationSiteUrl}#products`} className="menu-link-text">
                                    Bridge Aggregator
                                </a>
                                <a href={`${entityPresentationSiteUrl}#team`} className="menu-link-text">
                                    Team
                                </a>
                                <a href="https://entity.gitbook.io/entity" className="menu-link-text" target="_blank">
                                    Knowledge Base
                                </a>
                                <a
                                    href="https://app.galxe.com/quest/NoCq8h2VXsBBaKZxFhQz8J/GCawhthesx"
                                    target="_blank"
                                    className="menu-link-text"
                                >
                                    Campaigns
                                </a>
                                <a href="https://app.entity.global/unlock" className="menu-link-text">
                                    Launch App
                                </a>
                            </div>
                            <div className="menu-small-links"></div>
                        </div>
                    </div>
                    <div className="menu-works">
                        <div className="menu-works-wrapper w-dyn-list">
                            <div role="list" className="menu-works-list w-dyn-items">
                                <div role="listitem" className="menu-work-item w-dyn-item">
                                    <a href={`${entityPresentationSiteUrl}#products`} className="menu-work-link-block">
                                        <div className="menu-work-image">
                                            <img src={ProductIconOne} loading="lazy" alt="" className="cover-image" />
                                        </div>
                                        <div className="menu-work-title-block">
                                            <p className="menu-work-title">
                                                Cross-Chain
                                                <br />
                                                Launchpad
                                            </p>
                                        </div>
                                    </a>
                                </div>
                                <div role="listitem" className="menu-work-item">
                                    <a
                                        href={`${entityPresentationSiteUrl}#products`}
                                        className="menu-work-link-block w-inline-block"
                                    >
                                        <div className="menu-work-image">
                                            <img src={ProductIconTwo} loading="lazy" alt="" className="cover-image" />
                                        </div>
                                        <div className="menu-work-title-block">
                                            <p className="menu-work-title w-dyn-bind-empty">Bridge Aggregator</p>
                                        </div>
                                    </a>
                                </div>
                                <div role="listitem" className="menu-work-item w-dyn-item">
                                    <a
                                        href={`${entityPresentationSiteUrl}#products`}
                                        className="menu-work-link-block w-inline-block"
                                    >
                                        <div className="menu-work-image">
                                            <img src={ProductIconThree} loading="lazy" alt="" className="cover-image" />
                                        </div>
                                        <div className="menu-work-title-block">
                                            <p className="menu-work-title">Cross-Chain Swap</p>
                                        </div>
                                    </a>
                                </div>
                            </div>
                            <div className="empty-state-hidden w-dyn-empty">
                                <div>No items found.</div>
                            </div>
                        </div>
                    </div>
                    <div className="menu-socials">
                        <div className="menu-socials-wrapper w-dyn-list">
                            <div role="list" className="menu-socials-list w-dyn-items">
                                <div role="listitem" className="menu-social-item w-dyn-item">
                                    <a
                                        href="https://discord.gg/AH5y6uhJXH"
                                        target="_blank"
                                        className="menu-social-link-block w-inline-block"
                                    >
                                        <DiscordIcon width={26} height={26} />
                                    </a>
                                </div>
                                <div role="listitem" className="menu-social-item w-dyn-item">
                                    <a
                                        href="https://twitter.com/EntityFinance"
                                        target="_blank"
                                        className="menu-social-link-block w-inline-block"
                                    >
                                        <TwitterIcon width={26} height={26} />
                                    </a>
                                </div>
                                <div role="listitem" className="menu-social-item w-dyn-item">
                                    <a
                                        href="https://t.me/EntityCommunity"
                                        target="_blank"
                                        className="menu-social-link-block w-inline-block"
                                    >
                                        <TelegramIcon width={42} height={42} />
                                    </a>
                                </div>
                                <div role="listitem" className="menu-social-item w-dyn-item">
                                    <a
                                        href="https://www.linkedin.com/company/entity-finance/"
                                        target="_blank"
                                        className="menu-social-link-block w-inline-block"
                                    >
                                        <LinkedinIcon width={26} height={26} />
                                    </a>
                                </div>
                            </div>
                            <div className="empty-state-hidden w-dyn-empty">
                                <div>No items found.</div>
                            </div>
                        </div>
                    </div>
                    <div className="menu-contact">
                        <a
                            href="mailto:info@entity.global?subject=Contact%20from%20website"
                            className="menu-contact-link-block is-1st w-inline-block"
                        >
                            <p className="menu-contact-link-text">Email</p>
                            <img src={ContactEmail} loading="lazy" alt="" className="icon in-menu-contact mb-3" />
                        </a>
                        <a href="https://app.entity.global/contact" className="menu-contact-link-block w-inline-block">
                            <p className="menu-contact-link-text">Contact</p>
                            <img src={ContactArrow} loading="lazy" alt="" className="icon in-menu-contact mb-3" />
                        </a>
                        <div className="menu-contact-background">
                            <img src={ContactBackground} loading="lazy" sizes="100vw" alt="" className="cover-image" />
                        </div>
                    </div>
                    <div className="menu-close" onClick={() => onMenuClose(false)}>
                        <div className="menu-close-button">
                            <img src={CloseButton} loading="lazy" alt="" className="icon is-menu-close-icon" />
                            <div className="menu-close-button-text">Close</div>
                        </div>
                    </div>
                </div>
                <div className="menu-background"></div>
            </StyledContainer>
        </OverlayContainer>
    );
};

export default NewNavbarMenu;
