import styled from "styled-components";
import { EButton } from "./index";

const EButtonSmall = styled(EButton)`
    min-width: 3rem;
    padding: 0.1rem 0.3rem 0.1rem 0.3rem;
    text-transform: inherit;
`;

export default EButtonSmall;
