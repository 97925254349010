import React from "react";
import { Card, Col, Container, Row } from "react-bootstrap";
import { PageHeader } from "../../components/PageHeader";
import MetaDecorator from "../../common/components/MetaDecorator";
import { seoData } from "../../helpers/consts/SEO";
import { routeNames } from "../../routes/routes";
import { BackgroundDecorations } from "../../components/BackgroundDecorations";
import styled from "styled-components";
import { entitySiteUrl } from "../../config";

const Header = styled.div`
    display: flex;
    padding: 0 !important;
    margin-bottom: 0.8rem;

    h1 {
        margin: 0;
    }
`;

const Imprint = () => {
    return (
        <div>
            <BackgroundDecorations />
            <Container className="py-2 pb-md-5">
                <MetaDecorator
                    title={seoData.imprint.title}
                    description={seoData.imprint.description}
                    keywords={seoData.imprint.keywords}
                    canonical={routeNames.imprint}
                />
                <div className="py-0 py-sm-2 pt-md-4 mt-3">
                    <Col className="px-1 px-md-5 px-xl-1 mt-2">
                        <Header className="justify-content-center justify-content-sm-start">
                            <PageHeader>Imprint</PageHeader>
                        </Header>
                    </Col>
                </div>
                <Row className="mt-5">
                    <Col md={12}>
                        <Card>
                            <Card.Body>
                                <p>
                                    Any content &copy; ENT Technologies AG. <br />
                                    All rights reserved.
                                </p>

                                <>
                                    <h3>Information according to Art. 5 E-Commerce-Gesetz (Liechtenstein E-Commerce Act):</h3>
                                    <p>
                                        ENT Technologies AG <br />
                                        Pradafant 11 <br />
                                        9490 Vaduz, Liechtenstein
                                    </p>
                                    <p>
                                        E-mail:{" "}
                                        <a className="text-green" href="mailto:info@entity.global">
                                            info@entity.global
                                        </a>
                                        <br />
                                        Public register number: FL-0002.683.018-6
                                    </p>
                                </>

                                <>
                                    <h3>Publication and Administration:</h3>
                                    <p>
                                        ENT Technologies AG <br />
                                        Pradafant 11 <br />
                                        9490 Vaduz, Liechtenstein
                                    </p>
                                    <p>
                                        E-mail:{" "}
                                        <a className="text-green" href="mailto:info@entity.global">
                                            info@entity.global
                                        </a>
                                    </p>
                                </>

                                <p>
                                    <h3>Responsible for the Content</h3>
                                    Wolfgang Rückerl
                                </p>

                                <p>
                                    <h3>Authorised representatives</h3>
                                    Wolfgang Rückerl
                                </p>

                                <>
                                    <h3>Disclaimer (Limitation of liability)</h3>
                                    <p>
                                        This is the website of ENT Technologies AG. The following applies to all pages and
                                        sub-pages of this website. Anyone who accesses these web pages and retrieves information
                                        declares to agree to the following provisions:{" "}
                                    </p>
                                    <p>
                                        ENT Technologies AG makes every effort to ensure the secure operation of the website.
                                        Nevertheless, errors such as loss or corruption of data, virus attacks, interruption of
                                        service, etc. cannot be ruled out. Accessing the website is therefore at the user&apos;s
                                        own risk and responsibility.
                                    </p>
                                    <p>
                                        The content of this website is a general information of ENT Technologies AG. Any content
                                        of this website is therefore intended for general information purposes only and does not
                                        constitute a prospectus, an offer document, an offer of securities, an invitation to
                                        invest, an offer to sell a product or an investment advice.
                                    </p>
                                    <p>
                                        ENT Technologies AG assumes no liability for the topicality, correctness and completeness
                                        of any information provided on this website. The same applies to the content of external
                                        sites to which direct or indirect reference is made. We reserve the right to make changes
                                        or additions to the information provided without prior notice.
                                    </p>
                                    <p>
                                        Any liability for the content of the website, for damages or consequential damages
                                        resulting from accessing the website, or in connection with retrieved information or
                                        documents, or the use (or inability to access or use) is excluded. This includes both
                                        material and immaterial damages.
                                    </p>
                                    <p>
                                        <u>Linked Websites (Links)</u>
                                    </p>
                                    <p>
                                        {" "}
                                        Some of the web pages may contain links to third-party websites. ENT Technologies AG
                                        assumes no responsibility for the content, the products, services or other offers
                                        third-party websites contain, or for their compliance with data protection regulations.
                                        The activation of a link is at the user&apos;s own risk.
                                    </p>
                                    <p>
                                        Link to Privacy policy:
                                        <br />
                                        <p>
                                            <a
                                                className="text-green"
                                                href={`${entitySiteUrl}/privacy/policy`}
                                                target="_blank"
                                                rel="noreferrer"
                                            >
                                                {entitySiteUrl}/privacy/policy
                                            </a>
                                        </p>
                                    </p>
                                </>
                            </Card.Body>
                        </Card>
                    </Col>
                </Row>
            </Container>
        </div>
    );
};

export default Imprint;
