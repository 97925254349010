import axios from "axios";
import { Portfolio, Token } from "../common/types/portfolioTypes";
import BaseService from "./BaseService";
import { NftsListFilterArray } from "./WalletsService";
import { Nft } from "../pages/Nft/NftWallet";

export interface PortfolioResponse {
    portfolio: Portfolio;
    tokens: Token[];
}

class PortfolioService extends BaseService {
    private readonly _urlPrefix: string;

    constructor(entityApi: string) {
        super();
        this._urlPrefix = `${entityApi}/portfolio`;
    }

    public getWalletsBalance(publicAddresses: string[]): Promise<PortfolioResponse> {
        return this.doCall(() => axios.post(this._urlPrefix, { publicAddresses }));
    }

    public retrieveWalletsNfts(params: NftsListFilterArray): Promise<Nft[]> {
        return this.doCall(() => axios.post<Nft[]>(this._urlPrefix + "/nfts", params));
    }
}

export default PortfolioService;
