import React, { FC, memo } from "react";
import { StaticTable, TableVariant } from "../../../../../common/components/StaticTable";
import { TokenSale as ITokenSale } from "../../../launchpad.types";
import { TBA } from "../../../../../helpers/consts";
import Number from "../../../../../components/Price/Number";
import { Currency, CurrencySymbol } from "../../../../../common/utils/CurrencyUtils";
import { useService } from "../../../../../services/config/dependencyInjectorConfig";
import { LaunchpadService } from "../../../../../services/LaunchpadService";
import { useQuery } from "@tanstack/react-query";
import { network } from "../../../../../config";
import OverlayInfoTooltip from "../../../../../components/common/OverlayInfoTooltip";

interface TokenSaleProps {
    projectId?: string;
    tokensPerTicket?: number;
    privateSale?: ITokenSale;
    publicSale?: ITokenSale;
    tokenName?: string;
}

const headersLeft = ["Seed Sale", "Public Sale"];
const headersTop = ["Tokens Offered", "Price", "Tokens Sold", "Funds Raised"];

const TokenSale: FC<React.PropsWithChildren<TokenSaleProps>> = ({
    projectId,
    tokensPerTicket,
    privateSale,
    publicSale,
    tokenName,
}) => {
    const [statisticsService] = useService(LaunchpadService);

    const { isLoading, data: ticketsOverview } = useQuery({
        queryKey: ["tickets-overview"],
        enabled: !!projectId,
        retry: false,
        queryFn: () => projectId && statisticsService.getTicketsStatistics(projectId),
    });

    const rows =
        !!privateSale && !!publicSale && !isLoading
            ? [
                  [
                      privateSale.offeredTokens ? <Number value={privateSale.offeredTokens} suffix={tokenName} /> : TBA,
                      privateSale.tokenPrice ? (
                          <Number value={privateSale.tokenPrice} suffix={CurrencySymbol[Currency.USD]} />
                      ) : (
                          TBA
                      ),
                      privateSale.soldTokens ? <Number value={privateSale.soldTokens} suffix={tokenName} /> : TBA,
                      privateSale.tokenPrice && privateSale.soldTokens ? (
                          <Number value={privateSale.tokenPrice * privateSale.soldTokens} suffix={CurrencySymbol[Currency.USD]} />
                      ) : (
                          TBA
                      ),
                  ],
                  [
                      publicSale.offeredTokens ? <Number value={publicSale.offeredTokens} suffix={tokenName} /> : TBA,
                      publicSale.tokenPrice ? (
                          <Number value={publicSale.tokenPrice} suffix={CurrencySymbol[Currency.USD]} />
                      ) : (
                          TBA
                      ),
                      ticketsOverview && tokensPerTicket ? (
                          ticketsOverview.totalWonTickets * tokensPerTicket > 0 ? (
                              <Number value={ticketsOverview.totalWonTickets * tokensPerTicket} suffix={tokenName} />
                          ) : (
                              TBA
                          )
                      ) : (
                          TBA
                      ),
                      ticketsOverview && ticketsOverview.totalUsdPaid ? (
                          <span>
                              <Number value={ticketsOverview.totalUsdPaid} suffix={CurrencySymbol[Currency.USD]} />
                              {ticketsOverview.totalEgldPaid && (
                                  <OverlayInfoTooltip
                                      tooltipText={<Number value={ticketsOverview.totalEgldPaid} suffix={network.egldLabel} />}
                                      iconColor="var(--field-bg-color)"
                                  />
                              )}
                          </span>
                      ) : (
                          TBA
                      ),
                  ],
              ]
            : undefined;

    return <StaticTable variant={TableVariant.headerTopAndLeft} headersLeft={headersLeft} headersTop={headersTop} rows={rows} />;
};

export default memo(TokenSale);
