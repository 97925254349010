export interface KycAccount extends KycComment {
    email: string;
    emailConfirmedTimestamp: number;
    walletAddress: string;
    launchpadProjectId: string;
    kycRef: string;
    kycAttempts: number;
    initKycStatus: InitKycStatus;
    initKycStatusTimestamp: number;
    autoKycStatus: InitKycStatus;
    autoKycStatusTimestamp: number;
    kycStatus: FinalKycStatus;
    kycStatusTimestamp: number | null;
    kycSubResult: KycSubResult | null;
    kycFlow?: "ADVANCED" | "REGULAR";
    isWatchlistRejected: boolean;
    riskScore?: number;
}

export interface KycComment {
    comment: string | null;
    commentUpdatedAt: number | null;
    commentUpdatedBy: string | null;
}

export enum InitKycStatus {
    NOT_STARTED = "null",
    AWAIT_VERIFICATION = "AWAIT_VERIFICATION",
    VERIFIED = "VERIFIED",
    REJECTED = "REJECTED",
    FINAL_REJECTED = "FINAL_REJECTED",
}

export const initKycStatusOptions: InitKycStatus[] = [
    InitKycStatus.AWAIT_VERIFICATION,
    InitKycStatus.VERIFIED,
    InitKycStatus.REJECTED,
    InitKycStatus.FINAL_REJECTED,
];

export const INIT_KYC_STATUS_NOT_STARTED = null;

export enum FinalKycStatus {
    NOT_REVIEWED = "null",
    APPROVED = "APPROVED",
    DENIED = "DENIED",
}

export const finalKycStatusOptions: FinalKycStatus[] = [FinalKycStatus.APPROVED, FinalKycStatus.DENIED];

export const FINAL_KYC_STATUS_NOT_REVIEWED = null;

export enum KycSubResult {
    // If all underlying verifications passed
    CLEAR = "clear",

    // If the report has returned information where the check cannot
    // be processed further (for example, poor image quality or unsupported document).
    REJECTED = "rejected",

    // The analysed document is suspected to be fraudulent
    SUSPECTED = "suspected",

    // If any other underlying verifications fail, but they don't necessarily point to a
    // fraudulent document (for example, the name provided by the applicant doesn't match the one on the document)
    CAUTION = "caution",

    // default option if report sub result can not be mapped
    UNKNOWN = "unknown",
}

export interface KycStatusInfo {
    initStatus: InitKycStatus | null;
    status: FinalKycStatus | null;
    attemptsRemaining: number;
    rejectedReasons: string[] | null;
}

export interface LotteryWalletStatistics {
    walletAddress: string;
    confirmedTickets: number;
    wonTickets: number;
    hasClaimedTokens: boolean;
    isBlacklisted: boolean;
    egldPaid: number;
    usdPaid: number;
}

export const initialKycStatusInfo: KycStatusInfo = {
    initStatus: null,
    status: null,
    attemptsRemaining: 0,
    rejectedReasons: null,
};

export const NOT_STARTED_INIT_KYC_STATUS = "NOT STARTED";
export const NOT_FINALIZED_FINAL_KYC_STATUS = "NOT FINALIZED";

export const toInitKycStatusLabel = (status: InitKycStatus | keyof typeof InitKycStatus | null): string =>
    status ? status.replace("_", " ").toUpperCase() : NOT_STARTED_INIT_KYC_STATUS;

export const fromInitKycStatusLabel = (status: string): string =>
    status !== NOT_STARTED_INIT_KYC_STATUS ? status.replace(" ", "_") : "null";

export const fromFinalKycStatusLabel = (status: string): string => (status !== NOT_FINALIZED_FINAL_KYC_STATUS ? status : "null");

export const toKycSubResultLabel = (kycSubResult: KycSubResult | null): string => kycSubResult?.toUpperCase() ?? "-";

export const fromKycSubResultLabel = (kycSubResult: string): string => kycSubResult.toLowerCase();

export const toCommentLabel = (comment: string | null) => {
    if (!comment) return "-";
    if (comment.length < 15) return comment;
    return comment.substring(0, 15) + "...";
};

export const isNotAllowedToStartKycAgain = (kycStatus: KycStatusInfo) => {
    return (
        kycStatus &&
        !(kycStatus.attemptsRemaining > 0 && (kycStatus.initStatus === null || kycStatus.initStatus === InitKycStatus.REJECTED))
    );
};
