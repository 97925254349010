class BinaryUtils {
    static base64Encode(str: string) {
        return Buffer.from(str).toString("base64");
    }

    static stringToHex(str: string): string {
        return Buffer.from(str).toString("hex");
    }

    static hexToNumber(hex: string): number {
        return parseInt(hex, 16);
    }

    static decimalToHexadecimal(number: number): string {
        return "0" + number.toString(16);
    }

    static fromBase64ToString(str: string): string {
        return Buffer.from(str, "base64").toString();
    }

    static fromHexToString(str: string): string {
        return Buffer.from(str, "hex").toString();
    }
}

export default BinaryUtils;
