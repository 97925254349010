import React, { ReactNode, useCallback, useMemo } from "react";
import Title from "../../components/Title";
import { EShowMoreText } from "../../../../components/ShowMore";
import { Descriptions } from "./Investment";
import { ProjectInvestmentDetails, ProjectTiersDetails } from "../../launchpad.types";
import { EPlaceholder } from "../../../../components/Placeholder";
import StringUtils from "../../../../common/utils/StringUtils";
import Number from "../../../../components/Price/Number";
import { TBA } from "../../../../helpers/consts";
import { useQueryClient } from "@tanstack/react-query";
import { LaunchpadQueryKeys } from "../../../../common/types/queryKeysTypes";

const Exclusions = ({ investmentInfo }: { investmentInfo?: ProjectInvestmentDetails }) => {
    const queryClient = useQueryClient();

    const computeRestrictedCountriesText = useCallback(
        () => investmentInfo?.restrictedCountries?.map((country) => country.name).join(", "),
        [investmentInfo?.restrictedCountries],
    );

    const limitedCountries = useMemo(() => {
        if (!investmentInfo?.limitedCountries) return <></>;
        const countries = investmentInfo.limitedCountries.map((country) => country.name).join(", ");
        let maxInvestAmount: ReactNode | string = TBA;
        const tiers = queryClient.getQueryData<ProjectTiersDetails>([LaunchpadQueryKeys.PROJECT_TIERS_DETAILS])?.tiers;
        if (!!investmentInfo.limitedCountriesMaxTierNo && tiers) {
            const amount = tiers.filter((tier) => tier.no === investmentInfo.limitedCountriesMaxTierNo)[0]
                .maxAmountPerUser as string;
            maxInvestAmount = <Number value={amount} prefix={StringUtils.US_DOLLAR} />;
        }
        return (
            <>
                <Title title="Limited Countries" className="mt-2" />
                <EShowMoreText>
                    <Descriptions>
                        People from the following countries are restricted to invest up to {maxInvestAmount}: <br />
                        {countries}.
                    </Descriptions>
                </EShowMoreText>
            </>
        );
    }, [investmentInfo?.limitedCountries]);

    return (
        <>
            {limitedCountries}
            <Title title="Exclusions" className="mt-5" />
            {!investmentInfo ? (
                <EPlaceholder numberOfLines={10} />
            ) : (
                <>
                    <EShowMoreText>
                        {investmentInfo.restrictedCountries && (
                            <Descriptions>
                                People from the following countries are excluded from participating in the IDO (a person can’t be
                                a citizen, resident, located, or domiciled in any of these countries): <br />
                                {computeRestrictedCountriesText()}.
                            </Descriptions>
                        )}
                    </EShowMoreText>
                    <EShowMoreText>
                        {investmentInfo.excludedCountriesUrl && (
                            <>
                                <Descriptions>For an updated list of excluded countries, please visit:</Descriptions>
                                <a className="text-green" href={investmentInfo.excludedCountriesUrl}>
                                    {investmentInfo.excludedCountriesUrl}
                                </a>
                            </>
                        )}
                    </EShowMoreText>
                </>
            )}
        </>
    );
};

export default Exclusions;
