import BaseService from "./BaseService";
import axios from "axios";
import { SettingsWallet, SyncTxsWallet, Wallet } from "../common/types/walletTypes";
import { network } from "../config";
import { NftScResp } from "../pages/Nft/NftProgress";

export interface WalletsResponse {
    data: Wallet[];
    total: number;
}

export interface NftsListFilter {
    from: number;
    size: number;
    creator?: string;
    publicAddress: string;
    type: string;
}

export interface NftsListFilterArray {
    creator?: string;
    publicAddresses: string[];
    type: string;
}

class WalletsService extends BaseService {
    private readonly _entityApi: string;

    constructor(entityApi: string) {
        super();
        this._entityApi = entityApi;
    }

    public countAllWallets(): Promise<number> {
        return this.doCall(() => axios.get<number>(`${this._entityApi}/wallets/count`));
    }

    public getAllWallets(userId: string, from?: number, size?: number, monitoredOnly = true): Promise<WalletsResponse> {
        return this.doCall(() =>
            axios.get<WalletsResponse>(`${this._entityApi}/wallets`, { params: { userId, from, size, monitoredOnly } }),
        );
    }

    public getAllSettingsWallets(userId: string): Promise<SettingsWallet[]> {
        return this.doCall(() => axios.get<SettingsWallet[]>(`${this._entityApi}/wallets/settings`, { params: { userId } }));
    }

    public saveSettingsWallet(wallet: SettingsWallet): Promise<SettingsWallet> {
        return this.doCall(() => axios.post<SettingsWallet>(`${this._entityApi}/wallets`, wallet));
    }

    public updateSettingsWallet(walletId: string, wallet: SettingsWallet): Promise<SettingsWallet> {
        return this.doCall(() => axios.put<SettingsWallet>(`${this._entityApi}/wallets/${walletId}`, wallet));
    }

    public updateWalletSyncTxs(walletId: string, walletSyncTxs: SyncTxsWallet): Promise<SettingsWallet> {
        return this.doCall(() => axios.put<SettingsWallet>(`${this._entityApi}/wallets/${walletId}/sync-txs`, walletSyncTxs));
    }

    public removeWallet(walletId: string): Promise<SettingsWallet> {
        return this.doCall(() => axios.delete<SettingsWallet>(`${this._entityApi}/wallets/${walletId}`));
    }

    public retrieveAllNfts(params: NftsListFilter): Promise<[]> {
        return this.doCall<[]>(() =>
            axios.get(network.apiAddress + `/accounts/${params.publicAddress}/nfts`, {
                params: params,
            }),
        );
    }

    public getSmartContractInfo(): Promise<NftScResp> {
        return this.doCall<NftScResp>(() => axios.get(`${this._entityApi}/nfts/collection-info`));
    }
}

export default WalletsService;
