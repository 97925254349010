import React, { useEffect, useRef, useState } from "react";
import { Col, Form, Row } from "react-bootstrap";
import { NumberUtils, useYupValidationResolver } from "../../common";
import { useForm } from "react-hook-form";
import * as yup from "yup";
import AlertService from "../../services/AlertService";
import SessionStorageService from "../../services/sessionStorageService/SessionStorageService";
import { IcoListingSteps } from "./IcoCreation";
import IcoService from "../../services/IcoService";
import { Ico } from "../../common/types/icoTypes";
import { useService } from "../../services/config/dependencyInjectorConfig";
import IcoEditStepThree, { getIcoStepThreeValidationSchema } from "./Edit/IcoEditStepThree";
import { useNavigate } from "react-router-dom";
import { routeNames } from "../../routes/routes";
import { IcoStep } from "./ico.types";
import { EButton } from "../../components/Buttons";
import { SocialLinks } from "../../helpers/consts";
import ReCAPTCHA from "react-google-recaptcha";
import { recaptchaKey } from "../../config";
import FileService, { ImageType } from "../../services/FileService";
import { isValidQuarter } from "./utils/IcoUtils";
import StringUtils from "../../common/utils/StringUtils";
import errorToast from "../../components/Error/errorToast";
import { SessionStorageKeys } from "../../services/sessionStorageService/sessionStorageKeys";
import { useMutation } from "@tanstack/react-query";
import { IcoStepOneInputs } from "./IcoStepOne";
import { IcoStepTwoInputs } from "./IcoStepTwo";

export interface IcoStepThreeInputs {
    links: SocialLinks;
}

export const icoStepThreeInitial: IcoStepThreeInputs = {
    links: {
        litepaper: "",
        website: "",
        facebook: "",
        twitter: "",
        telegram: "",
        discord: "",
        github: "",
        medium: "",
        whitelist: "",
        whitepaper: "",
        tokenomics: "",
    },
};

const getValidationSchema = () => {
    return yup.object(getIcoStepThreeValidationSchema());
};

const IcoStepThree: React.FC<React.PropsWithChildren<IcoStep>> = ({ step, changeStep }) => {
    const [icoInfo, setIcoInfo] = useState<IcoStepThreeInputs>(icoStepThreeInitial);
    const validationResolver = useYupValidationResolver(getValidationSchema());
    const { register, handleSubmit, formState, reset } = useForm<IcoStepThreeInputs>({
        resolver: validationResolver,
    });

    const [icoService] = useService(IcoService);
    const [filesService] = useService(FileService);
    const navigate = useNavigate();
    const reRef = useRef<ReCAPTCHA>(null);

    const saveMutation = useMutation({
        mutationFn: (ico: Partial<IcoStepOneInputs> & Partial<IcoStepTwoInputs> & Partial<IcoStepThreeInputs>) =>
            icoService.saveIco(ico),
        onSuccess: (savedIco: Ico) => {
            AlertService.success(`ICO ${savedIco.name} saved. We will get back to you shortly.`);
            SessionStorageService.removeItem("ico");
            reset();
            reRef.current?.reset();
            navigate(routeNames.projectMonitor);
        },
    });

    const sessionIco = SessionStorageService.getItem("ico");

    useEffect(() => {
        if (sessionIco && sessionIco.stepThree) {
            const icoInitial: IcoStepThreeInputs = {
                links: {
                    litepaper: sessionIco.stepThree.litepaper,
                    website: sessionIco.stepThree.website,
                    facebook: sessionIco.stepThree.facebook,
                    twitter: sessionIco.stepThree.twitter,
                    telegram: sessionIco.stepThree.telegram,
                    discord: sessionIco.stepThree.discord,
                    github: sessionIco.stepThree.github,
                    medium: sessionIco.stepThree.medium,
                    whitelist: sessionIco.stepThree.whitelist,
                    whitepaper: sessionIco.stepThree.whitepaper,
                    tokenomics: sessionIco.stepThree.tokenomics,
                },
            };

            setIcoInfo(icoInitial);
            reset();
        }
    }, [step]);

    const onStepThreeChangeHandler = async (input: IcoStepThreeInputs) => {
        const ico: IcoListingSteps = sessionIco;
        ico.stepThree = input;
        SessionStorageService.setItem(SessionStorageKeys.ico, ico);
        AlertService.successToast("Step three completed!");

        if (!ico.stepOne.logoUploadedImage) {
            return;
        }

        const logoEncodedImage = ico.stepOne.logoUploadedImage[0].dataURL;
        const logoImageName = await filesService.uploadEncodedImage(logoEncodedImage, ImageType.ico);
        delete ico.stepOne.logoUploadedImage;

        let featureImage;
        const featureUploadedImage = ico.stepOne.featureUploadedImage;
        if (featureUploadedImage && featureUploadedImage.length > 0) {
            featureImage = await filesService.uploadEncodedImage(featureUploadedImage[0].dataURL, ImageType.ico);
            delete ico.stepOne.featureUploadedImage;
        }

        const icoProposal = {
            ...ico.stepOne,
            ...ico.stepTwo,
            ...ico.stepThree,
            logo: logoImageName,
            featureImage,
            preSaleStart: "",
            preSaleEnd: "",
            publicSaleStart: "",
            publicSaleEnd: "",
            kycStart: "",
            kycEnd: "",
            tradableStart: "",
            captchaToken: "",
        };

        const captchaToken = await reRef.current?.executeAsync();

        if (captchaToken == "" || captchaToken == null) {
            errorToast("Invalid captcha");
            return;
        }

        icoProposal.captchaToken = captchaToken;

        icoProposal.maxTokenSupply = Number(icoProposal.maxTokenSupply);
        icoProposal.privateSalePrice = NumberUtils.toNumber(icoProposal.privateSalePrice);
        icoProposal.privateSaleSoldTokens = NumberUtils.toNumber(icoProposal.privateSaleSoldTokens);
        icoProposal.publicSalePrice = NumberUtils.toNumber(icoProposal.publicSalePrice);
        icoProposal.softcap = NumberUtils.toNumber(icoProposal.softcap);
        icoProposal.hardcap = NumberUtils.toNumber(icoProposal.hardcap);
        icoProposal.privateSaleOfferedTokens = NumberUtils.toNumber(icoProposal.privateSaleOfferedTokens);
        icoProposal.publicSaleOfferedTokens = NumberUtils.toNumber(icoProposal.publicSaleOfferedTokens);
        icoProposal.initialCirculatingSupply = NumberUtils.toNumber(icoProposal.initialCirculatingSupply);

        if (icoProposal.preSaleDate) {
            if (icoProposal.preSaleDate.quarterInfo && isValidQuarter(icoProposal.preSaleDate.quarterInfo)) {
                icoProposal.preSaleStartQuarter =
                    icoProposal.preSaleDate.quarterInfo.quarter + " " + icoProposal.preSaleDate.quarterInfo.year;
                icoProposal.preSaleStart = "";
                icoProposal.preSaleEnd = "";
            } else if (icoProposal.preSaleDate.start && icoProposal.preSaleDate.end) {
                icoProposal.preSaleStart = StringUtils.getDateValue(icoProposal.preSaleDate.start);
                icoProposal.preSaleEnd = StringUtils.getDateValue(icoProposal.preSaleDate.end);
                icoProposal.preSaleStartQuarter = undefined;
            } else {
                icoProposal.preSaleStartQuarter = null;
                icoProposal.preSaleStart = "";
                icoProposal.preSaleEnd = "";
            }
        } else {
            icoProposal.preSaleStartQuarter = null;
            icoProposal.preSaleStart = "";
            icoProposal.preSaleEnd = "";
        }

        if (icoProposal.publicSaleDate) {
            if (icoProposal.publicSaleDate.quarterInfo && isValidQuarter(icoProposal.publicSaleDate.quarterInfo)) {
                icoProposal.publicSaleStartQuarter =
                    icoProposal.publicSaleDate.quarterInfo.quarter + " " + icoProposal.publicSaleDate.quarterInfo.year;
                icoProposal.publicSaleStart = "";
                icoProposal.publicSaleEnd = "";
            } else if (icoProposal.publicSaleDate.start && icoProposal.publicSaleDate.end) {
                icoProposal.publicSaleStart = StringUtils.getDateValue(icoProposal.publicSaleDate.start);
                icoProposal.publicSaleEnd = StringUtils.getDateValue(icoProposal.publicSaleDate.end);
                icoProposal.publicSaleStartQuarter = undefined;
            } else {
                icoProposal.publicSaleStartQuarter = null;
                icoProposal.publicSaleStart = "";
                icoProposal.publicSaleEnd = "";
            }
        } else {
            icoProposal.publicSaleStartQuarter = null;
            icoProposal.publicSaleStart = "";
            icoProposal.publicSaleEnd = "";
        }

        if (icoProposal.kycDate) {
            if (icoProposal.kycDate.quarterInfo && isValidQuarter(icoProposal.kycDate.quarterInfo)) {
                icoProposal.kycStartQuarter =
                    icoProposal.kycDate.quarterInfo.quarter + " " + icoProposal.kycDate.quarterInfo.year;
                icoProposal.kycStart = "";
                icoProposal.kycEnd = "";
            } else if (icoProposal.kycDate.start && icoProposal.kycDate.end) {
                icoProposal.kycStart = StringUtils.getDateValue(icoProposal.kycDate.start);
                icoProposal.kycEnd = StringUtils.getDateValue(icoProposal.kycDate.end);
                icoProposal.kycStartQuarter = undefined;
            } else {
                icoProposal.kycStartQuarter = null;
                icoProposal.kycStart = "";
                icoProposal.kycEnd = "";
            }
        } else {
            icoProposal.kycStartQuarter = null;
            icoProposal.kycStart = "";
            icoProposal.kycEnd = "";
        }

        if (icoProposal.tradableDate) {
            if (icoProposal.tradableDate.quarterInfo && isValidQuarter(icoProposal.tradableDate.quarterInfo)) {
                icoProposal.tradableStartQuarter =
                    icoProposal.tradableDate.quarterInfo.quarter + " " + icoProposal.tradableDate.quarterInfo.year;
                icoProposal.tradableStart = "";
            } else if (icoProposal.tradableDate.start) {
                icoProposal.tradableStart = StringUtils.getDateValue(icoProposal.tradableDate.start);
                icoProposal.tradableStartQuarter = undefined;
            } else {
                icoProposal.tradableStartQuarter = null;
                icoProposal.tradableStart = "";
            }
        } else {
            icoProposal.tradableStartQuarter = null;
            icoProposal.tradableStart = "";
        }

        saveMutation.mutate(icoProposal);
    };

    return (
        <div>
            <ReCAPTCHA ref={reRef} sitekey={recaptchaKey.siteKey ?? ""} size="invisible" />
            <Form className="mt-3" onSubmit={handleSubmit(onStepThreeChangeHandler)}>
                <IcoEditStepThree ico={icoInfo} register={register} formState={formState} />
                <Row className="mt-1">
                    <Col>
                        <EButton
                            className="mt-2 w-100 py-3 text-capitalize"
                            type="button"
                            onClick={() => changeStep(2)}
                            testId="back"
                        >
                            Go back
                        </EButton>
                    </Col>
                    <Col>
                        <EButton className="mt-2 w-100 py-3 text-capitalize" type="submit" testId="save">
                            Save information
                        </EButton>
                    </Col>
                </Row>
            </Form>
        </div>
    );
};

export default IcoStepThree;
