import React, { FC, useEffect, useState } from "react";
import ConditionsAgreement from "./ConditionsAgreement";
import { useGetLoginInfo } from "@multiversx/sdk-dapp/hooks";
import { useContext } from "../../context";
import { ESpinner } from "../Spinner";

const ConditionsAgreementWrapper: FC<React.PropsWithChildren<{ authenticatedRoute: boolean }>> = ({
    authenticatedRoute,
    children,
}) => {
    const { userSettings, jwtToken } = useContext();
    const { isLoggedIn } = useGetLoginInfo();
    const [isLoggedAndAuth, setIsLoggedAndAuth] = useState<boolean>(false);

    useEffect(() => {
        setIsLoggedAndAuth((isLoggedIn && jwtToken) as boolean);
    }, [isLoggedIn, jwtToken]);

    if (authenticatedRoute && isLoggedAndAuth) {
        return (
            <div>
                {!userSettings.conditionsAgreement && (
                    <>
                        <ConditionsAgreement />
                        <ESpinner />
                    </>
                )}
                {userSettings.conditionsAgreement && children}
            </div>
        );
    }

    return <>{children}</>;
};

export default ConditionsAgreementWrapper;
