import React from "react";
import { Orientation } from "../../components/Hexagon/helpers/hex.types";
import HexTemplate, { HexProps } from "../../components/Hexagon/HexTemplate";

const width = 135;
const height = 115;
const offset = 1.02;

const hexSettings = {
    dimensions: { width, height },
    orientation: Orientation.FLAT,
    offset,
};

const hexGridWidth = width * 8.5 + offset * 20;
const hexGridHeight = height * 3.5;

const styles = [
    { backgroundColor: "#0B2221" },
    { backgroundColor: "#103332" },
    { backgroundColor: "#124040" },
    { backgroundColor: "#081C1C" },
    { backgroundColor: "#0B2626" },
    { backgroundColor: "#081A1A" },
];
const initialHexagons: HexProps[] = [
    { q: 0, r: 1, style: styles[5] },
    { q: 1, r: 0, style: styles[3] },
    { q: 2, r: 0, style: styles[3] },
    { q: 3, r: -1, style: styles[0] },
    { q: 3, r: 0, style: styles[0] },
    { q: 4, r: -1, style: styles[0] },
    { q: 5, r: -1, style: styles[0] },
    { q: 6, r: -2, style: styles[4] },
    { q: 6, r: -1, style: styles[1] },
    { q: 7, r: -3, style: styles[3] },
    { q: 7, r: -2, style: styles[2] },
    { q: 7, r: -1, style: styles[1] },
    { q: 8, r: -4, style: styles[3] },
    { q: 8, r: -3, style: styles[1] },
    { q: 8, r: -2, style: styles[2] },
    { q: 9, r: -4, style: styles[1] },
    { q: 9, r: -3, style: styles[1] },
    { q: 10, r: -4, style: styles[2] },
    { q: 10, r: -3, style: styles[3] },
];

type HexBackgroundProps = Partial<HexProps> & Pick<HexProps, "q" | "r">;

const getId = (q: number, r: number) => {
    return `${q}-${r}`;
};

const getHexagons = (hexagons: HexProps[], props: HexBackgroundProps[]): HexProps[] => {
    const initialHexProps = Object.assign({}, ...hexagons.map((hex) => ({ [getId(hex.q, hex.r)]: hex })));

    for (const hexProps of props) {
        const id = getId(hexProps.q, hexProps.r);
        const hexagon = { ...initialHexProps[id], ...hexProps };
        initialHexProps[id] = hexagon;
    }

    return Object.values(initialHexProps);
};

const HexBackground = ({
    hexProps,
    className = "",
}: {
    hexProps?: (width: number, height: number) => HexBackgroundProps[];
    className?: string;
}) => (
    <div className={`d-flex justify-content-end ${className}`}>
        <HexTemplate
            layout={{ width: hexGridWidth, height: hexGridHeight }}
            hexSettings={hexSettings}
            hexagons={getHexagons(initialHexagons, hexProps ? hexProps(width, height) : [])}
        />
    </div>
);

export default HexBackground;
