import React from "react";
import { EPlaceholder } from "../Placeholder";
import { renderHTML } from "../../helpers/consts";

const DescriptionFirstParagraph = ({ description }: { description?: string }) => {
    return (
        <div className="lp-description" data-testid="description-first-paragraph">
            {!description ? (
                <EPlaceholder numberOfLines={3} />
            ) : (
                renderHTML(description.substr(0, description.indexOf("</p>") + 4))
            )}
        </div>
    );
};

export default DescriptionFirstParagraph;
