import React, { memo, useEffect, useState } from "react";
import styled, { css } from "styled-components";
import { Table } from "react-bootstrap";
import { Promise } from "core-js";
import TableTopHeader from "./TableTopHeader";
import TableLeftHeader from "./TableLeftHeader";
import TableCell from "./TableCell";
import EPlaceholder from "../../../components/Placeholder/Placeholder";

const NoBorderCss = css`
    border: none !important;
`;

const THead = styled.thead`
    ${NoBorderCss}
`;

const TBody = styled.tbody`
    ${NoBorderCss}
`;

export enum TableVariant {
    headerTop = "headerTop",
    headerLeft = "headerLeft",
    headerTopAndLeft = "headerTopAndLeft",
}

export type StaticTableRows = (string | number | JSX.Element)[][];

interface TableValues {
    rows?: StaticTableRows | Promise<StaticTableRows>;
    useColGroup?: boolean;
}

interface HeadersTop {
    headersTop: (string | JSX.Element)[];
}

interface HeadersLeft {
    headersLeft: (string | JSX.Element)[];
}

interface HeadersTopAndLeft extends HeadersTop, HeadersLeft {}

type TableComponentHeaderTopProps = {
    variant: TableVariant.headerTop;
} & TableValues &
    HeadersTop;

type TableComponentHeaderLeftProps = {
    variant: TableVariant.headerLeft;
} & TableValues &
    HeadersLeft;

type TableComponentHeaderTopAndLeftProps = {
    variant: TableVariant.headerTopAndLeft;
} & TableValues &
    HeadersTopAndLeft;

export type TableProps = TableComponentHeaderTopProps | TableComponentHeaderLeftProps | TableComponentHeaderTopAndLeftProps;

/**
 * props.rows is a two-dimensional array where each "outer" array is a row and each "inner" array contains elements for
 * each cell for the row
 *
 * Example A: headers on top:
 * ______________________________
 * |Top header 1 | Top header 2 |
 * |     1       |       5      |
 * |     2       |       6      |
 * |     3       |       7      |
 * |     4       |       8      |
 * ------------------------------
 * props: {
 *  variant: "headerTop",
 *  headersTop: ["Top header 1", "Top header 2"],
 *  rows: [         // array of rows
 *      ["1", "5"], // row, where 1 and 5 are cell values
 *      ["2", "6"], // row, where 2 and 6 are cell values
 *      ["3", "7"], // row, where 3 and 7 are cell values
 *      ["4", "8"], // row, where 4 and 8 are cell values
 *  ]
 * }
 *
 * Example B: headers on left:
 * __________________________________________
 * | Left header 1 |    1   |   4   |   7   |
 * | Left header 2 |    2   |   5   |   8   |
 * | Left header 3 |    3   |   6   |   9   |
 * ------------------------------------------
 * props: {
 *  variant: "headerLeft",
 *  headersLeft: ["Left header 1", "Left header 2"],
 *  rows: [
 *      ["1", "4", "7"],
 *      ["2", "5", "8"],
 *      ["3", "6", "9"],
 *  ]
 * }
 *
 * Example C: headers on top and left
 * _______________________________________________
 * |               | Top header 1 | Top header 2 |
 * | Left header 1 |       1      |      4       |
 * | Left header 2 |       2      |      5       |
 * | Left header 3 |       3      |      6       |
 * -----------------------------------------------
 * props: {
 *  variant: "headerTopAndLeft",
 *  headersTop: ["Top header 1", "Top header 2"],
 *  headersLeft: ["Left header 1", "Left header 2", "Left header 3"]
 *  rows: [
 *      ["1", "4"],
 *      ["2", "5"],
 *      ["3", "6"],
 *  ]
 * }
 */
const getPlaceholderRows = (n = 3): StaticTableRows => Array(n).fill([""]);

const StaticTable = (props: TableProps) => {
    const [resolvedRows, setResolvedRows] = useState<StaticTableRows>([]);
    const [showPlaceholder, setShowPlaceholder] = useState(!props.rows);

    useEffect(() => {
        fetchAndPrepareRows();
        setShowPlaceholder(!props.rows);
    }, [props.rows]);

    const fetchAndPrepareRows = async () => {
        const rows =
            (props.rows && (await props.rows)) ||
            ((props.variant === TableVariant.headerLeft || props.variant === TableVariant.headerTopAndLeft) &&
                getPlaceholderRows(props.headersLeft.length)) ||
            getPlaceholderRows();
        prepareValues(rows);
        setResolvedRows(rows);
    };

    // className is used for <col>s inside <colgroup>
    // to keep all the columns with the same width
    // except for left headers (it has fixed class "col-md-3")
    const getClassName = (maxNumber?: number): string => {
        const max = maxNumber || getMaxRowLength();
        return `col-md-${Math.floor(
            (12 - (props.variant === TableVariant.headerLeft || props.variant === TableVariant.headerTopAndLeft ? 3 : 0)) / max,
        )}`;
    };

    const getMaxRowLength = (): number => {
        let max = 0;
        resolvedRows.forEach((val) => (max = val.length > max ? val.length : max));
        return max;
    };

    // rows length should be equal to leftHeaders length:
    // if rows.length > leftHeader.length, we should remove the last rows so that rows.length === leftHeaders.length
    // if rows.length < leftHeader.length, we should add rows (with array of empty strings as values)
    //
    // each row length should be equal to headersTop length:
    // if row.length > rightHeaders.length, we should remove last elements from the row
    // if row.length < rightHeaders.length, we should add elements to the row (with "" as values)
    const prepareValues = async (rows: StaticTableRows) => {
        if (props.variant === TableVariant.headerLeft || props.variant === TableVariant.headerTopAndLeft) {
            const headersLeftLength = props.headersLeft.length;
            if (rows.length > headersLeftLength) {
                rows = rows.slice(0, headersLeftLength);
            } else {
                while (rows.length < headersLeftLength) {
                    rows.push(new Array(getMaxRowLength()).fill(""));
                }
            }
        }

        if (props.variant === TableVariant.headerTop || props.variant === TableVariant.headerTopAndLeft) {
            const headersTopLength = props.headersTop.length;
            rows.map((value, index) => {
                if (value.length > headersTopLength) {
                    rows[index] = value.slice(0, headersTopLength);
                } else {
                    while (value.length < headersTopLength) {
                        value.push("");
                    }
                }
            });
        }
    };

    return (
        <Table responsive>
            {props.useColGroup && (
                <colgroup>
                    {(props.variant === TableVariant.headerLeft || props.variant === TableVariant.headerTopAndLeft) && (
                        <col className="col-md-3" />
                    )}
                    {(props.variant === TableVariant.headerTop || props.variant === TableVariant.headerTopAndLeft) &&
                        props.headersTop.map((val: string | JSX.Element, key: number) => (
                            <col key={key} className={getClassName(props.headersTop.length)} />
                        ))}
                    {props.variant === TableVariant.headerLeft &&
                        [...Array(getMaxRowLength())].map((val, key) => <col key={key} className={getClassName()} />)}
                </colgroup>
            )}
            {(props.variant === TableVariant.headerTop || props.variant === TableVariant.headerTopAndLeft) && (
                <THead>
                    <tr>
                        {props.variant === TableVariant.headerTopAndLeft && <th />}
                        {props.headersTop.map((header: string | JSX.Element, key: number) => (
                            <TableTopHeader key={key}>{header}</TableTopHeader>
                        ))}
                    </tr>
                </THead>
            )}
            <TBody>
                {!showPlaceholder &&
                    resolvedRows.map((value, key) => (
                        <tr key={key}>
                            {(props.variant === TableVariant.headerLeft || props.variant === TableVariant.headerTopAndLeft) && (
                                <TableLeftHeader>{props.headersLeft[key]}</TableLeftHeader>
                            )}
                            {value.map((val, innerKey) => (
                                <TableCell key={innerKey}>{val}</TableCell>
                            ))}
                        </tr>
                    ))}
                {showPlaceholder &&
                    resolvedRows.map((value, key) => (
                        <tr key={key}>
                            {(props.variant === TableVariant.headerLeft || props.variant === TableVariant.headerTopAndLeft) &&
                            props.headersLeft[key] ? (
                                <TableLeftHeader>{props.headersLeft[key]}</TableLeftHeader>
                            ) : (
                                <EPlaceholder as={TableLeftHeader} />
                            )}
                            {value.map((val, innerKey) => (
                                <EPlaceholder as={TableCell} width={8} key={innerKey} />
                            ))}
                        </tr>
                    ))}
            </TBody>
        </Table>
    );
};

export default memo(StaticTable);
