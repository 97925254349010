import React, { FC } from "react";
import styled, { css } from "styled-components";
import { BgCell, commonStyles } from "./TableCell";
import { BREAKPOINTS } from "../../../assets/sass/breakpoints";

export const headerStyles = css`
    background-color: transparent !important;
    font-weight: 600;

    ${commonStyles}

    ${BgCell} {
        background-color: var(--table-header-bg-color) !important;
    }
`;

const Container = styled.td`
    min-width: 120px;

    @media (${BREAKPOINTS.tablet}) {
        width: 15.5rem;
    }

    &:first-child {
        border-left: 0 !important;
    }

    ${headerStyles}
`;

const TableLeftHeader: FC<React.PropsWithChildren<unknown>> = ({ children }) => {
    return (
        <Container>
            <BgCell>{children}</BgCell>
        </Container>
    );
};

export default TableLeftHeader;
