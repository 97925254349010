import React from "react";
import { Placeholder } from "react-bootstrap";
import { ColContainer, RowContainer } from "./ProjectKeyMetricsCard";

const width = Math.floor(Math.random() * (8 - 3) + 3);

const KeyMetricsPlaceholder = () => (
    <Placeholder as={RowContainer} animation="glow">
        <Placeholder as={ColContainer} animation="glow" xs={6}>
            <Placeholder xs={10} />
        </Placeholder>
        <Placeholder as={ColContainer} animation="glow" xs={6}>
            <Placeholder xs={width} />
        </Placeholder>
    </Placeholder>
);

export default KeyMetricsPlaceholder;
