import React, { FC } from "react";
import { Card } from "react-bootstrap";
import { ELinkButton } from "../Buttons";
import styled from "styled-components";
import { Rating } from "../../common/components/Rating/Rating";
import { ProjectGeneralDetails } from "../../pages/Launchpad/launchpad.types";
import { awsS3BaseURL } from "../../config";
import { routeNames } from "../../routes/routes";
import { ECard } from "../Card";
import { BREAKPOINTS } from "../../assets/sass/breakpoints";
import FeatureImage from "./FeatureImage";
import LogoImage from "./LogoImage";
import Title from "./Title";
import Slogan from "./Slogan";
import { LaunchpadDetails } from "./LaunchpadDetails";
import FooterCard from "./Footer/FooterCard";
import DescriptionFirstParagraph from "./DescriptionFirstParagraph";

interface LaunchpadProps {
    launchpadInfo?: ProjectGeneralDetails;
    showLaunchpadDetails?: boolean;
    showButtonDetails?: boolean;
}

const LaunchCard = styled(ECard)`
    padding: 0 !important;
    background-color: transparent;
    margin-top: 1.5rem;

    @media (${BREAKPOINTS.tablet}) {
        margin-top: 0rem;
    }

    .lp-title {
        font-size: 2.2rem;
        color: #51e2df;
        font-weight: 400;

        @media (min-width: 992px) {
            font-size: 2.714rem;
        }
    }

    .lp-slogan {
        font-size: 1.3rem;
        margin: 1rem 0 0 0;
        line-height: 2rem;
        font-weight: 300;
        color: #e6e6e6;

        @media (min-width: 992px) {
            font-size: 2.714rem;
            line-height: 3.5rem;
            margin: 2rem 0;
        }
    }

    .lp-description {
        display: none;
        font-weight: 300;

        p {
            line-height: 2.5rem;
        }

        @media (${BREAKPOINTS.tablet}) {
            display: unset;
            font-size: 0.7rem;
        }

        @media (min-width: 992px) {
            display: none;
        }

        @media (${BREAKPOINTS.desktop}) {
            display: unset;
            font-size: 1.5rem;
        }
    }

    .lp-infos {
        font-size: 1.1rem;

        .row {
            padding: 0.3rem 0;

            .col {
                padding: 0 10px !important;
            }
        }
    }

    .first-launchpad {
        width: 100%;

        @media (${BREAKPOINTS.tablet}) {
            width: 62%;
            height: 100%;
        }
    }

    .card-lp {
        border-radius: 10px 10px 0 0;
        box-shadow: 5px 5px 15px #00000029;
        min-height: 20rem;
        max-height: 41.428rem;
        display: block !important;

        @media (${BREAKPOINTS.tablet}) {
            overflow: hidden;
        }
    }

    .img-wrapper-sh {
        filter: drop-shadow(0 0 0 rgba(0, 0, 0, 0));
        height: 100%;
        width: 100%;
    }

    .last-launchpad {
        background-color: transparent;
        top: 0;
        right: 0;
        padding: 1.4rem;
        width: 100%;
        position: relative;
        text-align: center;

        @media (${BREAKPOINTS.tablet}) {
            position: absolute;
            width: 38%;
            text-align: right;
            padding-top: 2.5rem;
            padding-right: 2.142rem;
        }
    }

    .middle-launchpad {
        width: 8rem;
        height: 8rem;
        position: absolute;
        top: 0;
        left: 50%;
        transform: translate(-50%, -50%);
        margin-left: auto;
        margin-right: auto;
        background: var(--field-bg-color);
        border-radius: 50%;
        border: 1rem solid transparent;

        @media (${BREAKPOINTS.tablet}) {
            width: 15rem;
            height: 15rem;
            top: 50%;
        }
    }

    .launchpad-card-footer {
        min-height: 14.285rem;
        background-color: var(--page-block-background-color);
        margin-right: 0 !important;
        margin-left: 0 !important;
    }
`;
const DecorativeElement = styled.div`
    visibility: hidden;
  
    @media (${BREAKPOINTS.desktop}) {
        position: absolute;
        width: 22%;
        left: 61%;
        height: 100%;
        transform: translateX(-100%);
        background-color: #102c2e;
        clip-path: polygon(0 0, 100% 100%, 0 100%);
        visibility: visible;
    }
  }
`;

const Bg = styled.div`
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: var(--page-block-background-color);
    opacity: 0.8;
`;

const LaunchpadCard: FC<React.PropsWithChildren<LaunchpadProps>> = ({
    launchpadInfo,
    showLaunchpadDetails = false,
    showButtonDetails = false,
}) => {
    return (
        <LaunchCard testId="launchpad-card">
            <Card.Body className="card-lp p-0 d-flex position-relative">
                <Bg />
                <DecorativeElement />
                <div className="first-launchpad">
                    <div className="img-wrapper-sh">
                        <FeatureImage
                            src={launchpadInfo && awsS3BaseURL + launchpadInfo.featureImage}
                            showPlaceholder={!launchpadInfo}
                        />
                    </div>

                    <div className="middle-launchpad">
                        <LogoImage src={launchpadInfo && awsS3BaseURL + launchpadInfo.logo} showPlaceholder={!launchpadInfo} />

                        {showLaunchpadDetails && (
                            <div className="d-flex justify-content-center mt-5 mb-4">
                                <ELinkButton to={routeNames.launchpadAboutTab} className="mt-5" testId="view">
                                    View Project
                                </ELinkButton>
                            </div>
                        )}
                    </div>
                </div>

                <div className="last-launchpad">
                    <div className="info-right">
                        <Rating
                            ratingValue={0}
                            initialValue={launchpadInfo?.ratings?.totalScore ?? 0}
                            iconsCount={5}
                            fontSize={"1.5rem"}
                            size={35}
                            showTooltip
                            readonly
                            allowHalfIcon
                            fillColor="white"
                            emptyColor="gray"
                        />
                        <Title name={launchpadInfo?.name} />
                        <Slogan slogan={launchpadInfo?.slogan} />
                        <DescriptionFirstParagraph description={launchpadInfo?.description} />

                        {showLaunchpadDetails && <LaunchpadDetails launchpadInfo={launchpadInfo} />}
                    </div>
                </div>
            </Card.Body>
            <FooterCard showButtonDetails={showButtonDetails} launchpadInfo={launchpadInfo} />
        </LaunchCard>
    );
};

export default LaunchpadCard;
