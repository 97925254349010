import React, { FC } from "react";
import { Col, Row } from "react-bootstrap";
import Step, { Placement, StepDetails } from "./Step";
import styled from "styled-components";
import { frostyEffect } from "../../assets/sass/common-effects";
import { BREAKPOINTS } from "../../assets/sass/breakpoints";

interface StepsSliderProps {
    className?: string;
    onChangeStep?: (step: number) => void;
    steps: StepDetails[];
    activeStep: number;
    testId?: string;
}

export const BORDER_RADIUS = "0.714rem";
export const PADDING = "7.571rem";

const Container = styled.div`
    ${frostyEffect};
    background-color: var(--card-bg);
    border-radius: ${BORDER_RADIUS};
    border-width: 0;

    .wrapper-steps {
        position: relative;

        &:before {
            top: 45%;
            position: absolute;
            content: " ";
            background-color: #206666;
            background-image: linear-gradient(90deg, #51e2df 0%, #206666 100%);
            background-repeat: no-repeat;
            background-size: ${(props: { total: number }) => props.total}%;
            width: calc(100% - 6rem);
            height: 1.143rem;
            z-index: 0;
            left: 3rem;
        }
    }

    @media (${BREAKPOINTS.tablet}) {
        .wrapper-steps:before {
            top: 28%;
        }
        padding-left: ${PADDING};
        padding-right: ${PADDING};
    }
`;

const StepsSlider: FC<React.PropsWithChildren<StepsSliderProps>> = ({
    className = "",
    onChangeStep,
    activeStep,
    steps,
    testId = "steps-slider",
}) => {
    return (
        <Container
            data-testid={testId}
            className={`container spec py-4 ${className}`}
            total={(activeStep * 100) / (steps.length - 1)}
        >
            <Row className="justify-content-between">
                <Col md={12}>
                    <div className="wrapper-steps d-flex align-items-end justify-content-between mt-1 mb-1">
                        {steps.map((step, index) => {
                            return (
                                <Step
                                    number={step.number}
                                    title={step.title}
                                    isActive={activeStep === step.number}
                                    key={index}
                                    onClick={onChangeStep}
                                    labelPlacement={step.number % 2 === 1 ? Placement.Bottom : Placement.Top}
                                />
                            );
                        })}
                    </div>
                </Col>
            </Row>
        </Container>
    );
};

export default StepsSlider;
