import React, { useState } from "react";
import debounce from "lodash/debounce";
import { useService } from "services/config/dependencyInjectorConfig";
import TokensService from "../../../services/TokensService";
import { useQuery } from "@tanstack/react-query";
import { TokenWithBalance } from "./tokens.dto";
import { FormGroupProps } from "../../../common/form/types";
import { FormControlGroupProps } from "../../../common/form/FormControlledInputGroup";
import { Image } from "react-bootstrap";
import { FormSelectGroup } from "../../../common";
import { SelectVariant } from "../../../common/form/FormSelectGroup";

export const customStyles = {
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    menu: (provided: any) => {
        return {
            ...provided,
            border: 0,
            backgroundColor: "#0D2626",
            borderColor: "#0D2626",
            textColor: "#fff",
            padding: "1px",
            "min-height": "2.857rem",
            "&:hover": {
                boxShadow: "0 0 0.1rem 0.1rem #66a17a",
            },
        };
    },
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    control: (provided: any) => ({
        ...provided,
        backgroundColor: "#0D2626",
        borderColor: "#0D2626",
        "&:hover": {
            boxShadow: "0 0 0.1rem 0.1rem #66a17a",
        },
    }),
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    option: (provided: any, state: any) => {
        const opacity = state.isDisabled ? 0.9 : 1;
        return {
            ...provided,
            color: "#fff",
            backgroundColor: state.isFocused ? "#66a17a" : null,
            opacity,
            "&:hover": {
                boxShadow: "0 0 0.1rem 0.1rem #66a17a",
            },
        };
    },
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    singleValue: (provided: any) => {
        return {
            ...provided,
            color: "#fff",
        };
    },
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    input: (provided: any) => ({
        ...provided,
        color: "#fff",
    }),
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    placeholder: (provided: any) => ({
        ...provided,
        color: "#fff",
    }),
};

interface TokenFilterProps<T> extends FormGroupProps<T>, Pick<FormControlGroupProps<T>, "control"> {
    ico: any;
    defaultValue?: TokenWithBalance;
}

function TokenFilter<T>(props: TokenFilterProps<T>): JSX.Element {
    const { ico, label, name, register, formState, control } = props;

    const [tokensService] = useService(TokensService);
    const [searchedText, setSearchedText] = useState<string>("");
    const itemsNumber = 10;

    const { data: tokenCollectionList, isLoading } = useQuery({
        queryKey: ["getTokensList", { searchedText }],
        queryFn: () =>
            tokensService.getTokensList({
                size: itemsNumber,
                from: 0,
                search: searchedText,
            }),
        placeholderData: [],
    });

    const udpateDebounceText = debounce((text: string) => {
        if (text.length > 0) {
            setSearchedText(text);
        }
    }, 500);

    const onInputChange = (value: any) => {
        udpateDebounceText(value);
    };

    const getOptionValue = (p: TokenWithBalance) => {
        return (
            <div className="sb">
                <span className="d-flex align-items-center justify-content-between">
                    <span>
                        {p?.assets?.pngUrl && (
                            <Image alt={p.name} src={p.assets.pngUrl} width={20} height={20} className="mw-20 me-2" />
                        )}
                        {p.name} <span className="small-text">({p.identifier})</span>
                    </span>
                </span>
            </div>
        );
    };

    return (
        <FormSelectGroup
            name={name}
            className="mb-1"
            label={label}
            isSearchable
            isLoading={isLoading}
            defaultValue={ico.token}
            options={tokenCollectionList}
            formatOptionLabel={getOptionValue}
            displayKey="identifier"
            getOptionValue={(option) => {
                return `${option.name + option.identifier}`;
            }}
            register={register}
            formState={formState}
            control={control}
            variant={SelectVariant.Custom}
            onInputChange={onInputChange}
            required={false}
        />
    );
}

export default TokenFilter;
