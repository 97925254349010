export enum LaunchPlatforms {
    Entity = "entity",
    Maiar = "maiar",
    Elrond = "elrond",
}

export class LaunchPlatformsHelper {
    static toLabel = (launchPlatform: LaunchPlatforms): string => {
        switch (launchPlatform) {
            case LaunchPlatforms.Elrond:
                return "Ecosystem";
            case LaunchPlatforms.Maiar:
                return "xLaunchpad";
            case LaunchPlatforms.Entity:
                return "Entity";
            default:
                return "";
        }
    };
}

export enum IcoPublicSale {
    notStarted = "Public sale not started",
    running = "Public sale running",
    ended = "Public sale ended",
}

export enum IcoKyc {
    notStarted = "KYC not started",
    running = "KYC running",
    ended = "KYC ended",
}

export enum IcoLayers {
    Protocol = "protocol",
    Platform = "platform",
    dApp = "dApp",
}

const categoriesByIcoLayer = {
    [IcoLayers.Protocol]: "Protocol",
    [IcoLayers.Platform]: "Platform",
    [IcoLayers.dApp]: "dApp",
};

export class IcoLayersHelper {
    static toLabel = (icoLayer: IcoLayers) => {
        return categoriesByIcoLayer[icoLayer];
    };
}

export const PAGINATION_PER_PAGE = 30;
