import React from "react";
import { Cloud } from "../../../../../components/Cloud";
import styled from "styled-components";
import { Image } from "react-bootstrap";
import hexagonBg from "../../../../../assets/img/hexagons.svg";
import { BREAKPOINTS } from "../../../../../assets/sass/breakpoints";

const StyledHexBackground = styled.div`
    img {
        position: relative;
        top: 4rem;
        right: 8rem;
        overflow: hidden;
        min-width: 900px;
        opacity: 0.5;
    }

    @media (${BREAKPOINTS.desktop}) {
        img {
            top: 10rem;
            right: -30rem;
        }
    }
    opacity: 0.2;
`;

const Container = styled.div`
    left: 0;
    position: absolute;
    width: 100%;
    z-index: -1;
`;

const StyledCloud = styled(Cloud)`
    right: 10%;
    top: 10%;
    opacity: 0.6;
`;

const ColoredCloud = styled(Cloud)`
    opacity: 0.2;
    top: 0;
`;

const DecorationsBg = () => {
    return (
        <>
            <Container>
                <StyledHexBackground>
                    <Image fluid src={hexagonBg} />
                </StyledHexBackground>
                <StyledCloud />
                <ColoredCloud color="#ae74ff" width={300} height={200} />
            </Container>
        </>
    );
};

export default DecorationsBg;
