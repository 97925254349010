export enum Currency {
    "EUR" = "EUR",
    "USD" = "USD",
}

export const CurrencySymbol = {
    [Currency.EUR]: "€" as const,
    [Currency.USD]: "US$" as const,
};

export const ShortCurrencySymbol = {
    [Currency.EUR]: "€",
    [Currency.USD]: "$",
};

type TCurrencySymbolKeys = keyof typeof CurrencySymbol;
export type TCurrencySymbolValues = typeof CurrencySymbol[TCurrencySymbolKeys];

export const getCurrencyAsString = (currency: Currency) => {
    return currency + " " + ShortCurrencySymbol[currency];
};
