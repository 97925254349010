class ArrayUtils {
    public static range = (start: number, end: number) => {
        const length = end - start + 1;
        return Array.from({ length }, (_, i) => start + i);
    };

    public static getDefinedValues = <T>(array: (T | undefined)[]) => (array.filter((item) => !!item) as T[]) || [];

    public static getDuplicateValues = <T>(array: T[]) => array.filter((item: T, index: number) => array.indexOf(item) !== index);
}

export default ArrayUtils;
