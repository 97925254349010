import React from "react";
import styled from "styled-components";
import { Image } from "react-bootstrap";
import { BREAKPOINTS } from "../../assets/sass/breakpoints";
import { EImagePlaceholder } from "../Placeholder";

type Placeholder = { showPlaceholder?: boolean };
type FeatureImage = { src: string };

const StyledImage = styled(Image)`
    border-top-left-radius: 0.7rem;
    width: 100%;
    @media (${BREAKPOINTS.tablet}) {
        clip-path: polygon(0 0%, 100% 0%, 63% 100%, 0 100%);
        height: 100%;
    }
`;

const FeatureImage = (props: Partial<FeatureImage> & Placeholder) => {
    if (!props.showPlaceholder) {
        return <StyledImage fluid src={props.src} alt="Project image" />;
    } else {
        return <EImagePlaceholder as={StyledImage} />;
    }
};

export default FeatureImage;
