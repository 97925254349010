import BaseService from "./BaseService";
import { MetaStakingFarm } from "../pages/Metastaking/MetaStakingFarm.types";
import axios from "axios";

class MetaStakingFarmService extends BaseService {
    private readonly _urlPrefix: string;

    constructor(entityApi: string) {
        super();
        this._urlPrefix = `${entityApi}/metastaking-farms`;
    }

    createMetaStakingFarm(metaStakingFarm: MetaStakingFarm): Promise<MetaStakingFarm> {
        return this.doCall(() => axios.post(this._urlPrefix, metaStakingFarm));
    }

    retrieveAll(): Promise<MetaStakingFarm[]> {
        return this.doCall(() => axios.get(this._urlPrefix));
    }

    getById(id: string): Promise<MetaStakingFarm> {
        return this.doCall(() => axios.get(`${this._urlPrefix}/${id}`));
    }

    updateMetaStakingFarm(farm: MetaStakingFarm): Promise<MetaStakingFarm> {
        return this.doCall(() => axios.put(`${this._urlPrefix}/${farm.id}`, farm));
    }

    deleteMetaStakingFarm(id: string): Promise<MetaStakingFarm> {
        return this.doCall(() => axios.delete(`${this._urlPrefix}/${id}`));
    }
}

export default MetaStakingFarmService;
