import {
    BuyTicketsEndTimestamp,
    BuyTicketsStartTimestamp,
    ClaimTokensEndTimestamp,
    ClaimTokensStartTimestamp,
    Softcap,
    Hardcap,
    HasKyc,
    IcoId,
    IcoLayer,
    KycEndTimestamp,
    KycStartQuarter,
    KycStartTimestamp,
    MaxTokenSupply,
    Name,
    PreSaleStartQuarter,
    PreSaleStartTimestamp,
    ProjectGeneralStartTimestamps,
    ProjectId,
    PublicSaleOfferedTokens,
    PublicSalePrice,
    PublicSaleStartQuarter,
    PublicSaleStartTimestamp,
    RaisedInPublicSale,
    ShortDescription,
    Slogan,
    SnapshotEndTimestamp,
    SnapshotStartTimestamp,
    TokenName,
    WinnerSelectionEndTimestamp,
    WinnerSelectionStartTimestamp,
    AllowRegularInvestment,
    AllowAdvancedInvestment,
} from "../Ico/ico.types";
import { IcoLayers, SocialLinks } from "../../helpers/consts";
import { IcoRatings, icoRatingsInitial } from "../../common/types/icoTypes";
import { icoStepThreeInitial } from "../Ico/IcoStepThree";
import { KeyInvestor } from "../KeyInvestors/KeyInvestors.types";
import { TRangeDateTime } from "../../common/form/types";
import { TicketsScope } from "../../services/TicketsService";

export interface GeneralKeyMetrics
    extends TokenName,
        MaxTokenSupply,
        Hardcap,
        Softcap,
        PreSaleStartTimestamp,
        PublicSaleStartTimestamp,
        BuyTicketsStartTimestamp,
        BuyTicketsEndTimestamp,
        HasKyc,
        KycStartTimestamp,
        KycEndTimestamp,
        KycStartQuarter,
        RaisedInPublicSale,
        PreSaleStartTimestamp,
        PublicSaleStartTimestamp,
        PublicSaleOfferedTokens,
        PublicSaleStartQuarter,
        PreSaleStartQuarter,
        PublicSaleOfferedTokens,
        WinnerSelectionStartTimestamp,
        WinnerSelectionEndTimestamp,
        ClaimTokensStartTimestamp,
        ClaimTokensEndTimestamp,
        SnapshotStartTimestamp,
        SnapshotEndTimestamp,
        ProjectGeneralStartTimestamps,
        AllowRegularInvestment,
        AllowAdvancedInvestment {
    category: IcoLayer["icoLayer"];
    publicSaleTokenPrice: PublicSalePrice["publicSalePrice"];
}

export interface RoadmapPlanEntry {
    headline: string;
    descriptions: string[];
    isChecked: boolean;
}

export enum LaunchRoadmapEntryType {
    KYC = "KYC",
    SNAPSHOT = "SNAPSHOT",
    BUY_TICKETS = "BUY_TICKETS",
    WINNER_SELECTION = "WINNER_SELECTION",
    CLAIM_TOKEN = "CLAIM_TOKEN",
}

export interface LaunchRoadmapEntry {
    timestamp?: number | null;
    plan: RoadmapPlanEntry[];
    isChecked: boolean;
    startDate?: TRangeDateTime;
    quarter?: string | null;
    type?: LaunchRoadmapEntryType;
}

export interface ProjectGeneralDetails extends Name, Slogan, ProjectId, IcoId {
    logo: string;
    description: string;
    featureImage: string;
    links: SocialLinks;
    ratings?: IcoRatings;
    generalMetrics: GeneralKeyMetrics;
    launchRoadmap?: LaunchRoadmapEntry[];
    scAddress: string;
    lotteryTicketPrice: number;
    tokensPerTicket: number;
    isPublished: boolean;
    termsOfSaleUrl?: string;
    kycProvider: KycProvider;
}

export interface ProjectGeneralStagesDetails extends ProjectGeneralDetails {
    stages: Array<LaunchpadStage | null>;
}

export interface ProjectAboutDetails extends Name {
    featureImage: string;
    description: string;
}

export interface ProjectTeamDetails extends TeamDetails {
    keyInvestors: KeyInvestor[];
}

export interface ProjectTeamInfo extends TeamDetails {
    keyInvestorsIds: string[];
}

export interface TeamDetails {
    teamDescription?: string;
    teamMembers?: TeamMember[];
}

export interface TeamMember extends Name, ShortDescription {
    profile: string;
    role: string;
    isAdvisor: boolean;
    linkedIn: string;
    twitter: string;
}

export interface TokenSaleDetails {
    tokenName: string;
    softcap: number;
    hardcap: number;
    maxTokenSupply: number;
    initialCirculatingSupply: number;
    publicSalePrice: number;
    miscDetails?: string[];
}

export enum KycProvider {
    ON_FIDO = "onfido",
    IDENFY = "idenfy",
}

export interface Country {
    name: string;
    code: string;
    supported?: boolean;
}

export interface ProjectInvestmentDetails {
    investmentDescription?: string;
    tiersDescription?: string;
    maxNoLotteryTickets?: number;
    publicSalePrice?: number;
    tokenSaleDetails: TokenSaleDetails;
    kycProvider?: KycProvider;
    tiers?: Tier[];
    restrictedCountries?: Country[];
    limitedCountries?: Country[];
    limitedCountriesMaxTierNo?: number;
    excludedCountriesUrl?: string;
}

export const initialProjectInvestmentDetails: ProjectInvestmentDetails = {
    investmentDescription: "",
    tiersDescription: "",
    maxNoLotteryTickets: 0,
    publicSalePrice: 0,
    tokenSaleDetails: {
        tokenName: "",
        hardcap: 0,
        softcap: 0,
        maxTokenSupply: 0,
        initialCirculatingSupply: 0,
        publicSalePrice: 0,
        miscDetails: [],
    },
};

export interface Tier {
    no: number;
    minStakedAmount: string;
    maxTicketsPerUser: string;
    maxAmountPerUser: string;
}

export const initialTier: Tier = {
    no: 0,
    minStakedAmount: "0",
    maxTicketsPerUser: "0",
    maxAmountPerUser: "0",
};

export enum FundingToken {
    ENT = "ENT",
    EGLD = "EGLD",
}

export interface ProjectTiersDetails {
    tiers?: Tier[];
    lotteryTicketPrice?: number;
    fundingToken?: FundingToken;
}

export interface SnapshotData {
    timestamp: number;
    value: number;
    tier: Tier | null;
}

export interface ProjectSnapshotAddress {
    projectId: string;
    externalProjectId: string;
    projectName: string;
    address: string;
    snapshotData?: SnapshotData;
    status: string;
    timestamp: number;
}

export interface ProjectSnapshotDetails {
    externalProjectId: string;
    status: "CREATED" | "COMPLETED" | "FAILED" | "";
    totalRegisteredAddresses: number;
    totalSuccessfulSnapshots: number;
    totalFailedSnapshots: number;
    jsonFileName?: string;
    timestamp: number;
}

export const initialProjectSnapshotDetails: ProjectSnapshotDetails = {
    externalProjectId: "",
    status: "",
    totalRegisteredAddresses: 0,
    totalSuccessfulSnapshots: 0,
    totalFailedSnapshots: 0,
    jsonFileName: "",
    timestamp: 0,
};

export const initialProjectGeneralDetails: ProjectGeneralDetails = {
    id: "",
    icoId: "",
    name: "",
    slogan: "",
    description: "",
    logo: "",
    featureImage: "",
    scAddress: "",
    lotteryTicketPrice: 0,
    tokensPerTicket: 0,
    isPublished: false,
    generalMetrics: {
        allowRegularInvestment: true,
        allowAdvancedInvestment: true,
        category: IcoLayers.dApp,
        tokenName: "",
        maxTokenSupply: 0,
        publicSaleTokenPrice: 0,
        softcap: null,
        hardcap: null,
        hasKyc: false,
        publicSaleOfferedTokens: null,
        raisedInPublicSale: null,
        kycEndTimestamp: 0,
        kycStartTimestamp: 0,
        kycStartQuarter: "",
    },
    ratings: icoRatingsInitial,
    termsOfSaleUrl: undefined,
    kycProvider: KycProvider.ON_FIDO,
    ...icoStepThreeInitial,
};

export const initialProjectAboutDetails: ProjectAboutDetails = {
    name: "",
    featureImage: "",
    description: "",
};

export enum LaunchpadDetailedInfo {
    about = "about",
    metrics = "metrics",
    team = "team",
    investment = "investment",
    kycInstructions = "FAQs KYC",
}

export interface DetailedInfoProps {
    projectId?: string;
    isActive: boolean;
}

export interface EditLaunchpadTabProps extends Pick<DetailedInfoProps, "isActive"> {
    projectId: string;
}

export interface ProjectRoadmapEntry {
    quarterDate: string; // TODO: it is not a quarter date only: Q1 2020 or Dec 2020, both possible. BE migration needed for fixing the name
    descriptionPlan: string[];
    isChecked: boolean;
}

export interface KeyMetrics extends TokenName {
    tokenType: string;
    maxSupply: MaxTokenSupply["maxTokenSupply"];
    initialCirculatingSupply: number;
    launchpadPrice: number;
}

export interface TokenEconomicsEntry {
    percentage: number;
    allocation: string;
}

export interface TokenSale {
    soldTokens: number;
    tokenPrice: number;
    offeredTokens: number;
}

export interface ProjectMetricsDetails {
    projectRoadmap?: ProjectRoadmapEntry[];
    keyMetrics: KeyMetrics;
    privateSale: TokenSale;
    publicSale: TokenSale;
    tokenEconomics?: TokenEconomicsEntry[];
    website: string;
    whitepaper: string | null;
}

export interface LaunchpadCardBaseProps {
    projectDetails?: ProjectGeneralDetails;
    isRejected: boolean;
}

export enum LaunchpadStage {
    KYC = "KYC",
    SNAPSHOT = "SNAPSHOT",
    BUY_TICKETS = "BUY_TICKETS",
    WINNER_SELECTION = "WINNER_SELECTION",
    CLAIM_TOKENS = "CLAIM_TOKENS",
}

export const StageTitle = {
    [LaunchpadStage.KYC]: "KYC",
    [LaunchpadStage.SNAPSHOT]: "Snapshot",
    [LaunchpadStage.BUY_TICKETS]: "Buying tickets",
    [LaunchpadStage.WINNER_SELECTION]: "Winner Selection",
    [LaunchpadStage.CLAIM_TOKENS]: "Claim Tokens",
};

export type RetrieveTicketsInfoFn = (scope?: TicketsScope) => void | Promise<void>;
