import React, { useEffect, useState } from "react";
import { UseFormReturn } from "react-hook-form";
import { Col, Row } from "react-bootstrap";
import { DateUtils, ValidationSchemaUtils } from "../../../common";
import { IcoStepTwoInputs } from "../IcoStepTwo";
import * as yup from "yup";
import { ValidationError } from "yup";
import { useGetUserInfo } from "../../../common/hooks";
import { ICO_ALPHA_NUMERIC_NOT_ONLY_NUMERIC } from "../../../common/utils/RegexUtils";
import { isIntegerGreaterThanOrEqual, minLengthErrorMsg } from "common/utils/ValidationSchemaUtils";
import { validateQuarters } from "../utils/IcoUtils";
import { IcoEditFieldsStepTwoHelper } from "./helper";

// eslint-disable-next-line @typescript-eslint/no-explicit-any
interface IcoEditStepTwoProps<T extends IcoStepTwoInputs>
    extends Pick<UseFormReturn<any>, "register" | "formState" | "control" | "setValue"> {
    ico: T;
    displayCompact?: boolean;
}

const REQUIRED_FIELD_MESSAGE = "Required";

let isModeratorOrAdmin = false;

const isLaunchPlatformRequired = (input?: string | null): boolean => {
    return !isModeratorOrAdmin || (isModeratorOrAdmin && !!input);
};

const isIcoLayerRequired = (input?: string | null): boolean => {
    return !isModeratorOrAdmin || (isModeratorOrAdmin && !!input);
};

export function getIcoStepTwoValidationSchema() {
    return {
        tokenName: ValidationSchemaUtils.emptyAsNull
            .required(REQUIRED_FIELD_MESSAGE)
            .min(3, minLengthErrorMsg())
            .matches(ICO_ALPHA_NUMERIC_NOT_ONLY_NUMERIC, "Only alpha numeric characters allowed"),
        maxTokenSupply: ValidationSchemaUtils.emptyAsNull
            .required(REQUIRED_FIELD_MESSAGE)
            .test("Is integer greater than or equal to zero", "Must be an integer greater than or equal to zero", (v) =>
                isIntegerGreaterThanOrEqual(v, false, 0),
            ),
        preSaleDate: yup
            .object()
            .shape({
                start: yup.string(),
                end: yup.string(),
                quarterInfo: yup
                    .object()
                    .shape({
                        quarter: yup.string(),
                        year: yup.string(),
                    })
                    .test("Is quarter a valid quarter", (timeFrame, ctx) =>
                        validateQuarters(timeFrame.quarter, timeFrame.year, ctx, "Presale date quarter and year should be valid"),
                    ),
            })
            .test("Is presale start date before presale end date", (timeFrame, ctx) =>
                validateDates(
                    timeFrame.start,
                    timeFrame.end,
                    ctx,
                    `Presale Date Range: ${timeFrame.start} should not be before ${timeFrame.end}`,
                ),
            ),
        publicSaleDate: yup
            .object()
            .shape({
                start: yup.string(),
                end: yup.string(),
                quarterInfo: yup
                    .object()
                    .shape({
                        quarter: yup.string(),
                        year: yup.string(),
                    })
                    .test("Is quarter a valid quarter", (timeFrame, ctx) =>
                        validateQuarters(
                            timeFrame.quarter,
                            timeFrame.year,
                            ctx,
                            "Public sale date quarter and year should be valid",
                        ),
                    ),
            })
            .test("Is public start date before public end date", (timeFrame, ctx) =>
                validateDates(
                    timeFrame.start,
                    timeFrame.end,
                    ctx,
                    `Public date range: ${timeFrame.start} should not be before ${timeFrame.end}`,
                ),
            ),
        kycDate: yup
            .object()
            .shape({
                start: yup.string(),
                end: yup.string(),
                quarterInfo: yup
                    .object()
                    .shape({
                        quarter: yup.string(),
                        year: yup.string(),
                    })
                    .test("Is quarter a valid quarter", (timeFrame, ctx) =>
                        validateQuarters(timeFrame.quarter, timeFrame.year, ctx, "KYC date quarter and year should be valid"),
                    ),
            })
            .test("Is kyc start date before kyc end date", (timeFrame, ctx) =>
                validateDates(
                    timeFrame.start,
                    timeFrame.end,
                    ctx,
                    `Kyc Date Range: ${timeFrame.start} should not be before ${timeFrame.end}`,
                ),
            ),
        tradableDate: yup.object().shape({
            quarterInfo: yup
                .object()
                .shape({
                    quarter: yup.string(),
                    year: yup.string(),
                })
                .test("Is quarter a valid quarter", (timeFrame, ctx) =>
                    validateQuarters(timeFrame.quarter, timeFrame.year, ctx, "Listing date quarter and year should be valid"),
                ),
        }),
        softcap: ValidationSchemaUtils.emptyAsNull.test(
            "Is integer greater than one",
            "Must be an integer greater than one",
            (v) => isIntegerGreaterThanOrEqual(v),
        ),
        hardcap: ValidationSchemaUtils.emptyAsNull.test(
            "Is integer greater than one",
            "Must be an integer greater than one",
            (v) => isIntegerGreaterThanOrEqual(v),
        ),
        publicSalePrice: ValidationSchemaUtils.emptyAsNull.test(
            "Has min public sale price",
            "Must be at least 0.0000001",
            (v) => !v || Number(v) >= 0.0000001,
        ),
        privateSaleOfferedTokens: ValidationSchemaUtils.emptyAsNull.test(
            "Is integer greater than one",
            "Must be an integer greater than one",
            (v) => isIntegerGreaterThanOrEqual(v),
        ),
        publicSaleOfferedTokens: ValidationSchemaUtils.emptyAsNull.test(
            "Is integer greater than one",
            "Must be an integer greater than one",
            (v) => isIntegerGreaterThanOrEqual(v),
        ),
        initialCirculatingSupply: ValidationSchemaUtils.emptyAsNull.test(
            "Is integer greater than one",
            "Must be an integer greater than one",
            (v) => isIntegerGreaterThanOrEqual(v),
        ),
        launchPlatform: yup
            .string()
            .test("Is required for a moderator or an Admin", REQUIRED_FIELD_MESSAGE, (v) => isLaunchPlatformRequired(v)),
        icoLayer: yup
            .string()
            .test("Is required for a moderator or an Admin", REQUIRED_FIELD_MESSAGE, (v) => isIcoLayerRequired(v)),
    };
}

function validateDates(
    start: string | undefined,
    end: string | undefined,
    ctx: yup.TestContext<Record<string, unknown>>,
    message: string,
): boolean | ValidationError {
    if (!start || !end) {
        return true;
    }
    const isBefore = DateUtils.isDateStringBefore(start, end);
    if (!isBefore) {
        return ctx.createError({ message });
    }
    return true;
}

export enum QuarterSelectorType {
    PUBLIC_SALE,
    PRE_SALE,
    KYC,
    TRADABLE,
}

const IcoEditStepTwo = <T extends IcoStepTwoInputs>({
    ico,
    register,
    formState,
    control,
    displayCompact = false,
}: IcoEditStepTwoProps<T>): JSX.Element => {
    const { isAuthAsModeratorOrAdmin } = useGetUserInfo();

    const [usePublicSaleDate, setUsePublicSaleDate] = useState<boolean>(true);
    const [usePreSaleDate, setUsePreSaleDate] = useState<boolean>(true);
    const [useKycDate, setUseKycDate] = useState<boolean>(true);
    const [useTradableDate, setUseTradableDate] = useState<boolean>(true);
    const [hasKyc, setHasKyc] = useState(ico.hasKyc);

    const icoEditFieldsHelper = new IcoEditFieldsStepTwoHelper(register, formState, control, ico);

    useEffect(() => {
        isModeratorOrAdmin = isAuthAsModeratorOrAdmin();
        window.scrollTo(0, 0);
    }, []);

    useEffect(() => {
        setUsePublicSaleDate(!ico.publicSaleDate.quarterInfo?.quarter);
        setUsePreSaleDate(!ico.preSaleDate.quarterInfo?.quarter);
        setUseKycDate(!ico.kycDate.quarterInfo?.quarter);
        setUseTradableDate(!ico.tradableDate.quarterInfo?.quarter);
    }, [ico]);

    const toggleShowQuarterSelector = (type: QuarterSelectorType) => {
        switch (type) {
            case QuarterSelectorType.PUBLIC_SALE:
                setUsePublicSaleDate(!usePublicSaleDate);
                ico.publicSaleDate.useDate = !ico.publicSaleDate.useDate;
                break;
            case QuarterSelectorType.PRE_SALE:
                setUsePreSaleDate(!usePreSaleDate);
                ico.preSaleDate.useDate = !ico.preSaleDate.useDate;
                break;
            case QuarterSelectorType.KYC:
                setUseKycDate(!useKycDate);
                ico.kycDate.useDate = !ico.kycDate.useDate;
                break;
            case QuarterSelectorType.TRADABLE:
                setUseTradableDate(!useTradableDate);
                ico.tradableDate.useDate = !ico.tradableDate.useDate;
                break;
            default:
                break;
        }
    };

    return (
        <>
            {!displayCompact && (
                <>
                    <Row>
                        <Col xs={12} md={4}>
                            {icoEditFieldsHelper.getIcoLayerElement()}
                        </Col>
                        <Col xs={12} md={4}>
                            {icoEditFieldsHelper.getTokenNameElement()}
                        </Col>
                        <Col xs={12} md={4}>
                            {icoEditFieldsHelper.getTokenElement()}
                        </Col>
                    </Row>
                    <Row>
                        <Col xs={12} md={6}>
                            {icoEditFieldsHelper.getInitialCirculatingSupplyElement()}
                        </Col>
                        <Col xs={12} md={6}>
                            {icoEditFieldsHelper.getMaxTokenSupplyElement()}
                        </Col>
                    </Row>
                    <Row>
                        <Col xs={12} md={6}>
                            {icoEditFieldsHelper.getPrivateSaleSoldTokensElement()}
                        </Col>
                        <Col xs={12} md={6}>
                            {icoEditFieldsHelper.getPrivateSalePriceElement()}
                        </Col>
                    </Row>
                    <Row>
                        <Col xs={12} md={6}>
                            {icoEditFieldsHelper.getPrivateSaleOfferedTokensElement()}
                        </Col>
                        <Col xs={12} md={6}>
                            {icoEditFieldsHelper.getPublicSaleOfferedTokensElement()}
                        </Col>
                    </Row>
                    <Row>
                        <Col xs={12} md={4}>
                            {icoEditFieldsHelper.getSoftcapElement()}
                        </Col>
                        <Col xs={12} md={4}>
                            {icoEditFieldsHelper.getHardcapElement()}
                        </Col>
                        <Col xs={12} md={4}>
                            {icoEditFieldsHelper.getPublicSalePriceElement()}
                        </Col>
                    </Row>
                    <Row>
                        <Col xs={12} md={6}>
                            {icoEditFieldsHelper.getPreSaleDateElement(usePreSaleDate, toggleShowQuarterSelector)}
                        </Col>
                        <Col xs={12} md={6}>
                            {icoEditFieldsHelper.getPublicSaleDateElement(usePublicSaleDate, toggleShowQuarterSelector)}
                        </Col>
                    </Row>
                    <Row>
                        <Col xs={12} md={6} className="d-flex justify-content-between">
                            {icoEditFieldsHelper.getHasKycElement(setHasKyc, false)}
                            {hasKyc && icoEditFieldsHelper.getKycDateElement(useKycDate, toggleShowQuarterSelector)}
                        </Col>
                        <Col xs={12} md={6}>
                            {icoEditFieldsHelper.getTradableDateElement(useTradableDate, toggleShowQuarterSelector)}
                        </Col>
                    </Row>
                    <Row>
                        <Col xs={12} md={12}>
                            {isAuthAsModeratorOrAdmin() && icoEditFieldsHelper.getLaunchPlatformElement()}
                        </Col>
                    </Row>
                </>
            )}

            {displayCompact && (
                <>
                    <Row>
                        <Col xs={12} md={4}>
                            {icoEditFieldsHelper.getIcoLayerElement()}
                        </Col>
                        <Col xs={12} md={4}>
                            {icoEditFieldsHelper.getTokenNameElement()}
                        </Col>
                        <Col xs={12} md={4}>
                            {isAuthAsModeratorOrAdmin() && icoEditFieldsHelper.getLaunchPlatformElement()}
                        </Col>
                    </Row>

                    <Row>
                        <Col xs={12} md={3}>
                            {icoEditFieldsHelper.getInitialCirculatingSupplyElement()}
                        </Col>
                        <Col xs={12} md={3}>
                            {icoEditFieldsHelper.getMaxTokenSupplyElement()}
                        </Col>
                        <Col xs={12} md={3}>
                            {icoEditFieldsHelper.getSoftcapElement()}
                        </Col>
                        <Col xs={12} md={3}>
                            {icoEditFieldsHelper.getHardcapElement()}
                        </Col>
                    </Row>
                    <Row>
                        <Col xs={12} md={4}>
                            {icoEditFieldsHelper.getPrivateSaleOfferedTokensElement()}
                        </Col>
                        <Col xs={12} md={4}>
                            {icoEditFieldsHelper.getPrivateSaleSoldTokensElement()}
                        </Col>
                        <Col xs={12} md={4}>
                            {icoEditFieldsHelper.getPrivateSalePriceElement()}
                        </Col>
                    </Row>
                    <Row>
                        <Col xs={12} md={4}>
                            {icoEditFieldsHelper.getPublicSaleOfferedTokensElement()}
                        </Col>
                        <Col xs={12} md={4}>
                            {icoEditFieldsHelper.getPublicSalePriceElement()}
                        </Col>
                        <Col xs={12} md={4} className="d-flex justify-content-between">
                            {icoEditFieldsHelper.getHasKycElement(setHasKyc)}
                            {hasKyc && icoEditFieldsHelper.getKycDateElement(useKycDate, toggleShowQuarterSelector)}
                        </Col>
                    </Row>
                    <Row>
                        <Col xs={12} md={4}>
                            {icoEditFieldsHelper.getPreSaleDateElement(usePreSaleDate, toggleShowQuarterSelector)}
                        </Col>
                        <Col xs={12} md={4}>
                            {icoEditFieldsHelper.getPublicSaleDateElement(usePublicSaleDate, toggleShowQuarterSelector)}
                        </Col>
                        <Col xs={12} md={4}>
                            {icoEditFieldsHelper.getTradableDateElement(useTradableDate, toggleShowQuarterSelector)}
                        </Col>
                    </Row>
                </>
            )}
        </>
    );
};

export default IcoEditStepTwo;
