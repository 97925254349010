import React from "react";
import ReactPlayer from "react-player";
import styled from "styled-components";
import { BREAKPOINTS } from "../../../assets/sass/breakpoints";
import EntityHeroImg from "../../../assets/img/entity-hero-image.webp";
import EntityHeroMobileImg from "../../../assets/img/entity-hero-image-mobile.webp";
import { Image } from "react-bootstrap";
import { ProjectGeneralDetails } from "../../Launchpad/launchpad.types";
import NextLaunchpadCardTop from "./NextLaunchpadCardTop";
import { isMobile } from "react-device-detect";
import { useGetProjectGeneralDetails } from "../../../common/hooks";

const StyledImage = styled(Image)`
    width: 100%;

    @media (${BREAKPOINTS.tablet}) {
        z-index: 0;
        position: absolute;
        top: 0;
        margin-top: -7.142rem;
    }
`;

const MobileImage = styled.div`
    margin-top: -3.571rem;
`;

const Title = styled.div`
    width: 100%;
    z-index: 1;
    position: relative;
    display: flex;
    justify-content: center;
    padding-left: 2rem;
    padding-right: 2rem;
    font-size: 2.5rem;
    line-height: 3rem;
    font-weight: 300;
    background-color: #51e2df;
    background-image: linear-gradient(180deg, #51e2df, #83ff8c);
    background-size: 100%;
    background-repeat: repeat;
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
    -moz-background-clip: text;
    -moz-text-fill-color: transparent;
    text-align: center;

    @media (${BREAKPOINTS.tablet}) {
        padding-left: unset;
        padding-right: unset;
        width: calc(100vw / 3.8);
        top: 5rem;
        font-size: calc(100vw / 100 * 3.5);
        line-height: calc(100vw / 100 * 4.7);
        text-align: left;
        left: 2rem;
    }

    @media (${BREAKPOINTS.phone}) {
        margin-top: 34px;
    }

    @media (${BREAKPOINTS.tablet}) {
        padding-left: unset;
        padding-right: unset;
        width: calc(100vw / 3.8);
        top: 5rem;
        font-size: calc(100vw / 100 * 3.5);
        line-height: calc(100vw / 100 * 4.7);
        text-align: left;
        left: 2rem;
    }
`;

const Container = styled.div`
    --player-height: 25rem;

    @media (${BREAKPOINTS.desktop}) {
        --player-height: 57.142rem;
    }
`;

// TODO: put the url here when the video is ready
const url = "";

const DeFiEcosystem = () => {
    const { data: projectGeneralDetails, isLoading } = useGetProjectGeneralDetails();

    return (
        <Container>
            <Title>Growth Engine for Web3</Title>
            {!isMobile && !isLoading && (
                <NextLaunchpadCardTop projectGeneralDetails={projectGeneralDetails as ProjectGeneralDetails} />
            )}

            {ReactPlayer.canPlay(url) ? (
                <ReactPlayer
                    playing
                    loop
                    muted
                    width="100%"
                    height="var(--player-height)"
                    url={url}
                    playsinline
                    onContextMenu={(e: MouseEvent) => e.preventDefault()}
                />
            ) : (
                <>
                    <MobileImage className="d-block d-md-none">
                        <StyledImage src={EntityHeroMobileImg} alt="DeFi ecosystem" />
                    </MobileImage>
                    <StyledImage src={EntityHeroImg} className="d-none d-md-block" alt="DeFi ecosystem" />
                </>
            )}
        </Container>
    );
};

export default React.memo(DeFiEcosystem);
