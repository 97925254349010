import React, { useEffect } from "react";
import styled from "styled-components";
import { WhyEntity } from "./WhyEntity";
import { Investors } from "./Investors";
import { DeFiEcosystem } from "./DeFiEcosystem";
import { Startups } from "./Startups";
import { useNavigate } from "react-router-dom";
import { routeNames } from "../../routes/routes";

const Container = styled.div`
    --block-title-margin: 19.5%;
    width: 100%;
`;

const Landing = () => {
    const navigate = useNavigate();

    useEffect(() => {
        navigate(routeNames.launchpad);
    }, []);

    return (
        <Container>
            <DeFiEcosystem />
            <WhyEntity />
            <Startups />
            <Investors />
        </Container>
    );
};

export default Landing;
