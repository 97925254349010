import BinaryUtils from "./BinaryUtils";
import _ from "lodash";
import { Address, Transaction, TransactionOptions, TransactionVersion } from "@multiversx/sdk-core/out";
import { AccountType } from "@multiversx/sdk-dapp/types";

class TxUtils {
    static decodeComposeDataToString(txData: string): string[] {
        return BinaryUtils.fromBase64ToString(txData).split("@").map(BinaryUtils.fromHexToString);
    }

    static decodeComposeData(txData: string): string[] {
        return BinaryUtils.fromBase64ToString(txData).split("@");
    }

    static decodeMixComposeData(txData: string): string[] {
        const parts = BinaryUtils.fromBase64ToString(txData)
            .split("@")
            .map((part) => part.trim())
            .filter((part) => !_.isEmpty(part));
        const decodedParts = parts
            .map(BinaryUtils.fromHexToString)
            .map((part) => part.trim())
            .filter((part) => !_.isEmpty(part));
        return [...parts, ...decodedParts];
    }

    static checkAccountAndAddGuard(tx: Transaction, account?: AccountType): Transaction {
        if (account && account.isGuarded && account.activeGuardianAddress) {
            tx.setGuardian(Address.fromBech32(account.activeGuardianAddress));
            tx.setVersion(TransactionVersion.withTxOptions());
            tx.setOptions(
                TransactionOptions.withOptions({
                    guarded: true,
                    hashSign: true,
                }),
            );
        }

        return tx;
    }
}

export default TxUtils;
