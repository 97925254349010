export const ENTITY_MODULE_LINK_BASE_PATH = "https://entity.gitbook.io/entity";

export const modulesLinkList = {
    projectMonitor: `${ENTITY_MODULE_LINK_BASE_PATH}/project-monitor/discover-new-launches`,
    portfolio: `${ENTITY_MODULE_LINK_BASE_PATH}/portfolio/track-your-portfolio`,
    staking: `${ENTITY_MODULE_LINK_BASE_PATH}/staking-and-delegating/explore-staking-providers`,
    rewardStrategies: `${ENTITY_MODULE_LINK_BASE_PATH}/reward-strategies/compare-strategies`,
    taxTools: `${ENTITY_MODULE_LINK_BASE_PATH}/Tax-Tools/introduction`,
    delegations: `${ENTITY_MODULE_LINK_BASE_PATH}/staking-and-delegating/monitor-your-delegations`,
    listProject: `${ENTITY_MODULE_LINK_BASE_PATH}/project-monitor/list-your-project`,
    launchpad: `${ENTITY_MODULE_LINK_BASE_PATH}/entity-launchpad/join-the-entity-launchpad`,
};
