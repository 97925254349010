import React, { FC, useMemo } from "react";
import styled, { css } from "styled-components";
import ProjectKeyMetricsCard, { KeyMetrics } from "../../../components/ProjectKeyMetricsCard/ProjectKeyMetricsCard";
import { ProjectInfoCard } from "../../../components/ProjectInfoCard";
import LaunchRoadmap from "../LaunchRoadmap";
import {
    LaunchpadStage,
    LaunchRoadmapEntry,
    ProjectGeneralDetails,
    ProjectTiersDetails,
    RetrieveTicketsInfoFn,
    StageTitle,
    Tier,
} from "../launchpad.types";
import { ErrorDummy } from "../../../components/Error";
import KycCard from "../components/KycCard";
import { Row } from "react-bootstrap";
import { KycStatusInfo } from "../kyc/launchpad.kyc.dtos";
import SnapshotCard from "../components/SnapshotCard";
import BuyTicketsCard from "../components/BuyTicketsCard";
import WinnerSelectionCard from "../components/WinnerSelectionCard";
import ClaimTokensCard from "../components/ClaimTokensCard";
import { Tickets } from "../../../services/TicketsService";
import { LaunchpadScConfig, LaunchpadScFlags } from "../../../services/LaunchpadService";
import EPlaceholder from "../../../components/Placeholder/Placeholder";
import { useGetLoginInfo } from "@multiversx/sdk-dapp/hooks";
import { DateUtils } from "../../../common";

interface IInfoCardsBlock {
    metrics?: KeyMetrics[];
    titleAdditionalInfo: JSX.Element;
    events?: LaunchRoadmapEntry[];
    userLoadingInfo: boolean;
    kycStatusInfo?: KycStatusInfo;
    projectGeneralDetails?: ProjectGeneralDetails;
    currentStage: LaunchpadStage | null;
    previousStage: LaunchpadStage | null;
    tiersDetails?: ProjectTiersDetails;
    snapshotInfo?: Tier;
    snapshotStakingValue?: number;
    isRejectedFromKyc?: boolean;
    isRejectedFromSnapshot?: boolean;
    ticketsInfo?: Tickets;
    retrieveTicketsInfo?: RetrieveTicketsInfoFn;
    scConfig?: LaunchpadScConfig;
    scShard?: number;
    scFlags?: LaunchpadScFlags;
    isAddressBlacklisted: boolean;
}

const cardCss = css`
    margin-bottom: 2rem;

    @media (min-width: 1200px) {
        width: 32%;
        margin-left: 1.5%;
        margin-right: 1.5%;

        &:first-child {
            margin-left: 0;
        }

        &:last-child {
            margin-right: 0;
        }
    }
`;

const StyledProjectKeyMetricsCard = styled(ProjectKeyMetricsCard)`
    ${cardCss};
`;

const StyledProjectInfoCard = styled(ProjectInfoCard)`
    ${cardCss};
`;

const StageCard = styled(ProjectInfoCard)`
    ${cardCss};
`;

const InfoRow = styled(Row)`
    padding-top: 1.071rem;
    padding-bottom: 1.071rem;

    &:first-child {
        padding-top: 0;
    }

    &:last-child {
        padding-bottom: 0;
    }
`;

const Container = styled.div`
    display: flex;
    flex-direction: column;
    padding: 0 !important;
`;

const StageCardContainer: FC<React.PropsWithChildren<{ title: string; className?: string }>> = ({
    title,
    children,
    className = "",
}) => {
    return (
        <StageCard titleText={title} testId="stage-card" className={className}>
            <InfoRow>{children}</InfoRow>
        </StageCard>
    );
};

const InfoCardsBlock: FC<React.PropsWithChildren<IInfoCardsBlock>> = ({
    metrics,
    titleAdditionalInfo,
    events,
    projectGeneralDetails,
    kycStatusInfo,
    userLoadingInfo,
    currentStage,
    previousStage,
    tiersDetails,
    snapshotInfo,
    snapshotStakingValue,
    isRejectedFromKyc,
    ticketsInfo,
    retrieveTicketsInfo,
    isRejectedFromSnapshot,
    scConfig,
    scShard,
    scFlags,
    isAddressBlacklisted,
}) => {
    const { isLoggedIn } = useGetLoginInfo();

    const renderComponentIfReady = (isReady: () => boolean, component: JSX.Element) => {
        return isReady() || !isLoggedIn ? component : <EPlaceholder withBtn numberOfLines={5} />;
    };

    const isDataReadyForKycStage = () => !userLoadingInfo && !!projectGeneralDetails && !!kycStatusInfo;

    const isDataReadyForSnapshotStage = () => {
        return !userLoadingInfo && !!projectGeneralDetails && !!tiersDetails && isRejectedFromKyc !== undefined;
    };

    const isDataReadyForBuyingTicketsStage = () => {
        return (
            !userLoadingInfo &&
            !!projectGeneralDetails &&
            isRejectedFromKyc !== undefined &&
            isRejectedFromSnapshot !== undefined &&
            scShard != undefined
        );
    };

    const isDataReadyForWinnerSelectionStage = () => {
        return (
            !userLoadingInfo &&
            !!projectGeneralDetails &&
            isRejectedFromKyc !== undefined &&
            isRejectedFromSnapshot !== undefined &&
            !!scConfig &&
            !!scFlags &&
            scShard != undefined
        );
    };

    const isDataReadyForClaimingTokensStage = () => {
        return (
            !userLoadingInfo &&
            !!projectGeneralDetails &&
            !!ticketsInfo &&
            isRejectedFromKyc !== undefined &&
            isRejectedFromSnapshot !== undefined &&
            !!scConfig &&
            scShard != undefined
        );
    };

    const getStageCard = (currentLaunchpad: LaunchpadStage): JSX.Element => {
        const isKycRejected = isRejectedFromKyc as boolean;
        const isSnapshotRejected = isRejectedFromSnapshot as boolean;

        switch (currentLaunchpad) {
            case LaunchpadStage.KYC:
                return renderComponentIfReady(
                    () => isDataReadyForKycStage(),
                    <KycCard projectDetails={projectGeneralDetails} kycStatus={kycStatusInfo as KycStatusInfo} />,
                );

            case LaunchpadStage.SNAPSHOT:
                return renderComponentIfReady(
                    () => isDataReadyForSnapshotStage(),
                    <SnapshotCard
                        projectDetails={projectGeneralDetails}
                        tiersDetails={tiersDetails}
                        snapshotInfo={snapshotInfo as Tier}
                        snapshotStakingValue={snapshotStakingValue}
                        isRejected={isKycRejected}
                    />,
                );

            case LaunchpadStage.BUY_TICKETS:
                return renderComponentIfReady(
                    () => isDataReadyForBuyingTicketsStage(),
                    <BuyTicketsCard
                        projectDetails={projectGeneralDetails}
                        snapshotInfo={snapshotInfo as Tier}
                        ticketsInfo={ticketsInfo as Tickets}
                        retrieveTicketsInfo={retrieveTicketsInfo}
                        isRejected={isKycRejected || isSnapshotRejected}
                        scConfig={scConfig}
                        scShard={scShard}
                    />,
                );

            case LaunchpadStage.WINNER_SELECTION:
                return renderComponentIfReady(
                    () => isDataReadyForWinnerSelectionStage(),
                    <WinnerSelectionCard
                        projectDetails={projectGeneralDetails}
                        isRejected={isKycRejected || isSnapshotRejected}
                        scConfig={scConfig}
                        scFlags={scFlags}
                        scShard={scShard}
                    />,
                );

            case LaunchpadStage.CLAIM_TOKENS:
                return renderComponentIfReady(
                    () => isDataReadyForClaimingTokensStage(),
                    <ClaimTokensCard
                        projectDetails={projectGeneralDetails}
                        ticketsInfo={ticketsInfo as Tickets}
                        retrieveTicketsInfo={retrieveTicketsInfo}
                        isRejected={isKycRejected || isSnapshotRejected}
                        scConfig={scConfig}
                        scShard={scShard}
                    />,
                );

            default:
                return <div>There is no launchpad stage</div>;
        }
    };

    const cardContent = useMemo(() => {
        if (userLoadingInfo) {
            return <EPlaceholder withBtn numberOfLines={5} />;
        }
        if (isAddressBlacklisted) {
            return (
                <ErrorDummy errorText="Your address is blacklisted. You are not allowed to participate in this public sale." />
            );
        }
        if (projectGeneralDetails?.id && currentStage) {
            return getStageCard(currentStage);
        }
        if (projectGeneralDetails?.id && previousStage) {
            return <ErrorDummy errorText="All stages are completed." />;
        }
        if (
            projectGeneralDetails?.generalMetrics.claimTokensEndTimestamp &&
            Date.now() >= DateUtils.toMillisFormat(projectGeneralDetails?.generalMetrics.claimTokensEndTimestamp)
        ) {
            return <ErrorDummy errorText="Project successfully launched." />;
        }
        return <ErrorDummy errorText="The project is not launched yet." />;
    }, [
        userLoadingInfo,
        projectGeneralDetails,
        kycStatusInfo,
        currentStage,
        previousStage,
        tiersDetails,
        snapshotInfo,
        snapshotStakingValue,
        isRejectedFromKyc,
        ticketsInfo,
        isRejectedFromSnapshot,
        isAddressBlacklisted,
        scConfig,
        scShard,
        scFlags,
    ]);

    return (
        <Container className="flex-column-reverse flex-xl-row">
            <StyledProjectKeyMetricsCard metrics={metrics} showPlaceholder={!metrics} titleAdditionalInfo={titleAdditionalInfo} />

            <StyledProjectInfoCard titleText="Launch Roadmap" testId="launch-roadmap">
                <LaunchRoadmap events={events} showPlaceholder={!events} />
            </StyledProjectInfoCard>

            <StageCardContainer title={currentStage ? StageTitle[currentStage] : "Stage"}>{cardContent}</StageCardContainer>
        </Container>
    );
};

export default InfoCardsBlock;
