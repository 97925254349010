import React, { useEffect, useState } from "react";
import { Container } from "react-bootstrap";
import { useGetAccountInfo } from "@multiversx/sdk-dapp/hooks";
import {
    ExtensionLoginButton,
    ExtensionLoginButtonPropsType,
    LedgerLoginButton,
    LedgerLoginButtonPropsType,
    OperaWalletLoginButton,
    WalletConnectLoginButton,
    WalletConnectLoginButtonPropsType,
    WebWalletLoginButton,
    WebWalletLoginButtonPropsType,
    XaliasLoginButton,
} from "@multiversx/sdk-dapp/UI";
import { useNavigate } from "react-router-dom";
import { faLock } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import styles from "../../pages/UnlockPage/login.module.scss";
import AlertService, { ModalFunction } from "../../services/AlertService";
import { SweetAlertResult } from "sweetalert2";
import { clearSessionAndContextForLogout } from "../../helpers/util.functions";
import SessionStorageService from "../../services/sessionStorageService/SessionStorageService";
import { SessionStorageKeys } from "../../services/sessionStorageService/sessionStorageKeys";
import LocalStorageService from "../../services/localStorageService/LocalStorageService";
import { LocalStorageKeys } from "../../services/localStorageService/LocalStorageKeys";
import { BackgroundDecorations } from "../../components/BackgroundDecorations";
import Extension from "../../assets/img/Extension";
import Ledger from "../../assets/img/Ledger";
import mappedRoutes, { AuthRoute, routeNames } from "../../routes/routes";
import { useDispatch } from "../../context";
import { ReactComponent as MultiversXLogo } from "assets/img/x-light.svg";
import { walletConnectV2ProjectId } from "../../config";
import xPortalLogo from "../../assets/img/xPortalLogo";
import { WebWallet } from "../../assets/img/WebWallet";
import xAliasLogo from "../../assets/img/xAliasLogo";

interface ConnectionType extends Pick<WalletConnectLoginButtonPropsType, "isWalletConnectV2"> {
    title: string;
    name: string;
    background: string;
    icon: React.FC<React.PropsWithChildren>;
    component: React.FC<
        | WebWalletLoginButtonPropsType
        | WalletConnectLoginButtonPropsType
        | ExtensionLoginButtonPropsType
        | LedgerLoginButtonPropsType
    >;
}

const connects: Array<ConnectionType> = [
    {
        title: "Desktop",
        name: "MultiversX Web Wallet",
        background: "#000000",
        icon: WebWallet,
        component: WebWalletLoginButton,
    },
    {
        title: "Hardware",
        name: "Ledger",
        background: "#000000",
        icon: Ledger,
        component: LedgerLoginButton,
    },
    {
        title: "Mobile",
        name: "xPortal App",
        background: "#000000",
        icon: xPortalLogo,
        isWalletConnectV2: true,
        component: WalletConnectLoginButton,
    },
    {
        title: "Browser",
        name: "MultiversX DeFi Wallet",
        background: "#000000",
        icon: Extension,
        component: ExtensionLoginButton,
    },
    {
        title: "Opera",
        name: "Opera Crypto Wallet - Beta",
        background: "#000000",
        icon: MultiversXLogo,
        component: OperaWalletLoginButton,
    },
    {
        title: "xAlias",
        name: "xAlias Auth",
        background: "#000000",
        icon: xAliasLogo,
        component: XaliasLoginButton,
    },
];

const Unlock = () => {
    const loginRoute = SessionStorageService.getItem(SessionStorageKeys.loginRoute) ?? routeNames.home;
    const logoutReason = SessionStorageService.getItem(SessionStorageKeys.logoutReason);

    const [routeInfo, setRouteInfo] = useState<AuthRoute>();
    const { address } = useGetAccountInfo();
    const navigate = useNavigate();
    const dispatch = useDispatch();

    const redirectConditionally = () => {
        checkRouteInfoText(loginRoute);
        if (Boolean(address)) {
            navigate(loginRoute);
        }
    };

    useEffect(redirectConditionally, [address]);
    useEffect(() => checkLogoutReason(), []);

    const checkLogoutReason = () => {
        if (!!logoutReason) {
            getModalFunction()(logoutReason.text).then(handleAlertResult);
        }
    };

    const getModalFunction = (): ModalFunction => {
        switch (logoutReason?.type) {
            case "success":
                return AlertService.success;
            case "error":
                return AlertService.error;
            default:
                return AlertService.success;
        }
    };

    const handleAlertResult = (result: SweetAlertResult): void => {
        if (result.isConfirmed || result.isDismissed) {
            SessionStorageService.removeItem(SessionStorageKeys.logoutReason);
            if (LocalStorageService.getItem(LocalStorageKeys.dappCoreLoginExpiresAt)) {
                handleLoginExpiredSession();
            }
        }
    };

    const handleLoginExpiredSession = (): void => {
        if (isLoginSessionExpired()) {
            clearSessionAndContextForLogout(dispatch);
            AlertService.info("Session expired! Please log in again.").then(() => window.location.reload());
        }
    };

    const isLoginSessionExpired = (): boolean => {
        return LocalStorageService.getItem(LocalStorageKeys.dappCoreLoginExpiresAt) < new Date().getTime();
    };

    const checkRouteInfoText = (value: string) => {
        const routeResult = mappedRoutes.find((route) => {
            return route.path === value;
        });

        setRouteInfo(routeResult);
    };

    return (
        <div className="mt-5">
            <BackgroundDecorations />

            <Container className={styles.unlock}>
                <div className={styles.wrapper}>
                    <strong className={styles.heading}>Connect your MultiversX wallet</strong>

                    <div className={styles.description}>
                        <FontAwesomeIcon className="me-2" icon={faLock} />
                        Scam/Phishing verification: <span className="text-green">https://</span>app.entity.global
                    </div>

                    {loginRoute !== routeNames.home && routeInfo?.loginText !== "" && routeInfo?.loginText !== undefined && (
                        <div className={styles.textinfo}>{routeInfo?.loginText}</div>
                    )}

                    <div className={styles.connects}>
                        {connects.map((connect: ConnectionType) => (
                            <connect.component
                                nativeAuth={true}
                                key={connect.name}
                                callbackRoute={loginRoute}
                                logoutRoute={routeNames.unlock}
                                isWalletConnectV2={walletConnectV2ProjectId ? connect.isWalletConnectV2 : undefined}
                            >
                                <span className={styles.connect}>
                                    <span className={styles.title}>{connect.title}</span>

                                    <span className={styles.icon} style={{ background: connect.background }}>
                                        <connect.icon />
                                    </span>

                                    <span className={styles.name}>{connect.name}</span>
                                </span>
                            </connect.component>
                        ))}
                    </div>
                    <div className="p-4">
                        <a
                            className="text-green mx-auto"
                            target="_blank"
                            rel="noreferrer"
                            href={"https://wallet.multiversx.com/create"}
                        >
                            Create a new wallet
                        </a>
                    </div>
                </div>
            </Container>
        </div>
    );
};

export default Unlock;
