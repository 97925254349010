import React, { FC } from "react";
import { KycStatusInfo } from "../kyc/launchpad.kyc.dtos";
import KycStatusDescription from "../../KYC/KycStatusDescription";
import { LaunchpadCardBaseProps } from "../launchpad.types";
import { useGetLoginInfo } from "@multiversx/sdk-dapp/hooks";
import { ELinkButton } from "../../../components/Buttons";
import { routeNames } from "../../../routes/routes";
import { DateUtils, ECountDownWrapper } from "common";
import { useService } from "../../../services/config/dependencyInjectorConfig";
import EventsService, { EventsNames } from "../../../services/EventsService";

interface KycCardProps extends Partial<Pick<LaunchpadCardBaseProps, "projectDetails">> {
    kycStatus: KycStatusInfo;
    testId?: string;
}

const KycCard: FC<React.PropsWithChildren<KycCardProps>> = ({ projectDetails, kycStatus, testId = "kyc-card" }) => {
    const { isLoggedIn } = useGetLoginInfo();
    const [eventsService] = useService(EventsService);

    if (!isLoggedIn) {
        return (
            <>
                <div className="d-flex justify-content-center align-items-center" data-testid={testId}>
                    <ELinkButton to={routeNames.unlock} testId="login-btn" className="text-uppercase">
                        Login
                    </ELinkButton>
                </div>
                {projectDetails?.generalMetrics?.kycEndTimestamp &&
                    DateUtils.toMillisFormat(projectDetails?.generalMetrics?.kycEndTimestamp) > Date.now() && (
                        <div className="text-center pt-1 pb-3" data-testid={testId}>
                            <ECountDownWrapper
                                time={projectDetails?.generalMetrics?.kycEndTimestamp}
                                onCompleteHandler={() => eventsService.emitWithDelay(EventsNames.RERENDER_LAUNCHPAD)}
                                scope="ends"
                                className="text-center"
                            />
                        </div>
                    )}
            </>
        );
    }

    return (
        <div className="d-flex justify-content-center align-items-center flex-column pt-1 pb-3" data-testid={testId}>
            <KycStatusDescription statusInfo={kycStatus} />
            {projectDetails?.generalMetrics?.kycEndTimestamp &&
                DateUtils.toMillisFormat(projectDetails?.generalMetrics?.kycEndTimestamp) > Date.now() && (
                    <ECountDownWrapper
                        time={projectDetails?.generalMetrics?.kycEndTimestamp}
                        onCompleteHandler={() => eventsService.emitWithDelay(EventsNames.RERENDER_LAUNCHPAD)}
                        scope="ends"
                        className="text-center"
                    />
                )}
        </div>
    );
};

export default KycCard;
