import { Alert, Card, Col, Form, Row } from "react-bootstrap";
import { ESlider, FormControlGroup, useYupValidationResolver, ValidationSchemaUtils } from "../../../common";
import { denomination, launchpadSC, network } from "../../../config";
import { EButton } from "../../../components/Buttons";
import EModal from "../../../components/common/EModal";
import React, { FC, useEffect, useMemo, useState } from "react";
import * as yup from "yup";
import { REQUIRED_MSG } from "../../../common/utils/ValidationSchemaUtils";
import { useForm } from "react-hook-form";
import denominate from "../../../components/Denominate/denominate";
import { useGetAccountInfo } from "@multiversx/sdk-dapp/hooks";
import { sendTransactions } from "@multiversx/sdk-dapp/services";
import SessionStorageService from "../../../services/sessionStorageService/SessionStorageService";
import { useService } from "../../../services/config/dependencyInjectorConfig";
import TicketsService from "../../../services/TicketsService";
import { ProjectGeneralDetails } from "../launchpad.types";
import clsx from "clsx";
import styled from "styled-components";
import { BaseModalProps } from "../../../common/types/common.types";
import EButtonSmall from "../../../components/Buttons/SmallButton";
import { BREAKPOINTS } from "../../../assets/sass/breakpoints";
import AlertService from "../../../services/AlertService";
import TxUtils from "../../../common/utils/TxUtils";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faTriangleExclamation } from "@fortawesome/free-solid-svg-icons";

const StyledCol = styled(Col)`
    padding-left: 0 !important;
    margin-top: 2rem;

    @media (${BREAKPOINTS.tablet}) {
        margin-top: 0;
    }
`;

const AlertWarning = styled(Alert)`
    width: 98%;
    display: flex;
    align-items: center;
    justify-content: center;
`;

const egldTransactionBuffer = 0.005;

interface BuyTicketsInput {
    buyAmount: number;
    ticketsNoInput: number;
}

const getValidationSchema = (availableBalance: number, egldLabel: string, maxTickets: number) => {
    return yup.object({
        buyAmount: yup
            .number()
            .typeError("Not a number")
            .required(REQUIRED_MSG)
            .max(availableBalance, "Insufficient funds")
            .test(
                "Is available balance enough",
                "Insufficient funds",
                (buyAmount) => !!buyAmount && availableBalance >= buyAmount,
            )
            .min(0.00001, `Minimum 0.00001 ${egldLabel} required`),
        ticketsNoInput: ValidationSchemaUtils.string
            .test(
                "Is at least 1",
                "Minimum 1 ticket required",
                (ticketsNo) => !!ticketsNo && !isNaN(Number(ticketsNo)) && +ticketsNo > 0,
            )
            .test(
                "Is not more than max",
                `At most ${maxTickets} tickets`,
                (ticketsNo) => !!ticketsNo && !isNaN(Number(ticketsNo)) && +ticketsNo <= maxTickets,
            )
            .test(
                "Is integer",
                "Incorrect value",
                (ticketsNo) => !!ticketsNo && !ticketsNo.toString().includes(".") && !ticketsNo.toString().includes(","),
            ),
    });
};

interface BuyTicketsModalProps extends BaseModalProps {
    projectDetails: ProjectGeneralDetails;
    maxTicketsToConfirm: number;
}

export const BuyTicketsModal: FC<React.PropsWithChildren<BuyTicketsModalProps>> = ({
    show,
    closeModalHandler,
    projectDetails,
    maxTicketsToConfirm,
}) => {
    const [availableBalance, setAvailableBalance] = useState(0);
    const [ticketsNoSlider, setTicketsNoSlider] = useState<number>(1);
    const [ticketsNoInput] = useState<number>(1);
    const [availableMaxTickets, setAvailableMaxTickets] = useState<number>(0);
    const [agreeTermsOfSale, setAgreeTermsOfSale] = useState(false);
    const [agreeOver18Years, setAgreeOver18Years] = useState(false);

    const { account } = useGetAccountInfo();
    const [ticketsService] = useService(TicketsService);

    const accountBalance = useMemo(() => {
        const balance = denominate({
            input: account.balance,
            denomination,
            decimals: 4,
            showLastNonZeroDecimal: false,
        });
        return +balance - egldTransactionBuffer;
    }, [account]);

    const validationResolver = useYupValidationResolver(
        getValidationSchema(accountBalance, network.egldLabel, maxTicketsToConfirm),
    );

    const { register, handleSubmit, formState, setValue, watch } = useForm<BuyTicketsInput>({
        resolver: validationResolver,
    });

    const watchTicketsNoInput = watch("ticketsNoInput");

    useEffect(() => {
        computeAvailableBalance();
    }, [account.balance, maxTicketsToConfirm]);

    useEffect(() => {
        if (watchTicketsNoInput && +watchTicketsNoInput !== ticketsNoSlider) {
            setBuyAmount(+watchTicketsNoInput);
            setTicketsNoSlider(+watchTicketsNoInput);
        }
    }, [watchTicketsNoInput]);

    const computeAvailableBalance = (): void => {
        const available = Math.max(0, +accountBalance.toFixed(4));
        const availableTicketsNoToBuy = Math.min(
            Math.floor(+(available / projectDetails?.lotteryTicketPrice)),
            maxTicketsToConfirm,
        );
        setAvailableBalance(available);
        setAvailableMaxTickets(availableTicketsNoToBuy);
        setTicketsNoInput(availableTicketsNoToBuy);
    };

    const setMaxTickets = () => {
        setBuyAmount(availableMaxTickets);
        setTicketsNoInput(availableMaxTickets);
        setTicketsNoSlider(availableMaxTickets);
    };

    const onAmountSliderChangeHandler = (value: number | number[]): void => {
        const noOfTickets = +(value as number).toFixed(0);
        setBuyAmount(Number(noOfTickets));
        setTicketsNoInput(noOfTickets);
        setTicketsNoSlider(noOfTickets);
    };

    const setTicketsNoInput = (noOfTickets: number) => {
        setValue("ticketsNoInput", noOfTickets, { shouldValidate: true });
    };

    const setBuyAmount = (tickets: number) => {
        const amount = (projectDetails?.lotteryTicketPrice * tickets).toFixed(4);
        setValue("buyAmount", +amount, { shouldValidate: true });
    };

    const onBuyTicketsHandler = async (input: BuyTicketsInput) => {
        const tx = await ticketsService.getConfirmTicketsTx(projectDetails.id, +input.ticketsNoInput);
        if (tx.receiver !== launchpadSC) {
            AlertService.error("The SC for buying tickets is incorrect. Please contact admins!");
            return;
        }

        const { sessionId } = await sendTransactions({
            transactions: [TxUtils.checkAccountAndAddGuard(tx, account)],
        });
        if (sessionId) {
            SessionStorageService.addSessionId(sessionId);
        }

        closeModalHandler();
    };

    const availableInfoAlert = useMemo(() => {
        const remainingTickets = maxTicketsToConfirm - availableMaxTickets;
        if (remainingTickets > 0) {
            const balanceToIncrease = Math.abs(availableBalance - remainingTickets * projectDetails?.lotteryTicketPrice).toFixed(
                2,
            );
            return (
                <Alert variant="info" className="mt-4 mb-0">
                    To buy {remainingTickets} more tickets, increase your balance by {balanceToIncrease} {network.egldLabel}.
                </Alert>
            );
        }
    }, [availableBalance, availableMaxTickets]);

    return (
        <EModal show={show} onHide={closeModalHandler} size="lg" aria-labelledby="contained-modal-title-vcenter" centered>
            <EModal.Header closeButton>
                <EModal.Title>Select tickets quantity</EModal.Title>
            </EModal.Header>
            <Form className="mt-3" onSubmit={handleSubmit(onBuyTicketsHandler)}>
                <EModal.Body>
                    <Card>
                        <AlertWarning variant="info" className="mt-1 py-3">
                            <FontAwesomeIcon size={"2x"} icon={faTriangleExclamation} className="me-3" />
                            <div>
                                Unfortunately, 2FA with guardians on xPortal is not supported for now. Please consider to disable
                                it or use an alternative method of authentication using web wallet or DeFi wallet extension.
                            </div>
                        </AlertWarning>
                        <Card.Body>
                            <Form.Group className="mt-2 mb-0" controlId="buyTickets-chooseAmount">
                                <Row>
                                    <Col sm={10} md={10} lg={11}>
                                        <ESlider
                                            min={0}
                                            max={maxTicketsToConfirm}
                                            step={1}
                                            value={ticketsNoSlider}
                                            onChange={onAmountSliderChangeHandler}
                                        />
                                    </Col>
                                    <StyledCol sm={1} md={1} lg={1}>
                                        <EButtonSmall
                                            className="mt-3 mt-md-0 ml-1 py-1 px-3 mx-auto h-auto"
                                            onClick={setMaxTickets}
                                            testId="set-max"
                                        >
                                            max
                                        </EButtonSmall>
                                    </StyledCol>
                                </Row>
                                <Row className={"mt-5 flex-center"}>
                                    <Col sm={6} md={6} lg={6}>
                                        <FormControlGroup
                                            disabled
                                            name="buyAmount"
                                            label={`Amount (${network.egldLabel})`}
                                            type="number"
                                            defaultValue={projectDetails?.lotteryTicketPrice}
                                            register={register}
                                            formState={formState}
                                            className={clsx([
                                                {
                                                    "e-mb-4": !!formState.errors.ticketsNoInput && !formState.errors.buyAmount,
                                                },
                                            ])}
                                        />
                                    </Col>
                                    <Col sm={6} md={6} lg={6}>
                                        <FormControlGroup
                                            name="ticketsNoInput"
                                            label="No of tickets:"
                                            type="number"
                                            defaultValue={ticketsNoInput}
                                            step={1}
                                            max={availableMaxTickets}
                                            register={register}
                                            formState={formState}
                                            className={clsx([
                                                {
                                                    "e-mb-4": !!formState.errors.buyAmount && !formState.errors.ticketsNoInput,
                                                },
                                            ])}
                                        />
                                    </Col>
                                </Row>
                                <Row>
                                    <Form.Text className="mt-4 mt-md-0">
                                        Available: {availableBalance} {network.egldLabel}
                                    </Form.Text>
                                </Row>
                                <Row>{availableInfoAlert}</Row>
                            </Form.Group>
                        </Card.Body>
                    </Card>
                </EModal.Body>
                <EModal.Footer>
                    <div>
                        <div className="d-flex flex-row">
                            <Form.Check onChange={(event) => setAgreeTermsOfSale(event.target.checked)} />
                            <span className="ml-0-5">
                                I hereby declare that I have read and agree with the{" "}
                                <a href={projectDetails.termsOfSaleUrl} target="_blank" rel="noreferrer" className="text-green">
                                    Terms of Sale
                                </a>
                                .
                            </span>
                        </div>
                        <div className="d-flex flex-row mt-2">
                            <Form.Check onChange={(event) => setAgreeOver18Years(event.target.checked)} />
                            <span className="ml-0-5">
                                I hereby declare that I am over 18 years old and that I am not acting as a proxy but on my own
                                behalf and that I am the beneficial owner of the funds used for purchasing the Tokens.
                            </span>
                        </div>
                    </div>
                    <EButton
                        type="submit"
                        className="mx-auto"
                        testId="buy-tickets"
                        disabled={!agreeTermsOfSale || !agreeOver18Years}
                    >
                        Buy Tickets
                    </EButton>
                </EModal.Footer>
            </Form>
        </EModal>
    );
};

export default BuyTicketsModal;
