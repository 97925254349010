import React, { useEffect, useRef, useState } from "react";
import { Form } from "react-bootstrap";
import styled from "styled-components";
import { EButton } from "../Buttons";
import { faEnvelope } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import AlertService from "../../services/AlertService";
import { routeNames } from "../../routes/routes";
import { BREAKPOINTS } from "../../assets/sass/breakpoints";
import { PageBlockTitle } from "../PageBlockTitle";
import clsx from "clsx";
import { newsLetter } from "../../config";

const InputContainer = styled.div`
    margin: 0;
    width: 100%;

    .agree-checkbox-label {
        color: white;
        font-size: 0.8rem;
        padding-top: 0.1rem;
    }

    div.mce_inline_error {
        font-family: var(--entity-font-family);
        background: transparent !important;
        color: #fe7777 !important;
        margin-top: 0.25rem;
        margin-bottom: 0 !important;
        font-size: 0.875em;
        padding: 0 !important;
        font-weight: unset !important;
    }

    @media (${BREAKPOINTS.tablet}) {
        width: unset;
        min-width: 28.571rem;
        margin-right: 2rem;
    }
`;

const Input = styled.input`
    &.form-control {
        background-color: var(--field-bg-color);
        color: white;
        box-sizing: border-box;
        font-size: 1rem;
        min-height: 2.857rem;
        border-radius: 0 !important;
        border: none !important;
    }
`;

const SubscriptionContainer = styled.div`
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 80%;
    @media (${BREAKPOINTS.tablet}) {
        flex-direction: row;
        width: 100%;
    }
`;

const loadScript = (url: string) =>
    new Promise((resolve, reject) => {
        let ready = false;
        if (!document) {
            reject(new Error("Document was not defined"));
        }
        const tag = document.getElementsByTagName("script")[0];
        const script = document.createElement("script");

        script.type = "text/javascript";
        script.src = url;
        script.async = true;
        script.onload = () => {
            if (!ready) {
                ready = true;
                resolve(script);
            }
        };

        script.onerror = () => {
            reject();
        };

        script.onabort = () => {
            reject();
        };

        if (tag.parentNode != null) {
            tag.parentNode.insertBefore(script, tag);
        }
    });

const SubscriptionForm = styled(Form)`
    width: 100%;
    z-index: 1;
`;

const CheckBoxContainer = styled.div`
    color: white;
    display: flex;
    flex-direction: row;
    margin-top: 1rem;
    justify-content: center;

    .inactive {
        color: #708787;

        a {
            color: var(--entity-green-dark);

            :hover {
                color: var(--entity-green);
            }
        }
    }

    @media (${BREAKPOINTS.tablet}) {
        justify-content: unset;
    }
`;

const SubmitBtnContainer = styled.div`
    margin-top: 2.357rem;
    @media (${BREAKPOINTS.tablet}) {
        margin-top: unset;
        align-self: flex-start;
        padding-left: 2rem;
    }
`;

const PageTitle = styled(PageBlockTitle)`
    display: block;
    margin-bottom: 2rem;
    @media (${BREAKPOINTS.tablet}) {
        display: none;
    }
`;

const ElementsContainer = styled.div`
    width: 100%;
    @media (${BREAKPOINTS.tablet}) {
        display: flex;
        flex-direction: column;
    }
`;

function parseErrorMessage(message: string): string {
    if (message.includes("too many")) return "Email has already been submitted";
    if (message.includes("enter a value")) return "Please enter a valid email";
    if (message.includes("portion of the email address is invalid"))
        return "The email address you entered is invalid. Please check the domain and try again.";
    return message;
}

const NewsLetterSubscription = () => {
    const [showElement, setShowElement] = useState(true);
    const [agree, setAgree] = useState(false);
    const [isReady, setIsReady] = useState(false);
    const [btnLoading, setBtnLoading] = useState(false);
    const formRef = useRef<null | HTMLFormElement>(null);

    const success = ({ result, msg }: { result: string; msg: string }) => {
        setBtnLoading(false);
        switch (result) {
            case "error":
                AlertService.errorToast(parseErrorMessage(msg));
                break;
            case "success":
                AlertService.successToast(msg);
                formRef.current?.reset();
                break;
            default:
                AlertService.infoToast("Please try again");
                formRef.current?.reset();
                break;
        }
    };

    useEffect(() => {
        loadNewsLettersScript()
            .then(() => {
                // eslint-disable-next-line @typescript-eslint/ban-ts-comment
                // @ts-ignore
                window.mc.ajaxOptions.success = success;
                setIsReady(true);
                setShowElement(true);
            })
            .catch(() => {
                setShowElement(false);
                console.error("Cannot load NewsLetters Subscription script.");
            });
    }, []);

    const loadNewsLettersScript = async () => {
        await loadScript(newsLetter.loadScriptUrl);
    };

    if (!showElement) {
        return <></>;
    }

    return (
        <SubscriptionContainer id="mc_embed_signup">
            <PageTitle title="Stay informed" />
            <SubscriptionForm
                action={newsLetter.formSubscriptionUrl}
                method="post"
                id="mc-embedded-subscribe-form"
                name="mc-embedded-subscribe-form"
                className="validate"
                target="_blank"
                noValidate={false}
                ref={formRef}
            >
                <SubscriptionContainer
                    id="mc_embed_signup_scroll"
                    className="d-flex justify-content-center m-0 w-100"
                    data-testid="newsletter-subscription"
                >
                    <ElementsContainer className="d-flex flex-column">
                        <InputContainer className="mc-field-group m-0 w-100">
                            <Input
                                type="text"
                                name="EMAIL"
                                className="form-control"
                                id="mce-EMAIL"
                                data-testid="input"
                                required={true}
                                placeholder="E-Mail Address..."
                                disabled={!isReady}
                            />
                        </InputContainer>
                        <CheckBoxContainer>
                            <Form.Check
                                onChange={(event) => setAgree(event.target.checked)}
                                disabled={!isReady}
                                data-testid="agree-checkbox"
                            />
                            <span className={`ml-0-5 agree-checkbox-label ${clsx([{ inactive: agree }])}`}>
                                I agree with the&nbsp;
                                <a href={routeNames.privacyPolicy} className="text-green" target="_blank" rel="noreferrer">
                                    Privacy Policy
                                </a>
                            </span>
                        </CheckBoxContainer>
                    </ElementsContainer>

                    <div style={{ position: "absolute", left: "-5000px" }} aria-hidden="true">
                        <input type="text" name="newsletter" tabIndex={-1} defaultValue="" />
                    </div>
                    <SubmitBtnContainer className="clear">
                        <EButton
                            type="submit"
                            name="subscribe"
                            id="mc-embedded-subscribe"
                            disabled={!agree}
                            loading={btnLoading}
                            testId="submit"
                            onClick={() => setBtnLoading(true)}
                        >
                            <FontAwesomeIcon icon={faEnvelope} size="sm" className="pr-xs" />
                            Join the Newsletter
                        </EButton>
                    </SubmitBtnContainer>
                </SubscriptionContainer>
            </SubscriptionForm>
        </SubscriptionContainer>
    );
};

export default NewsLetterSubscription;
