import React from "react";
import { Dimensions } from "./helpers/hex.types";

interface PatternProps {
    id: string;
    image: string;
    dimensions: Dimensions;
}

const Pattern = ({ id, image, dimensions: { width, height, xOffset, yOffset } }: PatternProps) => {
    return (
        <defs>
            <pattern id={id} patternUnits="objectBoundingBox" width={"100%"} height={"100%"}>
                <image x={xOffset} y={yOffset} xlinkHref={image} width={width} height={height} />
            </pattern>
        </defs>
    );
};
export default Pattern;
