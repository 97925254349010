import { Accordion, Col, Row } from "react-bootstrap";
import { EAccordionHeader } from "../../../../components/Accordion";
import React, { memo } from "react";
import styled from "styled-components";
import { BREAKPOINTS } from "../../../../assets/sass/breakpoints";
import { TroubleshootingItem } from "./KYCInstructions";
import KYCInfoCardContent from "./KYCInfoCardContent";
import { EAccordionHeaderButtonStyle, EAccordionItemNoFrost } from "../../../../components/Accordion/EAccordion";

const KYCAccordionHeader = styled(EAccordionHeader)`
    background-color: var(--entity-bg-darg-green);
    opacity: 1;

    .accordion-button {
        ${EAccordionHeaderButtonStyle};
        background-color: var(--entity-bg-darg-green);
    }
`;

const KYCAccordionBody = styled(Accordion.Body)`
    background: var(--entity-bg-darg-green) 0% 0% no-repeat padding-box;
`;

const InfoRow = styled(Row)`
    flex-direction: column;

    @media (${BREAKPOINTS.tablet}) {
        flex-direction: row;
    }
`;

const Item = styled(EAccordionItemNoFrost)`
    background-color: var(--entity-bg-darg-green);
    box-shadow: 5px 5px 15px #00000029;
`;

const KYCAccordionItem = ({ eventKey, item }: { eventKey: string; item: TroubleshootingItem }) => {
    return (
        <Item eventKey={eventKey}>
            <KYCAccordionHeader>{item.title}</KYCAccordionHeader>
            <KYCAccordionBody>
                <InfoRow>
                    <Col>
                        <KYCInfoCardContent header="Do" items={item.do} className="text-green" />
                    </Col>
                    <Col>
                        <KYCInfoCardContent header="Don't" items={item.dont} className="text-red" />
                    </Col>
                </InfoRow>
            </KYCAccordionBody>
        </Item>
    );
};

export default memo(KYCAccordionItem);
