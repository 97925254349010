import React from "react";
import { HexBackground } from "../../common";
import styled from "styled-components";
import { BREAKPOINTS } from "../../assets/sass/breakpoints";

const StyledHexBackground = styled(HexBackground)`
    position: absolute;
    left: 0;
    width: 100%;
    opacity: 0.8;
    z-index: -100;
    top: -3.6rem;

    @media (${BREAKPOINTS.tablet}) {
        top: 0.517rem;
    }

    .hex-container {
        position: absolute;
        top: 0;
        right: 0;
    }
`;

const HexDecor = () => (
    <StyledHexBackground
        hexProps={(width, height) => [
            {
                q: 1,
                r: 0,
                imageSize: { width, height: 1.4 * height, xOffset: -width / 10, yOffset: -height / 2.8 },
            },
        ]}
    />
);

export default HexDecor;
