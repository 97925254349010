import React from "react";
import styled from "styled-components";
import TwitterIcon from "../../../../assets/img/icons/twitter";
import TelegramIcon from "../../../../assets/img/icons/telegram";
import LinkedinIcon from "../../../../assets/img/icons/linkedin";
import DiscordIcon from "../../../../assets/img/icons/discord";

const StyledSocials = styled("div")`
    grid-column-gap: 2em;
    justify-content: center;
    display: flex;
    grid-area: span 1 / span 1 / span 1 / span 1;
    align-self: stretch;

    .nav-socials {
        display: flex;
    }

    .nav-socials-list {
        justify-content: space-between;
        align-items: stretch;
        display: flex;
    }

    @media screen and (max-width: 991px) {
        display: none;
    }
`;

const NewNavbarSocials = () => {
    return (
        <StyledSocials>
            <p className="nav-topbar-text">Social</p>
            <div className="nav-socials">
                <div role="list" className="nav-socials-list w-dyn-items">
                    <a href="https://discord.gg/AH5y6uhJXH" target="_blank" className="nav-social-link">
                        <DiscordIcon />
                    </a>
                    <a href="https://twitter.com/EntityFinance" target="_blank" className="nav-social-link">
                        <TwitterIcon />
                    </a>
                    <a href="https://t.me/EntityCommunity" target="_blank" className="nav-social-link">
                        <TelegramIcon width={28} height={28} />
                    </a>
                    <a href="https://www.linkedin.com/company/entity-finance/" target="_blank" className="nav-social-link">
                        <LinkedinIcon />
                    </a>
                </div>
            </div>
        </StyledSocials>
    );
};

export default NewNavbarSocials;
