import styled from "styled-components";

export interface HrLineProps {
    height?: number;
    marginTop?: boolean;
    marginTopMd?: boolean;
}

const HrLine = styled.hr`
    margin: 0 -2rem;
    color: var(--theme-page-background);
    opacity: 1;
    height: ${({ height }: HrLineProps): string => (height ? height + "px" : "1px")} !important;
    margin-top: ${({ marginTop, marginTopMd }: HrLineProps) => (marginTopMd ? "1.4rem" : marginTop ? "2.142rem" : "0")};
`;

export default HrLine;
