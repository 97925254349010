/* eslint-disable @typescript-eslint/no-explicit-any */
export class MemoizeService {
    private readonly cache: Map<string, any>;
    private readonly asyncCache: Map<string, any>;

    constructor() {
        this.cache = new Map<string, any>();
        this.asyncCache = new Map<string, any>();
    }

    memoize = (fn: (...args: any[]) => any, ...args: any[]): any => {
        const key = JSON.stringify(args);
        if (this.cache.has(key)) {
            return this.cache.get(key);
        }
        const computedValue = fn(...args);
        this.cache.set(key, computedValue);
        return computedValue;
    };

    memoizeAsync = async (fn: (...args: any[]) => Promise<any>, ...args: any[]): Promise<any> => {
        const key = JSON.stringify(args);
        if (this.asyncCache.has(key)) {
            return this.asyncCache.get(key);
        }
        const computedValue = await fn(...args);
        this.asyncCache.set(key, computedValue);
        return computedValue;
    };
}

export default MemoizeService;
