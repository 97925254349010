import React from "react";
import styled from "styled-components";
import { Spinner, SpinnerProps } from "react-bootstrap";

const Container = styled.div`
    &.centered {
        display: flex;
        justify-content: center;
        align-items: center;
    }
`;

const StyledSpinner = styled(Spinner)`
    color: var(--entity-green);
    padding: 2rem;
    margin: 2rem;
`;

const ESpinner = ({
    animation = "border",
    size,
    variant,
    centered = true,
    className = "",
}: Partial<SpinnerProps> & { centered?: boolean; className?: string }) => (
    <Container className={`${className} ${centered ? "centered" : ""}`} data-testid="spinner">
        <StyledSpinner animation={animation} size={size} variant={variant} />
    </Container>
);

export default ESpinner;
