import { getMenu } from "./footer.types";
import { Nav } from "react-bootstrap";
import { ConditionalWrapper, DateUtils } from "../../../common";
import OverlayTriggerTooltip from "../../../helpers/OverlayTriggerTooltip";
import { ReactComponent as Logo } from "../../../assets/img/entity-logo.svg";
import { Link } from "react-router-dom";
import { routeNames } from "../../../routes/routes";
import NewsLetterSubscription from "../../NewsLetterSubscription/NewsLetterSubscription";
import SocialMediaButtons from "../../Buttons/SocialMediaButtons";
import { entitySocialList } from "../../../helpers/consts";
import React, { useEffect, useState } from "react";
import { useService } from "../../../services/config/dependencyInjectorConfig";
import WalletsService from "../../../services/WalletsService";
import styled, { css } from "styled-components";
import Number from "../../Price/Number";
import { NavItem, NavLink } from "../Navbar/navbar.helper";
import preval from "preval.macro";

const buildDate = preval`module.exports = new Date().getTime()`;

const ContainerStyles = css`
    gap: 2rem;
    margin-left: 7%;
    margin-right: 7%;

    text-align: left;
    font: normal normal 300 1rem/1.429rem var(--entity-exo-font-family);
    letter-spacing: 0.02rem;
    color: var(--primary-text);
    opacity: 1;
`;

const FooterNavContainer = styled.div`
    --container-padding: 0.392rem;
    --element-margin: 2.857rem;
    margin-bottom: 3rem;
    ${ContainerStyles};

    .nav-link {
        max-width: 15rem;
        padding: var(--container-padding);
        padding-left: 1.429rem;
        margin-bottom: 0.5rem !important;

        &:last-child {
            margin-bottom: 0 !important;
        }

        &:hover {
            color: var(--primary-text) !important;
            background-color: #f8f9fa4d;
        }

        &.inactive {
            color: var(--inactive-menu-item-color) !important;
        }
    }

    .header {
        font: normal normal normal 1.714rem/2.143rem var(--entity-header-font-family);
        letter-spacing: 0.206px;
        color: #a3b1b4 !important;
        line-height: 1.643rem;
        padding-top: 1.143rem !important;
        padding-bottom: 0.2rem !important;
        margin-bottom: var(--element-margin) !important;
        pointer-events: none;
        cursor: default;
        padding-left: 1.3rem;
    }
`;

const EntityInfo = styled.div`
    span,
    div,
    a {
        margin-bottom: 1.857rem;
        color: var(--primary-text);
    }

    a {
        padding-top: var(--container-padding);
        padding-bottom: var(--container-padding);

        &:hover {
            color: var(--primary-text);
        }
    }
`;

const BuildInfo = styled.div`
    span,
    a {
        padding-top: var(--container-padding);
        padding-bottom: var(--container-padding);
        margin-top: 1.143rem;
        color: var(--entity-gray);
    }
`;

const Users = styled(Number)`
    display: block;
    font-size: inherit !important;

    span {
        font-size: inherit !important;
    }
`;

const FooterElementContainer = styled.div`
    ${ContainerStyles};
    margin-bottom: 8.714rem;
    display: flex;
    flex-direction: column;

    @media (min-width: 1450px) {
        flex-direction: row;
    }
`;

const LogoIcon = styled.div`
    width: 11.5rem;
    height: 3.929rem;
    margin-bottom: var(--element-margin);
`;

const getMenuItemProps = ({
    to,
    href,
}: Partial<NavItem & NavLink>): { href: string; target: string } | { as: React.ElementType; to: string } => {
    if (!!href) {
        return {
            href: href,
            target: "_blank",
        };
    }

    return { as: Link, to: to ?? routeNames.home };
};

const DesktopFooter = ({ className }: { className?: string }) => {
    const [totalWalletsNo, setTotalWalletsNo] = useState(0);

    const [walletsService] = useService(WalletsService);

    useEffect(() => {
        retrieveTotalNoOfWallets();
    }, []);

    const retrieveTotalNoOfWallets = async () => {
        const walletsNo = await walletsService.countAllWallets();
        setTotalWalletsNo(walletsNo);
    };
    return (
        <div id="desktop-footer" className={className}>
            <FooterNavContainer className="d-flex flex-wrap flex-column flex-md-row justify-content-between">
                {Object.entries(getMenu()).map(([header, items], key) => (
                    <Nav key={`nav-menu-${key}`} className="d-flex flex-column align-items-md-start align-items-center">
                        <Nav.Link className="header">{header}</Nav.Link>
                        {items.map(({ title, testId, to, href, isDeprecated }, index) => (
                            <ConditionalWrapper
                                key={`nav-menu-item-${index}`}
                                condition={!to && !href}
                                wrap={(children) => (
                                    <OverlayTriggerTooltip
                                        placement="bottom"
                                        tooltipText={isDeprecated ? "This module has been closed" : "Coming soon"}
                                    >
                                        {children}
                                    </OverlayTriggerTooltip>
                                )}
                            >
                                <Nav.Link
                                    className={!to && !href ? "inactive" : ""}
                                    {...getMenuItemProps({ to, href })}
                                    data-testid={`${testId}-menu-item`}
                                >
                                    {title}
                                </Nav.Link>
                            </ConditionalWrapper>
                        ))}
                    </Nav>
                ))}
                <div className="d-flex flex-column justify-content-between">
                    <EntityInfo className="d-flex flex-column align-items-md-start align-items-center">
                        <LogoIcon>
                            <Logo />
                        </LogoIcon>
                        <Link to={routeNames.contact}>Contact</Link>

                        <BuildInfo>
                            <span>
                                v{process.env.REACT_APP_VERSION} | Built on {DateUtils.toFormat(buildDate)}
                            </span>
                            <Users title="Users" value={totalWalletsNo} />
                        </BuildInfo>
                    </EntityInfo>
                </div>
            </FooterNavContainer>
            <FooterElementContainer>
                <div className="mx-auto">{location.pathname !== routeNames.newsletter && <NewsLetterSubscription />}</div>
                <div className="mx-auto">
                    <div>
                        <SocialMediaButtons buttons={entitySocialList} />
                    </div>
                </div>
            </FooterElementContainer>
        </div>
    );
};

export default DesktopFooter;
