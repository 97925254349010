import { Control, FormState, UseFormRegister } from "react-hook-form/dist/types/form";
import { IcoStepThreeInputs } from "../IcoStepThree";
import { FormControlGroup, FormSelectGroup } from "../../../common";
import React from "react";
import { IcoStepTwoInputs } from "../IcoStepTwo";
import { IcoLayers, LaunchPlatforms, LaunchPlatformsHelper } from "../../../helpers/consts";
import DateQuarterGroup from "../../../common/form/DateQuarterGroup";
import { QuarterSelectorType } from "./IcoEditStepTwo";
import { IcoStepOneInputs } from "../IcoStepOne";
import FormCheckGroup from "../../../common/form/FormCheckGroup";
import { Currency, CurrencySymbol } from "../../../common/utils/CurrencyUtils";
import TokenFilter from "../utils/TokenFilter";

const MAX_TOKEN_SUPPLY_TOOLTIP = "Set 0 for 'Uncapped' supply or 1 for 'TBA'.";

class IcoEditFieldsHelper<T> {
    register: UseFormRegister<T>;
    formState: FormState<T>;

    constructor(register: UseFormRegister<T>, formState: FormState<T>) {
        this.register = register;
        this.formState = formState;
    }
}

export class IcoEditFieldsStepOneHelper<T> extends IcoEditFieldsHelper<T> {
    ico: IcoStepOneInputs;

    constructor(register: UseFormRegister<T>, formState: FormState<T>, ico: IcoStepOneInputs) {
        super(register, formState);
        this.ico = ico;
    }

    getNameElement = (): JSX.Element => {
        return (
            <FormControlGroup
                defaultValue={this.ico.name}
                name="name"
                label="Project Name:"
                register={this.register}
                formState={this.formState}
                required={true}
            />
        );
    };
    getUsernameElement = (): JSX.Element => {
        return (
            <FormControlGroup
                defaultValue={this.ico.username}
                name="username"
                label="Your Name:"
                register={this.register}
                formState={this.formState}
                required={true}
            />
        );
    };
    getEmailElement = (): JSX.Element => {
        return (
            <FormControlGroup
                defaultValue={this.ico.email}
                name="email"
                label="Email:"
                register={this.register}
                formState={this.formState}
                required={true}
            />
        );
    };
    getPositionElement = (): JSX.Element => {
        return (
            <FormControlGroup
                defaultValue={this.ico.position}
                name="position"
                label="Position:"
                labelTooltipText="Your role in the company"
                register={this.register}
                formState={this.formState}
                required={true}
            />
        );
    };
    getCountryElement = (): JSX.Element => {
        return (
            <FormControlGroup
                defaultValue={this.ico.country}
                name="country"
                label="Country:"
                register={this.register}
                formState={this.formState}
            />
        );
    };
    getSloganElement = (): JSX.Element => {
        return (
            <FormControlGroup
                defaultValue={this.ico.slogan}
                name="slogan"
                label="Project Motto:"
                register={this.register}
                formState={this.formState}
            />
        );
    };
}

export class IcoEditFieldsStepTwoHelper<T> extends IcoEditFieldsHelper<T> {
    ico: IcoStepTwoInputs;
    control: Control;

    constructor(register: UseFormRegister<T>, formState: FormState<T>, control: Control, ico: IcoStepTwoInputs) {
        super(register, formState);
        this.control = control;
        this.ico = ico;
    }

    getIcoLayerElement = (): JSX.Element => {
        return (
            <FormSelectGroup
                defaultValue={this.ico.icoLayer || IcoLayers.dApp}
                name="icoLayer"
                label="Project Layer:"
                register={this.register}
                formState={this.formState}
                control={this.control}
            >
                <option selected={!this.ico.icoLayer || IcoLayers.dApp === this.ico.icoLayer} value={IcoLayers.dApp}>
                    dApp
                </option>
                <option selected={IcoLayers.Protocol === this.ico.icoLayer} value={IcoLayers.Protocol}>
                    Protocol
                </option>
                <option selected={IcoLayers.Platform === this.ico.icoLayer} value={IcoLayers.Platform}>
                    Platform
                </option>
            </FormSelectGroup>
        );
    };

    getTokenNameElement = (): JSX.Element => {
        return (
            <FormControlGroup
                defaultValue={this.ico.tokenName}
                name="tokenName"
                label="Token Name:"
                register={this.register}
                formState={this.formState}
                required={true}
            />
        );
    };

    getTokenElement = (): JSX.Element => {
        return (
            <TokenFilter
                ico={this.ico}
                name="token"
                label="Token:"
                register={this.register}
                control={this.control}
                formState={this.formState}
            />
        );
    };

    getInitialCirculatingSupplyElement = (): JSX.Element => {
        return (
            <FormControlGroup
                defaultValue={this.ico.initialCirculatingSupply}
                type="number"
                name="initialCirculatingSupply"
                label="Initial Token Supply:"
                register={this.register}
                formState={this.formState}
            />
        );
    };

    getMaxTokenSupplyElement = (): JSX.Element => {
        return (
            <FormControlGroup
                defaultValue={this.ico.maxTokenSupply}
                type="number"
                name="maxTokenSupply"
                label="Max Token Supply:"
                register={this.register}
                formState={this.formState}
                labelTooltipText={MAX_TOKEN_SUPPLY_TOOLTIP}
                required={true}
            />
        );
    };
    getPrivateSaleOfferedTokensElement = (): JSX.Element => {
        return (
            <FormControlGroup
                defaultValue={this.ico.privateSaleOfferedTokens}
                type="number"
                name="privateSaleOfferedTokens"
                label="Private Sale Offered Tokens:"
                register={this.register}
                formState={this.formState}
            />
        );
    };
    getPrivateSaleSoldTokensElement = (): JSX.Element => {
        return (
            <FormControlGroup
                defaultValue={this.ico.privateSaleSoldTokens}
                type="number"
                name="privateSaleSoldTokens"
                label="Private Sale Sold Tokens:"
                register={this.register}
                formState={this.formState}
            />
        );
    };
    getPrivateSalePriceElement = (): JSX.Element => {
        return (
            <FormControlGroup
                defaultValue={this.ico.privateSalePrice}
                type="number"
                name="privateSalePrice"
                step={0.0000001}
                label={`Private Sale Price in ${CurrencySymbol[Currency.USD]}:`}
                register={this.register}
                formState={this.formState}
            />
        );
    };
    getPublicSaleOfferedTokensElement = (): JSX.Element => {
        return (
            <FormControlGroup
                defaultValue={this.ico.publicSaleOfferedTokens}
                type="number"
                name="publicSaleOfferedTokens"
                label="Public Sale Offered Tokens:"
                register={this.register}
                formState={this.formState}
            />
        );
    };

    getSoftcapElement = (): JSX.Element => {
        return (
            <FormControlGroup
                defaultValue={this.ico.softcap}
                type="number"
                name="softcap"
                label="Softcap:"
                register={this.register}
                formState={this.formState}
            />
        );
    };

    getHardcapElement = (): JSX.Element => {
        return (
            <FormControlGroup
                defaultValue={this.ico.hardcap}
                type="number"
                name="hardcap"
                label="Hardcap:"
                register={this.register}
                formState={this.formState}
            />
        );
    };
    getPublicSalePriceElement = (): JSX.Element => {
        return (
            <FormControlGroup
                defaultValue={this.ico.publicSalePrice}
                type="number"
                name="publicSalePrice"
                label={`Public Sale Price in ${CurrencySymbol[Currency.USD]}:`}
                step={0.0000001}
                register={this.register}
                formState={this.formState}
            />
        );
    };
    getPreSaleDateElement = (
        usePreSaleDate: boolean,
        toggleShowQuarterSelector: (type: QuarterSelectorType) => void,
    ): JSX.Element => {
        return (
            <DateQuarterGroup
                datePickerName="preSaleDate"
                datePickerLabel="Presale Date Range:"
                quarterSelectorName="preSaleDate.quarterInfo"
                quarterSelectorLabel="Presale"
                value={this.ico.preSaleDate}
                register={this.register}
                control={this.control}
                formState={this.formState}
                showDatePicker={usePreSaleDate}
                switcherBtnClick={() => toggleShowQuarterSelector(QuarterSelectorType.PRE_SALE)}
                name="preSaleDateTimeQuarterGroup"
                label="preSaleDateTimeQuarterGroup"
            />
        );
    };
    getPublicSaleDateElement = (
        usePublicSaleDate: boolean,
        toggleShowQuarterSelector: (type: QuarterSelectorType) => void,
    ): JSX.Element => {
        return (
            <DateQuarterGroup
                datePickerName="publicSaleDate"
                datePickerLabel="Public Sale Date Range:"
                quarterSelectorName="publicSaleDate.quarterInfo"
                quarterSelectorLabel="Public sale"
                value={this.ico.publicSaleDate}
                register={this.register}
                control={this.control}
                formState={this.formState}
                showDatePicker={usePublicSaleDate}
                switcherBtnClick={() => toggleShowQuarterSelector(QuarterSelectorType.PUBLIC_SALE)}
                name="publicSaleDateTimeQuarterGroup"
                label="publicSaleDateTimeQuarterGroup"
            />
        );
    };

    getHasKycElement = (setHasKyc: (value: boolean) => void, displayCompact = true): JSX.Element => {
        return (
            <FormCheckGroup
                name="hasKyc"
                label="Has KYC"
                className={displayCompact ? "mt-44" : "mt-30"}
                register={this.register}
                formState={this.formState}
                onChange={setHasKyc}
            />
        );
    };

    getKycDateElement = (useKycDate: boolean, toggleShowQuarterSelector: (type: QuarterSelectorType) => void): JSX.Element => {
        return (
            <DateQuarterGroup
                datePickerName="kycDate"
                datePickerLabel="KYC Date Range:"
                quarterSelectorName="kycDate.quarterInfo"
                quarterSelectorLabel="KYC"
                value={this.ico.kycDate}
                register={this.register}
                control={this.control}
                formState={this.formState}
                showDatePicker={useKycDate}
                switcherBtnClick={() => toggleShowQuarterSelector(QuarterSelectorType.KYC)}
                name="kycDateTimeQuarterGroup"
                label="kycDateTimeQuarterGroup"
            />
        );
    };

    getTradableDateElement = (
        useTradableDate: boolean,
        toggleShowQuarterSelector: (type: QuarterSelectorType) => void,
    ): JSX.Element => {
        return (
            <DateQuarterGroup
                datePickerName="tradableDate"
                datePickerLabel="Listing Date:"
                quarterSelectorName="tradableDate.quarterInfo"
                quarterSelectorLabel="Listing"
                value={this.ico.tradableDate}
                register={this.register}
                control={this.control}
                className="flex-column w-100"
                formState={this.formState}
                showDatePicker={useTradableDate}
                switcherBtnClick={() => toggleShowQuarterSelector(QuarterSelectorType.TRADABLE)}
                name="tradableStartDateTimeQuarterGroup"
                label="tradableStartDateTimeQuarterGroup"
                range={false}
            />
        );
    };
    getLaunchPlatformElement = (): JSX.Element => {
        return (
            <FormSelectGroup
                defaultValue={this.ico.launchPlatform || LaunchPlatforms.Elrond}
                name="launchPlatform"
                label="Launch Platform:"
                register={this.register}
                formState={this.formState}
                control={this.control}
            >
                {Object.keys(LaunchPlatforms).map((key, index) => {
                    const launchPlatform = LaunchPlatforms[key as keyof typeof LaunchPlatforms];
                    return (
                        <option
                            selected={
                                launchPlatform === this.ico.launchPlatform ||
                                (!this.ico.launchPlatform && launchPlatform === LaunchPlatforms.Elrond)
                            }
                            value={launchPlatform}
                            key={index}
                            className="text-capitalize"
                        >
                            {LaunchPlatformsHelper.toLabel(launchPlatform)}
                        </option>
                    );
                })}
            </FormSelectGroup>
        );
    };
}

export class IcoEditFieldsStepThreeHelper<T> extends IcoEditFieldsHelper<T> {
    ico: IcoStepThreeInputs;

    constructor(register: UseFormRegister<T>, formState: FormState<T>, ico: IcoStepThreeInputs) {
        super(register, formState);
        this.ico = ico;
    }

    getLitepaperLinkElement = (): JSX.Element => {
        return (
            <FormControlGroup
                defaultValue={this.ico.links.litepaper}
                type="text"
                name="links.litepaper"
                label="Litepaper Link:"
                register={this.register}
                formState={this.formState}
            />
        );
    };

    getWebsiteLinkElement = (): JSX.Element => {
        return (
            <FormControlGroup
                defaultValue={this.ico.links.website}
                type="text"
                name="links.website"
                label="Website Link:"
                register={this.register}
                formState={this.formState}
                required={true}
            />
        );
    };

    getFacebookLinkElement = (): JSX.Element => {
        return (
            <FormControlGroup
                defaultValue={this.ico.links.facebook}
                type="text"
                name="links.facebook"
                label="Facebook:"
                register={this.register}
                formState={this.formState}
            />
        );
    };

    getTwitterLinkElement = (): JSX.Element => {
        return (
            <FormControlGroup
                defaultValue={this.ico.links.twitter}
                type="text"
                name="links.twitter"
                label="Twitter:"
                register={this.register}
                formState={this.formState}
                required={true}
            />
        );
    };

    getTelegramLinkElement = (): JSX.Element => {
        return (
            <FormControlGroup
                defaultValue={this.ico.links.telegram}
                type="text"
                name="links.telegram"
                label="Telegram:"
                register={this.register}
                formState={this.formState}
            />
        );
    };

    getDiscordLinkElement = (): JSX.Element => {
        return (
            <FormControlGroup
                defaultValue={this.ico.links.discord}
                type="text"
                name="links.discord"
                label="Discord:"
                register={this.register}
                formState={this.formState}
            />
        );
    };

    getGithubLinkElement = (): JSX.Element => {
        return (
            <FormControlGroup
                defaultValue={this.ico.links.github}
                type="text"
                name="links.github"
                label="Github:"
                register={this.register}
                formState={this.formState}
            />
        );
    };

    getMediumLinkElement = (): JSX.Element => {
        return (
            <FormControlGroup
                defaultValue={this.ico.links.medium}
                type="text"
                name="links.medium"
                label="Medium:"
                register={this.register}
                formState={this.formState}
            />
        );
    };

    getWhiteListLinkElement = (): JSX.Element => {
        return (
            <FormControlGroup
                defaultValue={this.ico.links.whitelist}
                type="text"
                name="links.whitelist"
                label="Whitelist:"
                register={this.register}
                formState={this.formState}
            />
        );
    };

    getWhitePaperLinkElement = (): JSX.Element => {
        return (
            <FormControlGroup
                defaultValue={this.ico.links.whitepaper}
                type="text"
                name="links.whitepaper"
                label="Whitepaper:"
                register={this.register}
                formState={this.formState}
            />
        );
    };

    getTokenomicsLinkElement = (): JSX.Element => {
        return (
            <FormControlGroup
                defaultValue={this.ico.links.tokenomics}
                type="text"
                name="links.tokenomics"
                label="Tokenomics:"
                register={this.register}
                formState={this.formState}
            />
        );
    };
}
