import { LaunchpadDetailedInfo, ProjectGeneralDetails } from "../launchpad.types";
import { TimelineEventProps } from "../../../components/Timeline/Timeline";
import { DateUtils } from "../../../common";

export const getNextTab = (currentTab: LaunchpadDetailedInfo): LaunchpadDetailedInfo => {
    switch (currentTab) {
        case LaunchpadDetailedInfo.metrics:
            return LaunchpadDetailedInfo.team;
        case LaunchpadDetailedInfo.team:
            return LaunchpadDetailedInfo.investment;
        case LaunchpadDetailedInfo.investment:
            return LaunchpadDetailedInfo.about;
        case LaunchpadDetailedInfo.about:
        default:
            return LaunchpadDetailedInfo.metrics;
    }
};

export const prepareTimelineEvents = (
    events: ProjectGeneralDetails["launchRoadmap"],
    includeOnlyHeadlines = true,
): TimelineEventProps[] => {
    if (!events) return [];
    return events.map(({ plan, isChecked, startDate }) => {
        const subEvents = includeOnlyHeadlines ? plan.map((p) => p.headline) : plan.flatMap((p) => p.descriptions);
        return {
            header: startDate?.start
                ? DateUtils.timestampToDateFormattedMillis(startDate.start)
                : startDate?.quarterInfo
                ? startDate.quarterInfo.quarter + " " + startDate.quarterInfo.year
                : "",
            isChecked,
            subEvents,
        };
    });
};
