/* eslint-disable @typescript-eslint/no-explicit-any */
import { Form, FormText } from "react-bootstrap";
import React from "react";
import { FormCheckType } from "react-bootstrap/FormCheck";
import { FormGroupProps } from "./types";
import EFormLabel from "./EFormLabel";
import clsx from "clsx";

export interface FormCheckGroupProps<T> extends Omit<FormGroupProps<T>, "label"> {
    type?: FormCheckType;
    defaultChecked?: boolean;
    label: string | JSX.Element;
    labelClass?: string;
    displayFlexGroup?: boolean;
    onChange?: (value: boolean) => void;
}

function FormCheckGroup<T>({
    name,
    label,
    type = "checkbox",
    disabled = false,
    defaultChecked = undefined,
    className = undefined,
    labelClass = undefined,
    displayFlexGroup = false,
    register,
    registerOptions,
    formState: { errors },
    onChange,
}: FormCheckGroupProps<T>): JSX.Element {
    return (
        <Form.Group controlId={`checkGroup-${name}`} className={clsx([{ "d-flex": displayFlexGroup }])}>
            <div className="d-flex align-items-baseline">
                <Form.Check
                    type={type}
                    {...register(name, {
                        ...registerOptions,
                    })}
                    disabled={disabled}
                    defaultChecked={defaultChecked}
                    className={className}
                    id={name}
                    onChange={(event) => {
                        if (onChange) {
                            onChange(event.target.checked);
                        }
                    }}
                />
                <EFormLabel htmlFor={name} className={`ml-min mr-min ${labelClass ?? ""}`}>
                    {label}
                </EFormLabel>
            </div>
            {errors && errors[name]?.type && (
                <FormText className="form-error d-contents">{errors[name]?.message as string}</FormText>
            )}
        </Form.Group>
    );
}

export default FormCheckGroup;
