import React, { FC } from "react";
import Title from "../components/Title";
import styled from "styled-components";

const Container = styled.div`
    padding-top: 1.2rem;
    padding-bottom: 2.142rem;
`;

interface ContentContainer {
    title: string;
}

const ContentContainer: FC<React.PropsWithChildren<ContentContainer>> = ({ title, children }) => {
    return (
        <Container>
            <Title title={title} />
            <div onClick={(e) => e.stopPropagation()}>{children}</div>
        </Container>
    );
};

export default ContentContainer;
