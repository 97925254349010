import React, { useRef, useState } from "react";
import { Card, Col, Form, Row, Image } from "react-bootstrap";
import { EButton } from "../../components/Buttons";
import { FormControlGroup, useYupValidationResolver } from "../../common";
import { useForm } from "react-hook-form";
import * as yup from "yup";
import ReCAPTCHA from "react-google-recaptcha";
import { recaptchaKey } from "../../config";
import ContactService from "../../services/ContactService";
import { useService } from "../../services/config/dependencyInjectorConfig";
import AlertService from "../../services/AlertService";
import { PageHeader } from "../../components/PageHeader";
import { NO_WHITE_SPACE } from "../../common/utils/RegexUtils";
import ValidationSchemaUtils, { minLengthErrorMsg, REQUIRED_MSG } from "../../common/utils/ValidationSchemaUtils";
import MetaDecorator from "../../common/components/MetaDecorator";
import { seoData } from "../../helpers/consts/SEO";
import { routeNames } from "../../routes/routes";
import styled from "styled-components";
import { Cloud } from "../../components/Cloud";
import errorToast from "../../components/Error/errorToast";
import { ESpinner } from "../../components/Spinner";
import { useMutation } from "@tanstack/react-query";
import hexagonBg from "../../assets/img/hexagons.svg";

const TopContainer = styled.div`
    --cloud-color: rgba(174, 116, 255, 0.31);
    position: absolute;
    top: 3.517rem;
    left: 0;
    width: 100%;

    .hex-container {
        position: relative;
        z-index: -1;

        img {
            right: -5rem;
        }
    }
`;

const FirstCloud = styled(Cloud)`
    top: 15rem;
    left: -4rem;
`;

const RightCloud = styled(Cloud)`
    right: -4rem;
    top: 30rem;
`;

export interface EmailContact {
    firstName: string;
    lastName: string;
    companyName?: string;
    telephone?: string;
    email: string;
    request: string;
    captchaToken?: string;
}

const emailContactInitial: EmailContact = {
    firstName: "",
    lastName: "",
    email: "",
    request: "",
    captchaToken: "",
};

const whiteSpaceErrorMsg = "Please remove all whitespace from the beginning or the end.";

const getContactValidationSchema = () => {
    return yup.object({
        firstName: yup.string().matches(NO_WHITE_SPACE, whiteSpaceErrorMsg).required(REQUIRED_MSG).min(3, minLengthErrorMsg()),
        lastName: yup.string().matches(NO_WHITE_SPACE, whiteSpaceErrorMsg).required(REQUIRED_MSG).min(3, minLengthErrorMsg()),
        request: yup.string().matches(NO_WHITE_SPACE, whiteSpaceErrorMsg).required(REQUIRED_MSG).min(3, minLengthErrorMsg()),
        email: ValidationSchemaUtils.email,
    });
};

const Contact = () => {
    const [contact] = useState<EmailContact>(emailContactInitial);
    const validationResolver = useYupValidationResolver(getContactValidationSchema());
    const { register, handleSubmit, formState, reset } = useForm<EmailContact>({ resolver: validationResolver });

    const [contactService] = useService(ContactService);

    const reRef = useRef<ReCAPTCHA>(null);

    const sendEmailMutation = useMutation({
        mutationFn: (email: EmailContact) => contactService.sendEmailContact(email),
        onSuccess: () => {
            AlertService.success("Thank you for contacting us, we will get back to you as soon as possible!");
            reset();
        },
        onSettled: () => {
            reRef.current?.reset();
        },
    });

    const onContactChangeHandler = async (input: EmailContact) => {
        const captchaToken = await reRef.current?.executeAsync();

        if (captchaToken == "" || !captchaToken) {
            errorToast("Invalid captcha");
            return;
        }
        input.captchaToken = captchaToken;
        sendEmailMutation.mutate(input);
    };

    return (
        <div className="container py-2 py-md-5">
            <MetaDecorator
                title={seoData.contact.title}
                description={seoData.contact.description}
                keywords={seoData.contact.keywords}
                canonical={routeNames.contact}
            />
            <TopContainer className="py-2 py-md-5">
                <div className="hex-container">
                    <Image fluid src={hexagonBg} />
                </div>
                <FirstCloud color="var(--cloud-color)" />
                <RightCloud color="var(--cloud-color)" />
            </TopContainer>
            <Row>
                <Col>
                    <PageHeader className="mb-3">Get in touch with Entity</PageHeader>
                    <p className="page-description">Please send your inquiry to info@entity.global or use the form below.</p>
                </Col>
            </Row>

            <ReCAPTCHA ref={reRef} sitekey={recaptchaKey.siteKey ?? ""} size="invisible" />

            <Row className="mt-5">
                <Col md={12}>
                    {sendEmailMutation.isLoading ? (
                        <ESpinner />
                    ) : (
                        <Card className="entityCard">
                            <Card.Body>
                                <Form onSubmit={handleSubmit(onContactChangeHandler)}>
                                    <Row>
                                        <Col md={6}>
                                            <Row className="mt-2">
                                                <Col xs={12} md={6}>
                                                    <FormControlGroup
                                                        defaultValue={contact.firstName}
                                                        name="firstName"
                                                        label="First Name:"
                                                        register={register}
                                                        formState={formState}
                                                    />
                                                </Col>
                                                <Col xs={12} md={6}>
                                                    <FormControlGroup
                                                        defaultValue={contact.lastName}
                                                        name="lastName"
                                                        label="Last Name:"
                                                        register={register}
                                                        formState={formState}
                                                    />
                                                </Col>
                                            </Row>
                                            <Row className="mt-2">
                                                <Col xs={12} md={12}>
                                                    <FormControlGroup
                                                        defaultValue={contact.companyName}
                                                        name="companyName"
                                                        label="Company Name:"
                                                        register={register}
                                                        formState={formState}
                                                    />
                                                </Col>
                                            </Row>
                                            <Row className="mt-2">
                                                <Col xs={12} md={12}>
                                                    <FormControlGroup
                                                        defaultValue={contact.telephone}
                                                        name="telephone"
                                                        label="Telephone:"
                                                        register={register}
                                                        formState={formState}
                                                    />
                                                </Col>
                                            </Row>
                                            <Row className="mt-2">
                                                <Col xs={12} md={12}>
                                                    <FormControlGroup
                                                        defaultValue={contact.email}
                                                        name="email"
                                                        label="Email:"
                                                        register={register}
                                                        formState={formState}
                                                    />
                                                </Col>
                                            </Row>
                                        </Col>
                                        <Col md={6}>
                                            <FormControlGroup
                                                defaultValue={contact.request}
                                                name="request"
                                                className={"form-control"}
                                                label="Your Request:"
                                                register={register}
                                                formState={formState}
                                                rows={14}
                                                as="textarea"
                                            />
                                        </Col>
                                    </Row>
                                    <Row className="mt-2 mt-mb-5 pb-4">
                                        <Col md={6}>
                                            <div className="contact-footer-text">
                                                Your data will only be used to contact you and will not be shared with third
                                                parties.{" "}
                                            </div>
                                        </Col>
                                        <Col md={6}>
                                            <EButton className="mt-1 mt-md-1" type="submit" testId="send-email">
                                                SEND EMAIL
                                            </EButton>
                                        </Col>
                                    </Row>
                                </Form>
                            </Card.Body>
                        </Card>
                    )}
                </Col>
            </Row>
        </div>
    );
};

export default Contact;
