import React from "react";
import { EPlaceholder } from "../Placeholder";

type Name = { name: string };

const Title = ({ name }: Partial<Name>) => {
    if (!name) {
        return <EPlaceholder size="lg" />;
    }
    return (
        <div className="lp-title mt-4" data-testid="name">
            {name}
        </div>
    );
};

export default Title;
