import { useCallback } from "react";
import { useContext } from "context";
import { useGetLoginInfo } from "@multiversx/sdk-dapp/hooks";
import AuthService from "services/AuthService";
import { useService } from "services/config/dependencyInjectorConfig";

export const useGetUserInfo = () => {
    const { userSettings } = useContext();
    const { isLoggedIn } = useGetLoginInfo();
    const [authService] = useService(AuthService);

    const isAuthAsAdmin = useCallback(() => isLoggedIn && authService.isAdmin(userSettings), [isLoggedIn, userSettings]);

    const isAuthAsLaunchpadAdmin = useCallback(
        () => isLoggedIn && authService.isLaunchpadAdmin(userSettings),
        [isLoggedIn, userSettings],
    );

    const isAuthAsModeratorOrAdmin = useCallback(
        () => isLoggedIn && authService.isModeratorOrAdmin(userSettings),
        [isLoggedIn, userSettings],
    );

    return { isLoggedIn, isAuthAsAdmin, isAuthAsLaunchpadAdmin, isAuthAsModeratorOrAdmin };
};

export default useGetUserInfo;
