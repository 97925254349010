import BaseService from "./BaseService";
import axios from "axios";
import { PaginationResp } from "../common/types/common.types";

export enum LdmTokenScope {
    BUY = "BUY",
    SELL = "SELL",
}

export interface LdmToken {
    id: number;
    name: string;
    identifier: string;
    scopes: LdmTokenScope[];
}

export interface LdmTokenPayload extends Pick<LdmToken, "name" | "identifier"> {
    scope: LdmTokenScope;
}

export enum LdmTokenFilter {
    ALL = "ALL",
    BUY = "BUY",
    SELL = "SELL",
}

export interface LdmSaleOffer {
    creator: string;
    externalId?: number;
    createTimestamp: number;
    sellToken: string;
    sellTokenPrice: number;
    sellAmount: number;
    receiveToken: string;
    receiveTokenPrice: number;
    receiveAmount: number;
    ratio: number;
    offerPrice: number;
    discount: number;
    lockPeriodDays: number;
}

export interface LdmSale extends LdmSaleOffer {
    id: string;
    status: LdmSaleStatus;
}

export enum LdmSaleStatus {
    PENDING = "PENDING",
    APPROVED = "APPROVED",
    DENIED = "DENIED",
    PAUSED = "PAUSED",
    ENDED = "ENDED",
    FORCED_STOPPED = "FORCED_STOPPED",
}

export interface LdmUpdatedSaleStatus {
    externalId: string;
    newStatus: LdmSaleStatus;
}

export class LdmService extends BaseService {
    private readonly _baseApi: string;

    constructor(entityApi: string) {
        super();
        this._baseApi = entityApi + "/ldm";
    }

    async retrieveTokens({
        from,
        size,
        filter,
    }: {
        from?: number;
        size?: number;
        filter?: LdmTokenFilter;
    }): Promise<PaginationResp<LdmToken>> {
        return this.doCall(() => axios.get(`${this._baseApi}/token`, { params: { from, size, filter } }));
    }

    async addToken(tokenPayload: LdmTokenPayload): Promise<LdmToken> {
        return this.doCall(() => axios.post(`${this._baseApi}/token`, tokenPayload));
    }

    async removeTokenScope(identifier: string, scope: LdmTokenScope): Promise<LdmToken> {
        return this.doCall(() => axios.delete(`${this._baseApi}/token`, { params: { identifier, scope } }));
    }

    registerSaleOffer(offer: LdmSaleOffer): Promise<void> {
        return this.doCall(() => axios.post(`${this._baseApi}/offer/register`, offer));
    }

    updateSaleStatus(saleStatus: LdmUpdatedSaleStatus): Promise<void> {
        return this.doCall(() => axios.post(`${this._baseApi}/sale/status`, saleStatus));
    }

    getTerminatedSales(): Promise<LdmSale[]> {
        return this.doCall(() => axios.get(`${this._baseApi}/sales/terminated`));
    }

    getActiveSales(): Promise<LdmSale[]> {
        return this.doCall(() => axios.get(`${this._baseApi}/sales/active`));
    }
}

export default LdmService;
