import Swal, { SweetAlertIcon, SweetAlertOptions, SweetAlertResult } from "sweetalert2";
import { warnColor } from "../components/Buttons/Button";

export type ModalFunction = (text: string, title?: string) => Promise<SweetAlertResult>;

const DEFAULT_TIMER = 10_000;

class AlertService {
    static SwalStyled = Swal.mixin({
        background: "var(--page-block-background-color)",
        color: "white",
        width: "4:#173e49",
        confirmButtonColor: "#4DC2F933",
        cancelButtonColor: warnColor,
    });

    public static successToast(text: string, timer = DEFAULT_TIMER): void {
        AlertService.toast(text, "Success", "success", timer);
    }

    public static errorToast(text: string, timer = DEFAULT_TIMER): void {
        AlertService.toast(text, "Error", "error", timer);
    }

    public static infoToast(text: string, timer = DEFAULT_TIMER): void {
        AlertService.toast(text, "Info", "info", timer);
    }

    public static toast(text: string, title: string, icon: SweetAlertIcon, timer = DEFAULT_TIMER): void {
        AlertService.SwalStyled.fire({
            position: "top-right",
            title: title,
            text,
            icon: icon,
            showConfirmButton: false,
            timer: timer,
            toast: true,
        });
    }

    public static error(text: string, title = "Error"): Promise<SweetAlertResult> {
        return AlertService.SwalStyled.fire({
            title,
            text,
            icon: "error",
            showCancelButton: false,
            confirmButtonText: "Ok",
        });
    }

    public static success(text: string, title = "Success"): Promise<SweetAlertResult> {
        return AlertService.SwalStyled.fire({
            title,
            text,
            icon: "success",
            showCancelButton: false,
            confirmButtonText: "Ok",
        });
    }

    public static info(text: string, title = "Success"): Promise<SweetAlertResult> {
        return AlertService.SwalStyled.fire({
            title,
            text,
            icon: "info",
            showCancelButton: false,
            confirmButtonText: "Ok",
        });
    }

    public static delete(title: string, text: string, buttonAction = "Delete"): Promise<SweetAlertResult> {
        return AlertService.SwalStyled.fire({
            title,
            text,
            icon: "warning",
            confirmButtonColor: "#fe7777",
            showCancelButton: true,
            confirmButtonText: buttonAction,
        });
    }

    public static warning(text: string, options?: SweetAlertOptions): Promise<SweetAlertResult> {
        return AlertService.SwalStyled.fire({
            title: "Warning",
            text,
            icon: "warning",
            showCancelButton: true,
            confirmButtonText: "Got it",
            ...options,
        });
    }
}

export default AlertService;
