import React from "react";
import styled from "styled-components";
import { BREAKPOINTS } from "../../assets/sass/breakpoints";

const Container = styled.div`
    color: white;
    font-weight: 300;
    text-align: center;
    font-size: 1.357rem;

    @media (${BREAKPOINTS.tablet}) {
        text-align: left;
        font-size: 2.285rem;
        line-height: 2.285rem;
        margin-bottom: 2.285rem;
    }
`;

const SubTitle = ({ text = "" }) => {
    return <Container>{text}</Container>;
};

export default SubTitle;
