import { Ico } from "../../common/types/icoTypes";
import { KeyMetrics } from "./ProjectKeyMetricsCard";
import { IcoLayersHelper, TBA } from "../../helpers/consts";
import { DateUtils } from "../../common";
import React from "react";
import { GeneralKeyMetrics } from "../../pages/Launchpad/launchpad.types";
import TotalSupply from "../../pages/Ico/TotalSupply";
import CurrencyNumber from "../Price/CurrencyNumber";

enum KeyMetricsTitles {
    category = "Category",
    token = "Token",
    tokenTotalSupply = "Total Supply",
    tokenInitialSupply = "Initial Supply",
    cap = "Market Cap",
    presaleDate = "Seed Sale Date",
    publicSaleDate = "Public Sale Start",
    publicSaleTokenPrice = "Public Sale Token Price",
    kyc = "KYC",
    raisedInPublicSale = "Raised in Public Sale",
    softCap = "Soft Cap",
    hardCap = "Hard Cap",
}

const DEFAULT_VALUE = TBA;
const NO_VALUE = "No";

export const generateKeyMetricsFromGeneralKeyMetrics = (metrics: GeneralKeyMetrics): KeyMetrics[] => {
    const res = [
        {
            title: KeyMetricsTitles.category,
            value: metrics.category ? IcoLayersHelper.toLabel(metrics.category) : DEFAULT_VALUE,
        },
        {
            title: KeyMetricsTitles.token,
            value: metrics.tokenName || DEFAULT_VALUE,
        },
        {
            title: KeyMetricsTitles.tokenTotalSupply,
            value: "uncapped",
        },
        {
            title: KeyMetricsTitles.tokenInitialSupply,
            value: <TotalSupply maxTokenSupply={metrics.maxTokenSupply} />,
        },
        {
            title: KeyMetricsTitles.presaleDate,
            value: metrics.preSaleStartQuarter
                ? metrics.preSaleStartQuarter
                : metrics.preSaleStartTimestamp
                ? DateUtils.toDateLabel(metrics.preSaleStartTimestamp)
                : DEFAULT_VALUE,
        },
        {
            title: KeyMetricsTitles.publicSaleDate,
            value: metrics.publicSaleStartQuarter
                ? metrics.publicSaleStartQuarter
                : metrics.publicSaleStartTimestamp
                ? DateUtils.toDateLabel(metrics.publicSaleStartTimestamp)
                : DEFAULT_VALUE,
        },
        {
            title: KeyMetricsTitles.publicSaleTokenPrice,
            value: metrics.publicSaleTokenPrice ? <CurrencyNumber value={metrics.publicSaleTokenPrice || 0} /> : DEFAULT_VALUE,
        },
        {
            title: KeyMetricsTitles.kyc,
            value: !metrics.hasKyc
                ? NO_VALUE
                : metrics.kycStartQuarter
                ? metrics.kycStartQuarter
                : metrics.kycStartTimestamp
                ? DateUtils.toDateLabelWithMillis(metrics.kycStartTimestamp)
                : TBA,
        },
    ];
    if (metrics.raisedInPublicSale) {
        res.push({
            title: KeyMetricsTitles.raisedInPublicSale,
            value: <CurrencyNumber value={metrics.raisedInPublicSale} />,
        });
    }

    if (metrics.softcap && metrics.softcap !== metrics.hardcap) {
        res.push({
            title: KeyMetricsTitles.softCap,
            value: <CurrencyNumber value={metrics.softcap ? (metrics.softcap === 0 ? "TBA" : metrics.softcap) : 0} />,
        });
    }

    res.push({
        title: KeyMetricsTitles.hardCap,
        value: <CurrencyNumber value={metrics.hardcap ? (metrics.hardcap === 0 ? "TBA" : metrics.hardcap) : 0} />,
    });

    return res;
};

export const generateKeyMetricsFromIco = (ico: Ico): KeyMetrics[] => {
    return [
        {
            title: KeyMetricsTitles.category,
            value: ico.icoLayer ? IcoLayersHelper.toLabel(ico.icoLayer) : DEFAULT_VALUE,
        },
        {
            title: KeyMetricsTitles.token,
            value: ico.tokenName || DEFAULT_VALUE,
        },
        {
            title: KeyMetricsTitles.tokenTotalSupply,
            value: <TotalSupply maxTokenSupply={ico.maxTokenSupply} />,
        },
        {
            title: KeyMetricsTitles.presaleDate,
            value: ico.preSaleStartQuarter
                ? ico.preSaleStartQuarter
                : ico.preSaleStartTimestamp
                ? DateUtils.toDateLabel(ico.preSaleStartTimestamp)
                : DEFAULT_VALUE,
        },
        {
            title: KeyMetricsTitles.publicSaleDate,
            value: ico.publicSaleStartQuarter
                ? ico.publicSaleStartQuarter
                : ico.publicSaleStartTimestamp
                ? DateUtils.toDateLabel(ico.publicSaleStartTimestamp)
                : DEFAULT_VALUE,
        },
        {
            title: KeyMetricsTitles.kyc,
            value: !ico.hasKyc
                ? NO_VALUE
                : ico.kycStartQuarter
                ? ico.kycStartQuarter
                : ico.kycStartTimestamp
                ? DateUtils.toDateLabel(ico.kycStartTimestamp)
                : TBA,
        },
        {
            title: KeyMetricsTitles.publicSaleTokenPrice,
            value: ico.publicSalePrice ? <CurrencyNumber value={ico?.publicSalePrice || 0} /> : DEFAULT_VALUE,
        },
    ];
};
