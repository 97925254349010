import { AxiosError, AxiosRequestConfig } from "axios";
import { entityApi, launchpadApi } from "../../config";
import { ContextDispatch } from "../../context";
import SessionStorageService from "../sessionStorageService/SessionStorageService";
import { SessionStorageKeys } from "../sessionStorageService/sessionStorageKeys";
import LocalStorageService from "../localStorageService/LocalStorageService";
import { LocalStorageKeys } from "../localStorageService/LocalStorageKeys";

export const authorizationRequestInterceptor = (request: AxiosRequestConfig): AxiosRequestConfig => {
    // TODO update the function in order to set the headers only on a specific wanted requests
    // TODO Define a list with the endpoints in the config file that do not require authentication and for those which
    // TODO are not in this list attach the token
    if (
        (request.url?.includes(entityApi) || request.url?.includes(launchpadApi)) &&
        LocalStorageService.getItem(LocalStorageKeys.entityUserAuthToken)
    ) {
        request.headers.common.Authorization = `Bearer ${LocalStorageService.getItem(LocalStorageKeys.entityUserAuthToken)}`;
    }
    return request;
};

export const rejectResponseInterceptor = (
    error: AxiosError,
    dispatch: ContextDispatch,
    isLoggedIn: boolean,
): Promise<AxiosError> => {
    if (isLoggedIn && isAccessForbiddenByEntity(error)) {
        const logOutReason = {
            type: "error",
            text: "Forbidden access",
        };

        SessionStorageService.setItem(SessionStorageKeys.logoutReason, logOutReason);
    }
    return Promise.reject(error);
};

function isAccessForbiddenByEntity(error: AxiosError) {
    return error.config.url?.includes(entityApi) && (error.response?.status === 401 || error.response?.status === 403);
}
