import React, { FC } from "react";
import styled from "styled-components";
import EIcon from "../../../components/common/EIcon";
import { faAnglesLeft } from "@fortawesome/free-solid-svg-icons";

interface HoverCellProps {
    hoverText?: string;
    showFlipIcon?: boolean;
    className?: string;
}

const animationTime = 0.3;

const StyledCell = styled.div`
    width: 100%;
    height: 100%;
    position: absolute;
    display: flex;
    align-items: center;
    transition: background-color ${animationTime}s ease-out;
    margin-left: -0.8rem !important;
    padding: inherit !important;

    :hover {
        .show {
            width: 100%;
            left: 0;

            > span {
                transition: visibility 0s ${animationTime}s ease-out;
                visibility: visible;
            }
        }

        .not-show {
            transition: visibility ${animationTime}s ease-out;
            visibility: hidden;
        }
    }
`;

const StyledHoverElement = styled.div`
    position: absolute;
    bottom: 0;
    right: 0;
    width: 0;
    height: 100%;
    display: flex;
    justify-content: space-between;

    span {
        padding-left: 0.8rem !important;
        visibility: hidden;
        color: white;
        align-self: center;
    }
`;

const Slide = styled.div`
    width: 0;
    right: 0;
    left: 100%;
    bottom: 0;
    transition: ${animationTime}s ease-out;
    background-color: #000;
`;

const Icon = styled(EIcon)`
    transform: rotateY(0deg) rotate(320deg) scaleX(0.7) scaleY(0.7);
`;

const HoverElement = ({ hoverText, className }: Pick<HoverCellProps, "hoverText" | "className">) => (
    <StyledHoverElement className={className}>
        <span>{hoverText}</span>
        <Slide />
    </StyledHoverElement>
);

const HoverCell: FC<React.PropsWithChildren<HoverCellProps>> = ({ hoverText, showFlipIcon = false, children }) => {
    return (
        <StyledCell>
            {hoverText && <HoverElement className="show" hoverText={hoverText} />}
            <div className="not-show d-flex justify-content-between w-100 h-100">
                {children}
                {showFlipIcon && <Icon icon={faAnglesLeft} />}
            </div>
        </StyledCell>
    );
};

export default HoverCell;
