import { entityApi } from "../config";
import { ContextDispatch } from "../context";
import { logout } from "@multiversx/sdk-dapp/utils";
import { initUserSettings } from "../context/state";
import { routeNames } from "../routes/routes";
import LocalStorageService from "../services/localStorageService/LocalStorageService";
import { LocalStorageKeys } from "../services/localStorageService/LocalStorageKeys";
import SessionStorageService from "../services/sessionStorageService/SessionStorageService";
import { SessionStorageKeys } from "../services/sessionStorageService/sessionStorageKeys";
import { AppSetting } from "../pages/Admin/AppSettings/AppSettingsTypes";
import _ from "lodash";

export const middleStringEllipsis = (val: string, size = 5): string => {
    return val ? val.substring(0, size) + " ... " + val.slice(-size) : val;
};

export const getDateFromTimestamp = (timestamp: number): string | null => {
    if (timestamp) {
        return new Date(timestamp * 1000).toLocaleString();
    }

    return null;
};

export const getCurrentUnixTimestamp = (): number => {
    return Date.now() / 1_000;
};

export const getCurrentTimestampMillis = (): number => {
    return new Date().getTime();
};

export const downloadCsvFile = (fileName: string): void => {
    const link = document.createElement("a");
    link.href = entityApi + "/files/csv/" + fileName;
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
};

export const downloadCsvString = (csv: string, fileName: string): void => {
    const blob = new Blob([csv], { type: "text/csv" });

    const a = document.createElement("a");
    a.download = fileName;
    a.href = window.URL.createObjectURL(blob);
    const clickEvt = new MouseEvent("click", {
        view: window,
        bubbles: true,
        cancelable: true,
    });
    a.dispatchEvent(clickEvt);
    a.remove();
};

export const clearSessionAndContextForLogout = (dispatch: ContextDispatch): void => {
    clearSessionForLogout();
    dispatch({
        type: "setAuthenticationData",
        jwtToken: "",
        userSettings: initUserSettings,
    });
};

const clearSessionForLogout = (): void => {
    LocalStorageService.removeItem(LocalStorageKeys.settingsWallets);
    LocalStorageService.removeItem(LocalStorageKeys.entityUserAuthToken);
    SessionStorageService.removeItem(SessionStorageKeys.ico);
    LocalStorageService.removeItem(LocalStorageKeys.persistSdk);
};

export const performLogout = (dispatch: ContextDispatch, redirectRoute?: string | undefined): void => {
    clearSessionAndContextForLogout(dispatch);
    const callbackUrl =
        window.location.origin +
        (redirectRoute !== undefined ? (redirectRoute.startsWith("/") ? redirectRoute : "/" + redirectRoute) : routeNames.unlock);
    logout(callbackUrl);
};

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export const getAppSettingByAppSettingName = (appSettingName: string, appSettings: AppSetting[]): any => {
    if (!appSettingName || !appSettings) {
        return undefined;
    }

    const appSetting = appSettings.find((setting) => setting.name === appSettingName);
    return appSetting?.setting;
};

export function isAnyNotEmpty(...values: unknown[]): boolean {
    for (const val of values) {
        if (!_.isEmpty(val)) return true;
    }
    return false;
}
