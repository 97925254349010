import React, { FC } from "react";
import styled from "styled-components";
import { LaunchPlatforms } from "../../helpers/consts";

interface ProjectLabelProps {
    platform: LaunchPlatforms;
    label?: string;
    bgColor?: string;
}

const LabelBg = styled.div`
    border-top-left-radius: 0.714rem;
    width: 8.571rem;
    height: 8.571rem;
    clip-path: polygon(0 0, 0% 100%, 100% 0);
    --webkit-clip-path: polygon(0 0, 0% 100%, 100% 0);
    margin-left: -0.143rem;
    margin-top: -0.143rem;
    background-color: ${(props: Required<Pick<ProjectLabelProps, "bgColor">>): string => props.bgColor};
`;

const LabelContainer = styled.div`
    position: absolute;
    top: 0;
    left: 0;
    width: 0;
    height: 0;
    filter: drop-shadow(0.357rem 0.357rem 1.071rem #00000029);
`;

const Label = styled.div`
    position: absolute;
    top: 0;
    left: 0;
    transform: rotate(-45deg);
    width: 8.571rem;
    height: 8.571rem;
    padding-top: 0.714rem;
    text-align: center;
    text-transform: uppercase;
    font-size: 0.8rem;
`;

const ProjectLabel: FC<React.PropsWithChildren<ProjectLabelProps>> = ({ platform, label = "", bgColor = "inherit" }) => (
    <>
        <LabelContainer>
            <LabelBg bgColor={bgColor} />
        </LabelContainer>

        <Label>
            <b>{platform}</b>
            <br />
            {label}
        </Label>
    </>
);

export default ProjectLabel;
