import React from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCheck } from "@fortawesome/free-solid-svg-icons";
import Select, { GroupBase, Props, StylesConfig } from "react-select";
import styled from "styled-components";
import { dropdownComponents, FixedOption, getTheme, IndicatorsContainer, Option, styles } from "./select.helper";
import { EFormLabel } from "../..";

export interface ESelectProps<T extends FixedOption, IsMulti extends boolean = false, Group extends GroupBase<T> = GroupBase<T>>
    extends Props<T, IsMulti, Group> {
    displayLabel?: string;
    displayKey?: keyof T;
    containerPlaceholder?: string;
    testId?: string;
}

const DefaultOption = <T,>({
    item,
    displayKey,
    isSelected,
    displayCheckMark,
}: {
    item: T;
    displayKey?: keyof T;
    isSelected: boolean;
    displayCheckMark: boolean;
}) => (
    <div className="d-flex align-items-center justify-content-between">
        {displayKey && <span>{item[displayKey] as any}</span>}
        {item && isSelected && displayCheckMark && <FontAwesomeIcon className="text-green" icon={faCheck} />}
    </div>
);

const StyledSelect: Select = styled(Select)`
    .Select__control {
        .Select__value-container {
            color: var(--white) !important;
        }
    }

    .Select {
        &__multi-value {
            background: var(--page-block-background-color) !important;
        }
        &__multi-value__label {
            color: var(--white) !important;
        }

        &__multi-value__remove {
            :hover {
                color: rgba(201, 201, 201, 0.8) !important;
                background: var(--dark-green) !important;
            }
        }

        &__value-container {
            max-height: 10rem;
            overflow-y: scroll;
        }
    }

    #react-select-3-input {
        outline: none !important;
    }
`;

const ESelect = <T, IsMulti extends boolean = false, Group extends GroupBase<T> = GroupBase<T>>({
    value,
    displayKey,
    displayLabel,
    hideSelectedOptions = true,
    containerPlaceholder,
    isClearable = false,
    formatOptionLabel,
    testId,
    ...props
}: ESelectProps<T, IsMulti, Group>) => {
    const selectComponents = containerPlaceholder ? dropdownComponents(containerPlaceholder) : undefined;

    return (
        <>
            {displayLabel && <EFormLabel>{displayLabel}</EFormLabel>}
            <StyledSelect
                styles={styles as StylesConfig<T, IsMulti, Group>}
                hideSelectedOptions={hideSelectedOptions}
                formatOptionLabel={(item, meta) =>
                    formatOptionLabel ? (
                        formatOptionLabel(item, meta)
                    ) : (
                        <DefaultOption
                            item={item}
                            displayKey={displayKey}
                            isSelected={Array.isArray(value) && value?.includes(item)}
                            displayCheckMark={!hideSelectedOptions}
                        />
                    )
                }
                classNamePrefix="Select"
                name="eSelect"
                menuPortalTarget={document.body}
                theme={getTheme}
                isClearable={
                    Array.isArray(value) ? isClearable && !value.some((option: FixedOption) => option.isFixed) : isClearable
                }
                data-testid={testId ?? "select-field"}
                // eslint-disable-next-line @typescript-eslint/ban-ts-comment
                // @ts-ignore: IndicatorsContainer type is the problem, but it matches the react-select documentation
                components={{ Option, IndicatorSeparator: () => null, IndicatorsContainer, ...selectComponents }}
                value={value}
                {...props}
            />
        </>
    );
};

export default ESelect;
