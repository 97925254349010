import { AxiosResponse } from "axios";
import { SortOrder } from "react-data-table-component/dist/src/DataTable/types";
import AlertService from "./AlertService";

export interface Sort {
    field: string;
    order: SortOrder;
}

abstract class BaseService {
    public doCall<T>(callProducer: () => Promise<AxiosResponse<T>>, errorMessage?: string, catchSilent = false): Promise<T> {
        return new Promise((resolve, reject) => {
            callProducer()
                .then((resp) => resolve(resp.data))
                .catch((err) => {
                    if (!catchSilent) {
                        this.triggerNotificationError(err.response?.data, errorMessage);
                    }
                    reject(err);
                });
        });
    }

    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    public triggerNotificationError(response: any, errorMessage = "Sorry - Please refresh the page again"): void {
        let errMsg = errorMessage;
        if (!response) {
            errMsg = "Communication failed";
        } else if (response.error || response.statusCode !== 201 || response.statusCode !== 200) {
            errMsg = response.message;
        }
        AlertService.error(errMsg);
    }
}

export default BaseService;
