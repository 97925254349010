import { ApiNetworkProvider } from "@multiversx/sdk-network-providers/out";
import {
    AbiRegistry,
    Address,
    ContractFunction,
    EnumType,
    Interaction,
    ITransactionValue,
    ResultsParser,
    SmartContract,
    StructType,
    TypedValue,
} from "@multiversx/sdk-core/out";
import { defaultGasLimit, defaultGasPrice, ldmSC, network } from "../config";
import { sendTransactions } from "@multiversx/sdk-dapp/services";
import SessionStorageService from "./sessionStorageService/SessionStorageService";
import BigNumber from "bignumber.js";

export enum LdmScFunc {
    ADD_TO_WHITELIST = "addToWhitelist",
    REMOVE_FROM_WHITELIST = "removeFromWhitelist",
    GET_WHITELIST = "getWhitelist",
    APPROVE_SALE = "approveSale",
    DENY_SALE = "denySale",
    GET_PENDING_IDS = "getPendingIds",
    GET_PENDING_SALES = "getPendingSales",
    CREATE_SALE = "createSale",
    END_SALE = "endSale",
    GET_SALES = "getSales",
    GET_SALES_IDS = "getSalesIds",
    GET_SALE_INFO = "getSaleInfo",
    CLAIM_BUY_TOKENS = "claimBuyTokens",
    GET_CLAIMABLE_BUY_TOKENS = "getClaimableBuyTokens",
    SELL_TOKENS = "sellTokens",
    GET_SOLD_TOKENS = "getSoldTokens",
    GET_LOCKED_TOKENS = "getLockedTokens",
    CLAIM_EXCHANGED_TOKENS = "claimExchangedTokens",
    GET_REMAINING_TOKENS = "getRemainingTokens",
    GET_NFT_TOKENS = "getNftTokens",
    GET_SFT_TOKENS = "getSftTokens",
    SET_FEES_PERCENTAGE = "setFeesPercentage",
    GET_FEES_PERCENTAGE = "getFeesPercentage",
    FREEZE = "freeze",
    UNFREEZE = "unfreeze",
    GET_FREEZED = "getFreezed",
    PAUSE_SALE = "pauseSale",
    UNPAUSE_SALE = "unpauseSale",
    EMERGENCY = "emergency",
}

export const SaleType = EnumType.fromJSON({
    name: "SaleType",
    variants: [
        {
            name: "NFT",
            discriminant: 0,
        },
        {
            name: "SFT",
            discriminant: 1,
        },
        {
            name: "TOKEN",
            discriminant: 2,
        },
        {
            name: "EGLD",
            discriminant: 3,
        },
    ],
});

export enum LdmSaleType {
    NFT = "NFT",
    SFT = "SFT",
    TOKEN = "TOKEN",
    EGLD = "EGLD",
}

export interface LdmTokenSale {
    name: LdmSaleType;
    fields: unknown[];
}

export interface LdmScSale {
    id: BigNumber;
    token_buy: string;
    token_buy_type: LdmTokenSale;
    token_buy_nonces: BigNumber[];
    token_exchange: string;
    token_exchange_type: LdmTokenSale;
    amount_tokens: BigNumber;
    manager_address: Address;
    locking_time: BigNumber;
    ratio: BigNumber;
    amount_bought: BigNumber;
    sellers: Address[];
    paused: boolean;
}

export const Sale = StructType.fromJSON({
    name: "Sale",
    fields: [
        {
            name: "token_buy",
            type: "EgldOrEsdtTokenIdentifier",
        },
        {
            name: "token_buy_type",
            type: "SaleType",
        },
        {
            name: "token_buy_nonces",
            type: "List<u64>",
        },
        {
            name: "token_exchange",
            type: "EgldOrEsdtTokenIdentifier",
        },
        {
            name: "token_exchange_type",
            type: "SaleType",
        },
        {
            name: "amount_tokens",
            type: "BigUint",
        },
        {
            name: "manager_address",
            type: "Address",
        },
        {
            name: "locking_time",
            type: "u64",
        },
        {
            name: "ratio",
            type: "BigUint",
        },
        {
            name: "amount_bought",
            type: "BigUint",
        },
        {
            name: "sellers",
            type: "List<Address>",
        },
        {
            name: "paused",
            type: "bool",
        },
    ],
});

class LdmScInteractorService {
    private static instance: LdmScInteractorService;

    private readonly contract: SmartContract;
    private readonly networkProvider = new ApiNetworkProvider(network.apiAddress);
    private readonly resultsParser = new ResultsParser();

    private constructor(contract: SmartContract) {
        this.contract = contract;
    }

    static async getInstance() {
        if (!LdmScInteractorService.instance) {
            LdmScInteractorService.instance = await LdmScInteractorService.buildInstance();
        }
        return LdmScInteractorService.instance;
    }

    private static async buildInstance(): Promise<LdmScInteractorService> {
        const ldmAbiJson = await require("./../abi/ldm.abi.json");
        const registry = AbiRegistry.create(ldmAbiJson);
        const contract = new SmartContract({ address: new Address(ldmSC), abi: registry });
        return new LdmScInteractorService(contract);
    }

    async buildAndExecuteQueryFunc<T>(funcName: LdmScFunc, args: TypedValue[] = []): Promise<T | undefined> {
        const interaction = new Interaction(this.contract, new ContractFunction(funcName), args);
        const queryResp = await this.networkProvider.queryContract(interaction.check().buildQuery());
        const bundle = await this.resultsParser.parseQueryResponse(queryResp, interaction.getEndpoint());
        if (!bundle || !bundle.returnCode.isSuccess()) return;
        return bundle.firstValue?.valueOf();
    }

    async buildAndExecuteTx(funcName: LdmScFunc, args: TypedValue[] = [], value: ITransactionValue = 0): Promise<void> {
        const interaction = new Interaction(this.contract, new ContractFunction(funcName), args)
            .withValue(value)
            .withGasPrice(defaultGasPrice)
            .withGasLimit(defaultGasLimit)
            .withChainID(network.chainId);
        await LdmScInteractorService.buildAndSendTx(interaction);
    }

    async buildAndExecuteDynamicTx(
        funcName: LdmScFunc,
        args: TypedValue[] = [],
        interactionBuilder: (inter: Interaction) => Interaction,
    ): Promise<void> {
        const interaction = new Interaction(this.contract, new ContractFunction(funcName), args)
            .withGasPrice(defaultGasPrice)
            .withGasLimit(defaultGasLimit)
            .withChainID(network.chainId);
        await LdmScInteractorService.buildAndSendTx(interactionBuilder(interaction));
    }

    private static async buildAndSendTx(interaction: Interaction): Promise<void> {
        const tx = interaction.check().buildTransaction().toPlainObject();
        const { sessionId } = await sendTransactions({
            transactions: [tx],
        });
        if (sessionId) {
            SessionStorageService.addSessionId(sessionId);
        }
    }
}

export default LdmScInteractorService;
