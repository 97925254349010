import React from "react";
import { FormGroupProps, TQuarterInfo } from "./types";
import { FormControlGroupProps } from "./FormControlledInputGroup";
import { Quarters } from "../../pages/Ico/ico.types";
import styled from "styled-components";
import FormSelectGroup from "./FormSelectGroup";
import { FormControlledInputGroup } from "../index";

interface QuarterYearSelectProps<T> extends FormGroupProps<T>, Pick<FormControlGroupProps<T>, "control"> {
    value: TQuarterInfo;
    quarterSelectorName: string;
    quarterSelectorLabel: string;
    yearSelectorName: string;
    yearSelectorLabel: string;
    quarterYears?: number;
}

const SelectGroup = styled(FormSelectGroup)`
    width: 100%;
`;

const SelectGroupContainer = styled.div`
    display: flex;
    width: 100%;
`;

const MyFormControlledInputGroup = styled(FormControlledInputGroup)`
    display: unset !important;
    margin-bottom: 0 !important;
    width: 100%;
`;

function QuarterYearSelectGroup<T>(props: QuarterYearSelectProps<T>): JSX.Element {
    const {
        quarterSelectorName,
        quarterSelectorLabel,
        value,
        register,
        formState,
        quarterYears = 4,
        yearSelectorName,
        yearSelectorLabel,
        control,
        name,
    } = props;

    const prevYear = new Date().getFullYear() - 1;

    return (
        <MyFormControlledInputGroup
            name={name}
            label=""
            register={register}
            control={control}
            formState={formState}
            className=""
            render={() => (
                <SelectGroupContainer>
                    <SelectGroup
                        name={quarterSelectorName}
                        label={quarterSelectorLabel}
                        register={register}
                        formState={formState}
                        control={control}
                        defaultValue={value.quarter}
                        parentClassName="w-100 pr-xs mb-3"
                        className="w-100"
                    >
                        <option selected={!value.quarter}>None</option>
                        {Object.keys(Quarters).map((quarter, index) => (
                            <option value={`${quarter}`} key={index}>
                                {`${quarter}`}
                            </option>
                        ))}
                    </SelectGroup>

                    <SelectGroup
                        name={yearSelectorName}
                        label={yearSelectorLabel}
                        register={register}
                        formState={formState}
                        control={control}
                        defaultValue={value.year}
                        parentClassName="w-100 pl-xs mb-3"
                    >
                        <option selected={!value.year}>None</option>
                        {[...Array(quarterYears)].map((x, i) => (
                            <option value={`${prevYear + i}`} key={i}>
                                {`${prevYear + i}`}
                            </option>
                        ))}
                    </SelectGroup>
                </SelectGroupContainer>
            )}
        />
    );
}

export default QuarterYearSelectGroup;
