import React, { PropsWithChildren, useEffect } from "react";
import { FormGroupProps } from "./types";
import { ESelect } from "./../../common";
import FormControlledInputGroup, { FormControlGroupProps } from "./FormControlledInputGroup";
import { Path, UseFormSetValue } from "react-hook-form";
import { ESelectProps } from "../components/ESelect/ESelect";

interface FormMultiSelectGroupProps<T, V>
    extends FormGroupProps<T>,
        Pick<FormControlGroupProps<T>, "control">,
        Omit<ESelectProps<T>, "getOptionValue" | "onChange" | "value" | "name"> {
    setValue: UseFormSetValue<V>;
    values: T[];
}

function FormMultiSelectGroup<T, V>(props: PropsWithChildren<FormMultiSelectGroupProps<T, V>>): JSX.Element {
    const {
        name,
        label,
        displayKey,
        formatOptionLabel,
        isClearable,
        values = [],
        options = [],
        isMulti = true,
        className = undefined,
        control,
        register,
        setValue,
        formState,
    } = props;

    useEffect(() => setValue(name as Path<V>, values as any, { shouldValidate: true }), []);

    return (
        <FormControlledInputGroup
            name={name}
            label={label}
            register={register}
            control={control}
            className={`flex-column free-text-controller ${className}`}
            formState={formState}
            render={({ onChange, value, ref }) => {
                return (
                    <div ref={ref}>
                        <ESelect
                            displayKey={displayKey}
                            value={value}
                            options={options}
                            hideSelectedOptions
                            isClearable={isClearable}
                            isMulti={isMulti}
                            getOptionValue={(option) => (displayKey ? String(option[displayKey]) : String(option))}
                            formatOptionLabel={formatOptionLabel}
                            onChange={onChange}
                        />
                    </div>
                );
            }}
        />
    );
}

export default FormMultiSelectGroup;
