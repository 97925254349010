import React from "react";
import { NumberFormatProps } from "react-number-format";
import { Currency, CurrencySymbol } from "../../common/utils/CurrencyUtils";
import Number from "./Number";

interface CurrencyNumberProps extends Pick<NumberFormatProps, "value"> {
    currency?: Currency;
    title?: string;
}

const formatting = {
    [Currency.USD]: { prefix: CurrencySymbol[Currency.USD] },
    [Currency.EUR]: { suffix: CurrencySymbol[Currency.EUR] },
};

const CurrencyNumber = ({ currency = Currency.USD, value = 0, ...props }: CurrencyNumberProps) => {
    return <Number {...formatting[currency]} value={value as number} {...props} />;
};

export default CurrencyNumber;
