import { icoStepOneInitial, IcoStepOneInputs } from "../../pages/Ico/IcoStepOne";
import { icoStepTwoInitial, IcoStepTwoInputs } from "../../pages/Ico/IcoStepTwo";
import { icoStepThreeInitial, IcoStepThreeInputs } from "../../pages/Ico/IcoStepThree";
import { IcoStatus, IsHotListed, IsScam, Status, TotalRating } from "../../pages/Ico/ico.types";

export interface Ico extends IcoStepOneInputs, IcoStepTwoInputs, IcoStepThreeInputs, Status, IsHotListed, IsScam, CaptchaToken {
    id?: string;

    createdAt: number;
    updatedAt?: number;
    // logo image stored file name
    logo: string;
    // feature image stored file name
    featureImage: string | null;

    preSaleStart: string;
    preSaleEnd: string;
    publicSaleStart: string;
    publicSaleEnd: string;
    kycStart: string;
    kycEnd: string;
    tradableStart: string;

    // admin area
    review?: string;
    ratings: IcoRatings;
    totalVotes?: number;
}

export const icoInitial: Ico = {
    ...icoStepOneInitial,
    ...icoStepTwoInitial,
    ...icoStepThreeInitial,
    id: "",

    logo: "",
    createdAt: 0,
    featureImage: "",
    preSaleStart: "",
    preSaleEnd: "",
    publicSaleStart: "",
    publicSaleEnd: "",
    kycStart: "",
    kycEnd: "",
    tradableStart: "",

    status: IcoStatus.new,
    review: undefined,
    ratings: {
        scores: {
            advisorsScore: 0,
            competitionScore: 0,
            foundersScore: 0,
            hypeAndMediaPresenceScore: 0,
            marketPotentialScore: 0,
            mvpScore: 0,
            networkEffectScore: 0,
            proofOfConceptScore: 0,
            supplyScoreSold: 0,
            technologyLayerScore: 0,
            tokenUtilityScore: 0,
            valuationScore: 0,
            vestingScore: 0,
        },
        totalScore: 0,
    },
    isScam: false,
    isHotListed: false,
    totalVotes: 0,
};

export interface IcoReviewRatings {
    review?: string;
    ratings: IcoRatings;
}

export interface IcoRatings {
    scores: IcoRatingsScores;
    totalScore: TotalRating;
}

export interface IcoRatingsScores {
    foundersScore: number;
    advisorsScore: number;
    proofOfConceptScore: number;
    mvpScore: number;
    technologyLayerScore: number;
    tokenUtilityScore: number;
    networkEffectScore: number;
    valuationScore: number;
    marketPotentialScore: number;
    competitionScore: number;
    supplyScoreSold: number;
    vestingScore: number;
    hypeAndMediaPresenceScore: number;
}

export const icoRatingsInitial: IcoRatings = {
    scores: {
        advisorsScore: 0,
        competitionScore: 0,
        foundersScore: 0,
        hypeAndMediaPresenceScore: 0,
        marketPotentialScore: 0,
        mvpScore: 0,
        networkEffectScore: 0,
        proofOfConceptScore: 0,
        supplyScoreSold: 0,
        technologyLayerScore: 0,
        tokenUtilityScore: 0,
        valuationScore: 0,
        vestingScore: 0,
    },
    totalScore: 0,
};

export const icoReviewRatingInitial: IcoReviewRatings = {
    review: "",
    ratings: { ...icoRatingsInitial },
};

export type IcoRatingIdType = keyof typeof icoRatingsInitial.scores;

export interface IcoRatingsAndReview {
    review: string;
    ratings: IcoRatings;
}

export const icoRatingsAndReviewInitial: IcoRatingsAndReview = {
    ratings: icoRatingsInitial,
    review: "",
};

export interface CaptchaToken {
    captchaToken?: string;
}

export const initialCaptchaToken: CaptchaToken = {
    captchaToken: "",
};
