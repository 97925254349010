import React, { memo } from "react";
import styled from "styled-components";
import { Dimensions, Point } from "./helpers/hex.types";
import { getID } from "./helpers/hex.helper";
import Pattern from "./Pattern";
import Hex from "./helpers/hex";

export interface HexagonProps {
    className?: string;
    image?: string;
    imageSize?: Dimensions;
    style: React.CSSProperties;
    onClick?: HexagonMouseEventHandler;
    children?: React.ReactNode | JSX.Element;
    hex: Hex;
    templateId?: string;
    pulsation?: boolean;
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    memoCompareFunction?: (prev: any, next: any) => boolean;
}

export type EHexagonProps = HexagonProps & Omit<React.SVGProps<SVGGElement>, "onClick">;

type MouseEvent<T = SVGGElement> = React.MouseEvent<T, globalThis.MouseEvent>;
export type HexagonMouseEventHandler = (event: MouseEvent, h?: H) => void;
type H = { hex: Hex };

const defaultBackgroundColor = "#51E2DF40";

const getRandom = () => {
    return `${Math.random() * 3}s`;
};

const StyledHegaxon = styled.g`
    --random-animation-delay: ${() => getRandom()};

    g.hexagon {
        fill: ${(props: { backgroundColor?: string }) => props.backgroundColor || defaultBackgroundColor};
        fill-opacity: 1;
    }

    g.hexagon.pulse {
        fill-opacity: 0.3;
        animation-name: "hexagonPulse";
        -webkit-animation-name: "hexagonPulse";
        animation-duration: 4s;
        animation-iteration-count: infinite;
        animation-delay: var(--random-animation-delay);

        &:hover {
            --custom-animation-delay: 0;
            animation-play-state: paused;
            fill-opacity: 1;
            cursor: pointer;
            animation-name: none;
            -webkit-animation-name: none;
        }

        @keyframes hexagonPulse {
            0% {
                fill-opacity: 0.3;
            }

            20% {
                fill-opacity: 0.6;
            }
            40% {
                fill-opacity: 0.5;
            }
            60% {
                fill-opacity: 0.6;
            }

            80% {
                fill-opacity: 0.3;
            }
            100% {
                fill-opacity: 0.3;
            }
        }

        @-webkit-keyframes hexagonPulse {
            0% {
                fill-opacity: 0.3;
            }

            20% {
                fill-opacity: 0.6;
            }
            40% {
                fill-opacity: 0.5;
            }
            60% {
                fill-opacity: 0.6;
            }

            80% {
                fill-opacity: 0.3;
            }
            100% {
                fill-opacity: 0.3;
            }
        }
    }
`;

const defaultMemoCompareFunction = (prev: HexagonProps, next: HexagonProps) => {
    return (
        JSON.stringify(prev.hex) === JSON.stringify(next.hex) &&
        JSON.stringify(prev.style) === JSON.stringify(next.style) &&
        JSON.stringify(prev.image) === JSON.stringify(next.image) &&
        JSON.stringify(prev.className) === JSON.stringify(next.className) &&
        JSON.stringify(prev.templateId) === JSON.stringify(next.templateId)
    );
};

const EHexagon = ({
    hex,
    children,
    style,
    image,
    imageSize,
    className,
    templateId = "hex-template",
    onClick,
    pulsation,
}: EHexagonProps) => {
    const points = hex.corners.map((point: Point) => `${point.x},${point.y}`).join(" ");
    const bgId = !!image ? getID(hex, templateId) : null;
    const dimensions = imageSize ?? { width: hex.width, height: hex.height };

    return (
        <StyledHegaxon
            className={`hexagon-group ${className}`}
            backgroundColor={!!bgId ? `url(#${bgId})` : style.backgroundColor}
            onClick={(e: MouseEvent) => onClick && onClick(e, { hex })}
        >
            <g className={`hexagon ${pulsation ? "pulse" : ""}`}>
                <g transform={`translate(${hex.x}, ${hex.y})`}>
                    {bgId && !!image && <Pattern id={bgId} image={image} dimensions={dimensions} />}
                    {bgId && <polyline fill={style.backgroundColor} points={points} />}
                    <polygon points={points} style={style} />
                </g>
                {children}
            </g>
        </StyledHegaxon>
    );
};

export default memo(EHexagon, (prev, next) => {
    return prev.memoCompareFunction ? prev.memoCompareFunction(prev, next) : defaultMemoCompareFunction(prev, next);
});
