import SessionStorageService from "../../services/sessionStorageService/SessionStorageService";
import { SessionStorageKeys } from "../../services/sessionStorageService/sessionStorageKeys";
import { useGetSignedTransactionsReturnType } from "@multiversx/sdk-dapp/hooks/transactions/useGetSignedTransactions";
import { SignedTransactionsBodyType } from "@multiversx/sdk-dapp/types";

interface SignedCallback {
    onSuccessCallback: (txSigned: SignedTransactionsBodyType) => Promise<unknown> | void;
    afterSignedCallback?: (txSigned: SignedTransactionsBodyType) => Promise<void> | void;
    onFailCallback?: () => Promise<void> | void;
}

function checkSignedTransaction(signedTransactionsState: useGetSignedTransactionsReturnType, callbacks: SignedCallback) {
    const sessionIds = SessionStorageService.getItem(SessionStorageKeys.signedTxSessionsId);
    if (!sessionIds) return;

    const { onSuccessCallback, afterSignedCallback, onFailCallback } = callbacks;
    for (const sessionId of sessionIds) {
        if (
            signedTransactionsState &&
            signedTransactionsState.hasSignedTransactions &&
            signedTransactionsState.signedTransactions[sessionId]
        ) {
            const txSigned = signedTransactionsState.signedTransactions[sessionId];
            afterSignedCallback && afterSignedCallback(txSigned);
            if (txSigned.status === "success") {
                SessionStorageService.deleteSessionId(sessionId);
                onSuccessCallback(txSigned);
            }
            if (txSigned.status === "fail" || txSigned.status === "timedOut") {
                SessionStorageService.deleteSessionId(sessionId);
                onFailCallback && onFailCallback();
            }
        }
    }
}

export default checkSignedTransaction;
