import React from "react";
import styled from "styled-components";
import Seedling from "../../../assets/img/decorElements/seedling.webp";
import Description from "./Description";
import { Image } from "react-bootstrap";
import { BREAKPOINTS } from "../../../assets/sass/breakpoints";

interface InteriorProps {
    title: string;
    description: string;
    className?: string;
}

const SeedlingImage = styled(Image)`
    width: 60%;
    position: absolute;
    top: -6rem;
    left: -3rem;

    @media (${BREAKPOINTS.tablet}) {
        width: 58%;
        top: -5rem;
        left: -3rem;
    }
`;

const Container = styled.div`
    display: flex;
    flex-direction: row;
    justify-content: center;
    width: 100%;
    height: 100%;
    position: absolute;
    bottom: 0;
`;

const SphereContent = ({ title, description, className = "" }: InteriorProps): JSX.Element => {
    return (
        <Container className={className}>
            <SeedlingImage src={Seedling} />
            <Description title={title} description={description} />
        </Container>
    );
};

export default SphereContent;
