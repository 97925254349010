import React from "react";
import styled from "styled-components";
import { Placeholder, PlaceholderProps } from "react-bootstrap";

interface ImagePlaceholderProps {
    height?: string;
    width?: string;
    bgColor?: string;
    className?: string;
    as?: PlaceholderProps["as"];
}

const ImagePlaceholder = styled(Placeholder)`
    height: ${({ height }: ImagePlaceholderProps) => height || "100%"};
    width: ${({ width }: ImagePlaceholderProps) => width || "100%"};
    background-color: ${({ bgColor }: ImagePlaceholderProps) => bgColor || "var(--entity-gray-second)"};
    border: none;
`;

const Image = ({
    height = "100%",
    width = "100%",
    bgColor = "var(--entity-gray-second)",
    className = "",
    as,
}: ImagePlaceholderProps) => <ImagePlaceholder height={height} width={width} bgColor={bgColor} className={className} as={as} />;

export default Image;
