import React, { useMemo } from "react";
import Title from "../../components/Title";
import { TBA } from "../../../../helpers/consts";
import Number from "../../../../components/Price/Number";
import styled from "styled-components";
import { ProjectInvestmentDetails, ProjectTiersDetails } from "../../launchpad.types";
import { EPlaceholder } from "../../../../components/Placeholder";
import CurrencyNumber from "../../../../components/Price/CurrencyNumber";
import { TicketPriceUtils } from "../../../../common/utils/TicketPriceUtils";

const ListContainer = styled.ul`
    list-style: none;
    padding-left: 0;
    color: var(--white);
    margin-bottom: 0;

    li {
        margin-bottom: 0.35rem;
    }

    li::before {
        content: "\\2022";
        display: inline-block;
        width: 1rem;
        margin-left: 1em;
    }
`;

const getValueOrTBA = (value: string | number | undefined, children: JSX.Element): JSX.Element | string => {
    if (value) {
        if (value === 0) {
            return TBA;
        }

        return children;
    }

    return TBA;
};

const TokenSaleDetails = ({
    investmentInfo,
    tiersInfo,
}: {
    investmentInfo?: ProjectInvestmentDetails;
    tiersInfo?: ProjectTiersDetails;
}) => {
    const ticketPriceInDollars = useMemo(() => TicketPriceUtils.getDollarAmount(tiersInfo), [tiersInfo]);

    return (
        <>
            <Title title="Token Sale Details" className="mt-2" />

            {!investmentInfo ? (
                <EPlaceholder numberOfLines={5} />
            ) : (
                <>
                    <ListContainer>
                        <li>Token Name: {investmentInfo.tokenSaleDetails.tokenName ?? TBA}</li>
                        {investmentInfo.tokenSaleDetails.softcap &&
                            investmentInfo.tokenSaleDetails.softcap !== investmentInfo.tokenSaleDetails.hardcap && (
                                <li>
                                    Soft Cap:{" "}
                                    {getValueOrTBA(
                                        investmentInfo.tokenSaleDetails.softcap,
                                        <Number value={investmentInfo.tokenSaleDetails.softcap} prefix={"US$"} />,
                                    )}
                                </li>
                            )}
                        <li>
                            Hard Cap:{" "}
                            {getValueOrTBA(
                                investmentInfo.tokenSaleDetails.hardcap,
                                <Number value={investmentInfo.tokenSaleDetails.hardcap} prefix={"US$"} />,
                            )}
                        </li>
                        <li>
                            Total Token Supply:
                            {getValueOrTBA(
                                investmentInfo.tokenSaleDetails.maxTokenSupply,
                                <Number
                                    value={investmentInfo.tokenSaleDetails.maxTokenSupply}
                                    suffix={investmentInfo.tokenSaleDetails.tokenName}
                                />,
                            )}
                        </li>
                        <li>
                            Circulating Token Supply:{" "}
                            {getValueOrTBA(
                                investmentInfo.tokenSaleDetails.initialCirculatingSupply,
                                <Number
                                    value={investmentInfo.tokenSaleDetails.initialCirculatingSupply}
                                    suffix={investmentInfo.tokenSaleDetails.tokenName}
                                />,
                            )}
                        </li>
                        <li>
                            Public Sale Token Price:{" "}
                            {getValueOrTBA(
                                investmentInfo.tokenSaleDetails.publicSalePrice,
                                <>
                                    {` 1 ${investmentInfo.tokenSaleDetails.tokenName} ≈ `}
                                    <CurrencyNumber value={investmentInfo.tokenSaleDetails.publicSalePrice} />
                                </>,
                            )}
                        </li>
                    </ListContainer>
                    <ListContainer>
                        <li>
                            Max Number of Winning Lottery Tickets:{" "}
                            {getValueOrTBA(
                                investmentInfo.maxNoLotteryTickets,
                                <Number value={investmentInfo.maxNoLotteryTickets} />,
                            )}
                        </li>
                        <li>
                            Allocation Per Winning Ticket:{" "}
                            {getValueOrTBA(ticketPriceInDollars, <CurrencyNumber value={ticketPriceInDollars} />)}
                            {ticketPriceInDollars && investmentInfo.publicSalePrice && (
                                <span>
                                    {` (${+ticketPriceInDollars / investmentInfo.publicSalePrice} ${
                                        investmentInfo.tokenSaleDetails.tokenName
                                    })`}
                                </span>
                            )}
                        </li>

                        <li>Supported Currency: {getValueOrTBA(tiersInfo?.fundingToken, <>{tiersInfo?.fundingToken} only</>)}</li>
                    </ListContainer>
                    {investmentInfo.tokenSaleDetails.miscDetails && (
                        <ListContainer>
                            {investmentInfo.tokenSaleDetails.miscDetails.map((details, index) => (
                                <li key={index}>{details}</li>
                            ))}
                        </ListContainer>
                    )}
                </>
            )}
        </>
    );
};

export default TokenSaleDetails;
