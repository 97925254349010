import { BoundingBox, CubeCoordinates, Ellipse, Orientation, Point } from "./hex.types";
import { defaultHexSettings, hexToPoint } from "./hex.helper";

class Hex implements Readonly<CubeCoordinates>, Readonly<Point>, Readonly<BoundingBox> {
    static DIRECTIONS = [new Hex(1, 0), new Hex(1, -1), new Hex(0, -1), new Hex(-1, 0), new Hex(-1, 1), new Hex(0, 1)];

    public q: number;
    public r: number;
    public readonly s: number;

    constructor(q: number, r: number) {
        this.q = q;
        this.r = r;
        this.s = -q - r;
    }
    get dimensions(): Ellipse {
        return defaultHexSettings.dimensions as Ellipse;
    }

    get orientation(): Orientation {
        return defaultHexSettings.orientation;
    }

    get width(): number {
        const {
            orientation,
            dimensions: { xRadius },
        } = this;
        return orientation === Orientation.POINTY ? xRadius * Math.sqrt(3) : xRadius * 2;
    }

    get height(): number {
        const {
            orientation,
            dimensions: { yRadius },
        } = this;
        return orientation === Orientation.POINTY ? yRadius * 2 : yRadius * Math.sqrt(3);
    }

    get x(): number {
        return hexToPoint(this).x;
    }

    get y(): number {
        return hexToPoint(this).y;
    }

    get origin(): Point {
        return defaultHexSettings.origin as Point;
    }

    get offset(): number {
        return defaultHexSettings.offset;
    }

    get corners(): Point[] {
        const {
            width,
            height,
            origin: { x, y },
            orientation,
        } = this;

        if (orientation === Orientation.POINTY) {
            return [
                { x: width - x, y: height * 0.25 - y },
                { x: width - x, y: height * 0.75 - y },
                { x: width * 0.5 - x, y: height - y },
                { x: 0 - x, y: height * 0.75 - y },
                { x: 0 - x, y: height * 0.25 - y },
                { x: width * 0.5 - x, y: 0 - y },
                { x: width * 0.5 - x, y: 0 - y },
            ];
        }

        return [
            { x: width - x, y: height * 0.5 - y },
            { x: width * 0.75 - x, y: height - y },
            { x: width * 0.25 - x, y: height - y },
            { x: 0 - x, y: height * 0.5 - y },
            { x: width * 0.25 - x, y: 0 - y },
            { x: width * 0.75 - x, y: 0 - y },
        ];
    }
}

export default Hex;
