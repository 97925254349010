import React, { FC, SyntheticEvent } from "react";
import styled from "styled-components";
import { Link } from "react-router-dom";
import { eButtonStyles, EButtonProps } from "./Button";

// TODO: use LinkProps type from react-router-dom or use Link Button from react-bootstrap
export interface ELinkButtonProps extends EButtonProps {
    className?: string;
    target?: string;
    disabled?: boolean;
}

interface Helpers {
    onClick?: (e: SyntheticEvent) => void;
}

const Btn = styled.div`
    .btn {
        ${eButtonStyles};
    }
`;

const ELinkButton: FC<React.PropsWithChildren<ELinkButtonProps>> = ({
    color = "var(--primary-tx-btn)",
    target,
    disabled = false,
    className,
    testId = "link-btn",
    children,
    ...props
}) => {
    const helpers: Helpers = {};

    const handleDisabled = (e: SyntheticEvent) => e.preventDefault();
    disabled && (helpers.onClick = handleDisabled);
    const { to, ...containerParams } = props;

    return (
        <Btn color={color} {...containerParams}>
            <Link
                target={target}
                to={to}
                {...helpers}
                data-testid={testId}
                className={`btn ${className}` + (disabled ? " disabled" : "")}
            >
                {children}
            </Link>
        </Btn>
    );
};

export default ELinkButton;
