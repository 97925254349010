import styled from "styled-components";
import { Modal } from "react-bootstrap";
import { closeButton } from "../Buttons/CloseButton";

const EModal = styled(Modal)`
    --bs-modal-header-padding: 1.714rem !important;
    --bs-modal-padding: var(--bs-modal-header-padding);
    --close-button-height: 2.571rem;

    font-weight: lighter;
    background-color: #00000080;

    .btn-close {
        ${closeButton};
    }

    .modal-content {
        color: white;
        background: #2066668c 0 0 no-repeat padding-box;
        border-radius: 10px;
        backdrop-filter: blur(30px) brightness(3.5) drop-shadow(0 0 40px #51e2df99);

        .modal-header {
            position: relative !important;
            border-bottom: none;

            .modal-title {
                line-height: var(--close-button-height);
                font-size: 2rem;
            }
        }

        .modal-footer {
            border-top: none;
            display: flex;
            justify-content: center;
            align-items: center;
        }
    }
`;

export default EModal;
