import React from "react";
import ProjectDome from "../../../assets/img/decorElements/project-dome.webp";
import { PageBlockTitle } from "../../../components/PageBlockTitle";
import { Image } from "react-bootstrap";
import styled from "styled-components";
import { BREAKPOINTS } from "../../../assets/sass/breakpoints";

const title = "Startups";
const pageBlockDescription = "Successful projects add value to the MultiversX ecosystem and drive global network adoption.";

const DomeImage = styled(Image)`
    width: 100%;
    max-width: 40.938rem;
`;

const Content = styled.div`
    align-items: center;

    @media (${BREAKPOINTS.tablet}) {
        padding-left: 3rem;
        align-items: unset;
    }
`;

const Container = styled.div`
    margin-top: 0;
    margin-bottom: 3rem;

    @media (${BREAKPOINTS.tablet}) {
        margin-top: 8.75rem;
        margin-bottom: 8.75rem;
    }
`;

const Startups = () => {
    return (
        <Container className="d-flex flex-column" data-testid="startups">
            <Content className="d-flex flex-column-reverse flex-lg-row">
                <DomeImage src={ProjectDome} />
                <PageBlockTitle className="pt-4" title={title} description={pageBlockDescription} />
            </Content>
        </Container>
    );
};

export default React.memo(Startups);
