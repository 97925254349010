import React, { memo, useState } from "react";
import styled from "styled-components";
import { BREAKPOINTS } from "../../../../assets/sass/breakpoints";
import { Accordion } from "react-bootstrap";
import KYCAccordionItem from "./KYCAccordionItem";

export type TroubleshootingItem = {
    title: string;
    do: string[];
    dont: string[];
};

const troubleshootingItems: TroubleshootingItem[] = [
    {
        title: "Which Identity Document to Use",
        do: [
            "Use a passport, government-issued photo ID, or national identity card.",
            "Use a valid ID document.",
            "Use a document with a Machine-Readable Zone (MRZ). This is two lines of characters that are usually at the bottom of an ID document.",
        ],
        dont: [
            "Use a school ID, medical ID, temporary (paper) ID, residence permit, public services card, or military ID.",
            "Use an expired ID document.",
            "Use a document without an MRZ code.",
        ],
    },
    {
        title: "Entering Personal Information",
        do: [
            "Make sure you provide the exact name as in your ID document.",
            "If your ID document includes additional first names or a middle name next to your first name, include all names.",
        ],
        dont: [
            "Provide a slightly different name than in your ID document.",
            "Not include all names next to your first name in your ID document",
        ],
    },
    {
        title: "Taking Photos of Your ID",
        do: ["Use the latest Google Chrome browser", "Take photos in a well-lit area", "Use a plain background"],
        dont: ["Use an outdated browser", "Take photos that are too dark or have glare", "Use a cluttered or uneven background"],
    },
    {
        title: "Taking Photos of Your Self",
        do: [
            "Face the camera directly with your shoulders and top of head visible",
            "Take photos in indirect light",
            "Wear glasses only if you have them in your ID photo",
        ],
        dont: [
            "Face the camera directly with your shoulders and top of head visible",
            "Take photos in a setting that is too dark or bright",
            "Wear sunglasses, hats, or glasses if you aren’t wearing them in your ID photo",
        ],
    },
    {
        title: "Submitting Proof of Address",
        do: [
            "Make sure your address is viewable on the submitted document",
            "Use a real document, bill or statement",
            "Provide information in the document matches your ID",
            "Use a document older than 3 months before the submission date",
        ],
        dont: [
            "Submit a document without a visible address",
            "Use an invalid document",
            "Provide information that does not match your ID",
            "Use a document from fewer than 3 months before the submission date",
        ],
    },
    {
        title: "Waiting for Approval",
        do: [
            "Allow for 7 days for Entity to process your KYC application",
            "If you submitted incorrect information, wait for the system to reject you and try again",
        ],
        dont: [
            "E-mail the team or KYC provider before 7 days have elapsed",
            "Attempt to change your information during the review process",
        ],
    },
    {
        title: "Re-Submitting Your Application",
        do: [
            "Carefully read the feedback under reasons given",
            "Re-read the instructions and troubleshooting guide in their entirety",
            "Use the same wallet and email address as your first attempt",
        ],
        dont: [
            "Submit another application with the same errors as your prior attempts",
            "Ignore documentation and guidelines",
            "Use a different wallet and email address",
        ],
    },
];

const AccordionBodyContainer = styled.div`
    font-size: 0.9rem;
    color: white;

    @media (${BREAKPOINTS.tablet}) {
        font-size: 0.95rem;
    }
    @media (${BREAKPOINTS.desktop}) {
        font-size: 1rem;
    }
`;

const KYCInstructions = ({}) => {
    const [kycActiveKey, setKycActiveKey] = useState<string>("");

    return (
        <AccordionBodyContainer
            onClick={(event) => {
                event.preventDefault();
                event.stopPropagation();
            }}
        >
            <p>Find the most important questions and answers in this guide.</p>
            <Accordion activeKey={kycActiveKey} onSelect={(val) => setKycActiveKey(val as string)}>
                {troubleshootingItems.map((item, i) => (
                    <KYCAccordionItem key={i} eventKey={"" + i} item={item} />
                ))}
            </Accordion>
        </AccordionBodyContainer>
    );
};

export default memo(KYCInstructions);
