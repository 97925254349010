import React, { FC } from "react";
import NumberFormat from "react-number-format";

enum DisplayTypes {
    Input = "input",
    Text = "text",
}

const getSpace = (hasSpace: boolean) => (hasSpace ? " " : "");

interface NumberProps {
    title?: string;
    value?: string | number;
    prefix?: string;
    suffix?: string;
    thousandSeparator?: boolean;
    displayType?: DisplayTypes;
    className?: string;
    testId?: string;
    insertSpaceAfterPrefix?: boolean;
    insertSpaceBeforeSuffix?: boolean;
}

const Number: FC<NumberProps> = ({
    title = "",
    value = 0,
    prefix = "",
    suffix = "",
    thousandSeparator = true,
    displayType = DisplayTypes.Text,
    className = "",
    testId = "number",
    insertSpaceAfterPrefix = true,
    insertSpaceBeforeSuffix = true,
    ...props
}) => {
    return (
        <span className={className} data-testid={testId} {...props}>
            {title && <span>{title}: </span>}
            <NumberFormat
                thousandsGroupStyle="thousand"
                value={value ?? 0}
                prefix={[prefix, getSpace(insertSpaceAfterPrefix)].join("")}
                suffix={[getSpace(insertSpaceBeforeSuffix), suffix].join("")}
                decimalSeparator="."
                displayType={displayType}
                type="text"
                thousandSeparator={thousandSeparator}
                allowNegative={true}
            />
        </span>
    );
};
export default React.memo(Number);
