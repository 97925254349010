// eslint-disable-next-line @typescript-eslint/no-var-requires
const CryptoJS = require("crypto-js");

const JsonFormatter = {
    stringify: function (cipherParams: any) {
        const jsonObj = { ct: cipherParams.ciphertext.toString(CryptoJS.enc.Hex) };
        if (cipherParams.iv) {
            // @ts-ignore
            jsonObj.iv = cipherParams.iv.toString();
        }
        if (cipherParams.salt) {
            // @ts-ignore
            jsonObj.s = cipherParams.salt.toString();
        }
        return JSON.stringify(jsonObj);
    },
    parse: function (jsonStr: any) {
        const jsonObj = JSON.parse(jsonStr);
        const cipherParams = CryptoJS.lib.CipherParams.create({
            ciphertext: CryptoJS.enc.Base64.parse(jsonObj.ct),
        });
        if (jsonObj.iv) {
            cipherParams.iv = CryptoJS.enc.Hex.parse(jsonObj.iv);
        }
        if (jsonObj.s) {
            cipherParams.salt = CryptoJS.enc.Hex.parse(jsonObj.s);
        }
        return cipherParams;
    },
};

export class CipherUtils {
    static decrypt<T>(payload: string, iv?: string, algorithm?: string, secret?: string, isEncodedPayload = true): T | undefined {
        if (!payload || !iv || !algorithm || !secret) {
            return undefined;
        }
        if (algorithm !== "aes-256-cbc") {
            throw new Error("Only aes-256-cbc algorithm supported");
        }
        const key = CryptoJS.SHA256(secret);

        const decryptedPayload = CryptoJS.AES.decrypt(
            isEncodedPayload
                ? {
                      ciphertext: CryptoJS.enc.Base64.parse(payload),
                      salt: "",
                  }
                : CryptoJS.enc.Base64.parse(payload),
            key,
            { iv: CryptoJS.enc.Hex.parse(iv), mode: CryptoJS.mode.CBC },
        );

        const decryptedText = CryptoJS.enc.Utf8.stringify(decryptedPayload);

        try {
            return JSON.parse(JSON.parse(decryptedText.toString()));
        } catch (e) {
            return undefined;
        }
    }

    static encrypt(payload: string, iv?: string, algorithm?: string, secret?: string): string | undefined {
        if (!payload || !iv || !algorithm || !secret) {
            return undefined;
        }
        if (algorithm !== "aes-256-cbc") {
            throw new Error("Only aes-256-cbc algorithm supported");
        }
        const initVector = CryptoJS.enc.Hex.parse(CipherUtils.randomHexString(Number(iv)));
        const encrypted = CryptoJS.AES.encrypt(payload, CryptoJS.enc.Utf8.parse(secret), {
            format: JsonFormatter,
            mode: CryptoJS.mode.CBC,
            iv: initVector,
        });

        return encrypted.iv.toString() + ":" + encrypted.ciphertext.toString();
    }

    private static randomHexString(size: number): string {
        return [...Array(size)].map(() => Math.floor(16 * Math.random()).toString(16)).join("");
    }
}

export default CipherUtils;
