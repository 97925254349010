import React from "react";
import FormControlledInputGroup, { FormControlGroupProps } from "./FormControlledInputGroup";
import { DateRangePickerComponent } from "@syncfusion/ej2-react-calendars";
import { DateUtils } from "../index";
import { FormGroupProps, TDate, TRangeDate } from "./types";
import styled from "styled-components";
import { DATE_FORMAT_US } from "../../helpers/consts";

interface FormDatePickerProps<T> extends FormGroupProps<T>, Pick<FormControlGroupProps<T>, "control"> {
    value: TRangeDate;
    min?: TDate;
    max?: TDate;
    format?: string;
}

const WrappedPicker = styled(({ className, ...props }) => {
    return <DateRangePickerComponent cssClass={className} {...props} />;
})`
    &.rounded-item {
        border-radius: 3px !important;
        padding-left: 0.75rem !important;
    }

    &.rounded-item input#ej2-datetimepicker_0::placeholder {
        color: var(--field-placeholder-color) !important;
        padding-left: 0 !important;
    }
`;

const onRangeChange = (value: Date[], onChange: (args: TRangeDate | undefined) => void) => {
    if (value?.length > 0) {
        const [startDate, endDate] = value;
        onChange({
            start: DateUtils.toDateString(startDate),
            end: DateUtils.toDateString(endDate),
            quarterInfo: undefined,
            useDate: true,
        });
    } else {
        onChange(undefined);
    }
};

function FormDateRangePickerGroup<T>(props: FormDatePickerProps<T>): JSX.Element {
    const {
        name,
        label,
        value,
        min,
        max,
        className = "flex-column",
        format = { DATE_FORMAT_US },
        control,
        register,
        formState,
    } = props;

    return (
        <FormControlledInputGroup
            name={name}
            label={label}
            register={register}
            control={control}
            className={className}
            formState={formState}
            render={({ onChange }) => (
                <WrappedPicker
                    name={name}
                    placeholder="Select date"
                    className="rounded-item"
                    startDate={DateUtils.convertToOptionalDate(value.start)}
                    endDate={DateUtils.convertToOptionalDate(value.end)}
                    min={DateUtils.convertToOptionalDate(min)}
                    max={DateUtils.convertToOptionalDate(max)}
                    change={(data: { value: Date[] }) => onRangeChange(data.value, onChange)}
                    delayUpdate={true}
                    format={format}
                    openOnFocus
                    showClearButton
                    showTodayButton
                    allowEdit={false}
                />
            )}
        />
    );
}

export default FormDateRangePickerGroup;
