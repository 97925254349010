import React, { Suspense } from "react";
import { DappProvider } from "@multiversx/sdk-dapp/wrappers";
import { BrowserRouter as Router, Route, Routes } from "react-router-dom";
import { QueryClient, QueryClientProvider } from "@tanstack/react-query";
import { ReactQueryDevtools } from "@tanstack/react-query-devtools";
import { displayReactQueryDevTools, network } from "./config";
import Unlock from "./pages/UnlockPage";
import PageNotFound from "./pages/PageNotFound";
import { ConditionsAgreement } from "./components/ConditionsAgreement";
import Layout from "./components/Layout";
import mappedRoutes, { externalRoutes, routeNames } from "./routes/routes";
import { ESpinner } from "./components/Spinner";
import { customNetworkConfig } from "./config";

const App = () => {
    document.body.classList.add("entity-theme");

    const client = new QueryClient({
        defaultOptions: {
            queries: {
                refetchOnWindowFocus: false,
                refetchOnReconnect: false,
                retry: false,
            },
        },
    });

    return (
        <QueryClientProvider client={client}>
            <Router>
                <DappProvider
                    environment={network.id}
                    customNetworkConfig={customNetworkConfig}
                    dappConfig={{ shouldUseWebViewProvider: true, logoutRoute: routeNames.unlock }}
                    customComponents={{
                        transactionTracker: {
                            // uncomment this to use the custom transaction tracker
                            // component: TransactionsTracker,
                            props: {
                                onSuccess: (sessionId: string) => {
                                    console.log(`Session ${sessionId} successfully completed`);
                                },
                                onFail: (sessionId: string, errorMessage: string) => {
                                    console.log(`Session ${sessionId} failed. ${errorMessage ?? ""}`);
                                },
                            },
                        },
                    }}
                >
                    <Routes>
                        <Route element={<Layout />}>
                            <Route path={routeNames.unlock} element={<Unlock />} />
                            {mappedRoutes.map((route, index: number) => (
                                <Route
                                    path={route.path}
                                    key={"route-key-" + index}
                                    element={
                                        <ConditionsAgreement authenticatedRoute={route.authenticatedRoute}>
                                            <route.component />
                                        </ConditionsAgreement>
                                    }
                                />
                            ))}
                            <Route path="*" element={<PageNotFound />} />
                        </Route>
                        {externalRoutes.map((route, index) => (
                            <Route
                                path={route.path}
                                key={"external-route-key-" + index}
                                element={
                                    <Suspense fallback={<ESpinner />}>
                                        <route.component />
                                    </Suspense>
                                }
                            />
                        ))}
                    </Routes>
                </DappProvider>
            </Router>
            {displayReactQueryDevTools && <ReactQueryDevtools initialIsOpen={false} />}
        </QueryClientProvider>
    );
};

export default App;
