import React from "react";
import ModuleDescription from "../../ModuleDescription";

const TaxTool = () => (
    <ModuleDescription
        title="Tax Tools"
        description="The Entity Tax Tools module gathers and structures data from the MultiversX blockchain to simplify the DeFi accounting process."
    />
);

export default TaxTool;
