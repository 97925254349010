import React, { memo } from "react";
import styled from "styled-components";
import { BREAKPOINTS } from "../../../../assets/sass/breakpoints";

const InfoCard = styled.div`
    background: var(--page-block-background-color);
    color: white;
    height: 100%;
    border-radius: 0 7px 7px 7px;
    margin-bottom: 1rem;

    @media (${BREAKPOINTS.tablet}) {
        margin-bottom: unset;
    }
`;

const Header = styled.div`
    background: var(--entity-bg-darg-green);
    padding-left: 1rem;
    width: 100px;
    clip-path: polygon(-1% -1%, 100% -1%, 66% 100%, 0% 100%);
    -webkit-clip-path: polygon(-1% -1%, 100% -1%, 66% 100%, 0% 100%);
`;

const Body = styled.div`
    padding: 0 1rem 0.5rem 1rem;

    @media (${BREAKPOINTS.tablet}) {
        padding: 0 1rem;
    }
`;

const KYCInfoCardContent = ({ header, items, className }: { header: string; items: string[]; className?: string }) => {
    return (
        <InfoCard>
            <Header className={`text-capitalize ${className ? className : ""}`}>
                <p>{header}</p>
            </Header>
            <Body>
                {items.map((item, index) => (
                    <p key={index}>{item}</p>
                ))}
            </Body>
        </InfoCard>
    );
};

export default memo(KYCInfoCardContent);
