import axios from "axios";
import BaseService from "./BaseService";
import { EmailContact } from "../pages/Contact/Contact";

class ContactService extends BaseService {
    private readonly _urlPrefix: string;

    constructor(entityApi: string) {
        super();
        this._urlPrefix = `${entityApi}/contact`;
    }

    public async sendEmailContact(params: EmailContact) {
        return this.doCall(() => axios.post(this._urlPrefix + "/email", params));
    }
}

export default ContactService;
