import React, { FC, useEffect, useState } from "react";
import Countdown, { CountdownApi } from "react-countdown";
import styled from "styled-components";
import { NumberUtils } from "../../utils/NumberUtils";
import { BREAKPOINTS } from "../../../assets/sass/breakpoints";
import clsx from "clsx";

const CountDownContainer = styled.div`
    margin: 10px auto;
    text-align: center;

    .countdown-text {
        font-size: 1rem;
        text-transform: uppercase;
        text-align: center;
        font-weight: 300;
        color: white;

        @media (${BREAKPOINTS.tablet}) {
            font-size: 1.285rem;
        }
    }

    .countdown {
        display: flex;
        justify-content: center;

        .countdown-col {
            display: inline-block;
            position: relative;
            color: #eeeeee;

            .countdown-col-element {
                display: inline-block;
                margin: 0 1rem;
                display: -ms-flexbox;
                display: flex;
                -ms-flex-direction: column;
                flex-direction: column;
                text-align: center;

                @media (max-width: 992px) {
                    margin: 0 1rem;
                }

                .countdown-timer {
                    font-size: 2rem;
                    min-width: 2.857rem;

                    :after {
                        content: ":";
                        position: absolute;
                        right: -0.4rem;
                        font-weight: normal;
                        font-size: smaller;
                    }

                    @media (${BREAKPOINTS.desktop}) {
                        font-size: 3.428rem;
                        min-width: 4.285rem;
                    }
                }

                .embedded {
                    @media (${BREAKPOINTS.desktop}) {
                        font-size: 2rem;
                        min-width: 3rem;
                        margin-bottom: 0.375rem;
                    }
                }
            }

            .period-name {
                font-size: 1rem;
                color: #708787;
                text-transform: capitalize;
                font-weight: 300;

                @media (${BREAKPOINTS.desktop}) {
                    font-size: 1.285rem;
                }
            }

            .period-name-embedded {
                @media (${BREAKPOINTS.desktop}) {
                    font-size: 1rem;
                }
            }

            &.minutes .countdown-timer {
                font-weight: 300;
            }

            &.seconds .countdown-timer {
                font-weight: 100;
            }

            &:last-child {
                .countdown-timer:after {
                    content: "";
                }
            }
        }
    }
`;

export interface ECountDownProps {
    time?: number;
    text?: string;
    endedText?: string;
    embedded?: boolean;
    className?: string;
    onCompleteHandler?: () => void;
    testId?: string;
}

const ECountDown: FC<React.PropsWithChildren<ECountDownProps>> = ({
    time = Date.now(),
    text = "",
    endedText = "",
    embedded = false,
    className = "",
    onCompleteHandler,
    testId = "count-down",
}) => {
    const [countdownApi, setCountdownApi] = useState<CountdownApi>();

    useEffect(() => {
        countdownApi?.start();
    }, []);

    const setCountdownRef = (countdown: Countdown | null): void => {
        if (countdown) {
            setCountdownApi(countdown.getApi());
        }
    };

    const countdownRender = ({
        days,
        hours,
        minutes,
        seconds,
        completed,
    }: {
        days: number;
        hours: number;
        minutes: number;
        seconds: number;
        completed: boolean;
    }) => {
        if (completed) {
            return (
                <h3 className="text-center py-3" data-testid={testId}>
                    {endedText}
                </h3>
            );
        } else {
            return (
                <CountDownContainer className={className} data-testid={testId}>
                    <div className="countdown-text">{text}</div>

                    <div className="countdown">
                        <span className="countdown-col">
                            <span className="countdown-col-element">
                                <span className={clsx(["countdown-timer", { embedded: embedded }])}>
                                    {NumberUtils.toCountdownString(days)}
                                </span>
                            </span>
                            <span className={clsx(["period-name", { "period-name-embedded": embedded }])}>Days</span>
                        </span>

                        <span className="countdown-col">
                            <span className="countdown-col-element">
                                <span className={clsx(["countdown-timer", { embedded: embedded }])}>
                                    {NumberUtils.toCountdownString(hours)}
                                </span>
                            </span>
                            <span className={clsx(["period-name", { "period-name-embedded": embedded }])}>Hours</span>
                        </span>

                        <span className="countdown-col minutes">
                            <span className="countdown-col-element">
                                <span className={clsx(["countdown-timer", { embedded: embedded }])}>
                                    {NumberUtils.toCountdownString(minutes)}
                                </span>
                            </span>
                            <span className={clsx(["period-name", { "period-name-embedded": embedded }])}>Minutes</span>
                        </span>

                        <span className="countdown-col seconds">
                            <span className="countdown-col-element">
                                <span className={clsx(["countdown-timer", { embedded: embedded }])}>
                                    {NumberUtils.toCountdownString(seconds)}
                                </span>
                            </span>
                            <span className={clsx(["period-name", { "period-name-embedded": embedded }])}>Seconds</span>
                        </span>
                    </div>
                </CountDownContainer>
            );
        }
    };

    return (
        <Countdown
            renderer={countdownRender}
            date={time}
            ref={setCountdownRef}
            onComplete={onCompleteHandler}
            autoStart
            data-testid={testId}
        />
    );
};
export default ECountDown;
