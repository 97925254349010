import React, { useEffect, useState } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCheck, faClose, faSearch, IconDefinition } from "@fortawesome/free-solid-svg-icons";
import { useNavigate, useParams } from "react-router-dom";
import { useMutation } from "@tanstack/react-query";
import AuthService, { EmailConfirmationDto } from "../../../services/AuthService";
import { routeNames } from "../../../routes/routes";
import { useService } from "../../../services/config/dependencyInjectorConfig";

interface SubmitDataMessageProps {
    type: "error" | "success" | "inProgress";
    message: string;
    icon: IconDefinition;
}

const AuthEmailConfirmation = () => {
    const [authService] = useService(AuthService);
    const navigate = useNavigate();
    const [submitDataMessage, setSubmitDataMessage] = useState<SubmitDataMessageProps>({
        type: "inProgress",
        message: "Email confirmation in progress ...",
        icon: faSearch,
    });
    const routeParams = useParams();

    const emailConfirmation = useMutation({
        mutationFn: (emailConfirmationDto: EmailConfirmationDto) => authService.confirmEmail(emailConfirmationDto),
        onSuccess: () => {
            setSubmitDataMessage({
                type: "success",
                message: "Email confirmation successful. You'll be redirect to login page ...",
                icon: faCheck,
            });
            setTimeout(() => {
                navigate(routeNames.authLogin);
            }, 5000);
        },
        onError: (error) => {
            setSubmitDataMessage({
                type: "error",
                message: "Email confirmation not successful. An error occurred.",
                icon: faClose,
            });
        },
    });

    useEffect(() => {
        if (!routeParams.hash) {
            setSubmitDataMessage({
                type: "error",
                message: "Email confirmation not successful. Please try again.",
                icon: faClose,
            });
        } else {
            emailConfirmation.mutate({ hash: routeParams.hash });
        }
    }, [routeParams]);

    return (
        <div className="d-flex flex-fill align-items-center container mt-5">
            <div className="row w-100">
                <div className="col-12 col-md-8 col-lg-5 mx-auto">
                    <div className="card shadow-sm rounded p-4 border-0">
                        <div className="card-body text-center d-flex flex-column justify-content-center">
                            <div className="dapp-icon icon-medium">
                                <FontAwesomeIcon icon={submitDataMessage.icon} className="mx-auto text-muted fa-3x" />
                            </div>
                            <span className="h4 empty-heading mt-3">{submitDataMessage.message}</span>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default AuthEmailConfirmation;
