import React, { memo } from "react";
import styled from "styled-components";
import { ESpinner } from "../Spinner";

const SpinnerContainer = styled.div`
    position: absolute;
    height: 100px;
    width: 100px;
    top: 50%;
    left: 50%;
    margin-left: -50px;
    margin-top: -50px;
    background-size: 100%;
`;

interface Overlay {
    loading: boolean;
}

const SpinnerOverlay: React.FC<Overlay> = ({ loading }) => {
    return (
        <>
            {loading && (
                <SpinnerContainer>
                    <ESpinner></ESpinner>
                </SpinnerContainer>
            )}
        </>
    );
};

export default memo(SpinnerOverlay);
