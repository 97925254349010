import React from "react";
import Switch, { ReactSwitchProps } from "react-switch";
import styled from "styled-components";

type ESwitchProps = ReactSwitchProps & { testId?: string };

const StyledSwitch = styled(Switch)`
    margin-right: 1rem;

    &.disabled {
        div.react-switch-bg {
            background-color: ${(props) => (props.checked ? "var(--entity-green-dark) !important" : "transparent !important")};
            border: 1px solid #bfbfbf;
        }

        div.react-switch-handle {
            background-color: #bfbfbf !important;
        }
    }
`;

const ESwitch = ({ onChange, disabled, checked, testId = "switch-button", ...props }: ESwitchProps) => (
    <StyledSwitch
        onChange={onChange}
        checked={checked}
        disabled={disabled}
        height={22}
        width={40}
        onColor="#83FF8C"
        onHandleColor="#206666"
        offColor="#206666"
        offHandleColor="#fff"
        data-testid={testId}
        className={disabled ? "disabled" : ""}
        {...props}
    />
);
export default ESwitch;
