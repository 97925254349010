import React, { FC, memo, useMemo } from "react";
import { ProjectTiersDetails } from "../launchpad.types";
import StaticTable, { StaticTableRows, TableVariant } from "../../../common/components/StaticTable/StaticTable";
import CurrencyNumber from "../../../components/Price/CurrencyNumber";
import { TicketPriceUtils } from "../../../common/utils/TicketPriceUtils";

export interface TiersTableContainerProps {
    tiersInfo: ProjectTiersDetails;
    includeTicketPriceDisclaimer?: boolean;
}

const TiersTableContainer: FC<React.PropsWithChildren<TiersTableContainerProps>> = ({
    tiersInfo,
    includeTicketPriceDisclaimer = true,
}) => {
    const getTiersTableHeaders = (): string[] => {
        return ["Tier", `${tiersInfo.fundingToken} Staked / Delegated`, "Max. Tickets / User", "Max. Amount / User"];
    };

    const getTiersTableRows = (): StaticTableRows => {
        if (!tiersInfo.tiers) return [];
        return tiersInfo.tiers.map((tier, index) => {
            return [
                `Tier ${tier.no}`,
                `>= ${tier.minStakedAmount} ${tiersInfo.fundingToken}`,
                tier.maxTicketsPerUser,
                <CurrencyNumber value={+tier.maxAmountPerUser} key={index} />,
            ];
        });
    };

    const ticketPrice = useMemo(() => {
        if (!tiersInfo || !tiersInfo.tiers) return;
        const price = TicketPriceUtils.getDollarAmount(tiersInfo);
        if (!price) return;
        return (
            <>
                <b>
                    <CurrencyNumber value={+price} />
                    {` (paid in ${tiersInfo.fundingToken})`}
                </b>
            </>
        );
    }, [tiersInfo]);

    return (
        <>
            <StaticTable headersTop={getTiersTableHeaders()} rows={getTiersTableRows()} variant={TableVariant.headerTop} />
            {includeTicketPriceDisclaimer && tiersInfo?.tiers && <p>Price per Ticket: {ticketPrice}</p>}
        </>
    );
};

export default memo(TiersTableContainer);
