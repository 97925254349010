import React, { memo } from "react";
import styled from "styled-components";
import { Cloud } from "../Cloud";
import { Image } from "react-bootstrap";
import hexagonBg from "../../assets/img/hexagons.svg";
import { BREAKPOINTS } from "../../assets/sass/breakpoints";

const FirstCloud = styled(Cloud)`
    top: 5rem;
    left: -4rem;
`;

const MiddleCloud = styled(Cloud)`
    right: 30%;
    top: -25%;
    z-index: 1;
`;

const LastCloud = styled(Cloud)`
    right: -4rem;
    top: 20rem;
`;

const Container = styled.div`
    --cloud-color: rgba(174, 116, 255, 0.31);
    position: absolute;
    top: 3.517rem;
    left: 0;
    width: 100%;
    z-index: -1;

    .hex-container {
        z-index: -1;

        img {
            position: relative;
            top: 1rem;
            right: 8rem;
            overflow: hidden;
            min-width: 900px;
        }

        @media (${BREAKPOINTS.desktop}) {
            img {
                top: 3rem;
                right: -30rem;
            }
        }
    }
`;

interface BackgroundDecorationsProps {
    className?: string;
    opacity?: number;
    showClouds?: boolean;
}

const BackgroundDecorations = ({ className = "", opacity, showClouds = true }: BackgroundDecorationsProps) => {
    return (
        <Container className={className}>
            {showClouds && <FirstCloud color="var(--cloud-color)" className="first-cloud" />}
            {showClouds && <MiddleCloud opacity={opacity} className="middle-cloud" />}
            <div className="hex-container">
                <Image fluid src={hexagonBg} />
            </div>
            {showClouds && <LastCloud color="var(--cloud-color)" className="last-cloud" />}
        </Container>
    );
};

export default memo(BackgroundDecorations);
