import React, { FC } from "react";
import { StaticTable } from "../../../../../common";
import { TableVariant } from "../../../../../common/components/StaticTable";
import { KeyMetrics as IKeyMetrics } from "../../../launchpad.types";
import { TBA } from "../../../../../helpers/consts";
import TotalSupply from "../../../../Ico/TotalSupply";
import { Currency, CurrencySymbol } from "../../../../../common/utils/CurrencyUtils";
import Number from "../../../../../components/Price/Number";

type KeyMetricsProps = { metrics?: IKeyMetrics };

const headersLeft = ["Token Name", "Token Type", "Initial Supply", "Circulating Supply", "Entity Launchpad Price"];

const KeyMetrics: FC<React.PropsWithChildren<KeyMetricsProps>> = ({ metrics }) => {
    const rows = metrics
        ? [
              [metrics.tokenName ?? TBA],
              [metrics.tokenType ?? TBA],
              [<TotalSupply maxTokenSupply={metrics.maxSupply} key={metrics.maxSupply} />],
              [
                  metrics.initialCirculatingSupply && metrics.tokenName ? (
                      <Number value={metrics.initialCirculatingSupply} suffix={metrics.tokenName} />
                  ) : (
                      TBA
                  ),
              ],
              [metrics.launchpadPrice ? <Number value={metrics.launchpadPrice} suffix={CurrencySymbol[Currency.USD]} /> : TBA],
          ]
        : undefined;

    return <StaticTable variant={TableVariant.headerLeft} headersLeft={headersLeft} rows={rows} />;
};

export default KeyMetrics;
