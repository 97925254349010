import { GeneralKeyMetrics } from "../../../Launchpad/launchpad.types";
import { DateUtils } from "../../../../common";
import ECountDown from "../../../../common/components/ECountDown/ECountDown";
import React, { memo } from "react";
import styled from "styled-components";

const QuarterInfo = styled.h3`
    margin-left: 1rem;
`;

interface ProjectHexagonContainerProps {
    generalMetrics: GeneralKeyMetrics;
    embedded?: boolean;
}

const ProjectHexagonContainer = ({ generalMetrics, embedded = false }: ProjectHexagonContainerProps) => {
    if (generalMetrics.claimTokensEndTimestamp && DateUtils.toMillisFormat(generalMetrics.claimTokensEndTimestamp) < Date.now()) {
        return <h3 className="text-center py-3">Public Sale Ended</h3>;
    }
    if (generalMetrics.kycStartTimestamp) {
        return <ECountDown time={generalMetrics.kycStartTimestamp} endedText="Public Sale Started" embedded={embedded} />;
    }
    if (!generalMetrics.kycStartTimestamp && generalMetrics.kycStartQuarter) {
        return <QuarterInfo>{generalMetrics.kycStartQuarter}</QuarterInfo>;
    }
    return <></>;
};

export default memo(ProjectHexagonContainer);
