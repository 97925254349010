import BaseService from "./BaseService";
import axios from "axios";
import { AppSetting, AppSettingsFilter, AppSettingsResponse } from "../pages/Admin/AppSettings/AppSettingsTypes";

export enum AppSettingsList {
    taxToolSyncOn = "TAX_TOOL_SYNC_ON",
    taxToolTxsGroupBatch = "TAX_TOOL_TXS_GROUP_BATCH",
    taxToolTxsPerBatch = "TAX_TOOL_TXS_PER_BATCH",
}

class AppSettingsService extends BaseService {
    private readonly _urlPrefix: string;

    constructor(entityApi: string) {
        super();
        this._urlPrefix = `${entityApi}/app-settings`;
    }

    getById(id: string): Promise<AppSettingsResponse> {
        return this.doCall(() => axios.get(`${this._urlPrefix}/${id}`));
    }

    retrieveAll(params: AppSettingsFilter): Promise<AppSettingsResponse> {
        return this.doCall(() => axios.get(this._urlPrefix, { params }));
    }

    retrieveTaxToolAppSettings(): Promise<AppSettingsResponse> {
        return this.doCall(() => axios.get(`${this._urlPrefix}/tax-tool`));
    }

    createAppSetting(appSettingDto: AppSetting): Promise<AppSetting> {
        return this.doCall(() => axios.post(this._urlPrefix, appSettingDto));
    }

    updateAppSetting(id: string, appSettingDto: AppSetting): Promise<AppSetting> {
        return this.doCall(() => axios.put(`${this._urlPrefix}/${id}`, appSettingDto));
    }

    deleteAppSetting(id: string): Promise<AppSetting> {
        return this.doCall(() => axios.delete(`${this._urlPrefix}/${id}`));
    }
}

export default AppSettingsService;
