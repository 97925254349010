import React from "react";
import ModuleDescription from "../../ModuleDescription";

const Portfolio = () => (
    <ModuleDescription
        title="Portfolio"
        description="The Portfolio module lets users monitor the performance of their crypto assets through a cohesive dashboard and user-friendly interface."
    />
);

export default Portfolio;
