import React from "react";
import styled, { css } from "styled-components";
import HexFooterSVG from "./HexFooterSVG";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faAngleUp } from "@fortawesome/free-solid-svg-icons";
import { routeNames } from "../../../routes/routes";
import { NavDropdown } from "react-bootstrap";
import { DateUtils } from "../../../common";
import preval from "preval.macro";
import { entitySocialList } from "../../../helpers/consts";
import SocialMediaButtons from "../../Buttons/SocialMediaButtons";
import { Link as HashLink } from "react-scroll";
import { Link } from "react-router-dom";
import { featureToggles } from "../../../common/utils/FeatureToggleUtils";
import NewsLetterSubscription from "../../NewsLetterSubscription/NewsLetterSubscription";

const buildDate = preval`module.exports = new Date().getTime()`;

const EFooter = styled.div`
    width: 100%;
    margin: 0 !important;
    font-size: 0.857rem;

    .hex-menu {
        margin-left: -8rem;
        width: calc(100% + 30rem);

        svg {
            margin-top: -7rem;
            path.hex,
            path.cloud {
                mix-blend-mode: soft-light;
            }
        }
    }
`;

const linkStyle = css`
    color: var(--entity-green);

    &:hover,
    &:focus {
        color: #fff;
    }
`;

const ENavLink = styled(Link)`
    ${linkStyle};
`;

const ENavHashLink = styled(HashLink)`
    ${linkStyle};
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-item: center;
    color: var(--entity-green);
    pointer-events: auto;
    cursor: pointer;
`;

const BackMenu = () => {
    return (
        <ENavHashLink
            to={featureToggles.showBanner ? "offcanvasBannerEnt" : "offcanvasNavbarEnt"}
            spy
            smooth
            offset={0}
            duration={500}
        >
            <FontAwesomeIcon size={"3x"} icon={faAngleUp} />
            <span className="text-center">Back to top</span>
        </ENavHashLink>
    );
};

const menuItems = () => [
    { title: "Home", to: routeNames.home, testId: "home-nav-item" },
    { title: "Contact", to: routeNames.contact, testId: "contact-nav-item" },
];

const footerItems = () => [
    { title: "Imprint", to: routeNames.imprint, testId: "imprint-nav-item" },
    { title: "Privacy", to: routeNames.privacyPolicy, testId: "privacy-nav-item" },
    { title: "Disclaimer", to: routeNames.disclaimer, testId: "disclaimer-nav-item" },
    { title: "Terms of Use", to: routeNames.termsAndConditions, testId: "terms-of-use-nav-item" },
];

const Menu = () => {
    return (
        <div className="d-flex">
            {menuItems().map((menuItem, index) => (
                <NavDropdown.Item key={`submenu-item-${index}`} as={ENavLink} to={menuItem.to} data-testid={menuItem.testId}>
                    {menuItem.title}
                </NavDropdown.Item>
            ))}
        </div>
    );
};

const BuildInfo = styled.div`
    span,
    a {
        color: #879798;
        margin-bottom: 0.357rem;
    }
`;

const EBuildInfo = () => {
    return (
        <BuildInfo className="d-flex flex-column align-items-center justify-content-center">
            <span className="text-center">
                v{process.env.REACT_APP_VERSION} | Built on {DateUtils.toFormat(buildDate)}
            </span>
        </BuildInfo>
    );
};

const menuCss = css`
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding-left: 2rem;
    padding-right: 2rem;
    width: 100%;
    height: fit-content;
    position: absolute;
    left: 0;
    bottom: 0;
    z-index: 1;
`;

const SocialMedia = styled.div`
    ${menuCss};
    margin-bottom: 3rem;

    a {
        font-size: 1.786rem;
    }
`;

const FooterMenu = styled.div`
    ${menuCss};
    margin-bottom: 30%;
    font-size: 1rem;
    color: #879798;

    a:hover {
        color: var(--entity-green);
    }
`;

const MobileFooter = ({ className }: { className?: string }) => {
    return (
        <EFooter id="mobile-footer" className={className}>
            <div className="d-flex flex-column align-items-center position-relative">
                {location.pathname !== routeNames.newsletter && <NewsLetterSubscription />}
                <div className="hex-menu">
                    <HexFooterSVG backMenu={<BackMenu />} menu={<Menu />} buildInfo={<EBuildInfo />} />
                </div>
                <div className="d-flex justify-content-center"></div>
                <FooterMenu>
                    {footerItems().map(({ title, to, testId }, index) => (
                        <NavDropdown.Item key={`footer-item-${index}`} as={Link} to={to} data-testid={testId}>
                            {title}
                        </NavDropdown.Item>
                    ))}
                </FooterMenu>
                <SocialMedia>
                    <SocialMediaButtons buttons={entitySocialList} />
                </SocialMedia>
            </div>
        </EFooter>
    );
};

export default MobileFooter;
