export enum Orientation {
    FLAT = "FLAT",
    POINTY = "POINTY",
}

export interface CubeCoordinates {
    q: number;
    r: number;
    s: number;
}

export interface BoundingBox {
    width: number;
    height: number;
}

export type Dimensions = {
    width?: string | number;
    height?: string | number;
    xOffset?: string | number;
    yOffset?: string | number;
};

export interface Ellipse {
    xRadius: number;
    yRadius: number;
}

export interface HexSettings {
    dimensions: BoundingBox | Ellipse;
    orientation: Orientation;
    origin: Origin;
    offset: number;
}

export type TopLeft = "topLeft";
export type Origin = Point | TopLeft;

export interface Point {
    x: number;
    y: number;
}

export enum Corner {
    f0,
    f1,
    f2,
    f3,
    f4,
    f5,
}
