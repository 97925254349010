import React, { FC, memo } from "react";
import { BaseModalProps } from "../../common/types/common.types";
import EModal from "../../components/common/EModal";
import { EButton } from "../../components/Buttons";

interface KycRejectedReasonsModalProps extends BaseModalProps {
    reasons: string[] | null;
}

export const KycRejectedReasonsModal: FC<React.PropsWithChildren<KycRejectedReasonsModalProps>> = ({
    reasons,
    show,
    closeModalHandler,
}) => {
    return (
        <EModal show={show} onHide={closeModalHandler} centered>
            <EModal.Header closeButton>
                <EModal.Title>
                    <h2>KYC rejected reasons</h2>
                </EModal.Title>
            </EModal.Header>
            <EModal.Body>
                <ul>
                    {reasons?.map((reason, index) => (
                        <li key={index}>{reason}</li>
                    ))}
                </ul>
            </EModal.Body>
            <EModal.Footer>
                <EButton onClick={closeModalHandler} testId="cancel-btn">
                    Close
                </EButton>
            </EModal.Footer>
        </EModal>
    );
};

export default memo(KycRejectedReasonsModal);
