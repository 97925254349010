import React, { FC } from "react";
import { backgroundColor, borderColor, doughnutOptions } from "../../helpers/consts";
import styled from "styled-components";
import "chart.js/auto";
import { Doughnut } from "react-chartjs-2";

export interface ChartData {
    labels: string[];
    metrics: (string | number)[];
}

export interface DoughnutChartProps {
    chartData: ChartData;
    numberFormatStyle?: "currency" | "percent" | "value";
    displayLegend?: boolean;
    maintainAspectRatio?: boolean;
    className?: string;
}

const Container = styled.div`
    height: 20.8rem;
    width: 20.8rem;
`;

const getChartData = ({ labels, metrics }: ChartData) => {
    return {
        labels,
        datasets: [
            {
                data: metrics,
                backgroundColor: backgroundColor,
                borderColor: borderColor,
                borderWidth: 1,
            },
        ],
    };
};

const DoughnutChart: FC<React.PropsWithChildren<DoughnutChartProps>> = ({
    chartData,
    numberFormatStyle = "currency",
    displayLegend = false,
    maintainAspectRatio = false,
    className = "",
    children,
}) => {
    return (
        <Container className={`${className} position-relative`}>
            <Doughnut
                data={getChartData(chartData)}
                options={doughnutOptions(numberFormatStyle, maintainAspectRatio, displayLegend)}
                className="position-relative"
            />
            {children}
        </Container>
    );
};

export default DoughnutChart;
