import BaseService from "./BaseService";
import axios from "axios";
import ImageUtils from "../common/utils/ImageUtils";

export enum ImageType {
    ico = "ico",
    keyInvestors = "keyInvestors",
    projectTeamMembers = "projectTeamMembers",
}

class FileService extends BaseService {
    private readonly _urlPrefix: string;

    constructor(entityApi: string) {
        super();
        this._urlPrefix = `${entityApi}/files`;
    }

    async uploadEncodedImage(encodedImage: string, type: ImageType): Promise<string> {
        const image = await ImageUtils.toFile(encodedImage);
        const formData = new FormData();
        formData.append("image", image);
        formData.append("type", type);
        return this.doCall(() => axios.post<string>(`${this._urlPrefix}/upload-image`, formData));
    }

    async deleteFile(fileName: string): Promise<void> {
        return this.doCall(() => axios.delete(`${this._urlPrefix}/${fileName}`));
    }
}

export default FileService;
