import React from "react";
import ModuleDescription from "../../ModuleDescription";

const ProjectMonitor = () => (
    <ModuleDescription
        title="Project Monitor"
        description="The Project Monitor module is a hub for investors to discover new MultiversX projects with key information, expert analysis, and transparent ratings."
    />
);

export default ProjectMonitor;
