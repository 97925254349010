import React, { PropsWithChildren } from "react";
import { FormGroupProps } from "./types";
import { EFormSelect, ESelect } from "./../../common";
import FormControlledInputGroup, { FormControlGroupProps } from "./FormControlledInputGroup";
import { ESelectProps } from "../components/ESelect/ESelect";
import { Form, FormText } from "react-bootstrap";
import EFormLabel from "./EFormLabel";
import clsx from "clsx";

export enum SelectVariant {
    Default,
    Custom,
}
interface FormSelectGroupProps<V, T>
    extends FormGroupProps<V>,
        Pick<FormControlGroupProps<V>, "control">,
        Omit<ESelectProps<T>, "onChange" | "name"> {
    parentClassName?: string;
    variant?: SelectVariant;
}

function FormSelectGroup<V, T>(props: PropsWithChildren<FormSelectGroupProps<V, T>>): JSX.Element {
    const {
        name,
        label,
        displayKey,
        formatOptionLabel,
        getOptionLabel,
        getOptionValue,
        onInputChange,
        isClearable,
        isSearchable,
        options = [],
        parentClassName = undefined,
        className = undefined,
        defaultValue,
        disabled,
        control,
        register,
        formState: { errors, ...formStateProps },
        children,
        variant = SelectVariant.Default,
        registerOptions,
        required = false,
    } = props;

    return (
        <>
            {variant === SelectVariant.Default && (
                <Form.Group className={`mb-2 ${parentClassName}`} controlId={`controlSelectGroup-${name}`}>
                    <EFormLabel className={clsx([{ required }])}>{label}</EFormLabel>
                    <EFormSelect
                        {...register(name, {
                            ...registerOptions,
                        })}
                        defaultValue={defaultValue}
                        disabled={disabled}
                        className={className}
                    >
                        {children}
                    </EFormSelect>
                    {errors[name]?.type && <FormText className="form-error">{errors[name]?.message as string}</FormText>}
                </Form.Group>
            )}
            {variant === SelectVariant.Custom && (
                <FormControlledInputGroup
                    name={name}
                    label={label}
                    register={register}
                    control={control}
                    formState={{ errors, ...formStateProps }}
                    disabled={disabled}
                    required={required}
                    className={`flex-column free-text-controller ${parentClassName}`}
                    render={({ onChange, value, ref }) => {
                        return (
                            <div ref={ref}>
                                <ESelect
                                    displayKey={displayKey}
                                    options={options}
                                    defaultValue={defaultValue}
                                    value={value}
                                    onChange={onChange}
                                    onInputChange={onInputChange}
                                    formatOptionLabel={formatOptionLabel}
                                    getOptionLabel={getOptionLabel}
                                    getOptionValue={getOptionValue}
                                    isDisabled={disabled}
                                    isSearchable={isSearchable}
                                    isClearable={isClearable}
                                    hideSelectedOptions={false}
                                />
                            </div>
                        );
                    }}
                />
            )}
        </>
    );
}

export default FormSelectGroup;
