import React from "react";
import ModuleDescription from "../../ModuleDescription";

const RewardStrategies = () => (
    <ModuleDescription
        title="Reward Strategies"
        description="The Reward Strategies module helps users identify their ideal investment strategy based on their own custom criteria."
    />
);

export default RewardStrategies;
