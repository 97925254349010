import React, { memo } from "react";
import styled from "styled-components";
import { Col } from "react-bootstrap";
import { InvestorsHexagon, LinkInfoType } from "./Investors";
import Link from "../../../components/Buttons/Link";
import OverlayTriggerTooltip from "../../../helpers/OverlayTriggerTooltip";
import InvestorsHexContainer from "./InvestorsHexContainer";
import { investorsSvgHeight } from "./helper/consts";
import { BREAKPOINTS } from "../../../assets/sass/breakpoints";

const MainContent = styled.div`
    margin-top: -${(investorsSvgHeight / 2) * 0.071}rem;
    position: relative;
`;

export interface MainContentProps {
    activeModule: number[];
    hexagons: InvestorsHexagon[];
    detailedText: JSX.Element | undefined;
    moduleLinkInfo: LinkInfoType | undefined;
}

const Details = styled(Col)`
    margin-top: -4rem;
    margin-bottom: ${(investorsSvgHeight / 3) * 0.071}rem;
    position: relative;
    @media (${BREAKPOINTS.tablet}) {
        margin-top: 2rem;
    }
`;

const OverlayTooltip = styled(OverlayTriggerTooltip)`
    width: fit-content;
`;

const InvestorsMainContent = ({ activeModule, hexagons, detailedText, moduleLinkInfo }: MainContentProps) => {
    const getButton = (): JSX.Element => {
        if (!moduleLinkInfo) {
            return <></>;
        }
        if (Array.isArray(moduleLinkInfo)) {
            return (
                <div className="d-flex flex-column flex-md-row align-items-center">
                    <Link to={moduleLinkInfo[0].to} testId="module-link">
                        {moduleLinkInfo[0].text}
                    </Link>
                    <Link to={moduleLinkInfo[1].to} testId="module-link" className="mt-2 ms-md-4 mt-md-0">
                        {moduleLinkInfo[1].text}
                    </Link>
                </div>
            );
        }
        if (moduleLinkInfo.isAvailable) {
            return (
                <Link to={moduleLinkInfo.to} testId="module-link">
                    {moduleLinkInfo.text}
                </Link>
            );
        }
        return (
            <OverlayTooltip placement="auto">
                <Link to="" testId="module-link">
                    {moduleLinkInfo.text}
                </Link>
            </OverlayTooltip>
        );
    };

    return (
        <MainContent className="d-flex flex-column flex-lg-row justify-content-start p-0 mt-5">
            {activeModule && (
                <>
                    <InvestorsHexContainer hexagons={hexagons} />
                </>
            )}
            <Details className="d-flex flex-column align-self-end align-items-center align-items-md-start justify-content-end ms-md-2 p-2 p-md-0">
                {detailedText && detailedText}
                {moduleLinkInfo && getButton()}
            </Details>
        </MainContent>
    );
};

export default memo(InvestorsMainContent);
