import React, { FC, useEffect, useState } from "react";
import { LaunchpadCardBaseProps, RetrieveTicketsInfoFn } from "../launchpad.types";
import { faGem } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { useGetAccountInfo, useGetLoginInfo, useGetSignedTransactions } from "@multiversx/sdk-dapp/hooks";
import { sendTransactions } from "@multiversx/sdk-dapp/services";
import styled from "styled-components";
import { EButton, ELinkButton } from "../../../components/Buttons";
import { useService } from "../../../services/config/dependencyInjectorConfig";
import TicketsService, { Tickets, TicketsScope } from "../../../services/TicketsService";
import clsx from "clsx";
import SessionStorageService from "../../../services/sessionStorageService/SessionStorageService";
import { routeNames } from "../../../routes/routes";
import { DateUtils } from "../../../common";
import { LaunchpadScConfig, LaunchpadService } from "../../../services/LaunchpadService";
import AlertService from "../../../services/AlertService";
import { Alert } from "react-bootstrap";
import EventsService, { EventsNames } from "../../../services/EventsService";
import ECountDownFooter from "../../../common/components/ECountDown/ECountDownFooter";
import { launchpadSC } from "../../../config";
import errorToast from "../../../components/Error/errorToast";
import ESpinner from "../../../components/Spinner/ESpinner";
import UtilsService from "../../../services/UtilsService";
import checkSignedTransaction from "../../../common/hooks/checkSignedTransaction";
import { SignedTransactionsBodyType } from "@multiversx/sdk-dapp/types";
import TxUtils from "../../../common/utils/TxUtils";

const TicketWrapper = styled.div`
    display: flex;
    align-items: center;
    justify-content: center;
    margin-bottom: 2rem;
    flex-wrap: wrap;
`;

const Ticket = styled.div`
    border: 1px solid var(--light-gray);
    border-radius: 0.3rem;
    color: var(--light-gray);
    padding: 0.4rem 1rem;
    margin: 0.3rem;

    &.won {
        border: 1px solid var(--entity-green);
        color: #000;
        background-color: var(--entity-green);
    }
`;

interface ClaimTokensCardProps extends LaunchpadCardBaseProps {
    ticketsInfo: Tickets;
    retrieveTicketsInfo?: RetrieveTicketsInfoFn;
    scConfig?: LaunchpadScConfig;
    scShard?: number;
    testId?: string;
}

const ClaimTokensCard: FC<React.PropsWithChildren<ClaimTokensCardProps>> = ({
    projectDetails,
    ticketsInfo,
    retrieveTicketsInfo,
    isRejected,
    scConfig,
    scShard,
    testId = "claim-tokens-card",
}) => {
    const [started, setStarted] = useState<boolean | undefined>(undefined);
    const [finished, setFinished] = useState<boolean | undefined>(undefined);

    const { isLoggedIn } = useGetLoginInfo();
    const { account } = useGetAccountInfo();
    const [utilsService] = useService(UtilsService);
    const signedTransactionsState = useGetSignedTransactions();

    const [ticketsService] = useService(TicketsService);
    const [launchpadService] = useService(LaunchpadService);
    const [eventsService] = useService(EventsService);

    useEffect(() => {
        if (!projectDetails) {
            return;
        }

        const [hasStarted, hasFinished] = DateUtils.getPeriodStatusFlags(
            projectDetails.generalMetrics.claimTokensStartTimestamp,
            projectDetails.generalMetrics.claimTokensEndTimestamp,
        );
        setStarted(hasStarted);
        setFinished(hasFinished);
    }, []);

    useEffect(() => {
        if (!projectDetails) {
            return;
        }
        checkSignedTransaction(signedTransactionsState, {
            afterSignedCallback: async (txSigned: SignedTransactionsBodyType) => {
                if (txSigned.transactions && txSigned.transactions[0]) {
                    await launchpadService.registerTx(txSigned.transactions[0].hash, Date.now(), projectDetails.id);
                }
            },
            onSuccessCallback: async () => retrieveTicketsInfo && (await retrieveTicketsInfo(TicketsScope.CLAIM_TOKENS)),
        });
    }, [signedTransactionsState]);

    const onCompleteStartHandler = () => {
        setStarted(true);
        eventsService.emitWithDelay(EventsNames.RERENDER_LAUNCHPAD);
    };

    const onClaimTokensHandler = async () => {
        if (!scConfig || scShard === undefined) {
            console.error("SC config and shard are required");
            errorToast();
            return;
        }
        const lastBlockNo = await utilsService.getLastBlockNo(scShard);
        const isInClaimEpoch = lastBlockNo >= scConfig.claimStartBlock;
        if (isInClaimEpoch) {
            await claimTokens();
        } else {
            AlertService.warning("Claiming tickets is not enabled yet. Come back later.");
        }
    };

    const claimTokens = async () => {
        if (!projectDetails) {
            return;
        }

        const tx = await ticketsService.getClaimTokensTx(projectDetails.id);
        if (tx.receiver !== launchpadSC) {
            return (
                <Alert variant="info" className="mt-4 mb-0">
                    The SC for buying tickets it is incorrect. Please contact admins!
                </Alert>
            );
        }
        const { sessionId } = await sendTransactions({
            transactions: [TxUtils.checkAccountAndAddGuard(tx, account)],
        });
        if (sessionId) {
            SessionStorageService.addSessionId(sessionId);
        }
    };

    const getClaimDescription = () => {
        if (ticketsInfo.winningTicketsIds.length > 0) {
            return (
                <>
                    <h3>Congratulations</h3>
                    <p>
                        You won <strong>{ticketsInfo.winningTicketsIds.length} tickets</strong> out of{" "}
                        {ticketsInfo.numConfirmedTickets}.
                    </p>
                    <TicketWrapper>
                        {ticketsInfo.ticketEntries.map((ticketId) => {
                            const isWonTicket = ticketsInfo.winningTicketsIds.includes(ticketId);
                            return (
                                <Ticket key={ticketId} className={clsx([{ won: isWonTicket }])}>
                                    {ticketId}
                                </Ticket>
                            );
                        })}
                    </TicketWrapper>
                    {started && !ticketsInfo.hasUserClaimedTokens && (
                        <EButton className="mx-auto" onClick={onClaimTokensHandler}>
                            Claim Tokens
                        </EButton>
                    )}
                </>
            );
        }
        if (ticketsInfo.winningTicketsIds.length === 0 && !ticketsInfo.hasUserClaimedTokens) {
            return (
                <>
                    <h3>Sorry</h3>
                    <p>You did not win any tickets.</p>
                    <TicketWrapper>
                        {ticketsInfo.ticketEntries.map((ticketId) => (
                            <Ticket key={ticketId}>{ticketId}</Ticket>
                        ))}
                    </TicketWrapper>
                    {started && !ticketsInfo.hasUserClaimedTokens && (
                        <EButton className="mx-auto" onClick={onClaimTokensHandler}>
                            Claim Tokens
                        </EButton>
                    )}
                </>
            );
        }
        if (ticketsInfo.hasUserClaimedTokens) {
            return (
                <>
                    <h3>Congratulations</h3>
                    <p className="text-green">You already claimed your tokens.</p>
                </>
            );
        }
        if (ticketsInfo.isUserBlacklisted) {
            return (
                <>
                    <h3>Sorry</h3>
                    <p>You can not claim your tokens because you are blacklisted.</p>
                </>
            );
        }
    };

    if (started === undefined && finished === undefined) {
        return <ESpinner />;
    }

    if (!isLoggedIn) {
        return (
            <>
                <div className="d-flex justify-content-center align-items-center" data-testid={testId}>
                    <ELinkButton to={routeNames.unlock} testId="login-btn" className="text-uppercase">
                        Login
                    </ELinkButton>
                </div>
                <div className="text-center pt-1 pb-3" data-testid={testId}>
                    <ECountDownFooter
                        started={started}
                        finished={finished}
                        startTimestamp={projectDetails?.generalMetrics?.claimTokensStartTimestamp}
                        endTimestamp={projectDetails?.generalMetrics?.claimTokensEndTimestamp}
                        onCompleteStartHandler={onCompleteStartHandler}
                        onCompleteEndHandler={() => eventsService.emitWithDelay(EventsNames.RERENDER_LAUNCHPAD)}
                    />
                </div>
            </>
        );
    }

    return (
        <div className="text-center pt-1 pb-3" data-testid={testId}>
            <FontAwesomeIcon size={"3x"} className="text-green mb-3" icon={faGem} />
            {isRejected && <p className="text-warning">You are not eligible to claim tokens.</p>}
            {!isRejected && getClaimDescription()}
            <ECountDownFooter
                started={started}
                finished={finished}
                startTimestamp={projectDetails?.generalMetrics?.claimTokensStartTimestamp}
                endTimestamp={projectDetails?.generalMetrics?.claimTokensEndTimestamp}
                onCompleteStartHandler={onCompleteStartHandler}
                onCompleteEndHandler={() => eventsService.emitWithDelay(EventsNames.RERENDER_LAUNCHPAD)}
            />
        </div>
    );
};

export default ClaimTokensCard;
