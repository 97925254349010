import BaseService from "./BaseService";
import axios from "axios";
import { TaxToolFarms, TaxToolFarmsFilter, TaxToolFarmsResponse } from "../pages/Admin/TaxTool/TaxToolFarmsTypes";

class TaxToolFarmsService extends BaseService {
    private readonly _urlPrefix: string;

    constructor(entityApi: string) {
        super();
        this._urlPrefix = `${entityApi}/tax-tool-farms`;
    }

    retrieveAll(params: TaxToolFarmsFilter): Promise<TaxToolFarmsResponse> {
        return this.doCall(() => axios.get(this._urlPrefix, { params }));
    }

    createTaxToolFarm(taxToolFarm: TaxToolFarms): Promise<TaxToolFarms> {
        return this.doCall(() => axios.post(this._urlPrefix, taxToolFarm));
    }

    deleteTaxToolFarm(id: string): Promise<TaxToolFarms> {
        return this.doCall(() => axios.delete(`${this._urlPrefix}/${id}`));
    }
}

export default TaxToolFarmsService;
