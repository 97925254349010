import React, { useState } from "react";
import { BackgroundDecorations } from "../../../components/BackgroundDecorations";
import { Card, Container, Form } from "react-bootstrap";
import styles from "../../UnlockPage/login.module.scss";
import { useForm } from "react-hook-form";
import { FormControlGroup, SettingsButton, useYupValidationResolver } from "../../../common";
import * as yup from "yup";
import ValidationSchemaUtils from "../../../common/utils/ValidationSchemaUtils";
import Col from "react-bootstrap/Col";
import styled from "styled-components";
import { frostyEffect } from "../../../assets/sass/common-effects";
import { BORDER_RADIUS } from "../../../components/StepsSlider/StepsSlider";
import { BREAKPOINTS } from "../../../assets/sass/breakpoints";
import Row from "react-bootstrap/Row";
import { routeNames } from "../../../routes/routes";
import { Link, useNavigate } from "react-router-dom";
import { useService } from "../../../services/config/dependencyInjectorConfig";
import AuthService, { AuthDataDto } from "../../../services/AuthService";
import { useMutation } from "@tanstack/react-query";
import AuthSubmitDataMessage from "./AuthSubmitDataMessage";

const getValidationSchema = () =>
    yup.object({
        email: ValidationSchemaUtils.email,
        password: ValidationSchemaUtils.password,
    });

export interface LoginData {
    email: string;
    password: string;
}

const LoginCustomCard = styled(Card)`
    ${frostyEffect};
    color: white;
    border-radius: ${BORDER_RADIUS};
    border-width: 0;
    padding-bottom: 0.429rem;
    justify-content: center;

    @media (${BREAKPOINTS.tablet}) {
        width: 400px;
        padding-bottom: 1.571rem;
    }

    @media (${BREAKPOINTS.phone}) {
        width: 300px;
        padding-bottom: 1.571rem;
    }
`;

export interface SubmitData {
    type: "success" | "error";
    message: string;
}

const AuthLogin = () => {
    const [submitDataMessage, setSubmitDataMessage] = useState<SubmitData | null>(null);
    const [authService] = useService(AuthService);
    const navigate = useNavigate();

    const validationResolver = useYupValidationResolver(getValidationSchema());
    const { register, handleSubmit, formState } = useForm<LoginData>({
        resolver: validationResolver,
    });

    const authAccount = useMutation({
        mutationFn: (authAccountDto: AuthDataDto) => authService.authenticateAccount(authAccountDto),
        onSuccess: () => {
            navigate(routeNames.launchpad);
        },
        onError: (error) => {
            setSubmitDataMessage({ type: "error", message: "An error occurred." });
        },
    });

    const onDetailsChangeHandler = (data: LoginData) => {
        authAccount.mutate({ email: data.email, password: data.password });
    };

    return (
        <div className="mt-5">
            <BackgroundDecorations />
            <Container className={styles.unlock}>
                <LoginCustomCard>
                    <div className="container">
                        <h2 className="text-center">Authenticate</h2>
                        <AuthSubmitDataMessage submitDataMessage={submitDataMessage} />
                        <Form className="mt-2 d-flex flex-column" onSubmit={handleSubmit(onDetailsChangeHandler)}>
                            <Row>
                                <Col xs={12} md={12}>
                                    <FormControlGroup
                                        name="email"
                                        label="Email:"
                                        register={register}
                                        formState={formState}
                                        required
                                    />
                                    <FormControlGroup
                                        name="password"
                                        label="Password:"
                                        type="password"
                                        register={register}
                                        formState={formState}
                                        required
                                    />
                                </Col>
                            </Row>
                            <SettingsButton className="mt-4 w-100" type="submit" testId="set-details">
                                Login
                            </SettingsButton>
                        </Form>
                        <div className="mt-4 text-center">
                            <p>
                                You don't have an account ?{" "}
                                <Link to={routeNames.authRegister} className="link-success">
                                    Register here
                                </Link>
                                .
                            </p>
                        </div>
                    </div>
                </LoginCustomCard>
            </Container>
        </div>
    );
};

export default AuthLogin;
