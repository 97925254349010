import BigNumber from "bignumber.js";

export class NumberUtils {
    private static readonly DENOMINATE_BIG_INT = BigInt(Math.pow(10, 18));
    private static readonly DENOMINATE_BIG_NUMBER = new BigNumber(Math.pow(10, 18));
    private static readonly ONE_DAY_IN_SECS = 86400;

    static denominate(value: any): number {
        return Number(value.valueOf() / NumberUtils.DENOMINATE_BIG_INT);
    }

    static denominateBigNumber(value: BigNumber): BigNumber {
        return value.dividedBy(NumberUtils.DENOMINATE_BIG_NUMBER);
    }

    static toDenominate(value: any): BigNumber {
        return value.multipliedBy(NumberUtils.DENOMINATE_BIG_NUMBER);
    }

    static daysToSeconds(days: number): number {
        return days * NumberUtils.ONE_DAY_IN_SECS;
    }

    static secondsToDays(seconds: number): string {
        return (seconds / NumberUtils.ONE_DAY_IN_SECS).toFixed();
    }

    static denominateString(value: string): number {
        return NumberUtils.denominate(BigInt(value));
    }

    static denominateBigNumberString(value: string): BigNumber {
        return NumberUtils.denominateBigNumber(new BigNumber(value));
    }

    static toDenominatedString(amount: number | string, decimals = 18): string {
        if (!amount) {
            amount = "0";
        }

        let denominatedValue = new BigNumber(amount.toString()).shiftedBy(-decimals).toFixed(decimals);
        if (denominatedValue.includes(".")) {
            denominatedValue = denominatedValue.replace(/0+$/g, "").replace(/\.$/g, "");
        }

        return denominatedValue;
    }

    static toCountdownString(value: number): string {
        const str = String(value);
        return str.length === 1 ? "0" + value : str;
    }

    static numberDecode(encoded: string): string {
        const hex = Buffer.from(encoded, "base64").toString("hex");
        return new BigNumber(hex, 16).toString(10);
    }

    static getPercentage(value: number | string, toFixed: number | undefined = 2): number {
        return +(+value * 100).toFixed(toFixed);
    }

    static numberToDenominatedNumber(amount: number | string): BigNumber {
        if (!amount) {
            return new BigNumber(0);
        }
        const additionalZeros = "000000000000000000";
        let amountStr = amount.toString();
        amountStr = amountStr.indexOf(".") > 0 ? amountStr.replace(".", additionalZeros + ".") : amountStr + additionalZeros;

        return new BigNumber(amountStr);
    }

    static toNumber(value: string | number | null): number | null {
        return !!value ? Number(value) : null;
    }

    static toFraction(value?: number | string, fractionDigits = 2) {
        return Number(value ?? 0).toLocaleString("US", {
            minimumFractionDigits: fractionDigits,
            maximumFractionDigits: fractionDigits,
        });
    }

    static toNonUSA(value?: number | string, fractionDigits = 2) {
        return Number(value ?? 0).toLocaleString("de-DE", {
            minimumFractionDigits: fractionDigits,
            maximumFractionDigits: fractionDigits,
        });
    }

    static toTimestamp(value?: string): number {
        return value ? +value / 1_000 : 0;
    }
}
