import React, { FC } from "react";
import styled from "styled-components";
import { Card, CardProps } from "react-bootstrap";

const Container = styled(Card)`
    background: var(--page-block-background-color);
    border-radius: 10px;
    box-shadow: 5px 5px 15px #00000029;
    padding: 1rem 1.5rem;

    .card-body {
        color: #fff;
    }

    .card-title {
        font-size: 2rem;
        font-weight: 400 !important;
        color: var(--primary-text);
    }

    .card-text {
        color: var(--card-text);
        font-size: 1.071rem;
        line-height: 2rem;
        position: relative;
    }

    .text-info-card {
        color: var(--default-text);
        font-size: 0.93rem;
        font-weight: 300;
    }
`;

const ECard: FC<React.PropsWithChildren<CardProps & { title?: string; testId: string }>> = ({
    children,
    className = "",
    testId,
}) => {
    return (
        <Container className={className} data-testid={testId}>
            {children}
        </Container>
    );
};

export default ECard;
