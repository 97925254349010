import React from "react";
import ModuleDescription from "../../ModuleDescription";

const Launchpad = () => (
    <ModuleDescription
        title="Launchpad"
        description="The Entity Launchpad module spotlights the best new projects building on MultiversX and gives users the opportunity to make rewarding early-stage investments."
    />
);

export default Launchpad;
