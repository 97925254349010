import React, { FC } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import styled from "styled-components";
import { SocialMediaTypes, socialMediaInfo } from "../../helpers/consts";
import { SizeProp } from "@fortawesome/fontawesome-svg-core";

type Link = { link: string };

type CustomSMBtn = {
    icon: JSX.Element;
} & Link;

export type CommonSMBtn = {
    icon: SocialMediaTypes;
    size?: SizeProp;
} & Link;

export type SocialMediaButton = CustomSMBtn | CommonSMBtn;

interface Props {
    color?: string;
    buttons: SocialMediaButton[];
    target?: string;
    className?: string;
}

const StyledLink = styled.a`
    && {
        color: ${(props: Required<Pick<Props, "color">>): string => props.color} !important;
        font-size: 20px;
        padding: 12px;

        &.disabled,
        &.disabled:hover {
            color: #708787 !important;
            display: none !important;
        }

        &:hover {
            color: var(--entity-green) !important;
        }

        &:first-child {
            padding-left: 0 !important;
        }

        &:last-child {
            padding-right: 0 !important;
        }
    }
`;

const SocialMediaButtons: FC<React.PropsWithChildren<Props>> = ({
    buttons,
    color = "var(--entity-gray)",
    target = "_blank",
    className = "",
    ...props
}) => (
    <>
        {buttons.map((btn, index) => (
            <StyledLink
                target={target}
                href={btn.icon === SocialMediaTypes.email ? `mailto:${btn.link}` : btn.link}
                key={index}
                color={color}
                className={`d-inline-flex ${className} ${!btn.link ? "disabled" : ""}`}
                {...props}
            >
                {typeof btn.icon === "string" && btn.icon in SocialMediaTypes ? (
                    <FontAwesomeIcon
                        icon={socialMediaInfo[btn.icon].icon}
                        size={(btn as CommonSMBtn).size}
                        data-testid={socialMediaInfo[btn.icon].testId}
                    />
                ) : (
                    btn.icon
                )}
            </StyledLink>
        ))}
    </>
);

export default SocialMediaButtons;
