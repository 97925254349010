import { css } from "styled-components";
import { dangerStyles, eButtonStyles } from "../../components/Buttons/Button";

const btnWidth = css`
    width: 100%;
`;

const dappUIStyles = css`
    .dapp-core-component__dappModalStyles__dappModal {
        padding-top: 0;
    }

    div[class*="dapp-core-component__dappModalStyles__dappModalContent"] {
        border: none;
    }

    div[class*="dapp-core-component__dappModalStyles__dappModalHeader"] {
        font-size: 1.8rem;
        font-weight: 400 !important;
        color: var(--primary-text);
        line-height: 2.571rem;
        padding: 0.5rem;
        border: none;
    }

    .dapp-core-component__main__text-secondary {
        color: #adadad;
    }

    .dapp-core-component__signWithDeviceModalStyles__wallet-connect
        .dapp-core-component__main__btn.dapp-core-component__main__btn-primary.dapp-core-component__main__d-flex.dapp-core-component__main__ml-2 {
        margin-right: 0;
    }

    .dapp-core-component__dappModalStyles__dappModalCloseButton.dapp-core-component__main__btn.dapp-core-component__main__btn-light.dapp-core-component__walletConnectLoginContainerStyles__xPortal-modal-close-button,
    .dapp-core-component__dappModalStyles__dappModalCloseButton.dapp-core-component__main__btn.dapp-core-component__main__btn-light.dapp-core-component__ledgerLoginContainerStyles__ledger-modal-close-button {
        padding: 0.5rem 0.8rem;
    }

    .dapp-core-component__ledgerConnectStyles__login-connect-container
        .dapp-core-component__ledgerConnectStyles__login-connect-container-content
        .dapp-core-component__ledgerConnectStyles__login-connect-container-footer
        .dapp-core-component__ledgerConnectStyles__login-connect-container-link {
        font-size: 1rem;
    }
    .dapp-core-component__addressTableStyles__ledger-address-table-wrapper
        .dapp-core-component__addressTableStyles__ledger-address-table-navigation
        .dapp-core-component__addressTableStyles__ledger-address-table-navigation-button {
        svg {
            color: #fff !important;
        }
    }

    .dapp-core-component__scamPhishingStyles__scam-phishing-alert
        .dapp-core-component__scamPhishingStyles__scam-phishing-alert-icon,
    .dapp-core-component__scamPhishingStyles__scam-phishing-alert
        .dapp-core-component__scamPhishingStyles__scam-phishing-alert-text
        strong {
        color: var(--entity-green) !important;
    }
    .dapp-core-component__progressBarStyles__ledger-progress-bar
        .dapp-core-component__progressBarStyles__ledger-progress-bar-thumb {
        background-color: #85fc8c;
    }

    .dapp-core-component__confirmAddressStyles__ledger-confirm-address
        .dapp-core-component__confirmAddressStyles__ledger-confirm-address-footer,
    .dapp-core-component__confirmAddressStyles__ledger-confirm-address
        .dapp-core-component__confirmAddressStyles__ledger-confirm-address-section
        .dapp-core-component__confirmAddressStyles__ledger-confirm-address-description {
        color: #fff;

        a {
            color: var(--entity-green) !important;
        }
    }

    .dapp-core-component__addressTableStyles__ledger-address-table-navigation-button-label,
    .dapp-core-component__walletConnectLoginContainerStyles__xPortal-legacy-link {
        color: var(--entity-green) !important;
    }

    .dapp-core-component__addressTableStyles__ledger-address-table-header {
        background-color: #3b3b3b !important;
    }

    div[class*="dapp-core-component__main__card"] {
        padding: 0;
        margin: 0;
        border: none !important;
    }

    div[class*="dapp-core-component__dappModalStyles__dappModalBody"] {
        padding: 2rem;
    }

    .dapp-core-component__ledgerConnectStyles__login-connect-container thead th,
    .dapp-core-component__main__icon-state svg,
    .dapp-core-component__main__btn-link {
        color: var(--entity-green) !important;
        font-weight: normal;
    }

    .dapp-core-component__main__btn-link:hover,
    .dapp-core-component__main__btn-link svg:hover {
        color: #fff;
    }

    .dapp-core-component__addressRowStyles__ledger-address-table-body-item:not(.dapp-core-component__addressRowStyles__ledger-address-table-body-item-selected) {
        color: #c9cfd5;
    }

    .dapp-core-component__addressRowStyles__ledger-address-table-body-item
        .dapp-core-component__addressRowStyles__ledger-address-table-body-item-data
        .dapp-core-component__addressRowStyles__ledger-address-table-body-item-data-input:checked:before {
        border: 4px solid var(--entity-green) !important;
    }

    .dapp-core-component__ledgerConnectStyles__login-connect-container {
        color: #fff;

        a {
            color: var(--entity-green) !important;
        }
    }

    .dapp-core-component__main__table td {
        border-bottom: 1px solid #707070;
    }

    .dapp-core-component__main__btn-primary {
        ${eButtonStyles};
        margin: 0 auto;
        margin-top: 0.6rem;
    }

    div[class*="dapp-core-component__dappModalStyles__dappModalHeader"],
    div[class*="dapp-core-component__dappModalStyles__dappModalBody"] {
        .btn {
            display: inline-block !important;
            text-transform: capitalize !important;
        }
        .btn-primary,
        .btn-unlock {
            ${eButtonStyles};
        }
        background: #4d4d4d !important;
        color: white;
        div[class*="dapp-core-component__main__card"] {
            background: #4d4d4d !important;
        }
        .px-sm-4,
        .m-1,
        .mx-sm-3 {
            margin-left: 0 !important;
        }
        .btn-unlock {
            ${dangerStyles};
            margin-right: 14px;
        }
        @media (max-width: 600px) {
            .extension-login_noExtensionButtonTitle,
            .btn-primary {
                ${btnWidth}
            }
            .btn-unlock {
                margin-bottom: 3.5px;
                ${btnWidth}
            }
        }
    }
`;

export default dappUIStyles;
