import React, { FC, useRef } from "react";
import { EAccordionHeader, EAccordionItem } from "../../../components/Accordion";
import { ProjectRoadmapEntry } from "../launchpad.types";
import { Accordion, Col, Row, useAccordionButton } from "react-bootstrap";
import ContentContainer from "./ContentContainer";
import ProjectRoadmap from "./Metrics/ProjectRoadmap";
import LegalDisclaimer from "./LegalDisclaimer";
import { BackgroundDecorations } from "../../../components/BackgroundDecorations";
import styled from "styled-components";

type AdditionalInfo = {
    projectRoadmap?: ProjectRoadmapEntry[];
    CustomAdditionalItem?: () => JSX.Element;
    id: string;
};

type AccordionItemProps = { eventKey: string; header: string } & (
    | ({ showAdditionalInfo?: true } & AdditionalInfo)
    | ({ showAdditionalInfo: false } & Partial<AdditionalInfo>)
);

const StyledBackgroundDecorations = styled(BackgroundDecorations)`
    top: 10rem;

    .first-cloud {
        top: -5rem;
        left: 0%;
        opacity: 0.4;
    }

    .middle-cloud {
        top: -10rem;
        left: 30%;
        opacity: 0.7;
    }

    .last-cloud {
        top: 5rem;
        opacity: 0.4;
    }

    .hex-container {
        opacity: 0.2;
    }
`;

const AccordionItem: FC<React.PropsWithChildren<AccordionItemProps>> = ({
    eventKey,
    header,
    children,
    showAdditionalInfo = true,
    projectRoadmap,
    CustomAdditionalItem,
    id,
}) => {
    const accordionRef = useRef<null | HTMLDivElement>(null);

    const onClick = () => {
        setTimeout(() => {
            accordionRef?.current?.scrollIntoView();
        }, 350);
    };

    const decoratedOnClick = useAccordionButton(eventKey, onClick);
    return (
        <EAccordionItem eventKey={eventKey} id={id} ref={accordionRef}>
            <StyledBackgroundDecorations />
            <EAccordionHeader onClick={decoratedOnClick}>{header}</EAccordionHeader>
            <Accordion.Body>
                <Row>
                    {showAdditionalInfo ? (
                        <>
                            <Col xs={12} lg={8}>
                                {children}
                            </Col>
                            <Col xs={12} lg={4}>
                                <ContentContainer title="Project Roadmap">
                                    <ProjectRoadmap events={projectRoadmap} />
                                </ContentContainer>
                                <LegalDisclaimer />
                                {CustomAdditionalItem && <CustomAdditionalItem />}
                            </Col>
                        </>
                    ) : (
                        <Col>{children}</Col>
                    )}
                </Row>
            </Accordion.Body>
        </EAccordionItem>
    );
};

export default AccordionItem;
