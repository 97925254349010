import React from "react";
import Number from "../../components/Price/Number";
import { TBA, UNCAPPED } from "../../helpers/consts";

export const getTotalSupplyValue = (maxTokenSupply: number) => {
    if (maxTokenSupply > 1) {
        return maxTokenSupply;
    }

    return maxTokenSupply === 0 ? UNCAPPED : TBA;
};

const TotalSupply = ({ maxTokenSupply }: { maxTokenSupply: number }) => {
    const totalSupply = getTotalSupplyValue(maxTokenSupply);

    return totalSupply > 1 ? <Number value={maxTokenSupply} /> : <span>{totalSupply}</span>;
};

export default TotalSupply;
