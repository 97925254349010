import React, { FC } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import styled from "styled-components";
import OverlayTriggerTooltip from "../../helpers/OverlayTriggerTooltip";
import { faQuestionCircle } from "@fortawesome/free-solid-svg-icons";

const defaultColor = "#fff";

const QuestionMark = styled(FontAwesomeIcon)`
    color: ${(props): string => props.color || defaultColor};
`;

interface QuestionMarkIconProps {
    tooltipText?: string;
    redirectUrl?: string;
    color?: string;
    testId?: string;
}

const QuestionMarkIcon: FC<React.PropsWithChildren<QuestionMarkIconProps>> = ({
    tooltipText = "About this module",
    redirectUrl,
    color = defaultColor,
    testId = "questionMarkIcon",
    ...props
}) => {
    return (
        <a className="ml-0-5" href={redirectUrl} rel="noreferrer" target="_blank">
            <OverlayTriggerTooltip
                placement="top"
                tooltipText={tooltipText}
                cursor={!!redirectUrl ? "pointer" : "not-allowed"}
                inline
            >
                <QuestionMark icon={faQuestionCircle} color={color} data-testid={testId} {...props} />
            </OverlayTriggerTooltip>
        </a>
    );
};

export default QuestionMarkIcon;
