import React, { FC, memo } from "react";
import { ECountDownWrapper } from "../../index";

interface ECountDownFooterProps {
    started?: boolean;
    finished?: boolean;
    startTimestamp?: number;
    endTimestamp?: number;
    onCompleteStartHandler: () => void;
    onCompleteEndHandler: () => void;
    beforeStartedChildren?: JSX.Element;
    inProgressChildren?: JSX.Element;
}

const ECountDownFooter: FC<React.PropsWithChildren<ECountDownFooterProps>> = ({
    started,
    finished,
    startTimestamp,
    endTimestamp,
    onCompleteStartHandler,
    onCompleteEndHandler,
    beforeStartedChildren,
    inProgressChildren,
}) => {
    return (
        <>
            {!started && (
                <>
                    {!!beforeStartedChildren && beforeStartedChildren}
                    <ECountDownWrapper time={startTimestamp} onCompleteHandler={onCompleteStartHandler} scope="starts" />
                </>
            )}

            {started && !finished && (
                <>
                    {!!inProgressChildren && inProgressChildren}
                    <ECountDownWrapper time={endTimestamp} onCompleteHandler={onCompleteEndHandler} scope="ends" />
                </>
            )}
        </>
    );
};

export default memo(ECountDownFooter);
