import React, { useEffect, useState } from "react";
import { UseFormReturn } from "react-hook-form";
import { Col, Row } from "react-bootstrap";
import { ArrayUtils, FormCheckGroup, FormSelectGroup, ValidationSchemaUtils } from "../../../common";
import FormImageUploading, { ONLY_PNG_AND_JPEG } from "../../../common/form/FormImageUploading";
import FormControlledInputGroup from "../../../common/form/FormControlledInputGroup";
import Editor from "../TextEditor/Editor";
import { IcoStepOneInputs } from "../IcoStepOne";
import * as yup from "yup";
import { ALPHA_FOR_COUNTRY, ALPHA_WITH_SPACE, ICO_ALPHA_NUMERIC_NOT_ONLY_NUMERIC } from "../../../common/utils/RegexUtils";
import { IMAGE_MAX_SIZE, IMAGE_MIN_SIZE } from "../../../helpers/consts";
import FormMultipleInputGroup from "common/form/FormMultipleInputGroup";
import { TokenUtilityOption, tokenUtilityOptions } from "../ico.types";
import { IcoEditFieldsStepOneHelper } from "./helper";
import {
    getPureText,
    lengthErrorMsg,
    lengthTestTitle,
    maxLengthErrorMsg,
    minLengthErrorMsg,
    minStrLength,
    REQUIRED_MSG,
} from "../../../common/utils/ValidationSchemaUtils";

interface IcoEditStepOneProps<T extends IcoStepOneInputs>
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    extends Pick<UseFormReturn<any>, "register" | "formState" | "control" | "setError" | "watch"> {
    ico: T;
    displayCompact?: boolean;
}

const checkTextLength = ({
    text,
    min = minStrLength,
    max = 10_000,
}: {
    text?: string | null;
    min?: number;
    max?: number;
}): boolean => {
    if (!text) {
        return true;
    }

    const pureText = getPureText(text);
    const sum = pureText?.length || 0;
    return (sum >= min && sum <= max) || sum === 0;
};

const alphaNumericErrorMsg = "Only alphanumeric characters allowed (at least one alpha character)";

const fieldsLength = {
    name: { min: 2, max: 50 },
    username: { min: 2, max: 50 },
    position: { min: minStrLength, max: 50 },
    country: { min: 3, max: 50 },
    slogan: { min: minStrLength, max: 250 },
    description: { min: 250, max: 10_000 },
};

export function getIcoStepOneValidationSchema() {
    return {
        name: ValidationSchemaUtils.emptyAsNull
            .required(REQUIRED_MSG)
            .min(fieldsLength.name.min, minLengthErrorMsg(fieldsLength.name.min))
            .max(fieldsLength.name.max, maxLengthErrorMsg(fieldsLength.name.max))
            .matches(ICO_ALPHA_NUMERIC_NOT_ONLY_NUMERIC, alphaNumericErrorMsg),
        username: ValidationSchemaUtils.emptyAsNull
            .required(REQUIRED_MSG)
            .min(fieldsLength.username.min, minLengthErrorMsg(fieldsLength.username.min))
            .max(fieldsLength.username.max, maxLengthErrorMsg(fieldsLength.username.max))
            .matches(ALPHA_WITH_SPACE, "Only alpha characters allowed"),
        email: ValidationSchemaUtils.email,
        position: ValidationSchemaUtils.emptyAsNull
            .required(REQUIRED_MSG)
            .min(fieldsLength.position.min, minLengthErrorMsg(fieldsLength.position.min))
            .max(fieldsLength.position.max, maxLengthErrorMsg(fieldsLength.position.max))
            .matches(ICO_ALPHA_NUMERIC_NOT_ONLY_NUMERIC, alphaNumericErrorMsg),
        country: ValidationSchemaUtils.emptyAsNull
            .min(fieldsLength.country.min, minLengthErrorMsg(fieldsLength.country.min))
            .max(fieldsLength.country.max, maxLengthErrorMsg(fieldsLength.country.max))
            .matches(ALPHA_FOR_COUNTRY, "Only alpha and some special characters allowed"),
        slogan: ValidationSchemaUtils.emptyAsNull
            .min(fieldsLength.slogan.min, minLengthErrorMsg(fieldsLength.slogan.min))
            .max(fieldsLength.slogan.max, maxLengthErrorMsg(fieldsLength.slogan.max)),
        description: ValidationSchemaUtils.takeEditorEmptyAsNull
            .required(REQUIRED_MSG)
            .test(lengthTestTitle, lengthErrorMsg({ ...fieldsLength.description }), (text) =>
                checkTextLength({ text, ...fieldsLength.description }),
            ),
        targetAudience: ValidationSchemaUtils.uniqueArray(3).required(REQUIRED_MSG),
        tokenUtility: ValidationSchemaUtils.emptyArrayAsNull().when("tokenUtilityOption", {
            is: TokenUtilityOption.CUSTOM_INPUT,
            then: ValidationSchemaUtils.uniqueArray(3).required(REQUIRED_MSG),
            otherwise: (schema) => schema.transform(() => null),
        }),
        logoUploadedImage: yup.array().test("Check if image is present", REQUIRED_MSG, (arr) => !!arr && arr.length > 0),
    };
}

const getTokenUtilityOption = (tokenUtility?: string[]) =>
    tokenUtility && ArrayUtils.getDefinedValues(tokenUtility).length > 2
        ? TokenUtilityOption.CUSTOM_INPUT
        : TokenUtilityOption.NO_TOKEN_UTILITY;

const IcoEditStepOne = <T extends IcoStepOneInputs>({
    ico,
    register,
    formState,
    control,
    setError,
    displayCompact = false,
    watch,
}: IcoEditStepOneProps<T>): JSX.Element => {
    const [currentTokenUtilityOption, setCurrentTokenUtilityOption] = useState(getTokenUtilityOption(ico.tokenUtility));
    const watchTokenUtilityOption = watch("tokenUtilityOption");
    useEffect(() => {
        window.scrollTo(0, 0);
    }, []);

    useEffect(() => {
        setCurrentTokenUtilityOption(watchTokenUtilityOption);
    }, [watchTokenUtilityOption]);

    const icoEditFieldsHelper = new IcoEditFieldsStepOneHelper(register, formState, ico);

    return (
        <>
            {displayCompact && (
                <>
                    <Row>
                        <Col xs={12} md={4}>
                            {icoEditFieldsHelper.getNameElement()}
                        </Col>
                        <Col xs={12} md={4}>
                            {icoEditFieldsHelper.getUsernameElement()}
                        </Col>

                        <Col xs={12} md={4}>
                            {icoEditFieldsHelper.getEmailElement()}
                        </Col>
                    </Row>
                    <Row>
                        <Col xs={12} md={4}>
                            {icoEditFieldsHelper.getPositionElement()}
                        </Col>
                        <Col xs={12} md={4}>
                            {icoEditFieldsHelper.getCountryElement()}
                        </Col>
                        <Col xs={12} md={4}>
                            {icoEditFieldsHelper.getSloganElement()}
                        </Col>
                    </Row>
                </>
            )}
            {!displayCompact && (
                <>
                    <Row>
                        <Col xs={12} md={6}>
                            {icoEditFieldsHelper.getNameElement()}
                        </Col>
                        <Col xs={12} md={6}>
                            {icoEditFieldsHelper.getUsernameElement()}
                        </Col>
                    </Row>
                    <Row>
                        <Col xs={12} md={6}>
                            {icoEditFieldsHelper.getEmailElement()}
                        </Col>
                        <Col xs={12} md={6}>
                            {icoEditFieldsHelper.getPositionElement()}
                        </Col>
                    </Row>
                    <Row>
                        <Col xs={12} md={6}>
                            {icoEditFieldsHelper.getCountryElement()}
                        </Col>
                        <Col xs={12} md={6}>
                            {icoEditFieldsHelper.getSloganElement()}
                        </Col>
                    </Row>
                </>
            )}
            <Row>
                <Col xs={12} md={6}>
                    <FormImageUploading
                        limitResolution
                        name="logoUploadedImage"
                        label="Logo:"
                        tooltipText={`Only ${IMAGE_MIN_SIZE}x${IMAGE_MIN_SIZE} - ${IMAGE_MAX_SIZE}x${IMAGE_MAX_SIZE} PNG and JPEG images up to 2MB are allowed`}
                        register={register}
                        formState={formState}
                        control={control}
                        setError={setError}
                        required={true}
                    />
                </Col>
                <Col xs={12} md={6}>
                    <FormImageUploading
                        name="featureUploadedImage"
                        label="Feature:"
                        tooltipText={ONLY_PNG_AND_JPEG}
                        register={register}
                        formState={formState}
                        control={control}
                        setError={setError}
                    />
                </Col>
            </Row>
            <Row>
                <Col xs={12} md={12}>
                    <FormControlledInputGroup
                        name="description"
                        label=""
                        register={register}
                        control={control}
                        className="flex-column free-text-controller"
                        formState={formState}
                        render={({ onChange }) => (
                            <Editor
                                onChange={onChange}
                                previousValue={ico.description}
                                maxLength={fieldsLength.description.max}
                                title={() => <span className="required">Project Summary:</span>}
                            />
                        )}
                    />
                </Col>
            </Row>
            <Row>
                <Col xs={12} md={12}>
                    <FormMultipleInputGroup
                        name="targetAudience"
                        label="Target Audience:"
                        register={register}
                        control={control}
                        formState={formState}
                        elements={ico.targetAudience}
                        required={true}
                    />
                </Col>
            </Row>
            <Row>
                <Col xs={12} md={12}>
                    <FormSelectGroup
                        name="tokenUtilityOption"
                        defaultValue={ico.tokenUtilityOption || getTokenUtilityOption(ico.tokenUtility)}
                        register={register}
                        formState={formState}
                        control={control}
                        label="Token Utility:"
                        required={true}
                    >
                        {Object.values(TokenUtilityOption).map((tokenUtility: TokenUtilityOption, index: number) => (
                            <option className="w-100" key={`tokenUtility-option-${index}`} value={tokenUtility}>
                                {tokenUtilityOptions[tokenUtility]}
                            </option>
                        ))}
                    </FormSelectGroup>
                </Col>
            </Row>
            <Row>
                <Col xs={12} md={12}>
                    <FormMultipleInputGroup
                        name="tokenUtility"
                        label=""
                        register={register}
                        control={control}
                        formState={formState}
                        elements={ico.tokenUtility}
                        className={
                            currentTokenUtilityOption === TokenUtilityOption.CUSTOM_INPUT ? "d-flex flex-column" : "d-none"
                        }
                    />
                </Col>
            </Row>
            <Row>
                <Col xs={12} md={12}>
                    <FormCheckGroup
                        defaultChecked={ico.hasLaunchpadListing}
                        name="hasLaunchpadListing"
                        label="Apply to be considered for the Entity Launchpad"
                        register={register}
                        formState={formState}
                    />
                </Col>
            </Row>
        </>
    );
};

export default IcoEditStepOne;
