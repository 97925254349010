import React from "react";
import styled from "styled-components";
import { Point } from "./helpers/hex.types";

export interface LineProps extends Partial<AnimatedLineProps> {
    points: Point[];
    x?: number;
    y?: number;
    stroke?: string;
    strokeWidth?: string;
    animated?: boolean;
    isVisible?: boolean;
}

export interface AnimatedLineProps {
    pathLength: number | string;
    delay?: string;
    duration?: string;
}

const HighlightLine = styled.g`
    &.animated {
        polyline {
            stroke-dasharray: ${({ pathLength }: AnimatedLineProps) => `${pathLength}px`};
            stroke-dashoffset: ${({ pathLength }: AnimatedLineProps) => `${pathLength}px`};
            animation: draw ${({ duration }: Required<AnimatedLineProps>) => duration}s linear infinite;
            animation-delay: ${({ delay }: Required<AnimatedLineProps>) => delay}s;
        }
    }

    @keyframes draw {
        100% {
            stroke-dashoffset: 0;
        }
    }
`;

const Line = ({
    points,
    x,
    y,
    stroke = "#fff",
    strokeWidth = "1",
    animated,
    pathLength = 0,
    delay = "2",
    duration = "5",
    isVisible = true,
}: LineProps) => {
    const linePoints = points.map((point: Point) => `${point.x},${point.y}`).join(" ");

    if (!isVisible) return null;

    return (
        <HighlightLine pathLength={pathLength} delay={delay} duration={duration} className={animated ? "animated" : ""}>
            {animated && (
                <g>
                    <defs>
                        <filter id="blurFilter" x="0" y="0">
                            <feGaussianBlur in="SourceGraphic" stdDeviation="3" />
                        </filter>
                    </defs>
                    <polyline
                        points={linePoints}
                        fill="none"
                        stroke={stroke}
                        strokeLinejoin="round"
                        strokeWidth={strokeWidth}
                        filter="url(#blurFilter)"
                    />
                </g>
            )}

            <polyline
                fill="none"
                stroke={stroke}
                strokeLinejoin="round"
                strokeWidth={strokeWidth}
                transform={`translate(${x ?? 0}, ${y ?? 0})`}
                points={linePoints}
            />
        </HighlightLine>
    );
};

export default Line;
