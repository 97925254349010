import React from "react";
import { Image } from "react-bootstrap";
import { SingleValue } from "react-select";
import { StakingProvider } from "../types/stakingProiderTypes";
import ESelect from "./ESelect/ESelect";

export interface StakingProvidersSelectProps {
    selectedProvider: SingleValue<StakingProvider>;
    stakingProviders: StakingProvider[];
    selectStakingProviderHandler: (stakingProvider: SingleValue<StakingProvider>) => void;
    displayLabel?: boolean;
}

const StakingProvidersSelect = ({
    selectedProvider,
    stakingProviders,
    selectStakingProviderHandler,
    displayLabel = false,
}: StakingProvidersSelectProps) => (
    <ESelect
        value={selectedProvider}
        isSearchable
        options={stakingProviders}
        isLoading={!stakingProviders}
        formatOptionLabel={(provider: StakingProvider) => (
            <div className="d-flex align-items-center justify-content-between">
                <span>
                    {provider.identity.avatar && <Image src={provider.identity.avatar} className="icon-provider-sm" />}
                    {provider.identity.name}
                </span>
                {provider.apr && <span>{provider.apr}%</span>}
            </div>
        )}
        onChange={(newValue: SingleValue<StakingProvider>) => selectStakingProviderHandler(newValue)}
        getOptionValue={(option: StakingProvider) => option.identity.key}
        name="stakingProviderDropdown"
        displayLabel={displayLabel ? "Select a staking provider:" : undefined}
    />
);

export default StakingProvidersSelect;
