import BaseService from "./BaseService";
import { KeyInvestor } from "../pages/KeyInvestors/KeyInvestors.types";
import axios from "axios";

class KeyInvestorsService extends BaseService {
    private readonly _urlPrefix: string;

    constructor(entityApi: string) {
        super();
        this._urlPrefix = `${entityApi}/key-investors`;
    }

    createInvestor(investor: KeyInvestor): Promise<KeyInvestor> {
        return this.doCall(() => axios.post<KeyInvestor>(this._urlPrefix, investor));
    }

    retrieveAll(): Promise<KeyInvestor[]> {
        return this.doCall(() => axios.get<KeyInvestor[]>(this._urlPrefix));
    }

    getById(id: string): Promise<KeyInvestor> {
        return this.doCall(() => axios.get<KeyInvestor>(`${this._urlPrefix}/${id}`));
    }

    updateInvestor(investor: KeyInvestor): Promise<KeyInvestor> {
        return this.doCall(() => axios.put<KeyInvestor>(`${this._urlPrefix}/${investor.id}`, investor));
    }

    deleteInvestor(id: string): Promise<KeyInvestor> {
        return this.doCall(() => axios.delete<KeyInvestor>(`${this._urlPrefix}/${id}`));
    }
}

export default KeyInvestorsService;
