import React from "react";
import { EPlaceholder } from "../Placeholder";
import { renderHTML } from "../../helpers/consts";

type Slogan = { slogan: string };

const Slogan = ({ slogan }: Partial<Slogan>) => {
    if (!slogan) {
        return <EPlaceholder numberOfLines={3} size="lg" />;
    }
    return (
        <div className="lp-slogan" data-testid="slogan">
            {renderHTML(slogan)}
        </div>
    );
};

export default Slogan;
