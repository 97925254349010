import React, { memo } from "react";
import { InvestorsHexagon } from "./Investors";
import styled from "styled-components";
import { Row } from "react-bootstrap";
import { BREAKPOINTS } from "../../../assets/sass/breakpoints";
import { HexTemplate } from "../../../components/Hexagon";
import { Corner, Orientation } from "../../../components/Hexagon/helpers/hex.types";
import { investorsSvgHeight, investorsSvgWidth } from "./helper/consts";
import _ from "lodash";
import { isMobile } from "react-device-detect";

interface MyHexContainerProps {
    hexagons: InvestorsHexagon[];
}

const HexContent = styled(Row)`
    margin-right: 1rem;

    @media (${BREAKPOINTS.tablet}) {
        margin-left: -${(investorsSvgWidth / 4.4) * 0.071}rem;
    }

    margin-left: -${(investorsSvgWidth / 5) * 0.071}rem;
`;

const HexGrid = styled(HexTemplate)`
    svg {
        padding-right: 0 !important;
    }
`;

const highlightLines = [
    {
        coordinates: [
            { q: 0, r: 1, points: [Corner.f4, Corner.f5, Corner.f0] },
            { q: 1, r: 1, points: [Corner.f4] },
            { q: 2, r: 0, points: [Corner.f3, Corner.f2, Corner.f1, Corner.f0] },
            { q: 3, r: 0, points: [Corner.f4, Corner.f5] },
            { q: 4, r: -1, points: [Corner.f3, Corner.f2, Corner.f1] },
        ],
    },
    {
        coordinates: [
            { q: 1, r: 1, points: [Corner.f3, Corner.f4, Corner.f5] },
            { q: 2, r: 0, points: [Corner.f3, Corner.f4, Corner.f5, Corner.f0] },
            { q: 3, r: -1, points: [Corner.f2, Corner.f1, Corner.f0] },
        ],
    },
    {
        coordinates: [
            { q: 0, r: 0, points: [Corner.f5, Corner.f0] },
            { q: 1, r: 0, points: [Corner.f4, Corner.f3, Corner.f2, Corner.f1] },
            { q: 2, r: 0, points: [Corner.f3, Corner.f2, Corner.f1] },
            { q: 3, r: 0, points: [Corner.f4, Corner.f5, Corner.f0] },
            { q: 4, r: 0, points: [Corner.f4, Corner.f3, Corner.f2, Corner.f1] },
        ],
    },
];

const layout = { width: (investorsSvgWidth / 4) * 16.1, height: 3 * investorsSvgHeight };
const hexSettings = {
    dimensions: { width: investorsSvgWidth, height: investorsSvgHeight },
    orientation: Orientation.FLAT,
    offset: 1.01,
};

const InvestorsHexContainer = ({ hexagons }: MyHexContainerProps) => {
    return (
        <HexContent>
            <HexGrid
                className="gx-0"
                templateId={"investors-hex-template"}
                layout={layout}
                hexSettings={hexSettings}
                hexagons={hexagons}
                highlightLines={isMobile ? [] : highlightLines}
            />
        </HexContent>
    );
};

export default memo(InvestorsHexContainer, (prev, next) => {
    return _.isEqual(prev.hexagons, next.hexagons);
});
