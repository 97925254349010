import React, { FC } from "react";
import { TimelineEventProps } from "./Timeline";
import styled from "styled-components";

const Line = styled.div`
    position: absolute;
    top: 0;
    left: 0;
    width: 0.0714rem;
    height: 100%;
    background-color: ${({ isChecked }: Partial<TimelineEventProps>) =>
        isChecked ? "var(--entity-green)" : "var(--entity-gray)"};

    &.last-checked {
        background-color: var(--entity-gray);
        background-image: linear-gradient(var(--entity-green), var(--entity-gray));
    }
`;

const POINT_DIAMETER = "0.785rem";
const EVENT_LINE_HEIGHT = "1.285rem";
export const EventPoint = styled.div`
    position: absolute;
    width: ${POINT_DIAMETER};
    height: ${POINT_DIAMETER};
    border-radius: 100%;
    left: 0;
    top: 0;
    background-color: ${({ isChecked }: Partial<TimelineEventProps>) =>
        isChecked ? "var(--entity-green)" : "var(--entity-gray)"};
    transform: translate(-50%, calc(${EVENT_LINE_HEIGHT} / 2 - ${POINT_DIAMETER} / 2));
`;

const TimelineEventItem = styled.div`
    position: relative;
    width: 100%;
    padding-left: 1.714rem;
    padding-bottom: 1.714rem;
    line-height: 1.285rem;

    &:first-child {
        ${Line} {
            top: calc(${EVENT_LINE_HEIGHT} / 2 - ${POINT_DIAMETER} / 2);
            height: calc(100% - (${EVENT_LINE_HEIGHT} / 2 - ${POINT_DIAMETER} / 2));
        }
    }
`;

const Event: FC<React.PropsWithChildren<{ item?: TimelineEventProps }>> = ({ children, item }) => {
    return (
        <TimelineEventItem data-testid="event">
            <Line isChecked={item?.isChecked} className={`${(item?.lastChecked && "last-checked") || ""}`} />
            <EventPoint isChecked={item?.isChecked} />
            {children}
        </TimelineEventItem>
    );
};

export default Event;
