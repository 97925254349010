import React, { useState } from "react";
import { Form } from "react-bootstrap";

import { useService } from "../../services/config/dependencyInjectorConfig";
import UsersService from "../../services/UsersService";
import FormCheckGroup from "../../common/form/FormCheckGroup";
import { useForm } from "react-hook-form";
import { routeNames } from "../../routes/routes";
import { Link } from "react-router-dom";
import { useContext, useDispatch } from "../../context";
import EModal from "../common/EModal";

const Label = () => (
    <div>
        I accept the{" "}
        <Link className="text-green" to={routeNames.termsAndConditions}>
            Terms of Service
        </Link>{" "}
        and the{" "}
        <Link className="text-green" to={routeNames.privacyPolicy}>
            Privacy Policy
        </Link>
    </div>
);

const ConditionsAgreement = () => {
    const { userSettings } = useContext();
    const [usersService] = useService(UsersService);
    const [show, setShow] = useState(true);
    const { register, formState } = useForm();
    const dispatch = useDispatch();

    const onConditionsAcceptance = () => {
        const setConditionsAgreement = async () => {
            try {
                await usersService.setConditionsAgreement();
                userSettings.conditionsAgreement = true;
                dispatch({
                    type: "setUserSettings",
                    userSettings: userSettings,
                });
                setShow(false);
            } catch (error) {
                console.error(`${error.name}: ${error.message}`);
            }
        };
        setConditionsAgreement();
    };

    return (
        <EModal show={show} centered size="lg">
            <EModal.Header>
                <EModal.Title>
                    <h2>Terms and Conditions and Privacy Policy</h2>
                </EModal.Title>
            </EModal.Header>
            <EModal.Body>
                <Form className="mt-3 d-flex" onChange={() => onConditionsAcceptance()}>
                    <FormCheckGroup
                        name="isConditionsAccepted"
                        label=""
                        type="checkbox"
                        defaultChecked={false}
                        register={register}
                        formState={formState}
                        className="mb-3"
                    />
                    <Label />
                </Form>
            </EModal.Body>
        </EModal>
    );
};

export default ConditionsAgreement;
