import FormControlledInputGroup, { FormControlGroupProps } from "./FormControlledInputGroup";
import { FormGroupProps } from "./types";
import ImageUploading, { ErrorsType, ImageListType, ImageType } from "react-images-uploading";
import React from "react";
import { ErrorOption, FieldError } from "react-hook-form/dist/types/errors";
import { UseFormSetError } from "react-hook-form/dist/types/form";
import { faWindowClose } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import ImageUtils from "../utils/ImageUtils";
import { IMAGE_MAX_SIZE, IMAGE_MIN_SIZE } from "../../helpers/consts";
import styled from "styled-components";

const SIZE_2_MB = 2 * 1024 * 1024;
export const ONLY_PNG_AND_JPEG = "Only PNG and JPEG images up to 2MB are allowed";

export interface FormImageUploadingProps<T> extends FormGroupProps<T>, Pick<FormControlGroupProps<T>, "control"> {
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    setError: UseFormSetError<any>;
    tooltipText: string;
    limitResolution?: boolean;
}

const getImageName = (image: ImageType, label: string): string => {
    if (image.file) {
        let imageName = image.file.name;
        if (imageName.length > 20) {
            imageName = imageName.substring(0, 10) + "..." + imageName.substring(imageName.length - 10);
        }
        return imageName;
    }
    if (image.dataURL) {
        const imageName = label.split(":")[0].toLowerCase();
        return imageName + "." + ImageUtils.extractExtensionFromUrl(image.dataURL);
    }
    return "image";
};

const ImgContainer = styled.div`
    margin: 1rem 1rem 0 0;

    img {
        object-fit: cover;
        width: 100%;
        height: 100%;

        small p {
            width: 100%;
            height: 100%;
        }
    }
`;

const validateResolution = async (
    name: string,
    images: ImageListType,
    resolution: { minWidth: number; maxWidth: number; minHeight: number; maxHeight: number },
    setError: (name: string, error: FieldError) => void,
) => {
    for (const image of images) {
        const { width, height } = image.file ? await ImageUtils.getImage(image.file) : { width: 0, height: 0 };
        const { minWidth, maxWidth, minHeight, maxHeight } = resolution;
        if (width < minWidth || width > maxWidth || height < minHeight || height > maxHeight || width !== height) {
            setError(name, {
                type: "resolution",
                message: `${minWidth}x${minHeight} - ${maxWidth}x${maxHeight} pixels image required`,
            });
            return false;
        }
    }
    return true;
};

function FormImageUploading<T>(props: FormImageUploadingProps<T>): JSX.Element {
    const {
        name,
        label,
        limitResolution = false,
        className = "flex-column mb-2",
        control,
        register,
        formState,
        setError,
        tooltipText,
        required = false,
    } = props;

    const onChangeImageHandler = async (
        imageList: ImageListType,
        addUpdateIndex: number[] | undefined,
        onFormChange: (...event: unknown[]) => void,
    ) => {
        if (limitResolution) {
            const isValid = await validateResolution(
                name,
                imageList,
                { minWidth: IMAGE_MIN_SIZE, maxWidth: IMAGE_MAX_SIZE, minHeight: IMAGE_MIN_SIZE, maxHeight: IMAGE_MAX_SIZE },
                setError,
            );
            if (!isValid) {
                return;
            }
        }

        if (imageList.length > 1) {
            const uploadedImages = imageList.filter((image) => !!image.file);
            const newUploadedImage = uploadedImages[uploadedImages.length - 1];
            onFormChange([newUploadedImage]);
        } else {
            onFormChange(imageList);
        }
        setError(name, {});
    };

    const onImageErrorHandler = (errors: ErrorsType): void => {
        setError(name, getErrorMessageOption(errors));
    };

    const getErrorMessageOption = (errors: ErrorsType): ErrorOption => {
        if (!!errors?.maxFileSize) {
            return {
                type: "maxFileSize",
                message: "Image can not be larger than 2MB",
            };
        }
        if (!!errors?.acceptType) {
            return {
                type: "acceptType",
                message: "Only images are accepted",
            };
        }
        if (!!errors?.resolution) {
            return {
                type: "resolution",
                message: "300x300 pixels image required",
            };
        }
        return {};
    };

    return (
        <FormControlledInputGroup
            name={name}
            label={label}
            labelTooltipText={tooltipText}
            register={register}
            control={control}
            className={className}
            formState={formState}
            required={required}
            render={({ onChange: onFormChange, value: imageValue }) => (
                <ImageUploading
                    value={imageValue ?? []}
                    onChange={(imageList, index) => onChangeImageHandler(imageList, index, onFormChange)}
                    onError={onImageErrorHandler}
                    maxNumber={2}
                    multiple={true}
                    maxFileSize={SIZE_2_MB}
                    resolutionType={limitResolution ? "absolute" : undefined}
                    acceptType={["jpeg", "jpg", "png"]}
                >
                    {({ imageList, onImageUpload, onImageUpdate, onImageRemove, isDragging, dragProps }) => (
                        <div className="e-upload-container">
                            <div
                                id={name}
                                key={name}
                                aria-controls={name}
                                style={isDragging ? { borderColor: "#84fc8c" } : undefined}
                                onClick={() => (imageList?.length > 0 ? onImageUpdate(0) : onImageUpload())}
                                onDrop={dragProps.onDrop}
                                onDragEnter={dragProps.onDragEnter}
                                onDragLeave={dragProps.onDragLeave}
                                onDragOver={dragProps.onDragOver}
                                className="drag-and-drop-area"
                            >
                                {isDragging && (
                                    <small>
                                        <em>Drop image here...</em>
                                    </small>
                                )}
                                {!imageList.length && !isDragging && (
                                    <div>
                                        <small>Click or drop here</small>
                                    </div>
                                )}
                                {!!imageList.length && !isDragging && (
                                    <div className="sb e-upload-name">
                                        <small>{getImageName(imageList[0], label)}</small>
                                        <small>
                                            <FontAwesomeIcon
                                                icon={faWindowClose}
                                                onClick={(e) => {
                                                    e.preventDefault();
                                                    e.stopPropagation();
                                                    onImageRemove(0);
                                                }}
                                            />
                                        </small>
                                    </div>
                                )}
                            </div>
                            {imageList.map((image, index) => (
                                <ImgContainer key={index}>
                                    <img src={image.dataURL} alt="" />
                                </ImgContainer>
                            ))}
                        </div>
                    )}
                </ImageUploading>
            )}
        />
    );
}

export default FormImageUploading;
