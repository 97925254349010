import React, { FC, useEffect, useState } from "react";
import { useGetLoginInfo, useGetSignedTransactions } from "@multiversx/sdk-dapp/hooks";
import { EButton, ELinkButton } from "../../../components/Buttons";
import { LaunchpadCardBaseProps, RetrieveTicketsInfoFn, Tier } from "../launchpad.types";
import { Tickets, TicketsScope } from "../../../services/TicketsService";
import { routeNames } from "../../../routes/routes";
import BuyTicketsModal from "./BuyTicketsModal";
import { DateUtils } from "../../../common";
import { faTicket } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { LaunchpadScConfig, LaunchpadService } from "../../../services/LaunchpadService";
import AlertService from "../../../services/AlertService";
import { useService } from "../../../services/config/dependencyInjectorConfig";
import EventsService, { EventsNames } from "../../../services/EventsService";
import ECountDownFooter from "../../../common/components/ECountDown/ECountDownFooter";
import errorToast from "../../../components/Error/errorToast";
import ESpinner from "../../../components/Spinner/ESpinner";
import UtilsService from "../../../services/UtilsService";
import checkSignedTransaction from "../../../common/hooks/checkSignedTransaction";
import { SignedTransactionsBodyType } from "@multiversx/sdk-dapp/types";

interface BuyTicketsCardProps extends LaunchpadCardBaseProps {
    snapshotInfo?: Tier;
    ticketsInfo?: Tickets;
    retrieveTicketsInfo?: RetrieveTicketsInfoFn;
    scConfig?: LaunchpadScConfig;
    scShard?: number;
    testId?: string;
}

const BuyTicketsCard: FC<React.PropsWithChildren<BuyTicketsCardProps>> = ({
    projectDetails,
    snapshotInfo,
    ticketsInfo,
    retrieveTicketsInfo,
    isRejected,
    scConfig,
    scShard,
    testId = "buy-tickets-card",
}) => {
    const [started, setStarted] = useState<boolean | undefined>(undefined);
    const [finished, setFinished] = useState<boolean | undefined>(undefined);
    const [show, setShow] = useState(false);

    const { isLoggedIn } = useGetLoginInfo();
    const signedTransactionsState = useGetSignedTransactions();

    const [launchpadService] = useService(LaunchpadService);
    const [utilsService] = useService(UtilsService);
    const [eventsService] = useService(EventsService);

    useEffect(() => {
        if (!projectDetails) {
            return;
        }

        const [hasStarted, hasFinished] = DateUtils.getPeriodStatusFlags(
            projectDetails.generalMetrics.buyTicketsStartTimestamp,
            projectDetails.generalMetrics.buyTicketsEndTimestamp,
        );
        setStarted(hasStarted);
        setFinished(hasFinished);
    }, []);

    useEffect(() => {
        if (!projectDetails) {
            return;
        }
        checkSignedTransaction(signedTransactionsState, {
            afterSignedCallback: async (txSigned: SignedTransactionsBodyType) => {
                if (txSigned.transactions && txSigned.transactions[0]) {
                    await launchpadService.registerTx(txSigned.transactions[0]?.hash, Date.now(), projectDetails.id);
                }
            },
            onSuccessCallback: async () => retrieveTicketsInfo && (await retrieveTicketsInfo(TicketsScope.BUY_TICKETS)),
        });
    }, [signedTransactionsState]);

    const onCompleteStartHandler = () => {
        setStarted(true);
        eventsService.emitWithDelay(EventsNames.RERENDER_LAUNCHPAD);
    };

    const handleClose = () => setShow(false);

    const handleShow = () => setShow(true);

    const onBuyTicketsHandler = async () => {
        if (!scConfig || scShard === undefined) {
            console.error("SC config and shard are required");
            errorToast();
            return;
        }
        const lastBlockNo = await utilsService.getLastBlockNo(scShard);
        const isInConfirmationEpoch = lastBlockNo >= scConfig.confirmationStartBlock;
        const isNotInWinnerSelectionEpoch = lastBlockNo < scConfig.winnerSelectionStartBlock;
        if (isInConfirmationEpoch && isNotInWinnerSelectionEpoch) {
            handleShow();
        } else {
            const text = !isNotInWinnerSelectionEpoch
                ? "The Buy Tickets Phase has ended"
                : "Buying tickets is not enabled yet. Come back later.";
            AlertService.warning(text);
        }
    };

    if (started === undefined && finished === undefined) {
        return <ESpinner />;
    }

    if (!isLoggedIn) {
        return (
            <>
                <div className="d-flex justify-content-center align-items-center" data-testid={testId}>
                    <ELinkButton to={routeNames.unlock} testId="login-btn" className="text-uppercase">
                        Login
                    </ELinkButton>
                </div>
                <div className="text-center pt-1 pb-3" data-testid={testId}>
                    <ECountDownFooter
                        started={started}
                        finished={finished}
                        startTimestamp={projectDetails?.generalMetrics?.buyTicketsStartTimestamp}
                        endTimestamp={projectDetails?.generalMetrics?.buyTicketsEndTimestamp}
                        onCompleteStartHandler={onCompleteStartHandler}
                        onCompleteEndHandler={() => eventsService.emitWithDelay(EventsNames.RERENDER_LAUNCHPAD)}
                    />
                </div>
            </>
        );
    }

    return (
        <div className="text-center pt-1 pb-3" data-testid={testId}>
            <FontAwesomeIcon size={"3x"} className="text-green mb-3" icon={faTicket} />
            {isRejected && <p className="text-warning">You are not eligible to buy any ticket</p>}
            {!isRejected &&
                (!snapshotInfo || snapshotInfo?.no === 0 ? (
                    <div className="text-center text-warning py-3">You are not eligible to buy any ticket</div>
                ) : (
                    <>
                        <div className="text-center py-3">
                            <h4>
                                You are qualified for tier <strong>{snapshotInfo?.no}</strong>.
                            </h4>
                            <p>
                                Tier number {snapshotInfo?.no} grants you{" "}
                                <strong>{snapshotInfo?.maxTicketsPerUser} tickets</strong> in the Entity Launchpad Lottery.
                            </p>
                        </div>
                        {ticketsInfo &&
                            ticketsInfo.numConfirmedTickets > 0 &&
                            ticketsInfo.numConfirmedTickets === +snapshotInfo?.maxTicketsPerUser && (
                                <div className="text-center text-green mt-3">
                                    <p>You have successfully bought {ticketsInfo.numConfirmedTickets} tickets.</p>
                                </div>
                            )}
                        {ticketsInfo &&
                            ticketsInfo.numConfirmedTickets > 0 &&
                            ticketsInfo.numConfirmedTickets < +snapshotInfo?.maxTicketsPerUser && (
                                <div className="text-center mt-3">
                                    <p className="text-green mb-0">Tickets bought: {ticketsInfo.numConfirmedTickets}.</p>
                                    <p className="text-white">
                                        Remaining tickets: {ticketsInfo.ticketEntries.length - ticketsInfo.numConfirmedTickets}.
                                    </p>
                                </div>
                            )}
                        {started &&
                            !finished &&
                            ticketsInfo &&
                            ticketsInfo.numConfirmedTickets < ticketsInfo.ticketEntries.length && (
                                <EButton className="mx-auto" onClick={onBuyTicketsHandler}>
                                    Buy Tickets
                                </EButton>
                            )}
                        {finished && ticketsInfo?.numConfirmedTickets === 0 && (
                            <div className="text-center mt-3">
                                <p>No ticket confirmed.</p>
                            </div>
                        )}
                    </>
                ))}
            <ECountDownFooter
                started={started}
                finished={finished}
                startTimestamp={projectDetails?.generalMetrics?.buyTicketsStartTimestamp}
                endTimestamp={projectDetails?.generalMetrics?.buyTicketsEndTimestamp}
                onCompleteStartHandler={onCompleteStartHandler}
                onCompleteEndHandler={() => eventsService.emitWithDelay(EventsNames.RERENDER_LAUNCHPAD)}
            />

            {projectDetails && ticketsInfo && show && (
                <BuyTicketsModal
                    show
                    closeModalHandler={handleClose}
                    projectDetails={projectDetails}
                    maxTicketsToConfirm={ticketsInfo.ticketEntries.length - ticketsInfo.numConfirmedTickets}
                />
            )}
        </div>
    );
};

export default BuyTicketsCard;
