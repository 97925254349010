import React, { FC } from "react";
import { FontAwesomeIcon, FontAwesomeIconProps } from "@fortawesome/react-fontawesome";

export interface EIconProps extends FontAwesomeIconProps {
    iconColor?: string;
}

export const EIcon: FC<EIconProps> = ({ iconColor = "var(--entity-gray-second)", ...props }) => (
    <FontAwesomeIcon color={iconColor} {...props} />
);

export default EIcon;
