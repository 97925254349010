import React, { FC } from "react";
import styled, { css } from "styled-components";
import Number from "../../../../../../components/Price/Number";
import { NumberFormatProps } from "react-number-format";
import Placeholder from "./Placeholder";
import { BREAKPOINTS } from "../../../../../../assets/sass/breakpoints";

type DescribedNumberProps = {
    amount: number;
    description: string;
} & NumberFormatProps;

type PlaceholderProps = { showPlaceholder: true };

const Container = styled.div`
    width: 10rem;
    flex-direction: column;
    margin: 1rem auto 0 auto;
    @media (${BREAKPOINTS.desktop}) {
        margin-top: 0;
    }
`;

const commonFieldsStyles = css`
    width: 100%;
    justify-content: center;
    display: flex;
    align-items: center;
    text-align: center;
`;

export const Amount = styled(Number)`
    ${commonFieldsStyles};
    font-size: 1.714rem;
    color: white;
    padding-bottom: 0.714rem;
`;

export const Description = styled.div`
    ${commonFieldsStyles};
    font-size: 0.95rem;
    color: var(--light-gray);
`;

const DescribedNumber: FC<React.PropsWithChildren<DescribedNumberProps | PlaceholderProps>> = (props) => (
    <Container>
        {"showPlaceholder" in props ? (
            <Placeholder />
        ) : (
            <>
                <Amount suffix={props.suffix || "%"} value={props.amount} insertSpaceBeforeSuffix={false} />
                <Description>{props.description}</Description>
            </>
        )}
    </Container>
);

export default DescribedNumber;
