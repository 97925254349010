import { useQuery } from "@tanstack/react-query";
import { LaunchpadQueryKeys } from "../types/queryKeysTypes";
import { FinalKycStatus, InitKycStatus, KycStatusInfo } from "../../pages/Launchpad/kyc/launchpad.kyc.dtos";
import { useService } from "../../services/config/dependencyInjectorConfig";
import KycService from "../../services/KycService";

export const useGetKycStatus = (
    enabled = true,
    projectId: string,
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    onSuccessHandler?: (data: any) => void,
) => {
    const [kycService] = useService(KycService);
    return useQuery({
        enabled,
        queryKey: [LaunchpadQueryKeys.USER_KYC_STATUS],
        queryFn: () => kycService.getKycStatus(projectId),
        select: (kycInfo) => {
            const isRejected = isRejectedFromKyc(kycInfo);
            return {
                kycStatusInfo: kycInfo,
                isRejectedFromKyc: isRejected,
            };
        },
        onSuccess: onSuccessHandler,
    });
};

function isRejectedFromKyc(kycInfo: KycStatusInfo): boolean {
    if (!kycInfo.status) return true;
    return kycInfo.status
        ? kycInfo.status === FinalKycStatus.DENIED
        : [InitKycStatus.REJECTED, InitKycStatus.FINAL_REJECTED].includes(kycInfo.initStatus as InitKycStatus);
}
