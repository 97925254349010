import BaseService from "./BaseService";
import axios from "axios";
import { IcoRatings } from "../common/types/icoTypes";
import { IcoLayers, SocialLinks } from "../helpers/consts";
import {
    Softcap,
    Hardcap,
    HasKyc,
    IcoLayer,
    MaxTokenSupply,
    Name,
    PreSaleStartTimestamp,
    PublicSaleOfferedTokens,
    PublicSalePrice,
    PublicSaleStartTimestamp,
    Slogan,
    TokenName,
} from "../pages/Ico/ico.types";
import { icoStepThreeInitial } from "../pages/Ico/IcoStepThree";
import {
    Country,
    KycProvider,
    ProjectAboutDetails,
    ProjectGeneralDetails,
    ProjectInvestmentDetails,
    ProjectMetricsDetails,
    ProjectRoadmapEntry,
    ProjectSnapshotDetails,
    ProjectTeamDetails,
    ProjectTeamInfo,
    ProjectTiersDetails,
    SnapshotData,
    Tier,
} from "../pages/Launchpad/launchpad.types";
import { EditProjectMetricsDetails, EditProjectSpecificDetails } from "../pages/Launchpad/launchpad.edit.types";
import { OrderDirection } from "./LaunchpadService";
import { PaginationResp } from "../common/types/common.types";

interface GeneralKeyMetrics
    extends TokenName,
        MaxTokenSupply,
        Softcap,
        Hardcap,
        PreSaleStartTimestamp,
        PublicSaleStartTimestamp,
        HasKyc,
        PublicSaleOfferedTokens {
    category: IcoLayer["icoLayer"];
    publicSaleTokenPrice: PublicSalePrice["publicSalePrice"];
}

interface LaunchRoadmapEntry {
    timestamp: number;
    descriptionPlan: string[];
    isChecked: boolean;
}

export interface Project extends Name, Slogan {
    logo: string;
    featureImage: string;
    links: SocialLinks;
    ratings?: IcoRatings;
    generalMetrics: GeneralKeyMetrics;
    launchRoadmap?: LaunchRoadmapEntry[];
}

export const initialProject: Project = {
    name: "",
    slogan: "",
    logo: "",
    featureImage: "",
    generalMetrics: {
        category: IcoLayers.dApp,
        tokenName: "",
        maxTokenSupply: 0,
        publicSaleTokenPrice: 0,
        softcap: null,
        hardcap: null,
        hasKyc: false,
        publicSaleOfferedTokens: null,
    },
    ...icoStepThreeInitial,
};

export interface ProjectIdResp {
    projectId: string;
}

export enum StageStatus {
    NOT_STARTED = "NOT STARTED",
    IN_PROGRESS = "IN PROGRESS",
    COMPLETED = "COMPLETED",
}

export interface KycPeriodStatus {
    kycPeriodStatus: StageStatus;
}

export interface LotteryStagesStatuses {
    buyingTickets: StageStatus;
    winnerSelection: StageStatus;
    claimingTokens: StageStatus;
}

export interface SnapshotTierStatisticsDetails {
    level: number | null;
    count: number;
    percentage: string;
}

export interface AddressSnapshotDetails {
    address: string;
    status: string;
    snapshotData?: SnapshotData;
}

class ProjectsService extends BaseService {
    private readonly _entityBaseApi: string;

    constructor(entityApi: string) {
        super();
        this._entityBaseApi = `${entityApi}/projects`;
    }

    public createProjectForICO(id: string): Promise<ProjectIdResp> {
        return this.doCall(() => axios.post(`${this._entityBaseApi}`, { icoId: id }));
    }

    public retrieveProject(id: string): Promise<Project> {
        return this.doCall(() => axios.get(`${this._entityBaseApi}/${id}`));
    }

    public retrieveGeneralDetails(id?: string): Promise<ProjectGeneralDetails> {
        return this.doCall(() => axios.get(`${this._entityBaseApi}/general`, { params: { id } }));
    }

    public retrieveAboutDetails(id?: string): Promise<ProjectAboutDetails> {
        return this.doCall(() => axios.get(`${this._entityBaseApi}/about`, { params: { id } }));
    }

    public retrieveInvestmentDetails(id?: string): Promise<ProjectInvestmentDetails> {
        return this.doCall(() => axios.get(`${this._entityBaseApi}/investment`, { params: { id } }));
    }

    public retrieveInvestmentDetailsAdmin(id: string): Promise<ProjectInvestmentDetails> {
        return this.doCall(() => axios.get(`${this._entityBaseApi}/${id}/admin/investments`));
    }

    public retrieveProjectSnapshotDetails(id: string): Promise<ProjectSnapshotDetails> {
        return this.doCall(() => axios.get(`${this._entityBaseApi}/${id}/snapshot/data`));
    }

    public async downloadProjectSnapshotJson(id: string) {
        const respBlob = await this.doCall<Array<string>>(() =>
            axios.get(`${this._entityBaseApi}/${id}/snapshot/files`, { responseType: "blob" }),
        );
        const link = document.createElement("a");
        link.href = URL.createObjectURL(respBlob);
        link.setAttribute("download", id + "-snapshot.json");
        link.click();
        URL.revokeObjectURL(link.href);
    }

    public async retrieveTiersDetails(id?: string): Promise<ProjectTiersDetails> {
        return this.doCall(() => axios.get(`${this._entityBaseApi}/tiers`, { params: { id } }));
    }

    public retrieveSuccessfulLaunches(): Promise<ProjectGeneralDetails[]> {
        return this.doCall(() => axios.get(`${this._entityBaseApi}/successful-launches`));
    }

    public retrieveTeamDetails(id?: string): Promise<ProjectTeamDetails> {
        return this.doCall(() => axios.get(`${this._entityBaseApi}/team`, { params: { id } }));
    }

    public retrieveTeamDetailsForAdmins(id: string): Promise<ProjectTeamInfo> {
        return this.doCall(() => axios.get(`${this._entityBaseApi}/${id}/team`));
    }

    public saveTeamDetailsForAdmins(id: string, teamInfo: ProjectTeamInfo): Promise<ProjectTeamInfo> {
        return this.doCall(() => axios.post(`${this._entityBaseApi}/${id}/admin/team`, teamInfo));
    }

    public retrieveMetricsDetails(id?: string): Promise<ProjectMetricsDetails> {
        return this.doCall(() => axios.get(`${this._entityBaseApi}/metrics`, { params: { id } }));
    }

    public retrieveProjectRoadmapDetails(id?: string): Promise<ProjectRoadmapEntry[]> {
        return this.doCall(() => axios.get(`${this._entityBaseApi}/roadmap`, { params: { id } }));
    }

    public retrieveProjectSpecificDetailsForAdmins(id: string): Promise<EditProjectSpecificDetails> {
        return this.doCall(() => axios.get(`${this._entityBaseApi}/${id}/admin/details`));
    }

    public saveProjectSpecificDetailsForAdmins(
        id: string,
        generalInfo: EditProjectSpecificDetails,
    ): Promise<EditProjectSpecificDetails> {
        return this.doCall(() => axios.post(`${this._entityBaseApi}/${id}/admin/details`, generalInfo));
    }

    public retrieveProjectMetricsDetailsForAdmins(id: string): Promise<EditProjectMetricsDetails> {
        return this.doCall(() => axios.get(`${this._entityBaseApi}/${id}/admin/metrics`));
    }

    public saveProjectMetricsDetailsForAdmins(
        id: string,
        metricsInfo: EditProjectMetricsDetails,
    ): Promise<EditProjectMetricsDetails> {
        return this.doCall(() => axios.post(`${this._entityBaseApi}/${id}/admin/metrics`, metricsInfo));
    }

    public saveProjectInvestmentDetails(id: string, investmentInfo: ProjectInvestmentDetails): Promise<ProjectInvestmentDetails> {
        return this.doCall(() => axios.post(`${this._entityBaseApi}/${id}/admin/investments`, investmentInfo));
    }

    public saveTierDetails(id: string, tierInfo: ProjectTiersDetails): Promise<ProjectTiersDetails> {
        return this.doCall(() => axios.post(`${this._entityBaseApi}/${id}/admin/tiers`, tierInfo));
    }

    public getKycProviderCountries(kycProvider: KycProvider): Promise<Country[]> {
        return this.doCall(() => axios.get(`${this._entityBaseApi}/countries`, { params: { kycProvider } }));
    }

    public launchProject(projectId: string): Promise<Project> {
        return this.doCall(() => axios.post(`${this._entityBaseApi}/${projectId}/launch`));
    }

    public publishProject(projectId: string): Promise<Project> {
        return this.doCall(() => axios.post(`${this._entityBaseApi}/${projectId}/publish`));
    }

    public getKycPeriodStatus(projectId: string): Promise<KycPeriodStatus> {
        return this.doCall(() => axios.get(`${this._entityBaseApi}/${projectId}/kyc/status`));
    }

    public getLotteryStagesStatuses(projectId: string): Promise<LotteryStagesStatuses> {
        return this.doCall(() => axios.get(`${this._entityBaseApi}/${projectId}/lottery/status`));
    }

    public getProjectTiers(projectId: string): Promise<Tier[] | undefined> {
        return this.doCall(() => axios.get(`${this._entityBaseApi}/${projectId}/tiers`));
    }

    public getActiveLaunchpad(): Promise<ProjectGeneralDetails> {
        return this.doCall(() => axios.get(`${this._entityBaseApi}/active-launchpad`));
    }

    public getSnapshotTiersOverview(projectId: string): Promise<SnapshotTierStatisticsDetails[]> {
        return this.doCall(() => axios.get(`${this._entityBaseApi}/${projectId}/snapshot/tiers`));
    }

    public projectClearCache(): Promise<void> {
        return this.doCall(() => axios.post(`${this._entityBaseApi}/clear-cache`));
    }

    public getAllSnapshotsAddressesDetails(
        projectId: string,
        findBy?: string,
        findVal?: string | number | boolean,
        orderBy?: string,
        orderDir?: OrderDirection,
        from?: number,
        size?: number,
    ): Promise<PaginationResp<AddressSnapshotDetails>> {
        return this.doCall(() =>
            axios.get(`${this._entityBaseApi}/${projectId}/snapshot/addresses/all`, {
                params: {
                    findBy,
                    findVal,
                    orderBy,
                    orderDir,
                    from,
                    size,
                },
            }),
        );
    }
}

export default ProjectsService;
