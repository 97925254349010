import React, { FC } from "react";
import { Accordion } from "react-bootstrap";
import {
    LaunchpadDetailedInfo,
    LaunchpadStage,
    ProjectGeneralDetails,
    ProjectRoadmapEntry,
    ProjectTiersDetails,
} from "../launchpad.types";
import { About } from "./About";
import { Metrics } from "./Metrics";
import Team from "./Team/Team";
import Investment from "./Investment/Investment";
import AccordionItem from "./AccordionItem";
import Links from "./Metrics/Links";
import KYCInstructions from "./kycInstructions/KYCInstructions";
import { AccordionEventKey } from "react-bootstrap/AccordionContext";
import { useService } from "../../../services/config/dependencyInjectorConfig";
import ProjectsService from "../../../services/ProjectsService";
import { useQuery } from "@tanstack/react-query";

export enum LaunchpadAccordionsKeys {
    ABOUT = "about",
    METRICS = "metrics",
    TEAM = "team",
    INVESTMENT = "investment",
    FAQ = "faq",
    NONE = "none",
}

interface DetailedInformationProps {
    projectId: string | undefined;
    projectName?: string;
    tiersDetails: ProjectTiersDetails | undefined;
    launchRoadmap: ProjectGeneralDetails["launchRoadmap"];
    projectRoadmap?: ProjectRoadmapEntry[];
    activeKey?: LaunchpadAccordionsKeys;
    onSelect: (key: LaunchpadAccordionsKeys) => void;
    currentStage?: LaunchpadStage | null;
    tokensPerTicket?: number;
}

const DetailedInformation: FC<React.PropsWithChildren<DetailedInformationProps>> = ({
    projectId,
    projectName,
    tiersDetails,
    launchRoadmap,
    projectRoadmap = undefined,
    activeKey = LaunchpadAccordionsKeys.NONE,
    onSelect,
    currentStage,
    tokensPerTicket,
}) => {
    const [projectsService] = useService(ProjectsService);

    const { data: metricsInfo, isLoading: isLoadingMetricsInfo } = useQuery({
        enabled: activeKey === LaunchpadAccordionsKeys.METRICS,
        queryKey: ["project-metrics-details"],
        queryFn: () => projectsService.retrieveMetricsDetails(projectId),
        staleTime: Infinity,
    });

    const onSelectAccordionHandler = (key: AccordionEventKey) => {
        if (!key || key.length === 0) {
            onSelect(LaunchpadAccordionsKeys.NONE);
        } else if (key.length === 1) {
            onSelect(key[0] as LaunchpadAccordionsKeys);
        } else {
            onSelect(key[1] as LaunchpadAccordionsKeys);
        }
    };

    return (
        <Accordion defaultActiveKey={[activeKey]} activeKey={[activeKey]} onSelect={onSelectAccordionHandler} alwaysOpen>
            <AccordionItem
                id="about"
                eventKey={LaunchpadAccordionsKeys.ABOUT}
                header={LaunchpadDetailedInfo.about}
                projectRoadmap={projectRoadmap}
            >
                <About projectId={projectId} isActive={activeKey === LaunchpadAccordionsKeys.ABOUT} />
            </AccordionItem>
            <AccordionItem
                id="metrics"
                eventKey={LaunchpadAccordionsKeys.METRICS}
                header={LaunchpadDetailedInfo.metrics}
                projectRoadmap={projectRoadmap}
                CustomAdditionalItem={() => (
                    <>
                        {!isLoadingMetricsInfo && (
                            <Links
                                website={metricsInfo?.website as string | undefined}
                                whitepaper={metricsInfo?.whitepaper as string | undefined}
                                litepaper={metricsInfo?.litepaper as string | undefined}
                                showPlaceholder={!metricsInfo}
                            />
                        )}
                    </>
                )}
            >
                <Metrics
                    projectName={projectName}
                    metricsInfo={metricsInfo}
                    isActive={activeKey === LaunchpadAccordionsKeys.METRICS}
                    projectId={projectId}
                    tokensPerTicket={tokensPerTicket}
                />
            </AccordionItem>
            <AccordionItem
                id="team"
                eventKey={LaunchpadAccordionsKeys.TEAM}
                header={LaunchpadDetailedInfo.team}
                showAdditionalInfo={false}
            >
                <Team projectId={projectId} isActive={activeKey === LaunchpadAccordionsKeys.TEAM} />
            </AccordionItem>
            <AccordionItem
                id="investment"
                eventKey={LaunchpadAccordionsKeys.INVESTMENT}
                header={LaunchpadDetailedInfo.investment}
                projectRoadmap={projectRoadmap}
            >
                <Investment
                    tiersInfo={tiersDetails}
                    projectId={projectId}
                    launchRoadmap={launchRoadmap}
                    isActive={activeKey === LaunchpadAccordionsKeys.INVESTMENT}
                />
            </AccordionItem>
            {currentStage === LaunchpadStage.KYC && (
                <AccordionItem
                    id="faq"
                    eventKey={LaunchpadAccordionsKeys.FAQ}
                    header={LaunchpadDetailedInfo.kycInstructions}
                    showAdditionalInfo={false}
                >
                    <KYCInstructions />
                </AccordionItem>
            )}
        </Accordion>
    );
};

export default DetailedInformation;
