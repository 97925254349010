import axios from "axios";
import BaseService from "./BaseService";
import { ConditionsAgreement, User, UserNotifications, ValidateHashResponse } from "../common/types/userTypes";
import { UsersEntity } from "../common/entities/UsersEntity";

export interface UserFilterParams {
    size: number;
    from: number;
    role: string;
    search: string;
}

export class UsersService extends BaseService {
    private readonly _entityApi: string;
    private readonly _launchpadApi: string;

    constructor(entityApi: string, launchpadApi: string) {
        super();
        this._entityApi = entityApi;
        this._launchpadApi = launchpadApi;
    }

    retrieveAll(params: UserFilterParams): Promise<UsersEntity[]> {
        return this.doCall(() => axios.get(`${this._entityApi}/user`, { params }));
    }

    getById(id: string): Promise<UsersEntity> {
        return this.doCall(() => axios.get(`${this._entityApi}/user/${id}`));
    }

    public async setConditionsAgreement(): Promise<ConditionsAgreement> {
        return this.doCall(() => axios.post(`${this._entityApi}/user/conditions-agreement`));
    }

    public async checkUser(publicAddress: string): Promise<User> {
        return this.doCall(() => axios.get<User>(`${this._entityApi}/user/check/${publicAddress}`));
    }

    public async updateEmail(userId: string, email: string): Promise<User> {
        return this.doCall(() => axios.put<User>(`${this._entityApi}/user/${userId}/email`, { email }));
    }

    public async updateRoles(userId: string, roles: string[]): Promise<User> {
        return this.doCall(() => axios.put<User>(`${this._entityApi}/user/${userId}/roles`, { roles }));
    }

    public async updateUsername(userId: string, username: string): Promise<User> {
        return this.doCall(() => axios.put<User>(`${this._entityApi}/user/${userId}/username`, { username }));
    }

    public async updateNotificationFlags(userId: string, userNotifications: UserNotifications): Promise<User> {
        return this.doCall(() => axios.put<User>(`${this._entityApi}/user/${userId}/notifications`, userNotifications));
    }

    public async removeUser(userId: string): Promise<User> {
        return this.doCall(() => axios.delete<User>(`${this._entityApi}/user/${userId}`));
    }

    public async confirmEmail(): Promise<User> {
        return this.doCall(() => axios.post(`${this._entityApi}/user/email/confirm`));
    }

    public async validateEmailHash(
        hash: string,
        scope: "settings" | "launchpad" | "xDay-registration",
    ): Promise<ValidateHashResponse> {
        switch (scope) {
            case "settings":
                return this.doCall(() => axios.post(`${this._entityApi}/user/email/validate-hash`, { hash }));
            case "xDay-registration":
                return this.doCall(() => axios.post(`${this._entityApi}/user/x-day/email/validate-hash`, { hash }));
            case "launchpad":
                return this.doCall(() => axios.post(`${this._launchpadApi}/email/validate-hash`, { hash }));
        }
    }

    public registerXDayUser(email: string): Promise<User> {
        return this.doCall(() => axios.post<User>(`${this._entityApi}/user/x-day/email/register`, { email }));
    }
}

export default UsersService;
