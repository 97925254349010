import React, { FC } from "react";
import ECountDown, { ECountDownProps } from "./ECountDown";

interface ECountDownWrapperProps extends ECountDownProps {
    scope: "starts" | "ends";
    className?: string;
}

const startsText = "Starts in:";
const endsText = "Ends in:";

const ECountDownWrapper: FC<React.PropsWithChildren<ECountDownWrapperProps>> = ({ time, scope, className, ...props }) => {
    return (
        <div className={`mt-5 ${className ?? ""}`}>
            <p>{scope === "starts" ? startsText : endsText}</p>
            <ECountDown time={time ?? 0} {...props} />
        </div>
    );
};

export default ECountDownWrapper;
