import React, { useState } from "react";
import { Container } from "react-bootstrap";
import { faLock } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import styles from "../../pages/UnlockPage/login.module.scss";
import { BackgroundDecorations } from "../../components/BackgroundDecorations";
import { WebWallet } from "../../assets/img/WebWallet";
import AuthWeb3Modal from "./AuthWeb3Modal";
import { useNavigate } from "react-router-dom";
import { routeNames } from "../../routes/routes";

const Auth = () => {
    const [showModal, setShowModal] = useState(false);
    const navigate = useNavigate();

    return (
        <div className="mt-5">
            <BackgroundDecorations />
            <Container className={styles.unlock}>
                <div className={styles.wrapper}>
                    <strong className={styles.heading}>Connect your wallet</strong>

                    <div className={styles.description}>
                        <FontAwesomeIcon className="me-2" icon={faLock} />
                        Scam/Phishing verification: <span className="text-green">https://</span>app.entity.global
                    </div>

                    <div className={styles.connects}>
                        <button
                            onClick={() => navigate(routeNames.authLogin)}
                            className="dapp-core-component__main__btn dapp-core-component__main__btn-primary dapp-core-component__main__px-4 dapp-core-component__main__m-1 dapp-core-component__main__mx-3 dapp-default-login-button dapp-web-wallet-login"
                        >
                            <span className={styles.connect}>
                                <span className={styles.title}>Web2</span>

                                <span className={styles.icon} style={{ background: "#000000" }}>
                                    <WebWallet />
                                </span>

                                <span className={styles.name}>Web2 Authentication</span>
                            </span>
                        </button>
                        <button
                            onClick={() => setShowModal(true)}
                            className="dapp-core-component__main__btn dapp-core-component__main__btn-primary dapp-core-component__main__px-4 dapp-core-component__main__m-1 dapp-core-component__main__mx-3 dapp-default-login-button dapp-web-wallet-login"
                        >
                            <span className={styles.connect}>
                                <span className={styles.title}>Web3</span>
                                <span className={styles.icon} style={{ background: "#000000" }}>
                                    <WebWallet />
                                </span>
                                <span className={styles.name}>Web3 Authentication</span>
                            </span>
                        </button>
                    </div>
                </div>
            </Container>
            <AuthWeb3Modal show={showModal} closeHandler={() => setShowModal(false)} />
        </div>
    );
};

export default Auth;
