import { Address } from "@multiversx/sdk-core/out";

export const PUBLIC_WALLET_ADDRESS_REGEX = /^(erd1)([a-z0-9]{58})$/gm;

class AddressUtils {
    public static isValidAddress(address?: string): boolean {
        if (!address) {
            return false;
        }
        try {
            new Address(address);
            return true;
        } catch (_) {
            return false;
        }
    }

    public static isValidAndNotScAddress(address?: string): boolean {
        if (!address) {
            return false;
        }
        return !AddressUtils.isScAddress(address);
    }

    public static isValidScAddress(address?: string): boolean {
        if (!address) {
            return false;
        }
        return AddressUtils.isScAddress(address);
    }

    public static isScAddress(address: string): boolean {
        try {
            return new Address(address).isContractAddress();
        } catch (_) {
            return false;
        }
    }
}

export default AddressUtils;
