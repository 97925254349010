import React, { FC } from "react";
import { Card } from "react-bootstrap";
import ProjectCardTitle, { ProjectCardTitleProps } from "./ProjectCardTitle";
import styled from "styled-components";
import { ECard } from "../Card";

export interface ProjectInfoCardProps extends ProjectCardTitleProps {
    className?: string;
    testId: string;
}

const CardContainer = styled(ECard)`
    padding: 2.142rem !important;
`;

const CardBody = styled(Card.Body)`
    padding: 0;
`;

const BodyContainer = styled.div`
    padding-top: 2.6rem;
`;

const ProjectInfoCard: FC<React.PropsWithChildren<ProjectInfoCardProps>> = ({
    titleText,
    titleAdditionalInfo,
    className = "",
    testId = "project-info-card",
    children,
    ...props
}) => {
    return (
        <CardContainer className={className} testId={testId} {...props}>
            <CardBody>
                <ProjectCardTitle titleText={titleText} titleAdditionalInfo={titleAdditionalInfo} />
                <BodyContainer>{children}</BodyContainer>
            </CardBody>
        </CardContainer>
    );
};

export default ProjectInfoCard;
