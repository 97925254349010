import React, { FC, useEffect, useMemo, useState } from "react";
import { LaunchpadCardBaseProps, ProjectTiersDetails, Tier } from "../launchpad.types";
import { faCameraRetro } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { ELinkButton } from "../../../components/Buttons";
import { routeNames } from "../../../routes/routes";
import { useGetLoginInfo } from "@multiversx/sdk-dapp/hooks";
import { DateUtils } from "../../../common";
import { useService } from "../../../services/config/dependencyInjectorConfig";
import { LaunchpadService } from "../../../services/LaunchpadService";
import { network } from "../../../config";
import Number from "../../../components/Price/Number";
import EventsService, { EventsNames } from "../../../services/EventsService";
import ECountDownFooter from "../../../common/components/ECountDown/ECountDownFooter";
import { useContext } from "../../../context";
import ESpinner from "../../../components/Spinner/ESpinner";

const ascSortTiersPredicate = (t1: Tier, t2: Tier) => +t1.minStakedAmount - +t2.minStakedAmount;
const descSortTiersPredicate = (t1: Tier, t2: Tier) => +t2.minStakedAmount - +t1.minStakedAmount;

interface SnapshotCardProps extends LaunchpadCardBaseProps {
    snapshotInfo: Tier | null;
    snapshotStakingValue?: number;
    tiersDetails?: ProjectTiersDetails;
    testId?: string;
}

const SnapshotCard: FC<React.PropsWithChildren<SnapshotCardProps>> = ({
    projectDetails,
    tiersDetails,
    snapshotInfo,
    snapshotStakingValue,
    isRejected,
    testId = "snapshot-card",
}) => {
    const [started, setStarted] = useState<boolean | undefined>(undefined);
    const [finished, setFinished] = useState<boolean | undefined>(undefined);
    const [stakingValue, setStakingValue] = useState<number | undefined>(undefined);

    const { isLoggedIn } = useGetLoginInfo();
    const { jwtToken } = useContext();

    const [launchpadService] = useService(LaunchpadService);
    const [eventsService] = useService(EventsService);

    useEffect(() => {
        init();
    }, []);

    const init = async () => {
        if (!projectDetails) {
            return;
        }

        const [snapshotHasStarted, snapshotHasFinished] = DateUtils.getPeriodStatusFlags(
            projectDetails.generalMetrics.snapshotStartTimestamp,
            projectDetails.generalMetrics.snapshotEndTimestamp,
        );
        if (snapshotHasFinished && jwtToken) {
            const stakingResp = await launchpadService.getStakingValue(projectDetails.id);
            setStakingValue(stakingResp.stakingValue);
        }
        setStarted(snapshotHasStarted);
        setFinished(snapshotHasFinished);
    };

    const onCompleteStartHandler = () => {
        setStarted(true);
        eventsService.emitWithDelay(EventsNames.RERENDER_LAUNCHPAD);
    };

    const infoSection = useMemo(() => {
        if (!stakingValue || isRejected || !tiersDetails?.tiers) return;

        const nextTier = tiersDetails?.tiers.sort(ascSortTiersPredicate).find((tier) => +tier.minStakedAmount > stakingValue);
        const currentTier = tiersDetails?.tiers
            .sort(descSortTiersPredicate)
            .find((tier) => +tier.minStakedAmount <= stakingValue);

        const currentState = (
            <div data-testid={testId}>
                <p className="mb-1">
                    Current staking value:{" "}
                    <strong>
                        {stakingValue} {network.egldLabel}
                    </strong>
                    .
                </p>
                <p className="mb-1">
                    Predicted snapshot value:{" "}
                    <strong>
                        {stakingValue} {network.egldLabel}
                    </strong>
                    .
                </p>
                {currentTier && (
                    <p>
                        Predicted tier number: <strong>{currentTier.no}</strong>.
                    </p>
                )}
            </div>
        );
        if (!nextTier) {
            return currentState;
        }
        const remainingStakingValue = +nextTier.minStakedAmount - stakingValue;
        if (remainingStakingValue < stakingValue / 2 || +nextTier.no === tiersDetails?.tiers.length) {
            const details =
                currentTier && currentTier.no !== nextTier.no ? (
                    <span>
                        Tier number <strong>{nextTier.no}</strong> grants you{" "}
                        <strong>{+nextTier.maxTicketsPerUser - +currentTier.maxTicketsPerUser}</strong> more tickets for a total
                        of <strong>{nextTier.maxTicketsPerUser}</strong>.
                    </span>
                ) : (
                    <span>
                        Tier number <strong>{nextTier.no}</strong> grants you <strong>{nextTier.maxTicketsPerUser}</strong>{" "}
                        lottery tickets.
                    </span>
                );
            return (
                <>
                    {currentState}
                    <p className="mt-4">
                        Stake{" "}
                        <strong>
                            {remainingStakingValue.toFixed(2)} {network.egldLabel}
                        </strong>{" "}
                        more to reach the next tier. {details}
                    </p>
                </>
            );
        }
        return currentState;
    }, [stakingValue]);

    const getStatusDescription = () => {
        if (finished && !snapshotInfo) {
            return <p className="text-red text-center">Unfortunately, you did not pass the snapshot process.</p>;
        }
        if (isRejected) {
            return (
                <p className="text-warning">
                    You are not eligible for the snapshot stage or you KYC application is not finally reviewed yet.
                </p>
            );
        }
    };

    if (started === undefined && finished === undefined) {
        return <ESpinner />;
    }

    if (!isLoggedIn) {
        return (
            <>
                <div className="d-flex justify-content-center align-items-center" data-testid={testId}>
                    <ELinkButton to={routeNames.unlock} testId="login-btn" className="text-uppercase">
                        Login
                    </ELinkButton>
                </div>
                <div className="text-center pt-1 pb-3" data-testid={testId}>
                    <ECountDownFooter
                        started={started}
                        finished={finished}
                        startTimestamp={projectDetails?.generalMetrics?.snapshotStartTimestamp}
                        endTimestamp={projectDetails?.generalMetrics?.snapshotEndTimestamp}
                        onCompleteStartHandler={onCompleteStartHandler}
                        onCompleteEndHandler={() => eventsService.emitWithDelay(EventsNames.RERENDER_LAUNCHPAD)}
                    />
                </div>
            </>
        );
    }

    return (
        <div className="text-center pt-1 pb-3" data-testid={testId}>
            <FontAwesomeIcon size={"3x"} className="text-green mb-3" icon={faCameraRetro} />
            {getStatusDescription()}
            <ECountDownFooter
                started={started}
                finished={finished}
                startTimestamp={projectDetails?.generalMetrics?.snapshotStartTimestamp}
                endTimestamp={projectDetails?.generalMetrics?.snapshotEndTimestamp}
                onCompleteStartHandler={onCompleteStartHandler}
                onCompleteEndHandler={() => eventsService.emitWithDelay(EventsNames.RERENDER_LAUNCHPAD)}
                beforeStartedChildren={infoSection}
                inProgressChildren={<h4>Snapshot in progress.</h4>}
            />
            {finished && (
                <>
                    <h4>Snapshot finished.</h4>
                    {snapshotInfo && snapshotInfo.no && snapshotStakingValue && (
                        <>
                            <p className="text-green text-center">You successfully passed the snapshot process!</p>
                            <p className="text-white text-center">
                                Snapshot staking value:{" "}
                                <strong>
                                    <Number value={snapshotStakingValue} suffix={tiersDetails?.fundingToken} />
                                </strong>
                            </p>
                        </>
                    )}
                </>
            )}
        </div>
    );
};

export default SnapshotCard;
