import React from "react";
import styled from "styled-components";
import { Col, Container, Row } from "react-bootstrap";
import NewsLetterSubscription from "../../components/NewsLetterSubscription/NewsLetterSubscription";
import { BackgroundDecorations } from "../../components/BackgroundDecorations";
import MetaDecorator from "../../common/components/MetaDecorator";
import { seoData } from "../../helpers/consts/SEO";
import { routeNames } from "../../routes/routes";
import { PageHeader } from "../../components/PageHeader";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faEnvelope } from "@fortawesome/free-solid-svg-icons";

const Header = styled.div`
    display: flex;
    padding: 0 !important;
    margin-bottom: 3rem;

    h1 {
        margin: 0;
    }
`;

const EContainer = styled(Container)`
    h3 {
        font-size: 1.5rem;
        color: var(--secondary-text);
        padding: 3.4rem 0;
    }
`;

const EHr = styled.hr`
    color: var(--entity-border-green);
    margin-top: 4em;
`;

const EList = styled.ul`
    padding: 0;

    li {
        list-style-type: none;
        font-size: 1.1rem;
        padding: 0.5rem 0;

        a {
            color: var(--page-block-title-color);

            svg {
                color: #156a72;
                margin-right: 0.7rem;
                font-size: 1.4rem;
            }
        }
    }
`;

const NewsletterPage = () => {
    return (
        <>
            <MetaDecorator
                title={seoData.newsletter.title}
                description={seoData.newsletter.description}
                keywords={seoData.newsletter.keywords}
                canonical={routeNames.newsletter}
            />
            <BackgroundDecorations opacity={0.1} />
            <EContainer>
                <Row className="py-0 py-sm-2 pt-md-4 mt-3">
                    <Col lg={6}>
                        <Header className="justify-content-center justify-content-sm-start">
                            <PageHeader>Entity Newsletter</PageHeader>
                        </Header>
                        <h3>Sign up to receive the latest Entity updates!</h3>
                        <NewsLetterSubscription />
                    </Col>
                    <EHr />
                </Row>
                <Row className="mb-5">
                    <Col lg={6}>
                        <h3>What&apos;s been sent already ...</h3>
                        <EList>
                            <li>
                                <a href="https://mailchi.mp/a1553e00fb8e/enjoy-the-latest-news-from-entity-defi-multiversx">
                                    <FontAwesomeIcon icon={faEnvelope} /> Entity 2023 Preview: Prepare for Liftoff!
                                </a>
                            </li>
                        </EList>
                    </Col>
                </Row>
            </EContainer>
        </>
    );
};

export default NewsletterPage;
