import React from "react";
import { EPlaceholder } from "../../../../components/Placeholder";
import ContentContainer from "../ContentContainer";
import { ColContainer, RowContainer, TextContainer } from "./TeamSection";
import TeamCard from "../../components/TeamCard";

const Placeholder = () => (
    <ContentContainer title="Team" data-testid="team-placeholder">
        <EPlaceholder as={TextContainer} numberOfLines={3} />
        <RowContainer className="mt-3 px-0">
            <ColContainer md={3}>
                <EPlaceholder as={TeamCard} />
            </ColContainer>
            <ColContainer md={3}>
                <EPlaceholder as={TeamCard} />
            </ColContainer>
            <ColContainer md={3}>
                <EPlaceholder as={TeamCard} />
            </ColContainer>
        </RowContainer>
    </ContentContainer>
);

export default Placeholder;
