import { ProjectTiersDetails } from "../../pages/Launchpad/launchpad.types";
import React, { FC, memo } from "react";
import TiersTableContainer from "../../pages/Launchpad/components/TiersTableContainer";
import EModal from "../common/EModal";
import { BaseModalProps } from "../../common/types/common.types";
import styled from "styled-components";

export interface TiersModalProps extends BaseModalProps {
    tiersInfo?: ProjectTiersDetails;
}

export const TierModal = styled(EModal)`
    font-weight: normal;
`;

const TiersModal: FC<React.PropsWithChildren<TiersModalProps>> = ({ show, tiersInfo, closeModalHandler }) => {
    return (
        <TierModal show={show} onHide={closeModalHandler} centered size="lg">
            <EModal.Header closeButton>
                <EModal.Title>
                    <h2>Eligibility</h2>
                </EModal.Title>
            </EModal.Header>
            <EModal.Body>
                {(!tiersInfo || !tiersInfo.tiers || tiersInfo.tiers.length === 0 || !tiersInfo.fundingToken) && (
                    <p>No tiers defined yet.</p>
                )}
                {tiersInfo?.tiers && tiersInfo.tiers.length > 0 && tiersInfo.fundingToken && (
                    <TiersTableContainer tiersInfo={tiersInfo} />
                )}
            </EModal.Body>
        </TierModal>
    );
};

export default memo(TiersModal);
