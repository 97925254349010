import React, { memo, useEffect, useState } from "react";
import styled from "styled-components";
import { Col } from "react-bootstrap";
import { Corner } from "../../../components/Hexagon/helpers/hex.types";
import LaunchpadImg from "../../../assets/img/decorElements/launchpad.png";
import EsdtImg from "../../../assets/img/decorElements/esdt.png";
import DefiAnalyticsImg from "../../../assets/img/decorElements/defi-analytics.png";
import PortfolioManagementImg from "../../../assets/img/decorElements/portfolio-manager.png";
import ProjectMonitorImg from "../../../assets/img/decorElements/project-monitor.png";
import RewardStrategiesImg from "../../../assets/img/decorElements/reward-strategies.png";
import TaxToolsImg from "../../../assets/img/decorElements/tax-tools.png";
import MetricsImg from "../../../assets/img/decorElements/metrics.png";
import { ProjectGeneralDetails } from "../../Launchpad/launchpad.types";
import { BREAKPOINTS } from "../../../assets/sass/breakpoints";
import { Hexagon } from "../../../components/Hexagon/HexTemplate";
import TaxTool from "./moduleDescriptions/TaxTool";
import DefiAnalytics from "./moduleDescriptions/DefiAnalytics";
import Portfolio from "./moduleDescriptions/Portfolio";
import RewardStrategies from "./moduleDescriptions/RewardStrategies";
import Metrics from "./moduleDescriptions/Metrics";
import Esdt from "./moduleDescriptions/Esdt";
import Launchpad from "./moduleDescriptions/Launchpad";
import ProjectMonitor from "./moduleDescriptions/ProjectMonitor";
import { NextLaunchpad } from "./NextLaunchpad";
import { routeNames } from "../../../routes/routes";
import _ from "lodash";
import InvestorsHeaderContent from "./InvestorsHeaderContent";
import InvestorsMainContent from "./InvestorsMainContent";
import { investorsSvgHeight, investorsSvgWidth } from "./helper/consts";
import { useGetProjectGeneralDetails } from "../../../common/hooks";

const title = "Investors";
const description =
    "Entity gives DeFi investors smarter ways to manage their assets, conduct research, and diversify their holdings in the most high-potential and trustworthy MultiversX projects.";

const defaultHexColor = "#51E2DF14";
const styles = [
    { backgroundColor: "#51E2DF40" },
    { backgroundColor: "#51E2DF26" },
    { backgroundColor: defaultHexColor },
    { backgroundColor: "#0C2423" },
];
const style = styles[0];
export const points = [Corner.f0, Corner.f1, Corner.f2, Corner.f3, Corner.f4, Corner.f5, Corner.f0];

const Container = styled.div`
    margin-top: 2rem;
    padding-bottom: 2rem;
    position: relative;

    @media (${BREAKPOINTS.tablet}) {
        margin-top: 10.75rem;
        padding-bottom: 8.75rem;
    }
`;

const EmptyHex = styled.div`
    margin-top: 7rem;
    width: 100%;
    height: 100%;
    max-height: 57.143rem;
    clip-path: polygon(70% 0, 100% 0, 100% 100%, 70% 100%, 0 50%);
    -webkit-clip-path: polygon(70% 0, 100% 0, 100% 100%, 70% 100%, 0 50%);
    background-color: ${defaultHexColor};
    opacity: 1;
`;

const HexDecor = styled(Col)`
    display: none;
    margin-bottom: -10rem;

    @media (${BREAKPOINTS.tablet}) {
        display: block;
    }
`;

type LinkInfoItem = { text: string } & ({ to: string; isAvailable: true } | { to?: string; isAvailable: false });

export type LinkInfoType = LinkInfoItem | [LinkInfoItem, LinkInfoItem];

export interface InvestorsHexagon extends Hexagon {
    detailedInfo?: () => JSX.Element;
    linkInfo?: LinkInfoType;
}

const findHexagon = (q: number, r: number, hexagons: InvestorsHexagon[]) => {
    return hexagons.filter((h) => h.q === q && h.r === r)[0];
};

const memoCompareFunction = (prev: InvestorsHexagon, next: InvestorsHexagon) => {
    return _.isEqual(prev.points, next.points);
};

const Investors = () => {
    const [activeModule, setActiveModule] = useState([1, 1]);
    const getPoints = (q: number, r: number): Corner[] => {
        if (activeModule[0] === q && activeModule[1] === r) {
            return points;
        } else {
            return [];
        }
    };

    const hexagons: InvestorsHexagon[] = [
        { q: 0, r: 0, style: styles[1] },
        { q: 0, r: 1, style: styles[2] },
        {
            q: 1,
            r: 0,
            style,
            image: ProjectMonitorImg,
            imageSize: {
                yOffset: investorsSvgHeight / 20,
                xOffset: investorsSvgWidth / 20,
                width: investorsSvgWidth * 0.85,
                height: investorsSvgHeight * 0.85,
            },
            points: getPoints(1, 0), // TODO: try to use "this"
            onClick: async function () {
                return;
            },
            detailedInfo: () => <ProjectMonitor />,
            linkInfo: {
                text: "Discover the Project Monitor",
                to: routeNames.projectMonitor,
                isAvailable: true,
            },
            pulsation: true,
            memoCompareFunction: memoCompareFunction,
        },
        {
            q: 1,
            r: 1,
            style,
            image: LaunchpadImg,
            imageSize: {
                xOffset: -investorsSvgWidth / 20,
                yOffset: investorsSvgHeight / 20,
                width: investorsSvgWidth,
                height: investorsSvgHeight,
            },
            points: getPoints(1, 1),
            onClick: async function () {
                onClickHandler(this.q, this.r);
            },
            detailedInfo: () => <Launchpad />,
            linkInfo: {
                text: "Enter the Launchpad",
                to: routeNames.launchpad,
                isAvailable: true,
            },
            pulsation: true,
            memoCompareFunction: memoCompareFunction,
        },
        {
            q: 2,
            r: 0,
            style: styles[3],
            image: EsdtImg,
            imageSize: {
                yOffset: investorsSvgHeight / 8,
                xOffset: investorsSvgWidth / 8,
                width: investorsSvgWidth * 0.7,
                height: investorsSvgHeight * 0.7,
            },
            points: getPoints(2, 0),
            onClick: async function () {
                return;
            },
            detailedInfo: () => <Esdt />,
            linkInfo: {
                text: "Explore EGLD/ESDT",
                isAvailable: false,
            },
            pulsation: true,
            memoCompareFunction: memoCompareFunction,
        },
        {
            q: 2,
            r: 1,
            style,
            image: MetricsImg,
            imageSize: {
                yOffset: investorsSvgHeight / 5,
                xOffset: investorsSvgWidth / 5,
                width: investorsSvgWidth * 0.6,
                height: investorsSvgHeight * 0.6,
            },
            points: getPoints(2, 1),
            onClick: async function () {
                return;
            },
            detailedInfo: () => <Metrics />,
            linkInfo: {
                text: "Discover Staking Provider Metrics",
                to: routeNames.home,
                isAvailable: false,
            },
            pulsation: true,
            memoCompareFunction: memoCompareFunction,
        },
        {
            q: 3,
            r: -1,
            style,
            image: RewardStrategiesImg,
            imageSize: {
                yOffset: -investorsSvgHeight / 10,
                xOffset: -investorsSvgWidth / 10,
                width: investorsSvgWidth * 1.2,
                height: investorsSvgHeight * 1.2,
            },
            points: getPoints(3, -1),
            onClick: async function () {
                return;
            },
            detailedInfo: () => <RewardStrategies />,
            linkInfo: {
                text: "Discover Reward Strategies",
                to: routeNames.home,
                isAvailable: false,
            },
            pulsation: true,
            memoCompareFunction: memoCompareFunction,
        },
        {
            q: 3,
            r: 0,
            style,
            image: PortfolioManagementImg,
            imageSize: {
                yOffset: -investorsSvgHeight / 20,
                xOffset: -investorsSvgWidth / 20,
                width: investorsSvgWidth * 1.1,
                height: investorsSvgHeight * 1.1,
            },
            points: getPoints(3, 0),
            onClick: async function () {
                onClickHandler(this.q, this.r);
            },
            detailedInfo: () => <Portfolio />,
            linkInfo: {
                text: "Enter the Portfolio",
                to: routeNames.portfolio,
                isAvailable: true,
            },
            pulsation: true,
            memoCompareFunction: memoCompareFunction,
        },
        {
            q: 4,
            r: -1,
            style: styles[2],
            image: DefiAnalyticsImg,
            imageSize: {
                yOffset: investorsSvgHeight / 6,
                xOffset: investorsSvgWidth / 6,
                width: investorsSvgWidth * 0.65,
                height: investorsSvgHeight * 0.65,
            },
            points: getPoints(4, -1),
            onClick: async function () {
                return;
            },
            detailedInfo: () => <DefiAnalytics />,
            linkInfo: {
                text: "Explore DeFi & Analytics",
                isAvailable: false,
            },
            pulsation: true,
            memoCompareFunction: memoCompareFunction,
        },
        {
            q: 4,
            r: 0,
            style,
            image: TaxToolsImg,
            imageSize: {
                yOffset: -investorsSvgHeight / 10,
                xOffset: -investorsSvgWidth / 10,
                width: investorsSvgWidth * 1.2,
                height: investorsSvgHeight * 1.2,
            },
            points: getPoints(4, 0),
            onClick: async function () {
                return;
            },
            detailedInfo: () => <TaxTool />,
            linkInfo: {
                text: "Discover the Tax Tools",
                to: routeNames.home,
                isAvailable: false,
            },
            pulsation: true,
            memoCompareFunction: memoCompareFunction,
        },
    ];

    const [detailedText, setDetailedText] = useState(findHexagon(activeModule[0], activeModule[1], hexagons)!.detailedInfo);
    const [moduleLinkInfo, setModuleLinkInfo] = useState<LinkInfoType>();

    const { data: projectGeneralDetails, isLoading } = useGetProjectGeneralDetails();

    useEffect(() => {
        const hex = findHexagon(activeModule[0], activeModule[1], hexagons);
        setDetailedText(hex!.detailedInfo);
        setModuleLinkInfo(hex!.linkInfo);
    }, [activeModule]);

    const onClickHandler = async (q: number, r: number) => {
        setActiveModule([q, r]);
    };

    const getHexagons = () => {
        return _.cloneDeep(hexagons);
    };

    return (
        <Container className="d-flex flex-column" data-testid="investors">
            <div className="d-flex flex-nowrap">
                <Col xs={12} md={10}>
                    <InvestorsHeaderContent title={title} description={description} />
                    <InvestorsMainContent
                        hexagons={getHexagons()}
                        activeModule={activeModule}
                        detailedText={detailedText}
                        moduleLinkInfo={moduleLinkInfo}
                    />
                </Col>
                <HexDecor xs={0} md={2} className="p-0">
                    <EmptyHex />
                </HexDecor>
            </div>
            {!isLoading && <NextLaunchpad projectGeneralDetails={projectGeneralDetails as ProjectGeneralDetails} />}
        </Container>
    );
};

export default memo(Investors);
