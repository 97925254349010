import React from "react";

interface TelegramIconProps {
    width?: number;
    height?: number;
}

const TelegramIcon = ({ width = 14, height = 14 }: TelegramIconProps) => (
    <svg width={width} height={height} viewBox="-20 0 190 190" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M76.33 132.14L62.5 143.73L58.59 144.26L49.84 114.11L19.06 104L113.82 67.8799L118.29 67.9799L103.36 149.19L76.33 132.14ZM100.03 83.1399L56.61 109.17L61.61 130.5L62.98 130.19L68.2 113.73L102.9 83.4799L100.03 83.1399Z"
            fill="#34e7d7"
        />
    </svg>
);

export default TelegramIcon;
