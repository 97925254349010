import React from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faPencil } from "@fortawesome/free-solid-svg-icons";
import { TimelineEventProps } from "./Timeline";
import styled from "styled-components";

type Variants = "nested-sub-events" | "aligned-sub-events";

export interface EventContentProps {
    editable?: boolean;
    editCallback?: (index: number) => void;
    variant?: Variants;
}

export const Header = styled.div`
    color: white;
    font-size: 1rem;
    margin-bottom: 0.714rem;
    font-weight: 600;
`;

export const SubEvent = styled.div`
    color: ${({ variant }: Partial<EventContentProps>) => (variant === "nested-sub-events" ? "white" : "var(--light-gray)")};
    margin-left: ${({ variant }: Partial<EventContentProps>) => (variant === "nested-sub-events" ? "1.785rem" : "0")};
    margin-bottom: 0.714rem;
`;

const EventContent = ({
    item,
    index,
    editable,
    editCallback,
    variant = "aligned-sub-events",
}: EventContentProps & { item: TimelineEventProps; index: number }) => (
    <>
        <Header data-testid="header">
            {item.header}
            {editable && editCallback && (
                <FontAwesomeIcon icon={faPencil} onClick={() => editCallback(index)} className="text-green ml-1 pointer-cursor" />
            )}
        </Header>
        {item.subEvents?.map((subEvent, i) => (
            <SubEvent key={i} variant={variant} data-testid="sub-event">
                {subEvent}
            </SubEvent>
        ))}
    </>
);

export default EventContent;
