export enum SessionStorageKeys {
    userSettings = "userSettings",
    entityUserAuthToken = "entityUserAuthToken",
    settingsWallets = "settingsWallets",
    loginRoute = "loginRoute",
    logoutReason = "logoutReason",
    signedTxSessionsId = "signedTxSessionsId",
    stakingProviders = "cd-stakingProviders",
    stakingProvidersPeriodStartDate = "cd-providersPeriodStart",
    chainDataEconomics = "cd-economics",
    chainDataNetworkDelegators = "cd-netDelegators",
    chainDataStakeInfo = "cd-stake",
    chainDataStats = "cd-stats",
    chainDataScShard = "cd-sc-shard",
    isSessionAlive = "isSessionAlive",
    ico = "ico",
    ticketsScope = "tickets-scope",
}
