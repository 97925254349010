import React from "react";
import styled from "styled-components";
import { Image } from "react-bootstrap";
import { BREAKPOINTS } from "../../assets/sass/breakpoints";
import { EImagePlaceholder } from "../Placeholder";

type Placeholder = { showPlaceholder?: boolean };
type FeatureImage = { src: string };

const Logo = styled(Image)`
    display: block;
    margin: 0 auto;
    width: 6rem;
    border-radius: 50%;

    @media (${BREAKPOINTS.tablet}) {
        width: 13rem;
    }
`;

const LogoImage = (props: Partial<FeatureImage> & Placeholder) => {
    if (!props.showPlaceholder) {
        return <Logo fluid src={props.src} alt="Project logo" />;
    } else {
        return <EImagePlaceholder as={Logo} />;
    }
};

export default LogoImage;
