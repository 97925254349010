import axios from "axios";
import { DelegationMetricsResponse, PeriodDateRange } from "../common/types/delegationMetricsTypes";
import BaseService from "./BaseService";

class DelegationMetricsService extends BaseService {
    private readonly _entityApi: string;

    constructor(entityApi: string) {
        super();
        this._entityApi = entityApi;
    }

    public retrieveDelegationMetrics(userId: string, dateStart: number, dateEnd: number): Promise<DelegationMetricsResponse> {
        return this.doCall<DelegationMetricsResponse>(() =>
            axios.get(`${this._entityApi}/users/${userId}/delegation-metrics`, {
                params: {
                    dateStart,
                    dateEnd,
                },
            }),
        );
    }

    public retrieveDateRange(userId: string): Promise<PeriodDateRange> {
        return this.doCall<PeriodDateRange>(() => axios.get(`${this._entityApi}/users/${userId}/delegation-metrics/date-range`));
    }
}

export default DelegationMetricsService;
