import React, { FC } from "react";
import styled, { css } from "styled-components";

export const BgCell = styled.div`
    display: flex;
    align-items: center;
    width: 100%;
    height: 100%;
    padding: 0.5rem;
    padding-left: 1.428rem !important;
    padding-right: 1.428rem !important;
`;

export const commonStyles = css`
    border: 3px solid transparent !important;
    height: 3.571rem;
    display: table-cell;
    color: #ffffff;
    padding: 0 !important;
`;

const Container = styled.td`
    min-width: 150px;

    &:last-child {
        border-right: 0;
    }

    &:first-child {
        border-left: 0;
    }

    ${commonStyles}

    ${BgCell} {
        background-color: var(--table-col-bg-color) !important;
    }
`;

const TableCell: FC<React.PropsWithChildren<{ className?: string }>> = ({ children, className = "" }) => {
    return (
        <Container className={className}>
            <BgCell>{children}</BgCell>
        </Container>
    );
};

export default TableCell;
