import { ChartOptions } from "chart.js";

export function doughnutOptions(
    numberFormatStyle: "currency" | "percent" | "value",
    maintainAspectRatio = false,
    displayLegend = false,
): ChartOptions<"doughnut"> {
    return {
        cutout: "66%",
        maintainAspectRatio,
        plugins: {
            tooltip: {
                callbacks: {
                    // eslint-disable-next-line @typescript-eslint/no-explicit-any
                    label: function (context: any) {
                        let label = context.label || "";
                        if (label) {
                            label += ": ";
                        }
                        if (context.parsed !== null) {
                            if (numberFormatStyle === "currency") {
                                label += new Intl.NumberFormat("en-US", {
                                    style: numberFormatStyle,
                                    currency: "USD",
                                }).format(context.parsed);
                            }
                            if (numberFormatStyle === "percent") {
                                label += context.parsed + "%";
                            }
                            if (numberFormatStyle === "value") {
                                label += context.parsed;
                            }
                        }
                        return label;
                    },
                },
            },
            legend: {
                display: displayLegend,
                position: "bottom",
                labels: {
                    // boxPadding: 2,
                    // padding: 10,
                    color: "#FFF",
                },
            },
        },
    };
}

export const borderColor = [
    "#56ACFF",
    "#51E2DF",
    "#4DC2F9",
    "#4E8AC5",
    "#6EC574",
    "#9F9F9F",
    "rgba(255, 173, 71, 1)",
    "rgba(215, 149, 255, 1)",
];
export const backgroundColor = [
    "#46698A",
    "#69E6E4",
    "#7FD3FA",
    "#415263",
    "#4A634C",
    "#585858",
    "rgba(255, 173, 71, 0.5)",
    "rgba(215, 149, 255, 0.5)",
];
