import React from "react";
import styled from "styled-components";

const ConfirmationMessage = styled("div")`
    p {
        color: #fff;
        font-size: 1rem;
        border-top: 1px solid #5e5e5e;
        border-bottom: 1px solid #5e5e5e;
        width: 100%;
        text-align: center;
        margin-top: 0.8rem;
        padding: 0.8rem;
    }

    .text-danger {
        color: #d51515;
    }
`;

interface SubmitData {
    type: "success" | "error";
    message: string;
}

interface AuthSubmitDataProps {
    submitDataMessage: SubmitData | null;
}

const AuthSubmitDataMessage = ({ submitDataMessage }: AuthSubmitDataProps) => {
    if (!submitDataMessage) {
        return <></>;
    }

    return (
        <ConfirmationMessage>
            <p className={`${submitDataMessage.type === "error" ? "text-danger" : ""}`}>{submitDataMessage.message}</p>
        </ConfirmationMessage>
    );
};

export default AuthSubmitDataMessage;
