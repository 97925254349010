import React, { FC } from "react";
import styled from "styled-components";
import { TokenEconomicsEntry } from "../../../launchpad.types";
import DescribedNumber from "./DescribedNumber/DescribedNumber";
import { TokenAllocationChart } from "./TokenAllocationChart";
import { TokenAllocationChartProps } from "./TokenAllocationChart/TokenAllocationChart";
import { EPlaceholder } from "../../../../../components/Placeholder";
import { BREAKPOINTS } from "../../../../../assets/sass/breakpoints";

interface AllocationOfProceedsProps extends Pick<TokenAllocationChartProps, "numberFormatStyle"> {
    projectName?: string;
    tokenEconomics?: TokenEconomicsEntry[];
    tokenName?: string;
    className?: string;
}

const Description = styled.div`
    padding-bottom: 2.142rem;
    color: var(--light-gray);
    font-size: 1.1rem;
`;

const DataContainer = styled.div`
    width: 100%;
    background-color: var(--card-bg-dark);
    display: inline-flex;
    padding: 2.142rem;
    flex-direction: column;
    justify-content: space-between;
    @media (${BREAKPOINTS.desktop}) {
        flex-direction: row;
    }
`;

const Comment = styled.div`
    padding-top: 2.142rem;
    color: var(--light-gray);
    font-size: 1.1rem;
`;

const TokenEconomics: FC<React.PropsWithChildren<AllocationOfProceedsProps>> = ({
    projectName,
    tokenEconomics,
    tokenName,
    numberFormatStyle,
    className,
}) => {
    return (
        <>
            <div className={className}>
                {tokenName && projectName ? (
                    <Description>
                        The {projectName} team plans to allocate the proceeds of {tokenName} token sale as follows:
                    </Description>
                ) : (
                    <EPlaceholder as={Description} />
                )}
                <DataContainer className="card">
                    {!!tokenEconomics ? (
                        tokenEconomics.map((item, i) => (
                            <DescribedNumber key={i} amount={item.percentage} description={item.allocation} />
                        ))
                    ) : (
                        <>
                            <DescribedNumber showPlaceholder />
                            <DescribedNumber showPlaceholder />
                            <DescribedNumber showPlaceholder />
                        </>
                    )}
                </DataContainer>
                <Comment>
                    Entity is an intermediary service provider and each project is responsible for their own funds and token
                    distribution.
                </Comment>
            </div>
            <TokenAllocationChart
                projectName={projectName}
                tokenEconomics={tokenEconomics}
                numberFormatStyle={numberFormatStyle}
            />
        </>
    );
};

export default TokenEconomics;
