import React, { CSSProperties } from "react";
import {
    components as SelectComponents,
    components,
    CSSObjectWithLabel,
    GroupBase,
    IndicatorsContainerProps,
    OptionProps,
    Props,
    Theme,
    ValueContainerProps,
} from "react-select";
import styled from "styled-components";

export const getTheme = (theme: Theme) => ({
    ...theme,
    borderRadius: 0,
    marginTop: 0,
    colors: {
        ...theme.colors,
        primary: "transparent",
        primary25: "white",
    },
});

export interface FixedOption {
    isFixed?: boolean;
}

export const dropdownComponents = (containerPlaceholder: string) => {
    return {
        ValueContainer: <OptionType, IsMulti extends boolean, Group extends GroupBase<OptionType>>(
            props: ValueContainerProps<OptionType, IsMulti, Group>,
        ) => <ValueContainer {...props} containerPlaceholder={containerPlaceholder} />,
    };
};

const ValueContainer = <OptionType, IsMulti extends boolean, Group extends GroupBase<OptionType>>({
    containerPlaceholder,
    children,
    ...innerProps
}: ValueContainerProps<OptionType, IsMulti, Group> & { containerPlaceholder?: string }) => {
    const { props } = Array.isArray(children) && children[1];

    return (
        <SelectComponents.ValueContainer {...innerProps}>
            <div ref={props.innerRef} {...props}>
                {containerPlaceholder}
            </div>
        </SelectComponents.ValueContainer>
    );
};

export const Option = <OptionType, IsMulti extends boolean, Group extends GroupBase<OptionType>>(
    props: OptionProps<OptionType, IsMulti, Group>,
) => {
    const styles = props.getStyles("option", props) as CSSProperties;
    const { children, innerProps } = props;
    const classes = props.isSelected ? " selected" : "";
    return (
        <div style={{ ...styles }} {...innerProps} className={classes}>
            {children}
        </div>
    );
};

const IndicatorsContainerWrapper = styled.div`
    .styled-indicators {
        > div {
            color: white !important;
        }
    }
    .styled-indicators-disabled {
        > div {
            color: var(--disabled-field-color) !important;
        }
    }
`;

export const IndicatorsContainer = (props: IndicatorsContainerProps) => {
    return (
        <IndicatorsContainerWrapper>
            <components.IndicatorsContainer
                className={props.isDisabled ? "styled-indicators-disabled" : "styled-indicators"}
                {...props}
            />
        </IndicatorsContainerWrapper>
    );
};

const border = "1px solid var(--field-border-color) !important";
const backgroundColor = "var(--field-bg-color)";

export const styles = {
    menu: (provided: CSSObjectWithLabel) => {
        return {
            ...provided,
            backgroundColor,
            border,
            borderTop: "none !important",
            marginTop: "-1px", // -1px to create an effect menu and input are in one block. Otherwise they have different behaviour on focus which we cannot manage
            boxShadow: "none",
        };
    },
    control: (provided: CSSObjectWithLabel, state: Props) => {
        const cursor = state.isDisabled ? "not-allowed" : "default";
        const color = state.isDisabled ? "var(--disabled-field-color)" : "white";
        const bgColor = state.isDisabled ? "var(--disabled-field-bg-color)" : backgroundColor;

        return {
            ...provided,
            backgroundColor: bgColor,
            border: "none",
            color,
            cursor,
            minHeight: "40px",
            "&:focus, &:focus-within, &:active": {
                boxShadow: "none !important",
                border,
                borderColor: backgroundColor,
                backgroundColor,
            },
        };
    },
    option: (provided: CSSObjectWithLabel, state: Props) => {
        const opacity = state.isDisabled ? 0.5 : 1;
        // eslint-disable-next-line @typescript-eslint/ban-ts-comment
        // @ts-ignore: TS2339: Property 'isSelected' does not exist on type 'StateManagerProps'
        const color = state?.isSelected
            ? "white"
            : state.isDisabled
            ? "var(--field-placeholder-color)"
            : // eslint-disable-next-line @typescript-eslint/ban-ts-comment
            // @ts-ignore: TS2339: Property 'isFocused' does not exist on type 'StateManagerProps'
            state?.isFocused
            ? "black"
            : "var(--field-placeholder-color)";
        const optionBackgroundColor = state.isDisabled ? "transparent" : provided.backgroundColor;

        return {
            ...provided,
            backgroundColor: optionBackgroundColor,
            color,
            opacity,
        };
    },
    singleValue: (provided: CSSObjectWithLabel, state: Props) => {
        const color = state.isDisabled ? "var(--disabled-field-color)" : "white";

        return {
            ...provided,
            color,
        };
    },
    input: (provided: CSSObjectWithLabel) => ({
        ...provided,
        color: "#fff",
    }),
    placeholder: (provided: CSSObjectWithLabel, state: Props) => {
        return {
            ...provided,
            color: state.isDisabled ? "var(--disabled-field-color)" : "var(--field-placeholder-color)",
        };
    },
    multiValueRemove: (provided: CSSObjectWithLabel, props: { data: FixedOption }) => {
        return props.data.isFixed ? { ...provided, display: "none" } : provided;
    },
    menuPortal: (provided: CSSObjectWithLabel) => {
        return { ...provided, zIndex: "99999" };
    },
};
