import React from "react";

const Ledger = () => (
    <svg width="31" height="31" viewBox="0 0 31 31" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path
            d="M18.5821 29.0033V30.8919H30.9258V22.3739H29.0597V29.0033H18.5821ZM18.5821 0.892334V2.78103H29.0597V9.41074H30.9258V0.892334H18.5821ZM15.154 9.41074H13.288V22.3735H21.7039V20.6699H15.154V9.41074ZM0.925781 22.3739V30.8923H13.2694V29.0033H2.79175V22.3739H0.925781ZM0.925781 0.892334V9.41074H2.79175V2.78103H13.2694V0.892334H0.925781Z"
            fill={"#23f7dd"}
        />
    </svg>
);

export default Ledger;
