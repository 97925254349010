import { Form } from "react-bootstrap";
import styled, { css } from "styled-components";
import { fieldStates } from "../../assets/sass/common-effects";

export const formControlCSS = css`
    ${fieldStates};
    border-radius: 0 !important;
    border: none !important;

    &:focus {
        border: 1px solid var(--field-border-color) !important;
    }

    &:focus,
    &:focus-visible {
        outline: 0 !important;
        box-shadow: none !important;
    }
`;

const EFormControl = styled(Form.Control)`
    &.form-control {
        ${formControlCSS};
    }
`;

export default EFormControl;
