import React, { useEffect, useState } from "react";
import { Col, Form, Row } from "react-bootstrap";
import { useYupValidationResolver } from "../../common";
import { useForm } from "react-hook-form";
import * as yup from "yup";
import AlertService from "../../services/AlertService";
import SessionStorageService from "../../services/sessionStorageService/SessionStorageService";
import { IcoListingSteps } from "./IcoCreation";
import { ImageListType } from "react-images-uploading";
import IcoEditStepOne, { getIcoStepOneValidationSchema } from "./Edit/IcoEditStepOne";
import { IcoStep, Name, Slogan, TokenUtilityOption } from "./ico.types";
import { EButton } from "../../components/Buttons";
import { SessionStorageKeys } from "../../services/sessionStorageService/sessionStorageKeys";

export interface IcoStepOneInputs extends Name, Slogan {
    username: string;
    email: string;
    position: string;
    country: string;
    description: string;
    targetAudience: string[];
    tokenUtility?: string[];
    tokenUtilityOption?: TokenUtilityOption;
    logoUploadedImage?: ImageListType;
    featureUploadedImage?: ImageListType;
    hasLaunchpadListing?: boolean;
}

export const icoStepOneInitial: IcoStepOneInputs = {
    name: "",
    username: "",
    email: "",
    position: "",
    country: "",
    description: "",
    targetAudience: ["", "", ""],
    tokenUtility: ["", "", ""],
    tokenUtilityOption: TokenUtilityOption.CUSTOM_INPUT,
    slogan: "",
    logoUploadedImage: [],
    featureUploadedImage: [],
};

const getValidationSchema = () => {
    return yup.object(getIcoStepOneValidationSchema());
};

const IcoStepOne: React.FC<React.PropsWithChildren<IcoStep>> = ({ step, changeStep }) => {
    const [icoInfo, setIcoInfo] = useState<IcoStepOneInputs>(icoStepOneInitial);

    const validationResolver = useYupValidationResolver(getValidationSchema());
    const { register, handleSubmit, formState, reset, control, setError, watch } = useForm<IcoStepOneInputs>({
        resolver: validationResolver,
    });

    const sessionIco = SessionStorageService.getItem("ico");

    useEffect(() => {
        if (sessionIco && sessionIco.stepOne) {
            const logoUploadedImage = sessionIco.stepOne.logoUploadedImage
                ? [
                      {
                          dataURL: sessionIco.stepOne.logoUploadedImage[0].dataURL,
                      },
                  ]
                : [];
            const featureUploadedImage =
                sessionIco.stepOne.featureUploadedImage?.length > 0
                    ? [
                          {
                              dataURL: sessionIco.stepOne.featureUploadedImage[0].dataURL,
                          },
                      ]
                    : [];
            const icoInitial: IcoStepOneInputs = {
                name: sessionIco.stepOne.name,
                username: sessionIco.stepOne.username,
                email: sessionIco.stepOne.email,
                position: sessionIco.stepOne.position,
                country: sessionIco.stepOne.country,
                description: sessionIco.stepOne.description,
                slogan: sessionIco.stepOne.slogan,
                targetAudience: sessionIco.stepOne.targetAudience,
                tokenUtility: sessionIco.stepOne.tokenUtility,
                tokenUtilityOption: sessionIco.stepOne.tokenUtilityOption,
                hasLaunchpadListing: sessionIco.stepOne.hasLaunchpadListing,
                logoUploadedImage,
                featureUploadedImage,
            };

            setIcoInfo(icoInitial);
            reset(icoInitial);
        }
    }, [step]);

    const onStepOneChangeHandler = async (input: IcoStepOneInputs) => {
        AlertService.successToast("Step one completed!");
        const ico: IcoListingSteps = sessionIco ?? {};
        ico.stepOne = input;
        SessionStorageService.setItem(SessionStorageKeys.ico, ico);
        setIcoInfo(input);
        changeStep(2);
    };

    return (
        <div>
            <Form className="mt-3" onSubmit={handleSubmit(onStepOneChangeHandler)}>
                <IcoEditStepOne
                    ico={icoInfo}
                    register={register}
                    formState={formState}
                    control={control}
                    setError={setError}
                    watch={watch}
                />

                <Row className="mt-1">
                    <Col>
                        <EButton className="mt-2 w-100 py-3 text-capitalize" type="submit" testId="next">
                            Next step
                        </EButton>
                    </Col>
                </Row>
            </Form>
        </div>
    );
};

export default IcoStepOne;
