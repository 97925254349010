import React, { FC } from "react";
import styled from "styled-components";

interface CharactersInfoProps {
    message?: string;
    amount: number;
}

const Info = styled.div`
    color: var(--entity-gray);
`;

const CharactersInfo: FC<React.PropsWithChildren<CharactersInfoProps>> = ({ message = "Characters remaining:", amount }) => (
    <Info>
        {message} {amount}
    </Info>
);

export default CharactersInfo;
