import React, { memo } from "react";
import { faDownload } from "@fortawesome/free-solid-svg-icons";
import styled from "styled-components";
import { EButton, ETooltipButton } from "../../Buttons";
import { FontAwesomeIconCustom } from "./FooterCard";

const LitepaperTooltipBtn = styled(ETooltipButton)`
    margin-right: 0.714rem;
`;

const LitepaperBtn = styled(EButton)`
    margin-right: 0.714rem;
    color: #fff;

    :hover {
        color: #fff !important;
    }
`;

const LitepaperButton = ({ litepaper, showPlaceholder }: { litepaper?: string | null; showPlaceholder?: boolean }) => (
    <>
        {!litepaper && (
            <LitepaperTooltipBtn
                disabled={!litepaper}
                showTooltip={!litepaper}
                tooltipText="Coming soon"
                cursor={litepaper ? "pointer" : "not-allowed"}
                testId="remove-admin-btn"
            >
                <FontAwesomeIconCustom icon={faDownload} />
                <span className="text-capitalize">Litepaper</span>
            </LitepaperTooltipBtn>
        )}
        {litepaper && (
            <>
                <LitepaperBtn target="_blank" href={litepaper} testId="litepaper" showPlaceholder={showPlaceholder}>
                    <FontAwesomeIconCustom icon={faDownload} />
                    <span className="text-capitalize">Litepaper</span>
                </LitepaperBtn>
            </>
        )}
    </>
);

export default memo(LitepaperButton);
