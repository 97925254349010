import { CommonSMBtn, SocialMediaButton } from "../components/Buttons/SocialMediaButtons";
import { SocialLinks, SocialMediaTypes } from "./consts";
import { SizeProp } from "@fortawesome/fontawesome-svg-core";

// Use this function for creating a list of common social buttons.
// "Common" means that they are from the SocialMediaTypes list.
// If current list of social media buttons have custom values,
// then it is obligatory to create a custom handler function
export const createSMCommonBtnsList = (links: SocialLinks, size?: SizeProp): SocialMediaButton[] => {
    const socialButtons = [] as SocialMediaButton[];

    links &&
        Object.entries(links).forEach(([key, value]) => {
            if (value) {
                if (key in SocialMediaTypes) {
                    const commonSMButton: CommonSMBtn = { link: value, icon: key as SocialMediaTypes };
                    size && (commonSMButton.size = size);
                    socialButtons.push(commonSMButton);
                } else {
                    console.error("Unexpected social link type: ", key);
                }
            }
        });
    return socialButtons;
};

export const getSocialMedia = <T>(element: T, keys: (keyof T)[]) => {
    const socialMedia = [];
    for (const socialMediaKey of keys) {
        const key = String(socialMediaKey).toLowerCase();
        if (key in SocialMediaTypes) {
            socialMedia.push({ icon: key as SocialMediaTypes, link: element[socialMediaKey] as unknown as string });
        }
    }

    return socialMedia;
};
