import { BalanceRequestBody, EarningBalanceResponse, StakingBalanceResponse } from "../common/entities/StakingReportsEntity";
import BaseService from "./BaseService";
import axios from "axios";
import { EsdtRewards, ReportsMetastakingResponse } from "../common/types/esdtRewardsTypes";

class ReportsService extends BaseService {
    private readonly _entityBaseApi: string;
    private readonly _dataApiAddress: string;

    constructor(entityApi: string, dataApiAddress: string) {
        super();
        this._entityBaseApi = entityApi + "/reports";
        this._dataApiAddress = dataApiAddress;
    }

    public async getCurrentEGLDPrice(): Promise<number> {
        return this.doCall(() => axios.get<number>(`${this._dataApiAddress}/quoteshistorical/egld/price`));
    }

    public async getWalletsCurrentStakingBalanceReport(balance: BalanceRequestBody): Promise<StakingBalanceResponse> {
        const resp = await this.doCall(() =>
            axios.post<Partial<StakingBalanceResponse>>(`${this._entityBaseApi}/wallets/balance`, balance),
        );
        return StakingBalanceResponse.mapResponse(resp);
    }

    public async getWalletsEarningsBalanceReport(balance: BalanceRequestBody): Promise<EarningBalanceResponse> {
        const resp = await this.doCall(() =>
            axios.post<Partial<EarningBalanceResponse>>(`${this._entityBaseApi}/earnings/balance`, balance),
        );
        return EarningBalanceResponse.mapResponse(resp);
    }

    public async getFarmsClaimRewards(body: BalanceRequestBody): Promise<EsdtRewards> {
        return this.doCall(() => axios.post(`${this._entityBaseApi}/wallets/farms`, body));
    }

    public async getMetastakingClaimRewards(body: BalanceRequestBody): Promise<ReportsMetastakingResponse> {
        return this.doCall(() => axios.post(`${this._entityBaseApi}/wallets/metastaking`, body));
    }
}

export default ReportsService;
