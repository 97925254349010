import styled from "styled-components";

const EFormLabel = styled.label`
    margin-bottom: 0.857rem !important;
    font: normal normal normal 1.286rem/2.143rem;
    text-align: left;

    &.no-margin {
        margin-bottom: 0 !important;
    }
`;

export default EFormLabel;
