import React from "react";
import styled from "styled-components";
import { BREAKPOINTS } from "../../assets/sass/breakpoints";
import Link from "../Buttons/Link";
import { routeNames } from "../../routes/routes";
import { EButton } from "../Buttons";
import { modulesLinkList } from "../../helpers/consts";

type Color = string;

interface TitleProps {
    title: string;
    description?: string;
    color?: Color;
    className?: string;
}

const Container = styled.div`
    margin-bottom: 1rem;
    padding-right: 1rem;
    padding-left: 1rem;

    @media (${BREAKPOINTS.desktop}) {
        padding-right: unset;
        padding-left: unset;
        max-width: 52rem;
    }
`;

const Title = styled.div`
    font: normal normal normal 1.857rem/3rem var(--entity-header-font-family);
    color: ${({ color }: { color: Color }) => color};
    letter-spacing: 0;
    text-transform: uppercase;
    text-align: center;

    @media (${BREAKPOINTS.tablet}) {
        text-align: unset;
        font-size: 3.428rem;
        line-height: 6.571rem;
    }
`;

const Description = styled.div`
    color: white;
    letter-spacing: 0;
    font: normal normal 300 1.142rem/1.857rem var(--entity-font-family);
    text-align: center;
    margin-top: 0.714rem;

    @media (${BREAKPOINTS.tablet}) {
        text-align: unset;
        font-size: 1.9rem;
        line-height: 3.428rem;
    }
`;

const PageBlockTitle = ({
    title,
    description,
    color = "var(--page-block-title-color)",
    className = "",
}: TitleProps): JSX.Element => {
    return (
        <Container className={className}>
            <Title color={color}>{title}</Title>
            {description && <Description>{description}</Description>}
            {title === "Startups" && (
                <div className={"d-flex mt-4"}>
                    <Link className="me-md-4 mb-2 mb-md-0" key="list-ico" data-testid="get-listed" to={routeNames.icoNew}>
                        Get Listed on Entity
                    </Link>
                    ,
                    <EButton
                        key="knowledge-base"
                        data-testid="knowledge-base"
                        target="_blank"
                        rel="noreferrer"
                        href={modulesLinkList.listProject}
                    >
                        Learn How to Apply
                    </EButton>
                    ,
                </div>
            )}
        </Container>
    );
};

export default PageBlockTitle;
