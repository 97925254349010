import React from "react";
import ProjectInfoCard, { ProjectInfoCardProps } from "../ProjectInfoCard/ProjectInfoCard";
import { Col, Row } from "react-bootstrap";
import styled from "styled-components";
import KeyMetricsPlaceholder from "./Placeholder";

export const RowContainer = styled(Row)`
    margin-left: 0;
    margin-right: 0;
    padding-bottom: 0;
    line-height: 2.142rem;

    &:last-child {
        padding-bottom: 0;
    }
`;

export const ColContainer = styled(Col)`
    &:first-child {
        padding-left: 0 !important;
        padding-right: 0.75rem !important;
    }

    &:last-child {
        padding-left: 0.75rem !important;
        padding-right: 0 !important;
    }
`;

export interface KeyMetrics {
    title: string;
    value: string | number | JSX.Element;
}

const ProjectKeyMetricsCard = ({
    metrics,
    titleAdditionalInfo,
    className = "",
    showPlaceholder = false,
}: {
    metrics?: KeyMetrics[];
    titleAdditionalInfo?: ProjectInfoCardProps["titleAdditionalInfo"];
    className?: string;
    showPlaceholder?: boolean;
}) => {
    const getKeyMetricsCardBody = (): JSX.Element => (
        <div data-testid="content">
            {showPlaceholder && <KeyMetricsPlaceholder />}

            {/*TODO: change types: metrics should be existed in case of showPlaceholder == false*/}
            {metrics &&
                metrics.map((m, key) => (
                    <RowContainer key={key} data-testid="content-row">
                        <ColContainer xs={6}>
                            <span data-testid="content-row-title">{m.title}</span>
                        </ColContainer>
                        <ColContainer xs={6}>
                            <span data-testid="content-row-value">{m.value}</span>
                        </ColContainer>
                    </RowContainer>
                ))}
        </div>
    );

    return (
        <ProjectInfoCard
            titleText="Key Metrics"
            titleAdditionalInfo={titleAdditionalInfo}
            className={className}
            testId="project-key-metrics-card"
        >
            {getKeyMetricsCardBody()}
        </ProjectInfoCard>
    );
};

export default ProjectKeyMetricsCard;
