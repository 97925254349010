import React, { FC, memo, useEffect, useState } from "react";
import { DetailedInfoProps, ProjectGeneralDetails, ProjectInvestmentDetails, ProjectTiersDetails } from "../../launchpad.types";
import ContentContainer from "../ContentContainer";
import { useService } from "../../../../services/config/dependencyInjectorConfig";
import ProjectsService from "../../../../services/ProjectsService";
import { renderHTML } from "../../../../helpers/consts";
import HrLine from "../../../../components/common/HrLine";
import styled from "styled-components";
import TiersTableContainer from "../../components/TiersTableContainer";
import { EPlaceholder } from "../../../../components/Placeholder";
import TokenSaleDetails from "./TokenSaleDetails";
import LotteryTimeline from "./LotteryTimeline";
import Exclusions from "./Exclusions";
import DecorationsBg from "../Metrics/TokenEconomics/DecorationsBg";

export interface InvestmentViewTabProps extends DetailedInfoProps, Pick<ProjectGeneralDetails, "launchRoadmap"> {
    tiersInfo?: ProjectTiersDetails;
}

export const Descriptions = styled.p`
    color: var(--white) !important;
    font-size: 1.1rem;
`;

const StyledHrLine = styled(HrLine)`
    color: var(--card-bg-dark);
    border-top: 0.214rem solid;
    margin: 3.571rem 0;
`;

const Investment: FC<React.PropsWithChildren<InvestmentViewTabProps>> = ({ tiersInfo, projectId, isActive, launchRoadmap }) => {
    const [investmentInfo, setInvestmentInfo] = useState<ProjectInvestmentDetails | undefined>(undefined);

    const [projectsService] = useService(ProjectsService);

    useEffect(() => {
        if (isActive && !investmentInfo) {
            getProjectInvestmentDetails();
        }
    }, [isActive]);

    const getProjectInvestmentDetails = async () => {
        const details = await projectsService.retrieveInvestmentDetails(projectId);
        setInvestmentInfo(details);
    };

    return (
        <ContentContainer title="Token Sale on the Entity Launchpad">
            {!investmentInfo ? (
                <EPlaceholder numberOfLines={10} />
            ) : (
                <>
                    <Descriptions>
                        {investmentInfo.investmentDescription && renderHTML(investmentInfo.investmentDescription)}
                    </Descriptions>
                    {tiersInfo?.tiers && tiersInfo.tiers.length > 0 && tiersInfo.fundingToken && (
                        <TiersTableContainer tiersInfo={tiersInfo} />
                    )}
                    <Descriptions>{investmentInfo.tiersDescription && renderHTML(investmentInfo.tiersDescription)}</Descriptions>
                </>
            )}

            <StyledHrLine height={2} />
            <TokenSaleDetails investmentInfo={investmentInfo} tiersInfo={tiersInfo} />
            <StyledHrLine height={2} />
            <DecorationsBg />
            <LotteryTimeline launchRoadmap={launchRoadmap} />
            <StyledHrLine height={2} />
            <Exclusions investmentInfo={investmentInfo} />
        </ContentContainer>
    );
};

export default memo(Investment);
