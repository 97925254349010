import compareVersions from "compare-versions";
import { network } from "../../config";

const currentAppVersion = process.env.REACT_APP_VERSION ? process.env.REACT_APP_VERSION : "1.0.0"; // TODO Choose proper default / remove default

/**
 * When we have some features that we don't want to release before some specific version, but we already implemented it,
 * we can use this featureToggles object to add a feature toggle here and use it in the code to decide if we need
 * to show some feature or not
 *
 * To add a new feature toggle, just add a new element into this object like this:
 *
 * featureToggleExample: compareVersions.validate(currentAppVersion) && compareVersions.compare(currentAppVersion, "1.11.1", ">="),
 *
 * In this particular case we want to show featureToggleExample feature only starting version 1.11.1. You need to pass
 * the version as the second param to {@link compareVersions.compare} method.
 *
 * Then in the code where you want to use this feature toggle you can simply do something like this:
 * if (featureToggles.featureToggleExample) {
 *     // ... show your amazing feature
 * } else {
 *     // ... show old implementation or "coming soon" or whatever else
 * }
 */
export const featureToggles = {
    launchpadProject: compareVersions.validate(currentAppVersion) && compareVersions.compare(currentAppVersion, "1.18", ">="),
    premiumFeatures: compareVersions.validate(currentAppVersion) && compareVersions.compare(currentAppVersion, "1.19", ">="),
    buyNft: compareVersions.validate(currentAppVersion) && compareVersions.compare(currentAppVersion, "1.49", ">="),
    stakingProvidersPageRedesign:
        compareVersions.validate(currentAppVersion) && compareVersions.compare(currentAppVersion, "1.17", ">"),
    delegationMetricsPageRedesign:
        compareVersions.validate(currentAppVersion) && compareVersions.compare(currentAppVersion, "1.17", ">"),
    rewardStrategiesPageRedesign:
        compareVersions.validate(currentAppVersion) && compareVersions.compare(currentAppVersion, "1.24", ">"),
    showBanner:
        compareVersions.validate(currentAppVersion) &&
        compareVersions.compare(currentAppVersion, "1.24", ">=") &&
        compareVersions.compare(currentAppVersion, "1.56", "<"),
    showBannerLaunch:
        compareVersions.validate(currentAppVersion) &&
        compareVersions.compare(currentAppVersion, "1.18", ">=") &&
        compareVersions.compare(currentAppVersion, "1.56", "<"),
    LDM:
        compareVersions.validate(currentAppVersion) &&
        compareVersions.compare(currentAppVersion, "1.38", ">=") &&
        isDevnetOrTestnet(),
};

function isDevnetOrTestnet() {
    return network.chainId === "D" || network.chainId === "T";
}
