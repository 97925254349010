import React from "react";
import styled from "styled-components";
import { BREAKPOINTS } from "../../assets/sass/breakpoints";

export interface StepDetails {
    number: number;
    title: string;
}

export enum Placement {
    Top,
    Bottom,
}

interface StepProps extends StepDetails {
    isActive?: boolean;
    labelPlacement?: Placement;
    onClick?: (stepNumber: number) => void;
}

const Container = styled.div`
    cursor: default;

    .rounded-circle.step {
        display: block;
        background: #206666;
        color: #fff;
        width: 3.429rem;
        height: 3.429rem;
        text-align: center;
        filter: blur(40%);
        font-size: 1.714rem;

        &.active {
            background-image: linear-gradient(180deg, #51e2df 0%, #83ff8c 100%);
            color: #123737;
            width: 4.571rem;
            height: 4.571rem;
            font-size: 3.214rem;
        }
    }

    .step-text {
        color: var(--primary-text);

        &.bottom {
            margin-top: 1.143rem;
        }

        &.top {
            margin-bottom: 1.143rem;
        }

        &.hidden {
            visibility: hidden;
        }
    }

    .step-text.active {
        color: #51e2df;

        &.bottom {
            margin-top: 0.571rem;
        }

        &.top {
            margin-bottom: 0.571rem;
        }
    }

    @media (${BREAKPOINTS.tablet}) {
        .rounded-circle.step,
        .rounded-circle.step.active {
            width: 5.143rem;
            height: 5.143rem;
            font-size: 3.429rem;
        }

        .step-text {
            visibility: visible !important;
        }

        .step-text,
        .step-text.active {
            &.bottom {
                margin-top: 1rem !important;
            }
            &.top {
                margin-bottom: 1rem !important;
            }
        }
    }
`;

const Step = ({ isActive = false, onClick, number, title, labelPlacement = Placement.Bottom }: StepProps) => {
    return (
        <Container className="z-1">
            {labelPlacement === Placement.Top && (
                <span
                    className={`${isActive ? "active" : ""} text-center d-block d-md-none step-text top ${
                        onClick && "pointer-cursor"
                    }`}
                    onClick={() => onClick && onClick(number)}
                >
                    {title}
                </span>
            )}
            <div
                className={`${
                    isActive ? "active" : ""
                } mx-auto d-flex align-items-center justify-content-center rounded-circle step ${onClick && "pointer-cursor"}`}
                onClick={() => onClick && onClick(number)}
            >
                {number}
            </div>
            <span
                className={`${isActive ? "active" : ""}  ${
                    labelPlacement !== Placement.Bottom ? "hidden" : ""
                } text-center d-block step-text bottom ${onClick && "pointer-cursor"}`}
                onClick={() => onClick && onClick(number)}
            >
                {title}
            </span>
        </Container>
    );
};

export default Step;
