import BaseService from "./BaseService";
import axios from "axios";
import { CsvPath, initialCsvPath } from "../pages/Taxtools/TaxReport";
import { downloadCsvFile } from "../helpers/util.functions";
import { TransactionsEntity, TransactionsEntityWithWalletInfo } from "../common/entities/TransactionsEntity";
import { TDate } from "../pages/Taxtools";

export interface TransactionsCSVExportBody {
    userId: string;
    walletsIds: string[];
    dateStart: number | null;
    dateEnd: number | null;
    stakingProvider?: string;
}

export interface EntityTxQueryParams {
    from: number;
    size: number;
    userId: string;
    walletIds: string[];
    dateStart: TDate;
    dateEnd: TDate;
    search?: string;
    type?: string;
    includeWalletInfo: boolean;
}

export interface ExternalTxQueryParams extends Record<string, string | number> {
    from: number;
    size: number;
    sender: string;
    receiver: string;
    condition: string;
}

export interface TxListResponse {
    data: Array<TransactionsEntity | TransactionsEntityWithWalletInfo>;
    total: number;
}

export const initialTxListResponse = {
    data: [],
    total: 0,
};

export interface TxSettingsRequestBody {
    transactionId: string;
    comment?: string;
    type?: string;
    usdtPrice?: string;
}

export class TransactionService extends BaseService {
    private readonly _entityBaseApi: string;
    private readonly _apiAddress: string;

    constructor(entityApi: string, apiAddress: string) {
        super();
        this._entityBaseApi = entityApi + "/transactions";
        this._apiAddress = apiAddress;
    }

    public async downloadCsv(body: TransactionsCSVExportBody) {
        const csvPath: CsvPath = await this.generateTransactionsCSVExport(body);
        if (!csvPath?.fileName) {
            return;
        }
        downloadCsvFile(csvPath.fileName);
    }

    public async generateTransactionsCSVExport(body: TransactionsCSVExportBody): Promise<CsvPath> {
        if (!body.userId || !body.walletsIds || !body.dateStart || !body.dateEnd) {
            console.error("UserId, walletsIds, dateStart and dateEnd params are required");
            return initialCsvPath;
        }
        return this.doCall(() => axios.post<CsvPath>(`${this._entityBaseApi}/generate/csv`, body));
    }

    public async getBlockchainTransactionsList(queryParams: ExternalTxQueryParams): Promise<TransactionsEntity[]> {
        if (
            !queryParams.sender.toString() ||
            !queryParams.receiver ||
            !queryParams.condition ||
            !queryParams.from.toString() ||
            !queryParams.size.toString()
        ) {
            console.error("Sender, receiver, condition, from and size params are required");
            return [];
        }
        return this.doCall(() => axios.get<TransactionsEntity[]>(`${this._apiAddress}/transactions`, { params: queryParams }));
    }

    public async getTransactionsList(queryParams: EntityTxQueryParams): Promise<TxListResponse> {
        if (!queryParams.from.toString() || !queryParams.size || !queryParams.userId) {
            console.error("From, userId and size params are required");
            return initialTxListResponse;
        }
        return this.doCall(() => axios.get<TxListResponse>(`${this._entityBaseApi}/list`, { params: queryParams }));
    }

    public async getTransactionTypesList(): Promise<string[]> {
        return this.doCall(() => axios.get<string[]>(`${this._entityBaseApi}/types`));
    }

    public async resetTransaction(transactionId: string): Promise<string> {
        return this.doCall(() => axios.get<string>(`${this._entityBaseApi}/${transactionId}/reset`));
    }

    public async setTransactionInfo(txInfo: TxSettingsRequestBody): Promise<void> {
        return this.doCall(() => axios.post<void>(`${this._entityBaseApi}/${txInfo.transactionId}/settings`, txInfo));
    }
}

export default TransactionService;
