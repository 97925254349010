export const seoData = {
    homePage: {
        title: "Growth Engine for Web3",
        description:
            "Entity is a growth engine for Web3, featuring a Launchpad for the best new projects and a suite of DeFi tools that will elevate the MultiversX community.",
        keywords:
            "Entity, EGLD, ENT, Launchpad, Crypto rewards, DeFi, MultiversX, Elrond, Growth Engine, Elrond Network, Staking, EGLD Tax, Token, Blockchain startups, Projects",
    },
    transaction: {
        title: "Transaction",
        description:
            "In order to proceed, you must confirm the transaction with your connected MultiversX wallet, including Web Wallet, Ledger, the Maiar App or Maiar DeFi Wallet.",
        keywords:
            "Entity, EGLD, ENT, Launchpad, Crypto rewards, DeFi, MultiversX, Elrond, Growth Engine, Elrond Network, Staking, EGLD Tax, Token, Blockchain startups, Projects",
    },
    unlock: {
        title: "Unlock",
        description:
            "Securely and easily connect your MultiversX wallet to Entity using the following login possibilities: Web Wallet, Ledger, the Maiar App or Maiar DeFi Wallet.",
        keywords:
            "Entity, EGLD, ENT, Launchpad, Crypto rewards, DeFi, MultiversX, Elrond, Growth Engine, Elrond Network, Staking, EGLD Tax, Token, Blockchain startups, Projects",
    },
    ledger: {
        title: "Ledger Authentication",
        description:
            "Connect your Ledger to Entity to conveniently access the MultiversX ecosystem while maintaining exceptional security and control over your Web3 assets.",
        keywords:
            "Entity, EGLD, ENT, Launchpad, Crypto rewards, DeFi, MultiversX, Elrond, Growth Engine, Elrond Network, Staking, EGLD Tax, Token, Blockchain startups, Projects",
    },
    taxTool: {
        title: "Tax Tools",
        description:
            "With Entity’s state-of-the-art Tax Tools, users can monitor their crypto investments to simplify their DeFi tax reporting.",
        keywords:
            "EGLD Tax, Entity, EGLD, ENT, Launchpad, Crypto, DeFi, MultiversX, Elrond, Growth Engine, Elrond Network, Staking, Token, ESDT Tax, Tax Tools, DeFi accounting",
    },
    settings: {
        title: "Settings",
        description:
            "Configure your Entity account and update your data including associated email address, connected wallets, whitelisted wallets and notifications settings.",
        keywords:
            "Entity, EGLD, ENT, Launchpad, Crypto rewards, DeFi, MultiversX, Elrond, Growth Engine, Elrond Network, Staking, EGLD Tax, Token, Blockchain startups, Projects",
    },
    adminProjectMonitor: {
        title: "Project Monitor Admin Area",
        description:
            "The Project Monitor module showcases startups building with EGLD and ESDTs from across the MultiversX with transparent ratings, analytics, and expert analysis.",
        keywords:
            "Entity, Newest launches, EGLD, ENT, Rewards, Crypto, DeFi, NFT, GameFi, MultiversX, Elrond, Growth Engine, Elrond Network, Staking, Token, Blockchain startups",
    },
    projectMonitor: {
        title: "Project Monitor",
        description:
            "The Project Monitor module showcases startups building with EGLD and ESDTs from across MultiversX with transparent ratings, analytics, and expert analysis.",
        keywords:
            "The Project Monitor module showcases startups building with EGLD and ESDTs from across MultiversX with transparent ratings, analytics, and expert analysis.",
    },
    icoNew: {
        title: "List Your Project",
        description:
            "Entity's List Your Project module provides powerful utility to crypto startup founders by helping startups grow and possibly be featured on the Entity Launchpad.",
        keywords:
            "Entity, EGLD, ENT, Rewards, Crypto, DeFi, NFT, GameFi, Elrond, MultiversX, Growth Engine, Elrond Network, Staking, Token, Newest launches, Blockchain startups, Projects",
    },
    projectReview: {
        title: "Project Monitor Review",
        description:
            "Discover Entity’s expert Project Monitor analysis of startups from the MultiversX ecosystem with token information, key data, links, sale dates and more.",
        keywords:
            "Entity, EGLD, ENT, Rewards, Crypto, DeFi, NFT, GameFi, Elrond, MultiversX, Growth Engine, Elrond Network, Staking, Token, Newest launches, Blockchain startups, Projects",
    },
    launchpad: {
        title: "Launchpad",
        description:
            "The Entity Launchpad spotlights the most promising projects in the MultiversX ecosystem, supporting both builders and investors who use EGLD and ESDT tokens.",
        keywords:
            "Entity, Launchpad, EGLD, ENT, Crypto, DeFi, NFT, GameFi, MultiversX, Elrond, Growth Engine, Elrond Network, Token, Newest launches, Blockchain startups, Projects",
    },
    launchpadAboutTab: {
        title: "Launchpad Info",
        description:
            "Learn more about the current Entity Launchpad project and its team, concept, tokenomics, sale dates, how it will elevate the MultiversX ecosystem and more.",
        keywords:
            "Entity, Launchpad, EGLD, ENT, Crypto, DeFi, NFT, GameFi, Elrond, MultiversX, Growth Engine, Elrond Network, Token, Newest launches, Blockchain startups, Projects",
    },
    adminLaunchpadPages: {
        title: "Launchpad",
        description:
            "The Entity Launchpad spotlights the most promising projects in the MultiversX ecosystem, supporting both builders and investors who use EGLD and ESDT tokens.",
        keywords:
            "Entity, Launchpad, EGLD, ENT, Crypto, DeFi, NFT, GameFi, Elrond, MultiversX, Growth Engine, Elrond Network, Token, Newest launches, Blockchain startups, Projects",
    },
    stakingProviderMetrics: {
        title: "Staking Provider Metrics",
        description:
            "The Staking Provider Metrics module helps users and MultiversX validators compare different EGLD staking providers with next-level statistics and charts.",
        keywords:
            "Entity, EGLD, ENT, Rewards, Crypto, DeFi, Elrond, Growth Engine, MultiversX, Elrond Network, Staking, Token, Investment strategy, Stake EGLD, Staking Provider Metrics",
    },
    delegationMetrics: {
        title: "Delegation Metrics",
        description:
            "The Delegation Metrics module empowers MultiversX users to monitor and manage and optimize their EGLD delegations with detailed staking history and graphs.",
        keywords:
            "Entity, EGLD, ENT, Rewards, Crypto, DeFi, Elrond, Growth Engine, MultiversX, Elrond Network, Staking, Token, Investment strategy, Delegate EGLD, Delegation Metrics",
    },
    strategies: {
        title: "Reward Strategies",
        description:
            "Entity’s Reward Strategies module helps users identify their ideal EGLD and ESDT investment strategies for Multiversx based on their own custom criteria.",
        keywords:
            "Passive Income, Entity, EGLD, ENT, Rewards, Crypto, DeFi, MultiversX, Elrond, Growth Engine, Elrond Network, Staking, Token, Investment strategy, Stake EGLD, Reward Strategies",
    },
    portfolio: {
        title: "Portfolio",
        description:
            "The Entity Portfolio module lets users easily monitor the performance of their MultiversX crypto assets including EGLD and ESDTs from a user-friendly interface.",
        keywords:
            "Portfolio, Entity, EGLD, ENT, Launchpad, Crypto rewards, DeFi, MultiversX, Elrond, Growth Engine, Elrond Network, Staking, Token",
    },
    buyNft: {
        title: "NFTs",
        description:
            "The Entity NFT collection supports a loyal and passionate community of long-term investors with a range of benefits that will help fuel the next wave of ecosystem growth.",
        keywords:
            "NFT, Entity, EGLD, ENT, Launchpad, Crypto rewards, DeFi, Elrond, MultiversX, Growth Engine, Elrond Network, Staking, Token, Blockchain startups, Projects, Buy NFT",
    },
    team: {
        title: "Team",
        description:
            " Entity is powered by Istari Vision and Titan Stake, two of the largest secure EGLD staking and Web3 service providers on MultiversX.",
        keywords:
            "Entity, EGLD, ENT, Launchpad, Crypto, DeFi, Elrond, Growth Engine, MultiversX, Elrond Network, Staking, EGLD Tax, Token, Blockchain startups, Projects, Entity Team",
    },
    contact: {
        title: "Contact",
        description:
            "Get in touch if you have a question or want to support Entity's mission of growing the MultiversX ecosystem and kickstarting adoption of Web3 and DeFi.",
        keywords:
            "Entity, EGLD, ENT, Launchpad, Crypto rewards, DeFi, Elrond, Growth Engine, MultiversX, Elrond Network, Staking, EGLD Tax, Token, Blockchain startups, Projects, Contact",
    },
    faq: {
        title: "FAQ",
        description:
            "Discover how Entity is accelerating growth on MultiversX through its Launchpad and suite of cutting-edge modules that support both builders and investors.",
        keywords:
            "Entity, EGLD, ENT, Launchpad, Crypto, DeFi, Elrond, Growth Engine, MultiversX, Elrond Network, Staking, EGLD Tax, Token, Blockchain startups, Projects, FAQ",
    },
    confirmEmail: {
        title: "Email Confirmation",
        description:
            "Confirm your email to link it with your Entity account, enabling you to participate in launches as an investor and receive notifications about key alerts.",
        keywords:
            "Entity, EGLD, ENT, Launchpad, Crypto rewards, DeFi, MultiversX, Elrond, Growth Engine, Elrond Network, Staking, EGLD Tax, Token, Blockchain startups, Projects",
    },
    about: {
        title: "About Entity",
        description:
            "Entity offers Web3 startups a streamlined process to successfully launch their projects, connect with users in the blockchain community and achieve growth.",
        keywords:
            "Entity, EGLD, ENT, Launchpad, Crypto rewards, DeFi, MultiversX, Elrond, Growth Engine, Elrond Network, Staking, EGLD Tax, Token, Blockchain startups, Projects, Privacy Policy",
    },
    privacyPolicy: {
        title: "Privacy Policy",
        description:
            "Privacy is a priority to Entity, so learn how we protect user data and comply with all relevant data protection regulations to keep your information safe.",
        keywords:
            "Entity, EGLD, ENT, Launchpad, Crypto rewards, DeFi, MultiversX, Elrond, Growth Engine, Elrond Network, Staking, EGLD Tax, Token, Blockchain startups, Projects, Privacy Policy",
    },
    disclaimer: {
        title: "Disclaimer",
        description:
            "Learn more about how Entity protects users and follows all legal requirements in order to safeguard community members and ensure the security of their assets.",
        keywords:
            "Entity, EGLD, ENT, Launchpad, Crypto rewards, DeFi, MultiversX, Elrond, Growth Engine, Elrond Network, Staking, EGLD Tax, Token, Blockchain startups, Projects, Disclaimer",
    },
    imprint: {
        title: "Imprint",
        description: "Entity imprint page.",
        keywords:
            "Entity, EGLD, ENT, Launchpad, Crypto rewards, DeFi, MultiversX, Elrond, Growth Engine, Elrond Network, Staking, EGLD Tax, Token, Blockchain startups, Imprint",
    },
    newsletter: {
        title: "Newsletter",
        description: "Entity newsletter page.",
        keywords:
            "Entity, EGLD, ENT, Launchpad, Crypto rewards, DeFi, MultiversX, Elrond, Growth Engine, Elrond Network, Staking, EGLD Tax, Token, Blockchain startups, Newsletter",
    },
};
