import React, { FC, PropsWithChildren } from "react";
import { OverlayTrigger, OverlayTriggerProps, Tooltip } from "react-bootstrap";

const tooltip = (tooltipText: string | JSX.Element): JSX.Element => (
    <Tooltip style={{ position: "fixed" }} id="button-tooltip">
        {tooltipText}
    </Tooltip>
);

export interface OverlayTriggerTooltipProps extends PropsWithChildren<Partial<OverlayTriggerProps>> {
    tooltipText?: string | JSX.Element;
    inline?: boolean;
    cursor?: string;
    className?: string;
}

const OverlayTriggerTooltip: FC<React.PropsWithChildren<OverlayTriggerTooltipProps>> = ({
    inline = false,
    placement = "right",
    delay = { show: 250, hide: 400 },
    tooltipText = "Coming soon",
    overlay = tooltip(tooltipText),
    children = <span />,
    cursor = "not-allowed",
    className = "",
    ...props
}) => {
    const displayProp = inline ? { display: "inline-block" } : { display: "block" };
    return (
        <div style={{ position: "relative", ...displayProp }} className={`${className} overlay-tooltip`}>
            {children}
            <OverlayTrigger placement={placement} delay={delay} overlay={overlay} {...props}>
                <div
                    style={{ position: "absolute", top: "0", left: "0", width: "100%", height: "100%", cursor }}
                    className="e-tooltip"
                />
            </OverlayTrigger>
        </div>
    );
};

export default OverlayTriggerTooltip;
