import { SignedTransactionsBodyType, SignedTransactionType } from "@multiversx/sdk-dapp/types";
import LdmService, { LdmSaleOffer, LdmUpdatedSaleStatus } from "./LdmService";
import TxUtils from "../common/utils/TxUtils";
import { LdmScFunc } from "./LdmScInteractorService";
import LocalStorageService from "./localStorageService/LocalStorageService";
import { LocalStorageKeys } from "./localStorageService/LocalStorageKeys";
import { GetTransactionsByHashesReturnType } from "@multiversx/sdk-dapp/types/transactions.types";
import { getTransactionsByHashes } from "@multiversx/sdk-dapp/apiCalls/transactions/getTransactionsByHashes";
import { ldmSC } from "../config";
import BinaryUtils from "../common/utils/BinaryUtils";
import EventsService, { EventsNames } from "./EventsService";

interface LdmTx {
    tx: SignedTransactionType;
    scFunc: LdmScFunc;
}

class TxPostProcessingService {
    static async postProcessTx(
        txSigned: SignedTransactionsBodyType,
        ldmService: LdmService,
        eventsService: EventsService,
    ): Promise<void> {
        const txFunc = txSigned.transactions?.map(TxPostProcessingService.mapTxDataToFunc).filter((txData) => !!txData);
        if (!txFunc) return;
        for (const txFunction of txFunc) {
            if (!txFunction) continue;
            await TxPostProcessingService.registerSaleTx(txFunction, ldmService, eventsService);
        }
    }

    private static mapTxDataToFunc(tx: SignedTransactionType): LdmTx | undefined {
        if (!tx.data) return undefined;
        const txDataParts = TxUtils.decodeMixComposeData(tx.data);
        if (txDataParts.includes(LdmScFunc.CREATE_SALE)) return { tx, scFunc: LdmScFunc.CREATE_SALE };
        if (txDataParts.includes(LdmScFunc.APPROVE_SALE)) return { tx, scFunc: LdmScFunc.APPROVE_SALE };
        if (txDataParts.includes(LdmScFunc.DENY_SALE)) return { tx, scFunc: LdmScFunc.DENY_SALE };
        if (txDataParts.includes(LdmScFunc.END_SALE)) return { tx, scFunc: LdmScFunc.END_SALE };
        if (txDataParts.includes(LdmScFunc.PAUSE_SALE)) return { tx, scFunc: LdmScFunc.PAUSE_SALE };
        if (txDataParts.includes(LdmScFunc.UNPAUSE_SALE)) return { tx, scFunc: LdmScFunc.UNPAUSE_SALE };
        if (txDataParts.includes(LdmScFunc.EMERGENCY)) return { tx, scFunc: LdmScFunc.EMERGENCY };
        if (txDataParts.includes(LdmScFunc.SELL_TOKENS)) return { tx, scFunc: LdmScFunc.SELL_TOKENS };
        return undefined;
    }

    private static async registerSaleTx(txInfo: LdmTx, ldmService: LdmService, eventsService: EventsService) {
        switch (txInfo.scFunc) {
            case LdmScFunc.CREATE_SALE: {
                await TxPostProcessingService.registerCreateSaleTx(txInfo.tx, ldmService);
                break;
            }
            case LdmScFunc.APPROVE_SALE:
            case LdmScFunc.DENY_SALE:
            case LdmScFunc.END_SALE:
            case LdmScFunc.PAUSE_SALE:
            case LdmScFunc.UNPAUSE_SALE:
            case LdmScFunc.EMERGENCY: {
                await TxPostProcessingService.registerSaleStatusUpdateTx(txInfo.scFunc, ldmService);
                break;
            }
            case LdmScFunc.SELL_TOKENS: {
                eventsService.getEmitter().emit(EventsNames.REFETCH_PUBLIC_SALE);
                break;
            }
        }
    }

    private static async registerCreateSaleTx(tx: SignedTransactionType, ldmService: LdmService) {
        const ldmSaleOffer = LocalStorageService.getItem<LdmSaleOffer>(LocalStorageKeys.ldmSaleOffer);
        if (ldmSaleOffer) {
            const txDetails: GetTransactionsByHashesReturnType = await getTransactionsByHashes([
                {
                    hash: tx?.hash,
                    previousStatus: tx.status.toLowerCase(),
                },
            ]);
            const scResultData = txDetails[0].results.find((scRes) => scRes.sender === ldmSC)?.data;
            if (!scResultData) return;
            const parts = TxUtils.decodeComposeData(scResultData);
            ldmSaleOffer.externalId = BinaryUtils.hexToNumber(parts[parts.length - 1]);
            await ldmService.registerSaleOffer(ldmSaleOffer);
            LocalStorageService.removeItem(LocalStorageKeys.ldmSaleOffer);
        }
    }

    private static async registerSaleStatusUpdateTx(txFunc: LdmScFunc, ldmService: LdmService) {
        const ldmSaleStatus = LocalStorageService.getItem<LdmUpdatedSaleStatus>(LocalStorageKeys.ldmSaleStatus);
        if (ldmSaleStatus) {
            await ldmService.updateSaleStatus(ldmSaleStatus);
            LocalStorageService.removeItem(LocalStorageKeys.ldmSaleStatus);
        }
    }
}

export default TxPostProcessingService;
