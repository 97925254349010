import React, { FC, useEffect, useState } from "react";
import styled, { css } from "styled-components";
import { Button } from "react-bootstrap";
import Placeholder from "react-bootstrap/Placeholder";
import Content from "./Content";

// TODO: use ButtonProps type from react-bootstrap
/* eslint-disable @typescript-eslint/no-explicit-any */
type BtnProps = Record<string, any>;

type LoadingState = {
    loading?: boolean;
    loadingText?: string;
};

export interface EButtonProps extends LoadingState, BtnProps {
    color?: string;
    gradientAngle?: string;
    linearGradient?: string[];
    borderColor?: string;
    testId?: string;
    backgroundColor?: string;
    hoverColor?: string;
    showPlaceholder?: boolean;
}

export enum buttonVariants {
    premium = "premium",
    danger = "danger",
    warn = "warn",
}

export const defaultColor = "var(--medium-blue)";
const defaultBorderColor = "#173e49";
const defaultAngle = "180";
const defaultLinearGradient = ["#246171", "#1B4C55", "#153E41"];

export const premiumStyles = css`
    color: var(--premium-blue);
    border-color: var(--premium-blue);

    &:not(:disabled):not(.disabled) {
        &:hover,
        &:active,
        .active:focus,
        &:active:focus {
            background-color: var(--premium-blue);
            border-color: var(--premium-blue);
            box-shadow: none;
        }
    }
`;

const dangerColor = "var(--entity-red-medium)";
export const dangerStyles = css`
    color: ${dangerColor};

    &:not(:disabled):not(.disabled) {
        &:hover,
        &:active,
        .active:focus,
        &:active:focus {
            color: ${dangerColor};
        }
    }
`;

export const warnColor = "#FFA35F";
export const warnStyles = css`
    background: none;
    border-color: ${warnColor};
    color: ${warnColor};

    &:not(:disabled):not(.disabled) {
        &:hover,
        &:active,
        .active:focus,
        &:active:focus {
            background-color: ${warnColor};
            border-color: ${warnColor};
            box-shadow: none;
            color: white;
        }
    }
`;

const getLinearGradient = (linearGradient: string[]) =>
    `${linearGradient[0]} 0%, ${linearGradient[1]} 50%, ${linearGradient[2]} 100%`;

export const eButtonStyles = css`
    background: ${(props: Required<EButtonProps>): string => props.backgroundColor || "transparent"}
        linear-gradient(
            ${({ gradientAngle }: Required<EButtonProps>): string => gradientAngle || defaultAngle}deg,
            ${({ linearGradient: linearGradientBackground }: Required<EButtonProps>): string =>
                getLinearGradient(linearGradientBackground || defaultLinearGradient)}
        )
        0 0;
    background-origin: padding-box !important;
    background-repeat: no-repeat !important;
    color: ${(props: Required<EButtonProps>): string => props.color || defaultColor};
    border: 1px solid ${(props: Required<EButtonProps>): string => props.borderColor || defaultBorderColor};
    width: fit-content;
    letter-spacing: 0;
    align-self: center;
    padding: 0.714rem 1.214rem;
    min-width: 7.142rem;
    display: block;
    font: normal normal medium 1.143rem/1.357rem var(--entity-exo-font-family);
    opacity: 1;
    white-space: nowrap;

    &:not(:disabled):not(.disabled) {
        &:hover,
        &:active,
        .active,
        .active:focus,
        &:active:focus {
            background: ${({ linearGradient: linearGradientBackground }: Required<EButtonProps>): string =>
                (linearGradientBackground || defaultLinearGradient)[0]};
            color: ${(props: Required<EButtonProps>): string => props.color || defaultColor};
            border-color: ${(props: Required<EButtonProps>): string => props.borderColor || defaultBorderColor};
            opacity: 1;
            box-shadow: none;
        }
    }

    &:disabled,
    &.disabled {
        filter: brightness(75%);
        background: transparent
            linear-gradient(
                ${({ gradientAngle }: Required<EButtonProps>): string => gradientAngle || defaultAngle}deg,
                ${({ linearGradient: linearGradientBackground }: Required<EButtonProps>): string =>
                    getLinearGradient(linearGradientBackground || defaultLinearGradient)}
            )
            0 0 no-repeat padding-box !important;
        color: ${(props: Required<EButtonProps>): string => props.color || defaultColor} !important;
        border-color: ${(props: Required<EButtonProps>): string => props.borderColor || defaultBorderColor} !important;
        pointer-events: auto;
        cursor: not-allowed;
    }

    @media (max-width: 260px) {
        width: 100%;
    }

    &.btn-${buttonVariants.premium} {
        ${premiumStyles};
    }

    &.btn-${buttonVariants.danger} {
        ${dangerStyles};
    }

    &.btn-${buttonVariants.warn} {
        ${warnStyles};
    }
`;

const Btn = styled(Button)`
    ${eButtonStyles};
`;

const BtnPlaceholder = styled(Placeholder.Button)`
    ${eButtonStyles};

    &&& {
        color: transparent !important;
    }
`;

const EButton: FC<React.PropsWithChildren<EButtonProps>> = ({
    color = defaultColor,
    disabled,
    loading = false,
    loadingText = "Loading...",
    showPlaceholder = false,
    testId = "btn",
    children,
    ...props
}) => {
    const [isLoading, setLoading] = useState(loading);
    const [isPlaceholder, setIsPlaceholder] = useState(showPlaceholder);
    useEffect(() => setLoading(loading), [loading]);
    useEffect(() => setIsPlaceholder(showPlaceholder), [showPlaceholder]);

    disabled = isLoading ? true : disabled;

    const BtnContent = () => <Content customContent={children} isLoading={isLoading} loadingText={loadingText} />;

    return (
        <>
            {!isPlaceholder && (
                <Btn color={color} disabled={disabled} {...props} data-testid={testId}>
                    <BtnContent />
                </Btn>
            )}
            {isPlaceholder && (
                <BtnPlaceholder color={color} disabled={disabled} {...props} data-testid={testId} aria-hidden="true">
                    <BtnContent />
                </BtnPlaceholder>
            )}
        </>
    );
};

export default EButton;
