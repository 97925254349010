import React from "react";

export const renderHTML = (rawHtml: string, maxCharacters?: number): JSX.Element => {
    let rawContent = rawHtml;
    if (maxCharacters) {
        const text = extractTextFromHtml(rawHtml);
        rawContent = text.length > maxCharacters ? text.substring(0, maxCharacters) + "..." : text;
    }
    return React.createElement("div", { dangerouslySetInnerHTML: { __html: rawContent } });
};

const startTagDelimiter = "<";
const endTagDelimiter = ">";

export const extractTextFromHtml = (token: string): string => {
    let i1 = token.indexOf(startTagDelimiter);
    let i2 = token.indexOf(endTagDelimiter);
    while (i1 >= 0 && i2 >= 0) {
        token = token.replace(token.substring(i1, i2 + 1), "");
        i1 = token.indexOf(startTagDelimiter);
        i2 = token.indexOf(endTagDelimiter);
    }
    return token;
};
