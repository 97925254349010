import React, { FC, useState } from "react";
import { FinalKycStatus, InitKycStatus, KycStatusInfo } from "../Launchpad/kyc/launchpad.kyc.dtos";
import { buttonVariants, ELinkButton } from "../../components/Buttons";
import { routeNames } from "../../routes/routes";
import { faCheckCircle, faExclamationCircle, faInfoCircle, IconDefinition } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { KycRejectedReasonsModal } from "./KycRejectedReasonsModal";
import { SettingsButton } from "../../common";

interface KycStatusDescriptionProps {
    statusInfo: KycStatusInfo;
    isLaunchpadCardActive?: boolean;
    onStartAgainHandler?: () => void;
}

const mapKycStatusesToLabelInfo = (
    statusInfo: KycStatusInfo,
    isLaunchpadCardActive: boolean,
    onStartAgainHandler?: () => void,
): {
    text: string;
    icon?: IconDefinition | undefined;
    className?: string;
    children?: JSX.Element;
} => {
    if (
        statusInfo.initStatus === InitKycStatus.AWAIT_VERIFICATION ||
        (statusInfo.initStatus === InitKycStatus.VERIFIED && !statusInfo.status)
    ) {
        return {
            icon: faInfoCircle,
            text: "Your KYC has been submitted. We will review your information and get back to you within 72 hours.",
            className: "text-white",
        };
    }
    if (statusInfo.status === FinalKycStatus.APPROVED) {
        return {
            icon: faCheckCircle,
            text: "You successfully passed the KYC process!",
            className: "text-green",
        };
    }
    if (statusInfo.status === FinalKycStatus.DENIED || statusInfo.initStatus === InitKycStatus.FINAL_REJECTED) {
        return {
            icon: faExclamationCircle,
            text: "Unfortunately, your KYC was rejected for a final time.",
            className: "text-red",
        };
    }
    if (statusInfo.initStatus === InitKycStatus.REJECTED) {
        return {
            icon: faExclamationCircle,
            text: `Your KYC was rejected. You have ${statusInfo.attemptsRemaining} attempt(s) left.`,
            className: "text-warning",
            children: (
                <>
                    {statusInfo.attemptsRemaining > 0 && !isLaunchpadCardActive && onStartAgainHandler && (
                        <SettingsButton className="w-25 py-3" onClick={onStartAgainHandler}>
                            Start again
                        </SettingsButton>
                    )}
                    {statusInfo.attemptsRemaining > 0 && isLaunchpadCardActive && (
                        <ELinkButton to={routeNames.kyc} testId="start-kyc-again" className="text-uppercase">
                            Start KYC again
                        </ELinkButton>
                    )}
                </>
            ),
        };
    }
    return {
        text: "You have not started the KYC process yet.",
        children: (
            <ELinkButton to={routeNames.kyc} testId="start-kyc" className="text-uppercase">
                Start KYC
            </ELinkButton>
        ),
    };
};

const KycStatusDescription: FC<React.PropsWithChildren<KycStatusDescriptionProps>> = ({
    statusInfo,
    isLaunchpadCardActive = true,
    onStartAgainHandler,
}) => {
    const [showRejectedReasonsModal, setShowRejectedReasonsModal] = useState(false);

    if (!isLaunchpadCardActive) {
        return <></>;
    }

    const { text, icon, className, children } = mapKycStatusesToLabelInfo(statusInfo, isLaunchpadCardActive, onStartAgainHandler);

    return (
        <>
            {!!icon && <FontAwesomeIcon size="3x" className={`mb-3 ${className}`} icon={icon} />}
            {!!text && <p className={`text-center ${className}`}>{text}</p>}
            {(statusInfo.status && statusInfo.status === FinalKycStatus.DENIED) ||
                (statusInfo.initStatus &&
                    [InitKycStatus.REJECTED, InitKycStatus.FINAL_REJECTED].includes(statusInfo.initStatus) &&
                    statusInfo.status !== FinalKycStatus.APPROVED &&
                    statusInfo.rejectedReasons && (
                        <SettingsButton
                            variant={buttonVariants.warn}
                            onClick={(e: MouseEvent) => {
                                e.preventDefault();
                                setShowRejectedReasonsModal(true);
                            }}
                            className="mb-3"
                            data-testId="show-reasons"
                        >
                            Show reasons
                        </SettingsButton>
                    ))}
            {!!children && children}
            <KycRejectedReasonsModal
                reasons={statusInfo.rejectedReasons}
                show={showRejectedReasonsModal}
                closeModalHandler={() => setShowRejectedReasonsModal(false)}
            />
        </>
    );
};

export default KycStatusDescription;
