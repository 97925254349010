import styled from "styled-components";

export const PageHeader = styled.h1`
    color: var(--primary-text);
    font-size: 2.714rem;
    letter-spacing: 0;
    line-height: 2.714rem;

    @media (min-width: 260px) and (max-width: 1200px) {
        font-size: 1.7rem;
        font-weight: bolder !important;
    }
`;
