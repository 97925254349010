import React from "react";
import { useGetLoginInfo } from "@multiversx/sdk-dapp/hooks";
import { Link } from "react-router-dom";
import { routeNames } from "../../../../routes/routes";
import { useDispatch } from "../../../../context";
import { performLogout } from "../../../../helpers/util.functions";

const NewNavbarLaunchButton = () => {
    const { isLoggedIn } = useGetLoginInfo();
    const dispatch = useDispatch();

    const handleDisconnect = (): void => {
        performLogout(dispatch);
    };

    const getLaunchButton = () => {
        if (isLoggedIn) {
            return (
                <div onClick={handleDisconnect} className="nav-contact-button cursor-pointer">
                    Log out
                </div>
            );
        }

        return (
            <Link to={routeNames.unlock} className="nav-contact-button">
                Launch app
            </Link>
        );
    };

    return <div>{getLaunchButton()}</div>;
};

export default NewNavbarLaunchButton;
