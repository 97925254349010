import React from "react";
import { Placeholder, PlaceholderProps } from "react-bootstrap";
import { EButton } from "../Buttons";
import { PlaceholderAnimation } from "react-bootstrap/usePlaceholder";

interface EPlaceholderProps {
    withBtn?: boolean;
    numberOfLines?: number;
    lineClass?: string;
    btnClass?: string;
    width?: number;
    animation?: PlaceholderAnimation;
    as?: PlaceholderProps["as"];
    size?: PlaceholderProps["size"];
}

export const lineWidth = (n: number): number[] => {
    const arr = [];
    for (let i = 0; i < n; i++) {
        arr[i] = Math.floor(Math.random() * (12 - 3) + 3);
    }
    return arr;
};

const EPlaceholder = ({
    withBtn = false,
    numberOfLines = 1,
    lineClass = "",
    btnClass = "",
    width = 12,
    animation = "glow",
    size = "sm",
    as,
}: EPlaceholderProps) => {
    const placeholders = lineWidth(numberOfLines);

    return (
        <>
            {placeholders.map((item, index) => (
                <Placeholder
                    animation={animation}
                    xs={width}
                    size={size}
                    as={as}
                    key={index}
                    className={`${lineClass ? lineClass : ""}`}
                    data-textid="placeholder"
                >
                    <Placeholder xs={item} size={size} />
                </Placeholder>
            ))}
            {withBtn && (
                <EButton showPlaceholder={true} className={`mt-3 d-flex ${btnClass ? btnClass : ""}`} testId="placeholder-btn">
                    Placeholder
                </EButton>
            )}
        </>
    );
};

export default EPlaceholder;
