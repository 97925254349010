export enum EmailStatus {
    confirmed = "confirmed",
    notConfirmed = "notConfirmed",
    inProgress = "inProgress",
}

export enum EmailHashStatus {
    expired = "expired",
    confirmed = "confirmed",
    new = "new",
}

export interface ValidateHashResponse {
    status: EmailHashStatus;
}

export interface ConditionsAgreement {
    conditionsAgreement: boolean;
}

export interface User extends ConditionsAgreement {
    id: string;
    publicAddress: string;
    email: string;
    emailStatus: EmailStatus;
    username: string;
    walletName: string;
    isWalletMonitored: boolean;
    isTelegramBotNotificationEnabled: boolean;
    isBrowserNotificationEnabled: boolean;
    isEmailNotificationEnabled: boolean;
    roles: string[];
    confirmEmailSentAt: number;
    conditionsAgreement: boolean;
}

export interface UserNotifications {
    isTelegramBotNotificationEnabled: boolean;
    isBrowserNotificationEnabled: boolean;
    isEmailNotificationEnabled: boolean;
}
