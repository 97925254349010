import React from "react";
import { Col, Form, Row } from "react-bootstrap";
import { useYupValidationResolver } from "../../common";
import { useForm } from "react-hook-form";
import * as yup from "yup";
import AlertService from "../../services/AlertService";
import SessionStorageService from "../../services/sessionStorageService/SessionStorageService";
import { IcoListingSteps } from "./IcoCreation";
import {
    Hardcap,
    HasKyc,
    IcoLayer,
    IcoStep,
    KycStartQuarter,
    LaunchPlatform,
    MaxTokenSupply,
    PreSaleStartQuarter,
    PreSaleStartTimestamp,
    PublicSaleOfferedTokens,
    PublicSalePrice,
    PublicSaleStartQuarter,
    PublicSaleStartTimestamp,
    Softcap,
    TokenIdentifier,
    TokenName,
    TradableStartQuarter,
} from "./ico.types";
import IcoEditStepTwo, { getIcoStepTwoValidationSchema } from "./Edit/IcoEditStepTwo";
import { EButton } from "../../components/Buttons";
import { IcoLayers, LaunchPlatforms } from "../../helpers/consts";
import { TRangeDate } from "../../common/form/types";
import { SessionStorageKeys } from "../../services/sessionStorageService/sessionStorageKeys";

export interface IcoStepTwoInputs
    extends LaunchPlatform,
        IcoLayer,
        TokenName,
        MaxTokenSupply,
        PublicSalePrice,
        HasKyc,
        PreSaleStartTimestamp,
        PublicSaleStartTimestamp,
        PublicSaleStartQuarter,
        PreSaleStartQuarter,
        KycStartQuarter,
        TradableStartQuarter,
        Softcap,
        Hardcap,
        TokenIdentifier,
        PublicSaleOfferedTokens {
    // formatted string dates only to work on FE
    preSaleDate: TRangeDate;
    publicSaleDate: TRangeDate;
    kycDate: TRangeDate;
    tradableDate: TRangeDate;
    // timestamps for dates as they are retrieved from BE
    preSaleEndTimestamp?: number;
    publicSaleEndTimestamp?: number;
    tradableStartTimestamp?: number;
    kycStartTimestamp?: number;
    kycEndTimestamp?: number;
    privateSaleOfferedTokens: number | null;
    privateSalePrice: number | null;
    privateSaleSoldTokens: number | null;
    initialCirculatingSupply: number | null;
}

export const quarterDateRangeInitial: TRangeDate = { start: "", end: "", quarterInfo: undefined, useDate: true };

export const icoStepTwoInitial: IcoStepTwoInputs = {
    icoLayer: IcoLayers.dApp,
    tokenName: "",
    token: null,
    maxTokenSupply: 0,
    preSaleDate: quarterDateRangeInitial,
    publicSaleDate: quarterDateRangeInitial,
    kycDate: quarterDateRangeInitial,
    tradableDate: quarterDateRangeInitial,
    softcap: null,
    hardcap: null,
    publicSalePrice: 0,
    hasKyc: false,
    launchPlatform: LaunchPlatforms.Entity,
    privateSaleOfferedTokens: null,
    privateSalePrice: null,
    privateSaleSoldTokens: null,
    publicSaleOfferedTokens: null,
    initialCirculatingSupply: null,
};

function toIcoLayerKey(value: string): IcoLayers {
    switch (value) {
        case IcoLayers.Protocol:
            return IcoLayers.Protocol;
        case IcoLayers.Platform:
            return IcoLayers.Platform;
        case IcoLayers.dApp:
        default:
            return IcoLayers.dApp;
    }
}

const getIco = (input: IcoStepTwoInputs) => {
    const { preSaleDate, publicSaleDate, kycDate, tradableDate } = input;
    const { start: preSaleStart, end: preSaleEnd } = preSaleDate;
    const { start: publicSaleStart, end: publicSaleEnd } = publicSaleDate;
    const { start: kycStart, end: kycEnd } = kycDate;
    const { start: tradableStart } = tradableDate ?? {};

    return {
        ...input,
        preSaleStart,
        preSaleEnd,
        publicSaleStart,
        publicSaleEnd,
        kycStart,
        kycEnd,
        tradableStart,
    };
};

const getValidationSchema = () => {
    return yup.object(getIcoStepTwoValidationSchema());
};

const IcoStepTwo: React.FC<React.PropsWithChildren<IcoStep>> = ({ step, changeStep }) => {
    const sessionIco = SessionStorageService.getItem("ico");

    const getInitialValues = () => {
        if (sessionIco && sessionIco.stepTwo) {
            const icoInitial: IcoStepTwoInputs = {
                icoLayer: toIcoLayerKey(sessionIco.stepTwo.icoLayer),
                tokenName: sessionIco.stepTwo.tokenName,
                token: sessionIco.stepTwo.token,
                maxTokenSupply: sessionIco.stepTwo.maxTokenSupply,
                preSaleDate: sessionIco.stepTwo.preSaleDate ?? quarterDateRangeInitial,
                publicSaleDate: sessionIco.stepTwo.publicSaleDate ?? quarterDateRangeInitial,
                kycDate: sessionIco.stepTwo.kycDate ?? quarterDateRangeInitial,
                tradableDate: sessionIco.stepTwo.tradableDate ?? quarterDateRangeInitial,
                softcap: sessionIco.stepTwo.softcap,
                hardcap: sessionIco.stepTwo.hardcap,
                publicSalePrice: sessionIco.stepTwo.publicSalePrice,
                publicSaleOfferedTokens: sessionIco.stepTwo.publicSaleOfferedTokens,
                privateSalePrice: sessionIco.stepTwo.privateSalePrice,
                privateSaleOfferedTokens: sessionIco.stepTwo.privateSaleOfferedTokens,
                privateSaleSoldTokens: sessionIco.stepTwo.privateSaleSoldTokens,
                hasKyc: sessionIco.stepTwo.hasKyc,
                launchPlatform: sessionIco.stepTwo.launchPlatform,
                initialCirculatingSupply: sessionIco.stepTwo.initialCirculatingSupply,
            };

            return icoInitial;
        }

        return icoStepTwoInitial;
    };

    const icoInfo = getInitialValues();
    const validationResolver = useYupValidationResolver(getValidationSchema());
    const { register, handleSubmit, formState, control, setValue } = useForm<IcoStepTwoInputs>({
        defaultValues: icoInfo,
        resolver: validationResolver,
    });

    const onStepTwoChangeHandler = (input: IcoStepTwoInputs) => {
        AlertService.successToast("Step two completed!");
        const ico: IcoListingSteps = sessionIco;
        input.hasKyc = !!input.kycDate?.start && !!input.kycDate?.end;
        ico.stepTwo = getIco(input);
        SessionStorageService.setItem(SessionStorageKeys.ico, ico);
        changeStep(3);
    };

    return (
        <div>
            <Form className="mt-3 steptwo" onSubmit={handleSubmit(onStepTwoChangeHandler)}>
                <IcoEditStepTwo ico={icoInfo} register={register} formState={formState} control={control} setValue={setValue} />
                <Row className="mt-1">
                    <Col>
                        <EButton
                            className="mt-2 w-100 py-3 text-capitalize"
                            type="button"
                            onClick={() => changeStep(1)}
                            testId="back"
                        >
                            Go back
                        </EButton>
                    </Col>
                    <Col>
                        <EButton className="mt-2 w-100 py-3 text-capitalize" type="submit" testId="next">
                            Next step
                        </EButton>
                    </Col>
                </Row>
            </Form>
        </div>
    );
};

export default IcoStepTwo;
