import React, { ReactNode } from "react";
import { Spinner } from "react-bootstrap";
import styled from "styled-components";

interface ContentProps {
    isLoading: boolean;
    loadingText: string;
    customContent: ReactNode;
}

const LoadingContainer = styled.span`
    margin-left: 0.5rem;
`;

const Content = ({ isLoading, loadingText, customContent }: ContentProps) => (
    <>
        {isLoading && (
            <>
                <Spinner as="span" animation="border" size="sm" role="status" aria-hidden="true" />
                <LoadingContainer>{loadingText}</LoadingContainer>
            </>
        )}
        {!isLoading && customContent}
    </>
);

export default Content;
