import React from "react";
import { EPlaceholder } from "../../../../../../components/Placeholder";
import { Amount, Description } from "./DescribedNumber";

const Placeholder = () => (
    <>
        <EPlaceholder as={Amount} />
        <EPlaceholder as={Description} />
    </>
);

export default Placeholder;
