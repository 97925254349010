import React, { FC } from "react";
import styled from "styled-components";
import { Card } from "react-bootstrap";
import { routeNames } from "../../routes/routes";
import { Link } from "react-router-dom";

const LiWithSpace: FC<React.PropsWithChildren<unknown>> = ({ children }) => <li>&emsp;{children}</li>;

const UppercaseParagraph = styled.p`
    text-transform: uppercase;
`;

const Title = styled.p`
    font-size: 1.3rem;
    text-transform: uppercase;
    font-weight: bold;
`;

const SubSectionHeader = styled.p`
    font-weight: bold;
    text-decoration: underline;
`;

const Note = styled.p`
    text-transform: uppercase;
    font-weight: bold;
    text-align: justify;
`;

const ListParagraph = styled.p`
    margin-left: 3rem;
`;

const Container = styled.p`
    color: white;
    line-height: 2.1rem;
    font-size: 1.1rem;

    a {
        color: var(--entity-green);
    }
`;

const TermsAndConditions = () => {
    return (
        <Container>
            <div className="container py-2 py-md-5">
                <Card>
                    <Card.Body>
                        <Title>TERMS OF USE</Title>
                        <p>Our Terms of Use were last updated on: 13 Feb 2023</p>
                        <Note>
                            PLEASE READ THESE TERMS OF USE CAREFULLY BEFORE ACCESSING AND USING THE WEBSITE AND OUR SERVICES
                            PROVIDED WITHIN. NOTE THAT THESE TERMS OF USE CONTAIN AMONG OTHERS A DISCLAIMER OF WARRANTIES,
                            LIMITATION ON LIABILITY AND INDEMNIFICATION WHICH, IF APPLICABLE TO YOU, COULD AFFECT YOUR LEGAL
                            RIGHTS. BY CLICKING TO ACCEPT AND/USING OUR SERVICE, YOU AGREE TO BE BOUND BY THESE TERMS AND ALL OF
                            THE TERMS INCORPORATED HEREIN BY REFERENCE. IF YOU DO NOT AGREE TO THESE TERMS OF USE, DO NOT USE OUR
                            SERVICES PROVIDED WITHIN THIS WEBSITE.{" "}
                        </Note>
                        <p>
                            This website (
                            <a target="_blank" rel="noreferrer" href="https://entity.global/">
                                https://entity.global/
                            </a>
                            ) is operated by ENT Technologies AG (‘’ENTITY’’, ‘’We’’, ‘’Us’’, or ‘’Our’’). These Terms of Use
                            (“Terms”) govern your access as a visitor of the website and user of Our ENTITY Growth Engine within,
                            as well as all services, tools, features, and information made available on the website and any other
                            software, tools, features, or functionalities provided on or in connection with Our services;
                            including without limitation using Our services and Our tools, at your own discretion (collectively,
                            the ”Services”).{" "}
                        </p>
                        <p>
                            When accepted by You, these Terms and any documents We refer to, together with any operating rules and
                            policies, are legally binding between ,,You’’ and ,,Us’’.{" "}
                        </p>
                        <p>
                            This Terms of Services may be updated from time to time to any reason. We reserve the right, at Our
                            sole discretion, to change these Terms, to modify or replace any part. If We make material changes to
                            these Terms, We will notify You of any changes to Our Terms by posting the new Terms. The date the
                            Terms were last revised is identified at the beginning of this Terms. It is Your sole responsibility
                            to review the Terms from time to time to view such changes and to ensure that You understand the terms
                            and conditions that apply when You access or use Our Services. By continuing to access or use the
                            Service, you confirm Your acceptance of the revised Terms. Changes to this Terms are effective when
                            they are posted on this page.
                        </p>
                        <p>
                            If there is a conflict between two versions of the Terms that You have agreed to or been deemed to
                            have agreed to, the latest version shall prevail unless expressly provided otherwise.
                        </p>

                        <Title>INTRODUCTION</Title>

                        <SubSectionHeader>About ENTITY Growth Engine</SubSectionHeader>
                        <p>
                            ENTITY is an augmentative Growth Engine on the MultiversX featuring a Launchpad for new Web3 projects,
                            that allows natural persons, representatives of legal persons, or any other entities (“
                            <b>Users</b>” or “<b>You</b>”) who meet the criteria and conditions stipulated by these Terms to
                            access early-stage crypto projects before they enter into the mainstream and participate in their
                            public sales rounds (“<b>Projects</b>”). It’s and a suite of tools that elevate the entire MultiversX
                            community. Whether creating new opportunities for passive income and project expansion, presenting a
                            range of structured data in a unified interface, or helping users conquer the stress of tax season,{" "}
                            <b>ENTITY Growth Engine</b> empowers users through every step of their blockchain journey.
                        </p>
                        <p>
                            ENTITY is not a wallet provider, exchange, broker, financial institution or creditor. ENTITY Growth
                            Engine consists of a software platform that facilitates the access of Users to certain Projects
                            published on it. In this and other contexts, such as the analysis of one&apos;s crypto portfolio, it
                            may be necessary for the user to connect his wallet to the ENTITY Growth Engine in order to access
                            some of the Services We offer. Please note that We are not responsible for any loss or damage that may
                            arise from such integration.
                        </p>
                        <SubSectionHeader>Important disclaimer</SubSectionHeader>
                        <p>
                            You hereby declare that You fully understand that the Our Services involve nascent technologies, and
                            the usage of such Services could result in partial or complete loss of funds, and consequently, You
                            undertake the risk associated with the use of nascent technologies. No recommendation is made herein
                            as to the advisability of using our Services.
                        </p>
                        <p>
                            ENTITY is not intended as, and does not provide, any investment or financial advice whatsoever should
                            not be regarded as an offer, solicitation, invitation, or recommendation to invest, and is not
                            intended to be relied upon by You in making any specific investment or other decisions. As with any
                            financial or investment decisions, You should conduct Your own research and due diligence
                            investigation, to properly evaluate the benefits and risks of any investment or financial transaction
                            performed on or in connection to ENTITY. You should also seek the advice and guidance of qualified
                            accountants, financial advisors, tax advisors, legal counsels and investment advisors, in connection
                            with any investment or financial transaction performed on or in connection to the ENTITY Growth
                            Engine. ENTITY only provides advice on the technical use of the ENTITY Growth Engine.
                        </p>
                        <ListParagraph>
                            Nothing on the ENTITY Growth Engine or in any communications (including the marketing communications)
                            which We are sending to You is intended to represent the investment or financial advice or a
                            recommendation in this respect.
                        </ListParagraph>
                        <p>
                            ENTITY Growth Engine may be seen as a User-controlled wallet due to the fact that only the user has
                            control over his funds. You hereby declare that You fully understand that if You lose your recovery
                            words (and the private keys associated with Your wallet), You will not be able to access Your wallet
                            and Your funds. We do not have access to Your recovery words (and the private keys associated with
                            Your wallet), You are the only one who has access to them.
                        </p>
                        <p>
                            <div>We do not promise or guarantee that:</div>
                            <ListParagraph>
                                <div>
                                    a.&emsp;ENTITY Growth Engine is free of viruses or errors, its content is accurate, that its
                                    functioning will be uninterrupted, or that defects will be corrected;
                                </div>
                                <div>b.&emsp;the Services will be available at all times;</div>
                                <div>
                                    c.&emsp;access to ENTITY Growth Engine, any part of the Services, our Terms, and other
                                    information provided by Us on ENTITY Growth Engine will be continuous, uninterrupted, timely,
                                    error-free, or omissions-free.
                                </div>
                            </ListParagraph>
                        </p>
                        <p>
                            We have the right to limit, at Our sole discretion, the availability of ENTITY Growth Engine to any
                            person, geographic area, or jurisdiction and/or to prohibit Your access to and use of the ENTITY
                            Growth Engine at any time.
                        </p>
                        <p>
                            No part of ENTITY Growth Engine is intended to constitute advice, and the content of ENTITY Growth
                            Engine should not be relied upon when making any decisions or taking any action of any kind.
                        </p>
                        <p>
                            We will not be liable in any way for any damage or loss arising from the use or inability to use
                            ENTITY Growth Engine.
                        </p>
                        <p>
                            We shall not be liable in connection with any error or inaccuracy in the data entered by You or
                            another user or any misrepresentation or willful misconduct or any other user.
                        </p>
                        <p>
                            We shall not be liable to You for any indirect, consequential, special or punitive loss, damage, cost
                            or expense (including legal costs), unforeseeable losses or damages, loss of profit, loss of business,
                            lost or wasted management time or time of other employees, loss of reputation, depletion of goodwill
                            or loss, damage or corruption of data.
                        </p>
                        <p>
                            We may modify, suspend or discontinue, temporarily all or any part of our Services with or without
                            notice, as per Our decision. We reserve the right, at any time and for any reason, to discontinue,
                            redesign, modify, enhance, change, patch the software and/or the services, including without
                            limitation, the structure, specifications, ‘look and feel,’ navigation, features and other elements of
                            the software and/or the Services or any part thereof. You agree that We will not be liable to You or
                            to any third party (for whom You may be acting) for any modification, suspension, or discontinuance of
                            all or any part of our Services or ENTITY Growth Engine.
                        </p>
                        <ListParagraph>
                            <ol>
                                <LiWithSpace>
                                    <b>
                                        You understand that You obtain content or services through ENTITY Growth Engine at Your
                                        own risk.
                                    </b>
                                </LiWithSpace>
                                <LiWithSpace>
                                    <b style={{ textDecoration: "underline" }}>Privacy.</b>
                                </LiWithSpace>
                                <LiWithSpace>
                                    We respect your privacy as further explained in our{" "}
                                    <Link to={routeNames.privacyPolicy}>Privacy Policy</Link>.
                                </LiWithSpace>
                            </ol>
                        </ListParagraph>

                        <SubSectionHeader>Intellectual Property</SubSectionHeader>
                        <p>ENTITY is the owner of ENTITY Growth Engine and operator of this website and content.</p>
                        <p>
                            This website and its entire contents, services, features, and functionality including but not limited
                            to all copyright, trademarks, patents, service marks, domain names, trade names, rights in design,
                            information, software and software code, text, display, images, sound clips, video clips, and data
                            compilations and any other intellectual property rights (“<b>IP</b>”) are owned by ENTITY, its
                            affiliates or licensors or other providers of such material and are protected by copyright, trademark,
                            patent, trade secret and other intellectual property or proprietary rights laws and other relevant
                            legal provisions.
                        </p>
                        <p>
                            <div>You may not, without Our prior written consent:</div>
                            <ol>
                                <li>
                                    reproduce, modify, copy, display, distribute, store or in any other fashion re-use material
                                    from the ENTITY Growth Engine, unless otherwise indicated on ENTITY Growth Engine, or
                                    commercially exploit any IP or materials (including text, video, audio or user interface
                                    design etc.);
                                </li>
                                <li>carry out data mining or scraping the ENTITY Growth Engine;</li>
                                <li>remove any proprietary notices from any IP;</li>
                                <li>
                                    attempt to disable, bypass, modify, defeat, or otherwise circumvent any protection system
                                    applied to or used on ENTITY Growth Engine.
                                </li>
                            </ol>
                            <div>
                                Your use of ENTITY Growth Engine does not grant You any rights other than those granted to You
                                under these Terms. Nothing contained on ENTITY Growth Engine or any communications to You shall be
                                construed as granting, by implication or otherwise, any license or right to use any IP without Our
                                prior written consent.
                            </div>
                        </p>
                        <SubSectionHeader>Intended Use</SubSectionHeader>
                        <p>
                            ENTITY Growth Engine is intended to be accessed through the provided user interface. Any attempt to
                            disable, bypass, modify, defeat, or otherwise circumvent the frontend user interface of ENTITY Growth
                            Engine is considered an unintended use of the website. Any unintended use, including but not limited
                            to skipping security mechanisms or bypassing dialog boxes in the website’s user interface, constitutes
                            a violation of this agreement and Your use of ENTITY Growth Engine’s website, APIs, or other
                            intellectual property may be suspended or terminated.
                        </p>

                        <SubSectionHeader>Termination</SubSectionHeader>
                        <p>
                            You may request to terminate Your account at any time by contacting Us at info@entity.global. Upon
                            termination of these Terms or Your account, Your right to use ENTITY Growth Engine and Our services
                            provided is terminated, and You must immediately cease using ENTITY Growth Engine.
                        </p>
                    </Card.Body>
                </Card>
                <Card className="mt-3">
                    <Card.Body>
                        <Title>1. ACCESS TO ENTITY GROWTH ENGINE</Title>
                        <p>
                            The main purpose of ENTITY Growth Engine is to offer its Users the possibility to access certain
                            Projects from a technical perspective for the purpose of participating in their public rounds (public
                            sales).
                        </p>
                        <p>
                            The Users access is conditioned to having a private key the Users manage, this being the only way a
                            transaction can take place.
                        </p>
                        <p>
                            In addition, through ENTITY Growth Engine, the Users can also access and view the balance of their
                            Assets. The fiat value of User’s Assets as displayed through ENTITY Growth Engine is only an estimate
                            depending on relevant exchange rates of fiat currencies and the valuation of crypto-assets which are
                            constantly changing.
                        </p>
                        <p>
                            We do not warrant nor make any representations as to the accuracy of the fiat value displayed through
                            ENTITY Growth Engine. For the avoidance of any doubt, fiat currency means any currency issued by a
                            central bank of states, such as the US Dollar, Euro, Ron, etc.
                        </p>
                    </Card.Body>
                </Card>
                <Card className="mt-3">
                    <Card.Body>
                        <Title>2. REGISTRATION</Title>
                        <p>
                            In order to use ENTITY Growth Engine, You must be an individual 18 years of age or older and register
                            with a personal User account. When You register with ENTITY Growth Engine, We will ask You to provide
                            us with the details mentioned in our Privacy Notice. (“<b>Registration Information</b>”).
                        </p>
                        <p>
                            You hereby acknowledge and accept that We or any third party may need to undertake certain KYC
                            activities and/or AML verifications, including collecting certain personal data from You.
                        </p>
                        <p>
                            Upon request, You shall provide all information and documents that We or the third party or service
                            provider above-mentioned, in its sole discretion, deems necessary or appropriate to conduct KYC and/or
                            AML verifications or as they are/ become required by the applicable legal provisions.
                        </p>
                        <p>
                            We reserve the right to request additional information to verify your identity, during the
                            registration process, at any time throughout your use of ENTITY Growth Engine, or when You submit
                            requests related to Your User account being able to unilaterally suspend or terminate your User
                            account or Your access to ENTITY Growth Engine if the information provided contradicts the information
                            submitted previously, if We consider that the information is false, deceptive or offensive, or if We
                            believe that you breached these Terms.
                        </p>
                        <p>
                            Please note that ENTITY Growth Engine’s features are activated and available only to Users who have
                            successfully completed the sign-on process and, if applicable, certain KYC and AML processes.
                        </p>
                        <p>
                            The owner of this Website is based in Liechtenstein. Use of ENTITY Growth Engine may not be available
                            to You, in whole or in part, in certain regions, countries, or jurisdictions. We make no claims that
                            the Websites or any of its content is accessible or appropriate outside of Liechtenstein. Access to
                            the Website may not be legal by certain persons or in certain countries. If you access the Website
                            from outside Liechtenstein, You do so on Your own initiative and are therefore responsible for due
                            diligence with local laws.
                        </p>
                        <p>
                            Users in certain regions may have different Terms applicable to them, which are to be enacted and
                            updated by Us from time to time.
                        </p>
                        <p>By using ENTITY Growth Engine, You acknowledge, represent, and warrant that:</p>
                        <ol>
                            <LiWithSpace>
                                You are over 18 years old, or the age of majority, required by Your residence state, whichever is
                                older, and You have the capacity to enter into this Agreement;
                            </LiWithSpace>
                            <LiWithSpace>
                                You are eligible to enter a legally online binding agreement with Us in accordance with the laws
                                governing the jurisdiction applicable to You;
                            </LiWithSpace>
                            <LiWithSpace>
                                You are solely and completely responsible for Your own compliance with applicable legislation in
                                Your jurisdiction;
                            </LiWithSpace>
                            <LiWithSpace>
                                You have all necessary consent and the authority to enter into this Agreement and / or use the
                                Services;
                            </LiWithSpace>
                            <LiWithSpace>
                                if You are a body corporate, unincorporated association, trust, or partnership, You are validly
                                existing in accordance with applicable legislation and have obtained all necessary consent and
                                authorizations under your constitutional or organizational documents;
                            </LiWithSpace>
                            <LiWithSpace>
                                if You agree to these Terms on behalf of a legal entity, then You have the legal authority to be
                                held liable the legal entity to these Terms;
                            </LiWithSpace>
                            <LiWithSpace>
                                all information that You supply is true, accurate, complete, and not misleading;
                            </LiWithSpace>
                            <LiWithSpace>
                                Our assessment of Your use of the Services is performed on the basis of the information provided
                                by You, and We may rely upon information and documents provided by You, and We are not responsible
                                for any damages or losses which may arise from any inaccuracies;
                            </LiWithSpace>
                            <LiWithSpace>
                                You can distinguish between services and understand the underlying mechanisms behind financial
                                applications;
                            </LiWithSpace>
                            <LiWithSpace>
                                neither the entry into this Agreement, nor use of the Services, or the giving of any other
                                instruction will violate any law, rule, or regulation applicable to You;
                            </LiWithSpace>
                            <LiWithSpace>
                                all money that you use and invest through the crypto-assets and Services does not originate in any
                                way from drug trafficking, abduction, terrorist activity, or any other criminal activity that is
                                unlawful or could be considered unlawful by any relevant authority;
                            </LiWithSpace>
                            <LiWithSpace>
                                You have not and will not upload or transmit any malicious code to the ENTITY Growth Engine or
                                otherwise use any electronic device, software, algorithm, and/or dealing method or strategy that
                                aims to manipulate any aspect of the ENTITY Growth Engine or the Services;
                            </LiWithSpace>
                            <LiWithSpace>
                                You will use the Services offered by Us pursuant to this Agreement honestly, fairly, and in good
                                faith;
                            </LiWithSpace>
                            <LiWithSpace>
                                Our Privacy Notice and Cookie Policy shall be applicable to Your use of the website.
                            </LiWithSpace>
                        </ol>
                        <p>
                            If you breach any warranty or representation made under this Agreement, We preserve the right to
                            suspend or terminate the provisions of Services and prohibit Your access to the website and ENTITY
                            Growth Engine.
                        </p>
                        <p>
                            We reserve the right to deny, suspend or terminate your User account or your access to ENTITY Growth
                            Engine or our Services to any user, but not limited to the following reasons:
                            <ul>
                                <LiWithSpace>
                                    in Our opinion, the User is not able to fully comply with these Terms of Use;
                                </LiWithSpace>
                                <LiWithSpace>We suspect that the User has committed fraud or a criminal offense;</LiWithSpace>
                                <LiWithSpace>the User has acted in a way that might affect Our reputation;</LiWithSpace>
                                <LiWithSpace>
                                    the User has provided false or misleading, or incomplete registration information to Us or to
                                    the third party carrying out KYC process;
                                </LiWithSpace>
                                <LiWithSpace>
                                    the User does not provide to Us or to a third provider indicated by Us all the information
                                    needed for Know Your Customer (“KYC”) and/or Anti-Money Laundering (“AML”) verification if the
                                    above-mentioned third party or We deem them necessary or they are/ become required by the
                                    applicable legal provisions.
                                </LiWithSpace>
                            </ul>
                        </p>
                    </Card.Body>
                </Card>
                <Card className="mt-3">
                    <Card.Body>
                        <Title>3. LIMITATIONS</Title>
                        <p>
                            Please note that the Services allow You to interact directly with the MultiversX blockchain and maybe
                            with other blockchains as well (Ethereum, Binance, etc.), while You remain in full control of, and
                            always responsible for, Your own Private Keys and Assets.
                        </p>
                        <p>
                            As an un-hosted wallet where You are responsible for your own Private Keys and Assets, please be
                            informed that these Services are not regulated by any financial authority, and the Assets that you may
                            store, exchange, and transfer using Services are not covered or underwritten by any issuer, insurer,
                            government, or central authority.
                        </p>
                        <p>
                            When You access certain features, you will be able to perform a variety of actions with different
                            Assets. You will be requested to verify your e-mail address through an attestation process.
                        </p>
                        <p>
                            Please note that You will not give Us control or access to any of your Assets, and Your Assets are not
                            held in ENTITY Growth Engine but are on the MultiversX blockchain or, if the case, on another
                            blockchain(s) and ‘viewed’ through ENTITY Growth Engine, and We do not control the blockchain(s) or
                            your Assets.
                        </p>
                        <p>
                            <b>
                                We do not collect or hold your private keys, and We cannot access accounts; recover keys,
                                passwords, or other information; We cannot reset passwords or reverse transactions.
                            </b>
                        </p>
                        <p>
                            <b>
                                You will maintain the security and confidentiality of Your private keys, recovery keys, passwords.
                            </b>
                        </p>
                        <p>
                            <b>
                                You are solely responsible for Your use of the Services, including, without limitation, for
                                storing, backing up, and maintaining the confidentiality of your keys, passwords, and information,
                                and for the security of any transactions you perform using the Services.
                            </b>
                        </p>
                        <p>
                            <b>
                                Thus, you expressly hereby relieve and release Us from any and all liability and/or loss arising
                                from Your use of the services.
                            </b>
                        </p>
                        <p>
                            Users interacting with crypto-assets should be aware that such assets involve risks, including the
                            risk of loss of some or all assets. Losses are not insured, and You assume full responsibility for all
                            losses. You are advised to exercise caution, conduct research, and not to transact more than you can
                            afford to lose.
                        </p>
                        <p>
                            ENTITY Growth Engine does not facilitate the storage, the exchange from crypto to fiat, or sending of
                            fiat currencies.
                        </p>
                    </Card.Body>
                </Card>
                <Card className="mt-3">
                    <Card.Body>
                        <Title>4. ACCEPTABLE AND PROHIBITED USE OF ENTITY GROWTH ENGINE</Title>
                        <p>
                            You may use the Websites only for lawful purposes and in accordance with these Terms of Use. You agree
                            not to use the Website:
                        </p>
                        <p>
                            When using ENTITY Growth Engine, You shall not:
                            <ul>
                                <LiWithSpace>
                                    use any robot, spider, crawler, scraper, or other automated means or interface not provided by
                                    Us to access ENTITY Growth Engine to extract data;
                                </LiWithSpace>
                                <LiWithSpace>
                                    introduce any malware, virus, Trojan horse, worm, logic bomb, drop-dead device, backdoor,
                                    shutdown mechanism, or other harmful material into the website;
                                </LiWithSpace>
                                <LiWithSpace>
                                    interfere with, burden, or disrupt the functionality of ENTITY Growth Engine;
                                </LiWithSpace>
                                <LiWithSpace>
                                    breach the security of ENTITY Growth Engine or publicly identify any security vulnerabilities
                                    in it;
                                </LiWithSpace>
                                <LiWithSpace>
                                    circumvent or manipulate the operation or functionality of ENTITY Growth Engine, or attempt to
                                    enable features or functionalities that are otherwise disabled, inaccessible, or undocumented
                                    in ENTITY Growth Engine;
                                </LiWithSpace>
                                <LiWithSpace>send automated or machine generated queries;</LiWithSpace>
                                <LiWithSpace>
                                    use applications to collect and compile content from ENTITY Growth Engine or send data to
                                    ENTITY Growth Engine, including for the purposes of competing with ENTITY Growth Engine, or in
                                    such ways, that may impair or disrupt ENTITY Growth Engine’s functionality;
                                </LiWithSpace>
                                <LiWithSpace>
                                    display or embed content from ENTITY Growth Engine, including by any software, feature,
                                    gadget, or communication protocol, which alters the content or its design;
                                </LiWithSpace>
                                <LiWithSpace>
                                    impersonate any person or entity, or make any false statement pertaining to Your identity or
                                    affiliation with any person or entity;
                                </LiWithSpace>
                                <LiWithSpace>
                                    collect, harvest, obtain or process personal information regarding ENTITY Growth Engine’s
                                    Users, without their prior explicit consent;
                                </LiWithSpace>
                                <LiWithSpace>
                                    abuse, harass, threaten or intimidate other Users of ENTITY Growth Engine;
                                </LiWithSpace>
                                <LiWithSpace>
                                    link to ENTITY Growth Engine from web pages or applications that contain pornographic content
                                    or content that encourages racism or wrongful discrimination;
                                </LiWithSpace>
                                <LiWithSpace>
                                    engage in any activity that constitutes a criminal offense or gives rise to civil liability;
                                </LiWithSpace>
                                <LiWithSpace>transfer Your account on ENTITY Growth Engine to another person;</LiWithSpace>
                                <LiWithSpace>infringe the Intellectual Property or any other rights;</LiWithSpace>
                                <LiWithSpace>breach these Terms or any part of the Agreement;</LiWithSpace>
                                <LiWithSpace>breach any applicable legal provisions.</LiWithSpace>
                            </ul>
                        </p>
                        <p>
                            You are solely responsible for the content you make available through ENTITY Growth Engine and for the
                            consequences associated with doing so.
                        </p>
                        <p>
                            We may, in a unilateral way, temporarily or permanently suspend the use of or revoke Your account if
                            We believe You have breached these Terms or exhibit behavior that raises suspicion of criminal
                            activity.
                        </p>
                    </Card.Body>
                </Card>
                <Card className="mt-3">
                    <Card.Body>
                        <Title>5. RISK OF FINANCIAL LOSS</Title>
                        <p>
                            The projects supported by ENTITY Growth Engine and their products may be part of a new asset class and
                            present a risk of financial loss. You should also be satisfied that the projects are suitable for You
                            in light of your circumstances and financial position.
                        </p>
                        <p>No recommendation is made herein as to the advisability of investing in such projects.</p>
                        <p>
                            Crypto assets are digital assets with high volatility. Crypto-assets are not appropriate for all
                            investors. You should not deal in Crypto-assets unless You have the necessary knowledge and expertise,
                            understand these digital assets’ characteristics, and your exposure to risk.
                        </p>
                        <p>
                            Crypto-assets should be seen as high-risk digital assets, and You should never invest funds that You
                            cannot afford to lose.
                        </p>
                    </Card.Body>
                </Card>
                <Card className="mt-3">
                    <Card.Body>
                        <Title>6. FEE-BASED AND FREE OF CHARGE SERVICES</Title>
                        <p>
                            Access to ENTITY Growth Engine may be offered to You without any costs for a limited period of time (“
                            <b>Free Access</b>”). The Free Access period and the duration of it is and will be determined
                            exclusively by Us as additional features and services might be introduced in the future, which will
                            not be included as part of your Free Access. These additional features and services might be subject
                            to payment of the applicable fees (“<b>Fees</b>”) in accordance with the packages, schemes, and
                            amounts presented to You, either upon registration or at a later time.
                        </p>
                        <p>
                            Free Access to ENTITY Growth Engine is guaranteed for a determined period of time only at Our sole
                            discretion, after which access to ENTITY Growth Engine might be subject to payment of Fees. If You
                            benefited from Free Access prior to that change, You would still be able to access ENTITY Growth
                            Engine for free (excluding any additional pay for services presented by Us), provided You abide by
                            these Terms and use your Free Access only for one account on one device at a time (i.e., each User is
                            entitled to receive a Free Access benefit only once).
                        </p>
                    </Card.Body>
                </Card>
                <Card className="mt-3">
                    <Card.Body>
                        <Title>7. TAXATION</Title>
                        <p>
                            All state and/or local income and other taxes, if any, are the User’s sole responsibility. Our
                            Services may trigger the User’s obligation to pay taxes, depending on the project and their products,
                            on each state, and local tax laws applicable to the User. All Users are solely responsible for
                            reporting such their own tax returns or similar and observing/paying any associated taxes, as the case
                            may be.
                        </p>
                        <p>
                            The payment of taxes is entirely Your responsibility, and please note that We will not deduct or
                            withhold any taxes for You or provide You with any information with respect to Your tax position or
                            liability. If You have any questions with respect to tax matters, You should consult a professional
                            advisor.
                        </p>
                    </Card.Body>
                </Card>
                <Card className="mt-3">
                    <Card.Body>
                        <Title>8. THIRD-PARTY PLATFORMS/ WEBSITES</Title>
                        <p>
                            ENTITY Growth Engine may contain hyperlinks or references to third-party websites or platforms. Any
                            such hyperlinks or references are provided for Your information and convenience only. We have no
                            control over third-party websites or platforms, and We have no legal responsibility for any content,
                            material, or information contained in them.
                        </p>
                        <p>
                            The display of any hyperlink and reference to any third-party website or platform does not mean that
                            We endorse that third party’s website, platform, products, or services. Your use of a third-party site
                            may be governed by the terms and conditions of that third-party site.
                        </p>
                        <p>
                            For example, You may “share” Your activity on ENTITY Growth Engine and invite Your friends to use
                            ENTITY Growth Engine through social network websites. Other third-party platforms may provide You the
                            ability to be part of investment rounds with fiat currencies may provide You different services
                            applicable to Your Assets, such as purchasing crypto with fiat currency or staking.
                        </p>
                        <p>
                            The responsibilities, obligations, and liabilities of third-party platforms/websites are as indicated
                            in their respective terms and conditions.
                        </p>
                    </Card.Body>
                </Card>
                <Card className="mt-3">
                    <Card.Body>
                        <Title>9. USER ACKNOWLEDGMENTS</Title>
                        <p>
                            The User hereby acknowledges and agrees that:
                            <ul>
                                <LiWithSpace>
                                    ENTITY Growth Engine does not issue, subscribe, place or accept any funds;
                                </LiWithSpace>
                                <LiWithSpace>
                                    the role of ENTITY Growth Engine as a software platform is solely a technical and marketing
                                    vendor for the Projects, providing the technology for facilitating the launch and sale of the
                                    Assets;
                                </LiWithSpace>
                                <LiWithSpace>
                                    the issuer and seller of the crypto-assets is the Project owner, meaning a company that is
                                    different from Us;
                                </LiWithSpace>
                                <LiWithSpace>
                                    ENTITY Growth Engine will be relying on, and is so entitled to rely on, upon the information,
                                    representations, warranties, and covenants made by the Project owners via their own terms and
                                    conditions and related policies;
                                </LiWithSpace>
                                <LiWithSpace>
                                    ENTITY Growth Engine has no liability in respect to the accuracy of the information related to
                                    the Projects;
                                </LiWithSpace>
                                <LiWithSpace>
                                    the Projects initiators are providing to Us the information related to the Projects to be
                                    published on ENTITY Growth Engine;
                                </LiWithSpace>
                                <LiWithSpace>
                                    ENTITY Growth Engine does not offer or sell any Assets for or on behalf of any Project and
                                    also does not accept any proceeds from the public sale conducted by the Project;
                                </LiWithSpace>
                                <LiWithSpace>
                                    the Services provided by ENTITY Growth Engine do not constitute financial or legal advice
                                    which requires licensing or other approvals, and the User is not permitted to rely upon the
                                    Services at such;
                                </LiWithSpace>
                                <LiWithSpace>
                                    ENTITY Growth Engine does not independently verify information regarding the Projects and does
                                    not endorse the Projects for investment. Although We evaluate potential Projects to determine
                                    which Projects will be available as potential investments for Users, please note that this
                                    evaluation does not constitute an endorsement or a recommendation from Our part that You
                                    should invest in any particular Project and does not establish an advisory relationship
                                    between Us and You. For the avoidance of any doubt, We are not Your advisor in connection with
                                    the Projects presented on the ENTITY Growth Engine.
                                </LiWithSpace>
                                <LiWithSpace>
                                    ENTITY Growth Engine shall not be held liable with respect to any Project’s activity, as well
                                    as its past, present, or future performance or non-performance;
                                </LiWithSpace>
                                <LiWithSpace>
                                    ENTITY Growth Engine is not responsible for the information from the White Papers. For the
                                    avoidance of any doubt, the information from the White Papers is provided by the initiators of
                                    the Projects to be published on the ENTITY Growth Engine;
                                </LiWithSpace>
                                <LiWithSpace>
                                    You should consult Your own legal, tax, investment, and financial advisors regarding the
                                    suitability, desirability, and appropriateness of participating in the Projects. ENTITY Growth
                                    Engine does not advise You on the merits of Your investment and does not provide any other
                                    advisory services to You.
                                </LiWithSpace>
                                <LiWithSpace>
                                    any smart contracts you interact with are entirely Your own responsibility and liability, and
                                    We are not a party to the smart contracts;
                                </LiWithSpace>
                                <LiWithSpace>
                                    there may be a delay in Your access or use of your crypto-assets which may determine a
                                    decrease of the crypto-assets value or your inability to complete a smart contract.
                                </LiWithSpace>
                                <LiWithSpace>
                                    ENTITY Growth Engine will not conduct the KYC/AML process of the Projects. A third-party
                                    services provider is used for the KYC process. The KYC process is in relation to the Projects
                                    and represents an obligation in connection solely with each Project. ENTITY Growth Engine is
                                    only facilitating access to the Project’s own KYC/AML process or third-party provider
                                    performing the KYC process, as the case may be.
                                </LiWithSpace>
                                <LiWithSpace>
                                    ENTITY Growth Engine is permitted to email You with relevant information, including but not
                                    limited to updates on Your applications to the Entity Launchpad or Project Monitor.
                                </LiWithSpace>
                            </ul>
                        </p>
                    </Card.Body>
                </Card>
                <Card className="mt-3">
                    <Card.Body>
                        <Title>10. DISCLAIMER OF WARRANTY</Title>
                        <UppercaseParagraph>
                            You understand that we cannot and do not guarantee or warrant that files available for downloading
                            from the internet or the Websites will be free of viruses or other destructive code. You are
                            responsible for implementing sufficient procedures and checkpoints to satisfy your particular
                            requirements for anti-virus protection and accuracy of data input and output, and for maintaining a
                            means external to our site for any reconstruction of any lost data.{" "}
                            <span style={{ textTransform: "uppercase" }}>
                                WE WILL NOT BE LIABLE FOR ANY LOSS OR DAMAGE CAUSED BY A DISTRIBUTED DENIAL-OF-SERVICE ATTACK,
                                VIRUSES OR OTHER TECHNOLOGICALLY HARMFUL MATERIAL THAT MAY INFECT YOUR COMPUTER EQUIPMENT,
                                COMPUTER PROGRAMS, DATA OR OTHER PROPRIETARY MATERIAL DUE TO YOUR USE OF THE WEBSITES OR ANY
                                SERVICES OR ITEMS OBTAINED THROUGH THE YOUR DOWNLOADING OF ANY MATERIAL POSTED ON IT, OR ON ANY
                                WEBSITES LINKED WEBSITES OR TO TO IT.
                            </span>
                        </UppercaseParagraph>
                        <UppercaseParagraph>
                            YOUR USE OF THE WEBSITE, ITS CONTENT AND ANY SERVICES OR ITEMS OBTAINED THROUGH THE WEBSITES IS AT
                            YOUR OWN RISK. THE WEBSITE, ITS CONTENT AND ANY SERVICES OR ITEMS OBTAINED THROUGH THE WEBSITES ARE
                            PROVIDED ON AN &quot;AS IS&quot; AND &quot;AS AVAILABLE&quot; BASIS, WITHOUT ANY WARRANTIES OF ANY
                            KIND, EITHER EXPRESS OR IMPLIED. NEITHER ENTITY NOR ANY PERSON ASSOCIATED WITH ENTITY MAKES ANY
                            WARRANTY OR REPRESENTATION WITH RESPECT TO THE COMPLETENESS, SECURITY, RELIABILITY, QUALITY, ACCURACY
                            OR AVAILABILITY OF THE WEBSITES. WITHOUT LIMITING THE FOREGOING, NEITHER ENTITY NOR ANYONE ASSOCIATED
                            WITH ENTITY REPRESENTS OR WARRANTS THAT THE WEBSITE, ITS CONTENT OR ANY SERVICES OR ITEMS OBTAINED
                            THROUGH THE WEBSITES WILL BE ACCURATE, RELIABLE, ERROR-FREE OR UNINTERRUPTED, THAT DEFECTS WILL BE
                            CORRECTED, THAT OUR SITE OR THE SERVER THAT MAKES IT AVAILABLE ARE FREE OF VIRUSES OR OTHER HARMFUL
                            COMPONENTS OR THAT THE WEBSITES OR ANY SERVICES OR ITEMS OBTAINED THROUGH THE WEBSITES WILL OTHERWISE
                            MEET YOUR NEEDS OR EXPECTATIONS.
                        </UppercaseParagraph>
                        <UppercaseParagraph>
                            ENTITY HEREBY DISCLAIMS ALL WARRANTIES OF ANY KIND, WHETHER EXPRESS OR IMPLIED, STATUTORY OR
                            OTHERWISE, INCLUDING BUT NOT LIMITED TO ANY WARRANTIES OF MERCHANTABILITY, NON-INFRINGEMENT AND
                            FITNESS FOR PARTICULAR PURPOSE.
                        </UppercaseParagraph>
                        <UppercaseParagraph>
                            SOME JURISDICTIONS DO NOT ALLOW EXCLUSION OF WARRIANTIES OR LIMITATIONS ON THE DURATION OF IMPLIED
                            WARRANTIES, SO THE ABOVE DISCLAIMER MAY NOT APPLY TO YOU IN THEIR ENTIRETIES, BUT WILL APPLY TO THE
                            MAXIMUM EXTENT PERMITTED BY APPLICABLE LAW.
                        </UppercaseParagraph>
                    </Card.Body>
                </Card>
                <Card className="mt-3">
                    <Card.Body>
                        <Title>11. LIMITATION OF LIABILITY</Title>
                        <UppercaseParagraph>
                            IN NO EVENT WILL ENTITY, ITS RESPECTIVE PRESENT, PAST AND FUTURE AFFILIATES OR THEIR LICENSORS,
                            SERVICE PROVIDERS, EMPLOYEES, AGENTS, OFFICERS OR DIRECTORS BE LIABLE FOR DAMAGES OF ANY KIND, UNDER
                            ANY LEGAL THEORY, ARISING OUT OF OR IN CONNECTION WITH YOUR USE, OR INABILITY TO USE, THE WEBSITE, ANY
                            WEBSITES LINKED TO IT, ANY CONTENT ON THE WEBSITES OR SUCH OTHER WEBSITES OR ANY SERVICES OR ITEMS
                            OBTAINED THROUGH THE WEBSITES OR SUCH OTHER WEBSITES, INCLUDING ANY DIRECT, INDIRECT, SPECIAL,
                            INCIDENTAL, CONSEQUENTIAL OR PUNITIVE DAMAGES, INCLUDING BUT NOT LIMITED TO, PERSONAL INJURY, PAIN AND
                            SUFFERING, EMOTIONAL DISTRESS, LOSS OF REVENUE, LOSS OF PROFITS, LOSS OF BUSINESS OR ANTICIPATED
                            SAVINGS, LOSS OF USE, LOSS OF GOODWILL, LOSS OF DATA, AND WHETHER CAUSED BY TORT (INCLUDING
                            NEGLIGENCE), BREACH OF CONTRACT OR OTHERWISE, EVEN IF FORESEEABLE. THE FOREGOING DOES NOT AFFECT ANY
                            LIABILITY WHICH CANNOT BE EXCLUDED OR LIMITED UNDER APPLICABLE LAW WHICH MAY INCLUDE FRAUD.
                        </UppercaseParagraph>
                    </Card.Body>
                </Card>
                <Card className="mt-3">
                    <Card.Body>
                        <Title>12. INDEMNIFICATION</Title>
                        <p>
                            To the fullest extent permitted by applicable law, you agree to indemnify, defend and hold harmless
                            ENTITY and our respective past, present and future employees, officers, directors, contractors,
                            consultants, service providers, equity holders, suppliers, vendors, service providers, parent
                            companies, subsidiaries, affiliates, agents, representatives, predecessors, successors and assigns
                            from and against all claims, demands, actions, damages, losses, liabilities, costs and expenses
                            (including legal fees) in connection with this Terms and use of our Services, your responsibilities or
                            obligations under these Terms, any breach by you of these Terms, or any infringement or violation by
                            you of any rights of, or laws or regulations applicable to, any other person or entity.
                        </p>
                    </Card.Body>
                </Card>
                <Card className="mt-3">
                    <Card.Body>
                        <Title>13. CHANGES AND AVAILABILITY</Title>
                        <p>
                            <b>Changes on Website</b>
                        </p>
                        <p>
                            We may update the content and services provided on this website from time to time, this is not
                            necessarily complete or up-to-date. Any of the material on the website may be out of date at any given
                            time, and we are under no obligation to update such material.
                        </p>

                        <p>
                            <b>Availability</b>
                        </p>
                        <p>
                            The availability, functioning, quality, and functionality of the Website and services provided within
                            depend on various factors, including software, hardware, and communication networks, which are
                            provided by third parties, at their responsibility. These factors are not fault-free.
                        </p>
                    </Card.Body>
                </Card>
                <Card className="mt-3">
                    <Card.Body>
                        <Title>14. TERMINATION</Title>
                        <p>
                            <b>Cancellation of your account</b>
                        </p>
                        <p>You may, at any time, request to terminate your account by contacting us at info@entity.global.</p>
                        <p>
                            Upon termination of Your account, You will lose your right to use ENTITY Growth Engine, and you must
                            immediately cease using ENTITY Growth Engine.
                        </p>

                        <p>
                            <b>Termination of ENTITY Growth Engine operation</b>
                        </p>
                        <p>
                            We may at any time discontinue, suspend or terminate the operation of ENTITY Growth Engine, or any
                            part thereof, temporarily or permanently, for all Users, or for certain Users, without any liability
                            to You, by written notice.
                        </p>
                    </Card.Body>
                </Card>
                <Card className="mt-3">
                    <Card.Body>
                        <Title>15. GOVERNING LAW, JURISDICTION</Title>
                        <p>
                            Regardless of Your place of residence or where You access or use ENTITY Growth Engine from, these
                            Terms and your use of ENTITY Growth Engine will be governed by and construed solely in accordance with
                            the laws of Liechtenstein.
                        </p>
                        <p>
                            The competent courts in Liechtenstein will have exclusive and sole jurisdiction over any dispute,
                            claim, or controversy relating to ENTITY Growth Engine or with respect to any matter relating to these
                            Terms. You hereby expressly consent to personal jurisdiction in Liechtenstein and expressly waive any
                            right to object to such personal jurisdiction or the non-convenience of such forum.
                        </p>
                        <p>
                            Notwithstanding the foregoing, We may submit a claim against You pursuant to the indemnity clause
                            above in any court adjudicating a third party claim against us.
                        </p>
                    </Card.Body>
                </Card>
                <Card className="mt-3">
                    <Card.Body>
                        <Title>16. ASSIGNMENT</Title>
                        <p>
                            You may not assign or transfer any right to use the Services or any of your rights and obligations
                            under these Terms without our prior written consent, including any right or obligation related to the
                            enforcement of laws or the change of control. Any attempted or actual assignment by you, without our
                            prior written consent, shall be null and void.
                        </p>
                        <p>
                            We may assign or transfer any or all of our rights or obligations under these Terms, in whole or in
                            part, without notice or obtaining your consent/approval.
                        </p>
                    </Card.Body>
                </Card>
                <Card className="mt-3">
                    <Card.Body>
                        <Title>17. CHANGES IN OWNERSHIP</Title>
                        <p>
                            In case of mergers and acquisitions, change of control, or other similar operation, we may, without
                            notice or obtaining your consent, assign and delegate these Terms, including all of our rights,
                            performances, duties, liabilities, and obligations contained herein, to a third party.
                        </p>
                    </Card.Body>
                </Card>
                <Card className="mt-3">
                    <Card.Body>
                        <Title>18. FORCE MAJEURE</Title>
                        <p>
                            We will not be liable for any delay or failure to perform as required by these Terms because of any
                            fortuitous case or force majeure case, as defined by the Liechtenstein law.
                        </p>
                    </Card.Body>
                </Card>
                <Card className="mt-3">
                    <Card.Body>
                        <Title>19. INTERPRETATION</Title>
                        <p>
                            The section headings in these Terms are included for convenience only and shall take no part in the
                            interpretation or construing of these Terms. Whenever used in these Terms, the term “Including,”
                            whether capitalized or not, means without limitation to the preceding phrase. All examples and, e.g.,
                            notations are illustrative, not exhaustive.
                        </p>
                        <p>
                            These Terms supersede all prior and contemporaneous negotiations and oral representations, agreements,
                            and statements.
                        </p>
                    </Card.Body>
                </Card>
                <Card className="mt-3">
                    <Card.Body>
                        <Title>20. WAIVER AND SEVERABILITY</Title>
                        <p>
                            No waiver, concession, extension, representation, alteration, addition, or derogation from these Terms
                            by us or pursuant to these Terms, will be effective unless consented to explicitly and executed in
                            writing by our authorized representative. Any failure on our part to demand performance of any
                            provision to assert a right or provision in these Terms shall not constitute a waiver of any of Our
                            rights under these Terms.
                        </p>
                        <p>
                            If any provision of these Terms shall be held by a court or other tribunal of competent jurisdiction
                            to be invalid, illegal or unenforceable for any reason, such provision shall be eliminated or limited
                            to the minimum extent such that the remaining provisions of the Terms will continue in full force and
                            effect.
                        </p>
                    </Card.Body>
                </Card>
                <Card className="mt-3">
                    <Card.Body>
                        <Title>21. RELATIONSHIP</Title>
                        <p>
                            These Terms do not create any agency, partnership, employment, or fiduciary relationship between you
                            and us.
                        </p>
                    </Card.Body>
                </Card>
                <Card className="mt-3">
                    <Card.Body>
                        <Title>22. CONTACT US</Title>
                        <p>
                            This website is operated by ENT Technologies AG. At any time, you may contact us with any feedback,
                            question, request, comment, or complaint that you may have with respect to this website or these Terms
                            to: info@entity.global.
                        </p>
                    </Card.Body>
                </Card>
            </div>
        </Container>
    );
};

export default TermsAndConditions;
