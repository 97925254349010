import withPageTitle from "../components/PageTitle";
import { lazyLoad } from "./lazyLoad";
import PrivacyPolicy from "../pages/PrivacyPolicy/PrivacyPolicy";
import Disclaimer from "../pages/Disclaimer/Disclaimer";
import { TermsAndConditions } from "../pages/TermsAndConditions";
import PageNotFound from "../pages/PageNotFound";
import Imprint from "../pages/Imprint/Imprint";
import { Landing } from "../pages/Landing";
import Contact from "../pages/Contact/Contact";
import Newsletter from "../pages/Newsletter";
import Auth from "../pages/Auth/Auth";
import AuthLogin from "../pages/Auth/Web2/AuthLogin";
import AuthRegister from "../pages/Auth/Web2/AuthRegister";
import AuthEmailConfirmation from "../pages/Auth/Web2/AuthEmailConfirmation";

export const routeNames = {
    home: "/",
    whyEntity: "/#whyEntity",
    learn: "/#learn",
    unlock: "/unlock",
    settings: "/settings",
    adminLaunchpadPreview: "/admin/preview/launchpad",
    adminLaunchpadProject: "/admin/launchpad-project",
    adminProjectMonitor: "/admin/project-monitor",
    adminLDM: "/admin/ldm",
    projectMonitor: "/project-monitor",
    icoNew: "/list-your-project",
    projectReview: "/project/review/",
    launchpad: "/launchpad/",
    launchpadAboutTab: "/launchpad/#about",
    launchpadMetricsTab: "/launchpad/#metrics",
    launchpadTeamTab: "/launchpad/#team",
    launchpadInvestmentTab: "/launchpad/#investment",
    launchpadFaqTab: "/launchpad/#faq",
    investorsList: "/admin/investors/list",
    launchpadBlacklist: "/admin/launchpad/blacklist",
    investorsView: "/admin/investors/",
    investors: "/investors",
    project: "/project",
    portfolio: "/portfolio",
    contact: "/contact",
    confirmEmail: "confirm-email",
    kyc: "/kyc",
    privacyPolicy: "/privacy/policy",
    disclaimer: "/disclaimer",
    termsAndConditions: "/terms-and-conditions",
    notFound: "/not-found",
    imprint: "/imprint",
    xDay: "/xday",
    xDayConfirm: "/xday/confirm-email",
    xDayPresentation: "/xday/presentation/",
    adminDashboard: "/admin/dashboard/",
    adminMetaStakingFarms: "/admin/metastaking",
    adminMetaStakingFarmCreation: "/admin/metastaking/create",
    adminMetaStakingFarmEdit: "/admin/metastaking/edit/",
    newsletter: "/newsletter",
    adminAppSettings: "/admin/app-settings",
    adminUsers: "/admin/users",
    ldm: "/ldm",
    auth: "/auth",
    authLogin: "/auth/login",
    authRegister: "/auth/register",
    authEmailConfirmation: "/auth/email-confirmation/",
};

export interface AuthRoute {
    path: string;
    component: (() => JSX.Element) | string[];
    authenticatedRoute: boolean;
    loginText?: string | undefined;
}

// note: put the more specific routes at the beginning to correctly match them based on the path when the generic routes are built!
// i.e. wrong order [ "/abc", "/abc/:def" ]
// i.e. good order [ "/abc/:def", "/abc" ]
const routes: Array<AuthRoute & { title: string; partialAuthenticatedRoute?: boolean }> = [
    {
        path: routeNames.contact,
        title: "Contact",
        component: Contact,
        authenticatedRoute: false,
    },
    {
        path: routeNames.settings,
        title: "Settings",
        component: ["/Settings/Settings"],
        authenticatedRoute: true,
    },
    {
        path: routeNames.icoNew,
        title: "List your project",
        component: ["/Ico/IcoCreation"],
        loginText: "To securely submit and edit your project, you must login with a wallet.",
        authenticatedRoute: true,
    },
    {
        path: "/project/:id/:slug",
        title: "Project",
        component: ["/Ico/ValidateIco"],
        authenticatedRoute: false,
    },
    {
        path: routeNames.projectMonitor,
        title: "Upcoming projects",
        component: ["/Ico/IcoList"],
        authenticatedRoute: false,
    },
    {
        path: routeNames.projectReview + ":id",
        title: "Review projects",
        component: ["/Ico/IcoProjectReview", "IcoProjectReview"],
        authenticatedRoute: true,
    },
    {
        path: routeNames.projectReview + ":id/:tab",
        title: "Review projects",
        component: ["/Ico/IcoProjectReview", "IcoProjectReview"],
        authenticatedRoute: true,
    },
    {
        path: routeNames.adminDashboard,
        title: "Admin Dashboard",
        component: ["/Admin/AdminDashboard"],
        authenticatedRoute: true,
    },
    {
        path: routeNames.adminProjectMonitor,
        title: "Review projects list",
        component: ["/Ico/Admin/IcoAdminList"],
        authenticatedRoute: true,
    },
    {
        path: routeNames.adminLaunchpadProject,
        title: "Launchpad Project",
        component: ["/Launchpad/LaunchpadProject"],
        authenticatedRoute: true,
    },
    {
        path: routeNames.adminLaunchpadPreview,
        title: "Launchpad Preview",
        component: ["/Launchpad/LaunchpadPreview"],
        authenticatedRoute: true,
    },
    {
        path: routeNames.ldm,
        title: "LDM",
        component: ["/LDM"],
        authenticatedRoute: false,
    },
    {
        path: routeNames.ldm + "/:tab",
        title: "LDM",
        component: ["/LDM"],
        authenticatedRoute: false,
    },
    {
        path: routeNames.adminLDM + "/:tab",
        title: "LDM",
        component: ["/Admin/LDM/AdminLDM"],
        authenticatedRoute: true,
    },
    {
        path: routeNames.adminAppSettings,
        title: "Admin App Settings",
        component: ["/Admin/AppSettings/AdminAppSettings", "AdminAppSettings"],
        authenticatedRoute: true,
    },
    {
        path: routeNames.adminUsers,
        title: "Admin User Management",
        component: ["/Admin/UsersManagement/UserManagement", "UserManagement"],
        authenticatedRoute: true,
    },
    {
        path: routeNames.launchpadBlacklist,
        title: "Admin Launchpad Blacklist",
        component: ["/Admin/LaunchpadBlacklist/LaunchpadBlacklist", "LaunchpadBlacklist"],
        authenticatedRoute: true,
    },
    {
        path: routeNames.investorsList,
        title: "Investors list",
        component: ["/KeyInvestors", "KeyInvestorsList"],
        authenticatedRoute: true,
    },
    {
        path: routeNames.investorsView + ":id",
        title: "View investor",
        component: ["/KeyInvestors", "KeyInvestorsView"],
        authenticatedRoute: true,
    },
    {
        path: routeNames.launchpad + "/:tab",
        title: "Entity Launchpad",
        component: ["/Launchpad/Launchpad"],
        authenticatedRoute: false,
    },
    {
        path: routeNames.launchpad,
        title: "Entity Launchpad",
        component: ["/Launchpad/Launchpad"],
        authenticatedRoute: false,
    },
    {
        path: routeNames.portfolio,
        title: "Portfolio",
        component: ["/Portfolio/Portfolio"],
        authenticatedRoute: true,
    },
    {
        path: routeNames.confirmEmail,
        title: "Confirm Email",
        component: ["/Settings/EmailConfirmation"],
        authenticatedRoute: false,
    },
    {
        path: routeNames.kyc,
        title: "Entity KYC",
        component: ["/KYC/KycContainer"],
        authenticatedRoute: true,
    },
    {
        path: routeNames.privacyPolicy,
        title: "Privacy Policy",
        component: PrivacyPolicy,
        authenticatedRoute: false,
    },
    {
        path: routeNames.termsAndConditions,
        title: "Terms and Conditions",
        component: TermsAndConditions,
        authenticatedRoute: false,
    },
    {
        path: routeNames.disclaimer,
        title: "Disclaimer ENT TECHNOLOGIES AG",
        component: Disclaimer,
        authenticatedRoute: false,
    },
    {
        path: routeNames.newsletter,
        title: "Newsletter Entity",
        component: Newsletter,
        authenticatedRoute: false,
    },
    {
        path: routeNames.notFound,
        title: "Not Found",
        component: PageNotFound,
        authenticatedRoute: false,
    },
    {
        path: routeNames.home,
        title: "Home",
        component: Landing,
        authenticatedRoute: false,
    },
    {
        path: routeNames.imprint,
        title: "Imprint",
        component: Imprint,
        authenticatedRoute: false,
    },
    {
        path: routeNames.auth,
        title: "Auth",
        component: Auth,
        authenticatedRoute: false,
    },
    {
        path: routeNames.authLogin,
        title: "Auth Login",
        component: AuthLogin,
        authenticatedRoute: false,
    },
    {
        path: routeNames.authRegister,
        title: "Auth Register",
        component: AuthRegister,
        authenticatedRoute: false,
    },
    {
        path: routeNames.authEmailConfirmation + ":hash",
        title: "Auth Email Confirmation",
        component: AuthEmailConfirmation,
        authenticatedRoute: false,
    },
];

export const externalRoutes = [
    {
        path: routeNames.investors,
        title: "Investors",
        component: withPageTitle("Entity", lazyLoad("/Investors", "Investors")),
    },
    {
        path: routeNames.xDayPresentation + ":lang",
        title: "XDay Presentation",
        component: withPageTitle("XDay • Entity", lazyLoad("/XDay", "XDayPresentation")),
    },
];

const mappedRoutes = routes.map((route) => {
    const title = route.title ? `${route.title} • Entity` : "Entity";

    const requiresAuth = Boolean(route.authenticatedRoute);
    const component = Array.isArray(route.component) ? lazyLoad(route.component[0], route.component[1]) : route.component;
    const wrappedComponent = withPageTitle(title, component);

    return {
        path: route.path,
        component: wrappedComponent,
        authenticatedRoute: requiresAuth,
        loginText: route.loginText !== "" ? route.loginText : undefined,
    };
});

export default mappedRoutes;
