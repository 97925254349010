import React, { FC, Suspense, useEffect, useState } from "react";
import { useGetAccountInfo, useGetLoginInfo } from "@multiversx/sdk-dapp/hooks";
import { useContext, useDispatch } from "../../context";
import UsersService from "../../services/UsersService";
import { useService } from "../../services/config/dependencyInjectorConfig";
import { User } from "../../common/types/userTypes";
import { clearSessionAndContextForLogout, performLogout } from "../../helpers/util.functions";
import { useLocation } from "react-router-dom";
import mappedRoutes, { routeNames } from "../../routes/routes";
import SessionStorageService from "../../services/sessionStorageService/SessionStorageService";
import { SessionStorageKeys } from "../../services/sessionStorageService/sessionStorageKeys";
import LocalStorageService from "../../services/localStorageService/LocalStorageService";
import { LocalStorageKeys } from "../../services/localStorageService/LocalStorageKeys";
import ESpinner from "../Spinner/ESpinner";

function isAuthenticatedRoute(pathname: string): boolean {
    const mappedRoute = mappedRoutes.find((route) => {
        const parts = pathname.split("/");
        const routeStartPath = parts.length > 2 ? "/" + parts[1] : pathname;
        return route.path === routeStartPath;
    });
    return mappedRoute ? mappedRoute.authenticatedRoute : false;
}

const CheckAuth: FC<React.PropsWithChildren<unknown>> = ({ children }) => {
    const { userSettings, jwtToken } = useContext();
    const loginRoute = SessionStorageService.getItem(SessionStorageKeys.loginRoute);
    const logoutReason = SessionStorageService.getItem(SessionStorageKeys.logoutReason);
    const [userService] = useService(UsersService);
    const { address } = useGetAccountInfo();
    const { isLoggedIn } = useGetLoginInfo();
    const dispatch = useDispatch();
    const { pathname } = useLocation();
    const loginInfo = useGetLoginInfo();

    const [isUserAuthAndReady, setIsUserAuthAndReady] = useState<boolean>(false);
    const [isSessionAlive] = useState<boolean>(LocalStorageService.getItem(LocalStorageKeys.isSessionAlive));

    const updateLoginRoute = (currentRoute: string | undefined) => {
        if (currentRoute && currentRoute !== loginRoute && currentRoute !== routeNames.unlock) {
            SessionStorageService.setItem(SessionStorageKeys.loginRoute, currentRoute);
        }
    };

    const setAuthToken = async () => {
        let jwtTokenInfo: string | undefined = jwtToken;
        let getUser: User | undefined = userSettings;

        if (isLoggedIn && !logoutReason) {
            if (jwtTokenInfo && getUser && getUser.id) {
                setIsUserAuthAndReady(true);
                return;
            }

            if (!jwtTokenInfo) {
                jwtTokenInfo = loginInfo.tokenLogin?.nativeAuthToken;
                if (jwtTokenInfo) {
                    LocalStorageService.setItem(LocalStorageKeys.entityUserAuthToken, jwtTokenInfo);
                }
            }

            if (jwtTokenInfo && !getUser.id) {
                getUser = await userService.checkUser(address);
            }

            if (jwtTokenInfo && getUser && getUser.id) {
                setIsUserAuthAndReady(true);
                dispatch({
                    type: "setAuthenticationData",
                    jwtToken: jwtTokenInfo,
                    userSettings: getUser,
                });
            } else {
                performLogout(dispatch);
            }
        } else {
            if (jwtTokenInfo || (getUser && getUser.id)) {
                performLogout(dispatch);
            }

            if (LocalStorageService.getItem(LocalStorageKeys.entityUserAuthToken)) {
                clearSessionAndContextForLogout(dispatch);
            }
        }
    };

    useEffect(() => {
        if (!isSessionAlive) {
            if (isLoggedIn) {
                performLogout(dispatch, routeNames.home);
            }
            LocalStorageService.setItem(LocalStorageKeys.isSessionAlive, true);
        }
    }, [isSessionAlive]);

    useEffect(() => {
        updateLoginRoute(pathname);
    }, [pathname]);

    useEffect(() => {
        setAuthToken().catch(() => performLogout(dispatch));
    }, []);

    if (isAuthenticatedRoute(pathname) && !isUserAuthAndReady) {
        return <ESpinner />;
    }

    return <Suspense fallback={<ESpinner />}>{children}</Suspense>;
};

export default CheckAuth;
