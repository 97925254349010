interface ConditionalWrapProps {
    condition: boolean;
    wrap: (children: JSX.Element, className?: string) => JSX.Element;
    children: JSX.Element;
    className?: string;
}

const ConditionalWrapper = ({ condition, wrap, children, className = "" }: ConditionalWrapProps) =>
    condition ? wrap(children, className) : children;

export default ConditionalWrapper;
