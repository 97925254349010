import styled from "styled-components";
import { InputGroup } from "react-bootstrap";

const InputGroupIcon = styled(InputGroup.Text)`
    background: transparent;
    border: none;

    &.disabled {
        filter: brightness(75%);
        pointer-events: auto;
        cursor: not-allowed;
    }

    &.enabled {
        cursor: pointer;
    }

    &.green {
        color: var(--entity-green);
    }

    &.red {
        color: var(--entity-red);
    }

    :hover {
        color: var(--white);
    }
`;

export default InputGroupIcon;
