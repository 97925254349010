import React from "react";
import { Card } from "react-bootstrap";
import MetaDecorator from "../../common/components/MetaDecorator";
import { seoData } from "../../helpers/consts/SEO";
import { routeNames } from "../../routes/routes";
import styled from "styled-components";

const Title = styled.p`
    font-size: 1.3rem;
    text-transform: uppercase;
    font-weight: bold;
`;

const CardInfo = styled(Card)`
    color: white;
    line-height: 2.1rem;
    font-size: 1.1rem;

    a {
        color: var(--entity-green);
    }
`;

const PrivacyPolicy = () => {
    return (
        <div className="container py-2 py-md-5">
            <MetaDecorator
                title={seoData.privacyPolicy.title}
                description={seoData.privacyPolicy.description}
                keywords={seoData.privacyPolicy.keywords}
                canonical={routeNames.privacyPolicy}
            />
            <CardInfo>
                <Card.Body>
                    <Title>PRIVACY POLICY</Title>
                    <p>
                        One of the main priorities of ENT Technologies AG (‘’ENT’’) is the protection of the privacy of all
                        visitors of https://app.entity.global.
                    </p>
                    <p>
                        As We are committed to protect and respect Your privacy, the following information will provide You an
                        overview of what happens to Your Personal Data we collect, use and share in connection with the ENT
                        website as well as the purposes we use it. We are aware of the importance of how Personal Data as
                        confidential information are handled and We assure you that We observe all applicable data protection
                        laws, including the General Data Protection Regulation (EU) 2016/679 (“GDPR”). Unless otherwise indicated,
                        terms used in this Regulation shall have the same meaning as under GDPR.
                    </p>
                    <p>
                        This Privacy Policy is issued on behalf of ENT so when we mention &quot;ENT&quot;, &quot;We&quot;,
                        &quot;Us&quot; or &quot;Our&quot; in this Privacy Policy, We are referring to the company ENT Technologies
                        AG and its related group of undertakings, responsible for processing Your data. When We mention
                        &quot;You&quot; AND ‘’Your’’ We are referring to the individual accessing or using services, or the
                        company, or other legal entity on behalf of which such individual is accessing or using the service, as
                        applicable.
                    </p>
                </Card.Body>
            </CardInfo>
            <CardInfo className="mt-3">
                <Card.Body>
                    <Title>1. GENERAL PROVISIONS</Title>
                    <p>
                        Whenever You use access to Our website and services provided, a variety of personal information will be
                        collected. Personal data comprises data that can be used to personally identify You. We herewith advise
                        you that the transmission of data via the Internet (i.e., through e-mail communications) may be prone to
                        security gaps. It is not possible to completely protect data against third-party access.
                    </p>
                    <p>
                        Personal Data means any Data that can be used, collected and shared by us and disclosed to Us of any
                        visitor or participant accessing or using our website and services, including, for example, Your name,
                        email address, as well as any other non-public information about You that is associated with or linked to
                        any of the foregoing.{" "}
                    </p>
                    <p>
                        This Privacy Policy shall only apply to Our online activities and is valid for any visit to our website
                        with regards to the information that is shared and/or collect in ENT. This policy is not applicable to any
                        information collected offline or via other channels than this website.
                    </p>
                </Card.Body>
            </CardInfo>

            <CardInfo className="mt-3">
                <Card.Body>
                    <Title>2. YOUR LEGAL RIGHTS </Title>
                    <ol type="a">
                        <li>Right of access and information</li>
                        <p>
                            You have the right to request from Us free confirmation of the processing of the data in question and
                            to be informed of such data and to receive further information and a copy of the data in accordance
                            with the provisions of the law. The subject of the information is the stored personal data themselves,
                            the origin of the data, their recipients, and the purpose of the data processing (cf. Art. 15 GDPR).
                        </p>

                        <li>Right of rectification</li>
                        <p>
                            You have the right to correct your Data when it is inaccurate or incomplete (for example, change your
                            address).
                        </p>

                        <li>Right to cancellation or blocking</li>
                        <p>
                            You have the right to demand from us as the responsible party the immediate deletion of the data
                            concerned or, alternatively, to demand that the processing of the data be restricted in accordance
                            with the statutory provisions (cf. Art. 17, 18 GDPR).
                        </p>

                        <li>Right to data transfer</li>
                        <p>
                            You have the right to demand from us that we hand over to you or pass on to third parties the data
                            concerning you which you have made available to us in accordance with the statutory provisions (cf.
                            Art. 20 GDPR). Direct transfer to another responsible party is subject to technical feasibility.
                        </p>

                        <li>Right to object and withdraw your consent</li>
                        <p>
                            You may withdraw consent at any time where we are relying on consent to process your personal data.
                            However, We note that this will not affect the lawfulness of any processing carried out before You
                            withdraw Your consent. If you withdraw Your consent, We may not be able to provide certain products or
                            services to You. We will of course advise You if this is the case at the time you withdraw Your
                            consent. At any time, You may withdraw Your consent or object to the receipt of newsletters and
                            advertising emails, the personalization of advertisements or the sharing of your Data with our
                            partners (except for technical service providers) by email request to privacy@entity.global.
                        </p>
                        <p>
                            We will not discriminate against and, therefore, not deny requests without legal reason to You that
                            choose to exercise your rights.
                        </p>
                        <p>
                            We will consider all requests to exercise Your rights and provide Our response without undue delay
                            (and in any event within one month of your request unless We tell You we are entitled to a longer
                            period allowed by applicable law).
                        </p>
                        <p>
                            Certain personal information may be exempt from such requests in certain circumstances, for example if
                            We need to keep using the information to comply with our own legal obligations or to establish,
                            exercise or defend legal claims. If an exception applies, We will tell You this when responding to
                            your request.{" "}
                        </p>
                        <p>
                            We may request you provide us with information necessary to confirm your identity before responding to
                            any request You make.
                        </p>
                        <p>
                            If you feel that your rights have not been respected after having contacted us, you have the right to
                            make a complaint to the competent supervisory authority. For Liechtenstein, the competent authority
                            is: <br />
                            Datenschutzstelle Liechtenstein: <br />
                            Städtle 38 <br />
                            Postfach 684 <br />
                            FL-9490 Vaduz <br />
                            T +423 236 60 90 <br />
                            info.dss@llv.li
                        </p>
                    </ol>
                </Card.Body>
            </CardInfo>

            <CardInfo className="mt-3">
                <Card.Body>
                    <Title>3. CONTROLLER</Title>
                    <p>
                        Controller for the purpose of the GDPR, other data protection laws applicable in Member states of the
                        European Union and other provisions related to data protection is:
                    </p>
                    <p>
                        ENT Technologies AG <br />
                        Pradafant 11 <br />
                        9490 Vaduz <br />
                        Principality of Liechtenstein <br />
                        E-Mail: info@entity.global <br />
                        https://entity.global
                    </p>
                    <p>
                        If you have any questions or concerns concerning data protection in connection with the use of our
                        services or our website, you can reach us as the responsible party in accordance with the GDPR.{" "}
                    </p>
                </Card.Body>
            </CardInfo>

            <CardInfo className="mt-3">
                <Card.Body>
                    <Title>4. PROCESSING OF PERSONAL DATA WHEN VISITING OUR WEBSITE</Title>
                    <p>
                        When using the website for informational purposes, i.e. merely viewing it without registering and without
                        You providing Us with any other information, We process the Personal Data that Your browser transmits to
                        Our server. By using the website, You accept that Your user data can be stored on servers belonging to
                        ENTITY Growth Engine. The data described below is technically necessary for Us to display our website to
                        You and to ensure stability and security and must therefore be processed by Us. The legal basis is Art. 6
                        (1) lit. f GDPR:
                    </p>
                    <ul>
                        <li>IP address</li>
                        <li>Date and time of the request</li>
                        <li>Time zone difference</li>
                        <li>Content of the request (page visited)</li>
                        <li>Access status/HTTP status code</li>
                        <li>Amount of data transferred</li>
                        <li>Previously visited page</li>
                        <li>Browser</li>
                        <li>Operating system</li>
                        <li>Language and version of the browser software.</li>
                    </ul>
                </Card.Body>
            </CardInfo>

            <CardInfo className="mt-3">
                <Card.Body>
                    <Title>5. COLLECTION OF YOUR PERSONAL DATAWEBSITE</Title>
                    <p>
                        We may collect, use, store and transfer different kind of Personal Data as follow: <br />
                        Personal Data We receive from our visitors and users, cooperation partners, authorities and institution,
                        any third parties and other involved persons in connection with Our services on a case-specific basis
                        depending on the purpose and obtain and process, if permitted by law, further data or receive such data as
                        follows:
                    </p>
                    <ol type="a">
                        <li>Personal Information You provide us</li>
                        <p>
                            We collect Your data as a result of you sharing Your data with us by visiting our website, filling in
                            forms on website/platform, using Our services, updating Your user profile or otherwise among other
                            things to provide Us with the required Personal Information. We may also collect Personal Data, if You
                            provide us with feedback and/or correspond with Us by mail, phone etc. and voluntarily provide Us with
                            Your Personal Data.
                        </p>
                        <p>In this case, We may collect Personal Data from You, such as including, but not limited to:</p>
                        <ul>
                            <li>
                                Identity data, which includes Your first name, maiden name, last name, title, date of birth,
                                username, password, and other registration/user information and/or similar identifiers (if
                                applicable),
                            </li>
                            <li>Contact data, which includes Your address, e-mail address, phone number, billing address</li>
                            <li>Marketing and communication data, which includes Your communication preferences.</li>
                            <p>
                                and all other information and content You provide us. This also includes any know your customer
                                data You may provide us such as cryptocurrency address and any other information required to
                                comply with applicable laws and may become associated with Personal Data when using our website
                                and services.{" "}
                            </p>
                            <p>
                                Our service lets you store preferences like how your content is displayed, notification settings,
                                and favorites. We may associate these choices with Your ID, browser, or mobile device.
                            </p>
                        </ul>

                        <li>Automated Personal Information collected via technology </li>
                        <p>
                            By visiting our website ENT will automatically record any other Personal Data. This is Personal
                            information collected by using automated technologies or interactions.
                        </p>
                        <p>This data comprises primarily technical information such as including, but not limited to:</p>
                        <ul>
                            <li>
                                Technical Data, which includes the IP address, information on your internet service provider,
                                device type, browser type and version, time zone setting and location, browser plug-in types and
                                versions, operating system, clickstream data and other technology on the devices You use to access
                                the Website. This also includes information about Your use of the services, including the type of
                                browser time and date of access, pages viewed, and the page You visited before navigating to our
                                services.
                            </li>
                            <li>
                                Device Data, which includes information about the computer or mobile device You use to access our
                                website, including the hardware model, operating system and version, unique device identifiers,
                                and mobile network information.
                            </li>
                            <li>Usage Data, which includes information about how You use the website.</li>
                        </ul>
                    </ol>
                    <p>
                        We also collect, use and share aggregated data such as statistical or demographic data for any purpose.
                        Aggregated data will be derived from Your personal data but will be anonymized to the point that it is not
                        considered personal data as this data does not directly or indirectly reveal your identity nor allows any
                        conclusions to be drawn. For example, we may aggregate your usage data to calculate the percentage of
                        users accessing a specific feature of the website. However, if We combine or connect aggregated data with
                        your personal data so that it can directly or indirectly identify You, we treat the combined data as
                        Personal Data which will be used in accordance with the relevant data protection laws and this Privacy
                        Policy.
                    </p>
                    <p>
                        In case of using a location-enabled device ENT may collect location data or use various means to determine
                        the location.
                    </p>
                </Card.Body>
            </CardInfo>

            <CardInfo className="mt-3">
                <Card.Body>
                    <Title>Cookies</Title>
                    <p>
                        We use what the industry refers to as “cookies” or “pixels” to make our offer user-friendly. We use
                        cookies or similar technologies on our website for identification and analysis purposes. We also use
                        cookies and similar technologies to analyze how Our website visitors interact with Our website and to
                        serve advertisements to Our Website visitors when they are browsing other areas of the internet.
                    </p>
                    <p>
                        Cookies are small files that are automatically created by Your browser and stored on Your end device
                        (computer, tablet, smartphone, etc.) when You visit our website and do not cause any damage. They are
                        either stored temporarily for the duration of a session (“session cookies”) or they are permanently
                        archived on your device (“permanent cookies”). Session cookies are automatically deleted once You
                        terminate your visit. Permanent cookies remain archived on Your device until you actively delete them, or
                        they are automatically eradicated by Your web browser. In some cases, it is possible that third-party
                        cookies are stored on Your device once You enter our site (“third-party cookies”). These cookies enable
                        You or us to take advantage of certain services offered by the third party.
                    </p>
                    <p>
                        Cookies have a variety of functions. Many cookies are technically essential since certain website
                        functions would not work in the absence of the cookies (e.g., the display of videos). The purpose of other
                        cookies may be the analysis of user patterns or the display of promotional messages. Cookies allow Us to
                        analyze Your interaction with our services, make improvements and recognize Your browser the next time You
                        visit and use the information to personalize Your experience.
                    </p>
                    <p>
                        Any visitors and users that deny the use and saving of cookies from this website are advised to take the
                        necessary steps within their web browsers security settings to block all cookies from this website. Please
                        note that deactivating cookies means that You may not be able to use all functions of our website.
                        Accepting cookies is not a prerequisite for visiting our website.
                    </p>
                    <p>
                        In the event that third-party cookies are used or if cookies are used for analytical purposes, We will
                        separately notify You in conjunction with this Data Protection Policy and, if applicable, ask for Your
                        consent.
                    </p>
                </Card.Body>
            </CardInfo>

            <CardInfo className="mt-3">
                <Card.Body>
                    <Title>Tracking pixels</Title>
                    <p>
                        Tracking pixels work in a similar way to cookies and may allow us to gather information about Your
                        behavior on Our website or emails received from Us. Tracking pixels are electronic images that may be used
                        to help deliver cookies, count website visits, understand usage and allow us to analyze how users find Our
                        service, make the service more useful to You, and tailor Your experience with Us to meet Your particular
                        interests and needs.
                    </p>
                </Card.Body>
            </CardInfo>

            <CardInfo className="mt-3">
                <Card.Body>
                    <Title>6. HOW WE USE YOUR DATA</Title>
                    <p>
                        We will only use Your Personal Data to run Our business, provide Our services, personalize Your experience
                        on the services, improve the services and especially when the law allows Us to. Most commonly, We will use
                        Your data in the following circumstances:
                    </p>
                    <ul>
                        <li>identify You as a user in Our system;</li>
                        <li>to contact You;</li>
                        <li>facilitate the creation of and secure Your account;</li>
                        <li>
                            provide You with Our services, including, but not limited to, helping You view and explore Our
                            services using Our tools;
                        </li>
                        <li>
                            improve the administration of Our service and quality of experience when You interact with Our
                            service, including, but not limited to, by analysing how You and other users find and interact with
                            the service;
                        </li>
                        <li>ensure a flawless provision and security of the website;</li>
                        <li>to provide You with customer support, respond to Your requests and inquiries;</li>
                        <li>
                            to provide You with news, special offers and general information about other goods, services and
                            events which We offer that are similar to those that You have already purchased or enquired about
                            unless You have opted not to receive such information;
                        </li>
                        <li>
                            other data can be used to analyse Your user behaviour and prepare reports or compile statistics to
                            understand the type of people who use Our website, how they use Our website and to make Our website
                            more intuitive. If not necessarily in direct connection to Your account or to Your direct benefit
                            within the services offered by ENT, such details will be anonymized and You will not be identifiable
                            from the information collected;
                        </li>
                        <li>investigate and address conduct that may violate Our Terms and Conditions;</li>
                        <li>
                            where it is necessary for Our legitimate interests (or those of a third party) and Your interests and
                            fundamental rights do not override those interests;
                        </li>
                        <li>
                            where We need to comply with a legal obligation; cooperate with investigations by law enforcement or
                            other authorities of suspected violations of law, and/or to pursue or defend against legal threats
                            and/or claims; and
                        </li>
                        <li>
                            for other purposes: we may use your information for other purposes, such as data analysis, identifying
                            usage trends, determining the effectiveness of our promotional campaigns and to evaluate and improve
                            Our service, products, services, marketing and Your website experience.
                        </li>
                        <li>act in any other way We may describe when You provide the Personal Data.</li>
                    </ul>
                </Card.Body>
            </CardInfo>

            <CardInfo className="mt-3">
                <Card.Body>
                    <Title>7. LEGAL BASIS FOR PROCESSING PERSONAL DATA under GDPR</Title>
                    <p>We may process Personal Data under the following conditions:</p>
                    <ul>
                        <li>
                            Performance of a contract: Provision of Personal Data if it is necessary for the performance of an
                            agreement with You and/or for any pre-contractual obligations thereof.
                        </li>
                        <li>
                            Legal obligations: Processing Personal Data is necessary for compliance with a legal obligation to
                            which ENT is subject.
                        </li>
                        <li>
                            Vital interests: Processing Personal Data is necessary in order to protect Your vital interests or of
                            another natural person.
                        </li>
                        <li>
                            Public interests: Processing Personal Data is related to a task that is carried out in the public
                            interest or in the exercise of official authority vested in ENT.
                        </li>
                        <li>
                            Legitimate interests: Processing Personal Data is necessary for the purposes of the legitimate
                            interests pursued by ENT.
                        </li>
                        <li>
                            Consent: You have given Your consent for processing Personal Data for one or more specific purposes.
                        </li>
                    </ul>
                    <p>
                        In any case, ENT will gladly help to clarify the specific legal basis that applies to the processing, and
                        in particular whether the provision of Personal Data is a statutory or contractual requirement, or a
                        requirement necessary to enter into a contract.
                    </p>
                </Card.Body>
            </CardInfo>

            <CardInfo className="mt-3">
                <Card.Body>
                    <Title>8. DISCLOSURE OF YOUR DATA</Title>
                    <p>
                        We disclose your Personal Data as described below and as described elsewhere in this Privacy Policy and
                        may share Your Data with:
                    </p>
                    <ul>
                        <li>
                            With Service Providers: We may share your personal information with service providers of ENT if
                            necessary.
                        </li>
                        <li>
                            With affiliates: We may share Your information with our affiliates, in which case We will require
                            those affiliates to honor this Privacy Policy. Affiliates include any subsidiaries, joint venture
                            partners or other companies that We control or that are under common control with us, third parties
                            who provide Us with technical support, such as Our IT providers; and/or;
                        </li>
                        <li>
                            Merger or acquisition: If We are involved in a merger, acquisition, or sale of all or a portion of its
                            assets, any third party to which We sell or transfer Our assets will have the right to continue to use
                            the personal and other information that You provide us in the manner set out in this Privacy Policy.
                        </li>
                        <li>
                            Legal rights: In addition, we may disclose Your information to the extent that We are required to do
                            so by law (which may include to government bodies and law enforcement agencies); in connection with
                            any legal proceedings or prospective legal proceedings; and in order to establish, exercise or defend
                            our legal rights (including providing information to others for the purposes of fraud prevention).
                        </li>
                        <li>
                            With Your consent: We may disclose Your personal information for any other purpose with Your consent.
                        </li>
                    </ul>
                </Card.Body>
            </CardInfo>

            <CardInfo className="mt-3">
                <Card.Body>
                    <Title>9. TRANSFER OF DATA TO NON-EU COUNTRIES</Title>
                    <p>
                        Among other things, We use tools and services of companies domiciled outside the EU. If such tools are
                        active and your personal data is being transferred outside the EU, We make sure that the transfer is made
                        only to countries that have been declared as offering an adequate level of protection through a European
                        Commission decision (‘Adequacy Decision’). In absence of an Adequacy Decision, appropriate safeguards are
                        in place and asserted by each contractual party.
                    </p>
                </Card.Body>
            </CardInfo>

            <CardInfo className="mt-3">
                <Card.Body>
                    <Title>10. STORAGE DURATION</Title>
                    <p>
                        Cookies from Google Analytics and onFido are stored for 1 year. Other cookies are stored in the user’s
                        localStorage on their personal device. ENTITY Growth Engine does not delete these localStorage cookies,
                        therefore You are responsible to manually delete them within your web browser.
                    </p>
                    <p>
                        Unless a more specific storage period has been specified in this Privacy Policy, Your personal data will
                        remain with Us until the purpose for which it was collected no longer applies. If You assert a justified
                        request for deletion or revoke your consent to data processing, Your data will be deleted, unless We have
                        other legally permissible obligations for storing your personal data; in the latter case, the deletion
                        will take place after these obligations cease to apply.
                    </p>
                </Card.Body>
            </CardInfo>

            <CardInfo className="mt-3">
                <Card.Body>
                    <Title>11. MARKETING</Title>
                    <p>
                        We may use Your Personal data to form a view on what we think you may want or need or what may be of
                        interest to You. This is how we decide which products, services and offers may be relevant for You. With
                        regard to our marketing and advertising purposes ENT is striving to provide clear information of Personal
                        data usage. We provide You clear choices on your Personal data uses. You can always access your personal
                        communication preferences management page via Our e-mail marketing@entity.global and have the possibility
                        to opt-out any time from receiving any marketing communications from us by sending Your choice to
                        marketing@entity.global.
                    </p>
                </Card.Body>
            </CardInfo>

            <CardInfo className="mt-3">
                <Card.Body>
                    <Title>12. CONTACT FORM</Title>
                    <p>
                        If You submit inquiries to Us via Our contact form, the information provided in the contact form as well
                        as any contact information provided therein will be stored by Us in order to handle Your inquiry and in
                        the event that We have further questions.
                    </p>
                    <p>
                        The processing of these data is based on Art. 6(1)(b) GDPR, if Your request is related to the execution of
                        a contract or if it is necessary to carry out pre-contractual measures. In all other cases the processing
                        is based on Our legitimate interest in the effective processing of the requests addressed to us (Art.
                        6(1)(f) GDPR) or on Your agreement (Art. 6(1)(a) GDPR) if this has been requested.
                    </p>
                    <p>
                        The information You have entered into the contact form shall remain with Us until you ask Us to eradicate
                        the data, revoke Your consent to the archiving of data or if the purpose for which the information is
                        being archived no longer exists (e.g., after we have concluded Our response to Your inquiry). This shall
                        be without prejudice to any mandatory legal provisions, in particular retention periods.
                    </p>
                </Card.Body>
            </CardInfo>

            <CardInfo className="mt-3">
                <Card.Body>
                    <Title>13. ANALYSIS TOOLS AND ADVERTISING</Title>
                    <ol type="a">
                        <br />
                        <li>Google Analytics</li>
                        <p>
                            This website uses functions of the web analysis service Google Analytics. The provider of this service
                            is Google Inc., 1600 Amphitheatre Parkway, Mountain View, CA 94043, USA and/or its affiliates.
                        </p>
                        <p>
                            Google Analytics uses so-called cookies. Cookies are text files, which are stored on Your computer and
                            that enable an analysis of the use of the website by users. The information generated by cookies on
                            your use of this website is usually transferred to a Google server in the United States, where it is
                            stored. Google will possibly also transmit this information to third parties, insofar as this is
                            prescribed by law or insofar as third parties process these data on behalf of Google.
                        </p>
                        <p>
                            For more information on how to handle user data on Google Analytics, please refer to the Google
                            Privacy Policy: https://support.google.com/analytics/answer/6004245.
                        </p>
                        <p>
                            The storage of Google Analytics cookies and the utilization of this analysis tool are based on Art. 6
                            Sect. 1 lit. f GDPR. The operator of this website has a legitimate interest in the analysis of user
                            patterns to optimize both, the services offered online and the operator’s advertising activities.
                        </p>

                        <li>Google Tag Manager</li>
                        <p>
                            We use the Google Tag Manager. The provider is Google Ireland Limited, Gordon House, Barrow Street,
                            Dublin 4, Ireland and/or its affiliates.
                        </p>
                        <p>
                            The Google Tag Manager is a tool that allows us to integrate tracking or statistical tools and other
                            technologies on our website. The Google Tag Manager itself does not create any user profiles, does not
                            store cookies, and does not carry out any independent analyses. It only manages and runs the tools
                            integrated via it. However, the Google Tag Manager does collect your IP address, which may also be
                            transferred to Google’s parent company in the United States.
                        </p>
                        <p>
                            The Google Tag Manager is used on the basis of Art. 6(1)(f) GDPR. The website operator has a
                            legitimate interest in the quick and uncomplicated integration and administration of various tools on
                            his website. If appropriate consent has been obtained, the processing is carried out exclusively on
                            the basis of Art. 6(1)(a) GDPR, insofar the consent includes the storage of cookies or the access to
                            information in the user’s end device (e.g., device fingerprinting) within the meaning of the GDPR.
                            This consent can be revoked at any time.
                        </p>

                        <li>Meta Pixel</li>
                        <p>
                            We also use Meta Pixel. This tool is operated by Meta Platforms Ireland Limited, 4 Grand Canal Square,
                            Grand Canal Harbour, Dublin 2, Ireland. It allows Us to understand and deliver ads and make them more
                            relevant to You. The collected data remains anonymous, and We cannot see the Personal Data of any
                            individual user. However, the collected data is saved and processed by Meta. Meta may be able to
                            connect the data with your Facebook or Instagram account and use the data for their own advertising
                            purposes. Meta has ultimate control of the information gathered through Meta Pixel.{" "}
                        </p>

                        <li>Twitter Pixel</li>
                        <p>
                            On our website We use the visitor action pixel of Twitter Inc., 795 Folsom St., Suite 600, San
                            Francisco, CA 94107, USA (&quot;Twitter&quot;) for statistical purposes. The pixel enables us to track
                            the behaviour of users after they have been referred to our website by clicking on a Twitter ad. This
                            process is used to evaluate the effectiveness of Twitter ads for statistical and market research
                            purposes and may help to optimize future advertising efforts. The data collected in this way does not
                            allow us to draw any conclusions about the identity of users. However, the data is stored and
                            processed by Twitter so that a connection to the profile of the respective user is possible and
                            Twitter can use the data for its own advertising purposes. This data may enable Twitter and its
                            partners to place advertisements on and outside Twitter. A cookie may also be stored and read on
                            users&apos; computers for these purposes.
                        </p>
                    </ol>
                </Card.Body>
            </CardInfo>

            <CardInfo className="mt-3">
                <Card.Body>
                    <Title>14. SOCIAL PLUG-INS (Facebook, Twitter; Linked-in etc.)</Title>
                    <p>
                        Our internet pages do not use social plugins from Facebook and Twitter, this means, when visiting our
                        website, no information is passed on to the Facebook and Twitter providers. However, you can share content
                        on your own Facebook and Twitter profiles through our website by visiting links from Facebook and Twitter.
                        You then leave our website and will be redirected directly to the websites and features of the respective
                        providers. The information that they collect about You can be found in the privacy statements of the
                        respective providers.
                    </p>

                    <p>
                        Facebook Privacy Policy:{" "}
                        <a className="text-green" target="_blank" rel="noreferrer" href="https://www.facebook.com/privacy/policy">
                            https://www.facebook.com/privacy/policy
                        </a>
                    </p>
                    <p>
                        Privacy Policy from Twitter:{" "}
                        <a className="text-green" target="_blank" rel="noreferrer" href="https://twitter.com/privacy">
                            https://twitter.com/privacy
                        </a>
                    </p>
                    <p>
                        Linked-in Privacy Policy:{" "}
                        <a
                            className="text-green"
                            target="_blank"
                            rel="noreferrer"
                            href="https://de.linkedin.com/legal/privacy-policy"
                        >
                            https://de.linkedin.com/legal/privacy-policy
                        </a>
                    </p>
                    <p>
                        Instagram Privacy Policy:{" "}
                        <a
                            className="text-green"
                            target="_blank"
                            rel="noreferrer"
                            href="https://instagram.com/about/legal/privacy/"
                        >
                            https://instagram.com/about/legal/privacy/
                        </a>
                    </p>
                </Card.Body>
            </CardInfo>

            <CardInfo className="mt-3">
                <Card.Body>
                    <Title>15. LINKS TO OTHER THIRD-PARTY WEBSITES</Title>
                    <p>
                        Our service and site may contain links to third-party websites, plug-ins, and applications. When you click
                        on a link to any other website or location, You will leave Our service and go to another site, and another
                        entity may collect Personal Data from You. We have no control over, do not review, and cannot be
                        responsible for these third-party websites or their content. Please be aware that the terms of this
                        Privacy Policy do not apply to these third-party websites or their content, or to any collection of your
                        Personal Data after you click on links to such third-party websites. We encourage You to read the privacy
                        policies of every website You visit. Any links to third-party websites or locations are for Your
                        convenience and do not signify our endorsement of such third parties or their products, content, or
                        websites.
                    </p>
                </Card.Body>
            </CardInfo>

            <CardInfo className="mt-3">
                <Card.Body>
                    <Title>16. LINKS TO OTHER THIRD-PARTY WALLETS</Title>
                    <p>
                        To use Our service, You may use a third-party wallet which allows You to engage in transactions on public
                        blockchains. Your interactions with any third-party wallet provider are governed by the applicable terms
                        of service and privacy policy of that third party
                    </p>
                </Card.Body>
            </CardInfo>

            <CardInfo className="mt-3">
                <Card.Body>
                    <Title>17. MINORS</Title>
                    <p>
                        We do not intentionally gather Personal Data from visitors who are under the age of 13. Our Terms and
                        Conditions require all users to be at least 18 years old. If a child under 13 submits Personal Data to Us
                        and we learn that the Personal Data is the information of a child under 13, We will attempt to delete the
                        information as soon as possible. If You believe that We might have any Personal Data from a child under
                        13, please contact Us via the form contact or our indicated e-mail indicated in section 3.
                    </p>
                </Card.Body>
            </CardInfo>

            <CardInfo className="mt-3">
                <Card.Body>
                    <Title>18. CHANGES TO PRIVACY POLICY</Title>
                    <p>
                        This Privacy Policy may be updated from time to time for any reason. We will notify You of any changes to
                        our Privacy Policy by posting the new Privacy Policy. The date the Privacy Policy was last revised is
                        identified at the beginning of this Privacy Policy. You are responsible for periodically visiting Our
                        service and this Privacy Policy to check for any changes. Changes to this Privacy Policy are effective
                        when they are posted on this page.
                    </p>
                </Card.Body>
            </CardInfo>

            <CardInfo className="mt-3">
                <Card.Body>
                    <Title>19. CONTACT</Title>
                    <p>
                        If You have any questions about this Privacy Policy, You can contact Us by sending Us an email:
                        privacy@entity.global
                    </p>
                </Card.Body>
            </CardInfo>
        </div>
    );
};

export default PrivacyPolicy;
