import React, { useEffect, memo, LazyExoticComponent, ComponentType } from "react";

const withPageTitle =
    <T extends ComponentType>(title: string, Component: ComponentType | LazyExoticComponent<T>) =>
    () => {
        const Memoized = memo(Component);

        useEffect(() => {
            document.title = title;
        }, []);

        return <Memoized />;
    };

export default withPageTitle;
