import Hex from "./hex";
import { BoundingBox, Corner, Ellipse, HexSettings, Orientation, Origin, Point } from "./hex.types";

export const defaultHexSettings: HexSettings = {
    dimensions: { xRadius: 1, yRadius: 1 },
    orientation: Orientation.POINTY,
    origin: "topLeft",
    offset: 1.02,
};

export const getHexOrigin = (input: Origin, boundingBox?: BoundingBox): Point => {
    if (input === "topLeft" && boundingBox) {
        return { x: 0, y: 0 };
    }

    return input as Point;
};

export const getID = (hex: Hex, templateId: string) => {
    return `${templateId}-${hex.q},${hex.r},${hex.s}`;
};

export const getHexDimensions = (input: BoundingBox, orientation: Orientation): Ellipse => {
    const { width, height } = input;

    return orientation === Orientation.POINTY
        ? { xRadius: width / Math.sqrt(3), yRadius: height / 2 }
        : { xRadius: width / 2, yRadius: height / Math.sqrt(3) };
};

export const hexToPoint = ({ orientation, dimensions: { xRadius, yRadius }, origin: { x, y }, q, r, offset }: Hex): Point =>
    orientation === Orientation.POINTY
        ? {
              x: xRadius * Math.sqrt(3) * (q + r / 2) - x,
              y: ((yRadius * 3) / 2) * r - y,
          }
        : {
              x: ((xRadius * 3) / 2) * q * offset - x,
              y: yRadius * Math.sqrt(3) * (r + q / 2) * offset - y,
          };

export function createHex(hexSettings?: Partial<HexSettings>): typeof Hex {
    const { dimensions, orientation, origin, offset } = { ...defaultHexSettings, ...hexSettings };

    return class extends Hex {
        get dimensions(): Ellipse {
            return getHexDimensions(dimensions as BoundingBox, orientation);
        }

        get orientation(): Orientation {
            return orientation;
        }

        get origin(): Point {
            return getHexOrigin(origin, dimensions as BoundingBox);
        }

        get offset(): number {
            return offset;
        }
    };
}

export const getCorners = (corners: Corner[], width: number, height: number, { x, y }: Point) => {
    const points = {
        [Corner.f0]: { x: width - x, y: height * 0.5 - y },
        [Corner.f1]: { x: width * 0.75 - x, y: height - y },
        [Corner.f2]: { x: width * 0.25 - x, y: height - y },
        [Corner.f3]: { x: 0 - x, y: height * 0.5 - y },
        [Corner.f4]: { x: width * 0.25 - x, y: 0 - y },
        [Corner.f5]: { x: width * 0.75 - x, y: 0 - y },
    };

    return corners.map((corner) => points[corner]);
};

export const getHighlightLines = (line: { q: number; r: number; points: Corner[] }[], LineHexagon: typeof Hex) => {
    const points = [];
    let length = 0;
    for (const hex of line) {
        const hexLine = new LineHexagon(hex.q, hex.r);
        const currentPoints = getCorners(hex.points, hexLine.width, hexLine.height, hexLine.origin);
        points.push(...currentPoints.map((currentPoint) => add(currentPoint, { x: hexLine.x, y: hexLine.y })));
    }

    for (let index = 1; index < points.length; index++) {
        const currentCorner = points[index];
        const previousCorner = points[index - 1];
        length += Math.sqrt(Math.pow(currentCorner.x - previousCorner.x, 2) + Math.pow(currentCorner.y - previousCorner.y, 2));
    }

    return { points, length };
};

export const add = (first: Point, second: Point) => {
    return { x: first.x + second.x, y: first.y + second.y };
};
