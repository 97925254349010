import EIcon from "./EIcon";
import { faInfoCircle } from "@fortawesome/free-solid-svg-icons";
import OverlayTriggerTooltip, { OverlayTriggerTooltipProps } from "../../helpers/OverlayTriggerTooltip";
import React, { FC } from "react";
import { SizeProp } from "@fortawesome/fontawesome-svg-core";

interface OverlayInfoTooltipProps extends OverlayTriggerTooltipProps {
    iconSize?: SizeProp;
    iconColor?: string;
}

export const OverlayInfoTooltip: FC<React.PropsWithChildren<OverlayInfoTooltipProps>> = ({
    placement = "top",
    cursor = "pointer",
    className = "ml-1",
    inline = true,
    tooltipText = "",
    iconSize = "1x",
    iconColor = undefined,
    ...props
}) => {
    return (
        <OverlayTriggerTooltip
            placement={placement}
            tooltipText={tooltipText}
            cursor={cursor}
            className={className}
            inline={inline}
            {...props}
        >
            <EIcon icon={faInfoCircle} size={iconSize} iconColor={iconColor} />
        </OverlayTriggerTooltip>
    );
};

export default OverlayInfoTooltip;
