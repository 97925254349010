import React, { FC } from "react";
import DoughnutChart, { DoughnutChartProps } from "../../../../../../components/Charts/DoughnutChart";
import styled from "styled-components";
import Number from "../../../../../../components/Price/Number";
import { TokenEconomicsEntry } from "../../../../launchpad.types";
import ESpinner from "../../../../../../components/Spinner/ESpinner";
import { BREAKPOINTS } from "../../../../../../assets/sass/breakpoints";

export interface TokenAllocationChartProps extends Pick<DoughnutChartProps, "numberFormatStyle"> {
    projectName?: string;
    tokenEconomics?: TokenEconomicsEntry[];
}

const AllocationChart = styled(DoughnutChart)``;

const Labels = styled.div`
    display: flex;
    flex-flow: column;
    justify-content: center;
    line-height: 2.428rem;
`;

const LabelsTitle = styled.div`
    font-size: 1.285rem;
    padding-bottom: 2.142rem;
`;

const Percentage = styled(Number)`
    display: inline-block;
    width: 4.571rem;
`;

const Container = styled.div`
    display: flex;
    justify-content: center;
    padding-top: 4.285rem;
    color: var(--light-gray);
    flex-direction: column;
    margin: 0 auto;

    @media (${BREAKPOINTS.desktop}) {
        flex-direction: row;
        margin: 0 auto;
    }

    ${AllocationChart},
    ${Labels} {
        padding: 0 1.428rem;
    }
`;

const TokenAllocationChart: FC<React.PropsWithChildren<TokenAllocationChartProps>> = ({
    projectName,
    tokenEconomics,
    numberFormatStyle,
}) => {
    const labels: string[] = [];
    const metrics: number[] = [];

    if (!projectName || !tokenEconomics) {
        return (
            <Container>
                <ESpinner />
            </Container>
        );
    }

    tokenEconomics.forEach(({ allocation, percentage }) => {
        labels.push(allocation);
        metrics.push(percentage);
    });

    return (
        <Container>
            <AllocationChart chartData={{ labels, metrics }} numberFormatStyle={numberFormatStyle} />
            <Labels>
                <LabelsTitle>{projectName} Token Allocation</LabelsTitle>
                {labels.map((label, i) => {
                    return (
                        <div key={i}>
                            <Percentage value={metrics[i]} suffix="%" className="pe-3" />
                            {label}
                        </div>
                    );
                })}
            </Labels>
        </Container>
    );
};

export default TokenAllocationChart;
