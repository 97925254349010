import React, { FC } from "react";
import styled from "styled-components";

interface InteriorProps {
    interiorPercentageSize: number;
}

interface ShellProps extends InteriorProps {
    diameter: number | string;
    color: string;
}

interface SphereProps extends Partial<ShellProps>, Partial<InteriorProps> {
    className?: string;
    active?: boolean;
}

const Shell = styled.div`
    --diameter: ${({ diameter }: ShellProps) => diameter};
    --interior-percentage-size: ${({ interiorPercentageSize }) => interiorPercentageSize};

    position: absolute;
    width: calc(var(--diameter));
    height: calc(var(--diameter));
    border-radius: calc(var(--diameter));
    box-shadow: calc(var(--diameter) * 0.003) calc(var(--diameter) * -0.004) calc(var(--diameter) * 0.011)
            calc(var(--diameter) * -0.008) ${({ color }) => color},
        inset calc(var(--diameter) * -0.02) calc(var(--diameter) * 0.018) calc(var(--diameter) * 0.06)
            calc(var(--diameter) * -0.055) ${({ color }) => color};

    border-right: 1px solid ${({ color }) => `${color}5d`};
    border-top: 1px solid ${({ color }) => `${color}5d`};

    &.inactive {
        filter: brightness(40%);
    }
`;

const Platform = styled.div`
    position: absolute;
    width: calc(100% * 0.915);
    height: calc(100% * 0.915);
    border-radius: calc(100% * 0.915);
    box-shadow: inset calc(var(--diameter) * -0.4) calc(var(--diameter) * -0.1) calc(var(--diameter) * 0.24)
        calc(var(--diameter) * -0.2) ${({ color }) => `${color}2f`};
    top: 70%;
    left: 50%;
    transform: translate(-50%, -50%) rotateX(83.5deg);
    border: calc(var(--diameter) / 150) solid ${({ color }) => `${color}0d`};
    border-top: calc(var(--diameter) / 30) solid ${({ color }) => `${color}0d`};
    border-bottom: calc(var(--diameter) / 30) solid ${({ color }) => `${color}0d`};
`;

const Content = styled.div`
    position: absolute;
    width: calc(var(--diameter) * var(--interior-percentage-size) / 100);
    height: calc(var(--diameter) * 0.725 * var(--interior-percentage-size) / 100);
    max-width: calc(var(--diameter) * var(--interior-percentage-size) / 100);
    max-height: calc(var(--diameter) * 0.725 * var(--interior-percentage-size) / 100);
    bottom: calc(var(--diameter) * 0.275);
    left: calc(var(--diameter) * 0.5);
    transform: translateX(-50%);
`;

const Sphere: FC<React.PropsWithChildren<SphereProps>> = ({
    className = "",
    color = "#51e2df",
    diameter = 300,
    interiorPercentageSize = 100,
    children,
}) => {
    return (
        <Shell className={className} diameter={diameter} color={color} interiorPercentageSize={interiorPercentageSize}>
            <Content>{children}</Content>
            <Platform color={color} />
        </Shell>
    );
};

export default Sphere;
