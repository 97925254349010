import BaseService from "./BaseService";
import axios from "axios";

export interface Tickets {
    ticketEntries: number[];
    winningTicketsIds: number[];
    numConfirmedTickets: number;
    hasUserClaimedTokens: boolean;
    isUserBlacklisted: boolean;
}

export const initialTicketsInfo: Tickets = {
    ticketEntries: [],
    winningTicketsIds: [],
    numConfirmedTickets: 0,
    hasUserClaimedTokens: false,
    isUserBlacklisted: false,
};

export interface PlainTx {
    nonce: number;
    value: string;
    receiver: string;
    sender: string;
    gasPrice: number;
    gasLimit: number;
    data?: string;
    chainID: string;
    version: number;
    options?: number;
}

export enum TicketsScope {
    BUY_TICKETS = "buy-tickets",
    CLAIM_TOKENS = "claim-tokens",
}

class TicketsService extends BaseService {
    private readonly _launchpadBaseApi: string;

    constructor(launchpadApi: string) {
        super();
        this._launchpadBaseApi = `${launchpadApi}/projects`;
    }

    retrieveUserTickets(projectId: string, scope?: TicketsScope): Promise<Tickets> {
        return this.doCall(() => axios.get(`${this._launchpadBaseApi}/${projectId}/tickets`, { params: { scope } }));
    }

    getConfirmTicketsTx(projectId: string, noOfTickets: number): Promise<PlainTx> {
        return this.doCall(() =>
            axios.get(`${this._launchpadBaseApi}/${projectId}/tickets/confirm`, { params: { tickets: noOfTickets } }),
        );
    }

    getClaimTokensTx(projectId: string): Promise<PlainTx> {
        return this.doCall(() => axios.get(`${this._launchpadBaseApi}/${projectId}/tickets/claim`));
    }
}

export default TicketsService;
