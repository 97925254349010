import React from "react";
import { errorMessage } from "../../helpers/consts/error";
import styled from "styled-components";

const Container = styled.div`
    width: 100%;
    height: 10rem;
    display: flex;
    justify-content: center;
    align-items: center;
    color: white;
`;

const ErrorDummy = ({ errorText = errorMessage }: { errorText?: string }) => {
    return <Container>{errorText}</Container>;
};

export default ErrorDummy;
