import BaseService from "./BaseService";
import axios from "axios";
import { UsdEuroConversion } from "../common/types/usdEuroConversionTypes";

export class UsdEuroService extends BaseService {
    private readonly _entityApi: string;

    constructor(entityApi: string) {
        super();
        this._entityApi = entityApi;
    }

    public async getUsdEuroConversion(): Promise<UsdEuroConversion> {
        return this.doCall(() =>
            axios.get<UsdEuroConversion>(`${this._entityApi}/currency-conversion/usdt-eur`, {
                params: {
                    timestamp: Date.now(),
                },
            }),
        );
    }
}

export default UsdEuroService;
