import React from "react";
import { Col, Row } from "react-bootstrap";
import { useContext } from "../../../context";
import { downloadCsvFile } from "../../../helpers/util.functions";
import TransactionsService from "../../../services/TransactionsService";
import { Query } from "../index";
import moment from "moment";
import { EButton } from "../../../components/Buttons";
import { DATE_FORMAT_DAY_MONTH_MOMENT_JS } from "../../../helpers/consts";
import { useService } from "../../../services/config/dependencyInjectorConfig";
import TabTitle from "../components/TabTitle";
import styled from "styled-components";

interface ITaxToolProps {
    queryParams: Query;
}

export interface CsvPath {
    filePath: string;
    fileName: string;
}

export const initialCsvPath: CsvPath = { fileName: "", filePath: "" };

const CsvGenerateContainer = styled(Col)`
    .grey {
        color: #a3b1b4 !important;
    }
`;

const TaxReport = ({ queryParams }: ITaxToolProps) => {
    const { userSettings } = useContext();
    const [transactionsService] = useService(TransactionsService);

    const downloadCsv = async () => {
        const csvPath: CsvPath = await transactionsService.generateTransactionsCSVExport({
            userId: userSettings.id,
            walletsIds: queryParams.wallets,
            dateStart: queryParams.dateFrom,
            dateEnd: queryParams.dateTo,
        });

        if (!csvPath.fileName) {
            return;
        }
        downloadCsvFile(csvPath.fileName);
    };

    return (
        <>
            <div className="d-flex align-items-center justify-content-between">
                <Col md={9}>
                    <TabTitle>Tax Report</TabTitle>
                    <p className="py-2">
                        Depending on your country-specific tax policies, capital gains tax, income tax or other taxes may apply to
                        tax profits from crypto transactions. Our CSV export file is currently optimized for tax calculation with{" "}
                        <a href="https://cointracking.info/" className="text-green" target="_blank" rel="noreferrer">
                            cointracking.info
                        </a>
                        . Interfaces to other tax tools will follow.
                    </p>
                </Col>
            </div>
            <Row className="mt-5 mb-4">
                <CsvGenerateContainer>
                    <EButton className="mx-auto min-w-350" type="submit" onClick={downloadCsv} testId="generate-csv">
                        Generate CSV file for Cointracking.info
                    </EButton>
                    <p className="pt-4 mb-0 text-center">
                        Time period monitored:{" "}
                        {queryParams.dateFrom && (
                            <span>{moment(queryParams.dateFrom * 1000).format(DATE_FORMAT_DAY_MONTH_MOMENT_JS)} </span>
                        )}{" "}
                        -{" "}
                        {queryParams.dateTo && (
                            <span>{moment(queryParams.dateTo * 1000).format(DATE_FORMAT_DAY_MONTH_MOMENT_JS)} </span>
                        )}
                    </p>
                    <p className="text-center grey">(Change in the settings tab)</p>
                    <p className="text-center mt-4">
                        All data without guarantee. Please consult a tax advisor for a final review.
                    </p>
                </CsvGenerateContainer>
            </Row>
        </>
    );
};

export default TaxReport;
