import React, { FC } from "react";
import { Helmet } from "react-helmet";
import { seoData } from "../../helpers/consts/SEO";

export interface MetaDecoratorProps {
    title: string;
    description: string;
    canonical?: string;
    keywords?: string;
}

const MetaDecorator: FC<React.PropsWithChildren<MetaDecoratorProps>> = ({
    title = seoData.homePage.title,
    description = seoData.homePage.description,
    keywords = seoData.homePage.keywords,
    canonical = undefined,
}) => {
    return (
        <Helmet>
            <title>{title}</title>
            <meta name="description" content={description} />
            {canonical && <link rel="canonical" href={canonical} />}
            <meta property="og:url" content={window.location.href} />
            <meta name="twitter:title" content={title} />
            <meta name="twitter:description" content={description} />
            <meta property="og:title" content={title} />
            <meta property="og:description" content={description} />
            <meta name="keywords" content={keywords} />
        </Helmet>
    );
};

export default MetaDecorator;
