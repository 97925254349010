import { Col, Image } from "react-bootstrap";
import { ProjectCard } from "../../../components/ProjectCard";
import { LaunchPlatforms } from "../../../helpers/consts";
import { createSMCommonBtnsList } from "../../../helpers/socialMedia";
import { getLaunchInfo } from "../../Ico/utils/IcoUtils";
import React from "react";
import styled from "styled-components";
import Slider from "react-slick";
import { ProjectGeneralDetails } from "../launchpad.types";
import { routeNames } from "../../../routes/routes";
import StringUtils from "../../../common/utils/StringUtils";
import { awsS3BaseURL } from "../../../config";

const sliderSettings = {
    dots: true,
    arrows: true,
    infinite: false,
    speed: 500,
    slidesToShow: 3,
    slidesToScroll: 3,
    dotsClass: "slick-dots slick-thumb",
    responsive: [
        {
            breakpoint: 1200,
            settings: {
                slidesToShow: 2,
                slidesToScroll: 2,
                initialSlide: 2,
                infinite: false,
                dots: true,
            },
        },
        {
            breakpoint: 800,
            settings: {
                slidesToShow: 1,
                slidesToScroll: 1,
                initialSlide: 1,
                infinite: false,
                dots: true,
            },
        },
    ],
};

const CustomSlider = styled(Slider)`
    padding: 0 !important;

    .slick-slide.slick-active {
        padding-left: 1rem !important;
        padding-right: 1rem !important;
    }

    .slick-dots {
        position: relative;
        bottom: 0;
        padding-top: 2.928rem;
        padding-bottom: 1.785rem;
    }

    .slick-list {
        margin-left: -1rem;
        margin-right: -1rem;
    }

    .slick-arrow.slick-prev {
        margin-left: -1rem;
    }

    .slick-arrow.slick-next {
        margin-right: -1rem;
    }
`;

const SuccessfulLaunchesSlider = ({ launchedProjects }: { launchedProjects: ProjectGeneralDetails[] }) => {
    if (!launchedProjects || !launchedProjects.length) {
        return <></>;
    }

    return (
        <CustomSlider {...sliderSettings}>
            {launchedProjects.map((project, index) => (
                <Col key={index}>
                    <ProjectCard
                        linkUrl={`${routeNames.project}/${project.icoId}/${StringUtils.slugString(project.name)}`}
                        name={project.name}
                        socialMedia={createSMCommonBtnsList(project.links)}
                        launchPlatform={LaunchPlatforms.Entity}
                        icon={
                            project.logo ? (
                                <Image fluid className="ico-logo-successful-launches" src={awsS3BaseURL + project.logo} />
                            ) : undefined
                        }
                        launchInfo={getLaunchInfo({
                            icoLayer: project.generalMetrics.category,
                            tokenName: project.generalMetrics.tokenName,
                            maxTokenSupply: project.generalMetrics.maxTokenSupply,
                            preSaleStartTimestamp: project.generalMetrics.preSaleStartTimestamp,
                            publicSaleStartTimestamp: project.generalMetrics.publicSaleStartTimestamp,
                            publicSalePrice: project.generalMetrics.publicSaleTokenPrice,
                            hasKyc: project.generalMetrics.hasKyc,
                            publicSaleStartQuarter: project.generalMetrics.publicSaleStartQuarter ?? undefined,
                            preSaleStartQuarter: project.generalMetrics.preSaleStartQuarter ?? undefined,
                        })}
                        rating={project.ratings?.totalScore || 0}
                        testId={`${project.id}-successful-launch-card`}
                    />
                </Col>
            ))}
        </CustomSlider>
    );
};

export default SuccessfulLaunchesSlider;
