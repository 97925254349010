import React from "react";
import { Breadcrumb, Card } from "react-bootstrap";
import { PageHeader } from "../../components/PageHeader";
import MetaDecorator from "../../common/components/MetaDecorator";
import { seoData } from "../../helpers/consts/SEO";
import { routeNames } from "../../routes/routes";

const Disclaimer = () => {
    return (
        <div className="container py-2 py-md-5">
            <MetaDecorator
                title={seoData.disclaimer.title}
                description={seoData.disclaimer.description}
                keywords={seoData.disclaimer.keywords}
                canonical={routeNames.disclaimer}
            />
            <div className="px-3 px-md-0">
                <Breadcrumb className="py-3">
                    <Breadcrumb.Item href="/">Home</Breadcrumb.Item>
                    <Breadcrumb.Item active>Disclaimer</Breadcrumb.Item>
                </Breadcrumb>
                <PageHeader className="mb-3">Disclaimer</PageHeader>
            </div>
            <Card>
                <Card.Body>
                    <p>Disclaimer Website:</p>
                    <p>
                        The operator will undertake every effort to ensure the safe operation of the website. Defects including,
                        but not limited to, loss or falsification of data, virus attack, interruption of operations cannot be
                        excluded. The access to the website therefore lies at the user’s own risk and responsibility.
                    </p>
                    <p>
                        The content of this website is general information of ENT Technologies AG and provided for illustrative
                        and descriptive purposes only and does not constitute a prospectus, an offer document, an offer of
                        securities, a solicitation for investment, any offer to sell any product or an investment advice.{" "}
                    </p>
                    <p>
                        We assume no liability for the topicality, correctness and completeness of the information provided on
                        this website. Any content, documentation or functionalities are subject to modification by ENT in its sole
                        and absolute discretion.{" "}
                    </p>
                    <p>
                        The same applies to the content of external pages to which reference is made direct or indirect. We
                        reserve the right to make changes and additions to the content of the website without prior notice.{" "}
                    </p>
                    <p>
                        Any liability for the content of the website or for damages resulting from or in connection with
                        downloaded information or documents is excluded. This includes both material and immaterial damage.
                    </p>
                    <p>
                        This website is maintained by ENT Technologies AG, Pradafant 11, 9490 Vaduz, Principality of Liechtenstein{" "}
                    </p>
                </Card.Body>
            </Card>
        </div>
    );
};

export default Disclaimer;
