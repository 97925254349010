import styled from "styled-components";

export const AdditionalInfoCard = styled.div`
    background-color: transparent;
    color: var(--primary-text);
    padding: 2.142rem;
    font-size: 0.857rem;
    line-height: 1.714rem;
    border: 1px solid #878c90;
    border-radius: 0.714rem;
    margin-bottom: 2rem;

    h4 {
        font-size: 1.285rem;
        margin: 0 0 2rem 0;
    }

    p {
        line-height: 1.714rem;
        margin-bottom: 2rem;
    }
`;
