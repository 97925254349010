import React from "react";
import { Path, PathValue, useFieldArray } from "react-hook-form";
import { FormControlGroupProps } from "./FormControlledInputGroup";
import { UnpackNestedValue } from "react-hook-form/dist/types/form";
import EButtonSmall from "../../components/Buttons/SmallButton";
import { FormGroupValuesProps } from "./types";
import { FieldArrayWithId, UseFieldArrayRemove } from "react-hook-form/dist/types/fieldArray";

interface FieldsArrayProps<T> extends Pick<FormControlGroupProps<T>, "control">, FormGroupValuesProps<T> {
    pathName: Path<T>;
    newObjectValue: Record<string, unknown>;
    fieldBuilder: (
        field: FieldArrayWithId<Record<string, unknown>, never, string>,
        index: number,
        remove: UseFieldArrayRemove,
    ) => JSX.Element;
}

export default function FieldsArray<T>({
    pathName,
    newObjectValue,
    fieldBuilder,
    control,
    setValue,
    getValues,
}: FieldsArrayProps<T>): JSX.Element {
    const { fields, remove } = useFieldArray({ control, name: pathName });

    return (
        <>
            <EButtonSmall
                onClick={() => {
                    // @ts-ignore
                    setValue(pathName, [{ ...newObjectValue }, ...(getValues(pathName) as [])] as UnpackNestedValue<
                        PathValue<T, Path<T>>
                    >);
                }}
                className="ml-min"
                testId="append-start-entry-btn"
            >
                Append start
            </EButtonSmall>
            {fields.map((field, index) => fieldBuilder(field, index, remove))}
            <EButtonSmall
                onClick={() => {
                    // @ts-ignore
                    setValue(pathName, [...(getValues(pathName) as []), { ...newObjectValue }] as UnpackNestedValue<
                        PathValue<T, Path<T>>
                    >);
                }}
                className="ml-min mt-2"
                testId="append-end-entry-btn"
            >
                Append end
            </EButtonSmall>
        </>
    );
}
