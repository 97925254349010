import React from "react";
import styled from "styled-components";

const HexShadow = styled.div`
    filter: drop-shadow(0px 0px 30px var(--entity-green));

    .hexagon {
        clip-path: polygon(20% 0, 80% 0, 100% 50%, 80% 100%, 20% 100%, 0 50%);
        -webkit-clip-path: polygon(20% 0, 80% 0, 100% 50%, 80% 100%, 20% 100%, 0 50%);
        margin: 0 auto;
        background-color: #061f1f;
        opacity: 1;
        width: 100%;
    }
`;

interface HexContainerProps {
    className?: string;
}

const HexContainer: React.FC<React.PropsWithChildren<HexContainerProps>> = ({ children, className }) => {
    return (
        <HexShadow className={`d-flex flex-column ${className}`}>
            <div className="hexagon d-flex flex-column align-items-center justify-content-between h-100">{children}</div>
        </HexShadow>
    );
};

export default HexContainer;
