import BigNumber from "bignumber.js";

export interface StakingReportsEntity {
    totalBalance: BigNumber | null;
    outBound: number | null;
    swaps: number | null;
    currentBalance: BigNumber | null;
    currentStakingBalance: BigNumber | null;
    totalInStake: BigNumber | null;
    totalStakingRewards: BigNumber | null;
    claimedStaking: BigNumber | null;
    reDelegateStaking: BigNumber | null;
    claimableRewards: BigNumber | null;
    totalInStakeWithinPeriod: BigNumber | null;
    userUndelegatedStake: BigNumber | null;
}

export interface BalanceRequestBody {
    walletIds: string[] | null;
    dateStart: number | null;
    dateEnd: number | null;
}

export class StakingBalanceResponse {
    claimableRewards: BigNumber | null = new BigNumber(0);
    currentWalletBalance: BigNumber | null = new BigNumber(0);
    userActiveStake: BigNumber | null = new BigNumber(0);
    userActiveStakeWithinPeriod: BigNumber | null = new BigNumber(0);
    userUndelegatedStake: BigNumber | null = new BigNumber(0);

    static mapResponse = (obj: Partial<StakingBalanceResponse>): StakingBalanceResponse => {
        const res = new StakingBalanceResponse();
        if (!obj) {
            return res;
        }
        res.claimableRewards = obj.claimableRewards ? new BigNumber(obj.claimableRewards) : res.claimableRewards;
        res.currentWalletBalance = obj.currentWalletBalance ? new BigNumber(obj.currentWalletBalance) : res.currentWalletBalance;
        res.userActiveStake = obj.userActiveStake ? new BigNumber(obj.userActiveStake) : res.userActiveStake;
        res.userActiveStakeWithinPeriod = obj.userActiveStakeWithinPeriod
            ? new BigNumber(obj.userActiveStakeWithinPeriod)
            : res.userActiveStakeWithinPeriod;
        res.userUndelegatedStake = obj.userUndelegatedStake ? new BigNumber(obj.userUndelegatedStake) : res.userUndelegatedStake;
        return res;
    };
}

export class EarningBalanceResponse {
    claimedRewards: BigNumber | null = new BigNumber(0);
    relegatedRewards: BigNumber | null = new BigNumber(0);

    static mapResponse = (obj: Partial<EarningBalanceResponse>): EarningBalanceResponse => {
        const res = new EarningBalanceResponse();
        if (!obj) {
            return res;
        }

        res.claimedRewards = obj.claimedRewards ? new BigNumber(obj.claimedRewards) : res.claimedRewards;
        res.relegatedRewards = obj.relegatedRewards ? new BigNumber(obj.relegatedRewards) : res.relegatedRewards;
        return res;
    };
}

export const stakingReportInitial: StakingReportsEntity = {
    totalBalance: null,
    outBound: null,
    swaps: null,
    currentBalance: null,
    currentStakingBalance: null,
    totalInStake: null,
    claimedStaking: null,
    totalStakingRewards: null,
    reDelegateStaking: null,
    claimableRewards: null,
    totalInStakeWithinPeriod: null,
    userUndelegatedStake: null,
};
