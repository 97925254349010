import React, { FC } from "react";
import styled from "styled-components";

const PointerSpan = styled.span`
    cursor: pointer;
`;

export type GreenTextProps = React.HTMLAttributes<HTMLSpanElement>;

const EText: FC<React.PropsWithChildren<GreenTextProps>> = ({ className = "", children, ...props }) => {
    return (
        <PointerSpan {...props} className={`${className} text-green`}>
            {children}
        </PointerSpan>
    );
};

export default EText;
