import React, { useState } from "react";
import { BackgroundDecorations } from "../../../components/BackgroundDecorations";
import { Card, Container, Form } from "react-bootstrap";
import styles from "../../UnlockPage/login.module.scss";
import { useForm } from "react-hook-form";
import { FormControlGroup, SettingsButton, useYupValidationResolver } from "../../../common";
import * as yup from "yup";
import ValidationSchemaUtils from "../../../common/utils/ValidationSchemaUtils";
import Col from "react-bootstrap/Col";
import Row from "react-bootstrap/Row";
import styled from "styled-components";
import { frostyEffect } from "../../../assets/sass/common-effects";
import { BORDER_RADIUS } from "../../../components/StepsSlider/StepsSlider";
import { BREAKPOINTS } from "../../../assets/sass/breakpoints";
import { useMutation } from "@tanstack/react-query";
import { useService } from "../../../services/config/dependencyInjectorConfig";
import AuthService, { AuthDataDto } from "../../../services/AuthService";
import { SubmitData } from "./AuthLogin";
import AuthSubmitDataMessage from "./AuthSubmitDataMessage";

const RegisterCustomCard = styled(Card)`
    ${frostyEffect};
    color: white;
    border-radius: ${BORDER_RADIUS};
    border-width: 0;
    padding-bottom: 0.929rem;
    justify-content: center;
    width: 450px;

    @media (${BREAKPOINTS.tablet}) {
        width: 400px;
        padding-bottom: 1.571rem;
    }

    @media (${BREAKPOINTS.phone}) {
        min-width: 300px;
        padding-bottom: 1.571rem;
    }
`;

const getValidationSchema = () =>
    yup.object({
        email: ValidationSchemaUtils.email,
        password: ValidationSchemaUtils.password,
        confirmPassword: yup
            .string()
            .required()
            .oneOf([yup.ref("password"), null], "Passwords must match"),
    });

export interface LoginData {
    email: string;
    password: string;
    confirmPassword: string;
}

const AuthRegister = () => {
    const [submitDataMessage, setSubmitDataMessage] = useState<SubmitData | null>(null);
    const [authService] = useService(AuthService);
    const validationResolver = useYupValidationResolver(getValidationSchema());
    const { register, handleSubmit, formState } = useForm<LoginData>({
        resolver: validationResolver,
    });

    const registerAccount = useMutation({
        mutationFn: (registerAccountDto: AuthDataDto) => authService.registerAccount(registerAccountDto),
        onSuccess: () => {
            setSubmitDataMessage({
                type: "success",
                message: "Account was successfully registered. A confirmation email has been to the provided email.",
            });
        },
        onError: (error) => {
            setSubmitDataMessage({ type: "error", message: "An error occurred." });
        },
    });

    const onDetailsChangeHandler = (data: LoginData) => {
        if (data.password === data.confirmPassword) {
            registerAccount.mutate({ email: data.email, password: data.password });
        } else {
            setSubmitDataMessage({ type: "error", message: "Passwords do not match." });
        }
    };

    return (
        <div className="mt-5">
            <BackgroundDecorations />
            <Container className={styles.unlock}>
                <RegisterCustomCard>
                    <h2 className="text-center">Create an account</h2>
                    <AuthSubmitDataMessage submitDataMessage={submitDataMessage} />
                    <Form className="mt-2 d-flex flex-column" onSubmit={handleSubmit(onDetailsChangeHandler)}>
                        <Row>
                            <Col xs={12} md={12}>
                                <FormControlGroup
                                    name="email"
                                    label="Email:"
                                    register={register}
                                    formState={formState}
                                    required
                                />
                                <FormControlGroup
                                    name="password"
                                    label="Password:"
                                    type="password"
                                    register={register}
                                    formState={formState}
                                    required
                                />
                                <FormControlGroup
                                    name="confirmPassword"
                                    label="Password Confirmation:"
                                    type="password"
                                    register={register}
                                    formState={formState}
                                    required
                                />
                            </Col>
                        </Row>
                        <SettingsButton className="mt-4 w-100" type="submit" testId="set-details">
                            Save
                        </SettingsButton>
                    </Form>
                </RegisterCustomCard>
            </Container>
        </div>
    );
};

export default AuthRegister;
