import { LaunchInfo, Quarters } from "../ico.types";
import NumberComponent from "../../../components/Price/Number";
import { IcoLayersHelper, PROJECT_DATE_FORMAT_MOMEMNT_JS, TBA, UNCAPPED } from "../../../helpers/consts";
import { DateUtils } from "../../../common";
import React from "react";
import { TQuarterInfo } from "../../../common/form/types";
import * as yup from "yup";
import { ValidationError } from "yup";
import CurrencyNumber from "../../../components/Price/CurrencyNumber";

export const getLaunchInfo = ({
    icoLayer,
    tokenName = "",
    maxTokenSupply,
    preSaleStartTimestamp,
    preSaleStartQuarter = undefined,
    publicSaleStartTimestamp,
    publicSaleStartQuarter = undefined,
    publicSalePrice,
    hasKyc = false,
}: LaunchInfo) => [
    {
        key: "Category",
        value: icoLayer ? IcoLayersHelper.toLabel(icoLayer) : TBA,
    },
    {
        key: "Token",
        value: tokenName,
    },
    {
        key: "Total Token Supply",
        value:
            maxTokenSupply && maxTokenSupply > 1 ? (
                <NumberComponent value={Number(maxTokenSupply)} />
            ) : (
                [maxTokenSupply === 0 ? UNCAPPED : TBA]
            ),
    },
    {
        key: "Presale Date",
        value: preSaleStartQuarter
            ? preSaleStartQuarter
            : preSaleStartTimestamp
            ? DateUtils.timestampToDateFormatted(preSaleStartTimestamp, PROJECT_DATE_FORMAT_MOMEMNT_JS)
            : TBA,
    },
    {
        key: "Public Sale Start",
        value: publicSaleStartQuarter
            ? publicSaleStartQuarter
            : publicSaleStartTimestamp
            ? DateUtils.timestampToDateFormatted(publicSaleStartTimestamp, PROJECT_DATE_FORMAT_MOMEMNT_JS)
            : TBA,
    },
    {
        key: "Public Sale Token Price",
        value: publicSalePrice ? <CurrencyNumber value={publicSalePrice} /> : TBA,
    },
    {
        key: "KYC",
        value: hasKyc ? "Yes" : "No",
    },
];

export const icoUpdatedTooltipText = (createdAt: number, updatedAt?: number) => {
    if (updatedAt) {
        return icoLastUpdatedText(updatedAt);
    } else if (createdAt) {
        return icoPostedText(createdAt);
    } else {
        return "";
    }
};

export const icoLastUpdatedText = (updatedAt: number) => {
    return `Last updated: ${DateUtils.toFormat(updatedAt, "MMM. DD, Y")}`;
};

export const icoPostedText = (createdAt: number) => {
    return `Posted: ${DateUtils.toFormat(createdAt, "MMM. DD, Y")}`;
};

export const isValidQuarter = (quarterInfo: TQuarterInfo) => {
    return (
        quarterInfo.quarter &&
        Object.keys(Quarters).includes(quarterInfo.quarter) &&
        quarterInfo.year &&
        Number(quarterInfo.year) > 0
    );
};

export const validateQuarters = (
    quarter: string | undefined,
    year: string | undefined,
    ctx: yup.TestContext<Record<string, unknown>>,
    message: string,
    useDate?: boolean,
): boolean | ValidationError => {
    if (useDate) {
        return true;
    }
    if ((!quarter || quarter.toLowerCase() === "none") && (!year || year.toLowerCase() === "none")) {
        return true;
    }

    if (!isValidQuarter({ quarter: quarter as Quarters, year })) {
        return ctx.createError({ message });
    }
    return true;
};

export const getQueryPage = (
    pageParam: string,
    firstPage: number,
    params: URLSearchParams,
    { totalRows, perPage }: { totalRows: number; perPage: number },
) => {
    const paramsPage = params.has(pageParam) ? Number(params.get(pageParam)) : firstPage;
    const pagesCount = Math.ceil(totalRows / perPage);

    return paramsPage <= pagesCount && paramsPage > 0 ? paramsPage : firstPage;
};
