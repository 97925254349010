import React, { FC, memo } from "react";
import EButton, { EButtonProps } from "./Button";
import OverlayTriggerTooltip from "../../helpers/OverlayTriggerTooltip";

export interface ETooltipButtonProps extends EButtonProps {
    showTooltip?: boolean;
    cursor?: "pointer" | "not-allowed";
    tooltipText: string;
}

const ETooltipButton: FC<React.PropsWithChildren<ETooltipButtonProps>> = ({
    showTooltip,
    tooltipText,
    cursor = "pointer",
    children,
    ...props
}) => {
    if (showTooltip) {
        return (
            <OverlayTriggerTooltip placement="top" tooltipText={tooltipText} cursor={cursor} inline>
                <EButton {...props}>{children}</EButton>
            </OverlayTriggerTooltip>
        );
    }
    return <EButton {...props}>{children}</EButton>;
};

export default memo(ETooltipButton);
