export enum LocalStorageKeys {
    dappCoreLoginExpiresAt = "sdk-dapp-login-expires-at",
    isInformedAboutConditions = "isInformedAboutConditions",
    userSettings = "userSettings",
    entityUserAuthToken = "entityUserAuthToken",
    settingsWallets = "settingsWallets",
    isSessionAlive = "isSessionAlive",
    persistSdk = "persist:sdk-dapp-store",
    ldmSaleOffer = "ldmSaleOffer",
    ldmSaleStatus = "ldmSaleStatus",
}
