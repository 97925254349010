import FormControlledInputGroup, { FormControlGroupProps } from "./FormControlledInputGroup";
import { DatePickerComponent } from "@syncfusion/ej2-react-calendars";
import { DateUtils } from "../index";
import React from "react";
import { FormGroupProps } from "./types";
import { DATE_FORMAT_US } from "../../helpers/consts";

interface FormDatePickerProps<T> extends FormGroupProps<T>, Pick<FormControlGroupProps<T>, "control"> {
    value: string | number | Date;
    min?: string | Date;
    max?: string | Date;
    format?: string;
    placeholder?: string;
    allowEdit?: boolean;
}

function FormDatePickerGroup<T>(props: FormDatePickerProps<T>): JSX.Element {
    const {
        name,
        label,
        labelTooltipText,
        value,
        min,
        max,
        disabled,
        format = DATE_FORMAT_US,
        placeholder = "Select date",
        allowEdit = false,
        className = "flex-column",
        control,
        register,
        formState,
        required,
    } = props;

    return (
        <FormControlledInputGroup
            name={name}
            label={label}
            labelTooltipText={labelTooltipText}
            disabled={disabled}
            register={register}
            control={control}
            className={className}
            formState={formState}
            required={required}
            render={({ onChange }) => (
                <DatePickerComponent
                    name={name}
                    placeholder={placeholder}
                    allowEdit={allowEdit}
                    disabled={disabled}
                    value={DateUtils.convertToDate(value)}
                    min={DateUtils.convertToOptionalDate(min)}
                    max={DateUtils.convertToOptionalDate(max)}
                    change={(date) => onChange(DateUtils.toDateString(date.value))}
                    format={format}
                    openOnFocus
                    showClearButton
                    showTodayButton
                />
            )}
        />
    );
}

export default FormDatePickerGroup;
