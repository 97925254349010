import React from "react";
import styled from "styled-components";

const ProjectInfoCardTitle = styled.div`
    color: var(--light-gray);
    padding-bottom: 1rem;
    margin-bottom: 0;

    h2 {
        color: white;
        margin-bottom: 0;
    }
`;

export interface ProjectCardTitleProps {
    titleText: string;
    titleAdditionalInfo?: JSX.Element;
    className?: string;
}

const ProjectCardTitle = ({ titleText, titleAdditionalInfo, className = "" }: ProjectCardTitleProps) => {
    return (
        <ProjectInfoCardTitle className={`d-flex align-items-center justify-content-between ${className}`}>
            <h2>{titleText}</h2>
            {titleAdditionalInfo}
        </ProjectInfoCardTitle>
    );
};
export default ProjectCardTitle;
