import React, { FC } from "react";
import EventContent, { EventContentProps } from "./EventContent";
import Event from "./Event";
import TimelinePlaceholder from "./Placeholder";

export type TimelineEventProps = {
    header: string;
    isChecked?: boolean;
    subEvents?: string[];
    lastChecked?: boolean;
};

export interface TimelineProps extends EventContentProps {
    events: TimelineEventProps[];
    showPlaceholder?: boolean;
    testId?: string;
}

const Timeline: FC<React.PropsWithChildren<TimelineProps>> = ({
    events,
    variant = "aligned-sub-events",
    editable = false,
    editCallback,
    showPlaceholder = false,
    testId = "timeline",
}): JSX.Element => {
    const styledEvents = events;
    if (styledEvents.length > 0) {
        const lastCheckedIndex = events.findIndex((e) => e.isChecked === false) - 1;
        if (lastCheckedIndex >= 0) {
            styledEvents[lastCheckedIndex]["lastChecked"] = true;
        }
    }

    return (
        <div data-testid={testId}>
            {!showPlaceholder &&
                styledEvents.map((item, index) => (
                    <Event item={item} key={index}>
                        <EventContent
                            item={item}
                            index={index}
                            variant={variant}
                            editable={editable}
                            editCallback={editCallback}
                        />
                    </Event>
                ))}
            {showPlaceholder && (
                <>
                    <Event>
                        <TimelinePlaceholder />
                    </Event>
                    <Event>
                        <TimelinePlaceholder />
                    </Event>
                </>
            )}
        </div>
    );
};

export default Timeline;
