import { ContextState } from "./state";
import LocalStorageService from "../services/localStorageService/LocalStorageService";
import { LocalStorageKeys } from "../services/localStorageService/LocalStorageKeys";

export type ContextAction =
    | {
          type: "setJwtToken";
          jwtToken: ContextState["jwtToken"];
      }
    | {
          type: "setUserSettings";
          userSettings: ContextState["userSettings"];
      }
    | {
          type: "setSettingsWallets";
          settingsWallets: ContextState["settingsWallets"];
      }
    | {
          type: "setAuthenticationData";
          jwtToken: ContextState["jwtToken"];
          userSettings: ContextState["userSettings"];
      };

export function reducer(state: ContextState, action: ContextAction): ContextState {
    switch (action.type) {
        case "setJwtToken": {
            const { jwtToken } = action;

            jwtToken
                ? LocalStorageService.setItem(LocalStorageKeys.entityUserAuthToken, jwtToken)
                : LocalStorageService.removeItem(LocalStorageKeys.entityUserAuthToken);

            return {
                ...state,
                jwtToken,
            };
        }
        case "setUserSettings": {
            const { userSettings } = action;
            return {
                ...state,
                userSettings,
            };
        }
        case "setSettingsWallets": {
            const { settingsWallets } = action;
            LocalStorageService.setItem(LocalStorageKeys.settingsWallets, settingsWallets);
            return {
                ...state,
                settingsWallets,
            };
        }
        case "setAuthenticationData": {
            const { jwtToken, userSettings } = action;
            jwtToken
                ? LocalStorageService.setItem(LocalStorageKeys.entityUserAuthToken, jwtToken)
                : LocalStorageService.removeItem(LocalStorageKeys.entityUserAuthToken);

            return {
                ...state,
                jwtToken,
                userSettings,
            };
        }
        default: {
            throw new Error(`Unhandled action type: ${action}`);
        }
    }
}
