import React from "react";
import styled from "styled-components";
import { routeNames } from "../../routes/routes";
import { Link } from "react-router-dom";
import { EButton } from "../Buttons";

const Container = styled.div`
    position: fixed;
    left: 0;
    right: 0;
    bottom: 0;
    min-height: 6rem;
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 100;
    padding: 0.5rem;
`;

const Background = styled.div`
    position: absolute;
    width: 100%;
    height: 100%;
    background-color: var(--dark-gray);
    opacity: 0.8;
    z-index: -1;
`;

const Text = styled.div`
    height: 100%;
    margin-right: 1rem;
    color: white;
    position: relative;
`;

const NewLink = styled(Link)`
    color: #85fc8c;
`;

const Banner = ({ onConfirmSeeingConditions }: { onConfirmSeeingConditions: () => void }) => {
    return (
        <Container data-testid="conditions-banner">
            <Background />
            <Text>
                We use cookies to improve our services for you. By using our website you accept the&nbsp;
                <NewLink to={routeNames.termsAndConditions} data-testid="terms-and-conditions">
                    Terms of use
                </NewLink>
                &nbsp;and the&nbsp;
                <NewLink to={routeNames.privacyPolicy} data-testid="privacy-policy">
                    Privacy Policy
                </NewLink>
            </Text>
            <EButton onClick={onConfirmSeeingConditions} testId="ok">
                OK
            </EButton>
        </Container>
    );
};

export default Banner;
