import React from "react";
import styled from "styled-components";

type Position = "left" | "right" | "center";

interface CloudProps {
    className?: string;
    color?: string;
    position?: Position;
    width?: number | string;
    height?: number | string;
    matrix?: [number, number, number, number, number, number];
    opacity?: number;
    translateAttr?: [number, number];
    animation?: boolean;
}

type ElementProps = Required<Omit<Omit<CloudProps, "position">, "className">> & {
    position: Position;
};

const MainCloud = styled.div`
    position: absolute;
    left: calc(
        20% +
            ${(props: ElementProps) =>
                `${
                    props.position == "left"
                        ? typeof props.width == "string"
                            ? `calc(${props.width} * -0.425)`
                            : `calc(${props.width}px * -0.425)`
                        : props.position == "right"
                        ? typeof props.width == "string"
                            ? `calc(${props.width} * 0.425)`
                            : `calc(${props.width}px * 00.425)`
                        : typeof props.width == "string"
                        ? "0px"
                        : "0px"
                }`}
    );
    top: 50%;
    width: ${(props: ElementProps) => `${typeof props.width === "string" ? props.width : props.width + "px"}`};
    height: ${(props: ElementProps) => `${typeof props.height === "string" ? props.height : props.height + "px"}`};
    border-radius: 50%;
    background: ${(props: ElementProps) => props.color} 0 0 no-repeat padding-box;
    filter: blur(39px);
    opacity: ${({ opacity }: ElementProps) => opacity};
`;

const CloudContainer = styled.div`
    position: absolute;
    overflow: visible;
`;

const Cloud = ({
    className = "",
    color = "#1D5E5D",
    position = "center",
    width = 450,
    height = 250,
    matrix = [-1, 0.03, -0.03, -1, 0, 0],
    opacity = 0.6,
    translateAttr = [30, 50],
    animation = false,
}: CloudProps) => {
    return (
        <CloudContainer
            className={className}
            style={{
                height: `calc(${typeof height == "string" ? height : height + "px"} + 270px)`,
                width: `calc(${typeof width == "string" ? width : width + "px"} + 320px)`,
            }}
        >
            <MainCloud
                color={color}
                width={width}
                height={height}
                matrix={matrix}
                opacity={opacity}
                translateAttr={translateAttr}
                position={position}
                animation={animation}
            />
        </CloudContainer>
    );
};

export default Cloud;
