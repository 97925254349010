import BaseService from "./BaseService";
import { User } from "../common/types/userTypes";
import { UserRoles } from "../common";
import { SuperUserRoles } from "../common/types/rolesTypes";
import axios from "axios";

export interface UserRolesEncryption {
    userId: string;
    roles: string[];
}

export interface AuthDataDto {
    email: string;
    password: string;
}

export interface EmailConfirmationDto {
    hash: string;
}

export class AuthService extends BaseService {
    private readonly _entityApi: string;

    constructor(entityApi: string) {
        super();
        this._entityApi = entityApi;
    }

    authenticateAccount(authDto: AuthDataDto): Promise<any> {
        return this.doCall(() => axios.post(`${this._entityApi}/auth/email`, authDto));
    }

    registerAccount(authRegisterDto: AuthDataDto): Promise<void> {
        return this.doCall(() => axios.post(`${this._entityApi}/auth/email/register`, authRegisterDto));
    }

    confirmEmail(emailConfirmationDto: EmailConfirmationDto): Promise<any> {
        return this.doCall(() => axios.post(`${this._entityApi}/auth/email/confirm`, emailConfirmationDto));
    }

    public checkUserRole<T>(roles: T[], user: User): boolean {
        if (!user) {
            return false;
        }
        return user.roles ? user.roles.filter((userRole) => roles.includes(userRole as unknown as T)).length > 0 : false;
    }

    public isAdmin(user: User): boolean {
        return this.checkUserRole([UserRoles.admin], user);
    }

    public isLaunchpadAdmin(user: User): boolean {
        return this.checkUserRole([SuperUserRoles.launchpadAdmin], user);
    }

    public isModeratorOrAdmin(user: User): boolean {
        return this.checkUserRole([UserRoles.moderator, UserRoles.admin], user);
    }
}

export default AuthService;
