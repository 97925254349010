import { PublicStepConfig, PublicStepTypes } from "onfido-sdk-ui";
import { CustomNetworkType } from "@multiversx/sdk-dapp/types";

export const decimals = 4;
export const denomination = 18;
export const defaultGasPrice = 1_000_000_000;
export const defaultGasLimit = 60_000_000;

export const nftCollectionTotal = 1000;
export const nftContractBuy = "erd1qqqqqqqqqqqqqpgqkv20h6ezdsufcpzjq7td9n29s70lzxz044psyv6lsy";
export const displayReactQueryDevTools = false;
export const entitySiteUrl = "https://dev.entity.global";
export const entityPresentationSiteUrl = "https://entity.global";
export const entityApi = "https://dev.entity.global/api";
export const launchpadApi = "https://dev.entity.global/launchpad-api";
export const maintenanceMode = false;
export const gdprCipherIv = "16";
export const gdprCipherAlgorithm = "aes-256-cbc";
export const gdprCipherPassword = "juBvsdWqsjknTuDLmAsungJnysDBaqPA";
export const launchpadSC = "erd1qqqqqqqqqqqqqpgqh52mmtl569vswhqgjesxewn8atukk4k0jrxsg47hjh";
export const ldmSC = "erd1qqqqqqqqqqqqqpgq6ddzggha4j7mufkh4en538ryckf9lm7mjrxsdq88cc";

export const awsS3BaseURL = "https://entity-ico-test.s3.eu-central-1.amazonaws.com/";
export const onfidoURL = "https://dashboard.onfido.com/applicant/";
export const idenfyURL = "https://ui.idenfy.com";

export const maiarTokensApi = "https://maiartokens.com";
export const entityLogoURL = "https://entity.global/logo192.png";

export const recaptchaKey = {
    siteKey: "6Le9HqYfAAAAACDCbLkJC8I-EBidRfQVTeKlARLK",
    secretKey: "6Le9HqYfAAAAALXEI_kfWLCPv1LLwaPj7TOQxSNc",
};

export const newsLetter = {
    loadScriptUrl: "//s3.amazonaws.com/downloads.mailchimp.com/js/mc-validate.js",
    formSubscriptionUrl:
        "https://global.us21.list-manage.com/subscribe/post?u=ca8faf50e1fa43aea371469b0&amp;id=dddb8a16c6&amp;f_id=0039d0e1f0",
    securityInput: "b_ca8faf50e1fa43aea371469b0_dddb8a16c6",
};

export const walletConnectV2ProjectId = "9b1a9564f91cb659ffe21b73d5c4e2d8";
export const onfidoBaseSteps: Array<PublicStepTypes | PublicStepConfig> = ["welcome", "document", "face", "complete"];
export const onfidoAdvancedSteps: Array<PublicStepTypes | PublicStepConfig> = ["welcome", "poa", "document", "face", "complete"];

export const network = {
    id: "devnet",
    name: "Devnet",
    chainId: "D",
    egldLabel: "xEGLD",
    walletAddress: "https://devnet-wallet.multiversx.com",
    apiAddress: "https://devnet-api.multiversx.com",
    dataApiAddress: "https://data.multiversx.com/latest",
    gatewayAddress: "https://devnet-gateway.multiversx.com",
    explorerAddress: "http://devnet-explorer.multiversx.com",
    graphQlAddress: "https://devnet-exchange-graph.multiversx.com/graphql",
};

export const customNetworkConfig: CustomNetworkType = {
    name: "customConfig",
    apiTimeout: "30000",
    walletConnectV2ProjectId,
    skipFetchFromServer: true,
    chainId: network.chainId,
    egldLabel: network.egldLabel,
    decimals: "18",
    digits: "4",
    gasPerDataByte: "1500",
    walletConnectDeepLink:
        "https://maiar.page.link/?apn=com.multiversx.maiar.wallet&isi=1519405832&ibi=com.multiversx.maiar.wallet&link=https://maiar.com/",
    walletConnectBridgeAddresses: ["https://bridge.walletconnect.org"],
    apiAddress: network.apiAddress,
    walletAddress: network.walletAddress,
    explorerAddress: network.explorerAddress,
};
