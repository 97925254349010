import axios from "axios";
import { StakingProvider, StakingProviderMetricsResponse } from "../common/types/stakingProiderTypes";
import BaseService from "./BaseService";

class StakingProvidersService extends BaseService {
    private readonly _entityApi: string;

    constructor(entityApi: string) {
        super();
        this._entityApi = entityApi;
    }

    public retrieveAll(): Promise<StakingProvider[]> {
        return this.doCall<StakingProvider[]>(() => axios.get(`${this._entityApi}/staking-providers`));
    }

    public retrievePeriodStartDate(): Promise<number | undefined> {
        return this.doCall<number | undefined>(() => axios.get(`${this._entityApi}/staking-providers/period/start`));
    }

    public retrieveProviderMetrics(
        dateStart: number,
        dateEnd: number,
        contract: string,
    ): Promise<StakingProviderMetricsResponse[]> {
        return this.doCall<StakingProviderMetricsResponse[]>(() =>
            axios.get(`${this._entityApi}/staking-providers/period`, { params: { dateStart, dateEnd, contract } }),
        );
    }
}

export default StakingProvidersService;
