import React, { useEffect } from "react";
import { UseFormReturn } from "react-hook-form";
import { Col, Row } from "react-bootstrap";
import { ValidationSchemaUtils } from "../../../common";
import { IcoStepThreeInputs } from "../IcoStepThree";
import * as yup from "yup";
import {
    DISCORD_URL_REGEX,
    FACEBOOK_URL_REGEX,
    GITHUB_URL_REGEX,
    TELEGRAM_URL_REGEX,
    URL_REGEX,
} from "../../../common/utils/RegexUtils";
import { ENTER_WEBSITE_MSG, INCORRECT_URL_MSG } from "../../../common/utils/ValidationSchemaUtils";
import { IcoEditFieldsStepThreeHelper } from "./helper";

// eslint-disable-next-line @typescript-eslint/no-explicit-any
interface IcoEditStepThreeProps<T extends IcoStepThreeInputs> extends Pick<UseFormReturn<any>, "register" | "formState"> {
    ico: T;
    displayCompact?: boolean;
}

export function getIcoStepThreeValidationSchema() {
    return {
        links: yup.object().shape({
            litepaper: ValidationSchemaUtils.emptyAsNull.matches(URL_REGEX, INCORRECT_URL_MSG),
            website: yup.string().matches(URL_REGEX, INCORRECT_URL_MSG).required(ENTER_WEBSITE_MSG),
            facebook: ValidationSchemaUtils.emptyAsNull.matches(FACEBOOK_URL_REGEX, INCORRECT_URL_MSG),
            telegram: ValidationSchemaUtils.emptyAsNull.matches(TELEGRAM_URL_REGEX, INCORRECT_URL_MSG),
            discord: ValidationSchemaUtils.emptyAsNull.matches(DISCORD_URL_REGEX, INCORRECT_URL_MSG),
            github: ValidationSchemaUtils.emptyAsNull.matches(GITHUB_URL_REGEX, INCORRECT_URL_MSG),
            medium: ValidationSchemaUtils.emptyAsNull.matches(URL_REGEX, INCORRECT_URL_MSG),
            whitelist: ValidationSchemaUtils.emptyAsNull.matches(URL_REGEX, INCORRECT_URL_MSG),
            whitepaper: ValidationSchemaUtils.emptyAsNull.matches(URL_REGEX, INCORRECT_URL_MSG),
            tokenomics: ValidationSchemaUtils.emptyAsNull.matches(URL_REGEX, INCORRECT_URL_MSG),
        }),
    };
}

const IcoEditStepThree = <T extends IcoStepThreeInputs>({
    ico,
    register,
    formState,
    displayCompact = false,
}: IcoEditStepThreeProps<T>): JSX.Element => {
    useEffect(() => {
        window.scrollTo(0, 0);
    }, []);

    const icoEditFieldsHelper = new IcoEditFieldsStepThreeHelper(register, formState, ico);

    return (
        <>
            {displayCompact && (
                <>
                    <Row>
                        <Col xs={12} md={4}>
                            {icoEditFieldsHelper.getLitepaperLinkElement()}
                        </Col>
                        <Col xs={12} md={4}>
                            {icoEditFieldsHelper.getWebsiteLinkElement()}
                        </Col>
                        <Col xs={12} md={4}>
                            {icoEditFieldsHelper.getFacebookLinkElement()}
                        </Col>
                    </Row>

                    <Row>
                        <Col xs={12} md={4}>
                            {icoEditFieldsHelper.getTwitterLinkElement()}
                        </Col>
                        <Col xs={12} md={4}>
                            {icoEditFieldsHelper.getTelegramLinkElement()}
                        </Col>
                        <Col xs={12} md={4}>
                            {icoEditFieldsHelper.getDiscordLinkElement()}
                        </Col>
                    </Row>
                    <Row>
                        <Col xs={12} md={4}>
                            {icoEditFieldsHelper.getGithubLinkElement()}
                        </Col>
                        <Col xs={12} md={4}>
                            {icoEditFieldsHelper.getMediumLinkElement()}
                        </Col>
                        <Col xs={12} md={4}>
                            {icoEditFieldsHelper.getWhiteListLinkElement()}
                        </Col>
                    </Row>
                    <Row>
                        <Col xs={12} md={4}>
                            {icoEditFieldsHelper.getWhitePaperLinkElement()}
                        </Col>
                        <Col xs={12} md={4}>
                            {icoEditFieldsHelper.getTokenomicsLinkElement()}
                        </Col>
                    </Row>
                </>
            )}
            {!displayCompact && (
                <>
                    <Row>
                        <Col xs={12} md={6}>
                            {icoEditFieldsHelper.getLitepaperLinkElement()}
                        </Col>
                        <Col xs={12} md={6}>
                            {icoEditFieldsHelper.getWebsiteLinkElement()}
                        </Col>
                    </Row>
                    <Row>
                        <Col xs={12} md={6}>
                            {icoEditFieldsHelper.getFacebookLinkElement()}
                        </Col>
                        <Col xs={12} md={6}>
                            {icoEditFieldsHelper.getTwitterLinkElement()}
                        </Col>
                    </Row>
                    <Row>
                        <Col xs={12} md={6}>
                            {icoEditFieldsHelper.getTelegramLinkElement()}
                        </Col>
                        <Col xs={12} md={6}>
                            {icoEditFieldsHelper.getDiscordLinkElement()}
                        </Col>
                    </Row>
                    <Row>
                        <Col xs={12} md={6}>
                            {icoEditFieldsHelper.getGithubLinkElement()}
                        </Col>
                        <Col xs={12} md={6}>
                            {icoEditFieldsHelper.getMediumLinkElement()}
                        </Col>
                    </Row>
                    <Row>
                        <Col xs={12} md={6}>
                            {icoEditFieldsHelper.getWhiteListLinkElement()}
                        </Col>
                        <Col xs={12} md={6}>
                            {icoEditFieldsHelper.getWhitePaperLinkElement()}
                        </Col>
                    </Row>
                    <Row>
                        <Col xs={12} md={12}>
                            {icoEditFieldsHelper.getTokenomicsLinkElement()}
                        </Col>
                    </Row>
                </>
            )}
        </>
    );
};

export default IcoEditStepThree;
