import axios from "axios";
import { Ico, IcoRatings, IcoRatingsAndReview, IcoReviewRatings } from "../common/types/icoTypes";
import BaseService from "./BaseService";
import { IcoStepOneInputs } from "../pages/Ico/IcoStepOne";
import { IcoStepTwoInputs } from "../pages/Ico/IcoStepTwo";
import { IcoStepThreeInputs } from "../pages/Ico/IcoStepThree";
import { IcoSummary, UpcomingProject } from "../pages/Ico/ico.types";
import { IcoFilter } from "../pages/Ico/IcoList";
import { IcosVotesTypes } from "../components/Votes/Votes";

export interface IcoListResponse {
    data: Ico[];
    total: number;
}

class IcoService extends BaseService {
    private readonly _urlPrefix: string;
    private readonly _urlPrefixVotes: string;

    constructor(entityApi: string) {
        super();
        this._urlPrefix = `${entityApi}/ico`;
        this._urlPrefixVotes = `${entityApi}/ico-votes`;
    }

    public async saveIco(ico: Partial<IcoStepOneInputs> & Partial<IcoStepTwoInputs> & Partial<IcoStepThreeInputs>): Promise<Ico> {
        return this.doCall(() => axios.post<Ico>(this._urlPrefix, ico));
    }

    public async updateIco(ico: Ico): Promise<Ico> {
        return this.doCall(() => axios.put<Ico>(`${this._urlPrefix}/${ico.id}`, ico));
    }

    public async markAsHotListed(id: string): Promise<void> {
        return this.doCall(() => axios.put<void>(`${this._urlPrefix}/${id}/hot-list`));
    }

    public retrieveAll(params: IcoFilter): Promise<IcoListResponse> {
        return this.doCall<IcoListResponse>(() => axios.get(this._urlPrefix, { params }));
    }

    public retrieveUserICOs(): Promise<Ico[]> {
        return this.doCall<Ico[]>(() => axios.get(`${this._urlPrefix}/personal`));
    }

    public retrieveById(id: string): Promise<Ico> {
        return this.doCall<Ico>(() => axios.get(`${this._urlPrefix}/${id}`));
    }

    public retrieveIcoSummary(id: string): Promise<IcoSummary> {
        return this.doCall<IcoSummary>(() => axios.get(`${this._urlPrefix}/${id}/summary`));
    }

    public retrieveIcoReviewRatings(id: string): Promise<Partial<IcoReviewRatings>> {
        return this.doCall<IcoReviewRatings>(() => axios.get(`${this._urlPrefix}/${id}/ratings`));
    }

    public countApprovedICOs(): Promise<number> {
        return this.doCall<number>(() => axios.get(`${this._urlPrefix}/count`));
    }

    public calculateTotalScore(ratings: IcoRatings): Promise<IcoRatings> {
        return this.doCall(() => axios.post<IcoRatings>(`${this._urlPrefix}/calculate-total-score`, ratings));
    }

    public approve(icoId: string, ratingsAndReview: IcoRatingsAndReview): Promise<Ico> {
        return this.doCall(() => axios.put<Ico>(`${this._urlPrefix}/${icoId}/approve`, ratingsAndReview));
    }

    public approveAsScam(icoId: string, ratingsAndReview: IcoRatingsAndReview): Promise<Ico> {
        return this.doCall(() => axios.put<Ico>(`${this._urlPrefix}/${icoId}/approve/scam`, ratingsAndReview));
    }

    public saveAsPending(icoId: string, ratingsAndReview: IcoRatingsAndReview): Promise<Ico> {
        return this.doCall(() => axios.put<Ico>(`${this._urlPrefix}/${icoId}/pending`, ratingsAndReview));
    }

    public decline(icoId: string, ratingsAndReview: IcoRatingsAndReview): Promise<Ico> {
        return this.doCall(() => axios.put<Ico>(`${this._urlPrefix}/${icoId}/decline`, ratingsAndReview));
    }

    public retrievePublicInfo(id: string): Promise<Ico> {
        return this.doCall<Ico>(() => axios.get(`${this._urlPrefix}/${id}/public`));
    }

    public async deleteIcoImage(icoId: string, imageName: string): Promise<void> {
        return this.doCall(() => axios.delete(`${this._urlPrefix}/${icoId}/image`, { params: { imageName } }));
    }

    public getUpcomingProjects(): Promise<UpcomingProject[]> {
        return this.doCall(() => axios.get<UpcomingProject[]>(`${this._urlPrefix}/projects/upcoming`));
    }

    public voteIco(icoId: string): Promise<number> {
        return this.doCall(() => axios.post(`${this._urlPrefixVotes}/${icoId}/vote`));
    }

    public checkUserIcoVotes(icoId: string): Promise<IcosVotesTypes> {
        return this.doCall(() => axios.post(`${this._urlPrefixVotes}/${icoId}/check`));
    }

    public unVoteIco(icoId: string): Promise<number> {
        return this.doCall(() => axios.post(`${this._urlPrefixVotes}/${icoId}/unvote`));
    }
}

export default IcoService;
