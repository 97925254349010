import BaseService from "./BaseService";
import axios from "axios";
import { ProjectSnapshotAddress } from "../pages/Launchpad/launchpad.types";
import { FinalKycStatus, InitKycStatus, KycAccount, KycSubResult } from "../pages/Launchpad/kyc/launchpad.kyc.dtos";
import { Find, Order, PaginationResp } from "../common/types/common.types";
import { KycStatus } from "../pages/Launchpad/kyc/LegalImportKycAccountsModal";
import { KycFlow } from "./KycService";

export interface LaunchpadScConfig {
    winnerSelectionStartBlock: number;
    confirmationStartBlock: number;
    claimStartBlock: number;
}

export interface LaunchpadScFlags {
    wereWinnersSelected: boolean;
    wereTicketsFiltered: boolean;
    hasWinnerSelectionStarted: boolean;
}

export interface SnapshotRegisterResult {
    status: "SUCCESS" | "FAILED";
    details?: string;
}

export interface AddressStakingValue {
    stakingValue: number;
}

export interface FinalKycStatusesOverview {
    kycStatus: FinalKycStatus | null;
    count: string;
}

export interface InitKycStatusesOverview {
    initKycStatus: InitKycStatus | null;
    count: string;
}

export interface KycSubResultOverviewDetails {
    kycSubResult: KycSubResult | null;
    count: string;
}

export interface KycFlowOverviewDetails {
    kycFlow: KycFlow | null;
    count: string;
}

export enum AddressRiskLevel {
    LOW = "low",
    GUARDED = "guarded",
    ELEVATED = "elevated",
    SEVERE = "SEVERE",
}

export interface RiskLevelOverviewDetails {
    level: AddressRiskLevel;
    count: string;
}

export interface KycOverviewDetails {
    finalKycStatuses: FinalKycStatusesOverview[];
    initKycStatuses: InitKycStatusesOverview[];
    kycSubResults: KycSubResultOverviewDetails[];
    kycFlows: KycFlowOverviewDetails[];
    riskLevels: RiskLevelOverviewDetails[];
    totalAccounts: number;
    kycMaxRetries: number;
    kycManualReviewTimeframeHours: number;
}

export interface KycReviewOverview {
    kycSubResult: KycSubResult | null;
    initKycStatus: InitKycStatus;
    count: string;
}

export interface KycReviewOverviewResp {
    overview: KycReviewOverview[];
    totalReviewable: number;
}

export interface LotteryOverview {
    sc?: ScOverview;
    details: DetailedLotteryStatistics;
    countAddresses: CountsLotteryStatistics;
}

export interface ScOverview {
    flags: LaunchpadScFlags;
    config: LaunchpadScConfig;
}

export interface DetailedLotteryStatistics {
    totalTicketsConfirmed: string;
    averageTicketsConfirmed: string;
    stdTicketsConfirmed: string;
    varTicketsConfirmed: string;

    totalWonTickets: string;
    averageWonTickets: string;
    stdWonTickets: string;
    varWonTickets: string;

    totalEgldPaid: string;
    averageEgldPaid: string;
    stdEgldPaid: string;
    varEgldPaid: string;

    totalUsdPaid: string;
    averageUsdPaid: string;
    stdUsdPaid: string;
    varUsdPaid: string;
}

export interface CountsLotteryStatistics {
    total: number;
    atLeastOneBoughtTicket: number;
    atLeastOneBoughtTicketPercentage: number;
    atLeastOneWonTicket: number;
    atLeastOneWonTicketPercentage: number;
    claimedTokens: number;
    claimedTokensPercentage: number;
    areBlacklisted: number;
    areBlacklistedPercentage: number;
}

export declare type OrderDirection = "ASC" | "DESC";

export interface RejectedCategoryReason {
    category: string;
    reason: string;
}

export interface ProjectStagesFlags {
    isSyncedWithSnapshot: boolean;
    isSyncedAfterBuyingTickets: boolean;
    isSyncedAfterWinnerSelection: boolean;
    isSyncedAfterClaimingTokens: boolean;
}

export interface WorldCountry {
    name: string;
    code: string;
    flag: string;
}

export interface ExportDetails {
    type: "legal" | "intern";
    exportedAt: number;
    exportedBy: string;
    startTimestamp?: number;
    endTimestamp?: number;
    includeExportedAccounts?: boolean;
    take?: number;
    findBy?: string;
    findVal?: string | number | boolean;
    orderBy?: string;
    orderDir?: OrderDirection;
    from?: number;
    size?: number;
}

export interface TicketsOverview {
    totalWonTickets: number;
    totalUsdPaid: number;
    totalEgldPaid: number;
}

export interface KycAccountDetails extends KycAccount {
    kycStep: string;
}

export class LaunchpadService extends BaseService {
    private readonly _launchpadBaseApi: string;
    private readonly _registeredTxsHashes: string[] = [];

    constructor(launchpadApi: string) {
        super();
        this._launchpadBaseApi = `${launchpadApi}/projects`;
    }

    getScConfig(projectId: string): Promise<LaunchpadScConfig> {
        return this.doCall(() => axios.get(`${this._launchpadBaseApi}/${projectId}/launchpadState/config`));
    }

    getScFlags(projectId: string): Promise<LaunchpadScFlags> {
        return this.doCall(() => axios.get(`${this._launchpadBaseApi}/${projectId}/launchpadState/flags`));
    }

    getStakingValue(projectId: string): Promise<AddressStakingValue> {
        return this.doCall(() => axios.get(`${this._launchpadBaseApi}/${projectId}/snapshot/staking`));
    }

    retrieveUserSnapshotDetails(projectId: string): Promise<ProjectSnapshotAddress> {
        return this.doCall(() => axios.get(`${this._launchpadBaseApi}/${projectId}/snapshot`));
    }

    registerKycAddressesToSnapshot(projectId: string): Promise<SnapshotRegisterResult> {
        return this.doCall(() => axios.post(`${this._launchpadBaseApi}/${projectId}/snapshot/register-addresses`));
    }

    getKycStatusesOverview(projectId: string): Promise<KycOverviewDetails> {
        return this.doCall(() => axios.get(`${this._launchpadBaseApi}/${projectId}/account/kyc/overview`));
    }

    getReviewableKycOverview(projectId: string): Promise<KycReviewOverviewResp> {
        return this.doCall(() => axios.get(`${this._launchpadBaseApi}/${projectId}/account/kyc/reviewable/overview`));
    }

    getLotteryOverview(projectId: string): Promise<LotteryOverview> {
        return this.doCall(() => axios.get(`${this._launchpadBaseApi}/${projectId}/statistics/lottery/overview`));
    }

    countKycAccountsForLegalExport(
        startTimestamp: number,
        endTimestamp: number,
        includeExportedAccounts: boolean | undefined,
        projectId: string,
    ): Promise<number> {
        return this.doCall(() =>
            axios.get(`${this._launchpadBaseApi}/${projectId}/account/kyc/export/count`, {
                params: { startTimestamp, endTimestamp, includeExportedAccounts },
            }),
        );
    }

    exportLegalKycAccounts(
        startTimestamp: number,
        endTimestamp: number,
        includeExportedAccounts: boolean | undefined,
        includeCurrencyData: boolean | undefined,
        projectId: string,
        take: number | undefined,
    ) {
        return this.doCall(() =>
            axios.get(`${this._launchpadBaseApi}/${projectId}/account/kyc/export`, {
                params: { startTimestamp, endTimestamp, includeExportedAccounts, includeCurrencyData, take },
            }),
        );
    }

    exportInternalKycAccounts(projectId: string, find: Find, order: Order, from: number = 0, take: number | undefined) {
        return this.doCall(() =>
            axios.get(`${this._launchpadBaseApi}/${projectId}/account/kyc/export/internal`, {
                params: { findBy: find.by, findVal: find.val, orderBy: order.by, orderDir: order.dir, from, size: take },
            }),
        );
    }

    importLegalKycAccounts(kycStatus: KycStatus, applicantsIds: string[], projectId: string) {
        return this.doCall(() =>
            axios.post(`${this._launchpadBaseApi}/${projectId}/account/kyc/import`, { kycStatus, applicantsIds }),
        );
    }

    getExportsHistory(projectId: string): Promise<ExportDetails[]> {
        return this.doCall(() => axios.get(`${this._launchpadBaseApi}/${projectId}/exports/history`));
    }

    getAllKycAccountsDetails(
        projectId: string,
        findBy?: string,
        findVal?: string | number | boolean,
        orderBy?: string,
        orderDir?: OrderDirection,
        from?: number,
        size?: number,
        onlyReviewable?: boolean,
    ): Promise<PaginationResp<KycAccountDetails>> {
        return this.doCall(() =>
            axios.get(`${this._launchpadBaseApi}/${projectId}/account/kyc/all`, {
                params: {
                    findBy,
                    findVal,
                    orderBy,
                    orderDir,
                    from,
                    size,
                    onlyReviewable,
                },
            }),
        );
    }

    getAllLotteryStatisticsDetails(
        projectId: string,
        findBy?: string,
        findVal?: string | number | boolean,
        orderBy?: string,
        orderDir?: OrderDirection,
        from?: number,
        size?: number,
    ): Promise<PaginationResp<KycAccount>> {
        return this.doCall(() =>
            axios.get(`${this._launchpadBaseApi}/${projectId}/statistics/lottery/all`, {
                params: {
                    findBy,
                    findVal,
                    orderBy,
                    orderDir,
                    from,
                    size,
                },
            }),
        );
    }

    getRejectedReasons(projectId: string, walletAddress: string): Promise<RejectedCategoryReason[]> {
        return this.doCall(() =>
            axios.get(`${this._launchpadBaseApi}/${projectId}/account/kyc/rejected-reasons/${walletAddress}`),
        );
    }

    getLotteryStagesFlags(projectId: string): Promise<ProjectStagesFlags> {
        return this.doCall(() => axios.get(`${this._launchpadBaseApi}/${projectId}/stages/flags`));
    }

    getWorldCountries(projectId: string): Promise<WorldCountry[]> {
        return this.doCall(() => axios.get(`${this._launchpadBaseApi}/${projectId}/countries`));
    }

    registerTx(txHash: string, timestamp: number, projectId: string) {
        if (this._registeredTxsHashes.includes(txHash)) return;
        this._registeredTxsHashes.push(txHash);
        return this.doCall(() =>
            axios.post(`${this._launchpadBaseApi}/${projectId}/txs/register`, {
                txHash,
                timestamp,
            }),
        );
    }

    getTicketsStatistics(projectId: string): Promise<TicketsOverview> {
        return this.doCall(() => axios.get(`${this._launchpadBaseApi}/${projectId}/statistics/tickets`), undefined, true);
    }

    reviewApplicantKycStatus(
        projectId: string,
        userWallet: string,
        autoKycStatus: InitKycStatus,
        initKycStatus: InitKycStatus,
    ): Promise<void> {
        return this.doCall(() =>
            axios.put(`${this._launchpadBaseApi}/${projectId}/account/kyc/init-status`, {
                userWallet,
                autoKycStatus,
                initKycStatus,
            }),
        );
    }
}
