import { css } from "styled-components";

export const layoutContainer = css`
    background-color: var(--background-color);
    background-repeat: no-repeat;
    background-size: cover;
    background-position: top center;
    min-height: 100vh;
    overflow-x: hidden;
    position: relative;
    z-index: 0;
`;
