import React from "react";
import styled from "styled-components";
import { Row } from "react-bootstrap";
import { ELinkButton } from "../../../components/Buttons";
import { routeNames } from "../../../routes/routes";
import { useService } from "../../../services/config/dependencyInjectorConfig";
import ProjectsService from "../../../services/ProjectsService";
import SuccessfulLaunchesSlider from "./SuccessfulLaunchesSlider";
import { BREAKPOINTS } from "../../../assets/sass/breakpoints";
import { useQuery } from "@tanstack/react-query";

const ContainerRow = styled(Row)`
    padding-top: 2.142rem;
    padding-bottom: 2.142rem;
    margin: 0;
`;

const Header = styled.h2`
    font-size: 1.3rem;
    padding-left: 0 !important;
    @media (${BREAKPOINTS.desktop}) {
        font-size: 2rem;
    }
`;

const SuccessfulLaunches = () => {
    const [projectsService] = useService(ProjectsService);

    const { data } = useQuery({
        queryKey: [`getSuccessfullyLaunchedProjects`],
        queryFn: () => projectsService.retrieveSuccessfulLaunches(),
    });

    return (
        <>
            {data && data.length > 0 && <Header>Successful Launches</Header>}
            <SuccessfulLaunchesSlider launchedProjects={data} />
            <ContainerRow>
                <ELinkButton to={routeNames.projectMonitor} className="m-auto" testId="more">
                    Show all projects
                </ELinkButton>
            </ContainerRow>
        </>
    );
};

export default SuccessfulLaunches;
