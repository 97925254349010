import React from "react";
import styled from "styled-components";

const TabHeaderStyled = styled.div`
    padding-bottom: 2.142rem;
    margin-bottom: 0;

    h2 {
        margin-bottom: 0;
        font-size: 1.314rem;
    }
`;

interface TabHeaderProps {
    title: string;
    className?: string;
}

const Title = ({ title, className = "" }: TabHeaderProps): JSX.Element => {
    return (
        <TabHeaderStyled className={`d-flex align-items-center justify-content-between ${className}`}>
            <h2>{title}</h2>
        </TabHeaderStyled>
    );
};

export default Title;
