import React from "react";
import Sphere from "../../../components/Sphere";
import styled, { css } from "styled-components";
import PageBlockTitle from "../../../components/PageBlockTitle/PageBlockTitle";
import SphereContent from "./SphereContent";
import { Cloud } from "../../../components/Cloud";
import { BREAKPOINTS } from "../../../assets/sass/breakpoints";

const SphereBrightnessEffect = css`
    filter: brightness(100%);

    &:hover {
        animation-name: becoming-bright, stay-bright;
        animation-delay: 0s, 1s;
        animation-duration: 1s;
        animation-iteration-count: 1, infinite;
    }

    @keyframes becoming-bright {
        from {
            filter: brightness(40%);
        }
        to {
            filter: brightness(100%);
        }
    }

    @keyframes stay-bright {
        from {
            filter: brightness(100%);
        }
        to {
            filter: brightness(100%);
        }
    }

    @media (${BREAKPOINTS.tablet}) {
        filter: brightness(40%);
    }
`;

const StyledSphere = styled(Sphere)`
    position: relative;
    margin-left: 0.5rem;
    margin-right: 0.5rem;
    line-height: 1.857rem;
    --diameter: calc(100vw - 1rem) !important;
    --interior-percentage-size: 85 !important;
    ${SphereBrightnessEffect};

    @media (${BREAKPOINTS.tablet}) {
        --diameter: calc(100vw * 2 / 3 - 1rem) !important;
        --interior-percentage-size: 88 !important;
    }

    @media (${BREAKPOINTS.desktop}) {
        --diameter: calc(100vw / 3 - 1rem) !important;
        --interior-percentage-size: 85 !important;
    }
`;

const PageTitle = styled(PageBlockTitle)`
    padding-left: 1rem;
    padding-right: 1rem;
    max-width: 800px;
    text-align: center;

    @media (${BREAKPOINTS.tablet}) {
        padding-left: unset;
        padding-right: unset;
        margin: 0 auto;
    }
`;

const Container = styled.div`
    margin-bottom: 5rem;
    position: relative;

    @media (${BREAKPOINTS.tablet}) {
        margin-bottom: 10.714rem;
    }
`;

const SpheresContainer = styled.div`
    display: flex;
    flex-direction: column;
    align-items: center;
    margin-top: 5rem;

    @media (${BREAKPOINTS.desktop}) {
        flex-direction: row;
    }
`;

const WhyEntityCloud = styled(Cloud)`
    --startups-cloud-width: 25rem;
    --startups-cloud-height: 15rem;
    margin-top: -150px;
    opacity: 0.3;

    @media (${BREAKPOINTS.desktop}) {
        --startups-cloud-width: 35rem;
        --startups-cloud-height: 18rem;
    }
`;

const WhyEntity = () => {
    return (
        <Container id="whyEntity">
            <PageTitle
                title="Why Entity?"
                description="Entity is the central hub for builders and investors alike to navigate the MultiversX ecosystem and achieve growth."
            />
            <SpheresContainer>
                <StyledSphere>
                    <SphereContent
                        title="Onboarding Made Easy"
                        description="In a fast-paced, ever-growing network like MultiversX, new adopters need a hub where they can discover the best projects and investment strategies on the blockchain."
                    />
                </StyledSphere>
                <StyledSphere>
                    <SphereContent
                        title="Growing DeFi on MultiversX"
                        description="While MultiversX has the low fees and scalability to be a top DeFi blockchain, users need a healthy ecosystem with a range of innovative DeFi opportunities."
                    />
                </StyledSphere>
                <StyledSphere>
                    <SphereContent
                        title="Next-Level Decentralization"
                        description="The MultiversX needs more key players to help grow the community in a scalable and decentralized manner."
                    />
                </StyledSphere>
            </SpheresContainer>
            <WhyEntityCloud color="white" width="var(--startups-cloud-width)" height="var(--startups-cloud-height)" />
        </Container>
    );
};

export default React.memo(WhyEntity);
