import { useQuery } from "@tanstack/react-query";
import { LaunchpadQueryKeys } from "../types/queryKeysTypes";
import { GeneralKeyMetrics, LaunchpadStage, ProjectGeneralStagesDetails } from "../../pages/Launchpad/launchpad.types";
import { CURRENT_STAGE_INDEX } from "../../pages/Launchpad/Launchpad";
import { useService } from "../../services/config/dependencyInjectorConfig";
import ProjectsService from "../../services/ProjectsService";
import { DateUtils } from "../index";

export const useGetProjectGeneralDetails = (enabled = true, projectId?: string) => {
    const [projectsService] = useService(ProjectsService);
    return useQuery({
        enabled,
        queryKey: [LaunchpadQueryKeys.PROJECT_GENERAL_DETAILS],
        queryFn: () => projectsService.retrieveGeneralDetails(projectId),
        select: (projectInfo): ProjectGeneralStagesDetails => {
            const stages = decidePreviousAndCurrentLaunchpadStages(projectInfo.generalMetrics);
            return {
                ...projectInfo,
                // previous card is not consider since we display only the current stage card, so is not a need to
                // retrieve data for the previous stage
                stages: [null, stages[CURRENT_STAGE_INDEX]],
            };
        },
        staleTime: Infinity,
    });
};

export function decidePreviousAndCurrentLaunchpadStages(
    metrics: GeneralKeyMetrics,
    isRejectedFromKyc?: boolean,
    isRejectedFromSnapshot?: boolean,
): Array<LaunchpadStage | null> {
    const now = Date.now();
    if (DateUtils.isPeriodActive(now, metrics.kycStartTimestamp, metrics.kycEndTimestamp)) {
        return [null, LaunchpadStage.KYC];
    }
    if (
        DateUtils.isPeriodActive(now, metrics.snapshotStartTimestamp, metrics.snapshotEndTimestamp) ||
        DateUtils.isPeriodActive(now, metrics.kycEndTimestamp, metrics.snapshotStartTimestamp)
    ) {
        return [LaunchpadStage.KYC, LaunchpadStage.SNAPSHOT];
    }
    if (
        DateUtils.isPeriodActive(now, metrics.buyTicketsStartTimestamp, metrics.buyTicketsEndTimestamp) ||
        DateUtils.isPeriodActive(now, metrics.snapshotEndTimestamp, metrics.buyTicketsStartTimestamp)
    ) {
        return [isRejectedFromKyc ? LaunchpadStage.KYC : LaunchpadStage.SNAPSHOT, LaunchpadStage.BUY_TICKETS];
    }
    if (
        DateUtils.isPeriodActive(now, metrics.winnerSelectionStartTimestamp, metrics.winnerSelectionEndTimestamp) ||
        DateUtils.isPeriodActive(now, metrics.buyTicketsEndTimestamp, metrics.winnerSelectionStartTimestamp)
    ) {
        let prevStage = LaunchpadStage.BUY_TICKETS;
        if (isRejectedFromKyc) prevStage = LaunchpadStage.KYC;
        if (isRejectedFromSnapshot) prevStage = LaunchpadStage.SNAPSHOT;
        return [prevStage, LaunchpadStage.WINNER_SELECTION];
    }
    if (
        DateUtils.isPeriodActive(now, metrics.claimTokensStartTimestamp, metrics.claimTokensEndTimestamp) ||
        DateUtils.isPeriodActive(now, metrics.winnerSelectionEndTimestamp, metrics.claimTokensStartTimestamp)
    ) {
        let prevStage = LaunchpadStage.WINNER_SELECTION;
        if (isRejectedFromKyc) prevStage = LaunchpadStage.KYC;
        if (isRejectedFromSnapshot) prevStage = LaunchpadStage.SNAPSHOT;
        return [prevStage, LaunchpadStage.CLAIM_TOKENS];
    }
    if (DateUtils.isPeriodActive(now, metrics.claimTokensEndTimestamp, now)) {
        return [LaunchpadStage.CLAIM_TOKENS, null];
    }
    return [null, null];
}
