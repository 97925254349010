import React, { useEffect, useState } from "react";
import FormControlledInputGroup, { FormControlGroupProps } from "./FormControlledInputGroup";
import { FormGroupProps } from "./types";
import { Form, InputGroup } from "react-bootstrap";
import { faMinusCircle, faPlusCircle } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import InputGroupIcon from "../../components/common/InputGroupIcon";

interface FormMultipleInputGroupProps<T> extends FormGroupProps<T>, Pick<FormControlGroupProps<T>, "control"> {
    elements?: string[];
    type?: string;
    min?: number;
    max?: number;
}

function FormMultipleInputGroup<T>(props: FormMultipleInputGroupProps<T>): JSX.Element {
    const {
        name,
        label,
        elements,
        type = "text",
        className = "flex-column",
        min = 3,
        max = 5,
        control,
        register,
        formState,
        required = false,
    } = props;
    const [items, setItems] = useState<string[]>(Array.from({ length: min }, () => ""));
    const [count, setCount] = useState<number>(min);

    useEffect(() => {
        if (!!elements) {
            const emptyElements = Array.from({ length: min - elements?.length }, () => "");
            const nextItems = [...elements, ...emptyElements];

            setItems(nextItems);
            setCount(nextItems.length);
        }
    }, [elements]);

    const add = (onChange: (event: string[]) => void) => {
        if (count < max) {
            const nextItems = [...items, ""];

            setItems(nextItems);
            setCount(count + 1);
            onChange(nextItems);
        }
    };

    const remove = (index: number, onChange: (event: string[]) => void) => {
        if (count > min) {
            const nextItems = [...items];
            nextItems.splice(index, 1);

            setItems(nextItems);
            setCount(count - 1);
            onChange(nextItems);
        }
    };

    const onChangeHandler = (value: string, index: number, onChange: (event: string[]) => void) => {
        const nextItems = [...items];
        nextItems[index] = value;

        setItems(nextItems);
        onChange(nextItems);
    };

    return (
        <FormControlledInputGroup
            name={name}
            label={label}
            register={register}
            control={control}
            className={className}
            formState={formState}
            required={required}
            render={({ onChange }) => (
                <>
                    {items.map((item, index) => (
                        <InputGroup key={`${name}-input-${index}`} className="mb-2">
                            <Form.Control
                                onChange={(event) => onChangeHandler(event.target.value, index, onChange)}
                                type={type}
                                value={item}
                            />
                            {index === count - 1 && index !== max - 1 && (
                                <InputGroupIcon
                                    onClick={() => add(onChange)}
                                    className={`green px-2 ${count === max ? "disabled" : "enabled"}`}
                                >
                                    <FontAwesomeIcon icon={faPlusCircle} />
                                </InputGroupIcon>
                            )}
                            {(index < count - 1 || count === max) && (
                                <InputGroupIcon
                                    onClick={() => remove(index, onChange)}
                                    className={`red px-2 ${count <= min ? "disabled" : "enabled"}`}
                                >
                                    <FontAwesomeIcon icon={faMinusCircle} />
                                </InputGroupIcon>
                            )}
                        </InputGroup>
                    ))}
                </>
            )}
        />
    );
}

export default FormMultipleInputGroup;
