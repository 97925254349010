import React, { FC, useEffect, useState } from "react";
import { Editor } from "react-draft-wysiwyg";
import "../../../../node_modules/react-draft-wysiwyg/dist/react-draft-wysiwyg.css";
import "./editor.scss";
import { ContentState, convertToRaw, EditorState } from "draft-js";
import draftToHtml from "draftjs-to-html";
import htmlToDraft from "html-to-draftjs";
import AlertService from "../../../services/AlertService";
import CharactersInfo from "./CharactersInfo";
import styled from "styled-components";

export interface EditorComponent {
    onChange: (value: string) => void;
    previousValue?: string;
    title?: () => JSX.Element;
    maxLength?: number;
}

const TitleAndPrompt = styled.div`
    display: flex;
    justify-content: space-between;
    margin-bottom: 0.25rem;
`;

const getLengthOfSelectedText = (editorState: EditorState) => {
    const currentSelection = editorState.getSelection();
    const isCollapsed = currentSelection.isCollapsed();

    let length = 0;

    if (!isCollapsed) {
        const currentContent = editorState.getCurrentContent();
        const startKey = currentSelection.getStartKey();
        const endKey = currentSelection.getEndKey();
        const startBlock = currentContent.getBlockForKey(startKey);
        const isStartAndEndBlockAreTheSame = startKey === endKey;
        const startBlockTextLength = startBlock.getLength();
        const startSelectedTextLength = startBlockTextLength - currentSelection.getStartOffset();
        const endSelectedTextLength = currentSelection.getEndOffset();
        const keyAfterEnd = currentContent.getKeyAfter(endKey);

        if (isStartAndEndBlockAreTheSame) {
            length += currentSelection.getEndOffset() - currentSelection.getStartOffset();
        } else {
            let currentKey = startKey;

            while (currentKey && currentKey !== keyAfterEnd) {
                if (currentKey === startKey) {
                    length += startSelectedTextLength + 1;
                } else if (currentKey === endKey) {
                    length += endSelectedTextLength;
                } else {
                    length += currentContent.getBlockForKey(currentKey).getLength() + 1;
                }

                currentKey = currentContent.getKeyAfter(currentKey);
            }
        }
    }

    return length;
};

const handleBeforeInput = (editorState: EditorState, maxLength: number) => {
    const currentContent = editorState.getCurrentContent();
    const currentContentLength = currentContent.getPlainText().length;
    const selectedTextLength = getLengthOfSelectedText(editorState);

    return currentContentLength - selectedTextLength > maxLength - 1;
};

const handlePastedText = (editorState: EditorState, maxLength: number) => (pastedText: string) => {
    const currentContent = editorState.getCurrentContent();
    const currentContentLength = currentContent.getPlainText().length;
    const selectedTextLength = getLengthOfSelectedText(editorState);

    if (currentContentLength + pastedText.length - selectedTextLength > maxLength) {
        (async () =>
            await AlertService.error(
                `You can type max ${maxLength} characters, you have ${
                    currentContentLength + pastedText.length - selectedTextLength
                }`,
            ))();
        return true;
    }
    return false;
};

const EditorComponent: FC<React.PropsWithChildren<EditorComponent>> = ({
    onChange,
    previousValue = "",
    title,
    maxLength = 10000,
}) => {
    const initialEditorState = (() => {
        const html = previousValue ? previousValue.trim() : "";
        const contentBlock = htmlToDraft(html);
        const contentState = ContentState.createFromBlockArray(contentBlock.contentBlocks);
        return EditorState.createWithContent(contentState);
    })();

    const [editorState, setEditorState] = useState(initialEditorState);
    const [isMessageShown, setIsMessageShown] = useState(false);
    const [textLength, setTextLength] = useState(maxLength);

    useEffect(() => {
        setTextLength(maxLength - editorState.getCurrentContent().getPlainText().length);
    }, [editorState]);

    useEffect(() => {
        if (previousValue) {
            setEditorState(initialEditorState);
            onChange(getMarkup(initialEditorState));
        }
    }, [previousValue]);

    const onChangeEditor = (currentState: EditorState): void => {
        setEditorState(currentState);
    };

    const onChangeContent = (): void => {
        onChange(getMarkup());
    };

    const getMarkup = (initialState?: EditorState): string => {
        const state = initialState || editorState;
        const rawContentState = convertToRaw(state.getCurrentContent());
        return draftToHtml(rawContentState);
    };

    return (
        <div>
            <TitleAndPrompt>
                {title && title()}
                {isMessageShown && <CharactersInfo amount={textLength} />}
            </TitleAndPrompt>
            <Editor
                toolbarOnFocus
                editorState={editorState}
                onEditorStateChange={onChangeEditor}
                onContentStateChange={onChangeContent}
                wrapperClassName="editor-wrapper form-control"
                editorClassName="editor"
                toolbarClassName="toolbar"
                // eslint-disable-next-line @typescript-eslint/ban-ts-comment
                // @ts-ignore: Property 'handleBeforeInput' does not exist on type.
                // The property is from draftjs docs and it works
                handleBeforeInput={() => handleBeforeInput(editorState, maxLength)}
                handlePastedText={handlePastedText(editorState, maxLength)}
                onFocus={() => setIsMessageShown(true)}
                onBlur={() => setIsMessageShown(false)}
            />
        </div>
    );
};

export default EditorComponent;
