import React, { FC } from "react";
import styled from "styled-components";

interface IProps {
    icon: JSX.Element;
    iconColor?: string;
    backgroundColor?: string;
    iconSize?: string;
    shadowColor?: string;
    className?: string;
    testId?: string;
}

interface IconProps {
    color: string;
    backgroundColor: string;
    size: string;
    shadowColor: string;
}

const Container = styled.div`
    height: ${(props: IconProps): string => props.size};
    width: ${(props: IconProps): string => props.size};
    line-height: ${(props: IconProps): string => props.size};
    border-radius: ${(props: IconProps): string => props.size};
    box-shadow: 0 0 0.714rem 0 ${(props: IconProps): string => props.shadowColor};
    text-align: center;
    background: ${(props: IconProps): string => props.backgroundColor};
    margin-left: auto;
    margin-right: auto;

    svg {
        color: ${(props: IconProps): string => props.color};
        vertical-align: middle;
        height: calc(${(props: IconProps): string => props.size}*0.475);
        width: calc(${(props: IconProps): string => props.size}*0.475);
    }
`;

const CardIcon: FC<React.PropsWithChildren<IProps>> = ({
    icon,
    iconColor = "#fff",
    backgroundColor = "var(--icon-bg)",
    iconSize = "5.714rem",
    shadowColor = "none",
    className = "",
    testId = "card-icon",
    ...props
}) => {
    return (
        <Container
            className={className}
            color={iconColor}
            backgroundColor={backgroundColor}
            shadowColor={shadowColor}
            size={iconSize}
            data-testid={testId}
            {...props}
        >
            {icon && icon}
        </Container>
    );
};

export default CardIcon;
