/* eslint-disable @typescript-eslint/no-explicit-any */
import { Form, FormText } from "react-bootstrap";
import React from "react";
import { Control, Controller, ControllerRenderProps } from "react-hook-form";
import { FormGroupProps } from "./types";
import { LabelWithInfoTooltip } from "../../components/LabelWithTooltip";
import EFormLabel from "./EFormLabel";
import clsx from "clsx";

export interface FormControlGroupProps<T> extends FormGroupProps<T> {
    name: ControllerRenderProps["name"];
    control: Control<any>;
    render: (props: ControllerRenderProps) => JSX.Element;
}

function FormControlledInputGroup<T>(props: FormControlGroupProps<T>): JSX.Element {
    const {
        name,
        label,
        labelTooltipText,
        className = "",
        control,
        register,
        formState: { errors },
        render,
        required,
        disabled,
    } = props;

    const { ref, ...restRegister } = register(name);

    const getError = () => {
        const nameParts = name.split(".");
        let errorsLocal: any = errors;
        nameParts.forEach((namePart) => {
            if (errorsLocal && errorsLocal.hasOwnProperty(namePart)) {
                errorsLocal = errorsLocal[namePart];
            } else {
                errorsLocal = undefined;
                return;
            }
        });
        return { type: errorsLocal?.type ?? undefined, message: errorsLocal?.message };
    };

    return (
        <Form.Group className={`d-flex mb-3 ${className}`} controlId={`controlGroup-${name}`} key={name} id={name}>
            {label &&
                (labelTooltipText ? (
                    <LabelWithInfoTooltip label={label} tooltipText={labelTooltipText} required={required} />
                ) : (
                    <EFormLabel className={`mb-1 ${required ? "required" : ""}`}>{label}</EFormLabel>
                ))}
            <Controller
                control={control}
                {...restRegister}
                name={name}
                render={({ field: { onChange, onBlur, value, name, ref } }) => {
                    return (
                        <div tabIndex={0} ref={ref} className={clsx([{ "not-allowed": disabled }])}>
                            {render({ onChange, onBlur, value, name, ref })}
                        </div>
                    );
                }}
            />
            {getError().type && <FormText className="form-error">{getError().message}</FormText>}
        </Form.Group>
    );
}

export default FormControlledInputGroup;
