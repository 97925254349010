import React, { useEffect } from "react";
import { FormGroupProps } from "./types";
import FormControlledInputGroup, { FormControlGroupProps } from "./FormControlledInputGroup";
import { DateTimePickerComponent } from "@syncfusion/ej2-react-calendars";
import { DateUtils } from "../index";
import { UseFormSetValue } from "react-hook-form/dist/types/form";
import { Path, PathValue } from "react-hook-form";

interface FormDateTimePickerGroupProps<T>
    extends FormGroupProps<T>,
        Pick<FormControlGroupProps<T>, "labelTooltipText" | "control"> {
    value?: number | null;
    min?: Date;
    setValue: UseFormSetValue<T>;
}

function FormDateTimePickerGroup<T>(props: FormDateTimePickerGroupProps<T>): JSX.Element {
    const {
        name,
        label,
        labelTooltipText,
        value,
        min,
        setValue,
        className = "flex-column",
        control,
        register,
        formState,
    } = props;

    useEffect(() => setValue(name as Path<T>, value as PathValue<T, Path<T>>), []);

    return (
        <FormControlledInputGroup
            name={name}
            label={label}
            labelTooltipText={labelTooltipText}
            register={register}
            control={control}
            className={className}
            formState={formState}
            render={({ onChange }) => (
                <DateTimePickerComponent
                    id={name}
                    name={name}
                    placeholder="Select date time"
                    value={value ? DateUtils.utcTimestampMillisToDate(value) : undefined}
                    min={min}
                    change={(dateTime) => onChange(DateUtils.dateTimeToUtcTimestampMillis(dateTime.value, true))}
                    showClearButton
                    showTodayButton
                />
            )}
        />
    );
}

export default FormDateTimePickerGroup;
