import React from "react";
import ModuleDescription from "../../ModuleDescription";

const Metrics = () => (
    <ModuleDescription
        title="Staking Provider & Delegation Metrics"
        description="The Staking Provider and Delegation Metrics modules help investors compare staking providers and analyze their own delegation history."
    />
);

export default Metrics;
