import React from "react";
import ContentContainer from "../ContentContainer";
import TeamCard from "../../components/TeamCard";
import { TeamSectionProps } from "./Team";
import Placeholder from "./Placeholder";
import styled from "styled-components";
import { Col, Row } from "react-bootstrap";

export const TextContainer = styled.div`
    line-height: 1.5rem;
    margin-bottom: 2rem;
    font-size: 1.1rem;
`;

export const RowContainer = styled(Row)`
    margin-left: -1rem;
    margin-right: -1rem;
    padding-bottom: 2.142rem;
`;

export const ColContainer = styled(Col)`
    padding: 1rem !important;
`;

const TeamSection = (props: TeamSectionProps | { showPlaceholder: true }) => {
    if ("showPlaceholder" in props) {
        return <Placeholder />;
    }

    const { title, description, items, isHtmlDescription } = props;
    return (
        <ContentContainer title={title}>
            {description && <TextContainer>{description}</TextContainer>}
            {items && items.length > 0 && (
                <RowContainer className="mt-3 px-0">
                    {items.map((item, itemIndex) => (
                        <ColContainer md={3} key={`team-${title}-card-${itemIndex}`}>
                            <TeamCard {...item} isHtmlDescription={isHtmlDescription} />
                        </ColContainer>
                    ))}
                </RowContainer>
            )}
        </ContentContainer>
    );
};

export default TeamSection;
