import React from "react";
import { EImagePlaceholder, EPlaceholder } from "../../../../components/Placeholder";
import { FeatureImage } from "./About";

const AboutPlaceholder = () => (
    <>
        <EImagePlaceholder as={FeatureImage} height="21.428rem" className="mb-5" />
        <EPlaceholder numberOfLines={15} />
    </>
);

export default AboutPlaceholder;
