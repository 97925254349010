import React from "react";
import Title from "../../components/Title";
import { Timeline } from "../../../../components/Timeline";
import { prepareTimelineEvents } from "../../utils/LaunchpadUtils";
import { Descriptions } from "./Investment";
import { LaunchRoadmapEntry } from "../../launchpad.types";

const LotteryTimeline = ({ launchRoadmap }: { launchRoadmap?: LaunchRoadmapEntry[] }) => (
    <>
        <Title title="Launch Timeline" className="mt-2" />
        <Descriptions>
            Reminder: Users need to complete their account verification (KYC) and also be from an accepted jurisdiction to
            participate in this token sale.
        </Descriptions>
        <br />
        <Timeline
            events={prepareTimelineEvents(launchRoadmap, false)}
            variant="nested-sub-events"
            showPlaceholder={!launchRoadmap}
        />
    </>
);

export default LotteryTimeline;
