export enum LaunchpadQueryKeys {
    PROJECT_GENERAL_DETAILS = "project-general-details",
    PROJECT_ROADMAP_DETAILS = "project-roadmap-details",
    PROJECT_TIERS_DETAILS = "project-tiers-details",
    PROJECT_SC_CONFIG = "project-sc-config",
    PROJECT_SC_FLAGS = "project-sc-flags",
    USER_KYC_STATUS = "user-kyc-status",
    USER_KYC_EMAIL = "user-kyc-email",
    USER_KYC_FLOW = "user-kyc-flow",
    USER_SNAPSHOT = "user-snapshot",
    USER_TICKETS = "user-tickets",
    USER_COUNTRY_ALLOWED = "user-country-allowed",
    STATS_SC_SHARD = "stats-sc-shard",
}
