import React from "react";
import { Path, useFieldArray } from "react-hook-form";
import { FormControlGroupProps } from "./FormControlledInputGroup";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faPlusCircle } from "@fortawesome/free-solid-svg-icons";
import styled from "styled-components";
import InputGroupIcon from "../../components/common/InputGroupIcon";
import { InputGroup } from "react-bootstrap";
import { FieldArrayWithId, UseFieldArrayRemove } from "react-hook-form/dist/types/fieldArray";

const StyledInputGroupIcon = styled(InputGroupIcon)`
    padding-right: 0 !important;
`;

interface NestedFieldsArrayProps<T> extends Pick<FormControlGroupProps<T>, "control"> {
    nestIndex: number;
    pathName: Path<unknown>;
    label: string;
    newFieldValue: Partial<unknown>;
    nestedFieldBuilder: (
        field: FieldArrayWithId<Record<string, unknown>, never, string>,
        nestIndex: number,
        index: number,
        remove: UseFieldArrayRemove,
    ) => JSX.Element;
}

function NestedFieldsArray<T>({
    nestIndex,
    pathName,
    newFieldValue,
    label,
    nestedFieldBuilder,
    control,
}: NestedFieldsArrayProps<T>): JSX.Element {
    const { fields, remove, append } = useFieldArray({ control, name: pathName });

    return (
        <div>
            <InputGroup>
                <label>{label}</label>
                <StyledInputGroupIcon className="green px-2 enabled">
                    <FontAwesomeIcon onClick={() => append(newFieldValue)} icon={faPlusCircle} />
                </StyledInputGroupIcon>
            </InputGroup>
            {fields.map((nestedField, index) => nestedFieldBuilder(nestedField, nestIndex, index, remove))}
        </div>
    );
}

export default NestedFieldsArray;
