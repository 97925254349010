import React, { FC } from "react";
import { EButton, EButtonProps } from "../../components/Buttons";
import styled from "styled-components";

const StyledEButton = styled(EButton)`
    padding-top: 0.2rem !important;
    padding-bottom: 0.2rem !important;
    height: 2.857rem;
    width: 10rem !important;
`;

const SettingsButton: FC<React.PropsWithChildren<EButtonProps>> = ({
    children,
    className,
    backgroundColor,
    type = "submit",
    ...props
}) => {
    return (
        <StyledEButton
            className={`${className} mt-2 mb-2`}
            backgroundcolor={backgroundColor}
            type={type}
            gradientangle={"137"}
            {...props}
        >
            {children}
        </StyledEButton>
    );
};

export default SettingsButton;
