import * as yup from "yup";
import { convertFromHTML } from "draft-js";
import ArrayUtils from "./ArrayUtils";
import AddressUtils from "./AddressUtils";

export const getPureText = (value: string) => {
    let text = "";
    convertFromHTML(value).contentBlocks.forEach((block) => {
        text = text + block.getText();
    });
    return text;
};

export const REQUIRED_MSG = "Required";
export const INCORRECT_URL_MSG = "Please enter valid URL";
export const ENTER_WEBSITE_MSG = "Please enter website";

export const minStrLength = 3;
export const minLengthErrorMsg = (length = minStrLength) => `At least ${length} characters.`;
export const maxLengthErrorMsg = (length: number) => `Maximum ${length} characters exceeded.`;
export const lengthErrorMsg = ({ min = minStrLength, max = 10_000 }) =>
    `At least ${min} characters and at most ${max} characters`;
export const lengthTestTitle = "Length checking";
const minElementsErrorMsg = (length: number) => `At least ${length} entries are required.`;
const duplicateValuesErrorMsg = "Field contains duplicate entries.";
const duplicateValuesTestTitle = "Duplicate values checking";

class ValidationSchemaUtils {
    static emptyAsNull = yup
        .string()
        .ensure()
        .trim()
        .transform((input) => (input?.length > 0 ? input : null))
        .nullable();

    static object = yup.object().required(REQUIRED_MSG);

    static number = yup.number().typeError("Not a number");

    static boolean = yup.boolean().typeError("Not a boolean");

    static string = yup.string().typeError(REQUIRED_MSG).required(REQUIRED_MSG).trim();

    static bech32Address = yup
        .string()
        .typeError(REQUIRED_MSG)
        .required(REQUIRED_MSG)
        .trim()
        .test("Check is bech32 address", "Invalid bech32 address", AddressUtils.isValidAddress);

    static email = ValidationSchemaUtils.string.email("Not an email");

    static password = ValidationSchemaUtils.string
        .required("Please Enter your password")
        .matches(
            /^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*[!@#\$%\^&\*])(?=.{8,})/,
            "Must Contain 8 Characters, One Uppercase, One Lowercase, One Number and One Special Case Character",
        );

    static emptyNumberAsNull = yup
        .number()
        .transform((input) => (input > 0 || !!input ? input : null))
        .nullable();

    static takeEditorEmptyAsNull = yup
        .string()
        .ensure()
        .trim()
        .transform((value) => {
            const pureText = getPureText(value);
            return pureText?.length > 0 ? value : null;
        })
        .nullable();

    static uniqueArray = (min: number) =>
        yup
            .array()
            .transform(removeEmptyValues)
            .nullable()
            .test(
                duplicateValuesTestTitle,
                duplicateValuesErrorMsg,
                (arr) => !!arr && ArrayUtils.getDuplicateValues(arr)?.length < 1,
            )
            .min(min, minElementsErrorMsg(min));

    static singleOptionChecked = yup
        .object()
        .test(
            "Check if single option is checked",
            "Select single option",
            (obj) => Object.keys(obj).filter((key) => !!obj[key]).length === 1,
        );

    static emptyArrayAsNull = () => yup.array().transform(removeEmptyValues).nullable();

    static limitedString = (maxCharacters: number) =>
        yup.string().required(REQUIRED_MSG).max(maxCharacters, `At most ${maxCharacters} characters`).nullable();

    static dynamicLimitedString = (
        relatedField: string,
        relatedFieldValue: any | ((...values: any[]) => boolean),
        maxCharacters: number,
    ) =>
        yup
            .string()
            .when(relatedField, {
                is: relatedFieldValue,
                then: ValidationSchemaUtils.limitedString(maxCharacters),
            })
            .nullable();

    static lazyDynamicField = (
        relatedField: string,
        relatedFieldValue: any | ((...values: any[]) => boolean),
        maxCharacters: number,
    ) =>
        yup.lazy((value) =>
            value !== undefined
                ? ValidationSchemaUtils.dynamicLimitedString(relatedField, relatedFieldValue, maxCharacters)
                : yup.mixed().notRequired(),
        );
}

export const isIntegerGreaterThanOrEqual = (input?: string | null, isOptional = true, threshold = 1) => {
    if ((isOptional && input === null) || input === "") {
        return isOptional;
    }

    const value = Number(input);
    return Number.isInteger(value) && value >= threshold;
};

const removeEmptyValues = (args?: string[]) => {
    const filteredValues = args?.map((item: string) => item.trim())?.filter((item: string) => !!item);
    return filteredValues && filteredValues.length > 0 ? filteredValues : null;
};

export default ValidationSchemaUtils;
