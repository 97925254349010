import React, { memo } from "react";
import EModal from "../../components/common/EModal";
import { Container } from "react-bootstrap";
import styles from "../UnlockPage/login.module.scss";
import { WebWallet } from "../../assets/img/WebWallet";
import { useNavigate } from "react-router-dom";
import { routeNames } from "../../routes/routes";

type AuthWeb3ModalProps = {
    show: boolean;
    closeHandler: () => void;
};

const AuthWeb3Modal = ({ show, closeHandler }: AuthWeb3ModalProps) => {
    const navigate = useNavigate();

    return (
        <EModal show={show} onHide={closeHandler} size="lg" centered>
            <EModal.Header closeButton></EModal.Header>
            <EModal.Body>
                <div>
                    <Container className={styles.unlockModal}>
                        <div className={styles.wrapper}>
                            <strong className={styles.heading}>Select from the available options</strong>
                            <div className={styles.connects}>
                                <button
                                    onClick={() => navigate(routeNames.unlock)}
                                    className="dapp-core-component__main__btn dapp-core-component__main__btn-primary dapp-core-component__main__px-4 dapp-core-component__main__m-1 dapp-core-component__main__mx-3 dapp-default-login-button dapp-web-wallet-login"
                                >
                                    <span className={styles.connect}>
                                        <span className={styles.title}>Multiversx</span>
                                        <span className={styles.icon} style={{ background: "#000000" }}>
                                            <WebWallet />
                                        </span>
                                        <span className={styles.name}>Multiversx Wallets</span>
                                    </span>
                                </button>
                                <button
                                    onClick={() => navigate(routeNames.unlock)}
                                    className="dapp-core-component__main__btn dapp-core-component__main__btn-primary dapp-core-component__main__px-4 dapp-core-component__main__m-1 dapp-core-component__main__mx-3 dapp-default-login-button dapp-web-wallet-login"
                                >
                                    <span className={styles.connect}>
                                        <span className={styles.title}>Cosmos</span>
                                        <span className={styles.icon} style={{ background: "#000000" }}>
                                            <WebWallet />
                                        </span>
                                        <span className={styles.name}>Keplr</span>
                                    </span>
                                </button>
                                <button
                                    disabled={true}
                                    className="dapp-core-component__main__btn dapp-core-component__main__btn-primary dapp-core-component__main__px-4 dapp-core-component__main__m-1 dapp-core-component__main__mx-3 dapp-default-login-button dapp-web-wallet-login"
                                >
                                    <span className={styles.connect}>
                                        <span className={styles.title}>EVM</span>
                                        <span className={styles.icon} style={{ background: "#000000" }}>
                                            <WebWallet />
                                        </span>
                                        <span className={styles.name}>Coming soon</span>
                                    </span>
                                </button>
                            </div>
                        </div>
                    </Container>
                </div>
            </EModal.Body>
        </EModal>
    );
};

export default memo(AuthWeb3Modal);
