/* eslint-disable @typescript-eslint/no-explicit-any */
import { useCallback } from "react";
import _ from "lodash";
import BaseSchema from "yup/lib/schema";
import { AnyObject } from "yup/lib/types";

const processErrors = (allErrors: any, currentError: any) => {
    const currentErrorObj = {
        type: currentError.type ?? "validation",
        message: currentError.message,
    };
    /**
     * if currentError.path doesn't contain "." it means that the error happened not in nested object
     * in this case we simply prepare an object that contains allErrors and info about the current one (type and message)
     */
    if (currentError.path.indexOf(".") < 0) {
        return {
            ...allErrors,
            [currentError.path]: currentErrorObj,
        };
    }

    /**
     * otherwise if currentError.path contains "." it means that error happened in a nested object
     * in this case we need to build a proper error object
     * for example error happened in parent1.parent2._..._parentN.child object
     * in this case error object should look like this:
     * {
     *     parent1: {
     *         parent2: {
     *             ...
     *             parentN: {
     *                 child: {
     *                     type: "some type";
     *                     message: "some message"
     *                 }
     *             }
     *         }
     *     }
     * }
     * so we can have a kind of "tree-view" with all the errors (if they happened in one parent), for example:
     * {
     *     parent1: {
     *         parent2: {
     *             error1: {
     *                 type: "some type";
     *                 message: "some message";
     *             }
     *         },
     *         error2: {
     *             type: "some other type";
     *             message: "some other message"
     *         }
     *     }
     * }
     *
     * to do so we need to build error object with nested objects and put errors there:
     */
    const paths = currentError.path.split(".");
    let errorsObj: any = {}; // it's ok to have any here because we don't really have a type
    /**
     * we build error obj from the "child" level:
     * so after first iteration it will be {error1: {type..., message...}}
     * after second iteration it will be {parentN: {error1: {type..., messga...}}}
     * and so on
     */
    errorsObj[paths[paths.length - 1]] = currentErrorObj;
    for (let i = paths.length - 2; i >= 0; i--) {
        errorsObj = {
            [paths[i]]: { ...errorsObj },
        };
    }

    /**
     * then simply deep merge allErrors and errorsObj to have all the errors in one place
     */
    _.merge(allErrors, errorsObj);
    return allErrors;
};

const useYupValidationResolver = <Schema extends BaseSchema<any, AnyObject, any>>(validationSchema: Schema) =>
    useCallback(
        async (data: any) => {
            try {
                const values = await validationSchema.validate(data, {
                    abortEarly: false,
                });

                return {
                    values,
                    errors: {},
                };
            } catch (errors) {
                const processedErrors = errors.inner?.reduce(
                    (allErrors: any, currentError: any) => ({
                        ...processErrors(allErrors, currentError),
                    }),
                    {},
                );

                return {
                    values: {},
                    errors: processedErrors,
                };
            }
        },
        [validationSchema],
    );

export default useYupValidationResolver;
