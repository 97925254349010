import React from "react";
import EModal from "components/common/EModal";
import { buttonVariants, EButton } from "../../components/Buttons";

interface ConfirmModalProps {
    header: string;
    body: JSX.Element;
    confirmModalHandler: () => void;
    closeModalHandler: () => void;
}

const ConfirmationModal = ({ header, body, confirmModalHandler, closeModalHandler }: ConfirmModalProps) => (
    <EModal show onHide={closeModalHandler} centered size="lg">
        <EModal.Header closeButton>
            <EModal.Title>
                <h2>{header}</h2>
            </EModal.Title>
        </EModal.Header>
        <EModal.Body>{body}</EModal.Body>
        <EModal.Footer className="justify-content-end">
            <EButton variant={buttonVariants.warn} onClick={confirmModalHandler} testId="confirm-btn">
                Confirm
            </EButton>
            <EButton onClick={closeModalHandler} testId="cancel-btn">
                Cancel
            </EButton>
        </EModal.Footer>
    </EModal>
);

export default ConfirmationModal;
