import React, { FC } from "react";
import ShowMoreText, { ReactShowMoreTextProps } from "react-show-more-text";
import styled from "styled-components";

const StyledShowMoreText = styled(ShowMoreText)`
    .e-anchor {
        color: var(--entity-green) !important;
        cursor: pointer;
    }
`;

const EShowMoreText: FC<React.PropsWithChildren<ReactShowMoreTextProps>> = ({ children }) => {
    return <StyledShowMoreText anchorClass="e-anchor">{children}</StyledShowMoreText>;
};

export default EShowMoreText;
