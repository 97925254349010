import InvestorsDome from "../../../assets/img/decorElements/investors-dome-entity.webp";
import React, { memo } from "react";
import styled from "styled-components";
import { BREAKPOINTS } from "../../../assets/sass/breakpoints";
import { Cloud } from "../../../components/Cloud";
import { PageBlockTitle } from "../../../components/PageBlockTitle";
import { Image } from "react-bootstrap";
import { investorsSvgWidth } from "./helper/consts";

const HeaderContent = styled.div`
    margin-left: 1rem;
    position: relative;

    @media (${BREAKPOINTS.tablet}) {
        margin-left: ${investorsSvgWidth * 0.057}rem !important;
    }
`;

const InvestorsCloudTop = styled(Cloud)`
    --investors-top-cloud-width: 60%;
    --investors-top-cloud-height: 17.857rem;
    top: -1.425rem;
    left: -2.5rem;

    @media (${BREAKPOINTS.tablet}) {
        top: -4.285rem;
        left: -14.285rem;
    }
`;

const InvestorsPageBlockTitle = styled(PageBlockTitle)`
    position: relative;
`;

const DomeContainer = styled.div`
    max-width: 36.143rem;
`;
const DomeImage = styled(Image)`
    width: 100%;
`;

const InvestorsHeaderContent = ({ title, description }: { title: string; description: string }) => {
    return (
        <HeaderContent className="d-flex flex-column flex-md-row justify-content-between">
            <InvestorsPageBlockTitle className="pt-4" title={title} description={description} />
            <DomeContainer className="d-flex flex-column align-items-center justify-content-center">
                <DomeImage src={InvestorsDome} />
            </DomeContainer>
        </HeaderContent>
    );
};

export default memo(InvestorsHeaderContent);
