import React from "react";
import ModuleDescription from "../../ModuleDescription";

const DefiAnalytics = () => (
    <ModuleDescription
        title="DeFi & Analytics"
        description="The DeFi & Analytics module will empower investors with insightful data on user interactions and liquidity flows."
    />
);

export default DefiAnalytics;
