/* eslint-disable @typescript-eslint/no-explicit-any */
import React, { useMemo } from "react";
import { Form, FormText, Image } from "react-bootstrap";
import { FormGroupProps } from "./types";
import _ from "lodash";
import { LabelWithInfoTooltip } from "../../components/LabelWithTooltip";
import EFormControl from "./EFormControl";
import EFormLabel from "./EFormLabel";
import styled, { css } from "styled-components";
import clsx from "clsx";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faQuestionCircle } from "@fortawesome/free-solid-svg-icons";

interface FormControlGroupProps<T> extends FormGroupProps<T> {
    type?: string;
    defaultValue?: string | string[] | number | null;
    placeholder?: string;
    min?: number;
    max?: number;
    step?: number | string;
    as?: any;
    rows?: number | string;
    groupClassName?: string;
    labelClassName?: string;
    isLabelOptional?: boolean;
    controlled?: boolean;
    onChange?: (event: React.ChangeEvent<HTMLInputElement>) => void;
    withImage?: boolean;
    imageUrl?: string;
}

function includesArrayIndex(path: string): boolean {
    return path.includes("[") && path.includes("]");
}

function mapNestedPathToTokens(path: string): string[] {
    const index = path.indexOf("[");
    if (index > 0) {
        return [path.substring(0, index), path.substring(index + 1, path.length - 1)];
    }
    return [path];
}

function findNestedFieldValue(obj: Record<string, any>, path: string): any {
    const paths = path
        .split(".")
        .map((p) => mapNestedPathToTokens(p))
        .flatMap((p) => p);
    let current = obj;

    for (let i = 0; i < paths.length; ++i) {
        if (current[paths[i]] == undefined) {
            return undefined;
        } else {
            current = current[paths[i]];
        }
    }
    return current;
}

const Circle = styled.div`
    width: 61px;
    height: 60px;
    border-radius: 50%;
    background: var(--green-opaque);
    z-index: 1;
    padding: 8px;
`;

const imageStyles = css`
    width: 40px;
    border-radius: 50%;
    margin: 2px 0 0;
    z-index: 1;
`;

const StyledQuestionImage = styled(FontAwesomeIcon)`
    ${imageStyles};
    height: 40px;
`;

const StyledImage = styled(Image)`
    ${imageStyles};
`;

const StyledEFormControl = styled(EFormControl)`
    margin-top: 10px;
    margin-left: -0.5rem;
    padding-left: 1.5rem;
`;

function FormControlGroup<T>(props: FormControlGroupProps<T>): JSX.Element {
    const {
        name,
        label,
        labelTooltipText,
        type = "text",
        disabled = false,
        defaultValue = undefined,
        placeholder = undefined,
        min = undefined,
        max = undefined,
        step = undefined,
        className = undefined,
        groupClassName = undefined,
        labelClassName = undefined,
        as = undefined,
        rows = undefined,
        isLabelOptional = false,
        controlled = false,
        onChange,
        withImage = false,
        imageUrl,
        register,
        registerOptions,
        required = false,
        formState: { errors },
    } = props;

    const getError = (fieldPathName: string): string | undefined => {
        if (!errors || (errors && Object.entries(errors).length === 0)) return undefined;
        // if fieldPathName doesn't contain "." then it's not nested object, simply return error message
        if (includesArrayIndex(fieldPathName)) {
            return findNestedFieldValue(errors, `${fieldPathName}.message`);
        }
        if (fieldPathName.indexOf(".") < 0) {
            return errors[fieldPathName]?.type ? (errors[fieldPathName]?.message as string) : "";
        }
        // for nested objects we need to get error message by path
        // check validationResolver to see how errors object is done
        // to do so we need simply go through error object and get value by names[i] on each iteration
        const names = fieldPathName.split(".");
        let errorsCopy: any = _.cloneDeep(errors);
        for (let i = 0; i < names.length; i++) {
            const n = names[i];
            if (errorsCopy[n]) {
                errorsCopy = errorsCopy[n];
            }
        }
        return errorsCopy?.message as unknown as string;
    };

    const err = getError(name);
    const registerResults = register(name, { ...registerOptions, value: defaultValue });

    const formControl = useMemo(
        () => (
            <>
                {!withImage && (
                    <EFormControl
                        type={type}
                        {...registerResults}
                        onChange={controlled && onChange ? onChange : registerResults.onChange}
                        as={as}
                        rows={rows}
                        disabled={disabled}
                        defaultValue={!!defaultValue ? defaultValue : undefined}
                        placeholder={placeholder}
                        min={min}
                        max={max}
                        step={step}
                        className={className}
                    />
                )}
                {withImage && (
                    <div className="d-flex">
                        <Circle>
                            {imageUrl && <StyledImage fluid src={imageUrl} />}
                            {!imageUrl && <StyledQuestionImage icon={faQuestionCircle} />}
                        </Circle>
                        <StyledEFormControl
                            type={type}
                            {...registerResults}
                            onChange={controlled && onChange ? onChange : registerResults.onChange}
                            as={as}
                            rows={rows}
                            disabled={disabled}
                            defaultValue={!!defaultValue ? defaultValue : undefined}
                            placeholder={placeholder}
                            min={min}
                            max={max}
                            step={step}
                            className={className}
                        />
                    </div>
                )}
            </>
        ),
        [imageUrl, type, registerResults, controlled, as, rows, disabled, defaultValue, placeholder, min, max, step, className],
    );

    return (
        <Form.Group className={clsx([{ "mb-2": !imageUrl }, groupClassName])} controlId={`controlGroup-${name}`}>
            {!isLabelOptional && label && labelTooltipText && (
                <LabelWithInfoTooltip
                    label={label}
                    tooltipText={labelTooltipText}
                    className={labelClassName}
                    required={required}
                />
            )}
            {!isLabelOptional && label && !labelTooltipText && (
                <EFormLabel className={clsx([labelClassName, { required: required }, { "no-margin": imageUrl }])}>
                    {label}
                </EFormLabel>
            )}
            {formControl}
            {err && <FormText className="form-error">{err}</FormText>}
        </Form.Group>
    );
}

export default FormControlGroup;
