import * as React from "react";
import { Container, Image, Nav } from "react-bootstrap";
import { ReactComponent as EgldSymbol } from "assets/img/elrond-symbol.svg";
import IstariLogo from "assets/img/istarivision-logo.png";
import Logo from "assets/img/logo.png";
import TitanLogo from "assets/img/titanstake-logo.png";
import SocialMediaButtons from "../../components/Buttons/SocialMediaButtons";
import { entitySocialList } from "../../helpers/consts";
import styled from "styled-components";
import { layoutContainer } from "../../components/Layout/styles";

const MaintenanceContainer = styled.div`
    ${layoutContainer};
    z-index: -100;
`;

const Maintenance = () => {
    return (
        <MaintenanceContainer className={"homepage d-flex flex-column flex-fill maintenance " + location.pathname.substring(1)}>
            <header className="header-maintenance" />
            <Container className="mt-0 mt-md-5 mb-0 mb-md-5 pt-2 pt-md-5 pb-5 text-center">
                <Image className="logo-maintenance" src={Logo} />
                <h1>COMING IN Q2 2022</h1>

                <Nav className="d-flex justify-content-center social">
                    <SocialMediaButtons buttons={entitySocialList} />
                </Nav>
            </Container>
            <footer className="footer-maintenance">
                <div className="footer-bottom">
                    <div className="icon-footer-center">
                        <EgldSymbol className="mx-auto" />
                    </div>
                </div>
                <Container>
                    <Nav className="footer-nav-center text-center d-block">
                        <Nav.Item className="d-inline-flex flex-column flex-md-row">
                            <Nav.Link target="_blank" href="https://istari.vision/en/">
                                <Image className="logo-footer mb-3 mb-md-0" src={IstariLogo} />
                            </Nav.Link>
                            <Nav.Link target="_blank" href="https://www.titanstake.com/">
                                <Image className="logo-footer" src={TitanLogo} />
                            </Nav.Link>
                        </Nav.Item>
                    </Nav>
                </Container>
            </footer>
        </MaintenanceContainer>
    );
};

export default React.memo(Maintenance);
