import React from "react";
import styled from "styled-components";
import { HexContainer } from "../../../components/Hexagon";
import ProjectLaunch from "../Investors/ProjectLaunch";
import { ProjectGeneralDetails } from "../../Launchpad/launchpad.types";
import { BREAKPOINTS } from "../../../assets/sass/breakpoints";

const Container = styled.div`
    position: relative;
    width: 100%;
    z-index: 1;

    @media (${BREAKPOINTS.desktop}) {
        margin-top: 6.571rem;
        margin-bottom: 15rem;
        top: 1rem;
        left: 8rem;
    }
`;

const StyledHex = styled(HexContainer)`
    display: flex;
    align-items: center;
    flex-direction: unset;
    flex-wrap: wrap;

    &.hex-container {
        height: 23.571rem !important;
        width: 27.143rem !important;

        @media (${BREAKPOINTS.tablet}) {
            height: 25.571rem !important;
            width: 29.143rem !important;
        }
    }

    min-width: 27.142rem;
`;

const ContainerBody = styled(Container)`
    position: relative;
`;

const NextLaunchpadCardTop = ({ projectGeneralDetails }: { projectGeneralDetails: ProjectGeneralDetails }) => {
    return (
        <Container data-testid="next-launchpad-top">
            <ContainerBody>
                <StyledHex className="hex-container mb-5 mb-xl-0">
                    <ProjectLaunch launchpadInfo={projectGeneralDetails} position={"top"} embedded />
                </StyledHex>
            </ContainerBody>
        </Container>
    );
};

export default NextLaunchpadCardTop;
