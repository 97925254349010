import EconomicsService, { Economics } from "./EconomicsService";
import UtilsService, { Stake, Stats, Users } from "./UtilsService";
import SessionStorageService from "./sessionStorageService/SessionStorageService";
import { SessionStorageKeys } from "./sessionStorageService/sessionStorageKeys";
import StakingProvidersService from "./StakingProvidersService";
import { StakingProvider } from "../common/types/stakingProiderTypes";

class ChainDataService {
    private readonly _economicsService: EconomicsService;
    private readonly _utilsService: UtilsService;
    private readonly _stakingProvidersService: StakingProvidersService;

    private stakingProviders: StakingProvider[] | null = null;

    constructor(
        economicsService: EconomicsService,
        utilsService: UtilsService,
        stakingProvidersService: StakingProvidersService,
    ) {
        this._economicsService = economicsService;
        this._utilsService = utilsService;
        this._stakingProvidersService = stakingProvidersService;
    }

    async getEconomics(): Promise<Economics> {
        return ChainDataService.getOrFetchChainData(SessionStorageKeys.chainDataEconomics, () =>
            this._economicsService.retrieveEconomics(),
        );
    }

    async getNetworkDelegators(): Promise<Users> {
        return ChainDataService.getOrFetchChainData(SessionStorageKeys.chainDataNetworkDelegators, () =>
            this._utilsService.getStakedUsers(),
        );
    }

    async getStakeInfo(): Promise<Stake> {
        return ChainDataService.getOrFetchChainData(SessionStorageKeys.chainDataStakeInfo, () =>
            this._utilsService.getStakeInfo(),
        );
    }

    async getStats(): Promise<Stats> {
        return ChainDataService.getOrFetchChainData(SessionStorageKeys.chainDataStats, () => this._utilsService.getStats());
    }

    async getScShard(scAddress: string): Promise<number> {
        return ChainDataService.getOrFetchChainData(SessionStorageKeys.chainDataScShard, () =>
            this._utilsService.getScShard(scAddress),
        );
    }

    async getStakingProviders(): Promise<StakingProvider[]> {
        if (!this.stakingProviders) {
            this.stakingProviders = await ChainDataService.getOrFetchChainData(SessionStorageKeys.stakingProviders, () =>
                this._stakingProvidersService.retrieveAll(),
            );
        }
        return this.stakingProviders;
    }

    async getStakingProvidersPeriodStartDate(): Promise<number> {
        return ChainDataService.getOrFetchChainData(SessionStorageKeys.stakingProvidersPeriodStartDate, async () => {
            const startDateTimestamp = await this._stakingProvidersService.retrievePeriodStartDate();
            return startDateTimestamp ? startDateTimestamp : new Date().getTime();
        });
    }

    private static async getOrFetchChainData<T>(storageKey: SessionStorageKeys, fetchFn: () => Promise<T>): Promise<T> {
        let chainData = SessionStorageService.getItem(storageKey);
        if (!!chainData) return chainData;

        chainData = await fetchFn();
        SessionStorageService.setItem(storageKey, chainData);
        return chainData;
    }
}

export default ChainDataService;
