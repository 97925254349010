import React, { memo, useMemo } from "react";
import { createHex, getCorners, getHighlightLines } from "./helpers/hex.helper";
import Hexagon, { HexagonMouseEventHandler, HexagonProps } from "./Hexagon";
import { BoundingBox, Corner, HexSettings } from "./helpers/hex.types";
import Line, { AnimatedLineProps } from "./Line";
import _ from "lodash";

export interface HexProps extends Omit<HexagonProps, "hex"> {
    q: number;
    r: number;
}

export interface Hexagon extends HexProps {
    points?: Corner[];
    onClick?: HexagonMouseEventHandler;
}

interface Coordinates {
    q: number;
    r: number;
    points: Corner[];
}

type LineAnimation = Omit<AnimatedLineProps, "pathLength"> & { isVisible?: boolean };

interface TemplateProps {
    layout: BoundingBox;
    hexSettings: Partial<HexSettings>;
    className?: string;
    hexagons: Hexagon[];
    templateId?: string;
    highlightLines?: { coordinates: Coordinates[]; animation?: LineAnimation; hasOriginPoint?: boolean }[];
}

const HexTemplate = ({
    layout: { width, height },
    hexSettings,
    hexagons,
    className,
    highlightLines,
    templateId = "hex-template",
}: TemplateProps) => {
    const { width: dimW = 0, height: dimH = 0 } = hexSettings.dimensions as BoundingBox;
    const offset = hexSettings.offset ?? 0;
    const BaseHexagon = createHex(hexSettings);
    const LineHexagon = createHex({
        ...hexSettings,
        dimensions: { width: dimW * offset, height: dimH * offset },
        offset: 1,
    });
    const xOriginOffset = hexSettings.origin && hexSettings.origin !== "topLeft" ? 2 * hexSettings.origin.x : 0;

    const highlight = useMemo(
        () => highlightLines && highlightLines.map((line) => getHighlightLines(line.coordinates, LineHexagon)),
        [highlightLines?.length],
    );

    return (
        <svg
            xmlns="http://www.w3.org/2000/svg"
            className={className}
            width={width}
            height={height}
            viewBox={`0 0 ${width} ${height}`}
        >
            {hexagons.map((hexagon, index) => {
                const hex = new BaseHexagon(hexagon.q, hexagon.r);
                const lineHex = new LineHexagon(hexagon.q, hexagon.r);

                return (
                    <Hexagon
                        key={`hexagon-item-${index}`}
                        templateId={templateId}
                        hex={hex}
                        {...(hexagon as HexProps)}
                        memoCompareFunction={hexagon.memoCompareFunction}
                        onClick={(e) => hexagon && hexagon.onClick && hexagon.onClick(e)}
                    >
                        {hexagon.points && hexagon.points.length > 0 && (
                            <Line
                                x={lineHex.x}
                                y={lineHex.y}
                                points={getCorners(hexagon.points, lineHex.width, lineHex.height, lineHex.origin)}
                            />
                        )}
                    </Hexagon>
                );
            })}
            {highlight &&
                highlight.map(({ points }, key) => {
                    const { animation, hasOriginPoint } = (highlightLines && highlightLines[key]) || {};

                    return (
                        <Line
                            animated
                            {...animation}
                            key={`highlight-line-${key}`}
                            pathLength={(_.maxBy(highlight, "length") ?? { length: 0 }).length - xOriginOffset}
                            strokeWidth={"2"}
                            points={!hasOriginPoint ? points : [{ x: 0, y: points[0].y }, ...points]}
                        />
                    );
                })}
        </svg>
    );
};

export default memo(HexTemplate);
