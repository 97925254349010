import { DependencyInjector, HookTuple, InjectionToken, makeInjector, useInjectorHook } from "@mindspace-io/react";
import StakingProvidersService from "../StakingProvidersService";
import DelegationMetricsService from "../DelegationMetricsService";
import EconomicsService from "../EconomicsService";
import IcoService from "../IcoService";
import { entityApi, launchpadApi, maiarTokensApi, network } from "../../config";
import UsersService from "../UsersService";
import WalletsService from "../WalletsService";
import AuthService from "../AuthService";
import { Token } from "@mindspace-io/react/lib/di/injector.interfaces";
import UsdEuroService from "../UsdEuroService";
import FileService from "../FileService";
import ContactService from "../ContactService";
import MemoizeService from "../MemoizeService";
import ProjectsService from "../ProjectsService";
import KeyInvestorsService from "../KeyInvestorsService";
import PortfolioService from "../PortfolioService";
import UtilsService from "../UtilsService";
import ReportsService from "../ReportsService";
import TransactionService from "../TransactionsService";
import KycService from "../KycService";
import TicketsService from "../TicketsService";
import { LaunchpadService } from "../LaunchpadService";
import ChainDataService from "../ChainDataService";
import EventsService from "../EventsService";
import MetaStakingFarmService from "../MetaStakingFarmService";
import TaxToolFarmsService from "../TaxToolFarmsService";
import AppSettingsService from "../AppSettingsService";
import LdmService from "../LdmService";
import LaunchpadBlacklistService from "../LaunchpadBlacklistService";
import TokensService from "../TokensService";
import CollectionsService from "../CollectionsService";

export const ENTITY_API = new InjectionToken<string>("Entity API");
export const LAUNCHPAD_API = new InjectionToken<string>("Launchpad API");
export const ELROND_API = new InjectionToken<string>("Elrond API");
export const ELROND_DATA_API = new InjectionToken<string>("Elrond data API");
export const MAIAR_TOKENS_API = new InjectionToken<string>("Maiar tokens API");

export const injector: DependencyInjector = makeInjector([
    { provide: ENTITY_API, useValue: entityApi },
    { provide: LAUNCHPAD_API, useValue: launchpadApi },
    { provide: ELROND_API, useValue: network.apiAddress },
    { provide: ELROND_DATA_API, useValue: network.dataApiAddress },
    { provide: MAIAR_TOKENS_API, useValue: maiarTokensApi },
    {
        provide: StakingProvidersService,
        useClass: StakingProvidersService,
        deps: [ENTITY_API],
    },
    {
        provide: DelegationMetricsService,
        useClass: DelegationMetricsService,
        deps: [ENTITY_API],
    },
    {
        provide: UsersService,
        useClass: UsersService,
        deps: [ENTITY_API, LAUNCHPAD_API],
    },
    {
        provide: UsdEuroService,
        useClass: UsdEuroService,
        deps: [ENTITY_API],
    },
    {
        provide: AuthService,
        useClass: AuthService,
        deps: [ENTITY_API],
    },
    {
        provide: WalletsService,
        useClass: WalletsService,
        deps: [ENTITY_API],
    },
    {
        provide: EconomicsService,
        useClass: EconomicsService,
        deps: [ELROND_API, MAIAR_TOKENS_API],
    },
    {
        provide: IcoService,
        useClass: IcoService,
        deps: [ENTITY_API],
    },
    {
        provide: FileService,
        useClass: FileService,
        deps: [ENTITY_API],
    },
    {
        provide: ContactService,
        useClass: ContactService,
        deps: [ENTITY_API],
    },
    {
        provide: KeyInvestorsService,
        useClass: KeyInvestorsService,
        deps: [ENTITY_API],
    },
    {
        provide: MemoizeService,
        useClass: MemoizeService,
        deps: [],
    },
    {
        provide: ProjectsService,
        useClass: ProjectsService,
        deps: [ENTITY_API],
    },
    {
        provide: TicketsService,
        useClass: TicketsService,
        deps: [LAUNCHPAD_API],
    },
    {
        provide: PortfolioService,
        useClass: PortfolioService,
        deps: [ENTITY_API],
    },
    {
        provide: UtilsService,
        useClass: UtilsService,
        deps: [ELROND_API, ELROND_DATA_API],
    },
    {
        provide: ReportsService,
        useClass: ReportsService,
        deps: [ENTITY_API, ELROND_DATA_API],
    },
    {
        provide: TransactionService,
        useClass: TransactionService,
        deps: [ENTITY_API, ELROND_API],
    },
    {
        provide: KycService,
        useClass: KycService,
        deps: [ENTITY_API, LAUNCHPAD_API],
    },
    {
        provide: LaunchpadService,
        useClass: LaunchpadService,
        deps: [LAUNCHPAD_API],
    },
    {
        provide: ChainDataService,
        useClass: ChainDataService,
        deps: [EconomicsService, UtilsService, StakingProvidersService],
    },
    {
        provide: EventsService,
        useClass: EventsService,
        deps: [],
    },
    {
        provide: MetaStakingFarmService,
        useClass: MetaStakingFarmService,
        deps: [ENTITY_API],
    },
    {
        provide: TaxToolFarmsService,
        useClass: TaxToolFarmsService,
        deps: [ENTITY_API],
    },
    {
        provide: AppSettingsService,
        useClass: AppSettingsService,
        deps: [ENTITY_API],
    },
    {
        provide: LdmService,
        useClass: LdmService,
        deps: [ENTITY_API],
    },
    {
        provide: LaunchpadBlacklistService,
        useClass: LaunchpadBlacklistService,
        deps: [LAUNCHPAD_API],
    },
    {
        provide: TokensService,
        useClass: TokensService,
        deps: [ELROND_API],
    },
    {
        provide: CollectionsService,
        useClass: CollectionsService,
        deps: [ELROND_API],
    },
]);

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export function useService<T extends Token>(token: T): HookTuple<any, DependencyInjector> {
    return useInjectorHook(token, injector);
}
