import React, { FC } from "react";
import { Card, Image, Nav } from "react-bootstrap";
import SocialMediaButtons, { SocialMediaButton } from "../../../components/Buttons/SocialMediaButtons";
import styled from "styled-components";
import { renderHTML } from "../../../helpers/consts";

const defaultColor = "#FFFFFF";

const StyledImageWrapper = styled.div`
    min-height: 4.5rem;
`;

const StyledCard = styled(Card)`
    padding: 30px 30px 25px;
    background-color: var(--card-bg-dark);
    border: none !important;
    -webkit-box-shadow: 0px 4px 16px -4px rgba(19, 38, 19, 1);
    -moz-box-shadow: 0px 4px 16px -4px rgba(19, 38, 19, 1);
    box-shadow: 0px 4px 16px -4px rgba(19, 38, 19, 1);

    .card-body {
        padding: 0;
    }
`;

const StyledCardTitle = styled(Card.Title)`
    line-height: 1.5rem !important;
    font-size: 1.125rem !important;
    text-align: center;
`;

const StyledCardText = styled(Card.Text)`
    color: ${(props) => props.color || defaultColor} !important;
    line-height: 1.675rem !important;
    text-align: center;

    p {
        line-height: 1.7rem;
    }
`;

const StyledCardImage = styled(Image)`
    &.icon-rectangle {
        max-width: 190px;
        height: 52px;
    }

    &.icon-circle {
        width: 100px;
        height: 100px;
    }
`;

export interface TeamCardProps {
    photo?: string;
    roundedCircle?: boolean;
    name: string;
    role?: string;
    website?: string;
    description: string;
    isHtmlDescription?: boolean;
    socialMedia?: SocialMediaButton[];
}

const TeamCard: FC<React.PropsWithChildren<TeamCardProps>> = ({
    name,
    role,
    photo,
    website,
    description,
    isHtmlDescription,
    socialMedia,
    roundedCircle = false,
}) => {
    return (
        <StyledCard className="entityCard">
            <Card.Body className="d-flex flex-column">
                <div className="d-flex flex-column mb-3">
                    {photo && (
                        <StyledImageWrapper className="d-flex mb-3 justify-content-center">
                            <StyledCardImage
                                src={photo}
                                roundedCircle={roundedCircle}
                                className={roundedCircle ? "icon-circle" : "icon-rectangle"}
                            />
                        </StyledImageWrapper>
                    )}
                    <StyledCardTitle>
                        {website ? (
                            <a className="text-green" rel="noreferrer" target="_blank" href={website}>
                                {name}
                            </a>
                        ) : (
                            name
                        )}
                    </StyledCardTitle>
                    <StyledCardText color="#a3b1b4">{role && role}</StyledCardText>
                </div>
                <StyledCardText color="#C9C9C9">{isHtmlDescription ? renderHTML(description) : description}</StyledCardText>
                {socialMedia && (
                    <Nav className="d-flex justify-content-center social">
                        <SocialMediaButtons buttons={socialMedia} />
                    </Nav>
                )}
            </Card.Body>
        </StyledCard>
    );
};

export default TeamCard;
