import React from "react";
import { Col, Row } from "react-bootstrap";
import { EPlaceholder } from "../../Placeholder";

const DetailsRow = ({ key, value }: { key: string; value?: string | number }) => {
    return (
        <Row>
            <Col>{key}</Col>
            <Col className="text-right">{value ? value : <EPlaceholder width={6} />}</Col>
        </Row>
    );
};

export default DetailsRow;
