import axios from "axios";
import BaseService from "./BaseService";
import { network } from "../config";
import { gql, GraphQLClient } from "graphql-request";
import { NumberUtils } from "../common";
import { errorMessage } from "../helpers/consts/error";

export interface MexPrice {
    price: number;
}

export interface MexAprData {
    farms: {
        apr: string;
        farmingToken: {
            name: string;
            identifier: string;
        };
    }[];
}

export interface Economics {
    apr: number;
    baseApr: number;
    circulatingSupply: number;
    marketCap: number;
    price: number;
    staked: number;
    topUpApr: number;
    totalSupply: number;
}

export interface TokenValue {
    fsym: string;
    tsym: string;
    value: string;
    additionalData: {
        totalLiquidityUsdc: string;
        egldUsdcValue: string;
        tokenUsdcValue: string;
    };
    timestamp: number;
}

export const EconomicsInit = {
    apr: 0,
    baseApr: 0,
    circulatingSupply: 0,
    marketCap: 0,
    price: 0,
    staked: 0,
    topUpApr: 0,
    totalSupply: 0,
};

export const getPercentageOfApr = (apr: Economics["apr"]) => NumberUtils.getPercentage(apr);
export const getTotalEgldStake = ({
    staked,
    circulatingSupply,
}: {
    staked: Economics["staked"];
    circulatingSupply: Economics["circulatingSupply"];
}) => +((staked / circulatingSupply) * 100).toFixed(2);

const graphQLClient = new GraphQLClient(network.graphQlAddress);

class EconomicsService extends BaseService {
    private readonly _elrondApi: string;
    private readonly _maiarTokensApi: string;

    constructor(elrondApi: string, maiarTokensApi: string) {
        super();
        this._elrondApi = elrondApi;
        this._maiarTokensApi = maiarTokensApi;
    }

    public retrieveMexPrice(): Promise<MexPrice> {
        return this.doCall<MexPrice>(() => axios.get(`${this._elrondApi}/mex-economics`), errorMessage);
    }

    public async retrieveMexApr(): Promise<number> {
        const res = await graphQLClient.request<MexAprData>(gql`
            query {
                farms {
                    apr
                    farmingToken {
                        name
                        identifier
                    }
                }
            }
        `);
        const mexInfo = res.farms.filter((r) => r.apr && !isNaN(+r.apr) && r.farmingToken.name === "MEX");
        if (!mexInfo || mexInfo.length == 0) {
            this.triggerNotificationError(null, errorMessage);
        }
        return getPercentageOfApr(+mexInfo[0].apr);
    }

    public retrieveEconomics(): Promise<Economics> {
        return this.doCall<Economics>(() => axios.get(`${this._elrondApi}/economics`));
    }

    public retrieveTokenValue(fsym: string, tsym = "USDC"): Promise<TokenValue> {
        return this.doCall<TokenValue>(() => axios.get(`${this._maiarTokensApi}/token-value`, { params: { fsym, tsym } }));
    }

    public retrieveAllTokenValues(): Promise<TokenValue[]> {
        return this.doCall<TokenValue[]>(() => axios.get(`${this._maiarTokensApi}/token-value/latest/all`));
    }
}

export default EconomicsService;
