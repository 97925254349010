import React, { FC } from "react";
import styled from "styled-components";
import MobileFooter from "./MobileFooter";
import { BREAKPOINTS } from "../../../assets/sass/breakpoints";
import DesktopFooter from "./DesktopFooter";
import { useMediaQuery } from "react-responsive";

const EDesktopFooter = styled(DesktopFooter)`
    visibility: hidden;
    z-index: auto;

    @media (${BREAKPOINTS.tablet}) {
        visibility: unset;
        display: flex;
        flex-direction: column;
    }
`;

const EMobileFooter = styled(MobileFooter)`
    display: flex;
    flex-direction: column;
    z-index: auto;

    @media (${BREAKPOINTS.tablet}) {
        display: none;
    }
`;

const Footer: FC<React.PropsWithChildren<unknown>> = () => {
    const isBiggerThanMobile = useMediaQuery({ query: `(${BREAKPOINTS.tablet})` });
    return (
        <footer>
            {isBiggerThanMobile && <EDesktopFooter />}
            {!isBiggerThanMobile && <EMobileFooter />}
        </footer>
    );
};

export default React.memo(Footer);
