import React, { memo } from "react";
import { DetailedInfoProps, ProjectMetricsDetails } from "../../launchpad.types";
import ContentContainer from "../ContentContainer";
import { TokenEconomics } from "./TokenEconomics";
import { KeyMetrics } from "./KeyMetrics";
import { TokenSale } from "./TokenSale";
import DecorationsBg from "./TokenEconomics/DecorationsBg";

const Metrics = ({
    projectName,
    metricsInfo,
    projectId,
    tokensPerTicket,
}: DetailedInfoProps & { projectName?: string; metricsInfo: ProjectMetricsDetails | null; tokensPerTicket?: number }) => {
    return (
        <>
            <ContentContainer title="Key Metrics">
                <KeyMetrics metrics={metricsInfo?.keyMetrics} />
            </ContentContainer>
            <ContentContainer title="Token Sale">
                <TokenSale
                    projectId={projectId}
                    tokensPerTicket={tokensPerTicket}
                    privateSale={metricsInfo?.privateSale}
                    publicSale={metricsInfo?.publicSale}
                    tokenName={metricsInfo?.keyMetrics.tokenName}
                />
            </ContentContainer>
            <ContentContainer title="Token Governance And Use Of Funds">
                <DecorationsBg />
                <TokenEconomics
                    projectName={projectName}
                    numberFormatStyle="percent"
                    tokenEconomics={metricsInfo?.tokenEconomics}
                    tokenName={metricsInfo?.keyMetrics.tokenName}
                />
            </ContentContainer>
        </>
    );
};

export default memo(Metrics);
