import BaseService from "./BaseService";
import axios from "axios";
import { CollectionsEntity } from "../pages/Ico/utils/tokens.dto";

export class NftsQueryParams {
    from?: number | undefined = undefined;
    size?: number | undefined = undefined;
    type?: string | undefined = undefined;
    search?: string | undefined = undefined;
}

export class CollectionsService extends BaseService {
    private readonly _apiAddress: string;

    constructor(apiAddress: string) {
        super();
        this._apiAddress = apiAddress;
    }

    public async getCollectionsList(queryParams: NftsQueryParams): Promise<CollectionsEntity[]> {
        return this.doCall(() => axios.get<CollectionsEntity[]>(`${this._apiAddress}/collections`, { params: queryParams }));
    }
}

export default CollectionsService;
